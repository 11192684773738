import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { Router } from '@angular/router';
import { CheckboxSelectionComponent } from 'ag-grid-community';
import { CellRendererComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { ScenarioComponent } from '../scenario/scenario.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { HttpParams } from '@angular/common/http';
let countryCode;
@Component({
  selector: 'app-eq-taretcosting-calc-popup',
  templateUrl: './eq-taretcosting-calc-popup.component.html',
  styleUrls: ['./eq-taretcosting-calc-popup.component.css']
})
export class EqTaretcostingCalcPopupComponent implements OnInit {
  selectedData: any;

  constructor(
    public dialogRef: MatDialogRef<EqTaretcostingCalcPopupComponent>,
    public dialog: MatDialog,
    private router: Router,
    private localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public totalBkS3: any
  ) { }

  public columnDefs: any;
  public rowData: any;
  public defaultColDef: any;
  public showGrid = false;
  public gridApi: any;
  public gridColumnApi: any;
  public selectedScenario: any;
  gridRowData: any[];
  public totalBK = 0;
  public totalCrpData = 0;
  public modifiedRowsData = [];
  domLayout: any;


  ngOnInit(): void {
    countryCode = this.localStorageService.get('countryCode');
    this.loadGrid();


  }

  loadGrid() {
    let regex = new RegExp(/^\\s*$/);
    this.columnDefs = [
      {
        headerName: "",
        field: "Name",
        tooltipField: "Name",
        headerTooltip: "Name",
        editable: false,
      },

      {
        headerName: "Scenario1",
        field: "Scenario1",
        tooltipField: "Scenario1",
        headerTooltip: "Scenario1",
        cellRendererFramework: ScenarioComponent,
        tooltipValueGetter: CurrencyCellRenderer,
        editable: (params: any) => {
          if (params.data.Name == null || params.data.Name == 'BK') {
            return false
          }
          else {
            return true
          }
        },
        singleClickEdit: true,
        valueGetter: (params) => {
          if (params.node.data.Name == "BK") {
            let marginData;
            let crpData;
            crpData = Number(params.api.getDisplayedRowAtIndex(1).data.Scenario1)
            marginData = Number(params.api.getDisplayedRowAtIndex(2).data.Scenario1) / 100;
            let calculation = crpData * (1 - marginData);
            params.api.getDisplayedRowAtIndex(3).data.Scenario1 = calculation;
            return calculation;
          }
          return (params.data.Scenario1);
        }
      },
      {
        headerName: "Scenario2",
        field: "Scenario2",
        tooltipField: "Scenario2",
        headerTooltip: "Scenario2",
        tooltipValueGetter: CurrencyCellRenderer,
        editable: (params: any) => {
          if (params.data.Name == null || params.data.Name == 'CRP') {
            return false
          }
          else {
            return true
          }
        },
        singleClickEdit: true,
        cellRendererFramework: ScenarioComponent,
        valueGetter: (params) => {
          if ((params.data.Name == 'CRP')) {
            let marginData;
            let bkData;
            bkData = params.column.colId === 'CRP';
            bkData = Number(params.api.getDisplayedRowAtIndex(3).data.Scenario2)
            marginData = Number(params.api.getDisplayedRowAtIndex(2).data.Scenario2) / 100;
            let calculation = bkData / (1 - marginData);
            params.api.getDisplayedRowAtIndex(1).data.Scenario2 = calculation;
            return calculation;
          }
          return (params.data.Scenario2);
        }
      },
      {
        headerName: "Scenario3",
        field: "Scenario3",
        tooltipField: "Scenario3",
        headerTooltip: "Scenario3",
        tooltipValueGetter: CurrencyCellRenderer,
        cellRendererFramework: ScenarioComponent,
        editable: (params: any) => {
          if (params.data.Name == null || params.data.Name == 'CRP' || params.data.Name == 'BK') {
            return false
          }
          else {
            return true
          }
        },
        singleClickEdit: true,
        valueGetter: (params) => {
          if ((params.data.Name == 'CRP')) {
            let marginData;
            let bkData;
            bkData = this.totalBkS3
            bkData = Number(params.api.getDisplayedRowAtIndex(3).data.Scenario3)
            marginData = Number(params.api.getDisplayedRowAtIndex(2).data.Scenario3) / 100;
            let calculation = bkData / (1 - marginData);
            params.api.getDisplayedRowAtIndex(1).data.Scenario3 = calculation;
            return calculation;
          }
          return (params.data.Scenario3);

        }
      },


    ];


    this.domLayout = 'autoHeight';
    this.showGrid = true;
    this.rowData = [
      { Scenario1: false, Scenario2: false, Scenario3: false, Name: null },
      { Scenario1: null, Scenario2: null, Scenario3: null, Name: 'CRP' },
      { Scenario1: null, Scenario2: null, Scenario3: null, Name: 'Margin' },
      { Scenario1: null, Scenario2: null, Scenario3: this.totalBkS3, Name: 'BK' },
    ]
    //this.rowData = this.rowData;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  onCellValueChanged(params) {
  }
  checkboxchecked(event) {
  }
  onSubmit(event?: any) {
    let flagresponse = 0, crp, margin, bk;
    
    if (this.gridApi.getDisplayedRowAtIndex(0).data.Scenario1 == false && this.gridApi.getDisplayedRowAtIndex(0).data.Scenario2 == false && this.gridApi.getDisplayedRowAtIndex(0).data.Scenario3 == false) {
      this.openAlertDialog([{ [messages.eQScenario]: serverMessage.warning }]);
      flagresponse = 1;
    }
    else if (this.gridApi.getDisplayedRowAtIndex(0).data.Scenario1 == true) {
      if (this.gridApi.getDisplayedRowAtIndex(1).data.Scenario1 == null || this.gridApi.getDisplayedRowAtIndex(2).data.Scenario1 == null) {
        this.openAlertDialog([{ [messages.marginAndCrpData]: serverMessage.warning }]);
        flagresponse = 1;
      }
      else {
        crp = this.gridApi.getDisplayedRowAtIndex(1).data.Scenario1;
        margin = this.gridApi.getDisplayedRowAtIndex(2).data.Scenario1;
        bk = this.gridApi.getDisplayedRowAtIndex(3).data.Scenario1;
      }
    }
    else if (this.gridApi.getDisplayedRowAtIndex(0).data.Scenario2 == true) {
      if (this.gridApi.getDisplayedRowAtIndex(2).data.Scenario2 == null || this.gridApi.getDisplayedRowAtIndex(3).data.Scenario2 == null) {
        this.openAlertDialog([{ [messages.marginAndBkData]: serverMessage.warning }]);
        flagresponse = 1;
      }
      else {
        crp = this.gridApi.getDisplayedRowAtIndex(1).data.Scenario2;
        margin = this.gridApi.getDisplayedRowAtIndex(2).data.Scenario2;
        bk = this.gridApi.getDisplayedRowAtIndex(3).data.Scenario2;
      }
    }
    else if (this.gridApi.getDisplayedRowAtIndex(0).data.Scenario3 == true) {
      if (this.gridApi.getDisplayedRowAtIndex(2).data.Scenario3 == null) {
        this.openAlertDialog([{ [messages.marginData]: serverMessage.warning }]);
        flagresponse = 1;
      }
      else {
        crp = this.gridApi.getDisplayedRowAtIndex(1).data.Scenario3;
        margin = this.gridApi.getDisplayedRowAtIndex(2).data.Scenario3;
        bk = this.gridApi.getDisplayedRowAtIndex(3).data.Scenario3;
      }
    }
    if (flagresponse == 0) {
      this.selectedData = {
        CRP: crp,
        Margin: margin,
        BK: bk
      }
      this.dialogRef.close(this.selectedData);
    }

  }
}
// used to format the cell in germman currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
}
