<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>
<div class="container">
    <div class="col-md-12 col-lg-12 label-header">
        <label class="label-headercolor label-text">Product LifeCycle Costs - Direct Costs</label>
    </div>
    <div style="overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
        <div class="col-md-12 col-lg-12 label-header" style="display: inline-block;">
            <!-- <label class="label-headercolor">Reference Scenario: {{ referenceTitle }} </label> -->
            <label class="label-headercolor">Reference Scenario: {{refTitle}}
            </label>
        </div>
        <br>
        <div class="col-md-12 col-lg-12" style="padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="refGrid"
                [rowData]="gridData" [columnDefs]="refColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="refFrameworkComponents"
                [defaultColDef]="refDefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onRefCellValueChanged($event)"
                (gridReady)="onRefGridReady($event)" (gridSizeChanged)="onGridRefChanged($event)"
                [getRowHeight]="getRowHeightRef" (columnResized)="onColumnResizedRef($event)"
                (columnVisible)="onColumnVisibleRef($event)"  [rowHeight]="rowHeight" [headerHeight]="headerHeight">
            </ag-grid-angular>

        </div>
    </div>
    <div class="col-md-12 col-lg-12 label-header" style="display: inline-block;">
        <!-- <label class="label-headercolor">Reference Scenario: {{ referenceTitle }} </label> -->
        <label class="label-headercolor">Target Scenario: {{tarTitle}}
        </label>
    </div>
    <br>
    <div class="col-md-12 col-lg-12" style="padding-left: 0px;padding-right: 0px; display: inline-block;">
        <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="refGrid"
            [rowData]="tarGridData" [columnDefs]="tarColumnDefs" [rowClassRules]="rowClassRulesTar"
            [domLayout]="refdomLayout" [frameworkComponents]="tarFrameworkComponents" [defaultColDef]="tarDefaultColDef"
            [headerHeight]="headerHeight" [enableBrowserTooltips]="true" [popupParent]="popupParent"
            (cellValueChanged)="onTarCellValueChanged($event)" (gridReady)="onTarGridReady($event)"
            (gridSizeChanged)="onGridTarChanged($event)" [getRowHeight]="getRowHeightTar"
            (columnResized)="onColumnResizedTar($event)" (columnVisible)="onColumnVisibleTar($event)"  [rowHeight]="rowHeight" [headerHeight]="headerHeight">
        </ag-grid-angular>
    </div>
    <div *ngIf="optAVisible" class="col-md-12 col-lg-12"
        style="padding-left: 0px;padding-right: 0px; display: inline-block;">
        <div class="col-md-12 col-lg-12 label-header" style="display: inline-block;">
            <label class="label-headercolor">Option A: {{optATitle}}
            </label>
        </div>
        <br>
        <div class="col-md-12 col-lg-12" style="padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="optAGrid" [rowData]="optAGridData"
                [columnDefs]="optAColumnDefs" [rowClassRules]="rowClassRulesOptA" [domLayout]="OptADomLayout"
                [frameworkComponents]="optAFrameworkComponents" [defaultColDef]="optADefaultColDef"
                [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptACellValueChangedCS($event)" (gridReady)="onOptAGridReady($event)"
                (gridSizeChanged)="onGridOptACSChanged($event)" (columnResized)="onColumnResizedOptA($event)"
                (columnVisible)="onColumnVisibleOptA($event)"  [rowHeight]="rowHeight" [headerHeight]="headerHeight">
            </ag-grid-angular>
        </div>
    </div>
    <div *ngIf="optBVisible" class="col-md-12 col-lg-12"
        style="padding-left: 0px;padding-right: 0px; display: inline-block;">
        <div *ngIf="optBVisible" class="col-md-12 col-lg-12 label-header" style="display: inline-block;">
            <label class="label-headercolor">Option B: {{optBTitle}}
            </label>
        </div>
        <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="optBGrid" [rowData]="optBGridData"
            [columnDefs]="optBColumnDefs" [rowClassRules]="rowClassRulesOptB" [domLayout]="OptBDomLayout"
            [frameworkComponents]="optBrameworkComponents" [defaultColDef]="optBDefaultColDef"
            [enableBrowserTooltips]="true" [popupParent]="popupParent"
            (cellValueChanged)="onOptBCellValueChangedCS($event)" (gridReady)="onOptBGridReady($event)"
            (gridSizeChanged)="onGridOptBCSChanged($event)" (columnResized)="onColumnResizedOptB($event)"
            (columnVisible)="onColumnVisibleOptB($event)"  [rowHeight]="rowHeight" [headerHeight]="headerHeight">
        </ag-grid-angular>
    </div>
    <div *ngIf="optCVisible" class="col-md-12 col-lg-12"
        style="padding-left: 0px;padding-right: 0px; display: inline-block;">
        <div *ngIf="optCVisible" class="col-md-12 col-lg-12 label-header" style="display: inline-block;">
            <label class="label-headercolor">Option C: {{optCTitle}}
            </label>
        </div>
        <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="optCGrid" [rowData]="optCGridData"
            [columnDefs]="optCColumnDefs" [rowClassRules]="rowClassRulesOptC" [domLayout]="OptCDomLayout"
            [frameworkComponents]="optCFrameworkComponents" [defaultColDef]="optCDefaultColDef"
            [enableBrowserTooltips]="true" [popupParent]="popupParent"
            (cellValueChanged)="onOptCCellValueChangedCS($event)" (gridReady)="onOptCGridReady($event)"
            (gridSizeChanged)="onGridOptCCSChanged($event)" (columnResized)="onColumnResizedOptC($event)"
            (columnVisible)="onColumnVisibleOptC($event)"  [rowHeight]="rowHeight" [headerHeight]="headerHeight">
        </ag-grid-angular>
    </div>
    <br>
    <div class="row" style="margin-top:0px">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle" style="float: right; margin-right: 5px; margin-bottom: -20px">
                <button class="button button--small" (click)="onClickCancel()">
                    Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
                    Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
                    Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="submit($event)" type="submit">
                    Save & Exit
                </button>
            </div>
        </div>
    </div>


    <br>
    <app-commentbox [configData]="objData"></app-commentbox>
</div>