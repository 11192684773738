import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgCheckbox, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
// import { title } from 'process';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { ideaPageConstants } from 'src/app/app-constants/ideaPage-constants';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { CategorizationService } from 'src/app/services/categorization.service';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { UserReplacementService } from 'src/app/services/user-replacement.service';
import { CheckboxComponent } from '../admin/checkbox/checkbox.component';
import { DeleteiconComponent } from '../deleteIconNormalGrid/deleteicon.component';
import { TCProject } from '../../models/TCProject';
import { ProjectService } from 'src/app/services/project.service';
import moment from 'moment';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
//import { MMP } from '../../models/MMP';
@Component({
  selector: 'app-projectform',
  templateUrl: './projectform.component.html',
  styleUrls: ['./projectform.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectformComponent implements OnInit {
  public show: boolean = false;
  public projectForm: FormGroup;
  public submitted: boolean = false;
  public toggleValue: boolean = false;
  public dataRecieved: any;
  public optionAvalue: any;
  public optionBvalue: any;
  public optionCvalue: any;
  public workitemId: any;
  public devopsLink: any;
  public showform = false;
  public showWorkItem = false;
  public value: any;
  public devopsWorkitemLink: any;
  public projectIdFromLocal: any;
  public projectTitle: any;
  public solutionProposal: any;
  public situation: any;
  public MMP: any;

  public submitResponse: any;
  public hasUnsavedChanges: boolean = false;
  public isEditable: boolean = true;
  public mmpColumnDefs: any;
  public mmpRowData: any;
  public rowMMPAllData = [];
  public modifiedMMPRows = [];
  public defaultColDef: any;
  public domLayout: any;
  public gridApi: any;
  public gridColApi: any;
  public frameworkComponents: any;
  // Variables used in error handling
  public formerror = [];
  public alertErrorBox = [];
  public errorMessage = {};
  public field = "";
  public validationHeaderMessage: any;
  public optionRemovalMessage: any;

  public projectSubmitterId: any;
  public dataToSend = new TCProject();
  //public MeasureStatusId = 1;
  //Status
  status: any = [];
  public gridDataToSend: any = [];
  public milestoneSend: any = [];
  // TFS LINK:--
  public ekSlideValue: any = "";

  // PEOPLE PICKER Variables:--
  // submitter input search field;
  public searchInputSubmitter = new FormControl();
  //  financial controller input search field;
  public searchInputFinancialController = new FormControl();
  // product manager input search field;
  public searchInputProductManager = new FormControl();
  // service manager input search field;
  public searchInputServiceManager = new FormControl();
  // project manager input search field;
  public searchInputProjectManager = new FormControl();
  // procurement engineer input search field;
  public searchInputProcurementEngineer = new FormControl();

  // submitter people picker form variable;
  public submitterPeoplePickr: FormGroup;
  // financialcontroller people picker form variable;
  public financialControllerPeoplePickr: FormGroup;
  // productManager people picker form variable;
  public productManagerPeoplePickr: FormGroup;
  // serviceManager people picker form variable;
  public serviceManagerPeoplePickr: FormGroup;
  // projectManager people picker form variable;
  public projectManagerPeoplePickr: FormGroup;
  // procurementEngineer people picker form variable;
  public procurementEngineerPeoplePickr: FormGroup;

  public approvalProcurementEngineer: FormGroup;

  // this variable is for the submitterId and ResponsibleId;
  public submitterId: any;
  public financialControllerId: any;
  public productManagerId: any;
  public serviceManagerId: any;
  public projectManagerId: any;
  public procurementEngineerId: any;

  // these variable will contain the searched user data for perticular formField;
  public submitterSearchData: any = [];
  public financialControllerSearchData: any = [];
  public productManagerSearchData: any = [];
  public serviceManagerSearchData: any = [];
  public projectManagerSearchData: any = [];
  public procurementEngineerSearchData: any = [];
  public modifiedTreeData: any = [];
  public ImProdIdSelData = [];
  // this variable will contain all the users data from tbl_User of DB;
  public allUserList: any;
  // used for the autofocus in the input field for the people picker;
  public oldDataSubmitter: any;
  public oldDataFC: any;
  public oldDataProductM: any;
  public oldDataServiceM: any;
  public oldDataProjectM: any;
  public oldDataPE: any;
  public allActInActUser: any;
  //DNAremark: any;
  //DNAremark: string = '';

  // approvalProcurementEngineer = false;
  // approvalFinancialController=false;
  // approvalProductManager=false;
  // approvalServiceManager= false;
  // approvalProjectManager=false;
  public businessAreaId: number;
  public businessLineId: number;
  public userEmail: any;
  @ViewChild('myInputSub') myInputFieldSub: ElementRef;
  @ViewChild('myInputFC') myInputFieldFC: ElementRef;
  @ViewChild('myInputProductM') myInputFieldProductM: ElementRef;
  @ViewChild('myInputServiceM') myInputFieldServiceM: ElementRef;
  @ViewChild('myInputProjectM') myInputFieldProjectM: ElementRef;
  @ViewChild('myInputPE') myInputFieldPE: ElementRef;
  public projectId: any;
  public DNA: any;
  //public overallProjectStatus:any;
  public ProjectMMP: any;
  public ProjectMilestones: any;


  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public tempRoleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public tempRoleBusinessLine = [];
  loginUserDetails = [];
  public userInOrg: any;
  M120: boolean = false;
  M150: boolean = false;
  M200: boolean = false;
  M300: boolean = false;
  enableToggle = false;
  public copiedConfigurationData: any;

  constructor(
    private cd: ChangeDetectorRef,
    private toast: ToasterService,
    private router: Router,
    private sharedService: SharedServiceService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private menuService: MenuService,
    // service for the UserList;
    private userService: UserInfoService,
    private masterService: MasterdataService,
    private userReplacementSer: UserReplacementService,
    private _ideaservice: IdeaformService,
    private catService: CategorizationService,
    private projectService: ProjectService,
    private _configurationService: ConfigurationService,
  ) {
    this.frameworkComponents = { agCheckboxCellRenderer: AgCheckbox }
  }

  ngOnInit(): void {
    // creating a form for the submitter people picker;
    this.submitterPeoplePickr = this.formBuilder.group({
      submitterPeoplePickr: [null, Validators.required]
    });
    // creating a form for the financialController people picker;
    this.financialControllerPeoplePickr = this.formBuilder.group({
      financialControllerPeoplePickr: [null, Validators.required]
    });
    // creating a form for the productManager people picker;
    this.productManagerPeoplePickr = this.formBuilder.group({
      productManagerPeoplePickr: [null, Validators.required]
    });
    // creating a form for the serviceManager people picker;
    this.serviceManagerPeoplePickr = this.formBuilder.group({
      serviceManagerPeoplePickr: [null, '']
    });
    // creating a form for the projectManager people picker;
    this.projectManagerPeoplePickr = this.formBuilder.group({
      projectManagerPeoplePickr: [null, '']
    });
    // creating a form for the procurementEngineer people picker;
    this.procurementEngineerPeoplePickr = this.formBuilder.group({
      procurementEngineerPeoplePickr: [null, '']
    });

    this.projectForm = this.formBuilder.group({
      // approvalProcurementEngineer: [{
      //   value: false, disabled: true
      // }]
      approvalProcurementEngineer: [false]
    })

    this.sharedService.projectSubmitterId.subscribe(
      (id) => {
        this.projectSubmitterId = id;
      }
    )

    this.sharedService.projectMMPgDeletedRows.subscribe(data => {
      this.hasUnsavedChanges = true;
      if (data.mmpId != 0) {
        data.IsActive = false;
        let isPushed = false;
        if (this.modifiedMMPRows.length == 0) {
          this.modifiedMMPRows.push(data);
          isPushed = true;
        }
        else {
          this.modifiedMMPRows.forEach(row => {
            if (row.mmpId == data.mmpId) {
              isPushed = true;
            }
          }
          )
        }
        if (!isPushed) {
          this.modifiedMMPRows.push(data);
        }
        this.onRowClicked("")
      }
    })
    this.sharedService.getProjectIdAndTitle();
    //Get project id
    this.projectId = this.localStorageService.get('projectId');
    this.validationHeaderMessage = messages.cellEmptyErrorMesssage;

    this.loadFormStructure();
    this.loadGrid();
  }
  //   postChangeToServer() {
  //     //Do your POST call here and handle the negative response with the line below
  //  debugger;
  //       this.approvalProcurementEngineer = !this.approvalProcurementEngineer;
  //   this.approvalFinancialController=!this.approvalFinancialController;
  //   this.approvalProductManager=!this.approvalProductManager;
  //   this.approvalServiceManager=!this.approvalServiceManager;
  //   this.approvalProjectManager=!this.approvalProjectManager;
  //   }

  loadFormStructure() {
    this.projectForm = this.formBuilder.group({
      submitter: ['', [Validators.required, validateSpaceOnly]],
      orgCodeSub: ['', [Validators.required, validateSpaceOnly]],
      FinancialController: ['', [Validators.required, validateSpaceOnly]],
      orgCodeFC: ['', [Validators.required, validateSpaceOnly]],
      productManager: ['', [Validators.required, validateSpaceOnly]],
      orgCodeProductM: ['', [Validators.required, validateSpaceOnly]],
      serviceManager: [''],
      orgCodeServiceM: ['', [Validators.required, validateSpaceOnly]],
      projectManager: [''],
      orgCodeProjectM: ['', [Validators.required, validateSpaceOnly]],
      procurementEngineer: [''],
      orgCodePE: ['', [Validators.required, validateSpaceOnly]],
      title: ['', [Validators.required, validateSpaceOnly]],
      DNA: ['', [Validators.required, validateSpaceOnly]],
      toggleValue: '',
      businessAreaName: ['', [Validators.required, validateSpaceOnly]],
      businessLineName: ['', [Validators.required, validateSpaceOnly]],
      approvalFinancialController: false,
      approvalProductManager: false,
      approvalServiceManager: false,
      approvalProjectManager: false,
      approvalProcurementEngineer: false,
      dateM120: [''],
      dateM150: [''],
      dateM200: [''],
      dateM300: [''],
      achievedM120: false,
      achievedM150: false,
      achievedM200: false,
      achievedM300: false,
      EKSlideM120: [''],
      EKSlideM150: [''],
      EKSlideM200: [''],
      EKSlideM300: [''],
      MeasureStatusId: ['', [Validators.required]],
    });
    this.loadFormData();
  }

  loadFormData() {
    debugger;
    this.projectIdFromLocal = this.localStorageService.get('projectId');
    if (this.projectIdFromLocal == null) {
      this.sharedService.loggedInUser.subscribe((u: any) => {
        if (u) {
          this._ideaservice.getUserInfo(u.GID).subscribe((user: any) => {
            user = user.Data;
            this.loginUserDetails.push(user);

            this.roleBusinessArea = user.BusinessArea;
            this.roleAllBusinessLine = user.BusinessLine;
            this.businessAreaId = user.BusinessAreaId;
            this.businessLineId = user.BusinessLineId;
            this.userEmail = user.Email;
            this.roleBusinessArea = this.roleBusinessArea.filter((v, i, a) => a.findIndex(v2 => (v2.BusinessAreaId === v.BusinessAreaId)) === i);

            if (this.roleBusinessArea.length == 1) {
              this.projectForm.controls['businessAreaName'].setValue(this.roleBusinessArea[0].BusinessAreaId.toString());
              this.roleBusinessLine = this.roleAllBusinessLine;
              this.projectForm.controls['businessAreaName'].disable();
            }
            if (this.roleAllBusinessLine.length == 1) {
              this.projectForm.controls['businessLineName'].setValue(this.roleAllBusinessLine[0].BusinessLineId.toString());
              this.projectForm.controls['businessLineName'].disable();
            }
            this.projectForm.patchValue({
              submitter: user.Email,
              orgCodeSub: user.OrgCode,
            });
            //These function will patch the value to the people picker fields;
            this.fetchUserData(user.Email, true, "submitter");
            this.fetchUserData(user.Email, false, "Financialcontroller");
            this.fetchUserData(user.Email, false, "ProductManager");
            this.fetchUserData(user.Email, false, "servicemanager");
            this.fetchUserData(user.Email, false, "projectmanager");
            this.fetchUserData(user.Email, false, "ProcurementEngineer");

            //this.fetchUserData(user.Email, true, "both");
            // Admin and Power User are able to edit the "Submitter" field;
            // Admin, Power User and Normal User all are able to edit "Responsible" field;
            // "Sub org code" and "Res org code" fields are disabled, as it is automatically;

            //new implementation.
            if (this.projectForm.controls["businessAreaName"].value != "" && this.projectForm.controls["businessLineName"].value != "") {
              if (user.TargetRoleId == 3) {
                this.submitterPeoplePickr.get("submitterPeoplePickr").disable();
                this.projectForm.get("orgCodeSub").disable();
              } else {
                this.projectForm.get("orgCodeSub").disable();
              }
            } else {
              this.submitterPeoplePickr.get("submitterPeoplePickr").disable();
              this.projectForm.get("orgCodeSub").disable();
              this.financialControllerPeoplePickr.controls["financialControllerPeoplePickr"].disable();
              this.productManagerPeoplePickr.controls["productManagerPeoplePickr"].disable();
              this.serviceManagerPeoplePickr.controls["serviceManagerPeoplePickr"].disable();
              this.projectManagerPeoplePickr.controls["projectManagerPeoplePickr"].disable();
              this.procurementEngineerPeoplePickr.controls["procurementEngineerPeoplePickr"].disable();
            }
            this.getMeasureStatus();
            this.projectForm.get("MeasureStatusId").setValue(1);
          });
        }
      })
      this.showform = true;
      this.showWorkItem = false;
      this.projectForm.valueChanges.subscribe(e => { this.hasUnsavedChanges = this.projectForm.dirty; });
      //To disable the approvals at the time of creating project
      this.projectForm.get("approvalProjectManager").disable();
      this.projectForm.get("approvalFinancialController").disable();
      this.projectForm.get("approvalServiceManager").disable();
      this.projectForm.get("approvalProcurementEngineer").disable();
      this.projectForm.get("approvalProductManager").disable();


      //To check if milestone is updated
      this.projectForm.get("dateM120").valueChanges.subscribe(x => {
        this.M120 = true;
      })
      this.projectForm.get("achievedM120").valueChanges.subscribe(x => {
        this.M120 = true;
      })
      this.projectForm.get("EKSlideM120").valueChanges.subscribe(x => {
        this.M120 = true;
      })

      this.projectForm.get("dateM150").valueChanges.subscribe(x => {
        this.M150 = true;
      })
      this.projectForm.get("achievedM150").valueChanges.subscribe(x => {
        this.M150 = true;
      })
      this.projectForm.get("EKSlideM150").valueChanges.subscribe(x => {
        this.M150 = true;
      })

      this.projectForm.get("dateM200").valueChanges.subscribe(x => {
        this.M200 = true;
      })
      this.projectForm.get("achievedM200").valueChanges.subscribe(x => {
        this.M200 = true;
      })
      this.projectForm.get("EKSlideM200").valueChanges.subscribe(x => {
        this.M200 = true;
      })

      this.projectForm.get("dateM300").valueChanges.subscribe(x => {
        this.M300 = true;
      })
      this.projectForm.get("achievedM300").valueChanges.subscribe(x => {
        this.M300 = true;
      })
      this.projectForm.get("EKSlideM300").valueChanges.subscribe(x => {
        this.M300 = true;
      })
    }
    else {
      this.masterService.getDatabyMasterTypeId("17", 0, 0).subscribe((data) => {
        this.tempRoleBusinessArea = data.Data;
        this.roleBusinessArea = this.tempRoleBusinessArea.map(item => {
          return {
            BusinessAreaId: item.Id,
            BusinessAreaName: item.BusinessArea,
          };
        });
      })
      this.masterService.getDatabyMasterTypeId("18", 0, 0).subscribe((data) => {
        this.tempRoleBusinessLine = data.Data;
        this.roleBusinessLine = this.tempRoleBusinessLine.map(item => {
          return {
            BusinessLineId: item.Id,
            BusinessLineName: item.BusinessLine,
            BusinessAreaId: item.BusinessAreaId
          };
        });
      })

      // making the service call;
      this.projectService.getTCData(this.projectIdFromLocal).subscribe((data: any) => {
        let errorMsgeFromBack = data.Data.Message;
        if (data.StatusCode == 200) {
          // this.showWorkItem = true;
          this.hasUnsavedChanges = false;
          this.dataRecieved = data;
          this.dataRecieved = this.dataRecieved.Data;
          this.projectId = this.dataRecieved.ProjectId;
          this.DNA = this.dataRecieved.DNA;

          this.businessAreaId = this.dataRecieved.BusinessAreaId;
          this.businessLineId = this.dataRecieved.BusinessLineId;
          this.getMeasureStatus();

          this.ProjectMMP = this.dataRecieved.ProjectMMP;
          this.ProjectMilestones = this.dataRecieved.ProjectMilestones;

          for (let i of this.ProjectMMP) {
            let rowDataMmp = {};
            rowDataMmp["mmpId"] = i.TitleId
            rowDataMmp["mmpTitle"] = i.Title;
            rowDataMmp["IsChecked"] = i.MMPIsActive;
            rowDataMmp["IsActive"] = i.IsActive;
            rowDataMmp["IsEditable"] = this.isEditable;
            this.rowMMPAllData.push(rowDataMmp);
          }
          let selectedProduct = [];
          for (let i of this.ProjectMilestones) {
            //   selectedProduct.push({ id: i.MilestoneID });
            if (i.MilestoneID == 1) {
              this.projectForm.controls["dateM120"].setValue(i.Milestonedate);
              this.projectForm.controls["achievedM120"].setValue(i.IsAchieved);
              this.projectForm.controls["EKSlideM120"].setValue(i.EKSlides);
              //this.projectForm.controls["isactive"].setValue(i.EKSlides);
            }
            if (i.MilestoneID == 2) {
              this.projectForm.controls["dateM150"].setValue(i.Milestonedate);
              this.projectForm.controls["achievedM150"].setValue(i.IsAchieved);
              this.projectForm.controls["EKSlideM150"].setValue(i.EKSlides);
            }
            if (i.MilestoneID == 4) {
              this.projectForm.controls["dateM200"].setValue(i.Milestonedate);
              this.projectForm.controls["achievedM200"].setValue(i.IsAchieved);
              this.projectForm.controls["EKSlideM200"].setValue(i.EKSlides);
            }
            if (i.MilestoneID == 5) {
              this.projectForm.controls["dateM300"].setValue(i.Milestonedate);
              this.projectForm.controls["achievedM300"].setValue(i.IsAchieved);
              this.projectForm.controls["EKSlideM300"].setValue(i.EKSlides);
            }
          }
          this.projectForm.controls["businessAreaName"].setValue(this.dataRecieved.BusinessAreaId.toString());
          this.projectForm.controls['businessAreaName'].disable();
          this.projectForm.controls["businessLineName"].setValue(this.dataRecieved.BusinessLineId.toString());
          this.projectForm.controls['businessLineName'].disable();

          // this will fill the submitter and responsible field;
          this.fetchUserData(this.dataRecieved.Submitter, true, 'submitter');

          if (this.dataRecieved.FinancialController != "")
            this.fetchUserData(this.dataRecieved.FinancialController, true, 'Financialcontroller');
          else
            this.fetchUserData(this.dataRecieved.Submitter, false, 'Financialcontroller');

          if (this.dataRecieved.ProductManager != "")
            this.fetchUserData(this.dataRecieved.ProductManager, true, 'ProductManager');

          else
            this.fetchUserData(this.dataRecieved.Submitter, false, 'ProductManager')

          if (this.dataRecieved.ServiceManager != "")
            this.fetchUserData(this.dataRecieved.ServiceManager, true, 'servicemanager');
          else
            this.fetchUserData(this.dataRecieved.Submitter, false, 'servicemanager')

          if (this.dataRecieved.ProjectManager != "")
            this.fetchUserData(this.dataRecieved.ProjectManager, true, 'projectmanager');
          else
            this.fetchUserData(this.dataRecieved.Submitter, false, 'projectmanager')

          if (this.dataRecieved.ProcurementEngineer != "")
            this.fetchUserData(this.dataRecieved.ProcurementEngineer, true, 'ProcurementEngineer');
          else
            this.fetchUserData(this.dataRecieved.Submitter, false, 'ProcurementEngineer')

          // attaching the incoming data to ideaform;
          this.projectForm.patchValue({
            submitter: this.dataRecieved.Submitter,
            orgCodeSub: this.dataRecieved.SubmitterOrgCode,
            Financialcontroller: this.dataRecieved.FinancialController,
            orgCodeFC: this.dataRecieved.FinancialControllerOrgCode,
            approvalFinancialController: this.dataRecieved.IsFCApproved,
            ProductManager: this.dataRecieved.ProductManager,
            orgCodeProductM: this.dataRecieved.ProductManagerOrgCode,
            approvalProductManager: this.dataRecieved.IsPDMApproved,
            ServiceManager: this.dataRecieved.ServiceManager,
            orgCodeServiceM: this.dataRecieved.ServiceManagerOrgCode,
            approvalServiceManager: this.dataRecieved.IsSMApproved,
            ProjectManager: this.dataRecieved.ProjectManager,
            orgCodeProjectM: this.dataRecieved.ProjectManagerOrgCode,
            approvalProjectManager: this.dataRecieved.IsPMApproved,
            ProcurementEngineer: this.dataRecieved.ProcurementEngineer,
            orgCodePE: this.dataRecieved.ProcurementEngineerOrgCode,
            approvalProcurementEngineer: this.dataRecieved.IsPEApproved,
            title: this.dataRecieved.ProjectTitle,
            DNA: this.dataRecieved.DNA,
            MeasureStatusId: parseInt(this.dataRecieved.MeasureStatusId),
            businessAreaName: this.dataRecieved.BusinessAreaId.toString(),
            businessLineName: this.dataRecieved.BusinessLineId.toString(),

          })
          this.loadSharedValue();
          this.projectForm.valueChanges.subscribe(e => { this.hasUnsavedChanges = this.projectForm.dirty; });

          //To check if milestone is updated
          this.projectForm.get("dateM120").valueChanges.subscribe(x => {
            this.M120 = true;
          })
          this.projectForm.get("achievedM120").valueChanges.subscribe(x => {
            this.M120 = true;
          })
          this.projectForm.get("EKSlideM120").valueChanges.subscribe(x => {
            this.M120 = true;
          })

          this.projectForm.get("dateM150").valueChanges.subscribe(x => {
            this.M150 = true;
          })
          this.projectForm.get("achievedM150").valueChanges.subscribe(x => {
            this.M150 = true;
          })
          this.projectForm.get("EKSlideM150").valueChanges.subscribe(x => {
            this.M150 = true;
          })

          this.projectForm.get("dateM200").valueChanges.subscribe(x => {
            this.M200 = true;
          })
          this.projectForm.get("achievedM200").valueChanges.subscribe(x => {
            this.M200 = true;
          })
          this.projectForm.get("EKSlideM200").valueChanges.subscribe(x => {
            this.M200 = true;
          })

          this.projectForm.get("dateM300").valueChanges.subscribe(x => {
            this.M300 = true;
          })
          this.projectForm.get("achievedM300").valueChanges.subscribe(x => {
            this.M300 = true;
          })
          this.projectForm.get("EKSlideM300").valueChanges.subscribe(x => {
            this.M300 = true;
          })
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      },
        (error) => {
          //Show errorCode and errorMessage in the UI
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        }
      )
    }
  }
  // it will perform changes in shared service;
  loadSharedValue() {

    this.sharedService.loggedInUser.subscribe((user: any) => {

      // Admin and Power User are able to edit the "Submitter" field;
      // Admin, Power User and Normal User all are able to edit "Responsible" field;
      // "Sub org code" and "Res org code" fields are disabled, as it is automatically;
      this.userService.getUserList(Number(this.projectForm.controls["businessAreaName"].value), Number(this.projectForm.controls["businessLineName"].value)).subscribe((d: any) => {
        this.allUserList = d;
        this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
        if (this.userInOrg != undefined) {

          if (this.dataRecieved.ProductManager != this.userInOrg.Email) {
            //this.enableToggle = true;
            this.projectForm.get("approvalProductManager").disable();
          }
          else if (this.dataRecieved.ProductManager == this.userInOrg.Email) {
            this.projectForm.get("approvalProductManager").enable();
          }
          if (this.dataRecieved.FinancialController != this.userInOrg.Email) {
            this.projectForm.get("approvalFinancialController").disable();
          }
          else if (this.dataRecieved.FinancialController == this.userInOrg.Email) {
            this.projectForm.get("approvalFinancialController").enable();
          }
          if (this.dataRecieved.ProjectManager != this.userInOrg.Email) {
            this.projectForm.get("approvalProjectManager").disable();
          }
          else if (this.dataRecieved.ProjectManager == this.userInOrg.Email) {
            this.projectForm.get("approvalProjectManager").enable();
          }
          if (this.dataRecieved.ServiceManager != this.userInOrg.Email) {
            this.projectForm.get("approvalServiceManager").disable();
          }
          else if (this.dataRecieved.ServiceManager == this.userInOrg.Email) {
            this.projectForm.get("approvalServiceManager").enable();
          }

          if (this.dataRecieved.ProcurementEngineer != this.userInOrg.Email) {
            this.projectForm.get("approvalProcurementEngineer").disable();
          }
          else if (this.dataRecieved.ProcurementEngineer == this.userInOrg.Email) {
            this.projectForm.get("approvalProcurementEngineer").enable();
          }
          if (this.userInOrg.TargetRoleId == 3) {
            this.submitterPeoplePickr.get("submitterPeoplePickr").disable();
            this.projectForm.get("orgCodeSub").disable();
            //this.financialControllerPeoplePickr.get("financialControllerPeoplePickr").disable();
            //this.projectForm.get("orgCodeFC").disable();
            //    this.projectForm.get("orgCodeResp").disable();
          } else {
            this.projectForm.get("orgCodeSub").disable();
            //     this.projectForm.get("orgCodeFC").disable();
          }

          if (this.userInOrg.TargetRoleId == 1 || this.userInOrg.TargetRoleId == 2) {
            this.isEditable = true;
          }
          else if (this.userInOrg.TargetRoleId == 3) {
            if (this.submitterId == this.userInOrg.UserId || this.financialControllerId == this.userInOrg.UserId
              || this.productManagerId == this.userInOrg.UserId || this.projectManagerId == this.userInOrg.UserId ||
              this.serviceManagerId == this.userInOrg.UserId || this.procurementEngineerId == this.userInOrg.UserId
            ) {
              this.isEditable = true;
            }
            else {
              this.isEditable = false;
              this.projectForm.disable();
              this.submitterPeoplePickr.disable();
              this.financialControllerPeoplePickr.disable();
              this.serviceManagerPeoplePickr.disable();
              this.projectManagerPeoplePickr.disable();
              this.procurementEngineerPeoplePickr.disable();
              this.productManagerPeoplePickr.disable();
            }
          }
          this.rowMMPAllData.forEach(mmp => {
            mmp["IsEditable"] = this.isEditable;
          })
          this.mmpRowData = this.rowMMPAllData;
        }
      })
    })
    this.showform = true;
  }
  updateBusinessArea() {
    this.projectForm.controls['businessLineName'].setValue("");
    this.submitterPeoplePickr.controls["submitterPeoplePickr"].disable();
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.projectForm.controls['businessAreaName'].value)
    );
  }

  updateBusinessLine() {
    if (this.projectForm.controls["businessAreaName"].value != "" && this.projectForm.controls["businessLineName"].value != "") {
      // this.getMeasureStatus();

      this.userService.getUserList(Number(this.projectForm.controls["businessAreaName"].value), Number(this.projectForm.controls["businessLineName"].value)).subscribe((d: any) => {
        this.allUserList = d;
        this.userInOrg = this.allUserList.find(u => u.Email == this.projectForm.controls["submitter"].value);
        if (this.userInOrg != undefined) {
          let userDetail = [];
          userDetail.push(this.userInOrg);
          this.submitterPeoplePickr.get("submitterPeoplePickr").setValue(userDetail[0]);
          this.submitterId = this.submitterPeoplePickr.get("submitterPeoplePickr").value.UserId;
          this.submitterSearchData = userDetail;
          if (this.userInOrg.TargetRoleId == 3) {
            //this.responsiblePeoplePickr.get("responsiblePeoplePickr").enable();
          } else {
            this.submitterPeoplePickr.controls["submitterPeoplePickr"].enable();
            //this.responsiblePeoplePickr.controls["responsiblePeoplePickr"].enable();
          }
          this.financialControllerPeoplePickr.controls["financialControllerPeoplePickr"].enable();
          this.productManagerPeoplePickr.controls["productManagerPeoplePickr"].enable();
          this.serviceManagerPeoplePickr.controls["serviceManagerPeoplePickr"].enable();
          this.projectManagerPeoplePickr.controls["projectManagerPeoplePickr"].enable();
          this.procurementEngineerPeoplePickr.controls["procurementEngineerPeoplePickr"].enable();
        }
      })
    } else {
      this.submitterPeoplePickr.controls["submitterPeoplePickr"].disable();
    }
  }

  getMeasureStatus() {
    this.catService.getMeasureStatus(this.businessAreaId, this.businessLineId).subscribe((measureStatus: any) => {
      let errorMsgeFromBack = measureStatus.Data.Message;
      if (measureStatus.StatusCode == 200) {
        this.status = measureStatus;
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }

  // It will bind the data to the submitter and responsible field on page load;
  bindValueToPeopleFields(bind, formField, userDetail) {

    if (formField == 'submitter') {
      this.oldDataSubmitter = userDetail[0];
      if (bind) {
        this.submitterPeoplePickr.get("submitterPeoplePickr").setValue(userDetail[0]);
        this.submitterId = this.submitterPeoplePickr.get("submitterPeoplePickr").value.UserId;
      }
    } else if (formField == 'Financialcontroller') {
      this.oldDataFC = userDetail[0];
      if (bind) {
        this.financialControllerPeoplePickr.get("financialControllerPeoplePickr").setValue(userDetail[0]);
        this.financialControllerId = this.financialControllerPeoplePickr.get("financialControllerPeoplePickr").value.UserId;
      }
    } else if (formField == 'ProductManager') {
      this.oldDataProductM = userDetail[0];
      if (bind) {
        this.productManagerPeoplePickr.get("productManagerPeoplePickr").setValue(userDetail[0]);
        this.productManagerId = this.productManagerPeoplePickr.get("productManagerPeoplePickr").value.UserId;
      }
    } else if (formField == 'servicemanager') {
      this.oldDataServiceM = userDetail[0];
      if (bind) {
        this.serviceManagerPeoplePickr.get("serviceManagerPeoplePickr").setValue(userDetail[0]);
        this.serviceManagerId = this.serviceManagerPeoplePickr.get("serviceManagerPeoplePickr").value.UserId;
      }
    } else if (formField == 'projectmanager') {
      this.oldDataProjectM = userDetail[0];
      if (bind) {
        this.projectManagerPeoplePickr.get("projectManagerPeoplePickr").setValue(userDetail[0]);
        this.projectManagerId = this.projectManagerPeoplePickr.get("projectManagerPeoplePickr").value.UserId;
      }
    } else if (formField == 'ProcurementEngineer') {
      this.oldDataPE = userDetail[0];
      if (bind) {
        this.procurementEngineerPeoplePickr.get("procurementEngineerPeoplePickr").setValue(userDetail[0]);
        this.procurementEngineerId = this.procurementEngineerPeoplePickr.get("procurementEngineerPeoplePickr").value.UserId;
      }
    }
  }

  // People Picker Functions;
  fetchUserData(input, bind, formField) {
    let searchInput: string = "";
    // bind == false; it means function is called from the HTML;
    // bind == true; it means function is called from the Class;
    if (input.target != undefined) {
      searchInput = input.target.value.toLowerCase();
    } else {
      searchInput = input.toLowerCase();
    }

    if (searchInput.length >= 4) {
      let constString = ideaPageConstants.deletedUser;
      if (searchInput.includes(constString) && bind) {
        if (this.allActInActUser == undefined) {
          this.userReplacementSer.getUserList(Number(this.projectForm.controls['businessAreaName'].value), Number(this.projectForm.controls['businessLineName'].value)).subscribe((data) => {
            this.allActInActUser = data;
            let userDetail = [];
            userDetail = this.searchUsrInUsrlst(this.allActInActUser, searchInput);
            this.bindValueToPeopleFields(bind, formField, userDetail);
            this.fillSearchValue(formField, bind, userDetail);
          })
        } else {
          let userDetail = [];
          userDetail = this.searchUsrInUsrlst(this.allActInActUser, searchInput);
          this.bindValueToPeopleFields(bind, formField, userDetail);
          this.fillSearchValue(formField, bind, userDetail);
        }
      }
      else {
        if (this.allUserList == undefined) {
          if (this.projectForm.controls['businessAreaName'].value != "" && this.projectForm.controls['businessLineName'].value != "") {
            this.userService.getUserList(Number(this.projectForm.controls['businessAreaName'].value), Number(this.projectForm.controls['businessLineName'].value)).subscribe((data) => {
              this.allUserList = data;
              let userDetail = [];
              userDetail = this.searchUsrInUsrlst(this.allUserList, searchInput);
              this.bindValueToPeopleFields(bind, formField, userDetail);
              this.fillSearchValue(formField, bind, userDetail);
            })

          } else {
            this.bindValueToPeopleFields(bind, formField, this.loginUserDetails)
            this.fillSearchValue(formField, bind, this.loginUserDetails);
          }
        } else {
          let userDetail = [];
          userDetail = this.searchUsrInUsrlst(this.allUserList, searchInput);
          this.bindValueToPeopleFields(bind, formField, userDetail);
          this.fillSearchValue(formField, bind, userDetail);
        }
      }
    }
    else {
      if (formField == "submitter") {
        this.submitterSearchData = [this.oldDataSubmitter];
        this.submitterPeoplePickr.get("submitterPeoplePickr").setValue(this.oldDataSubmitter);
      }
      if (formField == "ProductManager") {
        this.productManagerSearchData = [this.oldDataProductM];
        this.productManagerPeoplePickr.get("productManagerPeoplePickr").setValue(this.oldDataProductM);
      }
      if (formField == "Financialcontroller") {
        this.financialControllerSearchData = [this.oldDataFC];
        this.financialControllerPeoplePickr.get("financialControllerPeoplePickr").setValue(this.oldDataFC);
      }
      else if (formField == "servicemanager") {
        this.serviceManagerSearchData = [this.oldDataServiceM];
        this.serviceManagerPeoplePickr.get("serviceManagerPeoplePickr").setValue(this.oldDataServiceM);
      }
      else if (formField == "projectmanager") {
        this.projectManagerSearchData = [this.oldDataProjectM];
        this.projectManagerPeoplePickr.get("projectManagerPeoplePickr").setValue(this.oldDataProjectM);
      }
      else if (formField == "ProcurementEngineer") {
        this.procurementEngineerSearchData = [this.oldDataPE];
        this.procurementEngineerPeoplePickr.get("procurementEngineerPeoplePickr").setValue(this.oldDataPE);
      }
    }

  }

  // this will fill the array with search data if found and set a value to null in the start of the search;
  fillSearchValue(formField, bind, userDetail) {
    if (formField == "submitter") {
      this.submitterSearchData = userDetail;
      if (!bind) {
        this.submitterPeoplePickr.get("submitterPeoplePickr").setValue("");
        this.hasUnsavedChanges = true;
      }
    }
    else if (formField == "ProductManager") {
      this.productManagerSearchData = userDetail;
      if (!bind) {
        this.productManagerPeoplePickr.get("productManagerPeoplePickr").setValue("");
        this.hasUnsavedChanges = true;
      }
    }
    else if (formField == "Financialcontroller") {
      this.financialControllerSearchData = userDetail;
      if (!bind) {
        this.financialControllerPeoplePickr.get("financialControllerPeoplePickr").setValue("");
        this.hasUnsavedChanges = true;
      }
    }
    else if (formField == "servicemanager") {
      this.serviceManagerSearchData = userDetail;
      if (!bind) {
        this.serviceManagerPeoplePickr.get("serviceManagerPeoplePickr").setValue("");
        this.hasUnsavedChanges = true;
      }
    }
    else if (formField == "projectmanager") {
      this.projectManagerSearchData = userDetail;
      if (!bind) {
        this.projectManagerPeoplePickr.get("projectManagerPeoplePickr").setValue("");
        this.hasUnsavedChanges = true;
      }
    }
    else if (formField == "ProcurementEngineer") {
      this.procurementEngineerSearchData = userDetail;
      if (!bind) {
        this.procurementEngineerPeoplePickr.get("procurementEngineerPeoplePickr").setValue("");
        this.hasUnsavedChanges = true;
      }
    }
  }

  // It will run a search operation in the userlist recieved from DB and return the user Information;
  searchUsrInUsrlst(userList, searchValue) {
    let userDetail = []
    userList.filter(data => {
      if (data.Email.toLowerCase().includes(searchValue)) {
        userDetail.push({
          Email: data.Email,
          FirstName: data.FirstName,
          FullName: data.FullName,
          GID: data.GID,
          IsActive: data.IsActive,
          LastName: data.LastName,
          OrgCode: data.OrgCode,
          RoleId: data.RoleId,
          RoleTitle: data.RoleTitle,
          UserDetails: data.UserDetails,
          UserId: data.UserId
        });
      }
    });
    return userDetail;
  }


  // It will clear the search input field once the dropdown is closed;
  clearSearch(formField) {
    if (formField == "submitter") {
      this.submitterSearchData = [this.oldDataSubmitter];
      this.submitterPeoplePickr.get("submitterPeoplePickr").setValue(this.oldDataSubmitter);
      this.searchInputSubmitter.setValue("");
    }
    if (formField == "ProductManager") {
      this.productManagerSearchData = [this.oldDataProductM];
      this.productManagerPeoplePickr.get("productManagerPeoplePickr").setValue(this.oldDataProductM);
      this.searchInputProductManager.setValue("");
    }
    if (formField == "Financialcontroller") {
      this.financialControllerSearchData = [this.oldDataFC];
      this.financialControllerPeoplePickr.get("financialControllerPeoplePickr").setValue(this.oldDataFC);
      this.searchInputFinancialController.setValue("");
    }
    if (formField == "servicemanager") {
      console.log(this.serviceManagerPeoplePickr.get("serviceManagerPeoplePickr"));
      this.serviceManagerSearchData = [this.oldDataServiceM];
      this.serviceManagerPeoplePickr.get("serviceManagerPeoplePickr").setValue(this.oldDataServiceM);
      this.searchInputServiceManager.setValue("");
    }
    if (formField == "projectmanager") {
      this.projectManagerSearchData = [this.oldDataProjectM];
      this.projectManagerPeoplePickr.get("projectManagerPeoplePickr").setValue(this.oldDataProjectM);
      this.searchInputProjectManager.setValue("");
    }
    if (formField == "ProcurementEngineer") {
      this.procurementEngineerSearchData = [this.oldDataPE];
      this.procurementEngineerPeoplePickr.get("procurementEngineerPeoplePickr").setValue(this.oldDataPE);
      this.searchInputProcurementEngineer.setValue("");
    }
  }

  // It will focus the cursor to the input search field;
  autofocus(field) {
    this.cd.detectChanges()
    if (field == "sub") {
      setInterval(() => this.myInputFieldSub?.nativeElement.focus(), 500);
      // making the focus at the input field at it's opening;
      this.myInputFieldSub.nativeElement.focus();
      document.getElementById("myInputSub")?.focus();
    } else if (field == "fc") {
      setInterval(() => this.myInputFieldFC?.nativeElement.focus(), 500);
      // making the focus at the input field at it's opening;
      this.myInputFieldFC.nativeElement.focus();
    } else if (field == "productM") {
      setInterval(() => this.myInputFieldProductM?.nativeElement.focus(), 500);
      // making the focus at the input field at it's opening;
      this.myInputFieldProductM.nativeElement.focus();
    } else if (field == "serviceM") {
      setInterval(() => this.myInputFieldServiceM?.nativeElement.focus(), 500);
      // making the focus at the input field at it's opening;
      this.myInputFieldServiceM.nativeElement.focus();
    } else if (field == "projectM") {
      setInterval(() => this.myInputFieldProjectM?.nativeElement.focus(), 500);
      // making the focus at the input field at it's opening;
      this.myInputFieldProjectM.nativeElement.focus();
    } else if (field == "pe") {
      setInterval(() => this.myInputFieldPE?.nativeElement.focus(), 500);
      // making the focus at the input field at it's opening;
      this.myInputFieldPE.nativeElement.focus();
    }
  }

  // It will assign the ID to submitter and responsible;
  assignValueId(userDetail, formField) {
    debugger;
    if (formField == "submitter") {
      this.oldDataSubmitter = userDetail;
      this.submitterId = userDetail.UserId;
      this.projectForm.patchValue({
        orgCodeSub: userDetail.OrgCode
      })
      this.hasUnsavedChanges = true;
    }
    if (formField == "Financialcontroller") {
      this.oldDataFC = userDetail;
      this.financialControllerId = userDetail.UserId;
      this.projectForm.patchValue({
        orgCodeFC: userDetail.OrgCode
      })
      this.hasUnsavedChanges = true;
    }
    if (formField == "ProductManager") {
      this.oldDataProductM = userDetail;
      this.productManagerId = userDetail.UserId;
      this.projectForm.patchValue({
        orgCodeProductM: userDetail.OrgCode
      })
      this.hasUnsavedChanges = true;
    }
    if (formField == "servicemanager") {
      this.oldDataServiceM = userDetail;
      this.serviceManagerId = userDetail.UserId;
      this.projectForm.patchValue({
        orgCodeServiceM: userDetail.OrgCode
      })
      this.hasUnsavedChanges = true;
    }
    if (formField == "projectmanager") {
      this.oldDataProjectM = userDetail;
      this.projectManagerId = userDetail.UserId;
      this.projectForm.patchValue({
        orgCodeProjectM: userDetail.OrgCode
      })
      this.hasUnsavedChanges = true;
    }
    if (formField == "ProcurementEngineer") {
      this.oldDataPE = userDetail;
      this.procurementEngineerId = userDetail.UserId;
      this.projectForm.patchValue({
        orgCodePE: userDetail.OrgCode
      })
      this.hasUnsavedChanges = true;
    }
  }

  // Below Object containing status of error;
  // the field value should be Camel Case and two words separated by '_'.. example - Form_Data_Value;
  errorObject = {
    Submitter: false,
    Financial_Controller: false,
    Product_Manager: false,
    Service_Manager: true,
    Project_Manager: true,
    Procurement_Engineer: true,
    Submitter_Org_Code: false,
    Financial_Controller_Org_Code: false,
    Product_Manager_Org_Code: false,
    Service_Manager_Org_Code: true,
    Project_Manager_Org_Code: true,
    Procurement_Engineer_Org_Code: true,
    Overall_Project_Status: false,
    Title: false,
    DNA: false,
  }

  // Function called everytime whenever there is any error;
  getErrorMessage(message, field, status,) {
    this.formerror = [];
    this.errorObject[field] = JSON.parse(status)
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/tc-manageProject']);
  }

  onClickNext() {
    console.log("hello")
    this.menuService.onClickNextAndBack('next');
  }

  checkMileStoneValidation() {
    let errorMessage = [];
    if (this.projectForm.get("dateM300").value == "") {
      let error = serverMessage.mileStoneError;
      errorMessage.push(error);
    }
    return errorMessage;
  }
  checkMileStoneM120Validation() {
    let errorMessage = [];
    if (this.projectForm.get("dateM120").value == "") {
      let error = serverMessage.mileStone120Error;
      errorMessage.push(error);
    }
    return errorMessage;
  }
  checkMileStoneM150Validation() {
    let errorMessage = [];
    if (this.projectForm.get("dateM150").value == "") {
      let error = serverMessage.mileStone150Error;
      errorMessage.push(error);
    }
    return errorMessage;
  }
  checkMileStoneM200Validation() {
    let errorMessage = [];
    if (this.projectForm.get("dateM200").value == "") {
      let error = serverMessage.mileStone200Error;
      errorMessage.push(error);
    }
    return errorMessage;
  }

  submit(event?: any) {
    if (this.projectId == null && !this.hasUnsavedChanges && event == undefined) {
      return true
    }
    let response = null;
    this.submitted = true
    this.gridApi.stopEditing(true);

    for (let i in this.errorObject) {
      if (!this.errorObject[i]) {
        this.formerror.push({ [i]: this.validationHeaderMessage })
      }
    }

    let finalGridData = this.modifiedMMPRows;

    if (this.gridApi.getDisplayedRowCount() == 0) {
      this.formerror.push({ ["MMP"]: messages.ProjectForm.MMPTable })
    }
    let mileStoneError = this.checkMileStoneValidation()
    if (mileStoneError.length != 0) {
      for (let i of mileStoneError) {
        this.formerror.push({ [i]: this.validationHeaderMessage });
      }
    }

    if (this.formerror.length) {
      this.submitted = false
      this.openDialog(this.formerror);
    }

    else {
      response = this.projectService.postTCData(this.sendData(finalGridData)).toPromise().
        then((res) => {
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            if (errorCode == 0) {
              this.hasUnsavedChanges = false;
              let dataSaveMessage = serverMessage.dataSaveMessage;
              this.toast.notify(dataSaveMessage, "success");
              this.localStorageService.set("projectId", this.submitResponse.Data.SuccessId);
              if (event != undefined) {
                this.hasUnsavedChanges = false;
                this.router.navigate(['/tc-manageProject'])
              }
              return true;
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          }
          else {
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }
        },
          (error) => {
            this.submitted = false;
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            return false;
          })
    }
    return response;
  }

  // Called to show the error popUp Window
  openDialog(error) {
    //  data is send in the format -- [{field:message},{field:message},{field:message}.....].
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  ontogglechangeM120(event: any) {
    //console.log(this.dataRecieved.IsPMApproved);
    let flagSuccess;
    let errorMsgeFromBack;

    if (event.checked == true) {

      if (this.projectForm.get("dateM120").value != "" && this.M120) {
        if ((this.projectForm.value.approvalProductManager == true && this.projectForm.value.approvalFinancialController == true &&
          this.projectForm.value.approvalServiceManager == true && this.projectForm.value.approvalProcurementEngineer == true
          && this.projectForm.value.approvalProductManager == true) ||
          (this.projectForm.value.approvalProductManager == true && this.projectForm.value.approvalFinancialController == true)||
          (this.projectForm.value.ProjectManagerID != null && this.projectForm.value.approvalProjectManager == true
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalProductManager == true) ||
          (this.projectForm.value.ServiceManagerID != null && this.projectForm.value.approvalProductManager == true 
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalServiceManager == true) ||
          (this.projectForm.value.ProcurementEngineerID != null && this.projectForm.value.approvalProductManager == true
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalProcurementEngineer == true)) {
          let dbDate = new Date(this.projectForm.get("dateM120").value);
          let dateUTC = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
          //let error = messages.copyTcConfiguration + params.data.Id + '?';
          let error = messages.copyTcConfiguration;
          // opens the dialog box for confirmation with two button Yes and No
          const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.projectService.postTCmanualAchiveMilestoneData(this.projectId, 1, this.userInOrg.UserId).subscribe((data: any) => {
                this.submitResponse = data;
                let successCode = this.submitResponse.StatusCode;
                let errorCode = this.submitResponse.Data.ErrorCode;
                errorMsgeFromBack = this.submitResponse.Data.Message;
                if (successCode == 200) {
                  if (errorCode == 0) {
                    flagSuccess = true;
                    this.toast.notify(serverMessage.projectSearch.dataCopied, "success");
                  }
                }
              })
            }
            else if (result == false) {
              event.source.checked = false;
              this.projectForm.get("achievedM120").setValue(false);
            }
          });

        }
        else {
          this.openAlertDialog([{ [messages.ProjectForm.manualAchieve]: serverMessage.warning }]);
          event.source.checked = false;
          this.projectForm.get("achievedM120").setValue(false);
        }

      }
      else {
        let mileStoneError = this.checkMileStoneM120Validation()
        if (mileStoneError.length != 0) {
          for (let i of mileStoneError) {
            this.formerror.push({ [i]: this.validationHeaderMessage });
          }
        }
        if (this.formerror.length) {
          this.submitted = false
          this.openDialog(this.formerror);
          event.source.checked = false;
          this.projectForm.get("achievedM120").setValue(false);
        }
      }
    }
  }
  ontogglechangeM150(event: any) {
    let flagSuccess;
    let errorMsgeFromBack;
    if (event.checked == true) {
      if (this.projectForm.get("dateM150").value != "" && this.M150) {
        if ((this.projectForm.value.approvalProductManager == true && this.projectForm.value.approvalFinancialController == true &&
          this.projectForm.value.approvalServiceManager == true && this.projectForm.value.approvalProcurementEngineer == true
          && this.projectForm.value.approvalProductManager == true) ||
          (this.projectForm.value.approvalProductManager == true && this.projectForm.value.approvalFinancialController == true)||
          (this.projectForm.value.ProjectManagerID != null && this.projectForm.value.approvalProjectManager == true
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalProductManager == true) ||
          (this.projectForm.value.ServiceManagerID != null && this.projectForm.value.approvalProductManager == true 
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalServiceManager == true) ||
          (this.projectForm.value.ProcurementEngineerID != null && this.projectForm.value.approvalProductManager == true
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalProcurementEngineer == true)) {
          let dbDate = new Date(this.projectForm.get("dateM150").value);
          let dateUTC = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
          //let error = messages.copyTcConfiguration + params.data.Id + '?';
          let error = messages.copyTcConfiguration;
          // opens the dialog box for confirmation with two button Yes and No
          const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.projectService.postTCmanualAchiveMilestoneData(this.projectId, 2, this.userInOrg.UserId).subscribe((data: any) => {
                this.submitResponse = data;
                let successCode = this.submitResponse.StatusCode;
                let errorCode = this.submitResponse.Data.ErrorCode;
                errorMsgeFromBack = this.submitResponse.Data.Message;
                if (successCode == 200) {
                  if (errorCode == 0) {
                    flagSuccess = true;
                    this.toast.notify(serverMessage.projectSearch.dataCopied, "success");
                  }
                }
              })
            }
            else if (result == false) {
              event.source.checked = false;
              this.projectForm.get("achievedM150").setValue(false);
            }
          });
        }
        else {
          this.openAlertDialog([{ [messages.ProjectForm.manualAchieve]: serverMessage.warning }]);
          event.source.checked = false;
          this.projectForm.get("achievedM150").setValue(false);
        }

      }
      else {
        let mileStoneError = this.checkMileStoneM150Validation()
        if (mileStoneError.length != 0) {
          for (let i of mileStoneError) {
            this.formerror.push({ [i]: this.validationHeaderMessage });
          }
        }
        if (this.formerror.length) {
          this.submitted = false
          this.openDialog(this.formerror);
          event.source.checked = false;
          this.projectForm.get("achievedM150").setValue(false);
        }
      }

    }
  }
  ontogglechangeM200(event: any) {
    let flagSuccess;
    let errorMsgeFromBack;
    if (event.checked == true) {
      if (this.projectForm.get("dateM200").value != "" && this.M200) {
        if ((this.projectForm.value.approvalProductManager == true && this.projectForm.value.approvalFinancialController == true &&
          this.projectForm.value.approvalServiceManager == true && this.projectForm.value.approvalProcurementEngineer == true
          && this.projectForm.value.approvalProductManager == true) ||
          (this.projectForm.value.approvalProductManager == true && this.projectForm.value.approvalFinancialController == true)||
          (this.projectForm.value.ProjectManagerID != null && this.projectForm.value.approvalProjectManager == true
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalProductManager == true) ||
          (this.projectForm.value.ServiceManagerID != null && this.projectForm.value.approvalProductManager == true 
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalServiceManager == true) ||
          (this.projectForm.value.ProcurementEngineerID != null && this.projectForm.value.approvalProductManager == true
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalProcurementEngineer == true)) {
          let dbDate = new Date(this.projectForm.get("dateM200").value);
          let dateUTC = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
          //let error = messages.copyTcConfiguration + params.data.Id + '?';
          let error = messages.copyTcConfiguration;
          // opens the dialog box for confirmation with two button Yes and No
          const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.projectService.postTCmanualAchiveMilestoneData(this.projectId, 4, this.userInOrg.UserId).subscribe((data: any) => {
                this.submitResponse = data;
                let successCode = this.submitResponse.StatusCode;
                let errorCode = this.submitResponse.Data.ErrorCode;
                errorMsgeFromBack = this.submitResponse.Data.Message;
                if (successCode == 200) {
                  if (errorCode == 0) {
                    flagSuccess = true;
                    this.toast.notify(serverMessage.projectSearch.dataCopied, "success");
                  }
                }
              })
            }
            else if (result == false) {
              event.source.checked = false;
              this.projectForm.get("achievedM200").setValue(false);
            }
          });
        }
        else {
          this.openAlertDialog([{ [messages.ProjectForm.manualAchieve]: serverMessage.warning }]);
          event.source.checked = false;
          this.projectForm.get("achievedM200").setValue(false);
        }
      }
      else {
        let mileStoneError = this.checkMileStoneM200Validation()
        if (mileStoneError.length != 0) {
          for (let i of mileStoneError) {
            this.formerror.push({ [i]: this.validationHeaderMessage });
          }
        }
        if (this.formerror.length) {
          this.submitted = false
          this.openDialog(this.formerror);
          event.source.checked = false;
          this.projectForm.get("achievedM200").setValue(false);
        }
      }
    }
  }
  ontogglechangeM300(event: any) {
    let flagSuccess;
    let errorMsgeFromBack;
    if (event.checked == true) {
      if (this.projectForm.get("dateM300").value != "" && this.M300) {
        if ((this.projectForm.value.approvalProductManager == true && this.projectForm.value.approvalFinancialController == true &&
          this.projectForm.value.approvalServiceManager == true && this.projectForm.value.approvalProcurementEngineer == true
          && this.projectForm.value.approvalProductManager == true) ||
          (this.projectForm.value.approvalProductManager == true && this.projectForm.value.approvalFinancialController == true)||
          (this.projectForm.value.ProjectManagerID != null && this.projectForm.value.approvalProjectManager == true
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalProductManager == true) ||
          (this.projectForm.value.ServiceManagerID != null && this.projectForm.value.approvalProductManager == true 
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalServiceManager == true) ||
          (this.projectForm.value.ProcurementEngineerID != null && this.projectForm.value.approvalProductManager == true
          && this.projectForm.value.approvalFinancialController == true && this.projectForm.value.approvalProcurementEngineer == true)) {
          let dbDate = new Date(this.projectForm.get("dateM300").value);
          let dateUTC = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
          //let error = messages.copyTcConfiguration + params.data.Id + '?';
          let error = messages.copyTcConfiguration;
          // opens the dialog box for confirmation with two button Yes and No
          const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.projectService.postTCmanualAchiveMilestoneData(this.projectId, 5, this.userInOrg.UserId).subscribe((data: any) => {
                this.submitResponse = data;
                let successCode = this.submitResponse.StatusCode;
                let errorCode = this.submitResponse.Data.ErrorCode;
                errorMsgeFromBack = this.submitResponse.Data.Message;
                if (successCode == 200) {
                  if (errorCode == 0) {
                    flagSuccess = true;
                    this.toast.notify(serverMessage.projectSearch.dataCopied, "success");
                  }
                }
              })
            }
            else if (result == false) {
              event.source.checked = false;
              this.projectForm.get("achievedM300").setValue(false);
            }
          });
        }
        else {
          this.openAlertDialog([{ [messages.ProjectForm.manualAchieve]: serverMessage.warning }]);
          event.source.checked = false;
          this.projectForm.get("achievedM300").setValue(false);
        }
      }
      else {
        let mileStoneError = this.checkMileStoneValidation()
        if (mileStoneError.length != 0) {
          for (let i of mileStoneError) {
            this.formerror.push({ [i]: this.validationHeaderMessage });
          }
        }
        if (this.formerror.length) {
          this.submitted = false
          this.openDialog(this.formerror);
          event.source.checked = false;
          this.projectForm.get("achievedM300").setValue(false);
        }
      }
    }
  }

  sendData(gridData) {
    let projectIdUpdate = 0;
    this.gridDataToSend = [];
    this.milestoneSend = [];

    if (this.projectIdFromLocal != "null") {
      projectIdUpdate = Number(this.projectIdFromLocal)
    }

    this.gridApi.forEachNode(node => {
      if (node.data.mmpId == 0 && node.data.mmpTitle != "Add Title") {
        this.hasUnsavedChanges = true;
        gridData.push(node.data);
      }
    })


    // Binding product tree data;
    for (let i of gridData) {
      let modifierId = null
      if (i.mmpId != 0) {
        modifierId = this.projectSubmitterId;
      }
      this.gridDataToSend.push({
        TitleId: i.mmpId,
        ProjectId: this.projectId == null ? 0 : this.projectId,
        Title: i.mmpTitle,
        IsActive: i.IsActive,
        MMPIsActive: i.IsChecked,
        CreatedBy: i.CreatedBy,
        CreatedOn: new Date().toISOString(),
        ModifiedBy: i.ModifiedBy,
        ModifiedOn: i.ModifiedOn,
      })
    }

    if (this.projectForm.get("dateM120").value != "" && this.M120) {
      let dbDate = new Date(this.projectForm.get("dateM120").value);
      let dateUTC = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
      this.milestoneSend.push({
        ProjectId: this.projectId == null ? 0 : this.projectId,
        MilestoneID: 1,
        Milestonedate: dateUTC,
        IsAchieved: this.projectForm.value.achievedM120,
        EKSlides: this.projectForm.get("EKSlideM120").value,
        //   IsActive: IsActive
        createdBy: (this.userInOrg == undefined) ? this.projectSubmitterId : this.userInOrg.UserId,
        //  CreatedOn: new Date().toISOString(),
        ModifiedBy: (this.userInOrg == undefined) ? null : this.userInOrg.UserId,
        //       ModifiedOn: j.ModifiedOn,
      })
    }
    if (this.projectForm.get("dateM150").value != "" && this.M150) {
      let dbDate = new Date(this.projectForm.get("dateM150").value);
      let dateUTC = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
      this.milestoneSend.push({
        ProjectId: this.projectId == null ? 0 : this.projectId,
        MilestoneID: 2,
        Milestonedate: dateUTC,
        IsAchieved: this.projectForm.value.achievedM150,
        EKSlides: this.projectForm.get("EKSlideM150").value,
        //      IsActive: IsActive,
        createdBy: (this.userInOrg == undefined) ? this.projectSubmitterId : this.userInOrg.UserId,
        //  CreatedOn: new Date().toISOString(),
        ModifiedBy: (this.userInOrg == undefined) ? null : this.userInOrg.UserId,
        //       ModifiedOn: j.ModifiedOn
      })
    }
    if (this.projectForm.get("dateM200").value != "" && this.M200) {
      let dbDate = new Date(this.projectForm.get("dateM200").value);
      let dateUTC = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
      this.milestoneSend.push({
        ProjectId: this.projectId == null ? 0 : this.projectId,
        MilestoneID: 4,
        Milestonedate: dateUTC,
        IsAchieved: this.projectForm.value.achievedM200,
        EKSlides: this.projectForm.get("EKSlideM200").value,
        // IsActive: IsActive,
        createdBy: (this.userInOrg == undefined) ? this.projectSubmitterId : this.userInOrg.UserId,
        //  CreatedOn: new Date().toISOString(),
        ModifiedBy: (this.userInOrg == undefined) ? null : this.userInOrg.UserId,
        //       ModifiedOn: j.ModifiedOn,
      })
    }
    if (this.projectForm.get("dateM300").value != "" && this.M300) {
      let dbDate = new Date(this.projectForm.get("dateM300").value);
      let dateUTC = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
      this.milestoneSend.push({
        ProjectId: this.projectId == null ? 0 : this.projectId,
        MilestoneID: 5,
        Milestonedate: dateUTC,
        IsAchieved: this.projectForm.value.achievedM300,
        EKSlides: this.projectForm.get("EKSlideM300").value,
        //      IsActive: IsActive,
        createdBy: (this.userInOrg == undefined) ? this.projectSubmitterId : this.userInOrg.UserId,
        //  CreatedOn: new Date().toISOString(),
        ModifiedBy: (this.userInOrg == undefined) ? null : this.userInOrg.UserId,
        //       ModifiedOn: j.ModifiedOn,
      })
    }
    this.dataToSend.ProjectMilestones = this.milestoneSend;
    this.dataToSend.ProjectMMP = this.gridDataToSend;
    this.dataToSend.ProjectId = projectIdUpdate;
    this.dataToSend.ProjectTitle = this.projectForm.get("title").value.trim();
    this.dataToSend.SubmitterId = this.submitterId;
    this.dataToSend.SubmitterOrgCode = this.projectForm.get("orgCodeSub").value.trim();
    this.dataToSend.FinancialControllerID = this.financialControllerId;
    this.dataToSend.FinancialControllerOrgCode = this.projectForm.get("orgCodeFC").value.trim();
    this.dataToSend.IsFCApproved = this.projectForm.value.approvalFinancialController;

    this.dataToSend.ProjectManagerID = this.projectManagerId == undefined ? null : this.projectManagerId;
    this.dataToSend.ProjectManagerOrgCode = this.projectForm.get("orgCodeProjectM").value.trim();
    this.dataToSend.IsPMApproved = this.projectForm.value.approvalProjectManager;

    this.dataToSend.ProductManagerID = this.productManagerId;
    this.dataToSend.ProductManagerOrgCode = this.projectForm.get("orgCodeProductM").value.trim();
    this.dataToSend.IsPDMApproved = this.projectForm.value.approvalProductManager;

    this.dataToSend.ServiceManagerID = this.serviceManagerId == undefined ? null : this.serviceManagerId;
    this.dataToSend.ServiceManagerOrgCode = this.projectForm.get("orgCodeServiceM").value.trim();
    this.dataToSend.IsSMApproved = this.projectForm.value.approvalServiceManager;

    this.dataToSend.ProcurementEngineerID = this.procurementEngineerId == undefined ? null : this.procurementEngineerId;
    this.dataToSend.ProcurementEngineerOrgCode = this.projectForm.get("orgCodePE").value.trim();
    this.dataToSend.IsPEApproved = this.projectForm.value.approvalProcurementEngineer;
    this.dataToSend.MeasureStatusId = parseInt(this.projectForm.value.MeasureStatusId),
      this.dataToSend.DNA = this.projectForm.get("DNA").value.trim();
    this.dataToSend.CreatedBy = (this.userInOrg == undefined) ? this.projectSubmitterId : this.userInOrg.UserId;
    this.dataToSend.BusinessAreaId = Number(this.projectForm.controls["businessAreaName"].value);
    this.dataToSend.BusinessLineId = Number(this.projectForm.controls["businessLineName"].value);
    // let finalMMPGridData = [];
    // let mmpGridDataToSend = [];

    // finalMMPGridData = this.modifiedMMPRows;
    // //console.log(finalMMPGridData);
    // this.gridApi.forEachNode(node => {
    //   console.log(node);
    //   if (node.data.mmpId == 0) {
    //     this.hasUnsavedChanges = true;
    //     finalMMPGridData.push(node.data);
    //   }
    // })

    // let isbusinessAreaName = this.projectForm.value.businessAreaName;
    // let isbusinessLineName = this.projectForm.value.businessLineName;
    // let isorgCodeSub = this.projectForm.value.orgCodeSub;
    // let isfinancialControllerPeoplePickr = this.projectForm.value.financialControllerPeoplePickr;
    // let isorgCodeFC = this.projectForm.value.orgCodeFC;
    // let isapprovalFinancialController = this.projectForm.value.approvalFinancialController;
    // let isproductManagerPeoplePickr = this.projectForm.value.productManagerPeoplePickr;
    // let isorgCodeProductM = this.projectForm.value.orgCodeProductM;
    // let isapprovalProductManager = this.projectForm.value.approvalProductManager;
    // let isserviceManagerPeoplePickr = this.projectForm.value.serviceManagerPeoplePickr;
    // let isorgCodeServiceM = this.projectForm.value.orgCodeServiceM;
    // let isapprovalServiceManager = this.projectForm.value.approvalServiceManager;
    // let isprojectManagerPeoplePickr = this.projectForm.value.projectManagerPeoplePickr;
    // let isorgCodeProjectM = this.projectForm.value.orgCodeProjectM;
    // let isapprovalProjectManager = this.projectForm.value.approvalProjectManager;
    // let isprocurementEngineerPeoplePickr = this.projectForm.value.procurementEngineerPeoplePickr;
    // let isorgCodePE = this.projectForm.value.orgCodePE;



    // for (let i in finalMMPGridData) {
    //   mmpGridDataToSend.push({
    //     mmpId: i.mmpId,
    //     projectid: this.projectId,

    //     mmpTitle: "Add Title",
    //     IsChecked: false,
    //     IsActive: true,
    //     CreatedBy: this.submitterId,
    //     CreatedOn: null,
    //     ModifiedBy: null,
    //     ModifiedOn: null,
    //     // IsEditable: this.isEditable
    //   })
    // }

    return this.dataToSend;
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  loadGrid() {
    // this.showGrid = true;

    this.defaultColDef = {
      editable: this.isEditable,
      sortable: false,
      suppressMovable: true,

      wrapText: true,
      autoHeight: false,
    };
    this.domLayout = 'autoHeight';

    this.mmpColumnDefs = [
      {
        headerName: 'Title',
        headerTooltip: "Title",
        field: 'mmpTitle',
        cellEditor: 'agTextCellEditor',
        singleClickEdit: true,
        width: 300,
        editable: (params) => {

          return this.isEditable;

        },

        cellStyle: (params) => (params.data.mmpTitle == "Add Title") ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" },
        // cellRenderer: '',
        // keyCreator:'',
      },
      {
        headerName: 'Active',
        headerTooltip: "Active",
        field: 'IsChecked',
        editable: false,
        cellRendererFramework: CheckboxComponent,
        cellClass: 'green-checkbox',
        Height: 20
      },
      {
        headerName: 'Action', headerTooltip: "Action", width: 40, height: 20, field: 'del', editable: false, cellRendererFramework: DeleteiconComponent,
      }
    ]
    this.mmpRowData = [];
  }

  AddNewRowMMP() {
    this.gridApi.stopEditing();
    this.validateRow(1);
    let rowCount = this.gridApi.getDisplayedRowCount()
    if (rowCount == 4) {
      this.gridApi.setDomLayout('normal');
      document.getElementById('mmpGrid').style.height = '200px';
    }
  }

  validateRow(value) {
    this.alertErrorBox = [];
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    let error = [];
    this.gridApi.forEachNode(node => count += 1);
    count = count - 1;
    let countRow = this.gridApi.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      if (value == 1 && error.length == 0) {
        this.gridApi.applyTransaction({
          add: [{
            mmpId: 0,
            projectId: this.projectId,
            mmpTitle: "Add Title",
            IsChecked: false,
            IsActive: true,
            CreatedBy: this.submitterId,
            CreatedOn: null,
            ModifiedBy: null,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
      }
    }
    else {
      let data = this.gridApi.getDisplayedRowAtIndex(0).data;
      if (data.mmpTitle == "" || data.mmpTitle == "Add Title") {
        error.push({ MMP: errorMessage })
      }
      if (error.length == 0) {
        this.gridApi.applyTransaction({
          add: [{
            mmpId: 0,
            projectId: this.projectId,
            mmpTitle: "Add Title",
            IsChecked: false,
            IsActive: true,
            CreatedBy: this.submitterId,
            CreatedOn: null,
            ModifiedBy: null,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
      }
      else {
        this.openAlertDialog(error)
      }
    }
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  mmpGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColApi = params.columnApi;
    this.gridApi.setGroupHeaderHeight(10);
    let rowCnt = this.gridApi.getDisplayedRowCount();
    if (rowCnt >= 2) {
      this.gridApi.setDomLayout('normal');
      document.getElementById('mmpGrid').style.height = '100px';
    }
  }

  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {

    params.api.resetRowHeights();
  }

  onCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    if (params.data.mmpId != 0) {
      let isPushed = false;
      if (this.modifiedMMPRows.length == 0) {
        this.modifiedMMPRows.push(params.data);
        isPushed = true;
      }
      else {
        this.modifiedMMPRows.forEach(row => {
          if (params.data.mmpId == row.mmpId) {
            isPushed = true;
          }
        })
      }
      if (!isPushed) {
        this.modifiedMMPRows.push(params.data);
      }
    }
  }

  onRowClicked(params) {

  }
  // funtion which will take user to the TFS Link provided in the TFS Link input box;
  ekSlideBind(para) {
    let ekSlideValue: any;
    ekSlideValue = para.target.value;
    this.ekSlideValue = ekSlideValue.includes("https://") || ekSlideValue.includes("http://") ? ekSlideValue : "https://" + ekSlideValue;
  }
}

// vallidates if only white space is present into the input field;
function validateSpaceOnly(check: FormControl) {
  let regEx = /^ *$/;
  let value = !regEx.test(check.value);
  return value ? true : 'required';
}