<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>

<div class="container">
    <div class="label-header">
        <label class="label-headercolor">Local Costs </label>
    </div>

    <div style="overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
        <div class="col-md-5 col-lg-5 label-header" style="display: inline-block;">
            <!-- <label class="label-headercolor">Reference Scenario: {{ referenceTitle }} </label> -->
            <label class="label-headercolor">Reference Scenario: <input type="text" [(ngModel)]="refTitle"
                    placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>

        <div class="col-md-7 col-lg-7 label-header" style="margin-left: 15px; display: inline-block;">
            <!-- <label class="label-headercolor">Target Scenario: {{ targetTitle }} </label> -->
            <label class="label-headercolor">Target Scenario: <input type="text" [(ngModel)]="tarTitle"
                    placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optAVisible" class="col-md-7 col-lg-7 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option A: <input [(ngModel)]="optATitle" type="text"
                    placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optBVisible" class="col-md-7 col-lg-7 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option B: <input [(ngModel)]="optBTitle" type="text"
                    placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optCVisible" class="col-md-7 col-lg-7 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option C:<input [(ngModel)]="optCTitle" type="text" placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div class="col-md-1 col-lg-1 label-header" style="margin-left: 15px; display: inline-block;">
            <button [disabled]="!isEditable" [ngClass]="{ 'click-not-allowed': !isEditable }" (click)="addNewRow()"
                title="Add">
                <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add" />
                <label class="label-headercolor">add Option</label>
            </button>
        </div>
        <br>

        <div class="col-md-5 col-lg-5" style="padding-left: 0px;padding-right:0px; display: inline-block;">
            <div class="col-md-5 col-lg-5 label-header" style="display: inline-block;width: 37.2%;">
                <label class="label-headercolor">Local Cost (Add item </label>
                <button [disabled]="!isEditable" [ngClass]="{'click-not-allowed':!isEditable}" (click)="AddNewRowRef()"
                    title="Add">
                    <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add">
                </button><label class="label-headercolor"> )</label>
            </div>


            <ag-grid-angular #refGrid *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="refLabourGrid"
                [rowData]="refGridData" [columnDefs]="refColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="refFrameworkComponents"
                [defaultColDef]="refDefaultColDef" [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onRefCellValueChanged($event)" (gridReady)="onRefGridReady($event)"
                (gridSizeChanged)="onGridRefCSChanged($event)"
                (displayedColumnsChanged)="onDisplayedColumnsRefChanged($event)"
                [rowHeight]="rowHeight" [headerHeight]="headerHeight">
            </ag-grid-angular>

        </div>

        <div class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular #tarGrid *ngIf="showGrid" style="width: 100%; " class="ag-theme-alpine" id="tarLabourGrid"
                [rowData]="tarGridData" [columnDefs]="tarColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="tarFrameworkComponents"
                [defaultColDef]="tarDefaultColDef" [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onTarCellValueChanged($event)" (gridReady)="onTarGridReady($event)"
                (gridSizeChanged)="onGridTarCSChanged($event)"
                [rowHeight]="rowHeight" [headerHeight]="headerHeight"
                (displayedColumnsChanged)="onDisplayedColumnsTarChanged($event)">
            </ag-grid-angular>
        </div>
        <div *ngIf="optAVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" #optAGrid style="width: 100%; " class="ag-theme-alpine" id="optALabourGrid" [rowData]="optAGridData"
                [columnDefs]="optAColumnDefs" [rowClassRules]="rowClassRulesRef" [domLayout]="domLayout"
                [frameworkComponents]="optAFrameworkComponents" [defaultColDef]="optADefaultColDef"
                [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptACellValueChanged($event)" (gridReady)="onOptAGridReady($event)"
                (gridSizeChanged)="onGridOptACSChanged($event)"
                [rowHeight]="rowHeight" [headerHeight]="headerHeight"
                (displayedColumnsChanged)="onDisplayedColumnsTarChanged($event)">
            </ag-grid-angular>
        </div>
        <div *ngIf="optBVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" #optBGrid style="width: 100%; " class="ag-theme-alpine" id="optBLabourGrid" [rowData]="optBGridData"
                [columnDefs]="optBColumnDefs" [rowClassRules]="rowClassRulesRef" [domLayout]="domLayout"
                [frameworkComponents]="optBrameworkComponents" [defaultColDef]="optBDefaultColDef"
                [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptBCellValueChanged($event)" (gridReady)="onOptBGridReady($event)"
                (gridSizeChanged)="onGridOptBCSChanged($event)"
                [rowHeight]="rowHeight" [headerHeight]="headerHeight"
                (displayedColumnsChanged)="onDisplayedColumnsTarChanged($event)">
            </ag-grid-angular>
        </div>
        <div *ngIf="optCVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" #optCGrid style="width: 100%; " class="ag-theme-alpine" id="optCLabourGrid" [rowData]="optCGridData"
                [columnDefs]="optCColumnDefs" [rowClassRules]="rowClassRulesRef" [domLayout]="domLayout"
                [frameworkComponents]="optCFrameworkComponents" [defaultColDef]="optCDefaultColDef"
                [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptCCellValueChanged($event)" (gridReady)="onOptCGridReady($event)"
                (gridSizeChanged)="onGridOptCCSChanged($event)"
                [rowHeight]="rowHeight" [headerHeight]="headerHeight"
                (displayedColumnsChanged)="onDisplayedColumnsTarChanged($event)">
            </ag-grid-angular>
        </div>
        <br>
    </div>

    <div class="row">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle" style="float: right; margin-right: 5px; margin-bottom: 20px">
                <button class="button button--small" (click)="onClickCancel()">
                    Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
                    Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
                    Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted || !isEditable" (click)="submit($event)"
                    type="submit">
                    Save & Exit
                </button>
            </div>
        </div>
    </div>

    <app-commentbox [configData]="objData"></app-commentbox>
</div>