import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable,throwError } from 'rxjs';
import { Comments } from  '../models/comment';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient) { }
  private urlPostCommentsData: string = environment.baseURL + "api/Comment/InsertComment";
  private urlgetCommentsData: string = environment.baseURL + "api/Comment/GetComments";
  postData(data): Observable<Comments> {
    return this.http.post<Comments>(this.urlPostCommentsData, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  getCommentsData(projectId,moduleId): Observable<Comments> {
    let _projectId = Number(projectId);
    let _moduleId = Number(moduleId)
    return this.http.get<Comments>(this.urlgetCommentsData + '?projectId=' + _projectId + '&moduleId=' + _moduleId );
  }
  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
  
}
