<button type="button" (click)="invokeLockMethod(params)" *ngIf="isLock" style="padding-top: 2px" title="Lock" [disabled]="!lockVisible"
[ngClass]="{ 'click-not-allowed': !lockVisible }">
    <img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="20"
        height="15" style="vertical-align: top" />
</button>

<button type="button" (click)="invokeLockMethod(params)" *ngIf="!isLock" style="padding-top: 2px" title="UnLock" [disabled]="!lockVisible"
[ngClass]="{ 'click-not-allowed': !lockVisible }">
    <img src="../assets/icons/unlockIcon.svg" alt="LockIcon" width="20"
        height="15" style="vertical-align: top" /> 
</button>