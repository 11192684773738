<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>

<div class="container">
    <div class="label-header">
        <label class="label-headercolor">CS Target Costs </label>
    </div>

    <div style="overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
        <div class="col-md-3 col-lg-3 label-header" style="display: inline-block;">
            <label class="label-headercolor">Reference Scenario: <input [(ngModel)]="refTitle" type="text"
                    placeholder="Add Title" style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>

        <div class="col-md-4 col-lg-4 label-header" style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Target Scenario: <input [(ngModel)]="tarTitle" type="text"
                    placeholder="Add Title" style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optAVisible" class="col-md-4 col-lg-4 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option A: <input [(ngModel)]="optATitle" type="text"
                    placeholder="Add Title" style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optBVisible" class="col-md-4 col-lg-4 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option B <input [(ngModel)]="optBTitle" type="text"
                    placeholder="Add Title" style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optCVisible" class="col-md-4 col-lg-4 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option C: <input [(ngModel)]="optCTitle" type="text"
                    placeholder="Add Title" style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div class="col-md-1 col-lg-1 label-header" style="margin-left: 15px; display: inline-block;">
            <button [disabled]="!isEditable" [ngClass]="{ 'click-not-allowed': !isEditable }" (click)="addNewRow()"
                title="Add">
                <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add" />
                <label class="label-headercolor">add Option</label>
            </button>
        </div>
        <br>

        <div class="col-md-3 col-lg-3" style="padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" style="width: 100%; " class="ag-theme-alpine" id="refGrid"
                [rowData]="refGridData" [columnDefs]="refColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="refFrameworkComponents"
                [defaultColDef]="refDefaultColDef" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onRefCellValueChangedCS($event)"
                (gridReady)="onRefGridReady($event)" (gridSizeChanged)="onGridRefCSChanged($event)"
                (columnResized)="onColumnResizedRef($event)"
                (columnVisible)="onColumnVisibleRef($event)" [rowHeight]="rowHeight" [headerHeight]="headerHeight">
            </ag-grid-angular>
        </div>

        <div class="col-md-4 col-lg-4"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" style="width: 100%; " class="ag-theme-alpine" id="tarGrid"
                [rowData]="tarGridData" [columnDefs]="tarColumnDefs" [rowClassRules]="rowClassRulesTar"
                [domLayout]="domLayout" [frameworkComponents]="tarFrameworkComponents"
                [defaultColDef]="tarDefaultColDef" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onTarCellValueChangedCS($event)"
                (gridReady)="onTarGridReady($event)" (gridSizeChanged)="onGridTarCSChanged($event)"
                (columnResized)="onColumnResizedTar($event)"
                (columnVisible)="onColumnVisibleTar($event)" [rowHeight]="rowHeight" [headerHeight]="headerHeight">
            </ag-grid-angular>
        </div>
        <div *ngIf="optAVisible" class="col-md-4 col-lg-4"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optAGrid"
                [rowData]="optAGridData" [columnDefs]="optAColumnDefs" [rowClassRules]="rowClassRulesOptA"
                [domLayout]="domLayout" [frameworkComponents]="optAFrameworkComponents"
                [defaultColDef]="optADefaultColDef" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onOptACellValueChangedCS($event)"
                (gridReady)="onOptAGridReady($event)" (gridSizeChanged)="onGridOptACSChanged($event)"
                (columnResized)="onColumnResizedOptA($event)"
                (columnVisible)="onColumnVisibleOptA($event)" [rowHeight]="rowHeight" [headerHeight]="headerHeight">
            </ag-grid-angular>
        </div>
        <div *ngIf="optBVisible" class="col-md-4 col-lg-4"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optBGrid"
                [rowData]="optBGridData" [columnDefs]="optBColumnDefs" [rowClassRules]="rowClassRulesOptB"
                [domLayout]="domLayout" [frameworkComponents]="optBFrameworkComponents"
                [defaultColDef]="optBDefaultColDef" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onOptBCellValueChangedCS($event)"
                (gridReady)="onOptBGridReady($event)" (gridSizeChanged)="onGridOptBCSChanged($event)"
                (columnResized)="onColumnResizedOptB($event)"
                (columnVisible)="onColumnVisibleOptB($event)" [rowHeight]="rowHeight" [headerHeight]="headerHeight">
            </ag-grid-angular>
        </div>
        <div *ngIf="optCVisible" class="col-md-4 col-lg-4"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">
            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optCGrid"
                [rowData]="optCGridData" [columnDefs]="optCColumnDefs" [rowClassRules]="rowClassRulesOptC"
                [domLayout]="domLayout" [frameworkComponents]="optCFrameworkComponents"
                [defaultColDef]="optCDefaultColDef" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onOptCCellValueChangedCS($event)"
                (gridReady)="onOptCGridReady($event)" (gridSizeChanged)="onGridOptCCSChanged($event)"
                (columnResized)="onColumnResizedOptC($event)"
                (columnVisible)="onColumnVisibleOptC($event)" [rowHeight]="rowHeight" [headerHeight]="headerHeight">
            </ag-grid-angular>
        </div>
        <br>
    </div>

    <div class="row">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle" style="float: right; margin-right: 5px; margin-bottom: 20px">
                <button class="button button--small" (click)="onClickCancel()">
                    Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
                    Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
                    Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted || !isEditable" (click)="submit($event)"
                    type="submit">
                    Save & Exit
                </button>
            </div>
        </div>
    </div>

    <app-commentbox [configData]="objData"></app-commentbox>
</div>