import { MMP } from "./MMP";
import { Milestone } from "./Milestone";
export class TCProject {
    
        ProjectMMP :MMP[] ;
        ProjectMilestones:Milestone[];
    ProjectId: number;
    ProjectTitle: string;
    SubmitterId:string;
    Submitter: string;
    SubmitterOrgCode: string;
    FinancialControllerID :string;
    FinancialController: string;
    FinancialControllerOrgCode: string;
    IsFCApproved:boolean;
    ProductManagerID :string;
    ProductManager: string;
    ProductManagerOrgCode: string;
    IsPDMApproved:boolean;
    ServiceManagerID :string;
    ServiceManager: string;
    ServiceManagerOrgCode: string;
    IsSMApproved:boolean;
    ProjectManagerID :string;
    ProjectManager: string;
    ProjectManagerOrgCode: string;
    IsPMApproved:boolean;
    ProcurementEngineerID :string;
    ProcurementEngineer: string;
    ProcurementEngineerOrgCode: string;
    IsPEApproved:boolean;
    MeasureStatusId: number;
    DNA: string;
    TargetSol: string;
    OptionA: string;
    OptionB: string;
    OptionC: string;
    IsActive: boolean;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    MilestoneID:number;
    MilestoneName:string;
    BusinessAreaId: number;
    BusinessLineId: number;
    
}
  
    