import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { ProcessCostService } from 'src/app/services/process-cost.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { GridlinkComponent } from '../../plc/processcost/gridlink/gridlink.component';
//import { GridlinkComponent } from '../../plc/processcost/gridlink/gridlink.component';

@Component({
  selector: 'app-investment-target',
  templateUrl: './investment-target.component.html',
  styleUrls: ['./investment-target.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InvestmentTargetComponent implements OnInit {
  refProcessCostColumnDefs: any;
  rowClassRules: { 'rowpin-background': (params: any) => boolean; };
//  refProcessCostDefaultColDef: { suppressMovable: boolean; sortable: boolean; };

  public showGrid = false;
  projectId: any;
  refProcessCostRowData: any[];
  refProcessCostGridApi: any;
  refProcessCostgridColumnApi: any;
  hasUnsavedChanges: boolean;
  isEditable: any;
  refProcessCostDefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };
  refFrameworkComponents: { DoublingEditor: any; };
  tgtProcessCostColumnDefs:any;
  domLayout: string;
  defaultColDef: {
    /*  flex: 1, */
    suppressMovable: boolean; sortable: boolean;
  };
  rowHeight: number;
  headerHeight: number;
  tgtProcessCostRowData: any[];
  tgtProcessCostGridApi: any;
  tgtProcessCostgridColumnApi: any;
  constructor(
    private http: HttpClient,
    private sharedService: SharedServiceService,
    private localStorageService: LocalStorageService,
    private processCostService: ProcessCostService,
    public dialog: MatDialog,
    private menuService:MenuService,
    private router:Router   ,
   // private catService: CategorizationService
    ) {
   /// this.rowHeight = 25;
  //  this.headerHeight = 48;

  }


  ngOnInit(): void {

    
    this.sharedService.getProjectIdAndTitle();
    this.projectId = this.localStorageService.get("projectId");
    // this.objData = {
    //   projectId: this.projectId,
    //   moduleId: this.moduleId
    // }
    this.showGrid = true
    this.loadtargetGrid();
    const countryCode = this.localStorageService.get('countryCode');

  }
  loadtargetGrid()
  {

    this.showGrid = true;
    this.tgtProcessCostColumnDefs = [
      {
        headerName: "",
      //  headerClass: "processcost-class",
        children: [
          {
            headerName: "Process Costs",  headerTooltip: "Process Costs",
            children: [
              // { headerName:"ID",field: 'id',minWidth: 10 },
              {
                headerName: "Subprocess",  headerTooltip: "Subprocess",
                cellStyle: { 'text-align': 'left !important' },
                width: 120,
                suppressSizeToFit: true,
                field: 'SubProcessTitle',
                tooltipField: "SubProcessTitle",
            //    cellRendererFramework: GridlinkComponent
              }
            ]
          },
        ]
      },
      {
        headerName: "Output",  headerTooltip: "Output",
        children: [
          {
            headerName: "One Time",  headerTooltip: "One Time",
            headerClass: "border-left",
            children: [
              {
                headerName: "effort [abs]",  headerTooltip: "effort [abs]", field: 'OutputOTEffort', // tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, 
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                // valueGetter: function (params) {
                //   if (params.node.data.OutputOTEffort == null) {
                //     return 0;
                //   } else {
                //     return params.node.data.OutputOTEffort;
                //   }
                // }
              },
              {
                headerName: "cost [abs]",  headerTooltip: "cost [abs]", field: 'OutputOTCost',
                
                //tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, 
                cellClass: 'numCell-align',
                // valueGetter: function (params) {
                //   if (params.node.data.OutputOTCost == null) {
                //     return 0;
                //   } else {
                //     return params.node.data.OutputOTCost;
                //   }
                // }
              },
              {
                headerName: "Total",  headerTooltip: "Total", field: 'OutputOTTotal',// tooltipValueGetter: CurrencyCellRenderer,cellRenderer: CurrencyCellRenderer,
                 cellClass: 'colorTotal numCell-align',
                // valueGetter: function (params) {
                //   if (params.node.data.OutputOTTotal == null) {
                //     return 0;
                //   } else {
                //     return params.node.data.OutputOTTotal;
                //   }
                // }/*valueGetter:oneTimeTotal*/
              },
            ]
          },

          {
            headerName: "Recurring/year",  headerTooltip: "Recurring/year",
            headerClass: "ry-header-class border-left",
            children: [
              {
                headerName: "effort [abs]",  headerTooltip: "effort [abs]", field: 'OutputRYEffort', //tooltipValueGetter: CurrencyCellRenderer,cellRenderer: CurrencyCellRenderer, 
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]",  headerTooltip: "cost [abs]", field: 'OutputRYCost', // tooltipValueGetter: CurrencyCellRenderer,cellRenderer: CurrencyCellRenderer,
                 cellClass: 'numCell-align',
                // valueGetter: function (params) {
                //   if (params.node.data.OutputRYCost == null) {
                //     return 0;
                //   } else {
                //     return params.node.data.OutputRYCost;
                //   }
                // }
              },
              {
                headerName: "Total",  headerTooltip: "Total", field: 'OutputRYTotal',// tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                 cellClass: 'colorTotal numCell-align',
                // valueGetter: function (params) {
                //   if (params.node.data.OutputRYTotal == null) {
                //     return 0;
                //   } else {
                //     return params.node.data.OutputRYTotal;
                //   }
                // }/*valueGetter:reccuringPerYrTotal*/
              },
            ]
          },

          {
            headerName: "Recurring over lifetime",  headerTooltip: "Recurring over lifetime",
            headerClass: "rl-header-class border-left",
            children: [
              {
                headerName: "effort [abs]",  headerTooltip: "effort [abs]", field: 'OutputRLEffort', //tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, 
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                // valueGetter: function (params) {
                //   if (params.node.data.OutputRLEffort == null) {
                //     return 0;
                //   } else {
                //     return params.node.data.OutputRLEffort;
                //   }
                // }
              },
              {
                headerName: "cost [abs]",  headerTooltip: "cost [abs]", field: 'OutputRLCost', //tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, 
                cellClass: 'numCell-align',
                // valueGetter: function (params) {
                //   if (params.node.data.OutputRLCost == null) {
                //     return 0;
                //   } else {
                //     return params.node.data.OutputRLCost;
                //   }
                // }
              },
              { headerName: "Total",  headerTooltip: "Total", field: 'OutputRLTotal', // tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                 cellClass: 'colorTotal numCell-align',
              // valueGetter: function (params) {
              //   if (params.node.data.OutputRLTotal == null) {
              //     return 0;
              //   } else {
              //     return params.node.data.OutputRLTotal;
              //   }
              // } 
            },
            ]
          },

          {
            headerName: "Total",  headerTooltip: "Total",
            headerClass: "border-left",
            children: [
              {
                headerName: "Over Lifetime",  headerTooltip: "Over Lifetime", field: 'OutputFinalTotal', // tooltipValueGetter: CurrencyCellRenderer,
                //cellRenderer: CurrencyCellRenderer, 
                cellClass: 'colorTotal numCell-align border-left', headerClass: 'border-left', 
             //   valueGetter: overLifetimeTotal
              },
            ]
          },
        ]
      },

    ];

  // this.domLayout = 'autoHeight';
   this.rowClassRules = {
     'rowpin-background': function (params: any) {
       return params.data.SubProcessTitle === "Total process cost"
      },

   };
    // this.refProcessCostDefaultColDef={
    //   suppressMovable: true,
    //   sortable: false,
    // }
    
    this.tgtProcessCostRowData=[];
  }
 
  
  ontgtProcessCostGridSizeChanged(params) {
    this.tgtProcessCostGridApi = params.api;
    this.tgtProcessCostGridApi.sizeColumnsToFit();
  }
  
  ontgtProcessCostGridReady(params: any) {
    this.tgtProcessCostGridApi = params.api;
    this.tgtProcessCostgridColumnApi = params.columnApi;

    this.tgtProcessCostGridApi.setGroupHeaderHeight(25);
    // setTimeout(() => {
    //   let pinnedBottomData = this.generatePinnedBottomData();
    //   this.tgtProcessCostGridApi.setPinnedBottomRowData([pinnedBottomData]);
    // }, 1000)
  }
}
