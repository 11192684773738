<div class="container">
    <p style="position:fixed;left:10px;font-size: 0.7rem;font-weight: bold;margin-bottom:6px">© Copyright {{year}}. All
        rights reserved.
        <span style="position:fixed;left:210px;font-size: 0.7rem;font-weight: bold;margin-bottom:6px">|</span>
        <span style="position:fixed;left:220px;font-size: 0.7rem;font-weight: bold;margin-bottom:6px"><a
                href=' https://www.siemens-healthineers.com/terms-of-use' aria-label="Terms of use"
                target="_blank">Terms of Use</a></span>
        <span style="position:fixed;left:292px;font-size: 0.7rem;font-weight: bold;margin-bottom:6px">|</span>
        <span style="position:fixed;left:302px;font-size: 0.7rem;font-weight: bold;margin-bottom:6px"><a
                href='https://intranet.for.healthineers.siemens.com/cms/generaltopics/en/Pages/privacy-pol.aspx'
                aria-label="Privacy Policy" target="_blank" rel="noopener">Privacy Policy </a></span>
    </p>
</div>