import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LocalStorageService } from 'src/app/services/local-storage.service';
let countryCode;
@Component({
  selector: 'app-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ScenarioComponent implements ICellRendererAngularComp {

  constructor(
    private localStorageService: LocalStorageService,
  ) { }
  public params: any;
  public checkVisible: boolean = false;
  gridApi: any;
  //public IsChecked: boolean = false;
  checked: boolean = false;


  agInit(params: any): void {
    countryCode = this.localStorageService.get('countryCode');
    this.params = params;
    this.gridApi = params.api;
    if (this.params.data.Name == null) {
      this.checkVisible = true;
    }
    else {
      console.log(params.value)
      params.value = params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
    }
  }
  refresh(params: any): boolean {
    if (params.colDef.field == "Scenario1") {
      params.data.Scenario1 = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);

      if (checked == true) {
        this.params.node.setDataValue("Scenario2", false);
        this.params.node.setDataValue("Scenario3", false)
      }
    }
    if (params.colDef.field == "Scenario2") {
      params.data.Scenario2 = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);

      if (checked == true) {
        this.params.node.setDataValue("Scenario1", false);
        this.params.node.setDataValue("Scenario3", false)
      }
    }
    if (params.colDef.field == "Scenario3") {
      params.data.Scenario3 = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);

      if (checked == true) {
        this.params.node.setDataValue("Scenario1", false);
        this.params.node.setDataValue("Scenario2", false)
      }
    }
    return false;
  }

}
