import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PeriodLockService {

  constructor(private http : HttpClient) {}

  private urlGetPeriodLockData:string = environment.baseURL + "api/PeriodLock/GetPeriodLockData";
  private urlInsertUpdatePeriodLockData:string = environment.baseURL + "api/PeriodLock/InsertUpdatePeriodLockData";
  private urlGetTCPeriodLockData:string = environment.baseURL + "api/PeriodLock/GetTCPeriodLockData";
  private urlInsertUpdateTCPeriodLockData:string = environment.baseURL + "api/PeriodLock/InsertUpdateTCPeriodLockData";

  //This is used to get PeriodLock data from Backend
  getPeriodLockData(businessAreaId?: number , businessLineId?: number, year?: number): Observable<any>{
    return this.http.get<any>(this.urlGetPeriodLockData+"?businessAreaId="+businessAreaId+"&businessLineId="+businessLineId+"&year="+year).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  getTCPeriodLockData(businessAreaId?: number , businessLineId?: number, year?: number): Observable<any>{
    return this.http.get<any>(this.urlGetTCPeriodLockData+"?businessAreaId="+businessAreaId+"&businessLineId="+businessLineId+"&year="+year).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  //This is used to insert and Update the Actual Unit data
  updateData(data): Observable<any>{
    const httpOptions= {
      headers : new HttpHeaders({'Content-Type' : 'application/json'})
    }
    return this.http.post<any>(this.urlInsertUpdatePeriodLockData,data,httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  updateTCData(data): Observable<any>{
    const httpOptions= {
      headers : new HttpHeaders({'Content-Type' : 'application/json'})
    }
    return this.http.post<any>(this.urlInsertUpdateTCPeriodLockData,data,httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };


  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
