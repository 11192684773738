<app-progress-spinner *ngIf="!showGrid || submitted"></app-progress-spinner>
<div class="container">
    <div style="overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
        <!-- <div> -->
            <div class="col-md-5 col-lg-5"
                style="display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px; position: sticky; left: 0; z-index: 2;">
                <div class="label-header">
                    <label class="label-headereq">Configuration</label>
                </div>
            </div>

            <div class="col-md-3 col-lg-3"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <div class="label-header">
                    <label class="label-headereq">Reference Scenario: <input [(ngModel)]="refTitle" type="text"
                            placeholder="Add Title"
                            style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
                    </label>
                </div>
            </div>

            <div class="col-md-6 col-lg-6"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <div class="label-header">
                    <label class="label-headereq">Target Scenario: <input [(ngModel)]="tarTitle" type="text"
                            placeholder="Add Title"
                            style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
                    </label>
                </div>
            </div>

            <div *ngIf="optAVisible" class="col-md-6 col-lg-6"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <div class="label-header">
                    <label class="label-headereq">Option A: {{ targetTitle }}
                        <input [(ngModel)]="optATitle" type="text" placeholder="Add Title"
                            style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
                    </label>
                </div>
            </div>

            <div *ngIf="optBVisible" class="col-md-6 col-lg-6"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <div class="label-header">
                    <label class="label-headereq">Option B: {{ targetTitle }}
                        <input [(ngModel)]="optBTitle" type="text" placeholder="Add Title"
                            style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
                    </label>
                </div>
            </div>

            <div *ngIf="optCVisible" class="col-md-6 col-lg-6"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <div class="label-header">
                    <label class="label-headereq">Option C: {{ targetTitle }}
                        <input [(ngModel)]="optCTitle" type="text" placeholder="Add Title"
                            style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
                    </label>
                </div>
            </div>

            <div class="col-md-1 col-lg-1"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <div class="label-header">
                    <button [disabled]="!isEditable" [ngClass]="{ 'click-not-allowed': !isEditable }"
                        (click)="addNewRow()" title="Add">
                        <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add" />
                        <label class="label-headereq">add Option</label>
                    </button>
                </div>
            </div>
        <!-- </div> -->
        <br>
        <!-- <div style="margin-top: -10px;"> -->
        
            <div class="col-md-5 col-lg-5 summary"
                style="display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px; position: sticky; left: 0; z-index: 2;">
                <ag-grid-angular #configGridSummary *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine"
                    id="configGridSummary" [rowData]="gridDataSummary" [columnDefs]="configColumnDefsSummary"
                    [groupDefaultExpanded]="1" [groupUseEntireRow]="true" [domLayout]="domLayoutConfigSummary"
                    [frameworkComponents]="frameworkComponents"
                    [defaultColDef]="configDefaultColDefSummary" [enableBrowserTooltips]="true"
                    [popupParent]="popupParent" (cellValueChanged)="onConfigCellValueChangedSummary($event)"
                    (gridReady)="onConfigGridReadySummary($event)"
                    [groupRowRendererParams]="groupRowRendererParamsSummary"
                    (rowGroupOpened)="onConfigRowGroupOpenedSummary($event)"
                    (gridSizeChanged)="onGridConfigChangedSummary($event)" [rowHeight]="getRowHeightConfigSummary"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedConfigSummary($event)">
                </ag-grid-angular>
            </div>

            <div class="col-md-3 col-lg-3 summary"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #refGridSummary *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine"
                    id="refGridSummary" [rowData]="gridDataSummary" [columnDefs]="refColumnDefsSummary"
                    [groupDefaultExpanded]="1" [groupUseEntireRow]="true" [domLayout]="domLayoutRefSummary"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="refDefaultColDefSummary"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent"
                    (cellValueChanged)="onRefCellValueChangedSummary($event)"
                    [groupRowRendererParams]="groupRowRendererParamsSummary" (gridReady)="onRefGridReadySummary($event)"
                    (rowGroupOpened)="onRefRowGroupOpenedSummary($event)"
                    (gridSizeChanged)="onGridRefChangedSummary($event)" [rowHeight]="getRowHeightRefSummary"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedRefSummary($event)">
                </ag-grid-angular>
            </div>

            <div class="col-md-6 col-lg-6 summary"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #tarGridSummary *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine"
                    id="tarGridSummary" [rowData]="gridDataSummary" [columnDefs]="tarColumnDefsSummary"
                    [groupDefaultExpanded]="1" [groupUseEntireRow]="true" [domLayout]="domLayoutTarSummary"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="tarDefaultColDefSummary"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent"
                    (cellValueChanged)="onTarCellValueChangedSummary($event)"
                    [groupRowRendererParams]="groupRowRendererParamsSummary" (gridReady)="onTarGridReadySummary($event)"
                    (rowGroupOpened)="onTarRowGroupOpenedSummary($event)"
                    (gridSizeChanged)="onGridTarChangedSummary($event)" [rowHeight]="getRowHeightTarSummary"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedTarSummary($event)">
                </ag-grid-angular>
            </div>

            <div *ngIf="optAVisible" class="col-md-6 col-lg-6 summary"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #optAGridSummary *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine"
                    id="optAGridSummary" [rowData]="gridDataSummary" [columnDefs]="optAColumnDefsSummary"
                    [groupDefaultExpanded]="1" [groupUseEntireRow]="true" [domLayout]="domLayoutOptASummary"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="optADefaultColDefSummary"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent"
                    (cellValueChanged)="onOptACellValueChangedSummary($event)"
                    [groupRowRendererParams]="groupRowRendererParamsSummary"
                    (gridReady)="onOptAGridReadySummary($event)" (rowGroupOpened)="onOptARowGroupOpenedSummary($event)"
                    (gridSizeChanged)="onGridOptAChangedSummary($event)" [rowHeight]="getRowHeightOptASummary"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedOptASummary($event)">
                </ag-grid-angular>
            </div>

            <div *ngIf="optBVisible" class="col-md-6 col-lg-6 summary"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #optBGridSummary *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine"
                    id="optBGridSummary" [rowData]="gridDataSummary" [columnDefs]="optBColumnDefsSummary"
                    [groupDefaultExpanded]="1" [groupUseEntireRow]="true" [domLayout]="domLayoutOptBSummary"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="optBDefaultColDefSummary"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent"
                    (cellValueChanged)="onOptBCellValueChangedSummary($event)"
                    [groupRowRendererParams]="groupRowRendererParamsSummary"
                    (gridReady)="onOptBGridReadySummary($event)" (rowGroupOpened)="onOptBRowGroupOpenedSummary($event)"
                    (gridSizeChanged)="onGridOptBChangedSummary($event)" [rowHeight]="getRowHeightOptBSummary"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedOptBSummary($event)">
                </ag-grid-angular>
            </div>

            <div *ngIf="optCVisible" class="col-md-6 col-lg-6 summary"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #optCGridSummary *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine"
                    id="optCGridSummary" [rowData]="gridDataSummary" [columnDefs]="optCColumnDefsSummary"
                    [groupDefaultExpanded]="1" [groupUseEntireRow]="true" [domLayout]="domLayoutOptCSummary"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="optCDefaultColDefSummary"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent"
                    (cellValueChanged)="onOptCCellValueChangedSummary($event)"
                    [groupRowRendererParams]="groupRowRendererParamsSummary"
                    (gridReady)="onOptCGridReadySummary($event)" (rowGroupOpened)="onOptCRowGroupOpenedSummary($event)"
                    (gridSizeChanged)="onGridOptCChangedSummary($event)" [rowHeight]="getRowHeightOptCSummary"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedOptCSummary($event)">
                </ag-grid-angular>
            </div>
        <!-- </div> -->
        <br>
        <!-- <div style="margin-top: -10px;"> -->
            <div class="col-md-5 col-lg-5"
                style="display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px; position: sticky; left: 0; z-index: 2;">
                <ag-grid-angular #configGrid *ngIf="showGrid" style="width: 100%; height: 300px" class="ag-theme-alpine"
                    id="configGrid" [rowData]="gridData" [columnDefs]="configColumnDefs" [groupDefaultExpanded]="1"
                    [groupUseEntireRow]="true" 
                    [groupRowRendererParams]="groupRowRendererParams" [frameworkComponents]="frameworkComponents"
                    [defaultColDef]="configDefaultColDef" [enableBrowserTooltips]="true" [popupParent]="popupParent"
                    [rowClassRules]="rowClassRules" (cellValueChanged)="onConfigCellValueChanged($event)"
                    (filterChanged)="onFilterChanged($event)" (gridReady)="onConfigGridReady($event)"
                    (rowGroupOpened)="onConfigRowGroupOpened($event)" (gridSizeChanged)="onGridConfigChanged($event)"
                    [rowHeight]="getRowHeightConfig"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedConfig($event)">
                </ag-grid-angular>
            </div>

            <div class="col-md-3 col-lg-3"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #referenceGrid *ngIf="showGrid" style="width: 100%; height: 300px" class="ag-theme-alpine"
                    id="refGrid" [rowData]="gridData" [columnDefs]="refColumnDefs" [groupDefaultExpanded]="1"
                    [groupSuppressAutoColumn]="true" [suppressAggFuncInHeader]="true"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="refDefaultColDef"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent" [rowClassRules]="rowClassRules"
                    (cellValueChanged)="onRefCellValueChanged($event)" (gridReady)="onRefGridReady($event)"
                    (gridSizeChanged)="onGridRefChanged($event)" [rowHeight]="getRowHeightRef"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedRef($event)">
                </ag-grid-angular>
            </div>

            <div class="col-md-6 col-lg-6"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #targetGrid *ngIf="showGrid" style="width: 100%; height: 300px;" class="ag-theme-alpine" id="tarGrid"
                    [rowData]="gridData" [columnDefs]="tarColumnDefs" [groupDefaultExpanded]="1"
                    [groupSuppressAutoColumn]="true" [suppressAggFuncInHeader]="true"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="tarDefaultColDef"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent" [rowClassRules]="rowClassRules"
                    (cellValueChanged)="onTarCellValueChanged($event)" (gridReady)="onTarGridReady($event)"
                    (gridSizeChanged)="onGridTarChanged($event)" [rowHeight]="getRowHeightTar"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedTar($event)">
                </ag-grid-angular>
            </div>

            <div *ngIf="optAVisible" class="col-md-6 col-lg-6"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #optAGrid *ngIf="showGrid" style="width: 100%; height: 300px;" class="ag-theme-alpine" id="optAGrid"
                    [rowData]="gridData" [columnDefs]="optAColumnDefs" [groupDefaultExpanded]="1"
                    [groupSuppressAutoColumn]="true" [suppressAggFuncInHeader]="true"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="optADefaultColDef"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent" [rowClassRules]="rowClassRules"
                    (cellValueChanged)="onOptACellValueChangedCS($event)" (gridReady)="onOptAGridReady($event)"
                    (gridSizeChanged)="onGridOptAChanged($event)" [rowHeight]="getRowHeightoptA"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedOptA($event)">
                </ag-grid-angular>
            </div>

            <div *ngIf="optBVisible" class="col-md-6 col-lg-6"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #optBGrid *ngIf="showGrid" style="width: 100%; height: 300px;" class="ag-theme-alpine" id="optBGrid"
                    [rowData]="gridData" [columnDefs]="optBColumnDefs" [groupDefaultExpanded]="1"
                    [groupSuppressAutoColumn]="true" [suppressAggFuncInHeader]="true"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="optBDefaultColDef"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent" [rowClassRules]="rowClassRules"
                    (cellValueChanged)="onOptBCellValueChangedCS($event)" (gridReady)="onOptBGridReady($event)"
                    (gridSizeChanged)="onGridOptBChanged($event)" [rowHeight]="getRowHeightoptB"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedOptB($event)">
                </ag-grid-angular>
            </div>

            <div *ngIf="optCVisible" class="col-md-6 col-lg-6"
                style="margin-left: 5px; display: inline-block; padding-left: 0px; margin-top: 0px; padding-right: 0px;">
                <ag-grid-angular #optCGrid *ngIf="showGrid" style="width: 100%; height: 300px;" class="ag-theme-alpine" id="optCGrid"
                    [rowData]="gridData" [columnDefs]="optCColumnDefs" [groupDefaultExpanded]="1"
                    [groupSuppressAutoColumn]="true" [suppressAggFuncInHeader]="true"
                    [frameworkComponents]="frameworkComponents" [defaultColDef]="optCDefaultColDef"
                    [enableBrowserTooltips]="true" [popupParent]="popupParent" [rowClassRules]="rowClassRules"
                    (cellValueChanged)="onOptCCellValueChangedCS($event)" (gridReady)="onOptCGridReady($event)"
                    (gridSizeChanged)="onGridOptCChanged($event)" [rowHeight]="getRowHeightoptC"
                    (displayedColumnsChanged)="onDisplayedColumnsChangedOptC($event)">
                </ag-grid-angular>
            </div>
        <!-- </div> -->

    </div>
    <div class="row" style="margin-top:0px">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle" style="float: right; margin-right: 5px; margin-bottom: -20px">
                <button class="button button--small" (click)="onClickCancel()">
                    Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
                    Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
                    Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="submit('save')">
                    Save</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="submit($event)" type="submit">
                    Save & Exit
                </button>
            </div>
        </div>
    </div>


    <br>
    <app-commentbox [configData]="objData"></app-commentbox>


</div>