import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { ProjectService } from 'src/app/services/project.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { messages } from 'src/app/popUpMessages/messages';
import { ScmtargetcostService } from 'src/app/services/scmtargetcost.service';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { SCMLCActual, SCMLCForecast, SCMLCScenario, SCMLCTargetCost, TCSCMLCTargetCost } from 'src/app/models/tcscmtarget-cost';
import { ToasterService } from 'src/app/services/toaster.service';
import { EnumSCMComponent } from 'src/app/enum/enum-scmcomponent';
let countryCode;
@Component({
  selector: 'app-tc-local',
  templateUrl: './tc-local.component.html',
  styleUrls: ['./tc-local.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TcLocalComponent implements OnInit, OnDestroy {
  projectId: any;
  objData: { projectId: any; moduleId: any; };
  moduleId: number = 9;

  isEditable: boolean = true;
  public submitted: boolean = false;
  hasUnsavedChanges: boolean;
  showGrid: boolean = false;
  optAVisible: boolean = false;
  optCVisible: boolean = false;
  optBVisible: boolean = false;
  refColumnDefs: any;
  refDefaultColDef: any;
  refFrameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };
  refGridData: any[];
  tarColumnDefs: any;
  tarDefaultColDef: any;
  tarGridData: any[];
  tarFrameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };
  optAColumnDefs: any;
  optADefaultColDef: any;
  optAFrameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };
  optAGridData: any[];
  optBColumnDefs: any;
  optBDefaultColDef: any;
  optBrameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };
  optBGridData: any[];
  optCColumnDefs: any;
  optCDefaultColDef: any;
  optCFrameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };
  optCGridData: any[];
  businessAreaId: any;
  businessLineId: any;
  targetTitle: string;
  public userInOrg: any;
  public allUserList: any;
  projectSubmitterId: any;
  refGridApi: any;
  tarGridApi: any;
  optAGridApi: any;
  optBGridApi: any;
  optCGridApi: any;
  tarPinnedBottomData: any;
  refPinnedBottomData: any;
  DirectCostSCMRef: number;
  regex: any;
  configurationId: any;
  lcMasterData: any;
  localCostData: any[];

  refTitle: string = "";
  tarTitle: string = "";
  optATitle: string = "";
  optBTitle: string = "";
  optCTitle: string = "";
  rowClassRulesRef: { colorTotal: (params: any) => boolean; };

  EQTargetCostMek: any;
  EQTargetCostRefMek: number;
  EQTargetCostTarMek: number;
  EQTargetCostOptAMek: number;
  EQTargetCostOptBMek: number;
  EQTargetCostOptCMek: number;

  EQTargetCostTarActualMek: number;
  EQTargetCostOptAActualMek: number;
  EQTargetCostOptBActualMek: number;
  EQTargetCostOptCActualMek: number;

  public modifiedRefSCMLabourRows = [];
  public modifiedTarSCMLabourRows = [];
  public modifiedOptASCMLabourRows = [];
  public modifiedOptBSCMLabourRows = [];
  public modifiedOptCSCMLabourRows = [];
  tarGridAApi: any;
  frameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };
  DirectCostSCMTar: number;
  tarGridCApi: any;
  tarGridBApi: any;
  submitResponse: any;
  AddOptionARow: number = 0;
  AddOptionBRow: number = 0;
  AddOptionCRow: number = 0;
  rowHeight: number;
  headerHeight: number;
  sizeToFitRefreshRef: number = 0;
  sizeToFitRefreshTar: number = 0;
  domLayout: string;
  configSub: any;
  constructor(
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userService: UserInfoService,
    private menuService: MenuService,
    private ScmtargetcostService: ScmtargetcostService,
  ) { }

  ngOnInit(): void {
    this.DirectCostSCMRef = 0;
    this.DirectCostSCMTar = 0;
    this.sharedService.getProjectIdAndTitle();
    this.projectId = this.localStorageService.get("projectId");
    this.objData = {
      projectId: this.projectId,
      moduleId: this.moduleId
    }
    countryCode = this.localStorageService.get('countryCode');

    if (this.projectId != null) {
      this.projectService.getTCData(this.projectId).subscribe((data: any) => {
        if (data.StatusCode == 200) {
          this.businessAreaId = data.Data.BusinessAreaId;
          this.businessLineId = data.Data.BusinessLineId;
          this.sharedService.loggedInUser.subscribe((user: any) => {
            if (user) {
              this.showGrid = true
              this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
                this.allUserList = d;
                this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
                if (this.userInOrg != undefined) {
                  this.projectSubmitterId = this.userInOrg.UserId;
                  if (this.userInOrg.TargetRoleId == 1 || this.userInOrg.TargetRoleId == 2) {
                    this.isEditable = true;
                  }
                  else if (this.userInOrg.TargetRoleId == 3) {
                    if (data.Data.SubmitterId == this.userInOrg.UserId || data.Data.FinancialControllerId == this.userInOrg.UserId
                      || data.Data.ProductManagerId == this.userInOrg.UserId || data.Data.ProjectManagerId == this.userInOrg.UserId ||
                      data.Data.ServiceManagerId == this.userInOrg.UserId || data.Data.ProcurementEngineerId == this.userInOrg.UserId) {
                      this.isEditable = true;
                    }
                    else {
                      this.isEditable = false;
                    }
                  }
                }
                this.loadData();
                this.loadRefGridStructure();
                this.loadTarGridStructure();
                this.loadOptAGridStructure();
                this.loadOptBGridStructure();
                this.loadOptCGridStructure();
              })
            }
          })
        }
      });
    } else {
      this.businessAreaId = this.businessLineId = 0;
    }
  }
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(["/tc-manageProject"]);
  }
  loadData() {
    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d;
      if (this.configurationId == 0) {
        return;
      }
      this.optAVisible = false;
      this.optBVisible = false;
      this.optCVisible = false;
      //  this.localStorageService.set("ConfigId", this.configurationID);
      //this.configurationId = this.localStorageService.get('ConfigId');
      this.ScmtargetcostService.getLCTargetCostData(this.configurationId).subscribe(d => {
        this.showGrid = true;
        let errorMsgeFromBack = d.Data.Message;
        if (d.StatusCode == 200) {

          this.lcMasterData = d.Data;

          if (this.lcMasterData.EQTargetCostSummaries.length > 0) {
            this.EQTargetCostMek = this.lcMasterData.EQTargetCostSummaries.filter(item => item.Name === 'MEKTotal')

            if (this.EQTargetCostMek.length != 0) {

              this.EQTargetCostRefMek = 0;
              this.EQTargetCostTarMek = 0;
              this.EQTargetCostOptAMek = 0;
              this.EQTargetCostOptBMek = 0;
              this.EQTargetCostOptCMek = 0;

              if (this.EQTargetCostMek[0].ReferenceScenario)
                this.EQTargetCostRefMek = this.EQTargetCostMek[0].ReferenceScenario.PerSystem ?? 0

              if (this.EQTargetCostMek[0].TargetScenario) {
                this.EQTargetCostTarMek = this.EQTargetCostMek[0].TargetScenario.TargetCost.PerSystem ?? 0
                this.EQTargetCostTarActualMek = this.EQTargetCostMek[0].TargetScenario.ActualMEKs ?? 0
              }
              if (this.EQTargetCostMek[0].OptionA) {
                this.EQTargetCostOptAMek = this.EQTargetCostMek[0].OptionA.TargetCost.PerSystem ?? 0
                this.EQTargetCostOptAActualMek = this.EQTargetCostMek[0].OptionA.TargetCost.ActualMEKs ?? 0
              }

              if (this.EQTargetCostMek[0].OptionB) {
                this.EQTargetCostOptBMek = this.EQTargetCostMek[0].OptionB.TargetCost.PerSystem ?? 0
                this.EQTargetCostOptBActualMek = this.EQTargetCostMek[0].OptionB.TargetCost.ActualMEKs ?? 0
              }

              if (this.EQTargetCostMek[0].OptionC) {
                this.EQTargetCostOptCMek = this.EQTargetCostMek[0].OptionC.TargetCost.PerSystem ?? 0
                this.EQTargetCostOptCActualMek = this.EQTargetCostMek[0].OptionC.TargetCost.ActualMEKs ?? 0
              }
            }
          }

          if (this.lcMasterData.SCMLCTargetCosts.length > 0) {
            this.localCostData = this.lcMasterData.SCMLCTargetCosts;
            if (this.localCostData.length != 0) {
              if (this.localCostData[0].OptionA)
                this.optAVisible = true
              if (this.localCostData[0].OptionB)
                this.optBVisible = true
              if (this.localCostData[0].OptionC)
                this.optCVisible = true
            }

            this.refTitle = this.lcMasterData.SCMLCTargetCosts[0].ReferenceScenario.ScenarioTitle ?? ""
            this.tarTitle = this.lcMasterData.SCMLCTargetCosts[0].TargetScenario.ScenarioTitle ?? ""
            this.optATitle = this.lcMasterData.SCMLCTargetCosts[0].OptionA?.ScenarioTitle ?? ""
            this.optBTitle = this.lcMasterData.SCMLCTargetCosts[0].OptionB?.ScenarioTitle ?? ""
            this.optCTitle = this.lcMasterData.SCMLCTargetCosts[0].OptionC?.ScenarioTitle ?? ""

            this.refGridData = [];
            this.tarGridData = [];
            this.optAGridData = [];
            this.optBGridData = [];
            this.optCGridData = [];


            for (let localdata of this.localCostData) {
              this.bindRefDatatoGrid(localdata, this.refGridData)
              this.bindTargetDatatoGrid(localdata, this.tarGridData)
              if (localdata.OptionA)
                this.bindOptionADatatoGrid(localdata, this.optAGridData)
              if (localdata.OptionB)
                this.bindOptionBDatatoGrid(localdata, this.optBGridData)
              if (localdata.OptionC)
                this.bindOptionCDatatoGrid(localdata, this.optCGridData)
            }



          }
          else {
            this.refGridData = [];
            this.tarGridData = [];
            this.optAGridData = [];
            this.optBGridData = [];
            this.optCGridData = [];
            this.refTitle = ""
            this.tarTitle = ""
            this.optATitle = ""
            this.optBTitle = ""
            this.optCTitle = ""
          }
        }

        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      });
    })
  }
  AddNewRowRef() {
    this.validateAddReference();
  }

  validateAddReference() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.refGridApi.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.refGridApi.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.DirectCostSCMRef = this.DirectCostSCMRef - 1;
      this.refGridApi.applyTransaction({
        add: [{
          SCMId: 0,
          Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
          ComponentId: this.DirectCostSCMRef,
          ConfigurationId: this.configurationId,
          SubComponent: null,
          Quantity: null,
          Weight: null,
          Absolute: null,
          PercentOfMEK: null,
          Total: null,
          IsActive: true,
          CreatedBy: 1,
          CreatedOn: null,
          ModifiedBy: 1,
          ModifiedOn: null,
          IsEditable: this.isEditable

        }], addIndex: 0
      })
      this.tarGridApi.applyTransaction({
        add: [{
          SCMIdTargetCost: 0,
          SCMIdForecast: 0,
          SCMIdActual: 0,
          Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
          ComponentId: this.DirectCostSCMRef,
          ConfigurationId: this.configurationId,
          SubComponent: null,
          ReferenceCost: null,
          TargetQuantity: null,
          ForecastQuantity: null,
          ActualsQuantity: null,
          TargetWeight: null,
          ForecastWeight: null,
          ActualsWeight: null,
          TargetAbsolute: null,
          ForecastAbsolute: null,
          ActualAbsolute: null,
          TargetPercentOfMEK: null,
          ForecastPercentOfMEK: null,
          ActualPercentOfMEK: null,
          TargetTotal: null,
          ForecastTotal: null,
          ActualTotal: null,
          DeltaTCVsRef: null,
          DeltaFCVsRef: null,
          DeltaTCVsACT: null,
          IsActive: true,
          CreatedBy: 1,
          CreatedOn: null,
          ModifiedBy: 1,
          ModifiedOn: null,
          IsEditable: this.isEditable

        }], addIndex: 0
      })
      if (this.optAVisible == true) {
        this.tarGridAApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
      if (this.optBVisible == true) {
        this.tarGridBApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
      if (this.optCVisible == true) {
        this.tarGridCApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
    } else {
      let data = this.refGridApi.getDisplayedRowAtIndex(0).data;
      // let checked = this.regex.test(data.refereceScenerioTitle)
      if (data.SubComponent === "Add Component Here" || data.SubComponent === "" || data.SubComponent === null || data.SubComponent === undefined) {
        error.push({ "Local Cost": errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.DirectCostSCMRef = this.DirectCostSCMRef - 1;
        this.refGridApi.applyTransaction({
          add: [{
            SCMId: 0,
            Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: "",
            Quantity: null,
            Weight: null,
            Absolute: null,
            PercentOfMEK: null,
            Total: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
        this.tarGridApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
        if (this.optAVisible == true) {
          this.tarGridAApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
        if (this.optBVisible == true) {
          this.tarGridBApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
        if (this.optCVisible == true) {
          this.tarGridCApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.LocalCosts],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
      } else {
        this.openAlertDialog(error)
      }
    }
  }
  addNewRow() {
    if (this.optAVisible == true && this.optBVisible == true) {
      this.optCVisible = true;
      this.AddOptionCRow = 1;
    }
    if (this.optAVisible == true) {
      this.optBVisible = true;
      this.AddOptionBRow = 1;
    }
    if (this.optBVisible == false) {

      this.optAVisible = true;
      this.AddOptionARow = 1;
    }
    this.hasUnsavedChanges = true;
  }
  bindRefDatatoGrid(i, target) {
    target.push({
      SCMId: i.SCMId,
      ConfigurationId: i.ConfigurationId,
      SubComponent: i.SubComponent,
      ComponentId: i.ComponentId,
      Component: i.Component,
      Quantity: i.ReferenceScenario.Quantity,
      Weight: i.ReferenceScenario.Weight,
      Absolute: i.ReferenceScenario.Absolute,
      PercentOfMEK: i.ReferenceScenario.PercentOfMEK,
      Total: i.ReferenceScenario.Total,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable,
    })
  }

  loadRefGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Local Costs';
      }
    }
    this.refColumnDefs = [
      {
        headerName: "Local Costs",
        field: "SubComponent",
        tooltipField: "Local Costs",
        width: 250,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Local Costs",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },

        singleClickEdit: true,
        // cellStyle: {  borderLeft: '5px solid #a94442' },
        cellStyle: params => {
          if (params.node.data.SubComponent != 'Total Local Costs' && (params.data.SubComponent == null
            || params.data.SubComponent == '')) {
            //mark police cells as red
            return { borderLeft: '5px solid #a94442' };
          }
          return { borderLeft: '' };
        }

      },

      {
        headerName: "Quantity",
        field: "Quantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "Weight",
        tooltipField: "Weight",
        width: 60,
        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,


      },
      {
        headerName: "Costs",
        headerTooltip: "Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "Absolute",
            tooltipField: "Absolute",
            headerTooltip: "Absolute",
            width: 88,
            // editable: this.isEditable,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.Absolute)
                  })
                  params.data.Absolute = total;
                  return total;
                }
              } else {
                return (params.data.Absolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                return false;
              }
              else if (params.node.data.PercentOfMEK === "" || params.node.data.PercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "PercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,
            editable: function (params) {
              if (params.node.data.Absolute === "" || params.node.data.Absolute === null || params.node.data.Absolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

            singleClickEdit: true
          },
          {
            headerName: "Total",
            field: "Total",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,
            // editable: false,

            // cellRenderer: CurrencyCellRenderer,tooltipValueGetter:CurrencyCellRenderer, valueGetter: Total
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;
                params.api.forEachNode((node) => {

                  total = Number(node.data.Total) + total;


                })
                params.data.Total = total;
                return total;
              }
              else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.PercentOfMEK === null || params.node.data.PercentOfMEK === 0 || params.node.data.PercentOfMEK === "") {

                    total = Number(params.data.Quantity) * Number(params.data.Weight) * Number(params.data.Absolute);
                  }
                  else {
                    permek = Number(params.data.PercentOfMEK) / 100;
                    total = Number(params.data.Quantity) * Number(params.data.Weight) * permek * this.EQTargetCostRefMek;
                  }
                }
                params.data.Total = total;
                return total;
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs' ||
                params.node.data.PercentOfMEK != "" || params.node.data.PercentOfMEK != null
              ) {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      }

    ]
    this.domLayout = 'autoHeight';
    this.refDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.refFrameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
  }

  bindTargetDatatoGrid(i, target) {
    target.push({
      SCMIdTargetCost: i.TargetScenario.TargetCost?.SCMId ?? 0,
      SCMIdForecast: i.TargetScenario.Forecast?.SCMId ?? 0,
      SCMIdActual: i.TargetScenario.Actual?.SCMId ?? 0,
      ConfigurationId: i.ConfigurationId,
      SubComponent: i.SubComponent,
      ComponentId: i.ComponentId,
      Component: i.Component,
      ReferenceCost: i.ReferenceScenario.Total,
      TargetQuantity: i.TargetScenario.TargetCost?.Quantity ?? null,
      ForecastQuantity: i.TargetScenario.Forecast?.Quantity ?? null,
      ActualsQuantity: i.TargetScenario.Actual?.Quantity ?? null,
      TargetWeight: i.TargetScenario.TargetCost?.Weight ?? null,
      ForecastWeight: i.TargetScenario.Forecast?.Weight ?? null,
      ActualsWeight: i.TargetScenario.Actual?.Weight ?? null,
      TargetAbsolute: i.TargetScenario.TargetCost?.Absolute ?? null,
      ForecastAbsolute: i.TargetScenario.Forecast?.Absolute ?? null,
      ActualAbsolute: i.TargetScenario.Actual?.Absolute ?? null,
      TargetPercentOfMEK: i.TargetScenario.TargetCost?.PercentOfMEK ?? null,
      ForecastPercentOfMEK: i.TargetScenario.Forecast?.PercentOfMEK ?? null,
      ActualPercentOfMEK: i.TargetScenario.Actual?.PercentOfMEK ?? null,
      TargetTotal: i.TargetScenario.TargetCost?.Total ?? null,
      ForecastTotal: i.TargetScenario.Forecast?.Total ?? null,
      ActualTotal: i.TargetScenario.Actual?.Total ?? null,
      DeltaTCVsRef: i.TargetScenario.TargetCost?.DeltaTCVsRef ?? null,
      DeltaFCVsRef: i.TargetScenario.Forecast?.DeltaFCVsRef ?? null,
      DeltaTCVsACT: i.TargetScenario.Actual?.DeltaTCVsACT ?? null,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable,
    })
  }

  loadTarGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Local Costs';
      }
    }
    this.tarColumnDefs = [
      {
        headerName: "SubComponent",
        field: "SubComponent",
        tooltipField: "SubComponent",
        hide: true
      },
      {
        headerName: "Quantity",
        field: "TargetQuantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "TargetWeight",
        tooltipField: "Weight",
        width: 60,
        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "TargetAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,
            // editable: this.isEditable,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.TargetAbsolute)
                  })
                  params.data.TargetAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.TargetAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                return false;
              }
              else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }


            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.TargetAbsolute === "" || params.node.data.TargetAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },


          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,
            // editable: false,

            cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.TargetTotal

            , cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false, singleClickEdit: true
          },
          {
            headerName: "Delta TC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaTCVsRef",
            tooltipField: "Delta TC vs. Reference",
            headerTooltip: "Delta TC vs. Reference",
            width: 66,
            // editable: false,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent != 'Total Local Costs') {

                if (params.node.data.TargetTotal == null || Number(params.node.data.TargetTotal) == 0) {
                  return 0
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetTotal) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
                }

                params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? 0 : params.node.data.DeltaTCVsRef
                return params.node.data.DeltaTCVsRef;
              } else {
                let target = 0;
                let ref = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    target += Number(node.data.TargetTotal)
                    ref += Number(node.data.ReferenceCost)
                  })
                  params.data.DeltaTCVsRef = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
                  return params.data.DeltaTCVsRef;
                }
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            // function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs') {
            //     return false;
            //   }
            //   else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null || params.node.data.TargetPercentOfMEK === 0) {
            //     return params.data.IsEditable;
            //   }
            //   else {
            //     return false;
            //   }
            // }
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ForecastAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 88,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ForecastAbsolute)
                  })
                  params.data.ForecastAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ForecastAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                return false;
              }
              else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,

            editable: function (params) {
              if (params.node.data.ForecastAbsolute === "" || params.node.data.ForecastAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;
                params.api.forEachNode((node) => {
                  total = Number(node.data.ForecastTotal) + total;
                })
                params.data.ForecastTotal = total;
                return total;
              } else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.ForecastPercentOfMEK != null) {
                    permek = Number(params.data.ForecastPercentOfMEK) / 100;
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * this.EQTargetCostTarMek;
                    //abs = Number(node.data.Absolute) + abs

                  }
                  else {
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ForecastAbsolute);
                  }
                }
                params.data.ForecastTotal = total;
                return total;

              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            // function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs') {
            //     return false;
            //   }
            //   else {
            //     return params.data.IsEditable;
            //   }

            // }
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "Delta FC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaFCVsRef",
            tooltipField: "Delta FC vs. Reference",
            headerTooltip: "Delta FC vs. Reference",
            width: 88,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ForecastDeltaFCVsRef, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            //  function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs') {
            //     return false;
            //   }
            //   else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null) {
            //     return params.data.IsEditable;
            //   }
            //   else {
            //     return false;
            //   }
            // }
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ActualAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;
                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ActualAbsolute)
                  })
                  params.data.ActualAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ActualAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                return false;
              }
              else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.ActualAbsolute === "" || params.node.data.ActualAbsolute === null) {

                return params.data.IsEditable;
              }

              else {
                return false;

              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.ActualTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            //  function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs') {
            //     return false;
            //   }
            //   else {
            //     return params.data.IsEditable;
            //   }
            // }
            , singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. ACT",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaTCVsACT",
            tooltipField: "Delta TC vs. ACT",
            headerTooltip: "Delta TC vs. ACT",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ActualDeltaTCVsACT, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            //  function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs'
            //   ) {
            //     return false;
            //   }
            //   else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null) {
            //     return params.data.IsEditable;
            //   }
            //   else {
            //     return false;
            //   }
            // }
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      },

    ]
    // this.tarDomLayout = 'autoHeight';
    this.tarDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      // autoHeight: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.tarFrameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    this.tarGridData = [];
  }
  bindOptionADatatoGrid(i, target) {
    target.push({
      SCMIdTargetCost: i.OptionA?.TargetCost?.SCMId ?? 0,
      SCMIdForecast: i.OptionA?.Forecast?.SCMId ?? 0,
      SCMIdActual: i.OptionA?.Actual?.SCMId ?? 0,
      ConfigurationId: i.ConfigurationId,
      SubComponent: i.SubComponent,
      Component: i.Component,
      ReferenceCost: i.ReferenceScenario.Total,
      TargetQuantity: i.OptionA?.TargetCost?.Quantity ?? null,
      ForecastQuantity: i.OptionA?.Forecast?.Quantity ?? null,
      ActualsQuantity: i.OptionA?.Actual?.Quantity ?? null,
      TargetWeight: i.OptionA?.TargetCost?.Weight ?? null,
      ForecastWeight: i.OptionA?.Forecast?.Weight ?? null,
      ActualsWeight: i.OptionA?.Actual?.Weight ?? null,
      TargetAbsolute: i.OptionA?.TargetCost?.Absolute ?? null,
      ForecastAbsolute: i.OptionA?.Forecast?.Absolute ?? null,
      ActualAbsolute: i.OptionA?.Actual?.Absolute ?? null,
      TargetPercentOfMEK: i.OptionA?.TargetCost?.PercentOfMEK ?? null,
      ForecastPercentOfMEK: i.OptionA?.Forecast?.PercentOfMEK ?? null,
      ActualPercentOfMEK: i.OptionA?.Actual?.PercentOfMEK ?? null,
      TargetTotal: i.OptionA?.TargetCost?.Total ?? null,
      ForecastTotal: i.OptionA?.Forecast?.Total ?? null,
      ActualTotal: i.OptionA?.Actual?.Total ?? null,
      DeltaTCVsRef: i.OptionA?.TargetCost?.DeltaTCVsRef ?? null,
      DeltaFCVsRef: i.OptionA?.Forecast?.DeltaFCVsRef ?? null,
      DeltaTCVsACT: i.OptionA?.Actual?.DeltaTCVsACT ?? null,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable,
    })
  }

  loadOptAGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Local Costs';
      }
    }
    this.optAColumnDefs = [
      {
        headerName: "SubComponent",
        field: "SubComponent",
        tooltipField: "SubComponent",
        hide: true
      },
      {
        headerName: "Quantity",
        field: "TargetQuantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "TargetWeight",
        tooltipField: "Weight",
        width: 60,
        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "TargetAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.TargetAbsolute)
                  })
                  params.data.TargetAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.TargetAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                return false;
              }
              else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null || params.node.data.TargetPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.TargetAbsolute === "" || params.node.data.TargetAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,

            cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.TargetTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            // function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs') {
            //     return false;
            //   }
            //   else {
            //     return params.data.IsEditable;
            //   }
            // }
            , singleClickEdit: true
          },
          {
            headerName: "Delta TC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaTCVsRef",
            tooltipField: "Delta TC vs. Reference",
            headerTooltip: "Delta TC vs. Reference",
            width: 66,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent != 'Total Local Costs') {

                if (params.node.data.TargetTotal == null || Number(params.node.data.TargetTotal) == 0) {
                  return 0
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetTotal) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
                }

                params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? 0 : params.node.data.DeltaTCVsRef
                return params.node.data.DeltaTCVsRef;
              } else {
                let target = 0;
                let ref = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    target += Number(node.data.TargetTotal)
                    ref += Number(node.data.ReferenceCost)
                  })
                  params.data.DeltaTCVsRef = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
                  return params.data.DeltaTCVsRef;
                }
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            //  function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs') {
            //     return false;
            //   }
            //   else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null) {
            //     return params.data.IsEditable;
            //   }
            //   else {
            //     return false;
            //   }
            // }
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ForecastAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 88,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ForecastAbsolute)
                  })
                  params.data.ForecastAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ForecastAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs'
              ) {
                return false;
              }
              else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,

            editable: function (params) {
              if (params.node.data.ForecastAbsolute === "" || params.node.data.ForecastAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },


          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;
                params.api.forEachNode((node) => {
                  total = Number(node.data.ForecastTotal) + total;
                })
                params.data.ForecastTotal = total;
                return total;
              } else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.ForecastPercentOfMEK != null) {
                    permek = Number(params.data.ForecastPercentOfMEK) / 100;
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * this.EQTargetCostOptAMek;
                    //abs = Number(node.data.Absolute) + abs

                  }
                  else {
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ForecastAbsolute);
                  }
                }
                params.data.ForecastTotal = total;
                return total;

              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            //  function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs') {
            //     return false;
            //   }
            //   else {
            //     return params.data.IsEditable;
            //   }

            // }
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "Delta FC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaFCVsRef",
            tooltipField: "Delta FC vs. Reference",
            headerTooltip: "Delta FC vs. Reference",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ForecastDeltaFCVsRef, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            //  function (params) {
            //   if (params.node.data.SubComponent === 'Total Local Costs'
            //   ) {
            //     return false;
            //   }
            //   else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null) {
            //     return params.data.IsEditable;
            //   }
            //   else {
            //     return false;
            //   }
            // }
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ActualAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ActualAbsolute)
                  })
                  params.data.ActualAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ActualAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs'
              ) {
                return false;
              }
              else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.ActualAbsolute === "" || params.node.data.ActualAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.ActualTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            //  editable: function (params) {
            //   if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' ||params.node.data.SubComponent==="Labour" || params.node.data.SubComponent==="Overhead") {
            //     return false;
            //   }
            //   else {
            //     return params.data.IsEditable;
            //   }



            // },

          },
          {
            headerName: "Delta TC vs. ACT",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaTCVsACT",
            tooltipField: "Delta TC vs. ACT",
            headerTooltip: "Delta TC vs. ACT",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ActualDeltaTCVsACT, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',

            editable: false,
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      },

    ]

    this.optADefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,

    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.optAFrameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    // this.optAGridLabourData = [];
  }

  bindOptionBDatatoGrid(i, target) {
    target.push({
      SCMIdTargetCost: i.OptionB?.TargetCost?.SCMId ?? 0,
      SCMIdForecast: i.OptionB?.Forecast?.SCMId ?? 0,
      SCMIdActual: i.OptionB?.Actual?.SCMId ?? 0,
      ConfigurationId: i.ConfigurationId,
      SubComponent: i.SubComponent,
      Component: i.Component,
      ReferenceCost: i.ReferenceScenario.Total,
      TargetQuantity: i.OptionB?.TargetCost?.Quantity ?? null,
      ForecastQuantity: i.OptionB?.Forecast?.Quantity ?? null,
      ActualsQuantity: i.OptionB?.Actual?.Quantity ?? null,
      TargetWeight: i.OptionB?.TargetCost?.Weight ?? null,
      ForecastWeight: i.OptionB?.Forecast?.Weight ?? null,
      ActualsWeight: i.OptionB?.Actual?.Weight ?? null,
      TargetAbsolute: i.OptionB?.TargetCost?.Absolute ?? null,
      ForecastAbsolute: i.OptionB?.Forecast?.Absolute ?? null,
      ActualAbsolute: i.OptionB?.Actual?.Absolute ?? null,
      TargetPercentOfMEK: i.OptionB?.TargetCost?.PercentOfMEK ?? null,
      ForecastPercentOfMEK: i.OptionB?.Forecast?.PercentOfMEK ?? null,
      ActualPercentOfMEK: i.OptionB?.Actual?.PercentOfMEK ?? null,
      TargetTotal: i.OptionB?.TargetCost?.Total ?? null,
      ForecastTotal: i.OptionB?.Forecast?.Total ?? null,
      ActualTotal: i.OptionB?.Actual?.Total ?? null,
      DeltaTCVsRef: i.OptionB?.TargetCost?.DeltaTCVsRef ?? null,
      DeltaFCVsRef: i.OptionB?.Forecast?.DeltaFCVsRef ?? null,
      DeltaTCVsACT: i.OptionB?.Actual?.DeltaTCVsACT ?? null,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable,
    })
  }

  loadOptBGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Local Costs';
      }
    }
    this.optBColumnDefs = [
      {
        headerName: "SubComponent",
        field: "SubComponent",
        tooltipField: "SubComponent",
        hide: true
      },
      {
        headerName: "Quantity",
        field: "TargetQuantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "TargetWeight",
        tooltipField: "Weight",
        width: 60,
        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "TargetAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.TargetAbsolute)
                  })
                  params.data.TargetAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.TargetAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs'
              ) {
                return false;
              }
              else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.TargetAbsolute === "" || params.node.data.TargetAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,


            cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.TargetTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
            singleClickEdit: true
          },
          {
            headerName: "Delta TC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaTCVsRef",
            tooltipField: "Delta TC vs. Reference",
            headerTooltip: "Delta TC vs. Reference",
            width: 66,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent != 'Total Local Costs') {

                if (params.node.data.TargetTotal == null || Number(params.node.data.TargetTotal) == 0) {
                  return 0
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetTotal) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
                }

                params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? 0 : params.node.data.DeltaTCVsRef
                return params.node.data.DeltaTCVsRef;
              } else {
                let target = 0;
                let ref = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    target += Number(node.data.TargetTotal)
                    ref += Number(node.data.ReferenceCost)
                  })
                  params.data.DeltaTCVsRef = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
                  return params.data.DeltaTCVsRef;
                }
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',

            editable: false,
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ForecastAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 88,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ForecastAbsolute)
                  })
                  params.data.ForecastAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ForecastAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs'
              ) {
                return false;
              }
              else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,

            editable: function (params) {
              if (params.node.data.ForecastAbsolute === "" || params.node.data.ForecastAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },


          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Local Costs')
              // if (params.node.data.SubComponent != 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs')
              {
                let total = 0;
                params.api.forEachNode((node) => {
                  total = Number(node.data.ForecastTotal) + total;
                })
                params.data.ForecastTotal = total;
                return total;
              } else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.ForecastPercentOfMEK != null) {
                    permek = Number(params.data.ForecastPercentOfMEK) / 100;
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * this.EQTargetCostOptBMek;
                    //abs = Number(node.data.Absolute) + abs

                  }
                  else {
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ForecastAbsolute);
                  }
                }
                params.data.ForecastTotal = total;
                return total;

              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
            // editable: function (params) {
            //   if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs') {
            //     return false;
            //   }
            //   else {
            //     return params.data.IsEditable;
            //   }

            // },
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "Delta FC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaFCVsRef",
            tooltipField: "Delta FC vs. Reference",
            headerTooltip: "Delta FC vs. Reference",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ForecastDeltaFCVsRef, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
            // editable: function (params) {
            //   if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs'
            //   ) {
            //     return false;
            //   }
            //   else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null) {
            //     return params.data.IsEditable;
            //   }
            //   else {
            //     return false;
            //   }
            // }, 
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ActualAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ActualAbsolute)
                  })
                  params.data.ActualAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ActualAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs'
              ) {
                return false;
              }
              else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.ActualAbsolute === "" || params.node.data.ActualAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.ActualTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
            // editable: function (params) {
            //   if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs') {
            //     return false;
            //   }
            //   else {
            //     return params.data.IsEditable;
            //   }
            // }, 
            singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. ACT",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaTCVsACT",
            tooltipField: "Delta TC vs. ACT",
            headerTooltip: "Delta TC vs. ACT",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ActualDeltaTCVsACT, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
            //  editable: function (params) {
            //   if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs'
            //   ) {
            //     return false;
            //   }
            //   else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null) {
            //     return params.data.IsEditable;
            //   }
            //   else {
            //     return false;
            //   }
            // }, 
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      }

    ]
    // this.tarDomLayout = 'autoHeight';
    this.optBDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      // autoHeight: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.optBrameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    // this.optBGridLabourData = [];
  }

  bindOptionCDatatoGrid(i, target) {
    target.push({
      SCMIdTargetCost: i.OptionC?.TargetCost?.SCMId ?? 0,
      SCMIdForecast: i.OptionC?.Forecast?.SCMId ?? 0,
      SCMIdActual: i.OptionC?.Actual?.SCMId ?? 0,
      ConfigurationId: i.ConfigurationId,
      SubComponent: i.SubComponent,
      Component: i.Component,
      ReferenceCost: i.ReferenceScenario.Total,
      TargetQuantity: i.OptionC?.TargetCost?.Quantity ?? null,
      ForecastQuantity: i.OptionC?.Forecast?.Quantity ?? null,
      ActualsQuantity: i.OptionC?.Actual?.Quantity ?? null,
      TargetWeight: i.OptionC?.TargetCost?.Weight ?? null,
      ForecastWeight: i.OptionC?.Forecast?.Weight ?? null,
      ActualsWeight: i.OptionC?.Actual?.Weight ?? null,
      TargetAbsolute: i.OptionC?.TargetCost?.Absolute ?? null,
      ForecastAbsolute: i.OptionC?.Forecast?.Absolute ?? null,
      ActualAbsolute: i.OptionC?.Actual?.Absolute ?? null,
      TargetPercentOfMEK: i.OptionC?.TargetCost?.PercentOfMEK ?? null,
      ForecastPercentOfMEK: i.OptionC?.Forecast?.PercentOfMEK ?? null,
      ActualPercentOfMEK: i.OptionC?.Actual?.PercentOfMEK ?? null,
      TargetTotal: i.OptionC?.TargetCost?.Total ?? null,
      ForecastTotal: i.OptionC?.Forecast?.Total ?? null,
      ActualTotal: i.OptionC?.Actual?.Total ?? null,
      DeltaTCVsRef: i.OptionC?.TargetCost?.DeltaTCVsRef ?? null,
      DeltaFCVsRef: i.OptionC?.Forecast?.DeltaFCVsRef ?? null,
      DeltaTCVsACT: i.OptionC?.Actual?.DeltaTCVsACT ?? null,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable,
    })
  }
  loadOptCGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Local Costs';
      }
    }
    this.optCColumnDefs = [
      {
        headerName: "SubComponent",
        field: "SubComponent",
        tooltipField: "SubComponent",
        hide: true
      },
      {
        headerName: "Quantity",
        field: "TargetQuantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "TargetWeight",
        tooltipField: "Weight",
        width: 60,
        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Local Costs') {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "TargetAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.TargetAbsolute)
                  })
                  params.data.TargetAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.TargetAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs'
              ) {
                return false;
              }
              else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.TargetAbsolute === "" || params.node.data.TargetAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,

            cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.TargetTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
            // editable: function (params) {
            //   if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
            //     return false;
            //   }
            //   else {
            //     return params.data.IsEditable;
            //   }
            // }, 
            singleClickEdit: true
          },
          {
            headerName: "Delta TC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaTCVsRef",
            tooltipField: "Delta TC vs. Reference",
            headerTooltip: "Delta TC vs. Reference",
            width: 66,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent != 'Total Local Costs') {

                if (params.node.data.TargetTotal == null || Number(params.node.data.TargetTotal) == 0) {
                  return 0
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetTotal) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
                }

                params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? 0 : params.node.data.DeltaTCVsRef
                return params.node.data.DeltaTCVsRef;
              } else {
                let target = 0;
                let ref = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    target += Number(node.data.TargetTotal)
                    ref += Number(node.data.ReferenceCost)
                  })
                  params.data.DeltaTCVsRef = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
                  return params.data.DeltaTCVsRef;
                }
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,

            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ForecastAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ForecastAbsolute)
                  })
                  params.data.ForecastAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ForecastAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs'
              ) {
                return false;
              }
              else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,

            editable: function (params) {
              if (params.node.data.ForecastAbsolute === "" || params.node.data.ForecastAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },
          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Local Costs')
              // if (params.node.data.SubComponent != 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs')
              {
                let total = 0;
                params.api.forEachNode((node) => {
                  total = Number(node.data.ForecastTotal) + total;
                })
                params.data.ForecastTotal = total;
                return total;
              } else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.ForecastPercentOfMEK != null) {
                    permek = Number(params.data.ForecastPercentOfMEK) / 100;
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * this.EQTargetCostOptCMek;
                    //abs = Number(node.data.Absolute) + abs

                  }
                  else {
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ForecastAbsolute);
                  }
                }
                params.data.ForecastTotal = total;
                return total;

              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,

            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "Delta FC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaFCVsRef",
            tooltipField: "Delta FC vs. Reference",
            headerTooltip: "Delta FC vs. Reference",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ForecastDeltaFCVsRef, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,

            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ActualAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ActualAbsolute)
                  })
                  params.data.ActualAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ActualAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Local Costs'
              ) {
                return false;
              }
              else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.ActualAbsolute === "" || params.node.data.ActualAbsolute === null) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.ActualTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,

            // },
            singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. ACT",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-lc"
              else
                return "colorTotal negative-num-color-lc"
            },
            field: "DeltaTCVsACT",
            tooltipField: "Delta TC vs. ACT",
            headerTooltip: "Delta TC vs. ACT",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ActualDeltaTCVsACT, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,

            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      }

    ]
    // this.tarDomLayout = 'autoHeight';
    this.optCDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      // autoHeight: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.optCFrameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    this.optCGridData = [];
  }

  onGridTarCSChanged(params) {
    params.api.sizeColumnsToFit();
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedTar(params) {
    params.api.resetRowHeights();
  }
  onColumnVisibleTar(params) {
    params.api.resetRowHeights();
  }

  onRefGridReady(params: any) {
    this.refGridApi = params.api;
    this.refGridApi.setGroupHeaderHeight(30);
    this.sizeToFitRefreshRef = 1;

    this.refPinnedBottomData = this.createDataRef(this.totalRef(this.refGridApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.refPinnedBottomData);
  }

  onDisplayedColumnsRefChanged(event) {
    if (this.sizeToFitRefreshRef != 0) {
      this.refGridApi.sizeColumnsToFit()
    }
  }

  generateRefPinnedBottomData() {

  }

  createDataRef(totalCost, isEditable) {
    let result = [];
    result.push({
      SubComponent: "Total Local Costs",
      Absolute: totalCost[0],
      Total: totalCost[1]
    });

    return result;
  }
  // calculate total for reference grid for bottom "total row"
  totalRef(gridType: any) {
    let abs = 0;
    let total = 0;
    gridType.forEachNode(function (node) {
      abs = Number(node.data.Absolute) + abs
      total = Number(node.data.Total) + total
    })

    return [abs, total];
  }

  onTarGridReady(params: any) {
    this.tarGridApi = params.api;
    this.tarGridApi.setGroupHeaderHeight(30);
    this.sizeToFitRefreshTar = 1;

    this.tarPinnedBottomData = this.createDataTar(this.totalTar(this.tarGridApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }
  // creates row for "Total Local Costs" 
  createDataTar(total, isEditable) {
    let result = [];
    result.push({
      SubComponent: "Total Local Costs",
      TargetAbsolute: total[0],
      TargetTotal: total[1],
      DeltaTCVsRef: total[2],
      ForecastAbsolute: total[3],
      ForecastTotal: total[4],
      DeltaFCVsRef: total[5],
      ActualAbsolute: total[6],
      ActualTotal: total[7],
      DeltaTCVsACT: total[8],
    });

    return result;
  }


  totalTar(gridType: any) {
    let targetAbsolute = 0;
    let targetTotal = 0;
    let deltaTCVsRef = 0;
    let forecastAbsolute = 0;
    let forecastTotal = 0;
    let deltaFCVsRef = 0;
    let actualAbsolute = 0;
    let actualTotal = 0;
    let deltaTCVsACT = 0;
    gridType.forEachNode(function (node) {
      targetAbsolute = Number(node.data.TargetAbsolute) + targetAbsolute
      targetTotal = Number(node.data.TargetTotal) + targetTotal
      deltaTCVsRef = Number(node.data.DeltaTCVsRef) + deltaTCVsRef
      forecastAbsolute = Number(node.data.ForecastAbsolute) + forecastAbsolute
      forecastTotal = Number(node.data.ForecastTotal) + forecastTotal
      deltaFCVsRef = Number(node.data.DeltaFCVsRef) + deltaFCVsRef
      actualAbsolute = Number(node.data.ActualAbsolute) + actualAbsolute
      actualTotal = Number(node.data.ActualTotal) + actualTotal
      deltaTCVsACT = Number(node.data.DeltaTCVsACT) + deltaTCVsACT
    })
    return [targetAbsolute, targetTotal, deltaTCVsRef, forecastAbsolute, forecastTotal, deltaFCVsRef, actualAbsolute, actualTotal, deltaTCVsACT];
  }

  onGridRefCSChanged(params) {
    this.refGridApi = params.api;
    this.refGridApi.sizeColumnsToFit();
  }

  onOptAGridReady(params: any) {
    this.tarGridAApi = params.api;
    this.tarGridAApi.setGroupHeaderHeight(30);
    if (this.AddOptionARow == 1) {
      this.tarGridApi.forEachNode(x => {
        this.tarGridAApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
            //)
          }]
        })
        // this.addOrUpdateRow(this.modifiedOptASCMLabourRows,x.data);
        this.tarGridAApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptASCMLabourRows, node.data);
        })
      });
    }
    this.tarPinnedBottomData = this.createDataTar(this.totalTar(this.tarGridAApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }
  onRefCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedRefSCMLabourRows, params.data);
    this.tarGridApi.forEachNode(x => {
      if (x.data.ComponentId == params.data.ComponentId || x.data.SubComponent == params.data.SubComponent) {
        x.data.ReferenceCost = params.data.Total
        x.data.SubComponent = params.data.SubComponent

        this.tarGridApi.redrawRows();
        if (x.data.TargetTotal > 0 && x.data.ActualTotal > 0 && x.data.ActualTotal > 0)
          this.addOrUpdateRow(this.modifiedTarSCMLabourRows, x.data);
      }
    })

    if (this.tarGridAApi != undefined) {
      this.tarGridAApi.forEachNode(x => {
        if (x.data.ComponentId == params.data.ComponentId || x.data.SubComponent == params.data.SubComponent) {
          x.data.ReferenceCost = params.data.Total
          x.data.SubComponent = params.data.SubComponent
          this.tarGridAApi.redrawRows();
          this.addOrUpdateRow(this.modifiedOptASCMLabourRows, x.data);
        }
      }
      )
    }

    if (this.tarGridBApi != undefined) {
      this.tarGridBApi.forEachNode(x => {
        if (x.data.ComponentId == params.data.ComponentId || x.data.SubComponent == params.data.SubComponent) {
          x.data.ReferenceCost = params.data.Total
          x.data.SubComponent = params.data.SubComponent
          this.tarGridBApi.redrawRows();
          this.addOrUpdateRow(this.modifiedOptBSCMLabourRows, x.data);
        }
      }
      )
    }

    if (this.tarGridCApi != undefined) {
      this.tarGridCApi.forEachNode(x => {
        if (x.data.ComponentId == params.data.ComponentId || x.data.SubComponent == params.data.SubComponent) {
          x.data.ReferenceCost = params.data.Total
          x.data.SubComponent = params.data.SubComponent
          this.tarGridCApi.redrawRows();
          this.addOrUpdateRow(this.modifiedOptCSCMLabourRows, x.data);
        }
      }
      )
    }
  }
  onGridOptACSChanged(params) {
    params.api.sizeColumnsToFit();
  }
  onDisplayedColumnsTarChanged(params) {
    if (this.sizeToFitRefreshTar != 0) {
      params.api.sizeColumnsToFit()
    }
  }
  onTarCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    params.api.sizeColumnsToFit();
    this.addOrUpdateRow(this.modifiedTarSCMLabourRows, params.data);
  }
  onOptACellValueChanged(params) {
    this.hasUnsavedChanges = true;
    params.api.sizeColumnsToFit();
    this.addOrUpdateRow(this.modifiedOptASCMLabourRows, params.data);
  }
  onOptBCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    params.api.sizeColumnsToFit();
    this.addOrUpdateRow(this.modifiedOptBSCMLabourRows, params.data);
  }
  onOptCCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    params.api.sizeColumnsToFit();
    this.addOrUpdateRow(this.modifiedOptCSCMLabourRows, params.data);
  }
  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptA(params) {
    params.api.resetRowHeights();
  }
  onColumnVisibleOptA(params) {
    params.api.resetRowHeights();
  }
  onOptBGridReady(params: any) {
    this.tarGridBApi = params.api;
    this.tarGridBApi.setGroupHeaderHeight(30);
    if (this.AddOptionBRow == 1) {
      this.tarGridApi.forEachNode(x => {
        this.tarGridBApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,

          }]
        })

        this.tarGridBApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptBSCMLabourRows, node.data);
        })

      });
    }
    this.tarPinnedBottomData = this.createDataTar(this.totalTar(this.tarGridBApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }

  onGridOptBCSChanged(params) {
    params.api.sizeColumnsToFit();
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptB(params) {
    params.api.resetRowHeights();
  }
  onColumnVisibleOptB(params) {
    params.api.resetRowHeights();
  }
  onOptCGridReady(params: any) {

    this.tarGridCApi = params.api;
    this.tarGridCApi.setGroupHeaderHeight(30);
    if (this.AddOptionCRow == 1) {
      this.tarGridApi.forEachNode(x => {
        this.tarGridCApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
          }]
        })
      });
      this.tarGridCApi.forEachNode(node => {
        this.addOrUpdateRow(this.modifiedOptCSCMLabourRows, node.data);
      })
    }
    this.tarPinnedBottomData = this.createDataTar(this.totalTar(this.tarGridCApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);
  }

  onGridOptCCSChanged(params) {
    params.api.sizeColumnsToFit();
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptC(params) {
    params.api.resetRowHeights();
  }
  onColumnVisibleOptC(params) {
    params.api.resetRowHeights();
  }



  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }

  onClickNext() {
    this.menuService.onClickNextAndBack("next");
  }

  submit(event?: any) {
    if (!this.hasUnsavedChanges && event != undefined) {
      this.router.navigate(['/tc-manageProject'])
      return true

    }
    else {

      let errorMessage = messages.cellEmptyErrorMesssage;
      let error = [];
      let firstRow = this.refGridApi.getDisplayedRowAtIndex(0);
      // let lastRow = this.refGridApi.getDisplayedRowAtIndex(this.refGridApi.getDisplayedRowCount() - 1);
      if (firstRow != undefined) {

        let data = firstRow.data;

        if (data.SubComponent === "Add Component Here" || data.SubComponent == null || data.SubComponent === "" || data.SubComponent === undefined) {
          error.push({ "Local Cost": errorMessage })
        }
      }
      if (error.length != 0) {
        this.openAlertDialog(error);
        return false;
      }
      else {
        let response = null;
        this.showGrid = false;
        this.submitted = true;
        let sendData = this.sendData();
        response = this.ScmtargetcostService.InsertupdateLCTargetCostData(sendData).toPromise().
          then((res) => {
            this.showGrid = true;
            this.submitResponse = res;
            let successCode = this.submitResponse.StatusCode;
            let errorCode = this.submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = this.submitResponse.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                this.hasUnsavedChanges = false;
                let dataSaveMessage = serverMessage.dataSaveMessage;
                this.toast.notify(dataSaveMessage, "success");
                if (event != undefined) {
                  //  this.localStorageService.remove("configuration");
                  this.hasUnsavedChanges = false;
                  this.router.navigate(['/tc-manageProject'])
                }
                return true;
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
                this.submitted = false;
                return false;
              }
            }
            else {
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          },
            (error) => {
              this.submitted = false;
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
              return false;
            })

        return response;
      }
    }
  }
  sendData() {
    let refObj: TCSCMLCTargetCost[] = [];

    if (this.localCostData != undefined) {
      this.localCostData.forEach(cs => {
        cs.Component = cs.Component;
        cs.ConfigurationId = this.configurationId,
          cs.IsActive = true;
        cs.ReferenceScenario.ScenarioTitle = this.refTitle;
        cs.TargetScenario.ScenarioTitle = this.tarTitle;
        if (cs.OptionA != null) cs.OptionA.ScenarioTitle = this.optATitle;
        if (cs.OptionB != null) cs.OptionB.ScenarioTitle = this.optBTitle;
        if (cs.OptionC != null) cs.OptionC.ScenarioTitle = this.optCTitle;

        if (cs.SCMId == 0) {
          cs.CreatedBy = this.userInOrg.UserId;
          cs.CreatedOn = new Date();
        } else {
          cs.ModifiedBy = this.userInOrg.UserId;
          cs.ModifiedOn = new Date();
        }

        let referenceModifiedRows = [];
        let tarModifiedRows = [];
        let optionAModifiedRows = [];
        let optionBModifiedRows = [];
        let optionCModifiedRows = [];


        referenceModifiedRows = this.modifiedRefSCMLabourRows;
        tarModifiedRows = this.modifiedTarSCMLabourRows;
        optionAModifiedRows = this.modifiedOptASCMLabourRows;
        optionBModifiedRows = this.modifiedOptBSCMLabourRows;
        optionCModifiedRows = this.modifiedOptCSCMLabourRows;


        let referenceRow = referenceModifiedRows.find(x => x.SCMId == cs.SCMId);
        if (referenceRow) {
          cs.SubComponent = referenceRow.SubComponent;
          cs.ReferenceScenario.Quantity = Number(referenceRow.Quantity);
          cs.ReferenceScenario.Weight = Number(referenceRow.Weight);
          cs.ReferenceScenario.Absolute = Number(referenceRow.Absolute);
          cs.ReferenceScenario.PercentOfMEK = referenceRow.PercentOfMEK > 0 ? Number(referenceRow.PercentOfMEK) : null;
          cs.ReferenceScenario.Total = Number(referenceRow.Total);
        }

        let targetRow = tarModifiedRows.find(x => x.SCMIdTargetCost == cs.TargetScenario.TargetCost.SCMId);
        if (targetRow) {
          cs.TargetScenario.TargetCost = {
            SCMId: targetRow.SCMIdTargetCost,
            Absolute: Number(targetRow.TargetAbsolute),
            Quantity: Number(targetRow.TargetQuantity),
            Weight: Number(targetRow.TargetWeight),
            PercentOfMEK: targetRow.TargetPercentOfMEK > 0 ? Number(targetRow.TargetPercentOfMEK) : null,
            Total: Number(targetRow.TargetTotal),
            DeltaTCVsRef: Number(targetRow.DeltaTCVsRef)
          }

          cs.TargetScenario.Forecast = {
            SCMId: targetRow.SCMIdForecast,
            Absolute: Number(targetRow.ForecastAbsolute),
            PercentOfMEK: targetRow.ForecastPercentOfMEK > 0 ? Number(targetRow.ForecastPercentOfMEK) : null,
            Total: Number(targetRow.ForecastTotal),
            DeltaFCVsRef: Number(targetRow.DeltaFCVsRef)
          }

          cs.TargetScenario.Actual = {
            SCMId: targetRow.SCMIdActual,
            Absolute: Number(targetRow.ActualAbsolute),
            PercentOfMEK: targetRow.ActualPercentOfMEK > 0 ? Number(targetRow.ActualPercentOfMEK) : null,
            Total: Number(targetRow.ActualTotal),
            DeltaTCVsACT: Number(targetRow.DeltaTCVsACT)
          }
        } else {
          if (cs.TargetScenario.TargetCost == null) {
            cs.TargetScenario.TargetCost = {
              SCMId: 0,
              Absolute: 0,
              Quantity: 0,
              Weight: 0,
              PercentOfMEK: null,
              Total: 0,
              DeltaTCVsRef: 0
            }
          }
          if (cs.TargetScenario.Forecast == null) {
            cs.TargetScenario.Forecast = {
              SCMId: 0,
              Absolute: 0,
              PercentOfMEK: null,
              Total: 0,
              DeltaFCVsRef: 0
            }
          }
          if (cs.TargetScenario.Actual == null) {
            cs.TargetScenario.Actual = {
              SCMId: 0,
              Absolute: 0,
              PercentOfMEK: null,
              Total: 0,
              DeltaTCVsACT: 0
            }
          }
        }

        if (this.optAVisible && cs.OptionA != null) {
          let optARow = optionAModifiedRows.find(x => x.SCMIdTargetCost == cs.OptionA.TargetCost.SCMId);
          if (optARow) {
            let TargetCost = {
              SCMId: optARow.SCMIdTargetCost,
              Absolute: Number(optARow.TargetAbsolute),
              Quantity: Number(optARow.TargetQuantity),
              Weight: Number(optARow.TargetWeight),
              PercentOfMEK: optARow.TargetPercentOfMEK > 0 ? Number(optARow.TargetPercentOfMEK) : null,
              Total: Number(optARow.TargetTotal),
              DeltaTCVsRef: Number(optARow.DeltaTCVsRef)
            }

            let Forecast = {
              SCMId: optARow.SCMIdForecast,
              Absolute: Number(optARow.ForecastAbsolute),
              PercentOfMEK: optARow.ForecastPercentOfMEK > 0 ? Number(optARow.ForecastPercentOfMEK) : null,
              Total: Number(optARow.ForecastTotal),
              DeltaFCVsRef: Number(optARow.DeltaFCVsRef)
            }

            let Actual = {
              SCMId: optARow.SCMIdActual,
              Absolute: Number(optARow.ActualAbsolute),
              PercentOfMEK: optARow.ActualPercentOfMEK > 0 ? Number(optARow.ActualPercentOfMEK) : null,
              Total: Number(optARow.ActualTotal),
              DeltaTCVsACT: Number(optARow.DeltaTCVsACT)
            }
            cs.OptionA = { TargetCost: TargetCost, Forecast: Forecast, Actual: Actual, ScenarioTitle: this.optATitle };
          }
        }
        else {
          let optARow = this.modifiedOptASCMLabourRows.find(x => x.SubComponent == cs.SubComponent);
          if (optARow) {
            cs.OptionA = SCMLCScenario;
            let optATargetCost: SCMLCTargetCost;
            let optAForecastCost: SCMLCForecast;
            let optAActual: SCMLCActual;

            optATargetCost = {
              SCMId: optARow.SCMIdTargetCost,
              Absolute: optARow.TargetAbsolute,
              Quantity: optARow.TargetQuantity,
              Weight: optARow.TargetWeight,
              PercentOfMEK: optARow.TargetPercentOfMEK > 0 ? optARow.TargetPercentOfMEK : null,
              Total: optARow.TargetTotal,
              DeltaTCVsRef: optARow.DeltaTCVsRef
            }

            optAForecastCost = {
              SCMId: optARow.SCMIdForecast,
              Absolute: optARow.ForecastAbsolute,
              PercentOfMEK: optARow.ForecastPercentOfMEK > 0 ? optARow.ForecastPercentOfMEK : null,
              Total: optARow.ForecastTotal,
              DeltaFCVsRef: optARow.DeltaFCVsRef
            }

            optAActual = {
              SCMId: optARow.SCMIdActual,
              Absolute: optARow.ActualAbsolute,
              PercentOfMEK: optARow.ActualPercentOfMEK > 0 ? optARow.ActualPercentOfMEK : null,
              Total: optARow.ActualTotal,
              DeltaTCVsACT: optARow.DeltaTCVsACT
            }
            cs.OptionA = { TargetCost: optATargetCost, Forecast: optAForecastCost, Actual: optAActual, ScenarioTitle: this.optATitle };
          }
        }

        if (this.optBVisible && cs.OptionB != null) {
          let optBRow = optionBModifiedRows.find(x => x.SCMIdTargetCost == cs.OptionB.TargetCost.SCMId);
          if (optBRow) {
            let TargetCost = {
              SCMId: optBRow.SCMIdTargetCost,
              Absolute: Number(optBRow.TargetAbsolute),
              Quantity: Number(optBRow.TargetQuantity),
              Weight: Number(optBRow.TargetWeight),
              PercentOfMEK: optBRow.TargetPercentOfMEK > 0 ? Number(optBRow.TargetPercentOfMEK) : null,
              Total: Number(optBRow.TargetTotal),
              DeltaTCVsRef: Number(optBRow.DeltaTCVsRef)
            }

            let Forecast = {
              SCMId: optBRow.SCMIdForecast,
              Absolute: Number(optBRow.ForecastAbsolute),
              PercentOfMEK: optBRow.ForecastPercentOfMEK > 0 ? Number(optBRow.ForecastPercentOfMEK) : null,
              Total: Number(optBRow.ForecastTotal),
              DeltaFCVsRef: Number(optBRow.DeltaFCVsRef)
            }

            let Actual = {
              SCMId: optBRow.SCMIdActual,
              Absolute: Number(optBRow.ActualAbsolute),
              PercentOfMEK: optBRow.ActualPercentOfMEK > 0 ? Number(optBRow.ActualPercentOfMEK) : null,
              Total: Number(optBRow.ActualTotal),
              DeltaTCVsACT: Number(optBRow.DeltaTCVsACT)
            }
            cs.OptionB = { TargetCost: TargetCost, Forecast: Forecast, Actual: Actual, ScenarioTitle: this.optBTitle };
          }
        }
        else {
          let optBRow = this.modifiedOptBSCMLabourRows.find(tl => tl.SubComponent === cs.SubComponent);
          if (optBRow) {
            cs.OptionB = SCMLCScenario;
            let optBTargetCost: SCMLCTargetCost;
            let optBForecastCost: SCMLCForecast;
            let optBActual: SCMLCActual;

            optBTargetCost = {
              SCMId: optBRow.SCMIdTargetCost,
              Absolute: optBRow.TargetAbsolute,
              Quantity: optBRow.TargetQuantity,
              Weight: optBRow.TargetWeight,
              PercentOfMEK: optBRow.TargetPercentOfMEK > 0 ? optBRow.TargetPercentOfMEK : null,
              Total: optBRow.TargetTotal,
              DeltaTCVsRef: optBRow.DeltaTCVsRef
            }

            optBForecastCost = {
              SCMId: optBRow.SCMIdForecast,
              Absolute: optBRow.ForecastAbsolute,
              PercentOfMEK: optBRow.ForecastPercentOfMEK > 0 ? optBRow.ForecastPercentOfMEK : null,
              Total: optBRow.ForecastTotal,
              DeltaFCVsRef: optBRow.DeltaFCVsRef
            }

            optBActual = {
              SCMId: optBRow.SCMIdActual,
              Absolute: optBRow.ActualAbsolute,
              PercentOfMEK: optBRow.ActualPercentOfMEK > 0 ? optBRow.ActualPercentOfMEK : null,
              Total: optBRow.ActualTotal,
              DeltaTCVsACT: optBRow.DeltaTCVsACT
            }
            cs.OptionB = { TargetCost: optBTargetCost, Forecast: optBForecastCost, Actual: optBActual, ScenarioTitle: this.optBTitle }

          }
        }

        if (this.optCVisible && cs.OptionC != null) {
          let optCRow = optionCModifiedRows.find(x => x.SCMIdTargetCost == cs.OptionC.TargetCost.SCMId);
          if (optCRow) {
            let TargetCost = {
              SCMId: optCRow.SCMIdTargetCost,
              Absolute: Number(optCRow.TargetAbsolute),
              Quantity: Number(optCRow.TargetQuantity),
              Weight: Number(optCRow.TargetWeight),
              PercentOfMEK: optCRow.TargetPercentOfMEK > 0 ? Number(optCRow.TargetPercentOfMEK) : null,
              Total: Number(optCRow.TargetTotal),
              DeltaTCVsRef: Number(optCRow.DeltaTCVsRef)
            }

            let Forecast = {
              SCMId: optCRow.SCMIdForecast,
              Absolute: Number(optCRow.ForecastAbsolute),
              PercentOfMEK: optCRow.ForecastPercentOfMEK > 0 ? Number(optCRow.ForecastPercentOfMEK) : null,
              Total: Number(optCRow.ForecastTotal),
              DeltaFCVsRef: Number(optCRow.DeltaFCVsRef)
            }

            let Actual = {
              SCMId: optCRow.SCMIdActual,
              Absolute: Number(optCRow.ActualAbsolute),
              PercentOfMEK: optCRow.ActualPercentOfMEK > 0 ? Number(optCRow.ActualPercentOfMEK) : null,
              Total: Number(optCRow.ActualTotal),
              DeltaTCVsACT: Number(optCRow.DeltaTCVsACT)
            }
            cs.OptionC = { TargetCost: TargetCost, Forecast: Forecast, Actual: Actual, ScenarioTitle: this.optCTitle };
          }
        }
        else {
          let optCRow = this.modifiedOptCSCMLabourRows.find(tl => tl.SubComponent === cs.SubComponent);
          if (optCRow) {
            cs.OptionC = SCMLCScenario;
            let optCTargetCost: SCMLCTargetCost;
            let optCForecastCost: SCMLCForecast;
            let optCActual: SCMLCActual;

            optCTargetCost = {
              SCMId: optCRow.SCMIdTargetCost,
              Absolute: optCRow.TargetAbsolute,
              Quantity: optCRow.TargetQuantity,
              Weight: optCRow.TargetWeight,
              PercentOfMEK: optCRow.TargetPercentOfMEK > 0 ? optCRow.TargetPercentOfMEK : null,
              Total: optCRow.TargetTotal,
              DeltaTCVsRef: optCRow.DeltaTCVsRef
            }

            optCForecastCost = {
              SCMId: optCRow.SCMIdForecast,
              Absolute: optCRow.ForecastAbsolute,
              PercentOfMEK: optCRow.ForecastPercentOfMEK > 0 ? optCRow.ForecastPercentOfMEK : null,
              Total: optCRow.ForecastTotal,
              DeltaFCVsRef: optCRow.DeltaFCVsRef
            }

            optCActual = {
              SCMId: optCRow.SCMIdActual,
              Absolute: optCRow.ActualAbsolute,
              PercentOfMEK: optCRow.ActualPercentOfMEK > 0 ? optCRow.ActualPercentOfMEK : null,
              Total: optCRow.ActualTotal,
              DeltaTCVsACT: optCRow.DeltaTCVsACT
            }
            cs.OptionC = { TargetCost: optCTargetCost, Forecast: optCForecastCost, Actual: optCActual, ScenarioTitle: this.optCTitle }
          }
        }
        refObj.push(cs);
      })
    }


    this.modifiedRefSCMLabourRows.forEach(modifiedRow => {
      let isExisting = refObj.some(cs => cs.SubComponent === modifiedRow.SubComponent && cs.Component === modifiedRow.Component);
      if (!isExisting) {

        let targetRow = this.modifiedTarSCMLabourRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let targetScenario: SCMLCScenario;
        let targetCost: SCMLCTargetCost;
        let forecastCost: SCMLCForecast;
        let actual: SCMLCActual;
        if (targetRow) {
          targetCost = {
            SCMId: targetRow.SCMIdTargetCost,
            Absolute: targetRow.TargetAbsolute,
            Quantity: targetRow.TargetQuantity,
            Weight: targetRow.TargetWeight,
            PercentOfMEK: targetRow.TargetPercentOfMEK > 0 ? targetRow.TargetPercentOfMEK : null,
            Total: targetRow.TargetTotal,
            DeltaTCVsRef: targetRow.DeltaTCVsRef
          }

          forecastCost = {
            SCMId: targetRow.SCMIdForecast,
            Absolute: targetRow.ForecastAbsolute,
            PercentOfMEK: targetRow.ForecastPercentOfMEK > 0 ? targetRow.ForecastPercentOfMEK : null,
            Total: targetRow.ForecastTotal,
            DeltaFCVsRef: targetRow.DeltaFCVsRef
          }

          actual = {
            SCMId: targetRow.SCMIdActual,
            Absolute: targetRow.ActualAbsolute,
            PercentOfMEK: targetRow.ActualPercentOfMEK > 0 ? targetRow.ActualPercentOfMEK : null,
            Total: targetRow.ActualTotal,
            DeltaTCVsACT: targetRow.DeltaTCVsACT
          }
          targetScenario = { TargetCost: targetCost, Forecast: forecastCost, Actual: actual, ScenarioTitle: this.targetTitle }
        }
        else {
          targetScenario = null;
        }

        let optARow = this.modifiedOptASCMLabourRows.find(x => x.SubComponent == modifiedRow.SubComponent);
        let optAScenario: SCMLCScenario;
        let optATargetCost: SCMLCTargetCost;
        let optAForecastCost: SCMLCForecast;
        let optAActual: SCMLCActual;
        if (optARow) {
          optATargetCost = {
            SCMId: optARow.SCMIdTargetCost,
            Absolute: optARow.TargetAbsolute,
            Quantity: optARow.TargetQuantity,
            Weight: optARow.TargetWeight,
            PercentOfMEK: optARow.TargetPercentOfMEK > 0 ? optARow.TargetPercentOfMEK : null,
            Total: optARow.TargetTotal,
            DeltaTCVsRef: optARow.DeltaTCVsRef
          }

          optAForecastCost = {
            SCMId: optARow.SCMIdForecast,
            Absolute: optARow.ForecastAbsolute,
            PercentOfMEK: optARow.ForecastPercentOfMEK > 0 ? optARow.ForecastPercentOfMEK : null,
            Total: optARow.ForecastTotal,
            DeltaFCVsRef: optARow.DeltaFCVsRef
          }

          optAActual = {
            SCMId: optARow.SCMIdActual,
            Absolute: optARow.ActualAbsolute,
            PercentOfMEK: optARow.ActualPercentOfMEK > 0 ? optARow.ActualPercentOfMEK : null,
            Total: optARow.ActualTotal,
            DeltaTCVsACT: optARow.DeltaTCVsACT
          }
          optAScenario = { TargetCost: optATargetCost, Forecast: optAForecastCost, Actual: optAActual, ScenarioTitle: this.optATitle }
        }
        else {
          optAScenario = null;
        }

        let optBRow = this.modifiedOptBSCMLabourRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let optBScenario: SCMLCScenario;
        let optBTargetCost: SCMLCTargetCost;
        let optBForecastCost: SCMLCForecast;
        let optBActual: SCMLCActual;
        if (optBRow) {
          optBTargetCost = {
            SCMId: optBRow.SCMIdTargetCost,
            Absolute: optBRow.TargetAbsolute,
            Quantity: optBRow.TargetQuantity,
            Weight: optBRow.TargetWeight,
            PercentOfMEK: optBRow.TargetPercentOfMEK > 0 ? optBRow.TargetPercentOfMEK : null,
            Total: optBRow.TargetTotal,
            DeltaTCVsRef: optBRow.DeltaTCVsRef
          }

          optBForecastCost = {
            SCMId: optBRow.SCMIdForecast,
            Absolute: optBRow.ForecastAbsolute,
            PercentOfMEK: optBRow.ForecastPercentOfMEK > 0 ? optBRow.ForecastPercentOfMEK : null,
            Total: optBRow.ForecastTotal,
            DeltaFCVsRef: optBRow.DeltaFCVsRef
          }

          optBActual = {
            SCMId: optBRow.SCMIdActual,
            Absolute: optBRow.ActualAbsolute,
            PercentOfMEK: optBRow.ActualPercentOfMEK > 0 ? optBRow.ActualPercentOfMEK : null,
            Total: optBRow.ActualTotal,
            DeltaTCVsACT: optBRow.DeltaTCVsACT
          }
          optBScenario = { TargetCost: optBTargetCost, Forecast: optBForecastCost, Actual: optBActual, ScenarioTitle: this.optBTitle }
        }
        else {
          optBScenario = null;
        }

        let optCRow = this.modifiedOptCSCMLabourRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let optCScenario: SCMLCScenario;
        let optCTargetCost: SCMLCTargetCost;
        let optCForecastCost: SCMLCForecast;
        let optCActual: SCMLCActual;
        if (optCRow) {
          optCTargetCost = {
            SCMId: optCRow.SCMIdTargetCost,
            Absolute: optCRow.TargetAbsolute,
            Quantity: optCRow.TargetQuantity,
            Weight: optCRow.TargetWeight,
            PercentOfMEK: optCRow.TargetPercentOfMEK > 0 ? optCRow.TargetPercentOfMEK : null,
            Total: optCRow.TargetTotal,
            DeltaTCVsRef: optCRow.DeltaTCVsRef
          }

          optCForecastCost = {
            SCMId: optCRow.SCMIdForecast,
            Absolute: optCRow.ForecastAbsolute,
            PercentOfMEK: optCRow.ForecastPercentOfMEK > 0 ? optCRow.ForecastPercentOfMEK : null,
            Total: optCRow.ForecastTotal,
            DeltaFCVsRef: optCRow.DeltaFCVsRef
          }

          optCActual = {
            SCMId: optCRow.SCMIdActual,
            Absolute: optCRow.ActualAbsolute,
            PercentOfMEK: optCRow.ActualPercentOfMEK > 0 ? optCRow.ActualPercentOfMEK : null,
            Total: optCRow.ActualTotal,
            DeltaTCVsACT: optCRow.DeltaTCVsACT
          }
          optCScenario = { TargetCost: optCTargetCost, Forecast: optCForecastCost, Actual: optCActual, ScenarioTitle: this.optCTitle }
        }
        else {
          optCScenario = null;
        }
        let newEntry: TCSCMLCTargetCost = {
          SCMId: 0,
          ConfigurationId: this.configurationId,
          Component: modifiedRow.Component,
          SubComponent: modifiedRow.SubComponent,
          IsActive: true,
          ReferenceScenario: {
            SCMId: 0,
            ScenarioTitle: this.refTitle,
            Quantity: Number(modifiedRow.Quantity),
            Weight: Number(modifiedRow.Weight),
            Absolute: Number(modifiedRow.Absolute),
            PercentOfMEK: modifiedRow.PercentOfMEK > 0 ? Number(modifiedRow.PercentOfMEK) : null,
            Total: Number(modifiedRow.Total)
          },
          TargetScenario: targetScenario,
          OptionA: optAScenario,
          OptionB: optBScenario,
          OptionC: optCScenario,
          CreatedBy: this.userInOrg.UserId,
          CreatedOn: new Date(),
          ModifiedBy: null,
          ModifiedOn: null
        };
        refObj.push(newEntry);
      }
    });


    return refObj;

  }

  TargetTotal = (params) => {
    if (params.node.data.SubComponent === 'Total Local Costs'
    ) {
      let total = 0;
      params.api.forEachNode((node) => {
        total = Number(node.data.TargetTotal) + total;
      })
      params.data.TargetTotal = total;
      return total;
    } else {
      let total = 0;
      let actualMeks = 0;

      switch (params.api.gridOptionsWrapper.environment.eGridDiv.id) {
        case 'refLabourGrid':
          actualMeks = this.EQTargetCostRefMek ?? 0
          break;

        case 'tarLabourGrid':
          actualMeks = this.EQTargetCostTarMek ?? 0
          break;


        case 'optALabourGrid':
          actualMeks = this.EQTargetCostOptAMek ?? 0
          break;

        case 'optBLabourGrid':
          actualMeks = this.EQTargetCostOptBMek ?? 0
          break;

        case 'optCLabourGrid':
          actualMeks = this.EQTargetCostOptCMek ?? 0
          break;
        default:
          actualMeks;
          break;
      }

      if (params.api != null) {
        let permek = 0;
        if (params.node.data.TargetPercentOfMEK > 0) {
          permek = Number(params.data.TargetPercentOfMEK) / 100;
          total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * actualMeks;
        }
        else {
          total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.TargetAbsolute);
        }
      }
      params.data.TargetTotal = total;
      return total;
    }
  };

  ActualTotal = (params) => {
    if (params.node.data.SubComponent === 'Total Local Costs') {
      let total = 0;
      params.api.forEachNode((node) => {
        total = Number(node.data.ActualTotal) + total;
      })
      params.data.ActualTotal = total;
      return total;
    } else {
      let total = 0;

      let actualMeks = 0;

      switch (params.api.gridOptionsWrapper.environment.eGridDiv.id) {
        case 'refLabourGrid':
          actualMeks = this.EQTargetCostRefMek ?? 0
          break;

        case 'tarLabourGrid':
          actualMeks = this.EQTargetCostTarActualMek ?? 0
          break;


        case 'optALabourGrid':
          actualMeks = this.EQTargetCostOptAActualMek ?? 0
          break;

        case 'optBLabourGrid':
          actualMeks = this.EQTargetCostOptBActualMek ?? 0
          break;

        case 'optCLabourGrid':
          actualMeks = this.EQTargetCostOptCActualMek ?? 0
          break;
        default:
          actualMeks;
          break;
      }

      if (params.api != null) {
        let permek = 0;

        if (params.node.data.ActualPercentOfMEK > 0) {
          permek = Number(params.data.ActualPercentOfMEK) / 100;
          total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * actualMeks;
          //abs = Number(node.data.Absolute) + abs

        }
        else {
          total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ActualAbsolute);
        }
      }
      params.data.ActualTotal = total;
      return total;

    }
  };

  private addOrUpdateRow(array: any[], row: any): void {
    const index = array.findIndex((r) => r.ComponentId === row.ComponentId && r.SubComponent === row.SubComponent);
    if (index > -1) {
      array[index] = row; // Update existing entry
    } else {
      array.push(row); // Add new entry
    }
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  ngOnDestroy(): void {
    this.configSub.unsubscribe();
  }
}
// used to format the cell in germman currency format;
function CurrencyCellRenderer(params: any) {
  if (params.node.data.SubComponent == 'Total Local Costs')
    return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(0)));
  else
    return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
}



function ActualDeltaTCVsACT(params) {
  if (params.node.data.SubComponent != 'Total Local Costs') {

    if (params.node.data.ActualTotal == null || Number(params.node.data.ActualTotal) == 0) {
      return 0
    } else {
      params.node.data.DeltaTCVsACT = Number(params.node.data.ActualTotal) - Number(params.node.data.ReferenceCost)
    }

    params.node.data.DeltaTCVsACT = (params.node.data.DeltaTCVsACT == 0) ? 0 : params.node.data.DeltaTCVsACT
    params.node.data.DeltaTCVsACT = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsACT) / Number(params.node.data.ReferenceCost)) * 100;
    return params.node.data.DeltaTCVsACT;
  } else {
    let target = 0;
    let ref = 0;

    if (params.api != null) {
      params.api.forEachNode((node) => {
        target += Number(node.data.ActualTotal)
        ref += Number(node.data.ReferenceCost)
      })
      params.data.DeltaTCVsACT = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
      return params.data.DeltaTCVsACT;
    }
  }
};
function ForecastDeltaFCVsRef(params) {
  if (params.node.data.SubComponent != 'Total Local Costs') {

    if (params.node.data.ForecastTotal == null || Number(params.node.data.ForecastTotal) == 0) {
      return 0
    } else {
      params.node.data.DeltaFCVsRef = Number(params.node.data.ForecastTotal) - Number(params.node.data.ReferenceCost)
      params.node.data.DeltaFCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaFCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
    }

    params.node.data.DeltaFCVsRef = (params.node.data.DeltaFCVsRef == 0) ? 0 : params.node.data.DeltaFCVsRef
    return params.node.data.DeltaFCVsRef;
  } else {
    let target = 0;
    let ref = 0;

    if (params.api != null) {
      params.api.forEachNode((node) => {
        target += Number(node.data.ForecastTotal)
        ref += Number(node.data.ReferenceCost)
      })
      params.data.DeltaFCVsRef = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
      return params.data.DeltaFCVsRef;
    }
  }
};