<app-progress-spinner *ngIf="!showGrid || submitted"></app-progress-spinner>

<div class="container">
    <div class="col-md-12 col-lg-12 label-header">
        <label class="label-headercolor label-text">Configuration </label>
    </div>

    <div class="label-header">
        <button (click)="addNewConfiguration()" title="Add" [disabled]="!isEditable"
            [ngClass]="{ 'click-not-allowed': !isEditable }">
            <img alt="plusIcon" src="../assets/icons/Plus.svg" width="25" height="25" class="icon-add">
        </button>
        <label class="label-headercolor"> to create a new one </label>
    </div>
    <div>
        <ag-grid-angular id="ConfigurationGrid" style="width: 100%;" class="ag-theme-alpine"
            [rowData]="rowData" [columnDefs]="columnDefs" [domLayout]="domLayout" [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)" (cellValueChanged)="onCellValueChanged($event)"
            (gridSizeChanged)="onGridSizeChanged($event)" [enableBrowserTooltips]="true">
        </ag-grid-angular>
    </div>
    <br>

    <div class="row" style="margin-top: -19px">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle" style="float: right; margin-right: 5px; margin-bottom: 20px">
                <button class="button button--small" (click)="onClickCancel()">
                    Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
                    Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
                    Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted || !isEditable" (click)="submit($event)"
                    type="submit">
                    Save & Exit
                </button>
            </div>
        </div>

    </div>
    <app-commentbox [configData]="objData"></app-commentbox>
</div>