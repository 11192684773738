import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { TCHelp } from 'src/app/models/TCHelp';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { HelpService } from 'src/app/services/help.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-help-edit-target-costing',
  templateUrl: './help-edit-target-costing.component.html',
  styleUrls: ['./help-edit-target-costing.component.css']
})
export class HelpEditTargetCostingComponent implements OnInit {
  helpForm: FormGroup;
  pageName: any;
  pageData: any;
  idea: any;
  parentPages: any;
  childPages: any;
  childData = new TCHelp();
  parentData = new TCHelp();
  childId: any;
  val: any;
  selectParent: boolean;
  ideaSubmitterId: any;
  user: any;
  editorStyle = {
    height: '720px'
  }

  // used by and for manage data component;
  public adminSubscription: any;
  @Output() unSavedChanges = new EventEmitter<any>();

  constructor(
    private sharedService: SharedServiceService,
    private helpService: HelpService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    // subscribe to manage data eventemitter;
    this.adminSubscription = this.sharedService.help.subscribe(() => {
      this.sendData();
    });

    this.sharedService.ideaSubmitterId.subscribe(
      (id) => {
        this.ideaSubmitterId = id;
      }
    );

    this.sharedService.loggedInUser.subscribe(user => {
      this.user = user;
    });

    this.loadForm();
  }
   // load form structure;
   loadForm() {
    this.helpForm = this.formBuilder.group({
      "parentId": ['', Validators.required],
      "childId": ['', Validators.required],
      "editor": ['', Validators.required]
    })

    this.loadInitialData();
  }
  loadInitialData() {
    this.helpService.getTCAllData().subscribe(
      (res: any) => {
        let errorMsgeFromBack = res.Data.Message;
        if (res.StatusCode == 200) {
          this.parentPages = res.Data.filter((data: any) => {
            if (data.ParentId == 0) {
              return data
            }
          })
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      },
      (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )

  }
  onSubmit(value) {
    let helpText = this.helpForm.get("editor").value;
    this.parentData.TCHelpText= helpText
  }

  // get called whenever there is change in editor field;
  changeInTextArea(para) {   
    //  emit whenever there is nay change;
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
  }

  //called to save the unsaved data;
  sendData(event?: any) {
    let TChelpText = this.helpForm.get("editor").value;
    if (!this.selectParent) {
      let message = messages.Help.pageSelect
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
    }
    else {
      this.parentData.TCHelpText = TChelpText;
      this.helpService.updateTCData(this.parentData).subscribe(
        (res: any) => {
          //Check if successCode is ok i.e 200
          let successCode = res.StatusCode;
          let errorCode = res.Data.ErrorCode;
          let errorMsgeFromBack = res.Data.Message;
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              // emit when there is succesfull saving;
              let valToEmit = {
                unsavedChanges: false,
                saveButtonClick: event == undefined ? false : true
              }
              this.unSavedChanges.emit(valToEmit);

              this.toasterService.notify(serverMessage.dataSaveMessage, "success");
              return true;
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              return false;
            }
          }
          //Going in else means that success code is not. ie. Http Status from server is not ok.
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            return false;
          }
        }, (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          return false;
        }
      )
    }
  }

  // open alert dialog box;
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  
  // get called on click of cancel button;
  onCancel() {
    // applying initial value to the form fields;
    this.helpForm.get("parentId").setValue("");
    this.helpForm.get("childId").setValue("");
    this.helpForm.get("editor").setValue("");

    // cancelling all the changes and emiting;
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }
    this.unSavedChanges.emit(valToEmit);
  }
 
  // get called when there is change in the parent dropdown to fetch related child data;
  parentPageChange(value) {
    this.helpForm.get("childId").setValue("")
    if (value == '') {
      this.selectParent = false;
      this.val = "";
     // return
    }
    else {
      this.helpService.getTCDatabyId(value).subscribe(
        (res: any) => {
          let errorMsgeFromBack = res.Data.Message;
          if (res.StatusCode == 200) {
            this.selectParent = true;
            this.val = res.Data.TCHelpText;
            this.parentData.TCHelpId = value;
            this.parentData.PageName = res.Data.PageName;
            this.parentData.ParentId = res.Data.ParentId;
            this.parentData.IsActive = true;
            this.parentData.ModifiedBy = this.user.UserId;
            this.parentData.ModifiedOn = new Date();
          }
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
          }
        },
        (error) => {
          //Show errorCode and errorMessage in the UI
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        })
      this.helpService.getTCAllData().subscribe(
        (res: any) => {
          let errorMsgeFromBack = res.Data.Message;
          if (res.StatusCode == 200) {
            this.childPages = res.Data.filter((data: any) => {
              if (data.ParentId == value) {
                return data
              }
            })
          }
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
          }
        },
        (error) => {
          //Show errorCode and errorMessage in the UI
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        }
      )
    }
  }

  // called when child dropdown is selected;
  childPageChange(value) {
    if (value == '') {
      let id = this.helpForm.get("parentId").value
      this.helpService.getTCDatabyId(id).subscribe(
        (res: any) => {
          this.val = res.Data.TCHelpText;
          this.parentData.TCHelpId = res.Data.TCHelpId;
          this.parentData.PageName = res.Data.PageName;
          this.parentData.ParentId = res.Data.ParentId;
          this.parentData.IsActive = true;
          this.parentData.ModifiedBy = this.user.UserId;
          this.parentData.ModifiedOn = new Date();
        })
      return false;
    }
    this.helpService.getTCDatabyId(value).subscribe(
      (res: any) => {
        let errorMsgeFromBack = res.Data.Message;
        if (res.StatusCode == 200) {
          this.val = res.Data.TCHelpText;
          this.parentData.TCHelpId = res.Data.TCHelpId;
          this.parentData.PageName = res.Data.PageName;
          this.parentData.ParentId = res.Data.ParentId;
          this.parentData.IsActive = true;
          this.parentData.ModifiedBy = this.user.UserId;
          this.parentData.ModifiedOn = new Date();
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      },
      (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  // destroys all the instance of component on tab change in admin page;
  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
  }
}
