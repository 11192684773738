import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { Observable, throwError } from 'rxjs';

import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EnumMasterType } from '../enum/MasterType';
import { KMATHierarchyDto } from '../models/KMATHierarchyDto';
import { KmatDto } from '../models/KmatDto';
import { TCConfigurationDataRequest } from '../models/tc-configuration';
import { CreateManualVBLODTO } from '../models/CreateManualVBLODTO';
import { Eqcosts } from '../models/Eqcosts';
@Injectable({
  providedIn: 'root'
})
export class EqtargetcostingService {
  readonly baseUrl = environment.baseURL

  private urlGetEqTargetCostingData = this.baseUrl + "api/TCEQTargetCost/GetTCEQTargetCosts?configurationId=";
  private urlInsertUpdateEqTargetCostingData = this.baseUrl + "api/TCEQTargetCost/SaveTCEQTargetCosts";
  private urlGetBkAndFkValue = this.baseUrl + "api/TCOverheadCost/GetAllOverheadCost?businessAreaId="
  
  constructor(private http: HttpClient) { }
  
  getEqTargetcostingData(configurationId, businessAreaId, businessLineId): Observable<any> {
    return this.http.get<any>(this.urlGetEqTargetCostingData + configurationId + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getBkAndFkValue(businessAreaId: Number, businessLineId: Number, year: Number, gmk: string): Observable<any> {
    return this.http.get<any>(this.urlGetBkAndFkValue + businessAreaId + "&businessLineId=" + businessLineId + "&year=" + year + "&gmkGroup=" + gmk).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  insertUpdateTargetcostingData(data): Observable<Eqcosts> {
    return this.http.post<Eqcosts>(this.urlInsertUpdateEqTargetCostingData, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  // It handle error ,server connection error and throws it to the component where its is recieved in the error of "subscribe" of service call method;
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }

}
