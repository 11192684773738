import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ImpactedOrg } from '../models/impacted-org'
import { Observable, throwError } from 'rxjs';
import { MOrgUnit } from '../models/m-org-unit';
import { Mimpact } from '../models/mimpact';
import { Impacted } from '../models/impacted';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EnumMasterType } from '../enum/MasterType';
@Injectable({
  providedIn: 'root'
})
export class ImpactService {
  // contains base Url
  readonly baseUrl=environment.baseURL
  private urlGetMorgUnit = this.baseUrl + "api/Master/GetAllData?masterTypeId="+EnumMasterType.OrganizationUnits;
  private urlGetMimpact = this.baseUrl + "api/Master/GetAllData?masterTypeId="+EnumMasterType.Impact;
  private urlGetProduct: string = this.baseUrl+"api/Master/GetAllData?masterTypeId="+EnumMasterType.ProductHierarchy;
  private urlPost: string = this.baseUrl + "api/Impact/InsertUpdateImpactOrgProduct";
  private urlGetAllData=this.baseUrl + "api/Impact/GetImpactedOrgProducts?IdeaId="; 
  constructor(private http: HttpClient) { 
    
  }

 
  // This is used to get user's data for Impact-Org Grid and Impact-Product Tree from backend by passing IdeaId;
  getAllData(ideaId, userId): Observable<Impacted> {
    return this.http.get<Impacted>(this.urlGetAllData+ideaId + '&userId=' + userId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  };

  //  This is used to post new Idea Data and also updates existing Idea present in database;
  postData(data): Observable<ImpactedOrg> {
    
    return this.http.post<ImpactedOrg>(this.urlPost, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  // Gets Master Data for "Org Unit" - Dropdown of Impact Organization Grid;
  getMasterDataOrgUnit(businessAreaId: number, businessLineId: number): Observable<MOrgUnit> {
    return this.http.get<MOrgUnit>(this.urlGetMorgUnit+"&businessAreaId="+businessAreaId+"&businessLineId="+businessLineId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  // Gets Master Data for "Impact" - Dropdown of Impact Organization Grid;
  getMasterDataImpact(businessAreaId: number, businessLineId: number): Observable<Mimpact> {
    return this.http.get<Mimpact>(this.urlGetMimpact+"&businessAreaId="+businessAreaId+"&businessLineId="+businessLineId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  // Gets Master Data for Product tree;
  getMasterDataProduct(businessAreaId: number, businessLineId: number) {
    return this.http.get(this.urlGetProduct+"&businessAreaId="+businessAreaId+"&businessLineId="+businessLineId).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  // It handle error ,server connection error and throws it to the component where its is recieved in the error of "subscribe" of service call method;
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
