<app-progress-spinner *ngIf="!showform || submitted"></app-progress-spinner>
<div>
    <main>
        <div *ngIf="showform" class="container">
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="projectForm" (ngSubmit)="submit($event)">
                <div class="col-md-12 col-lg-12 label-header">
                    <label class="label-headercolor label-text">Organization </label>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-md-3 col-lg-3">
                        <label for="businessArea" class="label">Business Area <span class="asterisk_input">*</span>
                        </label>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <select id="businessArea" class="form-control invalid" aria-label="businessArea"
                            formControlName="businessAreaName"
                            style="margin-top: 4px; height: 30px !important; font-size: 0.7rem !important; padding: 1px;"
                            (change)="updateBusinessArea()">
                            <option value="">Please select</option>
                            <option *ngFor="let ba of roleBusinessArea" [value]="ba.BusinessAreaId">
                                {{ba.BusinessAreaName}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <label for="businessLine" class="label">Business Line <span class="asterisk_input">*</span>
                        </label>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <select id="businessLine" class="form-control invalid" aria-label="businessLine"
                            formControlName="businessLineName"
                            style="margin-top: 4px; height: 30px !important; font-size: 0.7rem !important; padding: 1px;"
                            (change)="updateBusinessLine()">
                            <option value="">Please select</option>
                            <option *ngFor="let bl of roleBusinessLine" [value]="bl.BusinessLineId">
                                {{bl.BusinessLineName}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12 label-header">
                    <label class="label-headercolor label-text">Team </label>
                </div>
                <div class="row row-height row row-height-height row-style" style="margin-bottom: 10px;">
                    <div class="col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <label>Submitter <span class="asterisk_input">*</span> </label>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <form [formGroup]="submitterPeoplePickr" style="width: 100%; position: absolute"
                                        (click)="autofocus('sub')">
                                        <mat-form-field appearance="legacy" class="myPanel no-underline">
                                            <mat-select style="
                                      font-size: 0.7rem;
                                      padding-left: 10px;
                                      position: relative;
                                      top: -17px;
                                    " formControlName="submitterPeoplePickr" (closed)="clearSearch('submitter')"
                                                (click)="autofocus('sub')">
                                                <mat-form-field [floatLabel]="'never'"
                                                    class="no-underline no-wrapper sticky-search">
                                                    <mat-label class="search-label">Search Email...</mat-label>
                                                    <input role="textbox" style="
                                          padding-left: 10px;
                                          position: relative;
                                          top: -2px;
                                          border: 1px solid blue;
                                          box-shadow: 0px 0px 6px;
                                          border-radius: 2px;
                                          width: 100%;
                                          height: 20px;
                                          bottom: 4px;
                                          align-items: center;
                                        " matInput #myInputSub type="text" [formControl]="searchInputSubmitter"
                                                        (input)="fetchUserData($event, false, 'submitter')"
                                                        autocomplete="off" />
                                                </mat-form-field>
                                                <mat-option class="mat-option-hide" disabled></mat-option>
                                                <mat-select-trigger role="textbox" aria-label="peoplePicker">
                                                    <span *ngIf="submitterPeoplePickr.value">
                                                        {{
                                                        submitterPeoplePickr.get("submitterPeoplePickr")
                                                        .value
                                                        ? submitterPeoplePickr.get("submitterPeoplePickr")
                                                        .value.FullName
                                                        : ""
                                                        }}
                                                    </span>
                                                </mat-select-trigger>
                                                <mat-option class="font-control"
                                                    *ngFor="let user of submitterSearchData" [value]="user"
                                                    (click)="assignValueId(user, 'submitter')">
                                                    {{ user.Email }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input maxlength="50" type="text" aria-label="orgCodeSub"
                                        class="form-control textfield__input invalid" formControlName="orgCodeSub" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <label>Financial Controller <span class="asterisk_input">*</span></label>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <form [formGroup]="financialControllerPeoplePickr"
                                        style="width: 100%; position: absolute" (click)="autofocus('fc')">
                                        <mat-form-field appearance="legacy" class="myPanel no-underline">
                                            <mat-select style="
                                      font-size: 0.7rem;
                                      padding-left: 10px;
                                      position: relative;
                                      top: -17px;
                                    " formControlName="financialControllerPeoplePickr"
                                                (closed)="clearSearch('Financialcontroller')" (click)="autofocus('fc')">
                                                <mat-form-field [floatLabel]="'never'"
                                                    class="no-underline no-wrapper sticky-search">
                                                    <mat-label class="search-label">Search Email...</mat-label>
                                                    <input role="textbox" style="
                                          padding-left: 10px;
                                          position: relative;
                                          top: -2px;
                                          border: 1px solid blue;
                                          box-shadow: 0px 0px 6px;
                                          border-radius: 2px;
                                          width: 100%;
                                          height: 20px;
                                          bottom: 4px;
                                          align-items: center;
                                        " matInput #myInputFC type="text"
                                                        [formControl]="searchInputFinancialController"
                                                        (input)="fetchUserData($event, false, 'Financialcontroller')"
                                                        autocomplete="off" />
                                                </mat-form-field>
                                                <mat-option class="mat-option-hide" disabled></mat-option>
                                                <mat-select-trigger role="textbox" aria-label="peoplePicker">
                                                    <span *ngIf="financialControllerPeoplePickr.value">
                                                        {{
                                                        financialControllerPeoplePickr.get("financialControllerPeoplePickr")
                                                        .value
                                                        ? financialControllerPeoplePickr.get(
                                                        "financialControllerPeoplePickr"
                                                        ).value.FullName
                                                        : ""
                                                        }}
                                                    </span>
                                                </mat-select-trigger>
                                                <mat-option class="font-control"
                                                    *ngFor="let user of financialControllerSearchData" [value]="user"
                                                    (click)="assignValueId(user, 'Financialcontroller')">
                                                    {{ user.Email }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input type="text" aria-label="orgCodeFC"
                                        class="form-control textfield__input invalid" maxlength="50"
                                        formControlName="orgCodeFC" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <mat-slide-toggle class="approval-toggle" formControlName="approvalFinancialController">
                                    <label>Approval</label>
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row-height row row-height-height row-style" style="margin-bottom: 10px;">
                    <div class="col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <label>Product Manager <span class="asterisk_input">*</span> </label>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">

                                    <form [formGroup]="productManagerPeoplePickr"
                                        style="width: 100%; position: absolute" (click)="autofocus('productM')">
                                        <mat-form-field appearance="legacy" class="myPanel no-underline">
                                            <mat-select style="
                                      font-size: 0.7rem;
                                      padding-left: 10px;
                                      position: relative;
                                      top: -17px;
                                    " formControlName="productManagerPeoplePickr"
                                                (closed)="clearSearch('ProductManager')"
                                                (click)="autofocus('productM')">
                                                <mat-form-field [floatLabel]="'never'"
                                                    class="no-underline no-wrapper sticky-search">
                                                    <mat-label class="search-label">Search Email...</mat-label>
                                                    <input role="textbox" style="
                                          padding-left: 10px;
                                          position: relative;
                                          top: -2px;
                                          border: 1px solid blue;
                                          box-shadow: 0px 0px 6px;
                                          border-radius: 2px;
                                          width: 100%;
                                          height: 20px;
                                          bottom: 4px;
                                          align-items: center;
                                        " matInput #myInputProductM type="text"
                                                        [formControl]="searchInputProductManager"
                                                        (input)="fetchUserData($event, false, 'ProductManager')"
                                                        autocomplete="off" />
                                                </mat-form-field>
                                                <mat-option class="mat-option-hide" disabled></mat-option>
                                                <mat-select-trigger role="textbox" aria-label="peoplePicker">
                                                    <span *ngIf="productManagerPeoplePickr.value">
                                                        {{
                                                        productManagerPeoplePickr.get("productManagerPeoplePickr")
                                                        .value
                                                        ? productManagerPeoplePickr.get("productManagerPeoplePickr")
                                                        .value.FullName
                                                        : ""
                                                        }}
                                                    </span>
                                                </mat-select-trigger>
                                                <mat-option class="font-control"
                                                    *ngFor="let user of productManagerSearchData" [value]="user"
                                                    (click)="assignValueId(user, 'ProductManager')">
                                                    {{ user.Email }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input maxlength="50" type="text" aria-label="orgCodeProductM"
                                        class="form-control textfield__input invalid"
                                        formControlName="orgCodeProductM" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <mat-slide-toggle class="approval-toggle" formControlName="approvalProductManager" >
                                    <label>Approval</label>
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <label>Service Manager</label>
                                <!-- <label>Service Manager <span class="asterisk_input">*</span></label> -->
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <form [formGroup]="serviceManagerPeoplePickr"
                                        style="width: 100%; position: absolute" (click)="autofocus('serviceM')">
                                        <mat-form-field appearance="legacy" class="myPanel no-underline">
                                            <mat-select style="
                                      font-size: 0.7rem;
                                      padding-left: 10px;
                                      position: relative;
                                      top: -17px;
                                    " formControlName="serviceManagerPeoplePickr"
                                                (closed)="clearSearch('servicemanager')"
                                                (click)="autofocus('serviceM')">
                                                <mat-form-field [floatLabel]="'never'"
                                                    class="no-underline no-wrapper sticky-search">
                                                    <mat-label class="search-label">Search Email...</mat-label>
                                                    <input role="textbox" style="
                                          padding-left: 10px;
                                          position: relative;
                                          top: -2px;
                                          border: 1px solid blue;
                                          box-shadow: 0px 0px 6px;
                                          border-radius: 2px;
                                          width: 100%;
                                          height: 20px;
                                          bottom: 4px;
                                          align-items: center;
                                        " matInput #myInputServiceM type="text"
                                                        [formControl]="searchInputServiceManager"
                                                        (input)="fetchUserData($event, false, 'servicemanager')"
                                                        autocomplete="off" />
                                                </mat-form-field>
                                                <mat-option class="mat-option-hide" disabled></mat-option>
                                                <mat-select-trigger role="textbox" aria-label="peoplePicker">
                                                    <span *ngIf="serviceManagerPeoplePickr.value">
                                                        {{
                                                        serviceManagerPeoplePickr.get("serviceManagerPeoplePickr")
                                                        .value
                                                        ? serviceManagerPeoplePickr.get(
                                                        "serviceManagerPeoplePickr"
                                                        ).value.FullName
                                                        : ""
                                                        }}
                                                    </span>
                                                </mat-select-trigger>
                                                <mat-option class="font-control"
                                                    *ngFor="let user of serviceManagerSearchData" [value]="user"
                                                    (click)="assignValueId(user, 'servicemanager')">
                                                    {{ user.Email }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input type="text" aria-label="orgCodeServiceM"
                                        class="form-control textfield__input" maxlength="50"
                                        formControlName="orgCodeServiceM" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <mat-slide-toggle class="approval-toggle" formControlName="approvalServiceManager" >
                                    <label>Approval</label>
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row-height row row-height-height row-style" style="margin-bottom: 10px;">
                    <div class="col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <label>Project Manager</label>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">

                                    <form [formGroup]="projectManagerPeoplePickr"
                                        style="width: 100%; position: absolute" (click)="autofocus('projectM')">
                                        <mat-form-field appearance="legacy" class="myPanel no-underline">
                                            <mat-select style="
                                      font-size: 0.7rem;
                                      padding-left: 10px;
                                      position: relative;
                                      top: -17px;
                                    " formControlName="projectManagerPeoplePickr"
                                                (closed)="clearSearch('projectmanager')"
                                                (click)="autofocus('projectM')">
                                                <mat-form-field [floatLabel]="'never'"
                                                    class="no-underline no-wrapper sticky-search">
                                                    <mat-label class="search-label">Search Email...</mat-label>
                                                    <input role="textbox" style="
                                          padding-left: 10px;
                                          position: relative;
                                          top: -2px;
                                          border: 1px solid blue;
                                          box-shadow: 0px 0px 6px;
                                          border-radius: 2px;
                                          width: 100%;
                                          height: 20px;
                                          bottom: 4px;
                                          align-items: center;
                                        " matInput #myInputProjectM type="text"
                                                        [formControl]="searchInputProjectManager"
                                                        (input)="fetchUserData($event, false, 'projectmanager')"
                                                        autocomplete="off" />
                                                </mat-form-field>
                                                <mat-option class="mat-option-hide" disabled></mat-option>
                                                <mat-select-trigger role="textbox" aria-label="peoplePicker">
                                                    <span *ngIf="projectManagerPeoplePickr.value">
                                                        {{
                                                        projectManagerPeoplePickr.get("projectManagerPeoplePickr")
                                                        .value
                                                        ? projectManagerPeoplePickr.get("projectManagerPeoplePickr")
                                                        .value.FullName
                                                        : ""
                                                        }}
                                                    </span>
                                                </mat-select-trigger>
                                                <mat-option class="font-control"
                                                    *ngFor="let user of projectManagerSearchData" [value]="user"
                                                    (click)="assignValueId(user, 'projectmanager')">
                                                    {{ user.Email }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input maxlength="50" type="text" aria-label="orgCodeProjectM"
                                        class="form-control textfield__input" formControlName="orgCodeProjectM" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <mat-slide-toggle class="approval-toggle" formControlName="approvalProjectManager" >
                                    <label>Approval</label>
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <label>Procurement Engineer</label>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <form [formGroup]="procurementEngineerPeoplePickr"
                                        style="width: 100%; position: absolute" (click)="autofocus('pe')">
                                        <mat-form-field appearance="legacy" class="myPanel no-underline">
                                            <mat-select style="
                                      font-size: 0.7rem;
                                      padding-left: 10px;
                                      position: relative;
                                      top: -17px;
                                    " formControlName="procurementEngineerPeoplePickr"
                                                (closed)="clearSearch('ProcurementEngineer')" (click)="autofocus('pe')">
                                                <mat-form-field [floatLabel]="'never'"
                                                    class="no-underline no-wrapper sticky-search">
                                                    <mat-label class="search-label">Search Email...</mat-label>
                                                    <input role="textbox" style="
                                          padding-left: 10px;
                                          position: relative;
                                          top: -2px;
                                          border: 1px solid blue;
                                          box-shadow: 0px 0px 6px;
                                          border-radius: 2px;
                                          width: 100%;
                                          height: 20px;
                                          bottom: 4px;
                                          align-items: center;
                                        " matInput #myInputPE type="text"
                                                        [formControl]="searchInputProcurementEngineer"
                                                        (input)="fetchUserData($event, false, 'ProcurementEngineer')"
                                                        autocomplete="off" />
                                                </mat-form-field>
                                                <mat-option class="mat-option-hide" disabled></mat-option>
                                                <mat-select-trigger role="textbox" aria-label="peoplePicker">
                                                    <span *ngIf="procurementEngineerPeoplePickr.value">
                                                        {{
                                                        procurementEngineerPeoplePickr.get("procurementEngineerPeoplePickr")
                                                        .value
                                                        ? procurementEngineerPeoplePickr.get(
                                                        "procurementEngineerPeoplePickr"
                                                        ).value.FullName
                                                        : ""
                                                        }}
                                                    </span>
                                                </mat-select-trigger>
                                                <mat-option class="font-control"
                                                    *ngFor="let user of procurementEngineerSearchData" [value]="user"
                                                    (click)="assignValueId(user, 'ProcurementEngineer')">
                                                    {{ user.Email }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input type="text" aria-label="orgCodePE" class="form-control textfield__input"
                                        maxlength="50" formControlName="orgCodePE" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <mat-slide-toggle class="approval-toggle" formControlName="approvalProcurementEngineer" >
                                    <label>Approval</label>
                                </mat-slide-toggle>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12 label-header">
                    <label class="label-headercolor label-text">Milestones and Status
                    </label>
                </div>
                <div class="row">
                    <div class="col-md-3 col-lg-3" style="border-right: 1px solid; top: 10px;">
                        <span style="font-weight: bold; font-size: 0.75rem">M120</span>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>Date </label>
                            </div>
                            <div class="col-md-9 col-lg-9" style="margin-top: 4px;">
                                <input class="ttmp" type="text" aria-label="Datepicker" [matDatepicker]="picker1"
                                    formControlName="dateM120" placeholder=" mm/dd/yyyy" style="width: 50%;" />
                                <mat-datepicker-toggle class="ttmp1" matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </div>
                        </div>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>Achieved </label>
                            </div>
                            <div class="col-md-3 col-lg-3" style="margin-top: 4px;">
                                <mat-slide-toggle class="milestone-toggle" formControlName="achievedM120" 
                                    (change)="ontogglechangeM120($event)">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>EK slides </label>
                            </div>
                            <div class="col-md-7 col-lg-7">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input (input)="ekSlideBind($event)" type="text " aria-label="EKSlideM120"
                                        class="form-control textfield__input invalid" formControlName="EKSlideM120" />
                                </div>
                            </div>
                            <div class="col-md-0 col-lg-0">
                                <a href="{{ ekSlideValue }}" title="Go To Link: {{ ekSlideValue }}" target="_blank"
                                    rel="noopener" class="form-control textfield__input borderStyle"
                                    style="float: right"><img alt="TFS Link icon" src="../assets/icons/globe.svg"
                                        height="22" width="22" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3" style="border-right: 1px solid; top: 10px;">
                        <span style="font-weight: bold; font-size: 0.75rem">M150</span>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>Date </label>
                            </div>
                            <div class="col-md-9 col-lg-9" style="margin-top: 4px;">
                                <input class="ttmp" type="text" aria-label="Datepicker" [matDatepicker]="picker2"
                                    formControlName="dateM150" placeholder=" mm/dd/yyyy" style="width: 50%;" />
                                <mat-datepicker-toggle class="ttmp1" matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </div>
                        </div>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>Achieved </label>
                            </div>
                            <div class="col-md-3 col-lg-3" style="margin-top: 4px;">
                                <mat-slide-toggle class="milestone-toggle" formControlName="achievedM150" 
                                    (change)="ontogglechangeM150($event)">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>EK slides </label>
                            </div>
                            <div class="col-md-7 col-lg-7">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input (input)="ekSlideBind($event)" type="text " aria-label="EKSlideM150"
                                        class="form-control textfield__input invalid" formControlName="EKSlideM150" />
                                </div>
                            </div>
                            <div class="col-md-0 col-lg-0">
                                <a href="{{ ekSlideValue }}" title="Go To Link: {{ ekSlideValue }}" target="_blank"
                                    rel="noopener" class="form-control textfield__input borderStyle"
                                    style="float: right"><img alt="TFS Link icon" src="../assets/icons/globe.svg"
                                        height="22" width="22" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3" style="border-right: 1px solid; top: 10px;">
                        <span style="font-weight: bold; font-size: 0.75rem">M200</span>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>Date </label>
                            </div>
                            <div class="col-md-9 col-lg-9" style="margin-top: 4px;">
                                <input class="ttmp" type="text" aria-label="Datepicker" [matDatepicker]="picker3"
                                    formControlName="dateM200" placeholder=" mm/dd/yyyy" style="width: 50%;" />
                                <mat-datepicker-toggle class="ttmp1" matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </div>
                        </div>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>Achieved </label>
                            </div>
                            <div class="col-md-3 col-lg-3" style="margin-top: 4px;">
                                <mat-slide-toggle class="milestone-toggle" formControlName="achievedM200" 
                                (change)="ontogglechangeM200($event)">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>EK slides </label>
                            </div>
                            <div class="col-md-7 col-lg-7">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input (input)="ekSlideBind($event)" type="text " aria-label="EKSlideM200"
                                        class="form-control textfield__input invalid" formControlName="EKSlideM200" />
                                </div>
                            </div>
                            <div class="col-md-0 col-lg-0">
                                <a href="{{ ekSlideValue }}" title="Go To Link: {{ ekSlideValue }}" target="_blank"
                                    rel="noopener" class="form-control textfield__input borderStyle"
                                    style="float: right"><img alt="TFS Link icon" src="../assets/icons/globe.svg"
                                        height="22" width="22" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3" style="top: 10px;">
                        <span style="font-weight: bold; font-size: 0.75rem">M300</span><span
                            class="asterisk_input">*</span>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>Date </label>
                            </div>
                            <div class="col-md-9 col-lg-9" style="margin-top: 4px;">
                                <input class="ttmp" type="text" aria-label="Datepicker" [matDatepicker]="picker4"
                                    formControlName="dateM300" placeholder=" mm/dd/yyyy" style="width: 50%;" />
                                <mat-datepicker-toggle class="ttmp1" matSuffix [for]="picker4"></mat-datepicker-toggle>
                                <mat-datepicker #picker4></mat-datepicker>
                            </div>
                        </div>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>Achieved </label>
                            </div>
                            <div class="col-md-3 col-lg-3" style="margin-top: 4px;">
                                <mat-slide-toggle class="milestone-toggle" formControlName="achievedM300" 
                                (change)="ontogglechangeM300($event)" [checked]="checked">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row row-height row-style">
                            <div class="col-md-3 col-lg-3">
                                <label>EK slides </label>
                            </div>
                            <div class="col-md-7 col-lg-7">
                                <div class="textfield js-textfield textfield-with-prefix">
                                    <input (input)="ekSlideBind($event)" type="text " aria-label="EKSlideM300"
                                        class="form-control textfield__input invalid" formControlName="EKSlideM300" />
                                </div>
                            </div>
                            <div class="col-md-0 col-lg-0">
                                <a href="{{ ekSlideValue }}" title="Go To Link: {{ ekSlideValue }}" target="_blank"
                                    rel="noopener" class="form-control textfield__input borderStyle"
                                    style="float: right"><img alt="TFS Link icon" src="../assets/icons/globe.svg"
                                        height="22" width="22" /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row-height row row-height-height row-style"
                    style="margin-top: 30px; margin-bottom: 10px;">
                    <div class="col-md-auto col-lg-auto">
                        <label for="projectStatus" class="label">Overall Project Status <span
                                class="asterisk_input">*</span>
                        </label>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <select id="projectStatus" class="form-control invalid" aria-label="status"
                            formControlName="MeasureStatusId"
                            style="margin-top: 4px; height: 30px !important; font-size: 0.7rem !important; padding: 1px;">
                            <option value="">Please select</option>
                            <option *ngFor="let s of status.Data" [value]="s.MeasureStatusId">
                                {{s.MeasureStatusTitle}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12 label-header">
                    <label class="label-headercolor label-text">Rationale
                    </label>
                </div>
                <div class="row" style="margin-top: 10px; margin-bottom: 10px;">
                    <div class="col-md-3 col-lg-1">
                        <label>Title <span class="asterisk_input">*</span></label>
                    </div>
                    <div class="col-md-9 col-lg-11">
                        <div class="textfield js-textfield textfield-with-prefix"
                            style="margin-bottom: -1.66667rem !important;">
                            <input maxlength="255" type="text" aria-label="title"
                                class="form-control textfield__input invalid" formControlName="title" />
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-top: 10px; margin-bottom: 10px;">
                    <div class="col-md-1 col-lg-1">
                        <label> DNA <span class="asterisk_input">*</span></label>
                    </div>
                    <!-- <div class="col-md-4 col-lg-4">
                         <textarea id="DNAtestArea" style="font-size: 0.7rem; width: 99%" aria-label="Remarks_Target"
                        [(ngModel)]="DNAremark"    cols="75" rows="3" maxlength="1500" wrap="hard">
                     
                            </textarea>
                   
                  
                    </div> -->
                    <div class="col-md-4 col-lg-4">
                        <textarea id="DNAtestArea" style="font-size: 0.7rem; width: 99%; height: 102%;"
                            aria-label="Remarks_Target" pInputTextarea formControlName="DNA" cols="75" rows="3"
                            maxlength="1500" wrap="hard"></textarea>
                    </div>

                    <div class="col-md-1 col-lg-1">
                        <label> MMP <span class="asterisk_input">*</span></label>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div style="width: 100%;height: 230px; float: left; margin-left: 1px;">
                            <div class="label-header" style=" border-radius: 3px; margin-top: 0px !important">
                                <label class="label-headercolor" style="padding-bottom: 0px">Click
                                    <button type="button" aria-label="Add" (click)="AddNewRowMMP()"
                                        [disabled]="!isEditable" [ngClass]="{ 'click-not-allowed': !isEditable }">
                                        <img alt="Plus Icon" src="../assets/icons/Plus.svg" width="25" height="25" />
                                    </button>
                                    to add new item</label>
                            </div>

                            <ag-grid-angular id="mmpGrid" style="width: 100%;border: 1px solid #e6e6e6;"
                                class="ag-theme-alpine" [rowData]="mmpRowData" [domLayout]="domLayout"
                                [columnDefs]="mmpColumnDefs" [defaultColDef]="defaultColDef"
                                (rowClicked)="onRowClicked($event)" [gridOptions]="mmpGridOptions"
                                [popupParent]="popupParent" (gridReady)="mmpGridReady($event)"
                                (cellValueChanged)="onCellValueChanged($event)"
                                (gridSizeChanged)="onGridSizeChanged($event)"
                                [frameworkComponents]="frameworkComponents" [enableBrowserTooltips]="true"
                                (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)"
                                [dataTypeDefinitions]="dataTypeDefinitions"
                                [rowHeight]="30">
                            </ag-grid-angular>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row" style="height: 65px;">
                <div class="offset-6 col-md-6">
                    <div style="float: right; margin-top: 10px">
                        <button class="button button--small" (click)="onClickCancel()">
                            Cancel & Exit</button>&nbsp;
                        <button class="button button--small" (click)="onClickNext()"
                            [disabled]="submitted || (!hasUnsavedChanges && projectId == null)">
                            Next</button>&nbsp;
                        <button class="button button--small" type="submit" [disabled]="submitted || !isEditable"
                            (click)="submit($event)">
                            Save & Exit
                        </button>
                    </div>
                </div>
            </div>
            <!-- validation Business Area -->
            <div>
                <span id="title6" *ngIf="projectForm.get('businessAreaName').invalid; else nextArea">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Business Area",
                    "false"
                    )
                    }}</span>
                <ng-template #nextArea>{{
                    getErrorMessage("", "Business Area", "true")
                    }}</ng-template>
            </div>
            <!-- validation Business Line -->
            <div>
                <span id="title7" *ngIf="projectForm.get('businessLineName').invalid; else nextLine">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Business Line",
                    "false"
                    )
                    }}</span>
                <ng-template #nextLine>{{
                    getErrorMessage("", "Business Line", "true")
                    }}</ng-template>
            </div>
            <!-- Validation Submitter -->
            <div>
                <span id="subError" *ngIf="
                submitterPeoplePickr.get('submitterPeoplePickr').invalid;
                else sub
              ">
                    {{
                    getErrorMessage("Its a Required field", "Submitter", "false")
                    }}</span>
                <ng-template #sub>{{
                    getErrorMessage("", "Submitter", "true")
                    }}</ng-template>
            </div>
            <!-- Validation Financial_Controller -->
            <div>
                <span id="fcError" *ngIf="
                financialControllerPeoplePickr.get('financialControllerPeoplePickr').invalid;
                else fc
              ">
                    {{
                    getErrorMessage("Its a Required field", "Financial_Controller", "false")
                    }}</span>
                <ng-template #fc>{{
                    getErrorMessage("", "Financial_Controller", "true")
                    }}</ng-template>
            </div>
            <!-- Validation Product_Manager -->
            <div>
                <span id="productMError" *ngIf="
                productManagerPeoplePickr.get('productManagerPeoplePickr').invalid;
                else productM
              ">
                    {{
                    getErrorMessage("Its a Required field", "Product_Manager", "false")
                    }}</span>
                <ng-template #productM>{{
                    getErrorMessage("", "Product_Manager", "true")
                    }}</ng-template>
            </div>
            <!-- Validation Service_Manager -->
            <div>
                <span id="serviceMError" *ngIf="
                serviceManagerPeoplePickr.get('serviceManagerPeoplePickr').invalid;
                else serviceM
              ">
                    {{
                    getErrorMessage("Its a Required field", "Service_Manager", "false")
                    }}</span>
                <ng-template #serviceM>{{
                    getErrorMessage("", "Service_Manager", "true")
                    }}</ng-template>
            </div>
            <!-- Validation Project_Manager -->
            <!-- <div>
                <span id="projectMError" *ngIf="
                projectManagerPeoplePickr.get('projectManagerPeoplePickr').invalid;
                else projectM
              ">
                    {{
                    getErrorMessage("Its a Required field", "Project_Manager", "false")
                    }}</span>
                <ng-template #projectM>{{
                    getErrorMessage("", "Project_Manager", "true")
                    }}</ng-template>
            </div>
         Validation Procurement_Engineer -->
            <!-- <div>
                <span id="peError" *ngIf="
                procurementEngineerPeoplePickr.get('procurementEngineerPeoplePickr').invalid;
                else pe
              ">
                    {{
                    getErrorMessage("Its a Required field", "Procurement_Engineer", "false")
                    }}</span>
                <ng-template #pe>{{
                    getErrorMessage("", "Procurement_Engineer", "true")
                    }}</ng-template>
            </div> -->
            <!-- validation Title -->
            <div>
                <span id="title" *ngIf="projectForm.get('title').invalid; else nextTitle">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Title",
                    "false"
                    )
                    }}</span>
                <ng-template #nextTitle>{{
                    getErrorMessage("", "Title", "true")
                    }}</ng-template>
            </div>

            <!-- validation Submitter org code -->
            <div>
                <span id="title3" *ngIf="projectForm.get('orgCodeSub').invalid; else nextScode">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Submitter_Org_Code",
                    "false"
                    )
                    }}</span>
                <ng-template #nextScode>{{
                    getErrorMessage("", "Submitter_Org_Code", "true")
                    }}</ng-template>
            </div>
            <!-- validation FC org code -->
            <div>
                <span id="fcOrgCode" *ngIf="projectForm.get('orgCodeFC').invalid; else fcCode">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Financial_Controller_Org_Code",
                    "false"
                    )
                    }}</span>
                <ng-template #fcCode>{{
                    getErrorMessage("", "Financial_Controller_Org_Code", "true")
                    }}</ng-template>
            </div>
            <!-- validation ProductM org code -->
            <div>
                <span id="productMOrgCode" *ngIf="projectForm.get('orgCodeProductM').invalid; else productMCode">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Product_Manager_Org_Code",
                    "false"
                    )
                    }}</span>
                <ng-template #productMCode>{{
                    getErrorMessage("", "Product_Manager_Org_Code", "true")
                    }}</ng-template>
            </div>
            <!-- validation ServiceM org code -->
            <!-- <div>
                <span id="serviceMOrgCode" *ngIf="projectForm.get('orgCodeServiceM').invalid; else serviceMCode">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Service_Manager_Org_Code",
                    "false"
                    )
                    }}</span>
                <ng-template #serviceMCode>{{
                    getErrorMessage("", "Service_Manager_Org_Code", "true")
                    }}</ng-template>
            </div> -->
            <!-- validation ProjectM org code -->
            <!-- <div>
                <span id="projectMOrgCode" *ngIf="projectForm.get('orgCodeProjectM').invalid; else projectMCode">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Project_Manager_Org_Code",
                    "false"
                    )
                    }}</span>
                <ng-template #projectMCode>{{
                    getErrorMessage("", "Project_Manager_Org_Code", "true")
                    }}</ng-template>
            </div> -->
            <!-- validation PE org code -->
            <!-- <div>
                <span id="peOrgCode" *ngIf="projectForm.get('orgCodePE').invalid; else peCode">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Procurement_Engineer_Org_Code",
                    "false"
                    )
                    }}</span>
                <ng-template #peCode>{{
                    getErrorMessage("", "Procurement_Engineer_Org_Code", "true")
                    }}</ng-template>
            </div> -->
            <!-- validation Overall Project Status -->
            <div>
                <span id="title" *ngIf="projectForm.get('MeasureStatusId').invalid; else nextOPS">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "Overall_Project_Status",
                    "false"
                    )
                    }}</span>
                <ng-template #nextOPS>{{
                    getErrorMessage("", "Overall_Project_Status", "true")
                    }}</ng-template>
            </div>
            <!-- validation DNA -->
            <div>
                <span id="title" *ngIf="projectForm.get('DNA').invalid; else nextDNA">
                    {{
                    getErrorMessage(
                    "Its a
                    Required field",
                    "DNA",
                    "false"
                    )
                    }}</span>
                <ng-template #nextDNA>{{
                    getErrorMessage("", "DNA", "true")
                    }}</ng-template>
            </div>
            <br />
        </div>
    </main>
</div>