
export class CreateManualVBLODTO {
  VbloId: number;
  Vblo: string;
  VbloText: string;
  Type: string;
  Amount: string;
  Price: string;
  Date: Date;
  Kreditor: string;
  Assembly: string;
  UserId: number;
  KmatId: number;
  IsActive: boolean;
  CreatedBy: number;
  CreatedOn: Date;
  ModifiedBy: number;
  ModifiedOn: Date;
  BusinessAreaId: number;
  BusinessLineId: number;
  SubmitterId: any;
}

