import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Idea } from '../models/idea'
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { TCProject } from '../models/TCProject';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private http: HttpClient
  ) { }

  private urlTCPost: string = environment.baseURL+ "api/TCProject/InsertUpdateTCproject";
  private urlTCGet: string = environment.baseURL+ "api/TCProject/GetTCProjectByProjectId";
  private urlTCAchiveMilestoneManually: string = environment.baseURL+ "api/TCProject/ManualAchiveMilestone"
  //  This is used to post new Project Data and also updates existing Project present in database;
  postTCData(data): Observable<TCProject> {
    return this.http.post<TCProject>(this.urlTCPost, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  // This is used to get data from backend by passing IdeaId;
  getTCData(ProjectId): Observable<TCProject> {
    return this.http.get<TCProject>(this.urlTCGet + '?projectID=' + ProjectId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  // This is used to achive milestone manually  by passing projectId,milestoneId,Userid;
  postTCmanualAchiveMilestoneData(ProjectId,MilestoneID,UserId) {
    return this.http.post(this.urlTCAchiveMilestoneManually + '?ProjectId=' + ProjectId + '&MilestoneIdToAchieve=' + MilestoneID + '&UserId=' + UserId, "").pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
