<app-header class="header" [userName]="username"></app-header>
<mat-sidenav-container id="container" fullscreen autosize>
    <mat-sidenav #sidenav
        *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found' && router.url !='/tc-manageProject'"
        mode="side" [(opened)]="navopened">
        <app-nav></app-nav>
    </mat-sidenav>

    <mat-sidenav
        *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'"
        #sidehelp mode="side" [(opened)]="helpopened" position="end">
        <app-help
            *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData'">
        </app-help>
    </mat-sidenav>

    <mat-sidenav-content>
        <div style="position: fixed;width :20px;margin-top: 1px;z-index: 10;">
            <button aria-label="Arrow" style="width: 20px;height: 25px;background-color: transparent;"
                *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found' && router.url != '/tc-manageProject'"
                class="navbar-toggle" (click)="toggleSideNav()">
                <img alt="LeftArrow" *ngIf="sidenavopened" src="../assets/icons/LeftArrow.png" class="arrow-style">
                <img alt="RightArrow" *ngIf="!sidenavopened" src="../assets/icons/RightArrow.png" class="arrow-style">
            </button>
        </div>
        <div style="position: fixed;top:70px;width: 20px;margin-top: 1px;z-index: 10;" [style.right.px]="fixed">
            <button aria-label="Arrow" style="width: 20px;height: 25px;background-color: transparent;"
                *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found' && router.url != '/tc-manageProject'"
                class="navbar-toggle" (click)="toggleHelpNav()">

                <img alt="RightArrow" *ngIf="sideHelpOpen" src="../assets/icons/RightArrow.png" class="arrow-style">
                <img alt="LeftArrow" *ngIf="!sideHelpOpen" src="../assets/icons/LeftArrow.png" class="arrow-style">
            </button>
        </div>
        <div *ngIf="marqueeMessage!=''">
            <div class="marqueDiv"
                *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'">
                <p class="marqueP">{{marqueeMessage}}
                </p>
            </div>
        </div>

        <div class="container"
            *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'
            && router.url!='/tc-project' && router.url!='/tc-manageProject' && router.url!='/tc-configuration' && router.url!='/tc-configuration-vblo' &&  router.url!='/tc-eq'&& router.url!='/tc-eq' && router.url!='/tc-cs' && router.url!='/tc-scm' && router.url!='/tc-localcosts'  
            && router.url!='/tc-investmentplan' && router.url!='/plc-tc-directcost' && router.url!='/plc-tc-summary' && !router.url.includes('tc-investmentplan/investment-subdetails')">
            <div class="row" style="margin-right: 0.5px;margin-left: 0.5px;">
                <div class="col-2" style="margin-left: -2px;">
                    <p style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;"><b>Identifier: {{Identifier}}</b>
                    </p>
                </div>
                <div class="col-7">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:22px"
                        title="{{product}}"><b>Title: {{Productivity}}</b></p>
                </div>
                <div class="col-3">
                    <p style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;text-align: right;">
                        <b>Productivity Target In {{currency}}: {{ ProductivityFCEUR }}</b>
                    </p>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="(router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found')  
            && (router.url == '/tc-project' 
             || router.url ==' /plc-tc-directcost' || router.url == '/tc-configuration' ||router.url == '/tc-configuration-vblo')">
            <div class="row" style="margin-right: 0.5px;margin-left: 0.5px; margin-top: 5px;">
                <div class="col-2" style="margin-left: -2px;">
                    <p style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;"><b>Id: {{ProjectId}}</b>
                    </p>
                </div>
                <div class="col-7">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:22px"
                        title="{{product}}"><b>Project Title: {{ProjectTitle}}</b></p>
                </div>
            </div>
        </div>
        <!-- <div class="container" *ngIf="(router.url == '/tc-configuration-vblo' )">
            <div class="row" style="margin-right: 0.5px;margin-left: 0.5px;margin-top: 5px;">
                <div class="col-1" style="margin-left: -2px;">
                    <p style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;"><b>Id: {{ProjectId}}</b>
                    </p>
                </div>
                <div class="col-3">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:22px"
                        title="{{product}}"><b>Project Title: {{ProjectTitle}}</b></p>
                </div>
                <div class="col-3">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;" title="{{product}}">
                        <b>Configuration: <select id="projectStatus" class="form-control1 invalid"
                                aria-label="ConFiguration" [(ngModel)]="configurationID"
                                (change)="ConfigurationChanged()"
                                style="font-size:0.7rem; font-weight: 550;margin-Top: -29px; margin-left:87px;width: 60%;">
                                <option *ngFor="let s of ConFiguration" [value]="s.Id">
                                    {{s.Name}}
                                </option>
                            </select> </b></p>

                </div>
                <div class="col-2">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:22px"
                        title="{{product}}"><b>stand: {{Stand}}</b></p>
                </div>
                <div class="col-3">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:22px"
                        title="{{product}}"><b>Milestone: {{MilestoneName}} </b></p>

                </div>
            </div>
        </div> -->
        <div class="container"
            *ngIf="(router.url == '/tc-eq' ||router.url == '/tc-cs' ||router.url == '/tc-scm' ||router.url == '/tc-localcosts'|| router.url == '/plc-tc-directcost' || router.url == '/tc-investmentplan'|| router.url.includes('tc-investmentplan/investment-subdetails') || router.url == '/plc-tc-summary')">
            <div class="row" style="margin-right: 0.5px;margin-left: 0.5px;margin-top: 5px;">
                <div class="col-1" style="margin-left: 0px;">
                    <p style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;"><b>Id: {{ProjectId}}</b>
                    </p>
                </div>
                <div class="col-3">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:0px"
                        title="{{product}}"><b>Project Title: {{ProjectTitle}}</b></p>
                </div>
                <div class="col-3">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;" title="{{product}}">
                        <b>Configuration: <select id="projectStatus" class="form-control1 invalid"
                                aria-label="ConFiguration" [(ngModel)]="configurationID"
                                (change)="ConfigurationChanged()"
                                style="font-size:0.7rem; font-weight: 550;margin-Top: -29px; margin-left:87px;width: 60%;">
                                <option *ngFor="let s of ConFiguration" [value]="s.Id">
                                    {{s.Name}}
                                </option>
                            </select> </b></p>

                </div>
                <div class="col-2">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:0px"
                        title="{{product}}"><b>stand: {{Stand}}</b></p>
                </div>
                <div class="col-2">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:0px"
                        title="{{product}}"><b>Milestone: {{MilestoneName}} </b></p>

                </div>
                <div class="col-1">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:0px"
                        title="{{product}}"><b>Currency: {{currency}} </b></p>

                </div>
            </div>
        </div>
        <div>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<div>
    <app-footer id="footer"></app-footer>
</div>