import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
//import { DoublingEditorComponent } from '../non-plc/costsavings/doubling-editor.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { ProjectService } from 'src/app/services/project.service';
import { ScmtargetcostService } from 'src/app/services/scmtargetcost.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { ToasterService } from 'src/app/services/toaster.service';
import { SCMLCTargetCost, SCMLCScenario, TCSCMLCTargetCost, SCMLCForecast, SCMLCActual } from 'src/app/models/tcscmtarget-cost';
import { EnumSCMComponent } from 'src/app/enum/enum-scmcomponent';
//import { CostSaving } from 'src/app/models/cost-saving';
let countryCode;

@Component({
  selector: 'app-scm-targetcost',
  templateUrl: './scm-targetcost.component.html',
  styleUrls: ['./scm-targetcost.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ScmTargetcostComponent implements OnInit, OnDestroy {
  projectId: any;
  scmMasterData: any;

  LabourData: any[];
  OverheadData: any[];
  SpecialDirectCostData: any[];

  refTitle: string = "";
  tarTitle: string = "";
  optATitle: string = "";
  optBTitle: string = "";
  optCTitle: string = "";

  objData: { projectId: any; moduleId: any; };
  moduleId: number = 8;
  referenceTitle: string;
  targetTitle: string;
  businessAreaId: any;
  businessLineId: any;
  showGrid: boolean = false;
  public userInOrg: any;
  public allUserList: any;
  projectSubmitterId: any;
  hasUnsavedChanges: boolean = false;
  isEditable: boolean = true;
  submitted: any;

  rowData: any[];
  optAVisible: boolean = false;
  optBVisible: boolean = false;
  optCVisible: boolean = false;
  overheadOptAVisible: boolean = false;
  overheadOptBVisible: boolean = false;
  overheadOptCVisible: boolean = false;
  specialdirectCostoptAVisible: boolean = false;
  specialdirectCostoptBVisible: boolean = false;
  specialdirectCostoptCVisible: boolean = false;

  refGridLabourData: any[];
  refColumnDefs: any;
  refDefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };
  tarGridLabourData: any[];
  tarColumnDefs: any;
  tarDefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };
  optAGridLabourData: any[];
  optAColumnDefs: any;
  optADefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };

  optBGridLabourData: any[];
  optBColumnDefs: any;
  optBDefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };

  optCGridLabourData: any[];
  optCColumnDefs: any;
  optCDefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };

  refGridOverheadData: any[];

  tarGridOverheadData: any[];

  optAGridOverheadData: any[];

  optBGridOverheadData: any[];

  optCGridOverheadData: any[];


  refGridSpecialDirectCostData: any[];

  tarGridSpecialDirectCostData: any[];

  optAGridSpecialDirectCostData: any[];

  optBGridSpecialDirectCostData: any[];

  optCGridSpecialDirectCostData: any[];

  tarGridApi: any;

  tarPinnedBottomData: any;
  tarFrameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };
  refLabourGridApi: any;
  refPinnedBottomData: any;
  refFrameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };

  configurationId: any;
  masterdata: any;
  frameworkComponents: { DoublingEditor: typeof DoublingEditorComponent; };

  gridApi: any;

  DirectCostSCMTar: number;
  DirectCostSCMRef: number;
  regex: any;
  refLabourGrid: any;

  refGridOverheadApi: any;
  tarGridOverheadApi: any;
  refGridSpecialApi: any;
  tarGridSpecialApi: any;
  tarGridAApi: any;
  tarGridBApi: any;
  tarGridCApi: any;
  tarGridAOverheadApi: any;
  tarGridBOverheadApi: any;
  tarGridCOverheadApi: any;
  tarGridASpecialApi: any;
  tarGridBSpecialApi: any;
  tarGridCSpecialApi: any;
  rowClassRulesRef: { colorTotal: (params: any) => boolean; };
  submitResponse: any;
  public modifiedRefSCMLabourRows = [];
  public modifiedTarSCMLabourRows = [];
  public modifiedOptASCMLabourRows = [];
  public modifiedOptBSCMLabourRows = [];
  public modifiedOptCSCMLabourRows = [];
  public modifiedRefSCMOverheadRows = [];
  public modifiedTarSCMOverheadRows = [];
  public modifiedOptASCMOverheadRows = [];
  public modifiedOptBSCMOverheadRows = [];
  public modifiedOptCSCMOverheadRows = [];
  public modifiedRefSCMSpecialRows = [];
  public modifiedTarSCMSpecialRows = [];
  public modifiedOptASCMSpecialRows = [];
  public modifiedOptBSCMSpecialRows = [];
  public modifiedOptCSCMSpecialRows = [];

  EQTargetCostLabour: any;
  EQTargetCostRefLabourMek: number;
  EQTargetCostTarLabourMek: number;
  EQTargetCostOptALabourMek: number;
  EQTargetCostOptBLabourMek: number;
  EQTargetCostOptCLabourMek: number;


  EQTargetCostTarLabourActualMek: any;
  EQTargetCostOptALabourActualMek: any;
  EQTargetCostOptBLabourActualMek: any;
  EQTargetCostOptCLabourActualMek: any;
  rowHeight: number;
  headerHeight: number;
  addnewRowOptA: number = 0;
  addnewRowOptB: number = 0;
  addnewRowOptC: number = 0;
  configSub: any;
  sizeFitToRefresh: number = 0;
  domLayout: string;
  // toast: any;
  // generateTarPinnedBottomData: any;
  constructor(
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userService: UserInfoService,
    private menuService: MenuService,
    private ScmtargetcostService: ScmtargetcostService,
  ) { }

  ngOnInit(): void {

    this.DirectCostSCMRef = 0;
    this.DirectCostSCMTar = 0;
    this.sharedService.getProjectIdAndTitle();
    this.projectId = this.localStorageService.get("projectId");
    this.objData = {
      projectId: this.projectId,
      moduleId: this.moduleId
    }

    countryCode = this.localStorageService.get('countryCode');

    // this.referenceTitle = this.referenceTitle == "" ? "Add Title" : "Amar";
    // this.targetTitle = this.targetTitle == "" ? "Add Title" : "Amar";
    if (this.projectId != null) {
      this.projectService.getTCData(this.projectId).subscribe((data: any) => {
        if (data.StatusCode == 200) {
          this.businessAreaId = data.Data.BusinessAreaId;
          this.businessLineId = data.Data.BusinessLineId;
          this.sharedService.loggedInUser.subscribe((user: any) => {
            if (user) {
              this.showGrid = true
              this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
                this.allUserList = d;
                this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
                if (this.userInOrg != undefined) {
              //    console.log("user", this.userInOrg, data.Data);
                  this.projectSubmitterId = this.userInOrg.UserId;
                  if (this.userInOrg.TargetRoleId == 1 || this.userInOrg.TargetRoleId == 2) {
                    this.isEditable = true;
                  }
                  else if (this.userInOrg.TargetRoleId == 3) {
                    if (data.Data.SubmitterId == this.userInOrg.UserId || data.Data.FinancialControllerId == this.userInOrg.UserId
                      || data.Data.ProductManagerId == this.userInOrg.UserId || data.Data.ProjectManagerId == this.userInOrg.UserId ||
                      data.Data.ServiceManagerId == this.userInOrg.UserId || data.Data.ProcurementEngineerId == this.userInOrg.UserId) {
                      this.isEditable = true;
                    }
                    else {
                      this.isEditable = false;
                    }
                  }
                }
                this.loadData();
                this.loadRefLabourGridStructure();
                this.loadTarGridStructure();
                this.loadOptAGridStructure();
                this.loadOptBGridStructure();
                this.loadOptCGridStructure();
              })
            }
          })
        }
      });
    } else {
      this.businessAreaId = this.businessLineId = 0;
    }
  }
  loadData() {
    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d;
      this.optAVisible = false;
      this.optBVisible = false;
      this.optCVisible = false;
      this.ScmtargetcostService.getSCMTargetCostData(this.configurationId).subscribe(d => {
        this.showGrid = true;
        let errorMsgeFromBack = d.Data.Message;
        if (d.StatusCode == 200) {

          this.scmMasterData = d.Data;
          if (this.scmMasterData.EQTargetCostSummaries.length > 0) {
            this.EQTargetCostLabour = this.scmMasterData.EQTargetCostSummaries.filter(item => item.Name === 'MEKTotal')

            if (this.EQTargetCostLabour.length != 0) {
              if (this.EQTargetCostLabour[0].ReferenceScenario)
                this.EQTargetCostRefLabourMek = this.EQTargetCostLabour[0].ReferenceScenario.PerSystem ?? 0

              if (this.EQTargetCostLabour[0].TargetScenario) {
                this.EQTargetCostTarLabourMek = this.EQTargetCostLabour[0].TargetScenario.TargetCost.PerSystem ?? 0
                this.EQTargetCostTarLabourActualMek = this.EQTargetCostLabour[0].TargetScenario.ActualMEKs ?? 0
              }
              if (this.EQTargetCostLabour[0].OptionA) {
                this.EQTargetCostOptALabourMek = this.EQTargetCostLabour[0].OptionA.TargetCost.PerSystem ?? 0
                this.EQTargetCostOptALabourActualMek = this.EQTargetCostLabour[0].OptionA.TargetCost.ActualMEKs ?? 0
              }

              if (this.EQTargetCostLabour[0].OptionB) {
                this.EQTargetCostOptBLabourMek = this.EQTargetCostLabour[0].OptionB.TargetCost.PerSystem ?? 0
                this.EQTargetCostOptBLabourActualMek = this.EQTargetCostLabour[0].OptionB.TargetCost.ActualMEKs ?? 0
              }

              if (this.EQTargetCostLabour[0].OptionC) {
                this.EQTargetCostOptCLabourMek = this.EQTargetCostLabour[0].OptionC.TargetCost.PerSystem ?? 0
                this.EQTargetCostOptCLabourActualMek = this.EQTargetCostLabour[0].OptionC.TargetCost.ActualMEKs ?? 0
              }

            }
          }


          if (this.scmMasterData.SCMLCTargetCosts.length > 0) {
            this.LabourData = this.scmMasterData.SCMLCTargetCosts.filter(item => item.Component === 'Labour')
            this.OverheadData = this.scmMasterData.SCMLCTargetCosts.filter(item => item.Component === 'Overhead')
            this.SpecialDirectCostData = this.scmMasterData.SCMLCTargetCosts.filter(item => item.Component === 'SpecialDirectCost')

            if (this.LabourData.length != 0) {
              if (this.LabourData[0].OptionA)
                this.optAVisible = true
              if (this.LabourData[0].OptionB)
                this.optBVisible = true
              if (this.LabourData[0].OptionC)
                this.optCVisible = true
            }
            if (this.OverheadData.length != 0) {
              if (this.OverheadData[0].OptionA)
                this.optAVisible = true
              if (this.OverheadData[0].OptionB)
                this.optBVisible = true
              if (this.OverheadData[0].OptionC)
                this.optCVisible = true
            }
            if (this.SpecialDirectCostData.length != 0) {
              if (this.SpecialDirectCostData[0].OptionA)
                this.optAVisible = true
              if (this.SpecialDirectCostData[0].OptionB)
                this.optBVisible = true
              if (this.SpecialDirectCostData[0].OptionC)
                this.optCVisible = true
            }
            //           if (this.LabourData[0].OptionA || this.OverheadData[0].OptionA) this.optAVisible = true
            //           if (this.LabourData[0].OptionB || this.OverheadData[0].OptionB ) this.optBVisible = true
            //           if (this.LabourData[0].OptionC || this.OverheadData[0].OptionC ) this.optCVisible = true

            this.refTitle = this.scmMasterData.SCMLCTargetCosts[0].ReferenceScenario.ScenarioTitle ?? ""
            this.tarTitle = this.scmMasterData.SCMLCTargetCosts[0].TargetScenario.ScenarioTitle ?? ""
            this.optATitle = this.scmMasterData.SCMLCTargetCosts[0].OptionA?.ScenarioTitle ?? ""
            this.optBTitle = this.scmMasterData.SCMLCTargetCosts[0].OptionB?.ScenarioTitle ?? ""
            this.optCTitle = this.scmMasterData.SCMLCTargetCosts[0].OptionC?.ScenarioTitle ?? ""

            this.refGridLabourData = [];
            this.tarGridLabourData = [];
            this.optAGridLabourData = [];
            this.optBGridLabourData = [];
            this.optCGridLabourData = [];
            this.refGridOverheadData = [];
            this.tarGridOverheadData = [];
            this.optAGridOverheadData = [];
            this.optBGridOverheadData = [];
            this.optCGridOverheadData = [];
            this.refGridSpecialDirectCostData = [];
            this.tarGridSpecialDirectCostData = [];
            this.optAGridSpecialDirectCostData = [];
            this.optBGridSpecialDirectCostData = [];
            this.optCGridSpecialDirectCostData = [];

            for (let labourData of this.LabourData) {
              this.bindRefDatatoGrid(labourData, this.refGridLabourData)
              this.bindTargetDatatoGrid(labourData, this.tarGridLabourData)
              if (labourData.OptionA)
                this.bindOptionADatatoGrid(labourData, this.optAGridLabourData)
              if (labourData.OptionB)
                this.bindOptionBDatatoGrid(labourData, this.optBGridLabourData)
              if (labourData.OptionC)
                this.bindOptionCDatatoGrid(labourData, this.optCGridLabourData)
            }

            for (let overheadData of this.OverheadData) {
              this.bindRefDatatoGrid(overheadData, this.refGridOverheadData)
              this.bindTargetDatatoGrid(overheadData, this.tarGridOverheadData)
              if (overheadData.OptionA)
                this.bindOptionADatatoGrid(overheadData, this.optAGridOverheadData)
              if (overheadData.OptionB)
                this.bindOptionBDatatoGrid(overheadData, this.optBGridOverheadData)
              if (overheadData.OptionC)
                this.bindOptionCDatatoGrid(overheadData, this.optCGridOverheadData)
            }

            for (let directCost of this.SpecialDirectCostData) {
              this.bindRefDatatoGrid(directCost, this.refGridSpecialDirectCostData)
              this.bindTargetDatatoGrid(directCost, this.tarGridSpecialDirectCostData)
              if (directCost.OptionA)
                this.bindOptionADatatoGrid(directCost, this.optAGridSpecialDirectCostData)
              if (directCost.OptionB)
                this.bindOptionBDatatoGrid(directCost, this.optBGridSpecialDirectCostData)
              if (directCost.OptionC)
                this.bindOptionCDatatoGrid(directCost, this.optCGridSpecialDirectCostData)
            }

          }
          else {
            this.LabourData = [];
            this.OverheadData = [];
            this.SpecialDirectCostData = [];
            this.refGridLabourData = [];
            this.tarGridLabourData = [];
            this.optAGridLabourData = [];
            this.optBGridLabourData = [];
            this.optCGridLabourData = [];
            this.refGridOverheadData = [];
            this.tarGridOverheadData = [];
            this.optAGridOverheadData = [];
            this.optBGridOverheadData = [];
            this.optCGridOverheadData = [];
            this.refGridSpecialDirectCostData = [];
            this.tarGridSpecialDirectCostData = [];
            this.optAGridSpecialDirectCostData = [];
            this.optBGridSpecialDirectCostData = [];
            this.optCGridSpecialDirectCostData = [];

            this.refTitle = ""
            this.tarTitle = ""
            this.optATitle = ""
            this.optBTitle = ""
            this.optCTitle = ""
          }
        }

        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      });
    })
  }

  loadRefLabourGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Labor Costs' || data === 'Total Overhead Costs' || data === 'Total Special Direct Costs' || data === 'Total Direct Costs SCM';
      }
    }
    this.refColumnDefs = [
      {
        headerName: "Direct Costs SCM",
        field: "SubComponent",
        tooltipField: "DirectCostsSCM",
        width: 250,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Direct Costs SCM",
        editable: (params) => {
          if ((params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour]  && this.refLabourGridApi.rowModel.rowsToDisplay.length > 1 && params.node.data.ComponentId < 0) || (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead] && this.refGridOverheadApi.rowModel.rowsToDisplay.length > 1  && params.node.data.ComponentId < 0)) {
            return params.data.IsEditable;
          }
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }

          else {
            return params.data.IsEditable;
          }
        },

        singleClickEdit: true,
        // cellStyle: {  borderLeft: '5px solid #a94442' },
        cellStyle: params => {
          if (params.node.data.SubComponent != 'Total Labor Costs' && (params.data.SubComponent == null
            || params.data.SubComponent == '')) {
            //mark police cells as red
            return { borderLeft: '5px solid #a94442' };
          }
          return { borderLeft: '' };
        }

      },

      {
        headerName: "Quantity",
        field: "Quantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "Weight",
        tooltipField: "Weight",
        width: 60,

        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,


      },
      {
        headerName: "Costs",
        headerTooltip: "Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "Absolute",
            tooltipField: "Absolute",
            headerTooltip: "Absolute",
            width: 88,
            // editable: this.isEditable,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.Absolute)
                  })
                  params.data.Absolute = total;
                  return total;
                }
              }
              else {
                return (params.data.Absolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.PercentOfMEK === "" || params.node.data.PercentOfMEK === null || params.node.data.PercentOfMEK == 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }



            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "PercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,
            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else if (params.node.data.Absolute === "" || params.node.data.Absolute === null || params.node.data.Absolute === 0) {
                
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

            singleClickEdit: true
          },
          {
            headerName: "Total",
            field: "Total",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,
            // editable: false,

            // cellRenderer: CurrencyCellRenderer,tooltipValueGetter:CurrencyCellRenderer, valueGetter: Total
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;
                params.api.forEachNode((node) => {

                  total = Number(node.data.Total) + total;
                })
                params.data.Total = total;
                return total;
              }
              else if (params.node.data.SubComponent === 'Total Direct Costs SCM') {
                return params.data.Total;
              }
              else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.PercentOfMEK === null || params.node.data.PercentOfMEK === 0 || params.node.data.PercentOfMEK === "") {

                    total = Number(params.data.Quantity) * Number(params.data.Weight) * Number(params.data.Absolute);
                  }
                  else {

                    permek = Number(params.data.PercentOfMEK) / 100;
                    total = Number(params.data.Quantity) * Number(params.data.Weight) * permek * (this.EQTargetCostRefLabourMek ?? 0);


                  }
                }
                params.data.Total = total;
                return total;
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Change to reference' ||
                params.node.data.PercentOfMEK != "" || params.node.data.PercentOfMEK != null || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      }

    ]
    this.domLayout = 'autoHeight';
    this.refDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      // autoHeight: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.refFrameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    // this.refGridData = [];
  }

  bindRefDatatoGrid(i, target) {
    if (i.SCMId == 0) {
      this.DirectCostSCMRef = -1
    }
    target.push({
      SCMId: i.SCMId,
      ConfigurationId: i.ConfigurationId > 0 ? i.ConfigurationId : this.configurationId,
      SubComponent: i.SubComponent,
      ComponentId: this.DirectCostSCMRef,
      Quantity: i.ReferenceScenario.Quantity,
      Weight: i.ReferenceScenario.Weight,
      Absolute: i.ReferenceScenario.Absolute,
      PercentOfMEK: i.ReferenceScenario.PercentOfMEK,
      Total: i.ReferenceScenario.Total,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable,
    })
  }

  onRefGridReady(params: any) {
    this.refLabourGridApi = params.api;
    this.refLabourGridApi.setGroupHeaderHeight(30);

    this.refPinnedBottomData = this.createDataRef(this.totalRef(this.refLabourGridApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.refPinnedBottomData);
    this.sizeFitToRefresh = 1;
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeFitToRefresh != 0) {
      this.refLabourGridApi.sizeColumnsToFit();
    }
  }

  onRefGridReadyoverhead(params: any) {
    this.refGridOverheadApi = params.api;
    this.refGridOverheadApi.setGroupHeaderHeight(30);

    this.refPinnedBottomData = this.createDataRefoverhead(this.totalRef(this.refGridOverheadApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.refPinnedBottomData);


  }
  onRefGridReadySpecial(params: any) {
    this.refGridSpecialApi = params.api;
    this.refGridSpecialApi.setGroupHeaderHeight(30);
    // let changeInReferenceRow = this.createRowChangeReference();
    this.refPinnedBottomData = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.refPinnedBottomData);


  }

  // create chenge to reference row in target grid;
  // createRowChangeReference() {
  //   let result = [];
  //   let referenceTotalData: any;
  //   let targetTotalData: any;
  //   let refernecabouslute:any;
  //   referenceTotalData = this.totalRef(this.refLabourGridApi)
  //   referenceTotalData = referenceTotalData[1];
  //   refernecabouslute=referenceTotalData[0];

  //  console.log(referenceTotalData);
  //     result.push({
  //       targetScenerioTitle: 'Change to reference',
  //       material1: referenceTotalData.material1 - targetTotalData.material1,
  //       labor1: referenceTotalData.labor1 - targetTotalData.labor1,
  //       overhead: referenceTotalData.overhead - targetTotalData.overhead,
  //       product: referenceTotalData.product - targetTotalData.product,
  //       material2: referenceTotalData.material2 - targetTotalData.material2,
  //       labor2: referenceTotalData.labor2 - targetTotalData.labor2,
  //       travel: referenceTotalData.travel - targetTotalData.travel,
  //       service: referenceTotalData.service - targetTotalData.service,
  //       specialfactory: referenceTotalData.specialfactory - targetTotalData.specialfactory,
  //       local: referenceTotalData.local - targetTotalData.local,
  //       others: referenceTotalData.others - targetTotalData.others,
  //       special: referenceTotalData.special - targetTotalData.special,
  //       totaldirectcost: referenceTotalData.totaldirectcost - targetTotalData.totaldirectcost
  //     })



  //   return result;
  // }
  // calculate total for reference grid for bottom "total row"
  totalRef(gridType: any) {
    let abs = 0;
    let total = 0;
    gridType.forEachNode(function (node) {
      abs = Number(node.data.Absolute) + abs
      total = Number(node.data.Total) + total
    })

    return [abs, total];
  }



  createDataRef(totalCost, isEditable) {
    let result = [];
    result.push({
      SubComponent: "Total Labor Costs",
      Absolute: totalCost[0],
      Total: totalCost[1]
    });

    return result;
  }

  createDataRefoverhead(totalCost, isEditable) {
    let result = [];
    result.push({
      SubComponent: "Total Overhead Costs",
      Absolute: totalCost[0],
      Total: totalCost[1]
    });

    return result;
  }

  createDataRefSpecial(totalCost, isEditable) {
   // console.log(this.refGridOverheadApi);
    let absOverhead = 0;
    let totalOverhead = 0;
    this.refGridOverheadApi.forEachNode(function (node) {
      absOverhead = Number(node.data.Absolute) + absOverhead
      totalOverhead = Number(node.data.Total) + totalOverhead
    })
    let abslabour = 0;
    let totallabour = 0;
    this.refLabourGridApi.forEachNode(function (node) {
      abslabour = Number(node.data.Absolute) + abslabour
      totallabour = Number(node.data.Total) + totallabour
    })
    //console.log(absOverhead, totalOverhead);
    let result = [];
    result.push({
      SubComponent: "Total Special Direct Costs",
      Absolute: totalCost[0],
      Total: totalCost[1]
    });
   
    result.push({
      SubComponent: "Total Direct Costs SCM",

      Absolute: totalCost[0] + absOverhead + abslabour,
      Total: totalCost[1] + totalOverhead + abslabour

    });
    
    return result;
  }

  loadTarGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Labor Costs' || data === 'Total Overhead Costs' || data == 'Total Special Direct Costs' || data === 'Total Direct Costs SCM';
      }
    }
    this.tarColumnDefs = [
      {
        headerName: "SubComponent",
        field: "SubComponent",
        tooltipField: "SubComponent",
        hide: true
      },
      {
        headerName: "Quantity",
        field: "TargetQuantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "TargetWeight",
        tooltipField: "Weight",
        width: 60,

        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "TargetAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,
            // editable: this.isEditable,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.TargetAbsolute)
                  })
                  params.data.TargetAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.TargetAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null || params.node.data.TargetPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }


            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else  if (params.node.data.TargetAbsolute === "" || params.node.data.TargetAbsolute === null || params.node.data.TargetAbsolute === 0) {
               
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },
          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,
            // editable: false,

            cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.TargetTotal

            , cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
          
            , singleClickEdit: true
          },
          {
            headerName: "Delta TC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaTCVsRef",
            tooltipField: "Delta TC vs. Reference",
            headerTooltip: "Delta TC vs. Reference",
            width: 66,
            // editable: false,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent != 'Total Labor Costs' && params.node.data.SubComponent != 'Total Overhead Costs' && params.node.data.SubComponent != 'Total Special Direct Costs' && params.node.data.SubComponent != 'Total Direct Costs SCM') {

                if (params.node.data.TargetTotal == null || Number(params.node.data.TargetTotal) == 0) {
                  return 0
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetTotal) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
                }

                params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? 0 : params.node.data.DeltaTCVsRef
                return params.node.data.DeltaTCVsRef;
              } else if (params.node.data.SubComponent == 'Total Direct Costs SCM') {
                return null
              } else {
                let target = 0;
                let ref = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    target += Number(node.data.TargetTotal)
                    ref += Number(node.data.ReferenceCost)
                  })

                  if (target == 0) { return 0 }
                  params.data.DeltaTCVsRef = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
                  return params.data.DeltaTCVsRef;
                }
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
           
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ForecastAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 88,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ForecastAbsolute)
                  })
                  params.data.ForecastAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ForecastAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null || params.node.data.ForecastPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else  if (params.node.data.ForecastAbsolute === "" || params.node.data.ForecastAbsolute === null || params.node.data.ForecastAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs')
              // if (params.node.data.SubComponent != 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs')
              {
                let total = 0;
                params.api.forEachNode((node) => {
                  total = Number(node.data.ForecastTotal) + total;
                })
                params.data.ForecastTotal = total;
                return total;
              } if (params.node.data.SubComponent === 'Total Direct Costs SCM') {
                return params.data.ForecastTotal;
              }
              else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.ForecastPercentOfMEK != null) {
                    permek = Number(params.data.ForecastPercentOfMEK) / 100;
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * (this.EQTargetCostTarLabourMek ?? 0);
                    //abs = Number(node.data.Absolute) + abs

                  }
                  else {
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ForecastAbsolute);
                  }
                }
                params.data.ForecastTotal = total;
                return total;

              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
           
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "Delta FC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaFCVsRef",
            tooltipField: "Delta FC vs. Reference",
            headerTooltip: "Delta FC vs. Reference",
            width: 88,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ForecastDeltaFCVsRef, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ActualAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ActualAbsolute)
                  })
                  params.data.ActualAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ActualAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null || params.node.data.ActualPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else if (params.node.data.ActualAbsolute === "" || params.node.data.ActualAbsolute === null || params.node.data.ActualAbsolute === 0) {

                return params.data.IsEditable;
              }

              else {
                return false;

              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.ActualTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
          
            , singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. ACT",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaTCVsACT",
            tooltipField: "Delta TC vs. ACT",
            headerTooltip: "Delta TC vs. ACT",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ActualDeltaTCVsACT, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
           
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      },

    ]
    // this.tarDomLayout = 'autoHeight';
    this.tarDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      // autoHeight: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.tarFrameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    this.tarGridLabourData = [];
  }

  bindTargetDatatoGrid(i, target) {
    if (i.TargetScenario.TargetCost?.SCMId == 0 || i.TargetScenario.TargetCost?.SCMId == null) {
      this.DirectCostSCMRef = -1
    }
    target.push({
      SCMIdTargetCost: i.TargetScenario.TargetCost?.SCMId ?? 0,
      SCMIdForecast: i.TargetScenario.Forecast?.SCMId ?? 0,
      SCMIdActual: i.TargetScenario.Actual?.SCMId ?? 0,
      ConfigurationId: i.ConfigurationId > 0 ? i.ConfigurationId : this.configurationId,
      SubComponent: i.SubComponent,
      ComponentId: this.DirectCostSCMRef,
      ReferenceCost: i.ReferenceScenario.Total,
      TargetQuantity: i.TargetScenario.TargetCost?.Quantity ?? null,
      ForecastQuantity: i.TargetScenario.Forecast?.Quantity ?? null,
      ActualsQuantity: i.TargetScenario.Actual?.Quantity ?? null,
      TargetWeight: i.TargetScenario.TargetCost?.Weight ?? null,
      ForecastWeight: i.TargetScenario.Forecast?.Weight ?? null,
      ActualsWeight: i.TargetScenario.Actual?.Weight ?? null,
      TargetAbsolute: i.TargetScenario.TargetCost?.Absolute ?? null,
      ForecastAbsolute: i.TargetScenario.Forecast?.Absolute ?? null,
      ActualAbsolute: i.TargetScenario.Actual?.Absolute ?? null,
      TargetPercentOfMEK: i.TargetScenario.TargetCost?.PercentOfMEK ?? null,
      ForecastPercentOfMEK: i.TargetScenario.Forecast?.PercentOfMEK ?? null,
      ActualPercentOfMEK: i.TargetScenario.Actual?.PercentOfMEK ?? null,
      TargetTotal: i.TargetScenario.TargetCost?.Total ?? null,
      ForecastTotal: i.TargetScenario.Forecast?.Total ?? null,
      ActualTotal: i.TargetScenario.Actual?.Total ?? null,
      DeltaTCVsRef: i.TargetScenario.TargetCost?.DeltaTCVsRef ?? null,
      DeltaFCVsRef: i.TargetScenario.Forecast?.DeltaFCVsRef ?? null,
      DeltaTCVsACT: i.TargetScenario.Actual?.DeltaTCVsACT ?? null,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable,
    })
  }

  onTarGridReady(params: any) {
    this.tarGridApi = params.api;
    this.tarGridApi.setGroupHeaderHeight(30);

    this.tarPinnedBottomData = this.createDataTar(this.totalTar(this.tarGridApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }

  onTarGridOverheadReady(params: any) {
    this.tarGridOverheadApi = params.api;
    this.tarGridOverheadApi.setGroupHeaderHeight(30);

    this.tarPinnedBottomData = this.createDataTaroverhead(this.totalTar(this.tarGridOverheadApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }

  onTarGridSpecialReady(params: any) {
    this.tarGridSpecialApi = params.api;
    this.tarGridSpecialApi.setGroupHeaderHeight(30);

    this.tarPinnedBottomData = this.createDataTarSpecial(this.totalTar(this.tarGridSpecialApi), this.isEditable);
    //this.tarPinnedBottomData = this.createDataTarSpecial1(this.totalTar(this.tarGridSpecialApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }

  totalTar(gridType: any) {
    let targetAbsolute = 0;
    let targetTotal = 0;
    let deltaTCVsRef = 0;
    let forecastAbsolute = 0;
    let forecastTotal = 0;
    let deltaFCVsRef = 0;
    let actualAbsolute = 0;
    let actualTotal = 0;
    let deltaTCVsACT = 0;
    gridType.forEachNode(function (node) {
      targetAbsolute = Number(node.data.TargetAbsolute) + targetAbsolute
      targetTotal = Number(node.data.TargetTotal) + targetTotal
      deltaTCVsRef = Number(node.data.DeltaTCVsRef) + deltaTCVsRef
      forecastAbsolute = Number(node.data.ForecastAbsolute) + forecastAbsolute
      forecastTotal = Number(node.data.ForecastTotal) + forecastTotal
      deltaFCVsRef = Number(node.data.DeltaFCVsRef) + deltaFCVsRef
      actualAbsolute = Number(node.data.ActualAbsolute) + actualAbsolute
      actualTotal = Number(node.data.ActualTotal) + actualTotal
      deltaTCVsACT = Number(node.data.DeltaTCVsACT) + deltaTCVsACT
    })
    return [targetAbsolute, targetTotal, null, forecastAbsolute, forecastTotal, null, actualAbsolute, actualTotal, null];
  }

  // creates row for "Total Service Costs" and "CCR"
  createDataTar(total, isEditable) {
    let result = [];
    result.push({
      SubComponent: "Total Labor Costs",
      TargetAbsolute: total[0],
      TargetTotal: total[1],
      DeltaTCVsRef: total[2],
      ForecastAbsolute: total[3],
      ForecastTotal: total[4],
      DeltaFCVsRef: total[5],
      ActualAbsolute: total[6],
      ActualTotal: total[7],
      DeltaTCVsACT: total[8],
    });

    return result;
  }

  createDataTaroverhead(total, isEditable) {
    let result = [];
    result.push({
      SubComponent: "Total Overhead Costs",
      TargetAbsolute: total[0],
      TargetTotal: total[1],
      DeltaTCVsRef: total[2],
      ForecastAbsolute: total[3],
      ForecastTotal: total[4],
      DeltaFCVsRef: total[5],
      ActualAbsolute: total[6],
      ActualTotal: total[7],
      DeltaTCVsACT: total[8],
    });

    return result;
  }

  // createDataTarSpecial(total, isEditable) {
   
  //   let resultoverhead = [];
  //   let resultLabour = [];
  //   let resultAoverhead = [];
  //   let resultALabour = [];
  //   let resultBoverhead = [];
  //   let resultBLabour = [];
  //   let resultCoverhead = [];
  //   let resultCLabour = [];
  //   resultoverhead = this.totalTar(this.tarGridOverheadApi);
  //   resultLabour = this.totalTar(this.tarGridApi);
  //   // if(this.optAVisible=true)
  //   if (this.optAVisible == true) {
  //     resultAoverhead = this.totalTar(this.tarGridAOverheadApi);
  //     resultALabour = this.totalTar(this.tarGridAApi);

  //   }
  //   if (this.optBVisible == true) {
  //     resultBoverhead = this.totalTar(this.tarGridBOverheadApi);
  //     resultBLabour = this.totalTar(this.tarGridBApi);
  //   }
  //   if (this.optCVisible == true) {
  //     resultCoverhead = this.totalTar(this.tarGridCOverheadApi);
  //     resultCLabour = this.totalTar(this.tarGridCApi);
  //   }
  //   let result = [];
  //   result.push({
  //     SubComponent: "Total Special Direct Costs",
  //     TargetAbsolute: total[0],
  //     TargetTotal: total[1],
  //     DeltaTCVsRef: total[2],
  //     ForecastAbsolute: total[3],
  //     ForecastTotal: total[4],
  //     DeltaFCVsRef: total[5],
  //     ActualAbsolute: total[6],
  //     ActualTotal: total[7],
  //     DeltaTCVsACT: total[8],
  //   });
  
  //   if (this.optAVisible == false && this.optBVisible == false && this.optCVisible == false) {
  //     result.push({
  //       SubComponent: "Total Direct Costs SCM",
  //       TargetAbsolute: total[0] + resultoverhead[0] + resultLabour[0],
  //       TargetTotal: total[1] + resultoverhead[1] + resultLabour[1],
  //       DeltaTCVsRef: null,
  //       ForecastAbsolute: total[3] + resultoverhead[3] + resultLabour[3],
  //       ForecastTotal: total[4] + resultoverhead[4] + resultLabour[4],
  //       DeltaFCVsRef: null,
  //       ActualAbsolute: total[6] + resultoverhead[6] + resultLabour[6],
  //       ActualTotal: total[7] + resultoverhead[8] + resultLabour[8],
  //       DeltaTCVsACT: null,
  //     });
  //   }
  //   //if(this.optAVisible==true)
  //   if (this.optAVisible == true && this.optBVisible == false && this.optCVisible == false) {
  //     result.push({
  //       SubComponent: "Total Direct Costs SCM",
  //       TargetAbsolute: total[0] + resultAoverhead[0] + resultALabour[0],
  //       TargetTotal: total[1] + resultAoverhead[1] + resultALabour[1],
  //       DeltaTCVsRef: null,
  //       ForecastAbsolute: total[3] + resultAoverhead[3] + resultALabour[3],
  //       ForecastTotal: total[4] + resultAoverhead[4] + resultALabour[4],
  //       DeltaFCVsRef: null,
  //       ActualAbsolute: total[6] + resultAoverhead[6] + resultALabour[6],
  //       ActualTotal: total[7] + resultAoverhead[8] + resultALabour[8],
  //       DeltaTCVsACT: null,
  //     });
  //   }
  //   if (this.optBVisible == true && this.optCVisible == false) {

  //     result.push({
  //       SubComponent: "Total Direct Costs SCM",
  //       TargetAbsolute: total[0] + resultBoverhead[0] + resultBLabour[0],
  //       TargetTotal: total[1] + resultBoverhead[1] + resultBLabour[1],
  //       DeltaTCVsRef: null,
  //       ForecastAbsolute: total[3] + resultBoverhead[3] + resultBLabour[3],
  //       ForecastTotal: total[4] + resultBoverhead[4] + resultBLabour[4],
  //       DeltaFCVsRef: null,
  //       ActualAbsolute: total[6] + resultBoverhead[6] + resultBLabour[6],
  //       ActualTotal: total[7] + resultBoverhead[8] + resultBLabour[8],
  //       DeltaTCVsACT: null,
  //     });
  //   }
  //   if (this.optCVisible == true) {
  //     result.push({
  //       SubComponent: "Total Direct Costs SCM",
  //       TargetAbsolute: total[0] + resultCoverhead[0] + resultCLabour[0],
  //       TargetTotal: total[1] + resultCoverhead[1] + resultCLabour[1],
  //       DeltaTCVsRef: null,
  //       ForecastAbsolute: total[3] + resultCoverhead[3] + resultCLabour[3],
  //       ForecastTotal: total[4] + resultCoverhead[4] + resultCLabour[4],
  //       DeltaFCVsRef: null,
  //       ActualAbsolute: total[6] + resultCoverhead[6] + resultCLabour[6],
  //       ActualTotal: total[7] + resultCoverhead[8] + resultCLabour[8],
  //       DeltaTCVsACT: null,
  //     });
  //   }
  //   return result;
  // }

  createDataTarSpecial(total, isEditable) {
   // console.log(this.refGridOverheadApi);
    let resultoverhead = [];
    let resultLabour = [];
    resultoverhead = this.totalTar(this.tarGridOverheadApi);
    resultLabour = this.totalTar(this.tarGridApi);
   
    let result = [];
    result.push({
      SubComponent: "Total Special Direct Costs",
      TargetAbsolute: total[0],
      TargetTotal: total[1],
      DeltaTCVsRef: total[2],
      ForecastAbsolute: total[3],
      ForecastTotal: total[4],
      DeltaFCVsRef: total[5],
      ActualAbsolute: total[6],
      ActualTotal: total[7],
      DeltaTCVsACT: total[8],
    });
  
      result.push({
        SubComponent: "Total Direct Costs SCM",
        TargetAbsolute: total[0] + resultoverhead[0] + resultLabour[0],
        TargetTotal: total[1] + resultoverhead[1] + resultLabour[1],
        DeltaTCVsRef: null,
        ForecastAbsolute: total[3] + resultoverhead[3] + resultLabour[3],
        ForecastTotal: total[4] + resultoverhead[4] + resultLabour[4],
        DeltaFCVsRef: null,
        ActualAbsolute: total[6] + resultoverhead[6] + resultLabour[6],
        ActualTotal: total[7] + resultoverhead[7] + resultLabour[7],
        DeltaTCVsACT: null,
      });
   // }
    //if(this.optAVisible==true)
   
    return result;
  }
  createDataOptASpecial(total, isEditable) {
    let resultoverhead = [];
    let resultLabour = [];
    let resultAoverhead = [];
    let resultALabour = [];
    let resultBoverhead = [];
    let resultBLabour = [];
    let resultCoverhead = [];
    let resultCLabour = [];
    resultoverhead = this.totalTar(this.tarGridOverheadApi);
    resultLabour = this.totalTar(this.tarGridApi);
    // if(this.optAVisible=true)
    if (this.optAVisible == true) {
      resultAoverhead = this.totalTar(this.tarGridAOverheadApi);
      resultALabour = this.totalTar(this.tarGridAApi);

    }
    
    let result = [];
    result.push({
      SubComponent: "Total Special Direct Costs",
      TargetAbsolute: total[0],
      TargetTotal: total[1],
      DeltaTCVsRef: total[2],
      ForecastAbsolute: total[3],
      ForecastTotal: total[4],
      DeltaFCVsRef: total[5],
      ActualAbsolute: total[6],
      ActualTotal: total[7],
      DeltaTCVsACT: total[8],
    });
   
  
    //if(this.optAVisible==true)
  //  if (this.optAVisible == true && this.optBVisible == false && this.optCVisible == false) {
      result.push({
        SubComponent: "Total Direct Costs SCM",
        TargetAbsolute: total[0] + resultAoverhead[0] + resultALabour[0],
        TargetTotal: total[1] + resultAoverhead[1] + resultALabour[1],
        DeltaTCVsRef: null,
        ForecastAbsolute: total[3] + resultAoverhead[3] + resultALabour[3],
        ForecastTotal: total[4] + resultAoverhead[4] + resultALabour[4],
        DeltaFCVsRef: null,
        ActualAbsolute: total[6] + resultAoverhead[6] + resultALabour[6],
        ActualTotal: total[7] + resultAoverhead[7] + resultALabour[7],
        DeltaTCVsACT: null,
      });
 //   }
   
    return result;
  }
  
  createDataOptBSpecial(total, isEditable) {
   
    let resultoverhead = [];
    let resultLabour = [];
    let resultAoverhead = [];
    let resultALabour = [];
    let resultBoverhead = [];
    let resultBLabour = [];
    let resultCoverhead = [];
    let resultCLabour = [];
    resultoverhead = this.totalTar(this.tarGridOverheadApi);
    resultLabour = this.totalTar(this.tarGridApi);
   
    if (this.optBVisible == true) {
      resultBoverhead = this.totalTar(this.tarGridBOverheadApi);
      resultBLabour = this.totalTar(this.tarGridBApi);
    }
   
    let result = [];
    result.push({
      SubComponent: "Total Special Direct Costs",
      TargetAbsolute: total[0],
      TargetTotal: total[1],
      DeltaTCVsRef: total[2],
      ForecastAbsolute: total[3],
      ForecastTotal: total[4],
      DeltaFCVsRef: total[5],
      ActualAbsolute: total[6],
      ActualTotal: total[7],
      DeltaTCVsACT: total[8],
    });
    
      result.push({
        SubComponent: "Total Direct Costs SCM",
        TargetAbsolute: total[0] + resultBoverhead[0] + resultBLabour[0],
        TargetTotal: total[1] + resultBoverhead[1] + resultBLabour[1],
        DeltaTCVsRef: null,
        ForecastAbsolute: total[3] + resultBoverhead[3] + resultBLabour[3],
        ForecastTotal: total[4] + resultBoverhead[4] + resultBLabour[4],
        DeltaFCVsRef: null,
        ActualAbsolute: total[6] + resultBoverhead[6] + resultBLabour[6],
        ActualTotal: total[7] + resultBoverhead[7] + resultBLabour[7],
        DeltaTCVsACT: null,
      });
   // }
   
    return result;
  }

  createDataOptCSpecial(total, isEditable) {
 
    let resultoverhead = [];
    let resultLabour = [];
   
    let resultCoverhead = [];
    let resultCLabour = [];
    resultoverhead = this.totalTar(this.tarGridOverheadApi);
    resultLabour = this.totalTar(this.tarGridApi);
    // if(this.optAVisible=true)
    
    if (this.optCVisible == true) {
      resultCoverhead = this.totalTar(this.tarGridCOverheadApi);
      resultCLabour = this.totalTar(this.tarGridCApi);
    }
    let result = [];
    result.push({
      SubComponent: "Total Special Direct Costs",
      TargetAbsolute: total[0],
      TargetTotal: total[1],
      DeltaTCVsRef: total[2],
      ForecastAbsolute: total[3],
      ForecastTotal: total[4],
      DeltaFCVsRef: total[5],
      ActualAbsolute: total[6],
      ActualTotal: total[7],
      DeltaTCVsACT: total[8],
    });
   
  
    if (this.optCVisible == true) {
      result.push({
        SubComponent: "Total Direct Costs SCM",
        TargetAbsolute: total[0] + resultCoverhead[0] + resultCLabour[0],
        TargetTotal: total[1] + resultCoverhead[1] + resultCLabour[1],
        DeltaTCVsRef: null,
        ForecastAbsolute: total[3] + resultCoverhead[3] + resultCLabour[3],
        ForecastTotal: total[4] + resultCoverhead[4] + resultCLabour[4],
        DeltaFCVsRef: null,
        ActualAbsolute: total[6] + resultCoverhead[6] + resultCLabour[6],
        ActualTotal: total[7] + resultCoverhead[7] + resultCLabour[7],
        DeltaTCVsACT: null,
      });
    }
    return result;
  }
  loadOptAGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Labor Costs' || data === 'Total Overhead Costs' || data === 'Total Special Direct Costs' || data === 'Total Direct Costs SCM';
      }
    }
    this.optAColumnDefs = [
      {
        headerName: "SubComponent",
        field: "SubComponent",
        tooltipField: "SubComponent",
        hide: true
      },
      {
        headerName: "Quantity",
        field: "TargetQuantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "TargetWeight",
        tooltipField: "Weight",
        width: 60,

        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "TargetAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.TargetAbsolute)
                  })
                  params.data.TargetAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.TargetAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null || params.node.data.TargetPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else 
              if (params.node.data.TargetAbsolute === "" || params.node.data.TargetAbsolute === null || params.node.data.TargetAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,

            cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.TargetTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
            
            , singleClickEdit: true
          },
          {
            headerName: "Delta TC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaTCVsRef",
            tooltipField: "Delta TC vs. Reference",
            headerTooltip: "Delta TC vs. Reference",
            width: 66,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent != 'Total Labor Costs' && params.node.data.SubComponent != 'Total Overhead Costs' && params.node.data.SubComponent != 'Total Special Direct Costs' && params.node.data.SubComponent != 'Total Direct Costs SCM') {

                if (params.node.data.TargetTotal == null || Number(params.node.data.TargetTotal) == 0) {
                  return 0
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetTotal) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
                }

                params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? 0 : params.node.data.DeltaTCVsRef
                return params.node.data.DeltaTCVsRef;
              } else if (params.node.data.SubComponent == 'Total Direct Costs SCM') {
                return null
              } else {
                return 0
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
           
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ForecastAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 88,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ForecastAbsolute)
                  })
                  params.data.ForecastAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ForecastAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null || params.node.data.ForecastPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else 
              if (params.node.data.ForecastAbsolute === "" || params.node.data.ForecastAbsolute === null || params.node.data.ForecastAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },


          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs')
              // if (params.node.data.SubComponent != 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs')
              {
                let total = 0;
                params.api.forEachNode((node) => {
                  total = Number(node.data.ForecastTotal) + total;
                })
                params.data.ForecastTotal = total;
                return total;
              } if (params.node.data.SubComponent === 'Total Direct Costs SCM') {
                return params.data.ForecastTotal;
              }
              else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;



                  if (params.node.data.ForecastPercentOfMEK != null) {
                    permek = Number(params.data.ForecastPercentOfMEK) / 100;
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * (this.EQTargetCostOptALabourMek ?? 0);
                    //abs = Number(node.data.Absolute) + abs

                  }
                  else {
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ForecastAbsolute);
                  }
                }
                params.data.ForecastTotal = total;
                return total;

              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
           
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "Delta FC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaFCVsRef",
            tooltipField: "Delta FC vs. Reference",
            headerTooltip: "Delta FC vs. Reference",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ForecastDeltaFCVsRef, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: false
           
            , singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ActualAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ActualAbsolute)
                  })
                  params.data.ActualAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ActualAbsolute);
              }
              
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null || params.node.data.ActualPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else 
              if (params.node.data.ActualAbsolute === "" || params.node.data.ActualAbsolute === null || params.node.data.ActualAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.ActualTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
          
          },
          {
            headerName: "Delta TC vs. ACT",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaTCVsACT",
            tooltipField: "Delta TC vs. ACT",
            headerTooltip: "Delta TC vs. ACT",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ActualDeltaTCVsACT, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
        
            editable: false,
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      },

    ]
    // this.tarDomLayout = 'autoHeight';
    this.optADefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      // autoHeight: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.frameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    // this.optAGridLabourData = [];
  }

  bindOptionADatatoGrid(i, target) {
    target.push({
      SCMIdTargetCost: i.OptionA?.TargetCost?.SCMId ?? 0,
      SCMIdForecast: i.OptionA?.Forecast?.SCMId ?? 0,
      SCMIdActual: i.OptionA?.Actual?.SCMId ?? 0,
      ConfigurationId: i.ConfigurationId > 0 ? i.ConfigurationId : this.configurationId,
      SubComponent: i.SubComponent,
      ReferenceCost: i.ReferenceScenario.Total,
      TargetQuantity: i.OptionA?.TargetCost?.Quantity ?? null,
      ForecastQuantity: i.OptionA?.Forecast?.Quantity ?? null,
      ActualsQuantity: i.OptionA?.Actual?.Quantity ?? null,
      TargetWeight: i.OptionA?.TargetCost?.Weight ?? null,
      ForecastWeight: i.OptionA?.Forecast?.Weight ?? null,
      ActualsWeight: i.OptionA?.Actual?.Weight ?? null,
      TargetAbsolute: i.OptionA?.TargetCost?.Absolute ?? null,
      ForecastAbsolute: i.OptionA?.Forecast?.Absolute ?? null,
      ActualAbsolute: i.OptionA?.Actual?.Absolute ?? null,
      TargetPercentOfMEK: i.OptionA?.TargetCost?.PercentOfMEK ?? null,
      ForecastPercentOfMEK: i.OptionA?.Forecast?.PercentOfMEK ?? null,
      ActualPercentOfMEK: i.OptionA?.Actual?.PercentOfMEK ?? null,
      TargetTotal: i.OptionA?.TargetCost?.Total ?? null,
      ForecastTotal: i.OptionA?.Forecast?.Total ?? null,
      ActualTotal: i.OptionA?.Actual?.Total ?? null,
      DeltaTCVsRef: i.OptionA?.TargetCost?.DeltaTCVsRef ?? null,
      DeltaFCVsRef: i.OptionA?.Forecast?.DeltaFCVsRef ?? null,
      DeltaTCVsACT: i.OptionA?.Actual?.DeltaTCVsACT ?? null,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable
    })
  }

  onTarAGridReady(params: any) {
    
    this.tarGridAApi = params.api;
    this.tarGridAApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptA == 1) {
      this.tarGridApi.forEachNode(x => {
        this.tarGridAApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
            //)
          }]
        })
        this.tarGridAApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptASCMLabourRows, node.data);
        })

      });
    }
    this.tarPinnedBottomData = this.createDataTar(this.totalTar(this.tarGridAApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }

  onTarGridAOverheadReady(params: any) {
    this.tarGridAOverheadApi = params.api;
    this.tarGridAOverheadApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptA == 1) {
      this.tarGridOverheadApi.forEachNode(x => {
        this.tarGridAOverheadApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
          }]
        })
        this.tarGridAOverheadApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptASCMOverheadRows, node.data);
        })
      })
    }
    this.tarPinnedBottomData = this.createDataRefoverhead(this.totalTar(this.tarGridAOverheadApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }
  onTarGridASpecialReady(params: any) {
    this.tarGridASpecialApi = params.api;
    this.tarGridASpecialApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptA == 1) {
      this.tarGridSpecialApi.forEachNode(x => {
        this.tarGridASpecialApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
          }]
        })
        this.tarGridASpecialApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptASCMSpecialRows, node.data);
        })
      })
    }
  //  this.tarPinnedBottomData = this.createDataTarSpecial(this.totalTar(this.tarGridASpecialApi), this.isEditable);
    this.tarPinnedBottomData = this.createDataOptASpecial(this.totalTar(this.tarGridASpecialApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }

  loadOptBGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Labor Costs' || data === 'Total Overhead Costs' || data === 'Total Special Direct Costs' || data === 'Total Direct Costs SCM';
      }
    }
    this.optBColumnDefs = [
      {
        headerName: "SubComponent",
        field: "SubComponent",
        tooltipField: "SubComponent",
        hide: true
      },
      {
        headerName: "Quantity",
        field: "TargetQuantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "TargetWeight",
        tooltipField: "Weight",
        width: 60,

        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "TargetAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.TargetAbsolute)
                  })
                  params.data.TargetAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.TargetAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null || params.node.data.TargetPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else 
              if (params.node.data.TargetAbsolute === "" || params.node.data.TargetAbsolute === null || params.node.data.TargetAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,


            cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.TargetTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            
            editable: false,
            singleClickEdit: true
          },
          {
            headerName: "Delta TC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaTCVsRef",
            tooltipField: "Delta TC vs. Reference",
            headerTooltip: "Delta TC vs. Reference",
            width: 66,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent != 'Total Labor Costs' && params.node.data.SubComponent != 'Total Overhead Costs' && params.node.data.SubComponent != 'Total Special Direct Costs' && params.node.data.SubComponent != 'Total Direct Costs SCM') {

                if (params.node.data.TargetTotal == null || Number(params.node.data.TargetTotal) == 0) {
                  return 0
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetTotal) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
                }

                params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? 0 : params.node.data.DeltaTCVsRef
                return params.node.data.DeltaTCVsRef;
              } else if (params.node.data.SubComponent == 'Total Direct Costs SCM') {
                return null
              } else {
                return 0
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
          
            editable: false,
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ForecastAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 88,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ForecastAbsolute)
                  })
                  params.data.ForecastAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ForecastAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null || params.node.data.ForecastPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else 
              if (params.node.data.ForecastAbsolute === "" || params.node.data.ForecastAbsolute === null || params.node.data.ForecastAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },


          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: (params) => {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs')
              // if (params.node.data.SubComponent != 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs')
              {
                let total = 0;
                params.api.forEachNode((node) => {
                  total = Number(node.data.ForecastTotal) + total;
                })
                params.data.ForecastTotal = total;
                return total;
              } if (params.node.data.SubComponent === 'Total Direct Costs SCM') {
                return params.data.ForecastTotal;
              } else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.ForecastPercentOfMEK != null) {
                    permek = Number(params.data.ForecastPercentOfMEK) / 100;
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * (this.EQTargetCostOptBLabourMek ?? 0);
                    //abs = Number(node.data.Absolute) + abs

                  }
                  else {
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ForecastAbsolute);
                  }
                }
                params.data.ForecastTotal = total;
                return total;

              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
           
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "Delta FC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaFCVsRef",
            tooltipField: "Delta FC vs. Reference",
            headerTooltip: "Delta FC vs. Reference",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ForecastDeltaFCVsRef, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
           
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ActualAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ActualAbsolute)
                  })
                  params.data.ActualAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ActualAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null || params.node.data.ActualPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else 
              if (params.node.data.ActualAbsolute === "" || params.node.data.ActualAbsolute === null || params.node.data.ActualAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.ActualTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
          
            singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. ACT",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaTCVsACT",
            tooltipField: "Delta TC vs. ACT",
            headerTooltip: "Delta TC vs. ACT",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ActualDeltaTCVsACT, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
         
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      }

    ]
    // this.tarDomLayout = 'autoHeight';
    this.optBDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      // autoHeight: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.frameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    // this.optBGridLabourData = [];
  }

  bindOptionBDatatoGrid(i, target) {
    target.push({
      SCMIdTargetCost: i.OptionB?.TargetCost?.SCMId ?? 0,
      SCMIdForecast: i.OptionB?.Forecast?.SCMId ?? 0,
      SCMIdActual: i.OptionB?.Actual?.SCMId ?? 0,
      ConfigurationId: i.ConfigurationId > 0 ? i.ConfigurationId : this.configurationId,
      SubComponent: i.SubComponent,
      ReferenceCost: i.ReferenceScenario.Total,
      TargetQuantity: i.OptionB?.TargetCost?.Quantity ?? null,
      ForecastQuantity: i.OptionB?.Forecast?.Quantity ?? null,
      ActualsQuantity: i.OptionB?.Actual?.Quantity ?? null,
      TargetWeight: i.OptionB?.TargetCost?.Weight ?? null,
      ForecastWeight: i.OptionB?.Forecast?.Weight ?? null,
      ActualsWeight: i.OptionB?.Actual?.Weight ?? null,
      TargetAbsolute: i.OptionB?.TargetCost?.Absolute ?? null,
      ForecastAbsolute: i.OptionB?.Forecast?.Absolute ?? null,
      ActualAbsolute: i.OptionB?.Actual?.Absolute ?? null,
      TargetPercentOfMEK: i.OptionB?.TargetCost?.PercentOfMEK ?? null,
      ForecastPercentOfMEK: i.OptionB?.Forecast?.PercentOfMEK ?? null,
      ActualPercentOfMEK: i.OptionB?.Actual?.PercentOfMEK ?? null,
      TargetTotal: i.OptionB?.TargetCost?.Total ?? null,
      ForecastTotal: i.OptionB?.Forecast?.Total ?? null,
      ActualTotal: i.OptionB?.Actual?.Total ?? null,
      DeltaTCVsRef: i.OptionB?.TargetCost?.DeltaTCVsRef ?? null,
      DeltaFCVsRef: i.OptionB?.Forecast?.DeltaFCVsRef ?? null,
      DeltaTCVsACT: i.OptionB?.Actual?.DeltaTCVsACT ?? null,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable
    })
  }

  onTarBGridReady(params: any) {
    this.tarGridBApi = params.api;
    this.tarGridBApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptB == 1) {
      this.tarGridApi.forEachNode(x => {
        this.tarGridBApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,

          }]
        })
        this.tarGridBApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptBSCMLabourRows, node.data);
        })


      });
    }
    this.tarPinnedBottomData = this.createDataTar(this.totalTar(this.tarGridBApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }
  onTarBOverheadReady(params: any) {
    this.tarGridBOverheadApi = params.api;
    this.tarGridBOverheadApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptB == 1) {
      this.tarGridOverheadApi.forEachNode(x => {
        this.tarGridBOverheadApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
          }]
        })
        this.tarGridBOverheadApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptBSCMOverheadRows, node.data);
        })

      })
    }
    this.tarPinnedBottomData = this.createDataRefoverhead(this.totalTar(this.tarGridBOverheadApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }
  onTarBSpecialReady(params: any) {
    this.tarGridBSpecialApi = params.api;
    this.tarGridBSpecialApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptB == 1) {
      this.tarGridSpecialApi.forEachNode(x => {
        this.tarGridBSpecialApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
          }]
        })
        this.tarGridBSpecialApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptBSCMSpecialRows, node.data);
        })
      })
    }
    this.tarPinnedBottomData = this.createDataTarSpecial(this.totalTar(this.tarGridBSpecialApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }

  loadOptCGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.SubComponent;
        return data === 'Total Labor Costs' || data === 'Total Overhead Costs' || data === 'Total Special Direct Costs' || data === 'Total Direct Costs SCM';
      }
    }
    this.optCColumnDefs = [
      {
        headerName: "SubComponent",
        field: "SubComponent",
        tooltipField: "SubComponent",
        hide: true
      },
      {
        headerName: "Quantity",
        field: "TargetQuantity",
        tooltipField: "Quantity",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Quantity",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Weight",
        field: "TargetWeight",
        tooltipField: "Weight",
        width: 60,

        headerTooltip: "Weight",
        editable: function (params) {
          if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent == 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
            return false;

          }
          else {
            return params.data.IsEditable;
          }
        },
        singleClickEdit: true,

      },
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "TargetAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.TargetAbsolute)
                  })
                  params.data.TargetAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.TargetAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.TargetPercentOfMEK === "" || params.node.data.TargetPercentOfMEK === null || params.node.data.TargetPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else 
              if (params.node.data.TargetAbsolute === "" || params.node.data.TargetAbsolute === null || params.node.data.TargetAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,

            cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.TargetTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
          
            singleClickEdit: true
          },
          {
            headerName: "Delta TC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaTCVsRef",
            tooltipField: "Delta TC vs. Reference",
            headerTooltip: "Delta TC vs. Reference",
            width: 66,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent != 'Total Labor Costs' && params.node.data.SubComponent != 'Total Overhead Costs' && params.node.data.SubComponent != 'Total Special Direct Costs' && params.node.data.SubComponent != 'Total Direct Costs SCM') {

                if (params.node.data.TargetTotal == null || Number(params.node.data.TargetTotal) == 0) {
                  return 0
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetTotal) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
                }

                params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? 0 : params.node.data.DeltaTCVsRef
                return params.node.data.DeltaTCVsRef;
              } else if (params.node.data.SubComponent == 'Total Direct Costs SCM') {
                return null
              } else {
                return 0
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
           
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ForecastAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ForecastAbsolute)
                  })
                  params.data.ForecastAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ForecastAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.ForecastPercentOfMEK === "" || params.node.data.ForecastPercentOfMEK === null || params.node.data.ForecastPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }
            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 88,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else 
              if (params.node.data.ForecastAbsolute === "" || params.node.data.ForecastAbsolute === null || params.node.data.ForecastAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },



          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ForecastTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter:  (params) => {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs')
              // if (params.node.data.SubComponent != 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs')
              {
                let total = 0;
                params.api.forEachNode((node) => {
                  total = Number(node.data.ForecastTotal) + total;
                })
                params.data.ForecastTotal = total;
                return total;
              } if (params.node.data.SubComponent === 'Total Direct Costs SCM') {
                return params.data.ForecastTotal;
              } else {
                let total = 0;

                if (params.api != null) {
                  let permek = 0;

                  if (params.node.data.ForecastPercentOfMEK != null) {
                    permek = Number(params.data.ForecastPercentOfMEK) / 100;
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * (this.EQTargetCostOptCLabourMek ?? 0);
                    //abs = Number(node.data.Absolute) + abs

                  }
                  else {
                    total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ForecastAbsolute);
                  }
                }
                params.data.ForecastTotal = total;
                return total;

              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
          
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          },
          {
            headerName: "Delta FC vs. Reference",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaFCVsRef",
            tooltipField: "Delta FC vs. Reference",
            headerTooltip: "Delta FC vs. Reference",
            width: 88,
            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ForecastDeltaFCVsRef, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
           
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,

          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        headerClass: "left-border",
        children: [
          {
            headerName: "abs",
            headerClass: "right-border left-border",
            cellClass: "right-border left-border",
            field: "ActualAbsolute",
            tooltipField: "abs",
            headerTooltip: "abs",
            width: 66,

            tooltipValueGetter: CurrencyCellRenderer, valueGetter: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {

                    total += Number(node.data.ActualAbsolute)
                  })
                  params.data.ActualAbsolute = total;
                  return total;
                }
              } else {
                return (params.data.ActualAbsolute);
              }
            }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
              if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs' || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]
              ) {
                return false;
              }
              else if (params.node.data.ActualPercentOfMEK === "" || params.node.data.ActualPercentOfMEK === null || params.node.data.ActualPercentOfMEK === 0) {
                return params.data.IsEditable;
              }
              else {
                return false;
              }

            }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "% of MEK",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualPercentOfMEK",
            tooltipField: "% of MEK",
            headerTooltip: "% of MEK",
            width: 66,

            editable: function (params) {
              if (params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Labour] || params.node.data.SubComponent === EnumSCMComponent[EnumSCMComponent.Overhead]) {
                return false;
              }
              else
              if (params.node.data.ActualAbsolute === "" || params.node.data.ActualAbsolute === null || params.node.data.ActualAbsolute === 0) {

                return params.data.IsEditable;
              }
              else {
                return false;
              }
            },

          },
          {
            headerName: "Total",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "ActualTotal",
            tooltipField: "Total",
            headerTooltip: "Total",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: this.ActualTotal, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
           
            singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. ACT",
            cellClass: (params) => {
              if (params.value >= 0)
                return "colorTotal positive-num-color-scm"
              else
                return "colorTotal negative-num-color-scm"
            },
            field: "DeltaTCVsACT",
            tooltipField: "Delta TC vs. ACT",
            headerTooltip: "Delta TC vs. ACT",
            width: 66,


            tooltipValueGetter: CurrencyCellRenderer, valueGetter: ActualDeltaTCVsACT, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
            editable: false,
            
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
          }
        ]
      }

    ]
    // this.tarDomLayout = 'autoHeight';
    this.optCDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      // autoHeight: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.frameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    this.optCGridLabourData = [];
  }

  bindOptionCDatatoGrid(i, target) {
    target.push({
      SCMIdTargetCost: i.OptionC?.TargetCost?.SCMId ?? 0,
      SCMIdForecast: i.OptionC?.Forecast?.SCMId ?? 0,
      SCMIdActual: i.OptionC?.Actual?.SCMId ?? 0,
      ConfigurationId: i.ConfigurationId > 0 ? i.ConfigurationId : this.configurationId,
      SubComponent: i.SubComponent,
      ReferenceCost: i.ReferenceScenario.Total,
      TargetQuantity: i.OptionC?.TargetCost?.Quantity ?? null,
      ForecastQuantity: i.OptionC?.Forecast?.Quantity ?? null,
      ActualsQuantity: i.OptionC?.Actual?.Quantity ?? null,
      TargetWeight: i.OptionC?.TargetCost?.Weight ?? null,
      ForecastWeight: i.OptionC?.Forecast?.Weight ?? null,
      ActualsWeight: i.OptionC?.Actual?.Weight ?? null,
      TargetAbsolute: i.OptionC?.TargetCost?.Absolute ?? null,
      ForecastAbsolute: i.OptionC?.Forecast?.Absolute ?? null,
      ActualAbsolute: i.OptionC?.Actual?.Absolute ?? null,
      TargetPercentOfMEK: i.OptionC?.TargetCost?.PercentOfMEK ?? null,
      ForecastPercentOfMEK: i.OptionC?.Forecast?.PercentOfMEK ?? null,
      ActualPercentOfMEK: i.OptionC?.Actual?.PercentOfMEK ?? null,
      TargetTotal: i.OptionC?.TargetCost?.Total ?? null,
      ForecastTotal: i.OptionC?.Forecast?.Total ?? null,
      ActualTotal: i.OptionC?.Actual?.Total ?? null,
      DeltaTCVsRef: i.OptionC?.TargetCost?.DeltaTCVsRef ?? null,
      DeltaFCVsRef: i.OptionC?.Forecast?.DeltaFCVsRef ?? null,
      DeltaTCVsACT: i.OptionC?.Actual?.DeltaTCVsACT ?? null,
      IsActive: i.IsActive,
      CreatedBy: i.CreatedBy,
      CreatedOn: i.CreatedOn,
      ModifiedBy: i.ModifiedBy,
      ModifiedOn: i.ModifiedOn,
      IsEditable: this.isEditable
    })
  }

  onTarCGridReady(params: any) {
    this.tarGridCApi = params.api;
    this.tarGridCApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptC == 1) {
      this.tarGridApi.forEachNode(x => {
        this.tarGridCApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
          }]
        })
        this.tarGridCApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptCSCMLabourRows, node.data);
        })
      });
    }
    this.tarPinnedBottomData = this.createDataTar(this.totalTar(this.tarGridCApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }
  onTarCOverheadReady(params: any) {
    this.tarGridCOverheadApi = params.api;
    this.tarGridCOverheadApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptC == 1) {
      this.tarGridOverheadApi.forEachNode(x => {
        this.tarGridCOverheadApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
          }]
        })
        this.tarGridCOverheadApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptCSCMOverheadRows, node.data);
        })
      })
    }
    this.tarPinnedBottomData = this.createDataRefoverhead(this.totalTar(this.tarGridCOverheadApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }
  onTarCSpecialReady(params: any) {
    this.tarGridCSpecialApi = params.api;
    this.tarGridCSpecialApi.setGroupHeaderHeight(30);
    if (this.addnewRowOptC == 1) {
      this.tarGridSpecialApi.forEachNode(x => {
        this.tarGridCSpecialApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            ConfigurationId: x.data.ConfigurationId,
            SubComponent: x.data.SubComponent,
            ComponentId: x.data.ComponentId,
            ReferenceCost: x.data.ReferenceCost,
            TargetQuantity: x.data.TargetQuantity ?? null,
            ForecastQuantity: x.data.ForecastQuantity ?? null,
            ActualsQuantity: x.data.ActualsQuantity ?? null,
            TargetWeight: x.data.TargetWeight ?? null,
            ForecastWeight: x.data.ForecastWeight ?? null,
            ActualsWeight: x.data.ActualsWeight ?? null,
            TargetAbsolute: x.data.TargetAbsolute ?? null,
            ForecastAbsolute: x.data.ForecastAbsolute ?? null,
            ActualAbsolute: x.data.ActualAbsolute ?? null,
            TargetPercentOfMEK: x.data.TargetPercentOfMEK ?? null,
            ForecastPercentOfMEK: x.data.ForecastPercentOfMEK ?? null,
            ActualPercentOfMEK: x.data.ActualPercentOfMEK ?? null,
            TargetTotal: x.data.TargetTotal ?? null,
            ForecastTotal: x.data.ForecastTotal ?? null,
            ActualTotal: x.data.ActualTotal ?? null,
            DeltaTCVsRef: x.data.DeltaTCVsRef ?? null,
            DeltaFCVsRef: x.data.DeltaFCVsRef ?? null,
            DeltaTCVsACT: x.data.DeltaTCVsACT ?? null,
            IsActive: x.data.IsActive,
            CreatedBy: x.data.CreatedBy,
            CreatedOn: x.data.CreatedOn,
            ModifiedBy: x.data.ModifiedBy,
            ModifiedOn: x.data.ModifiedOn,
            IsEditable: this.isEditable,
          }]
        })
        this.tarGridCSpecialApi.forEachNode(node => {
          this.addOrUpdateRow(this.modifiedOptCSCMSpecialRows, node.data);
        })
      })
    }
    this.tarPinnedBottomData = this.createDataTarSpecial(this.totalTar(this.tarGridCSpecialApi), this.isEditable);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);

  }

  addNewRow() {
    if (this.optBVisible == true) {
      this.optCVisible = true;
      this.addnewRowOptC = 1;
    }
    if (this.optAVisible == true) {
      this.optBVisible = true;
      this.addnewRowOptB = 1;
    }
    if (this.optBVisible == false) {
      this.optAVisible = true;
      this.addnewRowOptA = 1;
    }
    this.hasUnsavedChanges = true;
  }

  generateTarPinnedBottomData() {

  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(["/tc-manageProject"]);
  }
  onGridRefChanged(params) {

    this.refLabourGridApi = params.api;
    this.refLabourGridApi.sizeColumnsToFit();
  }
  onGridTarChanged(params) {

    this.tarGridApi = params.api;
    this.tarGridApi.sizeColumnsToFit();

  }
  onGridTarAChanged(params) {

    this.tarGridAApi = params.api;
    this.tarGridAApi.sizeColumnsToFit();

  }
  onGridTarBChanged(params) {

    this.tarGridBApi = params.api;
    this.tarGridBApi.sizeColumnsToFit();

  }
  onGridTarCChanged(params) {

    this.tarGridCApi = params.api;
    this.tarGridCApi.sizeColumnsToFit();

  }
  onGridRefChanged1(params) {

    this.refGridOverheadApi = params.api;
    this.refGridOverheadApi.sizeColumnsToFit();


  }
  onGridRefChanged2(params) {

    this.refGridSpecialApi = params.api;
    this.refGridSpecialApi.sizeColumnsToFit();


  }
  onGridTarChanged2(params) {

    this.tarGridSpecialApi = params.api;
    this.tarGridSpecialApi.sizeColumnsToFit();

  }
  onGridTarAChanged2(params) {

    this.tarGridASpecialApi = params.api;
    this.tarGridASpecialApi.sizeColumnsToFit();

  }
  onGridTarBChanged2(params) {

    this.tarGridBSpecialApi = params.api;
    this.tarGridBSpecialApi.sizeColumnsToFit();

  }
  onGridTarCChanged2(params) {

    this.tarGridCSpecialApi = params.api;
    this.tarGridCSpecialApi.sizeColumnsToFit();

  }
  onGridTarChanged1(params) {

    this.tarGridOverheadApi = params.api;
    this.tarGridOverheadApi.sizeColumnsToFit();
  }
  onGridTarAChanged1(params) {
    this.tarGridAOverheadApi = params.api;
    this.tarGridAOverheadApi.sizeColumnsToFit();
  }
  onGridTarBChanged1(params) {

    this.tarGridBOverheadApi = params.api;
    this.tarGridBOverheadApi.sizeColumnsToFit();

  }
  onGridTarCChanged1(params) {

    this.tarGridCOverheadApi = params.api;
    this.tarGridCOverheadApi.sizeColumnsToFit();

  }
  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResized(params) {
    params.api.resetRowHeights();
  }
  onColumnVisible(params) {
    params.api.resetRowHeights();
  }


  generateRefPinnedBottomData() {

  }
  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }

  onClickNext() {
    this.menuService.onClickNextAndBack("next");
  }

  submit(event?: any) {
    if (!this.hasUnsavedChanges && event != undefined) {
      this.router.navigate(['/tc-manageProject'])
      return true
    }
    else {
      let errorMessage = messages.cellEmptyErrorMesssage;
      let error = [];
      let firstRowLabour = this.refLabourGridApi.getDisplayedRowAtIndex(0);
      if (firstRowLabour != undefined) {

        let data = firstRowLabour.data;

        if (data.SubComponent === "Add Component Here" || data.SubComponent == null || data.SubComponent === "" || data.SubComponent === undefined) {
          error.push({ "Direct Costs SCM": errorMessage })
        }

        let SubComponent = data.SubComponent.toLowerCase();
        if ((SubComponent.trim() === EnumSCMComponent[EnumSCMComponent.Labour].toLowerCase().trim() && this.refLabourGridApi.rowModel.rowsToDisplay.length > 1 && data.ComponentId < 0)) {
          error.push({ "Please change name SubComponent": errorMessage })

        }
      }
      let firstRowOverhead = this.refGridOverheadApi.getDisplayedRowAtIndex(0);
      if (firstRowOverhead != undefined) {

        let data = firstRowOverhead.data;

        if (data.SubComponent === "Add Component Here" || data.SubComponent == null || data.SubComponent === "" || data.SubComponent === undefined) {
          error.push({ "Direct Costs SCM": errorMessage })
        }
        let SubComponent = data.SubComponent.toLowerCase();
        if ((SubComponent.trim() === EnumSCMComponent[EnumSCMComponent.Overhead].toLowerCase().trim() && this.refGridOverheadApi.rowModel.rowsToDisplay.length > 1 && data.ComponentId < 0) ) {
          error.push({ "Please change name SubComponent": errorMessage })

        }
      }
      let firstRowSpecial = this.refGridSpecialApi.getDisplayedRowAtIndex(0);
      if (firstRowSpecial != undefined) {

        let data = firstRowSpecial.data;

        if (data.SubComponent === "Add Component Here" || data.SubComponent == null || data.SubComponent === "" || data.SubComponent === undefined) {
          error.push({ "Direct Costs SCM": errorMessage })
        }
      }


      if (error.length != 0) {
        this.openAlertDialog(error);
        return false;
      }
      else {
        let response = null;
        this.submitted = true;

        let sendData = this.sendData();
        response = this.ScmtargetcostService.InsertupdateSCMTargetCostData(sendData).toPromise().
          then((res) => {
            this.submitResponse = res;
            let successCode = this.submitResponse.StatusCode;
            let errorCode = this.submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = this.submitResponse.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                this.hasUnsavedChanges = false;
                let dataSaveMessage = serverMessage.dataSaveMessage;
                this.toast.notify(dataSaveMessage, "success");
                if (event != undefined) {
                  //  this.localStorageService.remove("configuration");
                  this.hasUnsavedChanges = false;
                  this.router.navigate(['/tc-manageProject'])
                }
                return true;
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
                this.submitted = false;
                return false;
              }
            }
            else {
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          },
            (error) => {
              this.submitted = false;
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
              return false;
            })

        return response;
      }
    }

  }
  sendData() {
    let refObj: TCSCMLCTargetCost[] = [];
    let allData = [...this.LabourData, ...this.OverheadData, ...this.SpecialDirectCostData]

    allData.forEach(cs => {
      cs.Component = cs.Component;
      cs.ConfigurationId = this.configurationId,
        cs.IsActive = true;
      cs.ReferenceScenario.ScenarioTitle = this.refTitle;
      cs.TargetScenario.ScenarioTitle = this.tarTitle;
      if (cs.OptionA != null) cs.OptionA.ScenarioTitle = this.optATitle;
      if (cs.OptionB != null) cs.OptionB.ScenarioTitle = this.optBTitle;
      if (cs.OptionC != null) cs.OptionC.ScenarioTitle = this.optCTitle;

      if (cs.SCMId == 0) {
        cs.CreatedBy = this.userInOrg.UserId;
        cs.CreatedOn = new Date();
      } else {
        cs.ModifiedBy = this.userInOrg.UserId;
        cs.ModifiedOn = new Date();
      }

      let referenceModifiedRows;
      let tarModifiedRows;
      let optionAModifiedRows;
      let optionBModifiedRows;
      let optionCModifiedRows;

      if (cs.Component === EnumSCMComponent[EnumSCMComponent.Labour]) {
        referenceModifiedRows = this.modifiedRefSCMLabourRows;
        tarModifiedRows = this.modifiedTarSCMLabourRows;
        optionAModifiedRows = this.modifiedOptASCMLabourRows;
        optionBModifiedRows = this.modifiedOptBSCMLabourRows;
        optionCModifiedRows = this.modifiedOptCSCMLabourRows;
      }
      else if (cs.Component === EnumSCMComponent[EnumSCMComponent.Overhead]) {
        referenceModifiedRows = this.modifiedRefSCMOverheadRows;
        tarModifiedRows = this.modifiedTarSCMOverheadRows;
        optionAModifiedRows = this.modifiedOptASCMOverheadRows;
        optionBModifiedRows = this.modifiedOptBSCMOverheadRows;
        optionCModifiedRows = this.modifiedOptCSCMOverheadRows;
      }
      else if (cs.Component === EnumSCMComponent[EnumSCMComponent.SpecialDirectCost]) {
        referenceModifiedRows = this.modifiedRefSCMSpecialRows;
        tarModifiedRows = this.modifiedTarSCMSpecialRows;
        optionAModifiedRows = this.modifiedOptASCMSpecialRows;
        optionBModifiedRows = this.modifiedOptBSCMSpecialRows;
        optionCModifiedRows = this.modifiedOptCSCMSpecialRows;
      }
      else {
        referenceModifiedRows = [];
        tarModifiedRows = [];
        optionAModifiedRows = [];
        optionBModifiedRows = [];
        optionCModifiedRows = [];
      }


      let referenceRow = referenceModifiedRows.find(x => (x.SCMId == cs.SCMId && cs.SCMId > 0)  || (cs.SCMId == 0 && cs.ComponentId != undefined && x.ComponentId == cs.ComponentId));
      if (referenceRow) {
        cs.SubComponent = referenceRow.SubComponent;
        cs.ReferenceScenario.Quantity = Number(referenceRow.Quantity);
        cs.ReferenceScenario.Weight = Number(referenceRow.Weight);
        cs.ReferenceScenario.Absolute = Number(referenceRow.Absolute);
        cs.ReferenceScenario.PercentOfMEK = referenceRow.PercentOfMEK > 0 ? Number(referenceRow.PercentOfMEK) : null;
        cs.ReferenceScenario.Total = Number(referenceRow.Total);
      }

      let targetRow = tarModifiedRows.find(x => (x.SCMIdTargetCost == cs.TargetScenario.TargetCost.SCMId && cs.TargetScenario.TargetCost.SCMId > 0) || (cs.TargetScenario.TargetCost.SCMId == 0 && cs.ComponentId != undefined && x.ComponentId == cs.ComponentId));
      if (targetRow) {
        cs.TargetScenario.TargetCost = {
          SCMId: targetRow.SCMIdTargetCost,
          Absolute: Number(targetRow.TargetAbsolute),
          Quantity: Number(targetRow.TargetQuantity),
          Weight: Number(targetRow.TargetWeight),
          PercentOfMEK: targetRow.TargetPercentOfMEK > 0 ? Number(targetRow.TargetPercentOfMEK) : null,
          Total: Number(targetRow.TargetTotal),
          DeltaTCVsRef: Number(targetRow.DeltaTCVsRef)
        }

        cs.TargetScenario.Forecast = {
          SCMId: targetRow.SCMIdForecast,
          Absolute: Number(targetRow.ForecastAbsolute),
          PercentOfMEK: targetRow.ForecastPercentOfMEK > 0 ? Number(targetRow.ForecastPercentOfMEK) : null,
          Total: Number(targetRow.ForecastTotal),
          DeltaFCVsRef: Number(targetRow.DeltaFCVsRef)
        }

        cs.TargetScenario.Actual = {
          SCMId: targetRow.SCMIdActual,
          Absolute: Number(targetRow.ActualAbsolute),
          PercentOfMEK: targetRow.ActualPercentOfMEK > 0 ? Number(targetRow.ActualPercentOfMEK) : null,
          Total: Number(targetRow.ActualTotal),
          DeltaTCVsACT: Number(targetRow.DeltaTCVsACT)
        }
      } else {
        if (cs.TargetScenario.TargetCost == null) {
          cs.TargetScenario.TargetCost = {
            SCMId: 0,
            Absolute: 0,
            Quantity: 0,
            Weight: 0,
            PercentOfMEK: null,
            Total: 0,
            DeltaTCVsRef: 0
          }
        }
        if (cs.TargetScenario.Forecast == null) {
          cs.TargetScenario.Forecast = {
            SCMId: 0,
            Absolute: 0,
            PercentOfMEK: null,
            Total: 0,
            DeltaFCVsRef: 0
          }
        }
        if (cs.TargetScenario.Actual == null) {
          cs.TargetScenario.Actual = {
            SCMId: 0,
            Absolute: 0,
            PercentOfMEK: null,
            Total: 0,
            DeltaTCVsACT: 0
          }
        }
      }

      if (this.optAVisible && cs.OptionA != null) {
        let optARow = optionAModifiedRows.find(x => (x.SCMIdTargetCost == cs.OptionA.TargetCost.SCMId && cs.OptionA.TargetCost.SCMId > 0) || (cs.OptionA.TargetCost.SCMId == 0 && cs.ComponentId != undefined && x.ComponentId == cs.ComponentId));
        if (optARow) {
          let TargetCost = {
            SCMId: optARow.SCMIdTargetCost,
            Absolute: Number(optARow.TargetAbsolute),
            Quantity: Number(optARow.TargetQuantity),
            Weight: Number(optARow.TargetWeight),
            PercentOfMEK: optARow.TargetPercentOfMEK > 0 ? Number(optARow.TargetPercentOfMEK) : null,
            Total: Number(optARow.TargetTotal),
            DeltaTCVsRef: Number(optARow.DeltaTCVsRef)
          }

          let Forecast = {
            SCMId: optARow.SCMIdForecast,
            Absolute: Number(optARow.ForecastAbsolute),
            PercentOfMEK: optARow.ForecastPercentOfMEK > 0 ? Number(optARow.ForecastPercentOfMEK) : null,
            Total: Number(optARow.ForecastTotal),
            DeltaFCVsRef: Number(optARow.DeltaFCVsRef)
          }

          let Actual = {
            SCMId: optARow.SCMIdActual,
            Absolute: Number(optARow.ActualAbsolute),
            PercentOfMEK: optARow.ActualPercentOfMEK > 0 ? Number(optARow.ActualPercentOfMEK) : null,
            Total: Number(optARow.ActualTotal),
            DeltaTCVsACT: Number(optARow.DeltaTCVsACT)
          }
          cs.OptionA = { TargetCost: TargetCost, Forecast: Forecast, Actual: Actual, ScenarioTitle: this.optATitle };
        }
      }
      else {
        let optARow = optionAModifiedRows.find(x => x.SubComponent == cs.SubComponent);
        if (optARow) {
          cs.OptionA = SCMLCScenario;
          let optATargetCost: SCMLCTargetCost;
          let optAForecastCost: SCMLCForecast;
          let optAActual: SCMLCActual;

          optATargetCost = {
            SCMId: optARow.SCMIdTargetCost,
            Absolute: optARow.TargetAbsolute,
            Quantity: optARow.TargetQuantity,
            Weight: optARow.TargetWeight,
            PercentOfMEK: optARow.TargetPercentOfMEK > 0 ? optARow.TargetPercentOfMEK : null,
            Total: optARow.TargetTotal,
            DeltaTCVsRef: optARow.DeltaTCVsRef
          }

          optAForecastCost = {
            SCMId: optARow.SCMIdForecast,
            Absolute: optARow.ForecastAbsolute,
            PercentOfMEK: optARow.ForecastPercentOfMEK > 0 ? optARow.ForecastPercentOfMEK : null,
            Total: optARow.ForecastTotal,
            DeltaFCVsRef: optARow.DeltaFCVsRef
          }

          optAActual = {
            SCMId: optARow.SCMIdActual,
            Absolute: optARow.ActualAbsolute,
            PercentOfMEK: optARow.ActualPercentOfMEK > 0 ? optARow.ActualPercentOfMEK : null,
            Total: optARow.ActualTotal,
            DeltaTCVsACT: optARow.DeltaTCVsACT
          }
          cs.OptionA = { TargetCost: optATargetCost, Forecast: optAForecastCost, Actual: optAActual, ScenarioTitle: this.optATitle };
        }
      }

      if (this.optBVisible && cs.OptionB != null) {
        let optBRow = optionBModifiedRows.find(x => (x.SCMIdTargetCost == cs.OptionB.TargetCost.SCMId && cs.OptionB.TargetCost.SCMId > 0) || (cs.OptionB.TargetCost.SCMId == 0 && cs.ComponentId != undefined && x.ComponentId == cs.ComponentId));
        if (optBRow) {
          let TargetCost = {
            SCMId: optBRow.SCMIdTargetCost,
            Absolute: Number(optBRow.TargetAbsolute),
            Quantity: Number(optBRow.TargetQuantity),
            Weight: Number(optBRow.TargetWeight),
            PercentOfMEK: optBRow.TargetPercentOfMEK > 0 ? Number(optBRow.TargetPercentOfMEK) : optBRow.TargetPercentOfMEK,
            Total: Number(optBRow.TargetTotal),
            DeltaTCVsRef: Number(optBRow.DeltaTCVsRef)
          }

          let Forecast = {
            SCMId: optBRow.SCMIdForecast,
            Absolute: Number(optBRow.ForecastAbsolute),
            PercentOfMEK: optBRow.ForecastPercentOfMEK > 0 ? Number(optBRow.ForecastPercentOfMEK) : null,
            Total: Number(optBRow.ForecastTotal),
            DeltaFCVsRef: Number(optBRow.DeltaFCVsRef)
          }

          let Actual = {
            SCMId: optBRow.SCMIdActual,
            Absolute: Number(optBRow.ActualAbsolute),
            PercentOfMEK: optBRow.ActualPercentOfMEK > 0 ? Number(optBRow.ActualPercentOfMEK) : null,
            Total: Number(optBRow.ActualTotal),
            DeltaTCVsACT: Number(optBRow.DeltaTCVsACT)
          }
          cs.OptionB = { TargetCost: TargetCost, Forecast: Forecast, Actual: Actual, ScenarioTitle: this.optBTitle };
        }
      } 
      else {
        let optBRow = optionBModifiedRows.find(tl => tl.SubComponent === cs.SubComponent);
        if (optBRow) {
          cs.OptionB = SCMLCScenario;
          let optBTargetCost: SCMLCTargetCost;
          let optBForecastCost: SCMLCForecast;
          let optBActual: SCMLCActual;

          optBTargetCost = {
            SCMId: optBRow.SCMIdTargetCost,
            Absolute: optBRow.TargetAbsolute,
            Quantity: optBRow.TargetQuantity,
            Weight: optBRow.TargetWeight,
            PercentOfMEK: optBRow.TargetPercentOfMEK > 0 ? optBRow.TargetPercentOfMEK : null,
            Total: optBRow.TargetTotal,
            DeltaTCVsRef: optBRow.DeltaTCVsRef
          }

          optBForecastCost = {
            SCMId: optBRow.SCMIdForecast,
            Absolute: optBRow.ForecastAbsolute,
            PercentOfMEK: optBRow.ForecastPercentOfMEK > 0 ? optBRow.ForecastPercentOfMEK : null,
            Total: optBRow.ForecastTotal,
            DeltaFCVsRef: optBRow.DeltaFCVsRef
          }

          optBActual = {
            SCMId: optBRow.SCMIdActual,
            Absolute: optBRow.ActualAbsolute,
            PercentOfMEK: optBRow.ActualPercentOfMEK > 0 ? optBRow.ActualPercentOfMEK : null,
            Total: optBRow.ActualTotal,
            DeltaTCVsACT: optBRow.DeltaTCVsACT
          }
          cs.OptionB = { TargetCost: optBTargetCost, Forecast: optBForecastCost, Actual: optBActual, ScenarioTitle: this.optBTitle }
        }
      }

      if (this.optCVisible && cs.OptionC != null) {
        let optCRow = optionCModifiedRows.find(x => (x.SCMIdTargetCost == cs.OptionC.TargetCost.SCMId  && cs.OptionC.TargetCost.SCMId > 0) || (cs.OptionC.TargetCost.SCMId == 0 && cs.ComponentId != undefined && x.ComponentId == cs.ComponentId));
        if (optCRow) {
          let TargetCost = {
            SCMId: optCRow.SCMIdTargetCost,
            Absolute: Number(optCRow.TargetAbsolute),
            Quantity: Number(optCRow.TargetQuantity),
            Weight: Number(optCRow.TargetWeight),
            PercentOfMEK: optCRow.TargetPercentOfMEK > 0 ? Number(optCRow.TargetPercentOfMEK) : null,
            Total: Number(optCRow.TargetTotal),
            DeltaTCVsRef: Number(optCRow.DeltaTCVsRef)
          }

          let Forecast = {
            SCMId: optCRow.SCMIdForecast,
            Absolute: Number(optCRow.ForecastAbsolute),
            PercentOfMEK: optCRow.ForecastPercentOfMEK > 0 ? Number(optCRow.ForecastPercentOfMEK) : null,
            Total: Number(optCRow.ForecastTotal),
            DeltaFCVsRef: Number(optCRow.DeltaFCVsRef)
          }

          let Actual = {
            SCMId: optCRow.SCMIdActual,
            Absolute: Number(optCRow.ActualAbsolute),
            PercentOfMEK: optCRow.ActualPercentOfMEK > 0 ? Number(optCRow.ActualPercentOfMEK) : null,
            Total: Number(optCRow.ActualTotal),
            DeltaTCVsACT: Number(optCRow.DeltaTCVsACT)
          }
          cs.OptionC = { TargetCost: TargetCost, Forecast: Forecast, Actual: Actual, ScenarioTitle: this.optCTitle };
        }
       } else {
        let optCRow = optionCModifiedRows.find(tl => tl.SubComponent === cs.SubComponent);
        if (optCRow) {
          cs.OptionC = SCMLCScenario;
          let optCTargetCost: SCMLCTargetCost;
          let optCForecastCost: SCMLCForecast;
          let optCActual: SCMLCActual;

          optCTargetCost = {
            SCMId: optCRow.SCMIdTargetCost,
            Absolute: optCRow.TargetAbsolute,
            Quantity: optCRow.TargetQuantity,
            Weight: optCRow.TargetWeight,
            PercentOfMEK: optCRow.TargetPercentOfMEK > 0 ? optCRow.TargetPercentOfMEK : null,
            Total: optCRow.TargetTotal,
            DeltaTCVsRef: optCRow.DeltaTCVsRef
          }

          optCForecastCost = {
            SCMId: optCRow.SCMIdForecast,
            Absolute: optCRow.ForecastAbsolute,
            PercentOfMEK: optCRow.ForecastPercentOfMEK > 0 ? optCRow.ForecastPercentOfMEK : null,
            Total: optCRow.ForecastTotal,
            DeltaFCVsRef: optCRow.DeltaFCVsRef
          }

          optCActual = {
            SCMId: optCRow.SCMIdActual,
            Absolute: optCRow.ActualAbsolute,
            PercentOfMEK: optCRow.ActualPercentOfMEK > 0 ? optCRow.ActualPercentOfMEK : null,
            Total: optCRow.ActualTotal,
            DeltaTCVsACT: optCRow.DeltaTCVsACT
          }
          cs.OptionC = { TargetCost: optCTargetCost, Forecast: optCForecastCost, Actual: optCActual, ScenarioTitle: this.optCTitle }
          }
        }
      refObj.push(cs);
    })

    this.modifiedRefSCMLabourRows.forEach(modifiedRow => {
      let isExisting = allData.some(cs => cs.SubComponent === modifiedRow.SubComponent && cs.Component === EnumSCMComponent[EnumSCMComponent.Labour]);
      if (!isExisting) {

        let targetRow = this.modifiedTarSCMLabourRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let targetScenario: SCMLCScenario;
        let targetCost: SCMLCTargetCost;
        let forecastCost: SCMLCForecast;
        let actual: SCMLCActual;
        if (targetRow) {
          targetCost = {
            SCMId: targetRow.SCMIdTargetCost,
            Absolute: targetRow.TargetAbsolute,
            Quantity: targetRow.TargetQuantity,
            Weight: targetRow.TargetWeight,
            PercentOfMEK: targetRow.TargetPercentOfMEK > 0 ? targetRow.TargetPercentOfMEK : null,
            Total: targetRow.TargetTotal,
            DeltaTCVsRef: targetRow.DeltaTCVsRef
          }

          forecastCost = {
            SCMId: targetRow.SCMIdForecast,
            Absolute: targetRow.ForecastAbsolute,
            PercentOfMEK: targetRow.ForecastPercentOfMEK > 0 ? targetRow.ForecastPercentOfMEK : null,
            Total: targetRow.ForecastTotal,
            DeltaFCVsRef: targetRow.DeltaFCVsRef
          }

          actual = {
            SCMId: targetRow.SCMIdActual,
            Absolute: targetRow.ActualAbsolute,
            PercentOfMEK: targetRow.ActualPercentOfMEK > 0 ? targetRow.ActualPercentOfMEK : null,
            Total: targetRow.ActualTotal,
            DeltaTCVsACT: targetRow.DeltaTCVsACT
          }
          targetScenario = { TargetCost: targetCost, Forecast: forecastCost, Actual: actual, ScenarioTitle: this.targetTitle }
        }
        else {
          targetScenario = null;
        }

        let optARow = this.modifiedOptASCMLabourRows.find(x => x.SubComponent == modifiedRow.SubComponent);
        let optAScenario: SCMLCScenario;
        let optATargetCost: SCMLCTargetCost;
        let optAForecastCost: SCMLCForecast;
        let optAActual: SCMLCActual;
        if (optARow) {
          optATargetCost = {
            SCMId: optARow.SCMIdTargetCost,
            Absolute: optARow.TargetAbsolute,
            Quantity: optARow.TargetQuantity,
            Weight: optARow.TargetWeight,
            PercentOfMEK: optARow.TargetPercentOfMEK > 0 ? optARow.TargetPercentOfMEK : null,
            Total: optARow.TargetTotal,
            DeltaTCVsRef: optARow.DeltaTCVsRef
          }

          optAForecastCost = {
            SCMId: optARow.SCMIdForecast,
            Absolute: optARow.ForecastAbsolute,
            PercentOfMEK: optARow.ForecastPercentOfMEK > 0 ? optARow.ForecastPercentOfMEK : null,
            Total: optARow.ForecastTotal,
            DeltaFCVsRef: optARow.DeltaFCVsRef
          }

          optAActual = {
            SCMId: optARow.SCMIdActual,
            Absolute: optARow.ActualAbsolute,
            PercentOfMEK: optARow.ActualPercentOfMEK > 0 ? optARow.ActualPercentOfMEK : null,
            Total: optARow.ActualTotal,
            DeltaTCVsACT: optARow.DeltaTCVsACT
          }
          optAScenario = { TargetCost: optATargetCost, Forecast: optAForecastCost, Actual: optAActual, ScenarioTitle: this.optATitle }
        }
        else {
          optAScenario = null;
        }

        let optBRow = this.modifiedOptBSCMLabourRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let optBScenario: SCMLCScenario;
        let optBTargetCost: SCMLCTargetCost;
        let optBForecastCost: SCMLCForecast;
        let optBActual: SCMLCActual;
        if (optBRow) {
          optBTargetCost = {
            SCMId: optBRow.SCMIdTargetCost,
            Absolute: optBRow.TargetAbsolute,
            Quantity: optBRow.TargetQuantity,
            Weight: optBRow.TargetWeight,
            PercentOfMEK: optBRow.TargetPercentOfMEK > 0 ? optBRow.TargetPercentOfMEK : null,
            Total: optBRow.TargetTotal,
            DeltaTCVsRef: optBRow.DeltaTCVsRef
          }

          optBForecastCost = {
            SCMId: optBRow.SCMIdForecast,
            Absolute: optBRow.ForecastAbsolute,
            PercentOfMEK: optBRow.ForecastPercentOfMEK > 0 ? optBRow.ForecastPercentOfMEK : null,
            Total: optBRow.ForecastTotal,
            DeltaFCVsRef: optBRow.DeltaFCVsRef
          }

          optBActual = {
            SCMId: optBRow.SCMIdActual,
            Absolute: optBRow.ActualAbsolute,
            PercentOfMEK: optBRow.ActualPercentOfMEK > 0 ? optBRow.ActualPercentOfMEK : null,
            Total: optBRow.ActualTotal,
            DeltaTCVsACT: optBRow.DeltaTCVsACT
          }
          optBScenario = { TargetCost: optBTargetCost, Forecast: optBForecastCost, Actual: optBActual, ScenarioTitle: this.optBTitle }
        }
        else {
          optBScenario = null;
        }

        let optCRow = this.modifiedOptCSCMLabourRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let optCScenario: SCMLCScenario;
        let optCTargetCost: SCMLCTargetCost;
        let optCForecastCost: SCMLCForecast;
        let optCActual: SCMLCActual;
        if (optCRow) {
          optCTargetCost = {
            SCMId: optCRow.SCMIdTargetCost,
            Absolute: optCRow.TargetAbsolute,
            Quantity: optCRow.TargetQuantity,
            Weight: optCRow.TargetWeight,
            PercentOfMEK: optCRow.TargetPercentOfMEK > 0 ? optCRow.TargetPercentOfMEK : null,
            Total: optCRow.TargetTotal,
            DeltaTCVsRef: optCRow.DeltaTCVsRef
          }

          optCForecastCost = {
            SCMId: optCRow.SCMIdForecast,
            Absolute: optCRow.ForecastAbsolute,
            PercentOfMEK: optCRow.ForecastPercentOfMEK > 0 ? optCRow.ForecastPercentOfMEK : null,
            Total: optCRow.ForecastTotal,
            DeltaFCVsRef: optCRow.DeltaFCVsRef
          }

          optCActual = {
            SCMId: optCRow.SCMIdActual,
            Absolute: optCRow.ActualAbsolute,
            PercentOfMEK: optCRow.ActualPercentOfMEK > 0 ? optCRow.ActualPercentOfMEK : null,
            Total: optCRow.ActualTotal,
            DeltaTCVsACT: optCRow.DeltaTCVsACT
          }
          optCScenario = { TargetCost: optCTargetCost, Forecast: optCForecastCost, Actual: optCActual, ScenarioTitle: this.optCTitle }
        }
        else {
          optCScenario = null;
        }
        let newEntry: TCSCMLCTargetCost = {
          SCMId: 0,
          ConfigurationId: this.configurationId,
          Component: modifiedRow.Component,
          SubComponent: modifiedRow.SubComponent,
          IsActive: true,
          ReferenceScenario: {
            SCMId: 0,
            ScenarioTitle: this.refTitle,
            Quantity: Number(modifiedRow.Quantity),
            Weight: Number(modifiedRow.Weight),
            Absolute: Number(modifiedRow.Absolute),
            PercentOfMEK: modifiedRow.PercentOfMEK > 0 ? Number(modifiedRow.PercentOfMEK) : null,
            Total: Number(modifiedRow.Total)
          },
          TargetScenario: targetScenario,
          OptionA: optAScenario,
          OptionB: optBScenario,
          OptionC: optCScenario,
          CreatedBy: this.userInOrg.UserId,
          CreatedOn: new Date(),
          ModifiedBy: null,
          ModifiedOn: null
        };
        refObj.push(newEntry);
      }
    });

    this.modifiedRefSCMOverheadRows.forEach(modifiedRow => {
      let isExisting = allData.some(cs => cs.SubComponent === modifiedRow.SubComponent && cs.Component === EnumSCMComponent[EnumSCMComponent.Overhead]);
      if (!isExisting) {

        let targetRow = this.modifiedTarSCMOverheadRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let targetScenario: SCMLCScenario;
        let targetCost: SCMLCTargetCost;
        let forecastCost: SCMLCForecast;
        let actual: SCMLCActual;
        if (targetRow) {
          targetCost = {
            SCMId: targetRow.SCMIdTargetCost,
            Absolute: targetRow.TargetAbsolute,
            Quantity: targetRow.TargetQuantity,
            Weight: targetRow.TargetWeight,
            PercentOfMEK: targetRow.TargetPercentOfMEK > 0 ? targetRow.TargetPercentOfMEK : null,
            Total: targetRow.TargetTotal,
            DeltaTCVsRef: targetRow.DeltaTCVsRef
          }

          forecastCost = {
            SCMId: targetRow.SCMIdForecast,
            Absolute: targetRow.ForecastAbsolute,
            PercentOfMEK: targetRow.ForecastPercentOfMEK > 0 ? targetRow.ForecastPercentOfMEK : null,
            Total: targetRow.ForecastTotal,
            DeltaFCVsRef: targetRow.DeltaFCVsRef
          }

          actual = {
            SCMId: targetRow.SCMIdActual,
            Absolute: targetRow.ActualAbsolute,
            PercentOfMEK: targetRow.ActualPercentOfMEK > 0 ? targetRow.ActualPercentOfMEK : null,
            Total: targetRow.ActualTotal,
            DeltaTCVsACT: targetRow.DeltaTCVsACT
          }
          targetScenario = { TargetCost: targetCost, Forecast: forecastCost, Actual: actual, ScenarioTitle: this.targetTitle }
        }
        else {
          targetScenario = null;
        }

        let optARow = this.modifiedOptASCMOverheadRows.find(x => x.SubComponent == modifiedRow.SubComponent);
        let optAScenario: SCMLCScenario;
        let optATargetCost: SCMLCTargetCost;
        let optAForecastCost: SCMLCForecast;
        let optAActual: SCMLCActual;
        if (optARow) {
          optATargetCost = {
            SCMId: optARow.SCMIdTargetCost,
            Absolute: optARow.TargetAbsolute,
            Quantity: optARow.TargetQuantity,
            Weight: optARow.TargetWeight,
            PercentOfMEK: optARow.TargetPercentOfMEK > 0 ? optARow.TargetPercentOfMEK : null,
            Total: optARow.TargetTotal,
            DeltaTCVsRef: optARow.DeltaTCVsRef
          }

          optAForecastCost = {
            SCMId: optARow.SCMIdForecast,
            Absolute: optARow.ForecastAbsolute,
            PercentOfMEK: optARow.ForecastPercentOfMEK > 0 ? optARow.ForecastPercentOfMEK : null,
            Total: optARow.ForecastTotal,
            DeltaFCVsRef: optARow.DeltaFCVsRef
          }

          optAActual = {
            SCMId: optARow.SCMIdActual,
            Absolute: optARow.ActualAbsolute,
            PercentOfMEK: optARow.ActualPercentOfMEK > 0 ? optARow.ActualPercentOfMEK : null,
            Total: optARow.ActualTotal,
            DeltaTCVsACT: optARow.DeltaTCVsACT
          }
          optAScenario = { TargetCost: optATargetCost, Forecast: optAForecastCost, Actual: optAActual, ScenarioTitle: this.optATitle }
        }
        else {
          optAScenario = null;
        }

        let optBRow = this.modifiedOptBSCMOverheadRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let optBScenario: SCMLCScenario;
        let optBTargetCost: SCMLCTargetCost;
        let optBForecastCost: SCMLCForecast;
        let optBActual: SCMLCActual;
        if (optBRow) {
          optBTargetCost = {
            SCMId: optBRow.SCMIdTargetCost,
            Absolute: optBRow.TargetAbsolute,
            Quantity: optBRow.TargetQuantity,
            Weight: optBRow.TargetWeight,
            PercentOfMEK: optBRow.TargetPercentOfMEK > 0 ? optBRow.TargetPercentOfMEK : null,
            Total: optBRow.TargetTotal,
            DeltaTCVsRef: optBRow.DeltaTCVsRef
          }

          optBForecastCost = {
            SCMId: optBRow.SCMIdForecast,
            Absolute: optBRow.ForecastAbsolute,
            PercentOfMEK: optBRow.ForecastPercentOfMEK > 0 ? optBRow.ForecastPercentOfMEK : null,
            Total: optBRow.ForecastTotal,
            DeltaFCVsRef: optBRow.DeltaFCVsRef
          }

          optBActual = {
            SCMId: optBRow.SCMIdActual,
            Absolute: optBRow.ActualAbsolute,
            PercentOfMEK: optBRow.ActualPercentOfMEK > 0 ? optBRow.ActualPercentOfMEK : null,
            Total: optBRow.ActualTotal,
            DeltaTCVsACT: optBRow.DeltaTCVsACT
          }
          optBScenario = { TargetCost: optBTargetCost, Forecast: optBForecastCost, Actual: optBActual, ScenarioTitle: this.optBTitle }
        }
        else {
          optBScenario = null;
        }

        let optCRow = this.modifiedOptCSCMOverheadRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let optCScenario: SCMLCScenario;
        let optCTargetCost: SCMLCTargetCost;
        let optCForecastCost: SCMLCForecast;
        let optCActual: SCMLCActual;
        if (optCRow) {
          optCTargetCost = {
            SCMId: optCRow.SCMIdTargetCost,
            Absolute: optCRow.TargetAbsolute,
            Quantity: optCRow.TargetQuantity,
            Weight: optCRow.TargetWeight,
            PercentOfMEK: optCRow.TargetPercentOfMEK > 0 ? optCRow.TargetPercentOfMEK : null,
            Total: optCRow.TargetTotal,
            DeltaTCVsRef: optCRow.DeltaTCVsRef
          }

          optCForecastCost = {
            SCMId: optCRow.SCMIdForecast,
            Absolute: optCRow.ForecastAbsolute,
            PercentOfMEK: optCRow.ForecastPercentOfMEK > 0 ? optCRow.ForecastPercentOfMEK : null,
            Total: optCRow.ForecastTotal,
            DeltaFCVsRef: optCRow.DeltaFCVsRef
          }

          optCActual = {
            SCMId: optCRow.SCMIdActual,
            Absolute: optCRow.ActualAbsolute,
            PercentOfMEK: optCRow.ActualPercentOfMEK > 0 ? optCRow.ActualPercentOfMEK : null,
            Total: optCRow.ActualTotal,
            DeltaTCVsACT: optCRow.DeltaTCVsACT
          }
          optCScenario = { TargetCost: optCTargetCost, Forecast: optCForecastCost, Actual: optCActual, ScenarioTitle: this.optCTitle }
        }
        else {
          optCScenario = null;
        }
        let newEntry: TCSCMLCTargetCost = {
          SCMId: 0,
          ConfigurationId: this.configurationId,
          Component: modifiedRow.Component,
          SubComponent: modifiedRow.SubComponent,
          IsActive: true,
          ReferenceScenario: {
            SCMId: 0,
            ScenarioTitle: this.refTitle,
            Quantity: Number(modifiedRow.Quantity),
            Weight: Number(modifiedRow.Weight),
            Absolute: Number(modifiedRow.Absolute),
            PercentOfMEK: modifiedRow.PercentOfMEK > 0 ? Number(modifiedRow.PercentOfMEK) : null,
            Total: Number(modifiedRow.Total)
          },
          TargetScenario: targetScenario,
          OptionA: optAScenario,
          OptionB: optBScenario,
          OptionC: optCScenario,
          CreatedBy: this.userInOrg.UserId,
          CreatedOn: new Date(),
          ModifiedBy: null,
          ModifiedOn: null
        };
        refObj.push(newEntry);
      }
    });

    this.modifiedRefSCMSpecialRows.forEach(modifiedRow => {
      let isExisting = allData.some(cs => cs.SubComponent === modifiedRow.SubComponent && cs.Component === EnumSCMComponent[EnumSCMComponent.SpecialDirectCost]);
      if (!isExisting) {

        let targetRow = this.modifiedTarSCMSpecialRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let targetScenario: SCMLCScenario;
        let targetCost: SCMLCTargetCost;
        let forecastCost: SCMLCForecast;
        let actual: SCMLCActual;
        if (targetRow) {
          targetCost = {
            SCMId: targetRow.SCMIdTargetCost,
            Absolute: targetRow.TargetAbsolute,
            Quantity: targetRow.TargetQuantity,
            Weight: targetRow.TargetWeight,
            PercentOfMEK: targetRow.TargetPercentOfMEK > 0 ? targetRow.TargetPercentOfMEK : null,
            Total: targetRow.TargetTotal,
            DeltaTCVsRef: targetRow.DeltaTCVsRef
          }

          forecastCost = {
            SCMId: targetRow.SCMIdForecast,
            Absolute: targetRow.ForecastAbsolute,
            PercentOfMEK: targetRow.ForecastPercentOfMEK > 0 ? targetRow.ForecastPercentOfMEK : null,
            Total: targetRow.ForecastTotal,
            DeltaFCVsRef: targetRow.DeltaFCVsRef
          }

          actual = {
            SCMId: targetRow.SCMIdActual,
            Absolute: targetRow.ActualAbsolute,
            PercentOfMEK: targetRow.ActualPercentOfMEK > 0 ? targetRow.ActualPercentOfMEK : null,
            Total: targetRow.ActualTotal,
            DeltaTCVsACT: targetRow.DeltaTCVsACT
          }
          targetScenario = { TargetCost: targetCost, Forecast: forecastCost, Actual: actual, ScenarioTitle: this.targetTitle }
        }
        else {
          targetScenario = null;
        }

        let optARow = this.modifiedOptASCMSpecialRows.find(x => x.SubComponent == modifiedRow.SubComponent);
        let optAScenario: SCMLCScenario;
        let optATargetCost: SCMLCTargetCost;
        let optAForecastCost: SCMLCForecast;
        let optAActual: SCMLCActual;
        if (optARow) {
          optATargetCost = {
            SCMId: optARow.SCMIdTargetCost,
            Absolute: optARow.TargetAbsolute,
            Quantity: optARow.TargetQuantity,
            Weight: optARow.TargetWeight,
            PercentOfMEK: optARow.TargetPercentOfMEK > 0 ? optARow.TargetPercentOfMEK : null,
            Total: optARow.TargetTotal,
            DeltaTCVsRef: optARow.DeltaTCVsRef
          }

          optAForecastCost = {
            SCMId: optARow.SCMIdForecast,
            Absolute: optARow.ForecastAbsolute,
            PercentOfMEK: optARow.ForecastPercentOfMEK > 0 ? optARow.ForecastPercentOfMEK : null,
            Total: optARow.ForecastTotal,
            DeltaFCVsRef: optARow.DeltaFCVsRef
          }

          optAActual = {
            SCMId: optARow.SCMIdActual,
            Absolute: optARow.ActualAbsolute,
            PercentOfMEK: optARow.ActualPercentOfMEK > 0 ? optARow.ActualPercentOfMEK : null,
            Total: optARow.ActualTotal,
            DeltaTCVsACT: optARow.DeltaTCVsACT
          }
          optAScenario = { TargetCost: optATargetCost, Forecast: optAForecastCost, Actual: optAActual, ScenarioTitle: this.optATitle }
        }
        else {
          optAScenario = null;
        }

        let optBRow = this.modifiedOptBSCMSpecialRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let optBScenario: SCMLCScenario;
        let optBTargetCost: SCMLCTargetCost;
        let optBForecastCost: SCMLCForecast;
        let optBActual: SCMLCActual;
        if (optBRow) {
          optBTargetCost = {
            SCMId: optBRow.SCMIdTargetCost,
            Absolute: optBRow.TargetAbsolute,
            Quantity: optBRow.TargetQuantity,
            Weight: optBRow.TargetWeight,
            PercentOfMEK: optBRow.TargetPercentOfMEK > 0 ? optBRow.TargetPercentOfMEK : null,
            Total: optBRow.TargetTotal,
            DeltaTCVsRef: optBRow.DeltaTCVsRef
          }

          optBForecastCost = {
            SCMId: optBRow.SCMIdForecast,
            Absolute: optBRow.ForecastAbsolute,
            PercentOfMEK: optBRow.ForecastPercentOfMEK > 0 ? optBRow.ForecastPercentOfMEK : null,
            Total: optBRow.ForecastTotal,
            DeltaFCVsRef: optBRow.DeltaFCVsRef
          }

          optBActual = {
            SCMId: optBRow.SCMIdActual,
            Absolute: optBRow.ActualAbsolute,
            PercentOfMEK: optBRow.ActualPercentOfMEK > 0 ? optBRow.ActualPercentOfMEK : null,
            Total: optBRow.ActualTotal,
            DeltaTCVsACT: optBRow.DeltaTCVsACT
          }
          optBScenario = { TargetCost: optBTargetCost, Forecast: optBForecastCost, Actual: optBActual, ScenarioTitle: this.optBTitle }
        }
        else {
          optBScenario = null;
        }

        let optCRow = this.modifiedOptCSCMSpecialRows.find(tl => tl.SubComponent === modifiedRow.SubComponent);
        let optCScenario: SCMLCScenario;
        let optCTargetCost: SCMLCTargetCost;
        let optCForecastCost: SCMLCForecast;
        let optCActual: SCMLCActual;
        if (optCRow) {
          optCTargetCost = {
            SCMId: optCRow.SCMIdTargetCost,
            Absolute: optCRow.TargetAbsolute,
            Quantity: optCRow.TargetQuantity,
            Weight: optCRow.TargetWeight,
            PercentOfMEK: optCRow.TargetPercentOfMEK > 0 ? optCRow.TargetPercentOfMEK : null,
            Total: optCRow.TargetTotal,
            DeltaTCVsRef: optCRow.DeltaTCVsRef
          }

          optCForecastCost = {
            SCMId: optCRow.SCMIdForecast,
            Absolute: optCRow.ForecastAbsolute,
            PercentOfMEK: optCRow.ForecastPercentOfMEK > 0 ? optCRow.ForecastPercentOfMEK : null,
            Total: optCRow.ForecastTotal,
            DeltaFCVsRef: optCRow.DeltaFCVsRef
          }

          optCActual = {
            SCMId: optCRow.SCMIdActual,
            Absolute: optCRow.ActualAbsolute,
            PercentOfMEK: optCRow.ActualPercentOfMEK > 0 ? optCRow.ActualPercentOfMEK : null,
            Total: optCRow.ActualTotal,
            DeltaTCVsACT: optCRow.DeltaTCVsACT
          }
          optCScenario = { TargetCost: optCTargetCost, Forecast: optCForecastCost, Actual: optCActual, ScenarioTitle: this.optCTitle }
        }
        else {
          optCScenario = null;
        }
        let newEntry: TCSCMLCTargetCost = {
          SCMId: 0,
          ConfigurationId: this.configurationId,
          Component: modifiedRow.Component,
          SubComponent: modifiedRow.SubComponent,
          IsActive: true,
          ReferenceScenario: {
            SCMId: 0,
            ScenarioTitle: this.refTitle,
            Quantity: Number(modifiedRow.Quantity),
            Weight: Number(modifiedRow.Weight),
            Absolute: Number(modifiedRow.Absolute),
            PercentOfMEK: modifiedRow.PercentOfMEK > 0 ? Number(modifiedRow.PercentOfMEK) : null,
            Total: Number(modifiedRow.Total)
          },
          TargetScenario: targetScenario,
          OptionA: optAScenario,
          OptionB: optBScenario,
          OptionC: optCScenario,
          CreatedBy: this.userInOrg.UserId,
          CreatedOn: new Date(),
          ModifiedBy: null,
          ModifiedOn: null
        };
        refObj.push(newEntry);
      }
    });

    // console.log(refObj)

    return refObj;
  }
  // called when user click add row icon for reference grid;
  AddNewRowSpecialRef() {
    this.validateAddSpecialReference();
  }
  // called when user click add row icon for reference grid;
  AddNewRowRef() {
    this.validateAddReference();
  }
  // called when user click add row icon for reference grid;
  AddNewRowOverheadRef() {
    this.validateAddOverhadReference();
  }
  // vallidate reference grid last row before adding new row and also add new row;
  validateAddReference() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.refLabourGridApi.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.refLabourGridApi.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.DirectCostSCMRef = this.DirectCostSCMRef - 1;
      this.refLabourGridApi.applyTransaction({
        add: [{
          SCMId: 0,
          Component: EnumSCMComponent[EnumSCMComponent.Labour],
          ComponentId: this.DirectCostSCMRef,
          ConfigurationId: this.configurationId,
          SubComponent: "",
          Quantity: null,
          Weight: null,
          Absolute: null,
          PercentOfMEK: null,
          Total: null,
          IsActive: true,
          CreatedBy: 1,
          CreatedOn: null,
          ModifiedBy: 1,
          ModifiedOn: null,
          IsEditable: this.isEditable

        }], addIndex: 0
      })
      this.tarGridApi.applyTransaction({
        add: [{
          SCMIdTargetCost: 0,
          SCMIdForecast: 0,
          SCMIdActual: 0,
          Component: EnumSCMComponent[EnumSCMComponent.Labour],
          ComponentId: this.DirectCostSCMRef,
          ConfigurationId: this.configurationId,
          SubComponent: null,
          ReferenceCost: null,
          TargetQuantity: null,
          ForecastQuantity: null,
          ActualsQuantity: null,
          TargetWeight: null,
          ForecastWeight: null,
          ActualsWeight: null,
          TargetAbsolute: null,
          ForecastAbsolute: null,
          ActualAbsolute: null,
          TargetPercentOfMEK: null,
          ForecastPercentOfMEK: null,
          ActualPercentOfMEK: null,
          TargetTotal: null,
          ForecastTotal: null,
          ActualTotal: null,
          DeltaTCVsRef: null,
          DeltaFCVsRef: null,
          DeltaTCVsACT: null,
          IsActive: true,
          CreatedBy: 1,
          CreatedOn: null,
          ModifiedBy: 1,
          ModifiedOn: null,
          IsEditable: this.isEditable

        }], addIndex: 0
      })
      if (this.optAVisible == true) {
      //  console.log('test')
        this.tarGridAApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Labour],
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
      if (this.optBVisible == true) {
        this.tarGridBApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Labour],
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
      if (this.optCVisible == true) {
        this.tarGridCApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Labour],
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
    } else {
      let data = this.refLabourGridApi.getDisplayedRowAtIndex(0).data;
      // let checked = this.regex.test(data.refereceScenerioTitle)
      if (data.SubComponent === "Add Component Here" || data.SubComponent === "" || data.SubComponent == null || data.SubComponent === undefined) {
        error.push({ "Direct Costs SCM": errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.DirectCostSCMRef = this.DirectCostSCMRef - 1;
        this.refLabourGridApi.applyTransaction({
          add: [{
            SCMId: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Labour],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: "",
            Quantity: null,
            Weight: null,
            Absolute: null,
            PercentOfMEK: null,
            Total: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
        this.tarGridApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Labour],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
        if (this.optAVisible == true) {
         // console.log('test')
          this.tarGridAApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.Labour],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
        if (this.optBVisible == true) {
          this.tarGridBApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.Labour],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
        if (this.optCVisible == true) {
          this.tarGridCApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.Labour],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
      } else {
        this.openAlertDialog(error)
      }
    }
  }

  // vallidate reference grid last row before adding new row and also add new row;
  validateAddSpecialReference() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.refGridSpecialApi.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.refGridSpecialApi.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.DirectCostSCMRef = this.DirectCostSCMRef - 1;
      this.refGridSpecialApi.applyTransaction({
        add: [{
          SCMId: 0,
          Component: "SpecialDirectCost",
          ComponentId: this.DirectCostSCMRef,
          ConfigurationId: this.configurationId,
          SubComponent: "",
          Quantity: null,
          Weight: null,
          Absolute: null,
          PercentOfMEK: null,
          Total: null,
          IsActive: true,
          CreatedBy: 1,
          CreatedOn: null,
          ModifiedBy: 1,
          ModifiedOn: null,
          IsEditable: this.isEditable

        }], addIndex: 0
      })
      this.tarGridSpecialApi.applyTransaction({
        add: [{
          SCMIdTargetCost: 0,
          SCMIdForecast: 0,
          SCMIdActual: 0,
          Component: "SpecialDirectCost",
          ComponentId: this.DirectCostSCMRef,
          ConfigurationId: this.configurationId,
          SubComponent: null,
          ReferenceCost: null,
          TargetQuantity: null,
          ForecastQuantity: null,
          ActualsQuantity: null,
          TargetWeight: null,
          ForecastWeight: null,
          ActualsWeight: null,
          TargetAbsolute: null,
          ForecastAbsolute: null,
          ActualAbsolute: null,
          TargetPercentOfMEK: null,
          ForecastPercentOfMEK: null,
          ActualPercentOfMEK: null,
          TargetTotal: null,
          ForecastTotal: null,
          ActualTotal: null,
          DeltaTCVsRef: null,
          DeltaFCVsRef: null,
          DeltaTCVsACT: null,
          IsActive: true,
          CreatedBy: 1,
          CreatedOn: null,
          ModifiedBy: 1,
          ModifiedOn: null,
          IsEditable: this.isEditable

        }], addIndex: 0
      })
      if (this.optAVisible == true) {
        this.tarGridASpecialApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: "SpecialDirectCost",
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
      if (this.optBVisible == true) {
        this.tarGridBSpecialApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: "SpecialDirectCost",
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
      if (this.optCVisible == true) {
        this.tarGridCSpecialApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: "SpecialDirectCost",
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
    } else {
      let data = this.refGridSpecialApi.getDisplayedRowAtIndex(0).data;
      // let checked = this.regex.test(data.refereceScenerioTitle)
      if (data.SubComponent === "Add Component Here" || data.SubComponent === "" || data.SubComponent == null || data.SubComponent === undefined) {
        error.push({ "Direct Costs SCM": errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.DirectCostSCMRef = this.DirectCostSCMRef - 1;
        this.refGridSpecialApi.applyTransaction({
          add: [{
            SCMId: 0,
            Component: "SpecialDirectCost",
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: "",
            Quantity: null,
            Weight: null,
            Absolute: null,
            PercentOfMEK: null,
            Total: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
        this.tarGridSpecialApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: "SpecialDirectCost",
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
        if (this.optAVisible == true) {
          this.tarGridASpecialApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: "SpecialDirectCost",
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
        if (this.optBVisible == true) {
          this.tarGridBSpecialApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: "SpecialDirectCost",
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
        if (this.optCVisible == true) {
          this.tarGridCSpecialApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: "SpecialDirectCost",
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
      } else {
        this.openAlertDialog(error)
      }
    }
  }
  validateAddOverhadReference() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.refGridOverheadApi.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.refGridOverheadApi.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.DirectCostSCMRef = this.DirectCostSCMRef - 1;
      this.refGridOverheadApi.applyTransaction({
        add: [{
          SCMId: 0,
          Component: EnumSCMComponent[EnumSCMComponent.Overhead],
          ComponentId: this.DirectCostSCMRef,
          ConfigurationId: this.configurationId,
          SubComponent: "",
          Quantity: null,
          Weight: null,
          Absolute: null,
          PercentOfMEK: null,
          Total: null,
          IsActive: true,
          CreatedBy: 1,
          CreatedOn: null,
          ModifiedBy: 1,
          ModifiedOn: null,
          IsEditable: this.isEditable
        }], addIndex: 0
      })
      this.tarGridOverheadApi.applyTransaction({
        add: [{
          SCMIdTargetCost: 0,
          SCMIdForecast: 0,
          SCMIdActual: 0,
          Component: EnumSCMComponent[EnumSCMComponent.Overhead],
          ComponentId: this.DirectCostSCMRef,
          ConfigurationId: this.configurationId,
          SubComponent: null,
          ReferenceCost: null,
          TargetQuantity: null,
          ForecastQuantity: null,
          ActualsQuantity: null,
          TargetWeight: null,
          ForecastWeight: null,
          ActualsWeight: null,
          TargetAbsolute: null,
          ForecastAbsolute: null,
          ActualAbsolute: null,
          TargetPercentOfMEK: null,
          ForecastPercentOfMEK: null,
          ActualPercentOfMEK: null,
          TargetTotal: null,
          ForecastTotal: null,
          ActualTotal: null,
          DeltaTCVsRef: null,
          DeltaFCVsRef: null,
          DeltaTCVsACT: null,
          IsActive: true,
          CreatedBy: 1,
          CreatedOn: null,
          ModifiedBy: 1,
          ModifiedOn: null,
          IsEditable: this.isEditable

        }], addIndex: 0
      })
      if (this.optAVisible == true) {
        this.tarGridAOverheadApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Overhead],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
      if (this.optBVisible == true) {
        this.tarGridBOverheadApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Overhead],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
      if (this.optCVisible == true) {
        this.tarGridCOverheadApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Overhead],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable

          }], addIndex: 0
        })
      }
    } else {
      let data = this.refGridOverheadApi.getDisplayedRowAtIndex(0).data;
      // let checked = this.regex.test(data.refereceScenerioTitle)
      if (data.SubComponent === "Add Component Here" || data.SubComponent === "" || data.SubComponent == null || data.SubComponent === undefined) {
        error.push({ "Direct Costs SCM": errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.DirectCostSCMRef = this.DirectCostSCMRef - 1;
        this.refGridOverheadApi.applyTransaction({
          add: [{
            SCMId: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Overhead],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: "",
            Quantity: null,
            Weight: null,
            Absolute: null,
            PercentOfMEK: null,
            Total: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
        this.tarGridOverheadApi.applyTransaction({
          add: [{
            SCMIdTargetCost: 0,
            SCMIdForecast: 0,
            SCMIdActual: 0,
            Component: EnumSCMComponent[EnumSCMComponent.Overhead],
            ComponentId: this.DirectCostSCMRef,
            ConfigurationId: this.configurationId,
            SubComponent: null,
            ReferenceCost: null,
            TargetQuantity: null,
            ForecastQuantity: null,
            ActualsQuantity: null,
            TargetWeight: null,
            ForecastWeight: null,
            ActualsWeight: null,
            TargetAbsolute: null,
            ForecastAbsolute: null,
            ActualAbsolute: null,
            TargetPercentOfMEK: null,
            ForecastPercentOfMEK: null,
            ActualPercentOfMEK: null,
            TargetTotal: null,
            ForecastTotal: null,
            ActualTotal: null,
            DeltaTCVsRef: null,
            DeltaFCVsRef: null,
            DeltaTCVsACT: null,
            IsActive: true,
            CreatedBy: 1,
            CreatedOn: null,
            ModifiedBy: 1,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
        if (this.optAVisible == true) {

          this.tarGridAOverheadApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.Overhead],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
        if (this.optBVisible == true) {
          this.tarGridBOverheadApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.Overhead],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
        if (this.optCVisible == true) {
          this.tarGridCOverheadApi.applyTransaction({
            add: [{
              SCMIdTargetCost: 0,
              SCMIdForecast: 0,
              SCMIdActual: 0,
              Component: EnumSCMComponent[EnumSCMComponent.Overhead],
              ComponentId: this.DirectCostSCMRef,
              ConfigurationId: this.configurationId,
              SubComponent: null,
              ReferenceCost: null,
              TargetQuantity: null,
              ForecastQuantity: null,
              ActualsQuantity: null,
              TargetWeight: null,
              ForecastWeight: null,
              ActualsWeight: null,
              TargetAbsolute: null,
              ForecastAbsolute: null,
              ActualAbsolute: null,
              TargetPercentOfMEK: null,
              ForecastPercentOfMEK: null,
              ActualPercentOfMEK: null,
              TargetTotal: null,
              ForecastTotal: null,
              ActualTotal: null,
              DeltaTCVsRef: null,
              DeltaFCVsRef: null,
              DeltaTCVsACT: null,
              IsActive: true,
              CreatedBy: 1,
              CreatedOn: null,
              ModifiedBy: 1,
              ModifiedOn: null,
              IsEditable: this.isEditable

            }], addIndex: 0
          })
        }
      } else {
        this.openAlertDialog(error)
      }
    }
  }

  onRefCellValueChangedLabour(params) {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let error = [];
    let firstRowLabour = this.refLabourGridApi.getDisplayedRowAtIndex(0);
    if (firstRowLabour != undefined) {
      let data = firstRowLabour.data;
      let SubComponent = data.SubComponent.toLowerCase();
      // let a=  SubComponent.trimLeft();
      if ((SubComponent.trim() === EnumSCMComponent[EnumSCMComponent.Labour].toLowerCase().trim() && data.ComponentId < 0) || (SubComponent.trim() === EnumSCMComponent[EnumSCMComponent.Overhead].toLowerCase().trim() && data.ComponentId < 0)) {
        error.push({ "Please change name SubComponent": errorMessage })

       // data.SubComponent = null;
      }
    }
    if (error.length != 0) {

      this.openAlertDialog(error);

      return false;

    }
    else {
      this.hasUnsavedChanges = true;
      this.addOrUpdateRow(this.modifiedRefSCMLabourRows, params.data);
      let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
      this.refGridSpecialApi.setPinnedBottomRowData(refpindata);
      //   if (params.data.configurationId      != 0) {
      // let isPushed = false;
      // if (this.modifiedRefSCMLabourRows.length == 0) {
      //   this.modifiedRefSCMLabourRows.push(params.data);
      //   isPushed = true;
      // }
      // else {
      //   this.modifiedRefSCMLabourRows.forEach((row, index) => {
      //     if (params.data.ComponentId
      //       == row.ComponentId && params.data.SubComponent == row.SubComponent
      //     ) {
      //       // row = params.data;
      //       // this.modifiedCSRows[index] = params.data;
      //       isPushed = true;
      //     }
      //   })
      // }
      // if (!isPushed) {
      //   this.modifiedRefSCMLabourRows.push(params.data);
      // }



      // if (params.data.SubComponent === "Labour") {
      //   let tar = this.tarGridLabourData.find(x => x.SubComponent == params.data.SubComponent)
      //   tar.ReferenceCost = params.data.Total
      //   this.tarGridApi.redrawRows();
      // } else {
      this.tarGridApi.forEachNode(x => {
        if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
          x.data.ReferenceCost = params.data.Total
          x.data.SubComponent = params.data.SubComponent

          this.tarGridApi.redrawRows();
          if (x.data.TargetTotal > 0 && x.data.ActualTotal > 0 && x.data.ActualTotal > 0)
            this.addOrUpdateRow(this.modifiedTarSCMLabourRows, x.data);
        }
      })

      if (this.tarGridAApi != undefined) {
        this.tarGridAApi.forEachNode(x => {
          if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
            x.data.ReferenceCost = params.data.Total
            x.data.SubComponent = params.data.SubComponent
            this.tarGridAApi.redrawRows();
            this.addOrUpdateRow(this.modifiedOptASCMLabourRows, x.data);
          }
        }
        )
      }

      if (this.tarGridBApi != undefined) {
        this.tarGridBApi.forEachNode(x => {
          if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
            x.data.ReferenceCost = params.data.Total
            x.data.SubComponent = params.data.SubComponent
            this.tarGridBApi.redrawRows();
            this.addOrUpdateRow(this.modifiedOptBSCMLabourRows, x.data);
          }
        }
        )
      }

      if (this.tarGridCApi != undefined) {
        this.tarGridCApi.forEachNode(x => {
          if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
            x.data.ReferenceCost = params.data.Total
            x.data.SubComponent = params.data.SubComponent
            this.tarGridCApi.redrawRows();
            this.addOrUpdateRow(this.modifiedOptCSCMLabourRows, x.data);
          }
        }
        )
      }
    }
  }

  onTarLabourCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedTarSCMLabourRows, params.data);

    let tarpindata = this.createDataTarSpecial(this.totalTar(this.tarGridSpecialApi), this.isEditable);
    //let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
    this.tarGridSpecialApi.setPinnedBottomRowData(tarpindata);
  }

  onOptionALabourCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptASCMLabourRows, params.data);
    let tarApindata = this.createDataOptASpecial(this.totalTar(this.tarGridASpecialApi), this.isEditable);
   // let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
    this.tarGridASpecialApi.setPinnedBottomRowData(tarApindata);
  }

  onOptionBLabourCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptBSCMLabourRows, params.data);
   // let tarBpindata = this.createDataTarSpecial(this.totalTar(this.tarGridBSpecialApi), this.isEditable);
    let tarBpindata = this.createDataOptBSpecial(this.totalTar(this.tarGridBSpecialApi), this.isEditable);
    this.tarGridBSpecialApi.setPinnedBottomRowData(tarBpindata);
  }

  onOptionCLabourCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptCSCMLabourRows, params.data);
  //  let tarCpindata = this.createDataTarSpecial(this.totalTar(this.tarGridCSpecialApi), this.isEditable);
    let tarCpindata = this.createDataOptCSpecial(this.totalTar(this.tarGridCSpecialApi), this.isEditable);
    this.tarGridCSpecialApi.setPinnedBottomRowData(tarCpindata);

  }

  onRefOverheadCellValueChanged(params) {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let error = [];
    let firstRowOverhead = this.refGridOverheadApi.getDisplayedRowAtIndex(0);
    if (firstRowOverhead != undefined) {
      let data = firstRowOverhead.data;
      let SubComponent = data.SubComponent.toLowerCase();
      if ((SubComponent.trim() === EnumSCMComponent[EnumSCMComponent.Labour].toLowerCase().trim() && data.ComponentId < 0) || (SubComponent.trim() === EnumSCMComponent[EnumSCMComponent.Overhead].toLowerCase().trim() && data.ComponentId < 0)) {
        error.push({ "Please change name SubComponent": errorMessage })
      }
    }
    if (error.length != 0) {
      this.openAlertDialog(error);

      return false;

    }
    else {
      this.hasUnsavedChanges = true;
      this.addOrUpdateRow(this.modifiedRefSCMOverheadRows, params.data);
      let tarpindata = this.createDataTarSpecial(this.totalTar(this.tarGridSpecialApi), this.isEditable);
      //let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
      this.tarGridSpecialApi.setPinnedBottomRowData(tarpindata);
      let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
      this.refGridSpecialApi.setPinnedBottomRowData(refpindata);
      //   if (params.data.configurationId      != 0) {;
      // let isPushed = false;
      // if (this.modifiedRefSCMLabourRows.length == 0) {
      //   this.modifiedRefSCMLabourRows.push(params.data);
      //   isPushed = true;
      // }
      // else {
      //   this.modifiedRefSCMLabourRows.forEach((row, index) => {
      //     if (params.data.ComponentId
      //       == row.ComponentId && params.data.SubComponent == row.SubComponent
      //     ) {
      //       // row = params.data;
      //       // this.modifiedCSRows[index] = params.data;
      //       isPushed = true;
      //     }
      //   })
      // }
      // if (!isPushed) {
      //   this.modifiedRefSCMLabourRows.push(params.data);
      // }
      // if (params.data.SubComponent === "Labour") {
      //   let tar = this.tarGridLabourData.find(x => x.SubComponent == params.data.SubComponent)
      //   tar.ReferenceCost = params.data.Total
      //   this.tarGridApi.redrawRows();
      // } else {
      this.tarGridOverheadApi.forEachNode(x => {
        if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
          x.data.ReferenceCost = params.data.Total
          x.data.SubComponent = params.data.SubComponent
          this.tarGridOverheadApi.redrawRows();
          if (x.data.TargetTotal > 0 && x.data.ActualTotal > 0 && x.data.ActualTotal > 0)
            this.addOrUpdateRow(this.modifiedTarSCMOverheadRows, x.data);
        }
      })

      if (this.tarGridAOverheadApi != undefined) {
        this.tarGridAOverheadApi.forEachNode(x => {
          if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
            x.data.ReferenceCost = params.data.Total
            x.data.SubComponent = params.data.SubComponent
            this.tarGridAOverheadApi.redrawRows();
            this.addOrUpdateRow(this.modifiedOptASCMOverheadRows, x.data);
          }
        }
        )
      }

      if (this.tarGridBOverheadApi != undefined) {
        this.tarGridBOverheadApi.forEachNode(x => {
          if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
            x.data.ReferenceCost = params.data.Total
            x.data.SubComponent = params.data.SubComponent
            this.tarGridBOverheadApi.redrawRows();
            this.addOrUpdateRow(this.modifiedOptBSCMOverheadRows, x.data);
          }
        }
        )
      }

      if (this.tarGridCOverheadApi != undefined) {
        this.tarGridCOverheadApi.forEachNode(x => {
          if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
            x.data.ReferenceCost = params.data.Total
            x.data.SubComponent = params.data.SubComponent
            this.tarGridCOverheadApi.redrawRows();
            this.addOrUpdateRow(this.modifiedOptCSCMOverheadRows, x.data);
          }
        }
        )
      }


      // this.hasUnsavedChanges = true;
      // //   if (params.data.configurationId      != 0) {
      // let isPushed = false;
      // if (this.modifiedRefSCMOverheadRows.length == 0) {
      //   this.modifiedRefSCMOverheadRows.push(params.data);
      //   isPushed = true;
      // }
      // else {
      //   this.modifiedRefSCMOverheadRows.forEach((row, index) => {
      //     if (params.data.ConfigurationId
      //       == row.ConfigurationId && params.data.SubComponent == row.SubComponent
      //     ) {
      //       // row = params.data;
      //       // this.modifiedCSRows[index] = params.data;
      //       isPushed = true;
      //     }
      //   })
      // }
      // if (!isPushed) {
      //   this.modifiedRefSCMOverheadRows.push(params.data);
      // }
      // }
      //console.log(this.modifiedRefSCMLabourRows);
    }
  }

  onTarOverheadCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedTarSCMOverheadRows, params.data);

    let tarpindata = this.createDataTarSpecial(this.totalTar(this.tarGridSpecialApi), this.isEditable);
    //let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
    this.tarGridSpecialApi.setPinnedBottomRowData(tarpindata);
  }

  onOptionAOverheadCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptASCMOverheadRows, params.data);
   // let tarApindata = this.createDataTarSpecial(this.totalTar(this.tarGridASpecialApi), this.isEditable);
    let tarApindata = this.createDataOptASpecial(this.totalTar(this.tarGridASpecialApi), this.isEditable);
    //let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
    this.tarGridASpecialApi.setPinnedBottomRowData(tarApindata);
  }

  onOptionBOverheadCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptBSCMOverheadRows, params.data);
   // let tarBpindata = this.createDataTarSpecial(this.totalTar(this.tarGridBSpecialApi), this.isEditable);
    let tarBpindata = this.createDataOptBSpecial(this.totalTar(this.tarGridBSpecialApi), this.isEditable);
    this.tarGridBSpecialApi.setPinnedBottomRowData(tarBpindata);
  }

  onOptionCOverheadCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptCSCMOverheadRows, params.data);
   // let tarCpindata = this.createDataRefSpecial(this.totalTar(this.tarGridCSpecialApi), this.isEditable);
    let tarCpindata = this.createDataOptCSpecial(this.totalTar(this.tarGridCSpecialApi), this.isEditable);
    this.tarGridCSpecialApi.setPinnedBottomRowData(tarCpindata);
  }

  onRefSpecialCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedRefSCMSpecialRows, params.data);
    let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
    this.refGridSpecialApi.setPinnedBottomRowData(refpindata);
    //   if (params.data.configurationId      != 0) {
    // let isPushed = false;
    // if (this.modifiedRefSCMLabourRows.length == 0) {
    //   this.modifiedRefSCMLabourRows.push(params.data);
    //   isPushed = true;
    // }
    // else {
    //   this.modifiedRefSCMLabourRows.forEach((row, index) => {
    //     if (params.data.ComponentId
    //       == row.ComponentId && params.data.SubComponent == row.SubComponent
    //     ) {
    //       // row = params.data;
    //       // this.modifiedCSRows[index] = params.data;
    //       isPushed = true;
    //     }
    //   })
    // }
    // if (!isPushed) {
    //   this.modifiedRefSCMLabourRows.push(params.data);
    // }
    // if (params.data.SubComponent === "Labour") {
    //   let tar = this.tarGridLabourData.find(x => x.SubComponent == params.data.SubComponent)
    //   tar.ReferenceCost = params.data.Total
    //   this.tarGridApi.redrawRows();
    // } else {
    this.tarGridSpecialApi.forEachNode(x => {
      if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
        x.data.ReferenceCost = params.data.Total
        x.data.SubComponent = params.data.SubComponent
        this.tarGridSpecialApi.redrawRows();
        if (x.data.TargetTotal > 0 && x.data.ActualTotal > 0 && x.data.ActualTotal > 0)
          this.addOrUpdateRow(this.modifiedTarSCMSpecialRows, x.data);
      }
    })

    if (this.tarGridASpecialApi != undefined) {
      this.tarGridASpecialApi.forEachNode(x => {
        if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
          x.data.ReferenceCost = params.data.Total
          x.data.SubComponent = params.data.SubComponent
          this.tarGridASpecialApi.redrawRows();
          this.addOrUpdateRow(this.modifiedOptASCMSpecialRows, x.data);
        }
      }
      )
    }

    if (this.tarGridBSpecialApi != undefined) {
      this.tarGridBSpecialApi.forEachNode(x => {
        if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
          x.data.ReferenceCost = params.data.Total
          x.data.SubComponent = params.data.SubComponent
          this.tarGridBSpecialApi.redrawRows();
          this.addOrUpdateRow(this.modifiedOptBSCMSpecialRows, x.data);
        }
      }
      )
    }

    if (this.tarGridCSpecialApi != undefined) {
      this.tarGridCSpecialApi.forEachNode(x => {
        if ((params.data.ComponentId != undefined && x.data.ComponentId == params.data.ComponentId) || (params.colDef.field == "SubComponent" && x.data.SubComponent == params.oldValue) || x.data.SubComponent == params.data.SubComponent) {
          x.data.ReferenceCost = params.data.Total
          x.data.SubComponent = params.data.SubComponent
          this.tarGridCSpecialApi.redrawRows();
          this.addOrUpdateRow(this.modifiedOptCSCMSpecialRows, x.data);
        }
      }
      )
    }



    // //   if (params.data.configurationId      != 0) {
    // let isPushed = false;
    // if (this.modifiedRefSCMSpecialRows.length == 0) {
    //   this.modifiedRefSCMSpecialRows.push(params.data);
    //   isPushed = true;
    // }
    // else {
    //   this.modifiedRefSCMSpecialRows.forEach((row, index) => {
    //     if (params.data.ConfigurationId
    //       == row.ConfigurationId && params.data.SubComponent == row.SubComponent
    //     ) {
    //       // row = params.data;
    //       // this.modifiedCSRows[index] = params.data;
    //       isPushed = true;
    //     }
    //   })
    // }
    // if (!isPushed) {
    //   this.modifiedRefSCMSpecialRows.push(params.data);
    // }
    // }
    //console.log(this.modifiedRefSCMLabourRows);
  }

  onTarSpecialCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedTarSCMSpecialRows, params.data);
    let tarpindata = this.createDataTarSpecial(this.totalTar(this.tarGridSpecialApi), this.isEditable);
    //let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
    this.tarGridSpecialApi.setPinnedBottomRowData(tarpindata);
  }

  onOptionASpecialCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptASCMSpecialRows, params.data);
    let tarApindata = this.createDataOptASpecial(this.totalTar(this.tarGridASpecialApi), this.isEditable);
    //let refpindata = this.createDataRefSpecial(this.totalRef(this.refGridSpecialApi), this.isEditable);
    this.tarGridASpecialApi.setPinnedBottomRowData(tarApindata);
  }

  onOptionBSpecialCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptBSCMSpecialRows, params.data);
  //  // let tarBpindata = this.createDataTarSpecial(this.totalTar(this.tarGridBSpecialApi), this.isEditable);
    let tarBpindata = this.createDataOptBSpecial(this.totalTar(this.tarGridBSpecialApi), this.isEditable);
    this.tarGridBSpecialApi.setPinnedBottomRowData(tarBpindata);
  }

  onOptionCSpecialCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.addOrUpdateRow(this.modifiedOptCSCMSpecialRows, params.data);
   // let tarCpindata = this.createDataRefSpecial(this.totalTar(this.tarGridCSpecialApi), this.isEditable);
    let tarCpindata = this.createDataOptCSpecial(this.totalTar(this.tarGridCSpecialApi), this.isEditable);
    this.tarGridCSpecialApi.setPinnedBottomRowData(tarCpindata);
  }

  TargetTotal = (params) => {
    if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs'
    ) {
      let total = 0;
      params.api.forEachNode((node) => {
        total = Number(node.data.TargetTotal) + total;
      })
      params.data.TargetTotal = total;
      return total;
    } if (params.node.data.SubComponent === 'Total Direct Costs SCM') {
      return params.data.TargetTotal;
    }
    else {
      let total = 0;
      let actualMeks = 0;

      switch (params.api.gridOptionsWrapper.environment.eGridDiv.id) {

        case 'refLabourGrid':
        case 'refOverheadGrid':
        case 'refSpecialGrid':
          actualMeks = this.EQTargetCostRefLabourMek ?? 0
          break;

        case 'tarLabourGrid':
        case 'tarOverheadGrid':
        case 'tarSpecialGrid':
          actualMeks = this.EQTargetCostTarLabourMek ?? 0
          break;


        case 'optALabourGrid':
        case 'optAOverheadGrid':
        case 'optASpecialGrid':
          actualMeks = this.EQTargetCostOptALabourMek ?? 0
          break;

        case 'optBLabourGrid':
        case 'optBOverheadGrid':
        case 'optBSpecialGrid':
          actualMeks = this.EQTargetCostOptBLabourMek ?? 0
          break;

        case 'optCLabourGrid':
        case 'optCOverheadGrid':
        case 'optCSpecialGrid':
          actualMeks = this.EQTargetCostOptCLabourMek ?? 0
          break;

        default:
          actualMeks;
          break;
      }

      if (params.api != null) {
        let permek = 0;

        if (params.node.data.TargetPercentOfMEK > 0) {
          permek = Number(params.data.TargetPercentOfMEK) / 100;
          total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * actualMeks;
          //abs = Number(node.data.Absolute) + abs
        }
        else {
          total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.TargetAbsolute);
        }
      }
      params.data.TargetTotal = total;
      return total;
    }
  };

  ActualTotal = (params) => {
    if (params.node.data.SubComponent === 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs' || params.node.data.SubComponent === 'Total Special Direct Costs')
    // if (params.node.data.SubComponent != 'Total Labor Costs' || params.node.data.SubComponent === 'Total Overhead Costs')
    {
      let total = 0;
      params.api.forEachNode((node) => {
        total = Number(node.data.ActualTotal) + total;
      })
      params.data.ActualTotal = total;
      return total;
    }
    if (params.node.data.SubComponent === 'Total Direct Costs SCM') {
      return params.data.ActualTotal;
    }
    else {
      let total = 0;

      if (params.api != null) {
        let permek = 0;

        let actualMeks = 0;

        switch (params.api.gridOptionsWrapper.environment.eGridDiv.id) {

          case 'refLabourGrid':
          case 'refOverheadGrid':
          case 'refSpecialGrid':
            actualMeks = this.EQTargetCostRefLabourMek ?? 0
            break;

          case 'tarLabourGrid':
          case 'tarOverheadGrid':
          case 'tarSpecialGrid':
            actualMeks = this.EQTargetCostTarLabourActualMek ?? 0
            break;


          case 'optALabourGrid':
          case 'optAOverheadGrid':
          case 'optASpecialGrid':
            actualMeks = this.EQTargetCostOptALabourActualMek ?? 0
            break;

          case 'optBLabourGrid':
          case 'optBOverheadGrid':
          case 'optBSpecialGrid':
            actualMeks = this.EQTargetCostOptBLabourActualMek ?? 0
            break;

          case 'optCLabourGrid':
          case 'optCOverheadGrid':
          case 'optCSpecialGrid':
            actualMeks = this.EQTargetCostOptCLabourActualMek ?? 0
            break;

          default:
            actualMeks;
            break;
        }

        if (params.node.data.ActualPercentOfMEK > 0) {
          permek = Number(params.data.ActualPercentOfMEK) / 100;
          total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * permek * actualMeks;
          //abs = Number(node.data.Absolute) + abs

        }
        else {
          total = Number(params.data.TargetQuantity) * Number(params.data.TargetWeight) * Number(params.data.ActualAbsolute);
        }
      }
      params.data.ActualTotal = total;
   
      return total;

    }
  };

  private addOrUpdateRow(array: any[], row: any): void {
    const index = array.findIndex((r) => (r.ComponentId === row.ComponentId && row.ComponentId != undefined) && r.SubComponent === row.SubComponent);
    if (index > -1) {
      array[index] = row; // Update existing entry
    } else {
      array.push(row); // Add new entry
    }
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  ngOnDestroy(): void {
    this.configSub.unsubscribe();
  }
}
// used to format the cell in germman currency format;
function CurrencyCellRenderer(params: any) {
  if (params.node.data.SubComponent == 'Total Labor Costs' && params.node.data.SubComponent == 'Total Overhead Costs' && params.node.data.SubComponent == 'Total Special Direct Costs' && params.node.data.SubComponent == 'Total Direct Costs SCM')
    return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(0)));
  else
    return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
}


function ActualDeltaTCVsACT(params) {
  if (params.node.data.SubComponent != 'Total Labor Costs' && params.node.data.SubComponent != 'Total Overhead Costs' && params.node.data.SubComponent != 'Total Special Direct Costs' && params.node.data.SubComponent != 'Total Direct Costs SCM') {

    if (params.node.data.ActualTotal == null || Number(params.node.data.ActualTotal) == 0) {
      return 0
    } else {
      params.node.data.DeltaTCVsACT = Number(params.node.data.ActualTotal) - Number(params.node.data.ReferenceCost)
      params.node.data.DeltaTCVsACT = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsACT) / Number(params.node.data.ReferenceCost)) * 100;
    }

    params.node.data.DeltaTCVsACT = (params.node.data.DeltaTCVsACT == 0) ? 0 : params.node.data.DeltaTCVsACT
    return params.node.data.DeltaTCVsACT;
  } else if (params.node.data.SubComponent == 'Total Direct Costs SCM') {
    return null
  } else {
    let target = 0;
    let ref = 0;

    if (params.api != null) {
      params.api.forEachNode((node) => {
        target += Number(node.data.ActualTotal)
        ref += Number(node.data.ReferenceCost)
      })
      if (target == 0) { return 0 }
      params.data.DeltaTCVsACT = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
      return params.data.DeltaTCVsACT;
    }
  }
};
function ForecastDeltaFCVsRef(params) {
  if (params.node.data.SubComponent != 'Total Labor Costs' && params.node.data.SubComponent != 'Total Overhead Costs' && params.node.data.SubComponent != 'Total Special Direct Costs' && params.node.data.SubComponent != 'Total Direct Costs SCM') {

    if (params.node.data.ForecastTotal == null || Number(params.node.data.ForecastTotal) == 0) {
      return 0
    } else {
      params.node.data.DeltaFCVsRef = Number(params.node.data.ForecastTotal) - Number(params.node.data.ReferenceCost)
      params.node.data.DeltaFCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaFCVsRef) / Number(params.node.data.ReferenceCost)) * 100;
    }

    params.node.data.DeltaFCVsRef = (params.node.data.DeltaFCVsRef == 0) ? 0 : params.node.data.DeltaFCVsRef
    return params.node.data.DeltaFCVsRef;
  } else if (params.node.data.SubComponent == 'Total Direct Costs SCM') {
    return null
  } else {
    let target = 0;
    let ref = 0;

    if (params.api != null) {
      params.api.forEachNode((node) => {
        target += Number(node.data.ForecastTotal)
        ref += Number(node.data.ReferenceCost)
      })
      if (target == 0) { return 0 }
      params.data.DeltaFCVsRef = (ref == 0) ? 0 : ((target - ref) / ref) * 100;
      return params.data.DeltaFCVsRef;
    }
  }
};

// };
