<!-- <p>eq-taretcosting-calc-popup works!</p> -->
<div class="col-md-12 col-lg-12" style="background-color: #CF4B00; margin-top: 0; height: 35px; margin-bottom: 10px;">
    <label class="label-headercolor label-text" style="padding-bottom: 0 !important;">Calculation </label>
</div>

  <div class="card-body" style="padding: 10px">
    <ag-grid-angular
      id="userId"
      style="width: 100%; height: 162px"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      (gridSizeChanged)="onGridSizeChanged($event)"
      [enableBrowserTooltips]="true"
      [domLayout] ="domLayout"
      [rowHeight]="30"
    >
    </ag-grid-angular>
  <mat-dialog-actions align="end">
    <button class="button button--small" mat-dialog-close>
        Cancel</button>&nbsp;
    <button class="button button--small" (click)="onSubmit($event)" >
        Save</button>&nbsp;
</mat-dialog-actions>
  
  <!-- <button (click)="save()">Save</button> -->