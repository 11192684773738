export enum EnumMasterType {
    OrganizationUnits = 1,
    ProductHierarchy = 2,
    MeasureType = 3,
    MeasureCategory = 4,
    CostImpact = 5,
    MeasureStatus = 6,
    Commodity = 7,
    SubCommodity = 8,
    CostType = 9,
    ComplexityLevel = 10,
    Impact = 11,
    Probability = 12,
    Role = 13,
    GeneralSetting = 14,
    Menu = 15,
    Products = 16,
    BusinessArea = 17,
    BusinessLine = 18,
    OPWFilter = 19,
    OPWSubFilter = 20,
    OPWTag = 21,
    TCMenu = 22
}