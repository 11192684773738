<main>
  <app-progress-spinner *ngIf="!user"></app-progress-spinner>
  <div *ngIf="user" class="container" style="padding:0px;overflow: hidden;width:100%;height: 100%;">
    <div class="homeImage" style="position: fixed;top: 70px;bottom: 0px;right: 0px;left: 0px;">
      <div class="row">
        <div class="col-md-6 col-lg-6" *ngIf="isProductivityVisible">
          <span
            style="font-size: 24px; font-family: Calibri, sans-serif !important; font-weight: 800; color: white; margin-left: 30px; line-height: 40px;">Productivity</span>
        </div>
        <div class="col-md-6 col-lg-6" *ngIf="isTargetVisible">
          <span
            style="font-size: 24px; font-family: Calibri, sans-serif !important; font-weight: 800; color: white; margin-left: 30px; line-height: 40px;">Target
            Costing</span>
        </div>
      </div>

      <div class="row" style="cursor: pointer;">
        <div *ngIf="isProductivityVisible" class="col-md-6 col-lg-6">
          <div class="row">
            <div (click)="gotoCreateIdea()" class="col-md-4 col-lg-4"
              style="height:65px;background-color:white; margin-left: 40px;">
              <button title="Create Idea" style="padding:10px">
                <img alt="ideaIcon" src="../assets/icons/idea.svg" width="50" height="50" style="padding-bottom: 6px;">
                <span style="margin-left:10px;font-weight: bold;">Create Idea</span>
              </button>
            </div>
            <div (click)="gotoManageIdea()" class="col-md-4 col-lg-4"
              style="height:65px;background-color:white; margin-left: 5px">
              <button title="Manage Ideas" style="padding:10px">
                <img alt="createIcon" src="../assets/create.svg" width="50" height="50" style="padding-bottom: 6px;">
                <span style="margin-left:10px;font-weight: bold;">Manage Ideas</span>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="isTargetVisible && isProductivityVisible"  class="vertical-line"></div>

        <div *ngIf="isTargetVisible" class="col-md-6 col-lg-6">
          <div class="row">
            <div (click)="goToCreateProject()" class="col-md-4 col-lg-4"
              style="height:65px; background-color:white;margin-left: 40px;">
              <button title="Create Project" style="padding:10px">
                <img alt="ideaIcon" src="../assets/icons/idea.svg" width="50" height="50" style="padding-bottom: 6px;">
                <span style="margin-left:10px;font-weight: bold;">Create Project</span>
              </button>
            </div>

            <div (click)="gotoManageProject()" class="col-md-4 col-lg-4"
              style="height:65px; background-color:white; margin-left:5px">
              <button title="Manage Project" style="padding:10px">
                <img alt="createIcon" src="../assets/create.svg" width="50" height="50" style="padding-bottom: 6px;">
                <span style="margin-left:10px;font-weight: bold;">Manage Project</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row" style="position: fixed;bottom: 50px;right: -20px;left: 0;">
        <div class="offset-9 col-md-1 col-lg-1" style="margin-right: -40px;float: right;">
          <button (click)="gotoProcessGuide()" title="Process Guide">
            <img alt="infoIcon" src="../assets/info.svg" width="50" height="50">
            <figcaption style="padding:0px;text-align: center;font-weight: bold;color:white"> Process <br>Guide
            </figcaption>
          </button>
        </div>
        <div class=" col-md-1 col-lg-1" style="margin-right: -30px;">
          <button (click)="gotoLegalTerms()" title="Legal terms">
            <img alt="legalIcon" src="../assets/legal.svg" width="50" height="50">
            <figcaption style="padding:0px;text-align: center;font-weight: bold;color:white"> Legal <br>Terms
            </figcaption>
          </button>
        </div>
        <div class="col-md-1 col-lg-1" style="padding:0px" *ngIf="isMasterDataManagementVisible">
          <button (click)="gotoMasterData()" title="Admin Login">
            <img alt="adminIcon" src="../assets/admin.svg" width="50" height="50">
            <figcaption style="padding:0px;text-align: center;font-weight: bold;color:white"> Admin <br>Login
            </figcaption>
          </button>
        </div>
      </div>
    </div>
  </div>
</main>