import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { messages } from '../popUpMessages/messages';
import { serverMessage } from '../popUpMessages/serverMessage';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToasterService } from '../services/toaster.service';
import { SharedServiceService } from '../services/shared-service.service';
import { UserInfoService } from '../services/user-info.service';
import { ConfigurationService } from '../services/configuration.service';
import { LocalStorageService } from '../services/local-storage.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-lock-icon',
  templateUrl: './lock-icon.component.html',
  styleUrls: ['./lock-icon.component.css']
})
export class LockIconComponent implements ICellRendererAngularComp {
  public lockVisible: any = true;
  public isLock: any = false;
  public gridApi: any;
  public params: any;
  public user: any;
  public passedData: any;
  public objectOfGrid: any;
  public incomingValue: any;
  public statuscode: any;
  public errorMessage = [];
  public response: any;
  projectId: any;

  constructor (
    private router: Router,
    private toast: ToasterService,
    private dialog: MatDialog,
    private sharedService: SharedServiceService,
    private userService: UserInfoService,
    private configurationService: ConfigurationService,
    private localStorageService: LocalStorageService
  ) { }


  refresh(): boolean {
    return false;
  };

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;    
    this.sharedService.loggedInUser.subscribe(user => {
      this.user = user;
      if (this.user) {

        if (this.params.data.hasOwnProperty("IsEditable")) {
          this.lockVisible = this.params.data.IsEditable;
        }
        this.isLock = this.params.data.IsLock;
        if (this.params.data.hasOwnProperty("LockVisible")) {
          this.lockVisible = this.params.data.LockVisible;
        }
      }
    })

  }

  invokeLockMethod(params: any) {
    if (params.data.Id != undefined) {
      let error: string; 
      if (params.data.IsLock) error = messages.unlockTcConfiguration + params.data.Id + '?';
      else error = messages.lockTcConfiguration + params.data.Id + '?';
      // opens the dialog box for confirmation with two button Yes and No
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.configurationService.lockUnlockConfiguration({ 'ConfigurationId': params.data.Id, 'UserId': this.user.UserId }).subscribe((data) => {
            this.response = data;
            let successCode = this.response.StatusCode;
            let errorCode = this.response.Data.ErrorCode;
            let errorMsgeFromBack = this.response.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                const itemToUpdate: any[] = [];
                this.gridApi.forEachNode(function (rowNode, index) {
                  const data = rowNode.data;
                  if (data.Id == params.data.Id) {
                    data.IsLock = !data.IsLock
                    itemToUpdate.push(data);
                  }
                });
                const res = this.gridApi.applyTransaction({ update: itemToUpdate })!;
                this.gridApi.redrawRows(params);

                if (params.data.IsLock)
                  this.toast.notify(serverMessage.projectSearch.dataLocked, "success");
                else
                  this.toast.notify(serverMessage.projectSearch.dataUnlocked, "success");                
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
              }
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          },
            (error) => {
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            }
          )
        }
      });
    }
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
}
