<div class="comment-section ">
    <div class="label-heading">
        <label class="label-commentheadercolor">Discussion</label>
    </div>
    <br>
    <!-- <div style="width: 545px; height: 500px;">
        <quill-editor placeholder="Add a comment" required style="width: 68%;height:110px;" class="formControl"
            (keyup)="onKeyUp($event)" [(ngModel)]="newComment" role="textbox" name="comment"></quill-editor>

    </div> -->
    <form (ngSubmit)="addComment()" style="height: 100px; width: 70%; padding-left: 0%;">
        <!-- <textarea [(ngModel)]="newComment" role="textbox" name="comment" placeholder="Add a comment" required
            style="width: 440px;height:110px;" class="formControl" (keyup)="onKeyUp($event)" multiple>

            </textarea> -->
        <quill-editor placeholder="Add a comment" required class="formControl" (keyup)="onKeyUp($event)"
            [(ngModel)]="newComment" role="textbox" name="comment" required></quill-editor>
        <mat-option class="font-control" *ngFor="let user of submitterSearchData" [value]="user.Email"
            (click)="onEmailclick(user.Email)">
            {{ user.Email }}
        </mat-option>
        <button class="button commentbutton" style="margin-right: 5px; margin-top: 5px;" type="submit"
            (click)="onCancel()">Clear Comment</button>
        <button class="button commentbutton" style="margin-right: 5px; margin-top: 5px;" type="submit">Save
            Comment</button>
    </form>

    <!-- <ul>
        <li *ngFor="let comment of comments">{{ comment }}</li>
    </ul> -->
    <br><br>
    <div style="padding-top: 8%;">
        <div *ngFor="let comment of selectedCommentsData">
            <mat-card clas="comment-card" style="height: 100px">
                <mat-card-header>
                    <mat-card-title>{{comment.UserName}}</mat-card-title> <mat-card-content>{{comment.CreatedOn}}</mat-card-content> 
                </mat-card-header>
                <mat-card-content>
                    <!-- <p>{{comment.CommentText}}</p> -->
                    <div [innerHTML]="comment.CommentText"></div>
                </mat-card-content>

            </mat-card>
        </div>
    </div>

</div>