<app-progress-spinner *ngIf="!showGrid || submitted"></app-progress-spinner>
<div class="container">
  <div class="col-md-12 col-lg-12 label-header" style="height: 34.66px;">
    <label class="label-headercolor label-text">Define Reference configuration and weight </label>
  </div>
  <div class="card-body" style="padding: 10px">
    <div class="form-row">
      <!-- <div class="form-group col-md-12 col-lg-6">
        <nav class="navbar navbar-light bg-light">
          <form class="form-inline col-12">
            <input class="form-control col-12" type="search" placeholder="Select Product to select VBLOs"
              aria-label="Search" (click)="openKmatDialog()" [disabled]="configurationData?.configurationId > 0">
          </form>
        </nav>
      </div>
      <div class="form-group col-md-6">

      </div> -->
      <div style="width: 70.9%; float: right; margin-left: 10.1px;height: 160px;">
        <div id="product" class="div-tree">
          <div class="label-header" style="width: 48.95%; border-radius: 3px;margin-left:8px; position: sticky; top: 0px; z-index: 1;">
            <label class="label-headercolor" style="padding-bottom: 0px">Selection
              <button (click)="addNewConfiguration()" aria-label="Add">
                <img alt="Plus Icon" src="../assets/icons/Plus.svg" width="25" height="25" />
              </button>
              to create new VBLO manually </label>
          </div>
          <mat-tree *ngIf="showTree" [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree"
            role="tree" style="border-radius: 5px;">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
              <ul role="list">
                <li class="mat-tree-node">
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <button aria-label="checkbox" mat-icon-button disabled></button>
                  <mat-checkbox class="checklist-leaf-node checkbox-color"
                    (change)="todoItemSelectionToggle($event.checked,node,$event)"
                    [checked]="node.selected">{{node.VBLOName}}
                  </mat-checkbox>
                </li>
              </ul>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <ul role="list">
                <li>
                  <div class="mat-tree-node">
                    <button aria-label="Arrows" mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'toggle ' + node.KMATName">
                      <img alt="Right_Arrow" *ngIf="treeControl.isExpanded(node)==false"
                        src="../assets/icons/RightArrow.svg" height="15" width="15" style="margin-bottom: 3px;">
                      <img alt="Down_Arrow" *ngIf="treeControl.isExpanded(node)==true"
                        src="../assets/icons/DownArrow.svg" height="15" width="15" style="margin-bottom: 3px;">
                    </button>
                    <mat-checkbox [checked]="node.selected" class="checkbox-color"
                      [indeterminate]="node.indeterminate && !node.selected"
                      (change)="todoItemSelectionToggle($event.checked,node,$event)">{{node.KMATName}}</mat-checkbox>
                  </div>

                </li>
              </ul>
              <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="list">
                <li>
                  <ng-container matTreeNodeOutlet></ng-container>
                </li>
              </ul>
            </mat-nested-tree-node>
          </mat-tree>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="height: 65px;">
    <div class="offset-6 col-md-6" style="margin-top: 33%;">
      <div class="buttonStyle" style="float: right; margin-right: 5px; margin-bottom: 20px">
        <button class="button button--small" (click)="onClickCancel()">
          Cancel & Exit</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
          Back</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
          Next</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="submit($event)" type="submit">
          Save & Exit
        </button>
      </div>
    </div>
  </div>
</div>