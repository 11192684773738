import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OverheadService {

  constructor(
    private http:HttpClient
  ) { }

  private urlGetOverheadData: string = environment.baseURL + "api/TCOverheadCost/GetAllOverheadCost";
  private urlPostOverheadData: string = environment.baseURL + "api/TCOverheadCost/InsertOrUpdateBulkDataForOverheadCost";

  getAllOverheadCostData(businessAreaId?: number, businessLineId?: number){
    return this.http.get<any>(this.urlGetOverheadData+ '?businessAreaId=' + businessAreaId + '&businessLineId=' + businessLineId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postData(data){
    const httpOptions= {
      headers : new HttpHeaders({'Content-Type' : 'application/json'})
    }
    return this.http.post<any>(this.urlPostOverheadData, data, httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  handleError(error: HttpErrorResponse){
    return throwError(error || "Server Error");
  }
}
