import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CheckboxComponent implements ICellRendererAngularComp {
  // @Input() isChecked: boolean = false;
  // @Output() isCheckedChange = new EventEmitter<boolean>();
  public params: any;
  public checkVisible: boolean = false;
  gridApi: any;
  //public IsChecked: boolean = false;
  checked :boolean = false;

  

  // refresh the grid with new value;
  refresh(params: any): boolean {
    // this.isChecked = !this.isChecked;
    // this.isCheckedChange.emit(this.isChecked); 
    if (params.colDef.field == "OnLegalHold") {
      params.data.OnLegalHold = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    if (params.colDef.field == "IsChecked") {
      params.data.IsActive = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    if (params.colDef.field == "IsFloor") {
      params.data.IsActive = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    if (params.colDef.field == "IsCeiling") {
      params.data.IsActive = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    if (params.colDef.field == "IsBiplane") {
      params.data.IsActive = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    if (params.colDef.field == "IsMP") {
      params.data.IsActive = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    if (params.colDef.field == "IsPheno") {
      params.data.IsActive = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    if (params.colDef.field == "IsFD") {
      params.data.IsActive = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    if (params.colDef.field == "IsII") {
      params.data.IsActive = params.value
      let checked = params.value
      let colId = params.column.colId;
      this.params.node.setDataValue(colId, checked);
    }
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    if (this.params.data.hasOwnProperty('IsEditable')) {
      this.checkVisible = !this.params.data.IsEditable;
    }

    if (this.params.data.hasOwnProperty("Id") && this.params.data.hasOwnProperty("IsEditable") && this.params.data.hasOwnProperty("IsLock") && this.params.data.hasOwnProperty("IsAdmin")) {
      this.checkVisible = !((this.params.data.IsAdmin) || (this.params.data.IsEditable && !this.params.data.IsLock));
    }
    if (this.params.data.hasOwnProperty('IsChecked')) {
      console.log("HI");
      this.checked = true;
    }
    

  }

  // update the grid data as the input is checked/unchecked;
  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }
}