<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>

<div class="form-row container">
  <div class="form-group col-md-3">
    <label for="businessArea" class="label">Business Area</label>
    <select [(ngModel)]="selectBusinessArea" (change)="updateBusinessLine()" id="businessArea" class="form-control"
      aria-label="businessArea" [disabled]="this.disableBusinessAreaDropdown == true">
      <option value="">Please select</option>
      <option *ngFor="let ba of roleBusinessArea" [value]="ba.BusinessAreaId">
        {{ba.BusinessAreaName}}
      </option>
    </select>
  </div>
  <div class="form-group col-md-3">
    <label for="businessLine" class="label">Business Line</label>
    <select [(ngModel)]="selectBusinessLine" (change)="updateYearDropdown()" id="businessLine" class="form-control"
      aria-label="businessLine" [disabled]="this.disableBusinessLineDropdown == true">
      <option value="">Please select</option>
      <option *ngFor="let bl of roleBusinessLine" [value]="bl.BusinessLineId">
        {{bl.BusinessLineName}}
      </option>
    </select>
  </div>
  <div class="form-group col-md-1">
    <label for="actualUnits" class="label">Year</label>
    <select [(ngModel)]="this.startYear" (change)="updatePeriodLockGrid()" id="year" class="form-control"
      aria-label="actualUnits">
      <option value="">Please select</option>
      <option *ngFor="let bl of this.headerYears" [value]="bl">
        {{bl}}
      </option>
    </select>
  </div>
</div>
<br>

<div class="container" *ngIf="showGrid">
  <div class="card" style="height: 100%">
    <div class="label-header" style="width: 100%; margin-top: 0px">
      <label class="label-headercolor">Period Lock</label>
    </div>
    
    <div class="card-body" style="padding: 0px; overflow: scroll;">
      <table class="card-table table" *ngIf="showTable" style="width: 100%; height: 100%">
        <caption hidden></caption>
        <tr class="ttmp catInvalid">
          <th scope="row" class="td-header">
            <b>Calender Month</b>
          </th>
          <th scope="row" class="td-cell" *ngFor="let month of monthsArray; let i = index">
            {{month}}
          </th>
        </tr>
        <tr class="ttmp catInvalid">
          <th scope="row" class="td-header">
            <b>Fiscal Year</b>
          </th>
          <th scope="row" class="td-cell" *ngFor="let year of fiscalYearArr; let i = index">
            {{year}}
          </th>
        </tr>
        <tr class="ttmp catInvalid">
          <th scope="row" class="td-header">
            <b>Reporting Period</b>
          </th>
          <th scope="row" class="td-cell" *ngFor="let reportingPeriod of reportingPeriodArr; let i = index">
            {{reportingPeriod}}
          </th>
        </tr>
      </table>
    </div>

    <div class="label-header" style="width: 100%; margin-top: 0px" *ngIf="isEditablePeriodLock">
      <label class="label-headercolor">Productivity</label>
    </div>

    <div class="card-body" style="padding: 0px; overflow: scroll;">
      <table class="card-table table" *ngIf="showTable && isEditablePeriodLock" style="width: 100%; height: 100%">
        <caption hidden></caption>
        <tr class="ttmp catInvalid">
          <td class="td-header" style="text-align: left;
          vertical-align: middle;
          font-weight: bold;">
            Lock Date
          </td>
          <td class="td-cell" *ngFor="let date of lockDateArray; let i = index">
            <input class="ttmp catInvalid" type="text" aria-label="datepicker" [matDatepicker]="picker"
              placeholder=" mm/dd/yyyy" value="{{date}}" (dateChange)="modifiedLockDateData($event, i)" />
            <mat-datepicker-toggle class="mat-button-wrapper" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </td>
        </tr>
        <tr class="ttmp catInvalid">
          <td class="td-header" style="text-align: left;
          vertical-align: middle;
          font-weight: bold;">
            Lock Time (UTC)
          </td>
          <td class="td-cell" *ngFor="let time of lockTimeArray; let i= index">
            <!-- {{time}} -->
            <input type="time" id="time{{i}}" name="LockTime" style="width:90px" value="{{time}}"
              (change)="modifiedLockTimeData(i)">
          </td>
        </tr>
        <tr class="ttmp catInvalid">
          <td class="td-header" style="text-align: left;
          vertical-align: middle;
          font-weight: bold;">
            Lock Status
          </td>
          <td class="td-cell" *ngFor="let status of lockStatusArray; let i = index">
            <div>
              <mat-checkbox checked="{{status}}" (change)="modifiedLockStatusData($event, i )">
              </mat-checkbox>
            </div>
          </td>
        </tr>
        <tr class="ttmp catInvalid">
          <td class="td-header" style="text-align: left;
          vertical-align: middle;
          font-weight: bold;">
            <!-- In Code it is referred as U9Date. -->
            Download OPW Date
          </td>
          <td class="td-cell" *ngFor="let date of u9DateArray; let i = index">
            <input class="ttmp catInvalid" type="text" aria-label="datepicker" [matDatepicker]="picker" placeholder=" mm/dd/yyyy"
               value="{{date}}" (dateChange)="modifiedU9DateData($event, i)" />
            <mat-datepicker-toggle matSuffix [for]="picker" class="mat-button-wrapper"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </td>
        </tr>
        <tr class="ttmp catInvalid">
          <td class="td-header" style="text-align: left;
          vertical-align: middle;
          font-weight: bold;">
            <!-- In Code it is referred as U9Time. -->
            Download OPW Time (UTC)
          </td>
          <td class="td-cell" *ngFor="let time of u9TimeArray; let i= index">
            <input type="time" id="U9time{{i}}" name="U9Time" style="width:90px" value="{{time}}"
              (change)="modifiedU9TimeData(i)">
          </td>
        </tr>
      </table>
    </div>

    <div class="label-header" style="width: 100%; margin-top: 0px" *ngIf = "isEditableTC">
      <label class="label-headercolor">Target Costing</label>
    </div>

    <div class="card-body" style="padding: 0px; overflow: scroll;">
      <table class="card-table table" *ngIf="showTable && isEditableTC" style="width: 100%; height: 100%" >
        <caption hidden></caption>
        <tr class="ttmp catInvalid">
          <td class="td-header" style="text-align: left;
          vertical-align: middle;
          font-weight: bold;">
            Lock Date
          </td>
          <td class="td-cell" *ngFor="let date of targetCostingLockDateArray; let i = index">
            <input class="ttmp catInvalid" type="text" aria-label="datepicker" [matDatepicker]="picker"
              placeholder=" mm/dd/yyyy" value="{{date}}" (dateChange)="modifiedTargetCostingLockDateData($event, i)" />
            <mat-datepicker-toggle class="mat-button-wrapper" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </td>
        </tr>
        <tr class="ttmp catInvalid">
          <td class="td-header" style="text-align: left;
          vertical-align: middle;
          font-weight: bold;">
            Lock Time (UTC)
          </td>
          <td class="td-cell" *ngFor="let time of targetCostingLockTimeArray; let i= index">
            <!-- {{time}} -->
            <input type="time" id="TCLocktime{{i}}" name="TCLockTime" style="width:90px" value="{{time}}"
              (change)="modifiedTargetCostingLockTimeData(i)">
          </td>
        </tr>
        <tr class="ttmp catInvalid">
          <td class="td-header" style="text-align: left;
          vertical-align: middle;
          font-weight: bold;">
            Lock Status
          </td>
          <td class="td-cell" *ngFor="let status of targetCostingLockStatusArray; let i = index">
            <div>
              <mat-checkbox checked="{{status}}" (change)="modifiedTargetCostingLockStatusData($event, i )">
              </mat-checkbox>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="row">
      <div class="offset-4 col-md-8">
        <div class="buttonStyle">
          <button class="button button--small" style="margin-right: 5px" type="submit" (click)="onCancel()">
            Cancel
          </button>
          <button class="button button--small" type="submit" (click)="submit('true')">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

</div>