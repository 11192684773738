import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-quicklinks',
  templateUrl: './quicklinks.component.html',
  styleUrls: ['./quicklinks.component.css']
})
export class QuicklinksComponent implements ICellRendererAngularComp {
  isEditVisible: boolean = true;
  params: any;
  gridApi: any;

  constructor(
    private router: Router,
    private sharedService: SharedServiceService,
    private localStorage: LocalStorageService,
  ) { }

  refresh(): boolean {
    return false;
  }

  agInit(params: any) {
    this.params = params;
    this.gridApi = params.api; 
    this.isEditVisible = !params.data.IsLock;
    if (params.data.Id == 0) 
      this.isEditVisible = false;
  }

  goToPage(params) {
    this.sharedService.changeConfigId(0);
    this.localStorage.set("ConfigId", params.data.Id);
    if (params.colDef.field == "eq") {
      this.router.navigate(["/tc-eq"]);
    }
    if (params.colDef.field == "cs") {
      this.router.navigate(["/tc-cs"]);
    }
    if (params.colDef.field == "scm") {
      this.router.navigate(["/tc-scm"]);
    }
    if (params.colDef.field == "lc") {
      this.router.navigate(["/tc-localcosts"]);
    }
    if (params.colDef.field == "inv") {
      this.router.navigate(["/tc-investmentplan"]);
    }
    if (params.colDef.field == "tdc") {
      this.router.navigate(["/plc-tc-directcost"]);
    }
    if (params.colDef.field == "plc") {
      this.router.navigate(["/plc-tc-summary"]);
    }
  }
}
