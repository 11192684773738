import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { CreateManualVBLODTO } from 'src/app/models/CreateManualVBLODTO';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ConfigurationVBLOComponent } from '../../configuration-vblo/configuration-vblo.component';
import { ProjectService } from 'src/app/services/project.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-configuration-mvblo',
  templateUrl: './configuration-mvblo.component.html',
  styleUrls: ['./configuration-mvblo.component.css']
})
export class ConfigurationMvbloComponent implements OnInit {
  projectIdFromLocal: string;
  public dataToSend = new CreateManualVBLODTO();
  title: string;
  public configurationForm: FormGroup;
  hasUnsavedChanges: boolean;
  submitterId: any;
  public userInOrg: any;
  submitResponse: any;
  submitted: boolean;
  public kmatData: any;
  projectId: any;
  businessAreaId: any;
  //sharedService: any;
  businessLineId: any;
  showGrid: boolean;
  public allUserList: any;
  projectSubmitterId: any;
  recievedData: any;
  vbloTypeList:any = [];
  assemblyList:any = [];

  constructor(
    private configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private sharedService: SharedServiceService,
    private toast: ToasterService,
    private localStorageService: LocalStorageService,
    public dialogRef: MatDialogRef<ConfigurationMvbloComponent>,
    public dialog: MatDialog,
    private projectService: ProjectService,
    private userService: UserInfoService,
    @Inject(MAT_DIALOG_DATA) public selectedKmat: ConfigurationVBLOComponent
  ) {

  }

  ngOnInit(): void {
    this.kmatData = this.selectedKmat;
    console.log(this.kmatData);
    this.projectId = this.localStorageService.get("projectId");
    if (this.projectId != null) {
      this.projectService.getTCData(this.projectId).subscribe((data: any) => {
        if (data.StatusCode == 200) {
          this.businessAreaId = data.Data.BusinessAreaId;
          this.businessLineId = data.Data.BusinessLineId;
          this.sharedService.loggedInUser.subscribe((user: any) => {
            if (user) {
              this.showGrid = true
              this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
                this.allUserList = d;
                this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
                if (this.userInOrg != undefined) {
                  console.log("user", this.userInOrg, data.Data);
                  this.projectSubmitterId = this.userInOrg.UserId;
                }
              })
            }
          })
        }
      });
    } else {
      this.businessAreaId = this.businessLineId = 0;
    } 
    this.configurationService.GetVBLOTypeAndAssemblyList().subscribe((data:any) => {
      if(data.StatusCode == 200){
        console.log(data);
        this.recievedData = data.Data;
        this.vbloTypeList = this.recievedData.VBLOTypes;
        this.assemblyList = this.recievedData.Assemblies;
      }
    })
    this.loadFormStructure();
  }

  loadFormStructure() {
    this.configurationForm = this.formBuilder.group({
      // vbloamount: [''],
      // vbloprice: [''],
      // dateVBLOMat: [''],
      // vblokreditor: [''],
      title: ['', [Validators.required, validateSpaceOnly]],
      vblotype: ['', [Validators.required]],
      assembly: ['', [Validators.required]],
    });
  }
  onSubmit(event?: any) {
    let response = null;
    if (event == undefined) {
      return true
    }

    let formerror = [];
    if (this.configurationForm.get("title").value.trim() == "") {
      formerror.push({ "VBLO Title": messages.cellEmptyErrorMesssage })
    }
    if (this.configurationForm.get("vblotype").value.trim() == "") {
      formerror.push({ "VBLO Type": messages.cellEmptyErrorMesssage })
    }
    if (this.configurationForm.get("assembly").value.trim() == "") {
      formerror.push({ "Assembly": messages.cellEmptyErrorMesssage })
    }
     
    if (formerror.length) {
      this.openAlertDialog(formerror);
    }
    else {
      response = this.configurationService.postMVBLOData(this.sendData()).toPromise().
        then((res) => {
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            if (errorCode == 0) {
              this.hasUnsavedChanges = false;
              let dataSaveMessage = serverMessage.dataSaveMessage;
              this.toast.notify(dataSaveMessage, "success");
              /// this.localStorageService.set("projectId", this.submitResponse.Data.SuccessId);
              if (event != undefined) {
                this.hasUnsavedChanges = false;
                //this.router.navigate(['/tc-manageProject'])
              }
              this.dialogRef.close("success");
              return true;
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          }
          else {
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }
        },
          (error) => {
            this.submitted = false;
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            return false;
          })
    }
    return response;
  }


  sendData() {
    let projectIdUpdate = 0;

    if (this.projectIdFromLocal != "null") {
      projectIdUpdate = Number(this.projectIdFromLocal)
    }

    this.dataToSend.VbloText = this.configurationForm.get("title").value.trim();
    this.dataToSend.Amount = null;
    this.dataToSend.Date = null;
    this.dataToSend.SubmitterId = this.submitterId;
    this.dataToSend.Price = null;
    this.dataToSend.Kreditor = "";
    this.dataToSend.Type = this.configurationForm.get("vblotype").value.trim();
    this.dataToSend.Assembly = this.configurationForm.get("assembly").value.trim();
    this.dataToSend.KmatId = this.kmatData[0].KMATId;
    this.dataToSend.CreatedBy = (this.userInOrg == undefined) ? this.projectSubmitterId : this.userInOrg.UserId;
    this.dataToSend.BusinessAreaId = this.businessAreaId;
    this.dataToSend.BusinessLineId = this.businessLineId;
    
    return this.dataToSend;
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

}

// vallidates if only white space is present into the input field;
function validateSpaceOnly(check: FormControl) {
  let regEx = /^ *$/;
  let value = !regEx.test(check.value);
  return value ? true : 'required';
}

