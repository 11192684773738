import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { HelpService } from 'src/app/services/help.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { NestedTreeControl } from "@angular/cdk/tree";
import { ProjectService } from 'src/app/services/project.service';
import { messages } from 'src/app/popUpMessages/messages';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { ConfigurationMvbloComponent } from '../configuration-MVBLO/configuration-mvblo/configuration-mvblo.component';
//import { ConfigurationMvbloComponent } from '../configuration-mvblo/configuration-mvblo.component';

import { KMATHierarchyDto } from 'src/app/models/KMATHierarchyDto';
import { ConfigurationKmatComponent } from '../configuration-kmat/configuration-kmat.component';

interface Node {
  name: string;
  id?: number;
  selected?: boolean;
  indeterminate?: boolean;
  children?: Node[];
  type?: string;
}
@Component({
  selector: 'app-configuration-vblo',
  templateUrl: './configuration-vblo.component.html',
  styleUrls: ['./configuration-vblo.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationVBLOComponent implements OnInit {

  public rowData: any = [];
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowHeight: any;
  public headerHeight: any;
  public Editable: boolean = true;
  public alertErrorBox = [];
  public errorMessage = []
  public loggedInUser: any;
  public getResponse: any;
  public frameworkComponents: any;
  public idGenerated: number = 1;
  public dataSource = new MatTreeNestedDataSource<Node>();
  public treeControl = new NestedTreeControl<Node>(node => node.children);
  public sizeToFitRefresh: number = 0;
  public showGrid = false;
  submitResponse: any;
  receivedData: any;
  showTree: boolean;
  public selectedDataKmat: any;
  public selectedNode: any;
  public flatdata = [];
  public projectIdFromLocal: any;
  public addedVblo: any = [];
  public removedVblo: any = [];
  recKmatVblo: any;
  dbSelectedNodeIds: any = [];
  hasUnsavedChanges: boolean;
  public submitted: boolean = false;
  public kmatData: any;
  public kmatVbloData: any;
  public configurationData: any;
  submitClicked: boolean;
  projectId: any;

  constructor(
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private router: Router,
    private configuration: ConfigurationService,
  ) { }

  ngOnInit(): void {
    this.sharedService.getProjectIdAndTitle();
    this.projectId = this.localStorageService.get("projectId");
    console.log(this.projectId);
    this.loadAllData();

  }
  loadAllData() {
    this.showGrid = false;

    this.configurationData = this.localStorageService.get("configuration");
    console.log(this.configurationData);
    this.kmatData = this.configurationData.selectedKmat;

    this.configuration.getVBLOData(this.projectId, this.configurationData.configurationId, this.kmatData).subscribe((data: any) => {
      this.showGrid = true;
      let errorMsgeFromBack = data.Data.Message;
      if (data.StatusCode == 200) {
        this.kmatVbloData = data.Data.map(d => {
          return {
            KMATId: d.KMATId,
            KMATName: d.KMATName,
            children: d.VBLOs,
          }
        });

        this.recKmatVblo = this.kmatVbloData[0].children.filter(c => c.IsLinkedToConfiguration);

        let selectedKmat = [];
        for (let i of this.recKmatVblo) {
          selectedKmat.push({ id: i.KMATVBLOId });
          //this.ImProdIdSelData.push({ id: i.KMATVBLOId, ImpactedProductId: i.VBLOName });
        }
        this.selectedDataKmat = selectedKmat;

        this.loadTree();

      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    },
      (error) => {
        // this.hideButton = true;
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      })

  }
  addNewConfiguration() {
    let dialogRef = this.dialog.open(ConfigurationMvbloComponent, {
      // maxHeight: '60vh',
      // maxWidth: '40vw',
      height: '35%',
      width: '40%',
      panelClass: 'kmat-dialog-container',
      data: this.kmatVbloData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != "" && result != undefined) {
        console.log(result);
        this.loadAllData();
      }
    });
  }

  loadTree() {
    this.showTree = true;
    this.flatdata = [];
    this.addedVblo = [];
    this.removedVblo = [];
    this.dataSource.data = this.kmatVbloData;
    Object.keys(this.dataSource.data).forEach(x => {
      this.setParent(this.dataSource.data[Number(x)], null);
    });
    this.getFlatData(this.dataSource.data);
    this.treeControl.expand(this.flatdata[0]);

    if (this.configurationData.configurationId > 1)
      this.getSelectedNode(this.flatdata);
    else {
      this.kmatVbloData[0].children.forEach(node => {
        node.selected = true;
        this.checkAllParents(node);
        this.manageModifiedTreeData(true, node);
      })
    }
  }
  hasChild = (_: number, node: Node) =>
    !!node.children && node.children.length > 0;
  // To set the parent of a node and make tree like structure
  setParent(data: any, parent: any) {
    data.parent = parent;
    if (data.children) {
      data.children.forEach((x: any) => {
        this.setParent(x, data);
      });
    }
  }

  /* To fetch the selected nodes from database and to autoselect and autoexpand them*/
  getSelectedNode(data: any) {
    this.selectedNode = this.selectedDataKmat;
    this.selectedNode.forEach((node: any) => {
      //this.expand(data, node.id);
      data.forEach((d: any) => {
        if (d.KMATVBLOId == node.id) {
          d.selected = true;
          this.dbSelectedNodeIds.push(node.id);
          this.todoItemSelectionToggle(true, d);
        }
      })
    })
  }

  // toggle node selection
  todoItemSelectionToggle(checked: any, node: any, event?: any) {
    if (event != undefined) { //if the product node is selected/unselected from UI
      this.hasUnsavedChanges = true;
    }
    this.manageModifiedTreeData(checked, node);
    node.selected = checked;

    if (node.children) {
      node.children.forEach((x: any) => {
        this.todoItemSelectionToggle(checked, x);
      });
    }
    this.checkAllParents(node);
  }

  manageModifiedTreeData(checked, node) {
    if (node.children) return;

    if (checked) {
      if (!this.dbSelectedNodeIds.includes(node.KMATVBLOId)) {
        this.getAddedVbloData(node);
      } else {
        this.removedVblo = this.removedVblo.filter(modifiedNode => {
          return modifiedNode.KMATVBLOId != node.KMATVBLOId;
        })
      }
    }
    else {
      if (!this.dbSelectedNodeIds.includes(node.KMATVBLOId)) {
        this.addedVblo = this.addedVblo.filter(modifiedNode => {
          return modifiedNode.KMATVBLOId != node.KMATVBLOId;
        })
      } else {
        this.getRemovedVbloData(node);
      }
    }
  }
  // this will give all the added vblo data;
  getAddedVbloData(node) {
    console.log(node, 4)
    let isPushed = false;
    if (this.addedVblo.length == 0) {
      this.addedVblo.push(node);
      isPushed = true;
    }
    else {
      this.addedVblo.forEach(data => {
        if (node.KMATVBLOId == data.KMATVBLOId) {
          isPushed = true;
        }
      })
    }
    if (!isPushed) {
      this.addedVblo.push(node);
    }
  }

  // this will give all the removed vblo data;
  getRemovedVbloData(node) {
    console.log(node, 3)
    let isPushed = false;
    if (this.removedVblo.length == 0) {
      this.removedVblo.push(node);
      isPushed = true;
    }
    else {
      this.removedVblo.forEach(data => {
        if (node.KMATVBLOId == data.KMATVBLOId) {
          isPushed = true;
        }
      })
    }
    if (!isPushed) {
      this.removedVblo.push(node);
    }
  }

  /* To make the immediate parent of version node fully checked 
   instead of indeterminate even if all the version aren't selected*/
  checkImmediateParent(node: any) {
    node.parent.selected = true;
    node.parent.indeterminate = false;
    this.checkAllParents(node.parent);
  }
  // To check all parent node if a particular node is selected
  checkAllParents(node: any) {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.selected = descendants.every(child => child.selected);
      node.parent.indeterminate = descendants.some(child => child.selected);
      this.checkAllParents(node.parent);
    }
  }

  /* To populate flatdata array with all the node present in the tree data 
  so that the flatdata can be passed to the getSelectedNode for easy searching of required node 
  for expand and toggle purposes*/
  getFlatData(dataSource: any) {
    dataSource.forEach((node: any) => {
      if (!node.children) {
        this.flatdata.push(node);
      }
      else if (node.children) {
        this.flatdata.push(node);
        this.getFlatData(node.children);
      }
    })
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  canDeactivate() {
    return this.submitClicked;
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(["/tc-manageProject"]);
  }

  onClickBack() {
    this.hasUnsavedChanges = false;
    this.localStorageService.remove("configuration");
    this.router.navigate(["/tc-configuration"])
  }

  onClickNext() {
    this.submit();
  }

  openKmatDialog() {
    let dialogRef = this.dialog.open(ConfigurationKmatComponent, {
      // maxHeight: '60vh',
      // maxWidth: '40vw',
      height: '60%',
      width: '40%',
      panelClass: 'kmat-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != "" && result != undefined) {
        console.log(result);
        this.localStorageService.set("selectedKmat", result);
        this.loadAllData();
      }
    });
  }

  submit(event?: any) {
    this.submitClicked = true;
    console.log("add", this.addedVblo);
    console.log("remove", this.removedVblo);

    this.addedVblo = this.addedVblo.map(v => v.KMATVBLOId);
    this.removedVblo = this.removedVblo.map(v => v.KMATVBLOId);

    this.configurationData.addedVblo = this.addedVblo;
    this.configurationData.removedVblo = this.removedVblo

    this.localStorageService.set("configuration", this.configurationData);
    this.router.navigate(['/tc-configuration'])
  }
}
