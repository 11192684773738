import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Help } from '../models/help'
import { HelpIconText } from '../models/HelpIconText'
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TCHelp } from '../models/TCHelp';
@Injectable({
  providedIn: 'root'
})
export class HelpService {
  constructor(private http: HttpClient) { }
  private urlGetbyId: string = environment.baseURL+ "api/Help/GetHelpTextByHelpId";
  private urlGet: string = environment.baseURL + "api/Help/GetAllHelpTexts";
  private urlTCGet: string = environment.baseURL + "api/Help/GetAllTCHelpTexts";
  private urlUpdate: string = environment.baseURL+ "api/Help/InsertUpdateHelp";
  private urlGetbyText: string = environment.baseURL + "api/HelpIconText/GetAllHelpIconText";
  private urlTCGetbyId: string = environment.baseURL+ "api/Help/GetTCHelpTextByHelpId";
  private urlTCUpdate: string = environment.baseURL+ "api/Help/InsertUpdateTCHelp";

  helpIdEvent=new EventEmitter();
  // This is used to get data from backend by passing HelpId;
  getDatabyId(helpId): Observable<Help> {
    let helpIdn = Number(helpId)
    return this.http.get<Help>(this.urlGetbyId + '?helpId=' + helpIdn).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  //This is used to get data from backend 
  getAllData(): Observable<Help> {
    return this.http.get<Help>(this.urlGet).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
    //This is used to get target costing help data from backend 
    getTCAllData(): Observable<TCHelp> {
      return this.http.get<TCHelp>(this.urlTCGet).pipe(
        retry(1),
        catchError(this.handleError)
      );;
    };

     // This is used to get Target Costing help data from backend by passing HelpId;
  getTCDatabyId(helpId): Observable<TCHelp> {
    let helpIdn = Number(helpId)
    return this.http.get<TCHelp>(this.urlTCGetbyId + '?helpId=' + helpIdn).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  //This is used to get data from backend for help text
  getAllText(): Observable<HelpIconText> {
    return this.http.get<HelpIconText>(this.urlGetbyText).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  //  This is used to update new help Data in Database;
  updateData(data): Observable<Help> {
    return this.http.post<Help>(this.urlUpdate, data).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

//  This is used to update new Traget costing help Data in Database;
updateTCData(data): Observable<Help> {
  return this.http.post<Help>(this.urlTCUpdate, data).pipe(
    retry(1),
    catchError(this.handleError)
  );;
};

  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
  emitHelpId(helpId){
    this.helpIdEvent.emit(helpId);
  }
}

