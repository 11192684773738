import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { EqtargetcostingService } from 'src/app/services/eqtargetcosting.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { messages } from 'src/app/popUpMessages/messages';
import { Eqcosts } from 'src/app/models/Eqcosts';
import { Eqsummaries } from 'src/app/models/EqSummaries';
import { ProjectService } from 'src/app/services/project.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { EqTaretcostingCalcPopupComponent } from '../eq-taretcosting-calc-popup/eq-taretcosting-calc-popup.component';
import { Eqtargetcosts } from 'src/app/models/EqTargetcosts';
import { EditEqCalcComponent } from '../edit-eq-calc/edit-eq-calc.component';
import { AddMaterialComponent } from '../add-material/add-material.component';

let countryCode;
let grids;

@Component({
  selector: 'app-eq-targetcosting',
  templateUrl: './eq-targetcosting.component.html',
  styleUrls: ['./eq-targetcosting.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EqTargetcostingComponent implements OnInit, OnDestroy {
  public gridApi: any;
  public gridColumnApi: any;
  public sizeToFitRefresh: number = 0;
  public showGrid = false;
  eqConfigurationData: any;

  public defaultColDef: any;
  public hasUnsavedChanges: boolean = false;
  public submitted: boolean = false;
  public alertErrorBox = [];
  TCEQTargetCostSummaries: any[] = [];
  TCEQTargetCosts: any[] = [];
  submitResponse: any;
  public businessAreaId: number;
  public businessLineId: number;
  public userInOrg: any;
  public allUserList: any;
  gmkGroup: string;
  bkFactorValue: number;
  fkFactorValue: number;

  optAVisible: boolean = false;
  optBVisible: boolean = false;
  optCVisible: boolean = false;
  isEditable: boolean = true;

  gridData: any[];
  gridDataSummary: any[];
  public modifiedGridData = [];

  configGridApi: any;
  configColumnDefs: any;
  configDefaultColDef: any;
  configGridData: any[];
  configPinnedTopData: any;
  public configFrameworkComponents;
  getRowHeightConfig: any;
  domLayoutConfig: string;

  refGridApi: any;
  refColumnDefs: any;
  refDefaultColDef: any;
  refGridData: any[];
  refPinnedTopData: any;
  public refFrameworkComponents;
  getRowHeightRef: any;
  domLayoutRef: string;

  tarColumnDefs: any;
  tarGridData: any[];
  tarDefaultColDef: any;
  tarGridApi: any;
  tarPinnedTopData: any;
  public tarFrameworkComponents;
  getRowHeightTar: any;
  domLayoutTar: string;

  optAColumnDefs: any;
  optAGridData: any[];
  optAGridApi: any;
  optAPinnedTopData: any;
  public optAFrameworkComponents;
  getRowHeightoptA: any;
  optADefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };
  OptADomLayout: string;

  optBColumnDefs: any;
  optBGridData: any[];
  optBGridApi: any;
  optBPinnedTopData: any;
  public optBFrameworkComponents;
  getRowHeightoptB: any;
  optBDefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };
  OptBDomLayout: string;

  optCColumnDefs: any;
  optCGridData: any[];
  optCGridApi: any;
  optCPinnedTopData: any;
  public optCFrameworkComponents;
  getRowHeightoptC: any;
  optCDefaultColDef: {
    suppressMovable: boolean; sortable: boolean;
    // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
    wrapText: boolean;
  };
  OptCDomLayout: string;

  refTitle: string = "";
  tarTitle: string = "";
  optATitle: string = "";
  optBTitle: string = "";
  optCTitle: string = "";

  configurationId: any;

  vbloDropdown: any[];
  componentDropdown: any[];

  configSummaryGridApi: any;
  configColumnDefsSummary: any;
  getRowHeightConfigSummary: any;
  configDefaultColDefSummary: any;
  domLayoutConfigSummary: any;

  refSummaryGridApi: any;
  refColumnDefsSummary: any;
  getRowHeightRefSummary: any;
  refDefaultColDefSummary: any;
  domLayoutRefSummary: any;
  refSummaryFrameworkComponents: any;

  tarSummaryGridApi: any;
  tarColumnDefsSummary: any;
  getRowHeightTarSummary: any;
  tarDefaultColDefSummary: any;
  domLayoutTarSummary: any;
  tarSummaryFrameworkComponents: any;

  optASummaryGridApi: any;
  optAColumnDefsSummary: any;
  getRowHeightOptASummary: any;
  optADefaultColDefSummary: any;
  domLayoutOptASummary: any;
  optASummaryFrameworkComponents: any;

  optBSummaryGridApi: any;
  optBColumnDefsSummary: any;
  getRowHeightOptBSummary: any;
  optBDefaultColDefSummary: any;
  domLayoutOptBSummary: any;
  optBSummaryFrameworkComponents: any;

  optCSummaryGridApi: any;
  optCColumnDefsSummary: any;
  getRowHeightOptCSummary: any;
  optCDefaultColDefSummary: any;
  domLayoutOptCSummary: any;
  optCSummaryFrameworkComponents: any;

  sizeToFitRefreshConfigSummary: number = 0;
  sizeToFitRefreshRefSummary: number = 0;
  sizeToFitRefreshTarSummary: number = 0;
  sizeToFitRefreshOptASummary: number = 0;
  sizeToFitRefreshOptBSummary: number = 0;
  sizeToFitRefreshOptCSummary: number = 0;
  sizeToFitRefreshConfig: number = 0;
  sizeToFitRefreshRef: number = 0;
  sizeToFitRefreshTar: number = 0;
  sizeToFitRefreshOptA: number = 0;
  sizeToFitRefreshOptB: number = 0;
  sizeToFitRefreshOptC: number = 0;
  rowClassRules: any;
  autoGroupColumnDef: any;
  groupRowRendererParamsSummary: any;
  groupRowRendererParams: any;
  frameworkComponents: any;
  configSub: any;
  objData: any;
  moduleId: Number = 6;

  constructor(
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private router: Router,
    private menuService: MenuService,
    private userService: UserInfoService,
    private eqtargetcostingService: EqtargetcostingService
  ) {
    this.frameworkComponents = {
      doublingEditor: DoublingEditorComponent,
      groupRowInnerRenderer: AddMaterialComponent,
    }

    this.groupRowRendererParamsSummary = {
      suppressCount: true,
    }

    this.groupRowRendererParams = {
      suppressCount: true,
      innerRenderer: 'groupRowInnerRenderer',
    }
  }

  ngOnInit(): void {
    this.sharedService.getProjectIdAndTitle();
    let projectId = this.localStorageService.get("projectId");
    this.objData = {
      projectId: projectId,
      moduleId: this.moduleId
    }

    countryCode = this.localStorageService.get('countryCode');

    this.sharedService.eqAddedRow.subscribe(data => {
      this.hasUnsavedChanges = true;
      this.refGridApi.applyTransaction({
        add: [data.addObject], addIndex: data.addIndex
      })
      this.tarGridApi.applyTransaction({
        add: [data.addObject], addIndex: data.addIndex
      })

      if (this.optAVisible) {
        this.optAGridApi.applyTransaction({
          add: [data.addObject], addIndex: data.addIndex
        })
      }

      if (this.optBVisible) {
        this.optBGridApi.applyTransaction({
          add: [data.addObject], addIndex: data.addIndex
        })
      }

      if (this.optCVisible) {
        this.optCGridApi.applyTransaction({
          add: [data.addObject], addIndex: data.addIndex
        })
      }
    });

    if (projectId != null) {
      this.projectService.getTCData(projectId).subscribe((data: any) => {
        if (data.StatusCode == 200) {
          this.businessAreaId = data.Data.BusinessAreaId;
          this.businessLineId = data.Data.BusinessLineId;
          this.sharedService.loggedInUser.subscribe((user: any) => {
            if (user) {
              this.showGrid = true
              this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
                this.allUserList = d;
                this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
                if (this.userInOrg != undefined) {
                  if (this.userInOrg.TargetRoleId == 1 || this.userInOrg.TargetRoleId == 2) {
                    this.isEditable = true;
                  }
                  else if (this.userInOrg.TargetRoleId == 3) {
                    if (data.Data.SubmitterId == this.userInOrg.UserId || data.Data.FinancialControllerId == this.userInOrg.UserId
                      || data.Data.ProductManagerId == this.userInOrg.UserId || data.Data.ProjectManagerId == this.userInOrg.UserId ||
                      data.Data.ServiceManagerId == this.userInOrg.UserId || data.Data.ProcurementEngineerId == this.userInOrg.UserId) {
                      this.isEditable = true;
                    }
                    else {
                      this.isEditable = false;
                    }
                  }
                }
                this.loadData();
              })
            }
          })
        }
      });
    } else {
      this.businessAreaId = this.businessLineId = 0;
    }

  }

  loadData() {
    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d
      if (this.configurationId == 0) {
        return;
      }
      this.optAVisible = false;
      this.optBVisible = false;
      this.optCVisible = false;
      this.eqtargetcostingService.getEqTargetcostingData(this.configurationId, this.businessAreaId, this.businessLineId).subscribe(d => {
        this.showGrid = true;
        let errorMsgeFromBack = d.Data.Message;
        if (d.StatusCode == 200) {
          this.eqConfigurationData = d.Data;
          this.TCEQTargetCostSummaries = this.eqConfigurationData.TCEQTargetCostSummaries;
          this.TCEQTargetCosts = this.eqConfigurationData.TCEQTargetCosts;

          if (this.TCEQTargetCosts.length == 0) {
            return;
          }

          if (this.TCEQTargetCosts.some(x => x.OptionA)) this.optAVisible = true
          if (this.TCEQTargetCosts.some(x => x.OptionB)) this.optBVisible = true
          if (this.TCEQTargetCosts.some(x => x.OptionC)) this.optCVisible = true

          this.refTitle = this.TCEQTargetCosts[0].ReferencceScenario.ScenarioTitle ?? ""
          this.tarTitle = this.TCEQTargetCosts[0].TargetScenario.ScenarioTitle ?? ""
          this.optATitle = this.TCEQTargetCosts[0].OptionA?.ScenarioTitle ?? ""
          this.optBTitle = this.TCEQTargetCosts[0].OptionB?.ScenarioTitle ?? ""
          this.optCTitle = this.TCEQTargetCosts[0].OptionC?.ScenarioTitle ?? ""

          this.bkFactorValue = d.Data.OverheadCosts.find(x => x.OverheadCostType == "BK Factor")?.FactorValue ?? 1;
          this.fkFactorValue = d.Data.OverheadCosts.find(x => x.OverheadCostType == "FK Factor")?.FactorValue ?? 1;

          this.vbloDropdown = ["Create new VBLO", ...new Set(this.TCEQTargetCosts.map(d => d.VBLO))]
          this.componentDropdown = ["Create new Component", ...new Set(this.TCEQTargetCosts.map(d => d.Component))]

          this.TCEQTargetCostSummaries.forEach(x => {
            x["rowGroup"] = "Summary";
          })

          this.gridData = this.TCEQTargetCosts;
          this.gridDataSummary = this.TCEQTargetCostSummaries;

          this.gridData.forEach(d => {
            d["IsEditable"] = this.isEditable;
          })

          this.gridDataSummary.forEach(d => {
            d["IsEditable"] = this.isEditable;
          })

          for (let i of this.gridData) {
            i.TargetScenario.TargetCostMEK.Quantity = i.TargetScenario.TargetCostMEK.Quantity ?? i.ReferencceScenario.Quantity;
            i.TargetScenario.TargetCostMEK.Weight = i.TargetScenario.TargetCostMEK.Weight ?? i.ReferencceScenario.Weight;
            i.TargetScenario.TargetCostMEK.PerPiece = i.TargetScenario.TargetCostMEK.PerPiece ?? i.ReferencceScenario.MEKsPerPiece;
            i.TargetScenario.TargetCostMEK.PerSystem = i.TargetScenario.TargetCostMEK.PerSystem ?? i.ReferencceScenario.MEKPerSystem;
            i.TargetScenario.ForecastMEK.PerPiece = i.TargetScenario.ForecastMEK.PerPiece ?? i.ReferencceScenario.MEKsPerPiece;
            i.TargetScenario.ForecastMEK.PerSystem = i.TargetScenario.ForecastMEK.PerSystem ?? i.ReferencceScenario.MEKPerSystem;
            i.TargetScenario.ForecastMEK.ActualsMEK = i.TargetScenario.ForecastMEK.ActualsMEK ?? i.ReferencceScenario.MEKPerSystem;
            i.TargetScenario.TargetCostMEK.EQId = i.TargetScenario.TargetCostMEK.EQId ?? 0;
            i.TargetScenario.ForecastMEK.EQId = i.TargetScenario.ForecastMEK.EQId ?? 0;
            i.TargetScenario.TargetCostMEK.DeltaTCVsRefMEK = i.TargetScenario.TargetCostMEK.DeltaTCVsRefMEK ?? 0;
            i.TargetScenario.ForecastMEK.DeltaFCVsRefMEK = i.TargetScenario.ForecastMEK.DeltaFCVsRefMEK ?? 0;
            i.TargetScenario.ForecastMEK.DeltaTCVsACT = i.TargetScenario.ForecastMEK.DeltaTCVsACT ?? 0;

            if (this.optAVisible && i.OptionA == null) {
              i.OptionA = {
                TargetCostMEK: {
                  EQId: 0,
                  Quantity: i.ReferencceScenario.Quantity,
                  Weight: i.ReferencceScenario.Weight,
                  PerPiece: i.ReferencceScenario.MEKsPerPiece,
                  PerSystem: i.ReferencceScenario.MEKPerSystem,
                },
                ForecastMEK: {
                  EQId: 0,
                  PerPiece: i.ReferencceScenario.MEKsPerPiece,
                  PerSystem: i.ReferencceScenario.MEKPerSystem,
                  ActualsMEK: i.ReferencceScenario.MEKPerSystem,
                }
              }
            }

            if (this.optBVisible && i.OptionB == null) {
              i.OptionB = {
                TargetCostMEK: {
                  EQId: 0,
                  Quantity: i.ReferencceScenario.Quantity,
                  Weight: i.ReferencceScenario.Weight,
                  PerPiece: i.ReferencceScenario.MEKsPerPiece,
                  PerSystem: i.ReferencceScenario.MEKPerSystem,
                },
                ForecastMEK: {
                  EQId: 0,
                  PerPiece: i.ReferencceScenario.MEKsPerPiece,
                  PerSystem: i.ReferencceScenario.MEKPerSystem,
                  ActualsMEK: i.ReferencceScenario.MEKPerSystem,
                }
              }
            }

            if (this.optCVisible && i.OptionC == null) {
              i.OptionC = {
                TargetCostMEK: {
                  EQId: 0,
                  Quantity: i.ReferencceScenario.Quantity,
                  Weight: i.ReferencceScenario.Weight,
                  PerPiece: i.ReferencceScenario.MEKsPerPiece,
                  PerSystem: i.ReferencceScenario.MEKPerSystem,
                },
                ForecastMEK: {
                  EQId: 0,
                  PerPiece: i.ReferencceScenario.MEKsPerPiece,
                  PerSystem: i.ReferencceScenario.MEKPerSystem,
                  ActualsMEK: i.ReferencceScenario.MEKPerSystem,
                }
              }
            }
          }

          for (let i of this.TCEQTargetCostSummaries) {
            if (i.TargetScenario == null) {
              i.TargetScenario = {
                TargetCost: {
                  Id: 0,
                  PerSystem: i.ReferenceScenario.PerSystem
                },
                Forecast: {
                  Id: 0,
                  PerSystem: i.ReferenceScenario.PerSystem,
                  ActualMEKs: i.ReferenceScenario.PerSystem
                }
              }
            }
          }

          this.loadConfigGridStructureSummary();
          this.loadRefGridStructureSummary();
          this.loadTarGridStructureSummary();
          this.loadOptAGridStructureSummary();
          this.loadOptBGridStructureSummary();
          this.loadOptCGridStructureSummary();
          this.loadConfigurationGridStructure();
          this.loadRefGridStructure();
          this.loadTarGridStructure();
          this.loadOptAGridStructure();
          this.loadOptBGridStructure();
          this.loadOptCGridStructure();


        } else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      });
    });

  }

  addNewRow() {
    if (this.optAVisible == true && this.optBVisible == true) {
      this.optCVisible = true;
      for (let i of this.gridData) {
        i.OptionC = {
          TargetCostMEK: {
            EQId: 0,
            Quantity: i.ReferencceScenario.Quantity,
            Weight: i.ReferencceScenario.Weight,
            PerPiece: i.ReferencceScenario.MEKsPerPiece,
            PerSystem: i.ReferencceScenario.MEKPerSystem,
          },
          ForecastMEK: {
            EQId: 0,
            PerPiece: i.ReferencceScenario.MEKsPerPiece,
            PerSystem: i.ReferencceScenario.MEKPerSystem,
            ActualsMEK: i.ReferencceScenario.MEKPerSystem,
          }
        }
      }
      for (let i of this.gridDataSummary) {
        i.OptionC = {
          TargetCost: {
            Id: 0,
            PerSystem: i.ReferenceScenario.PerSystem
          },
          Forecast: {
            Id: 0,
            PerSystem: i.ReferenceScenario.PerSystem,
            ActualMEKs: i.ReferenceScenario.PerSystem
          }
        }
      }
    }
    if (this.optAVisible == true) {
      this.optBVisible = true;
      for (let i of this.gridData) {
        if (i.OptionB == null) {
          i.OptionB = {
            TargetCostMEK: {
              EQId: 0,
              Quantity: i.ReferencceScenario.Quantity,
              Weight: i.ReferencceScenario.Weight,
              PerPiece: i.ReferencceScenario.MEKsPerPiece,
              PerSystem: i.ReferencceScenario.MEKPerSystem,
            },
            ForecastMEK: {
              EQId: 0,
              PerPiece: i.ReferencceScenario.MEKsPerPiece,
              PerSystem: i.ReferencceScenario.MEKPerSystem,
              ActualsMEK: i.ReferencceScenario.MEKPerSystem,
            }
          }
        }
      }
      for (let i of this.gridDataSummary) {
        i.OptionB = {
          TargetCost: {
            Id: 0,
            PerSystem: i.ReferenceScenario.PerSystem
          },
          Forecast: {
            Id: 0,
            PerSystem: i.ReferenceScenario.PerSystem,
            ActualMEKs: i.ReferenceScenario.PerSystem
          }
        }
      }
    }
    if (this.optBVisible == false) {
      this.optAVisible = true;
      for (let i of this.gridData) {
        i.OptionA = {
          TargetCostMEK: {
            EQId: 0,
            Quantity: i.ReferencceScenario.Quantity,
            Weight: i.ReferencceScenario.Weight,
            PerPiece: i.ReferencceScenario.MEKsPerPiece,
            PerSystem: i.ReferencceScenario.MEKPerSystem,
          },
          ForecastMEK: {
            EQId: 0,
            PerPiece: i.ReferencceScenario.MEKsPerPiece,
            PerSystem: i.ReferencceScenario.MEKPerSystem,
            ActualsMEK: i.ReferencceScenario.MEKPerSystem,
          }
        }
      }
      for (let i of this.gridDataSummary) {
        i.OptionA = {
          TargetCost: {
            Id: 0,
            PerSystem: i.ReferenceScenario.PerSystem
          },
          Forecast: {
            Id: 0,
            PerSystem: i.ReferenceScenario.PerSystem,
            ActualMEKs: i.ReferenceScenario.PerSystem
          }
        }
      }
    }
  }

  loadConfigGridStructureSummary() {
    this.configColumnDefsSummary = [
      {
        field: "rowGroup",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: "Description",
        field: "Description",
      }
    ];

    this.getRowHeightConfigSummary = 25

    this.configDefaultColDefSummary = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.domLayoutConfigSummary = "autoHeight";
  }

  ngOnDestroy(): void {
    this.configSub.unsubscribe();
  }

  onConfigGridReadySummary(params: any) {
    this.configSummaryGridApi = params.api;
    this.configSummaryGridApi.setHeaderHeight(20);
    this.sizeToFitRefreshConfigSummary = 1;
  }

  onConfigRowGroupOpenedSummary(params) {
    let refRow = this.refSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.refSummaryGridApi.setRowNodeExpanded(refRow, params.expanded);

    let tarRow = this.tarSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.tarSummaryGridApi.setRowNodeExpanded(tarRow, params.expanded);

    if (this.optAVisible) {
      let optA = this.optASummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optASummaryGridApi.setRowNodeExpanded(optA, params.expanded);
    }

    if (this.optBVisible) {
      let optB = this.optBSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optBSummaryGridApi.setRowNodeExpanded(optB, params.expanded);
    }

    if (this.optCVisible) {
      let optC = this.optCSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optCSummaryGridApi.setRowNodeExpanded(optC, params.expanded);
    }
  }

  onGridConfigChangedSummary(params) {
    this.configSummaryGridApi = params.api;
    this.configSummaryGridApi.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedConfigSummary(params) {
    if (this.sizeToFitRefreshConfigSummary != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onConfigCellValueChangedSummary(params) {
  }

  loadRefGridStructureSummary() {
    this.refColumnDefsSummary = [
      {
        field: "rowGroup",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: "",
        width: 276
      },
      {
        headerName: "Value",
        field: "ReferenceScenario.PerSystem",
        width: 88,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.ReferenceScenario.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.ReferenceScenario.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.ReferenceScenario.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.ReferenceScenario.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.ReferenceScenario.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.ReferenceScenario.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.ReferenceScenario.PerSystem;
            }
            return (params.node.data.ReferenceScenario.PerSystem);
          }
        }
      }
    ];

    this.getRowHeightRefSummary = 25

    this.refDefaultColDefSummary = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.domLayoutRefSummary = "autoHeight";

    this.refSummaryFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
      groupRowInnerRenderer: AddMaterialComponent,
    };
  }

  onRefGridReadySummary(params: any) {
    this.refSummaryGridApi = params.api;
    this.refSummaryGridApi.setHeaderHeight(20);
    this.refPinnedTopData = createDataRef(this.TCEQTargetCostSummaries, this.isEditable);
    this.sizeToFitRefreshRefSummary = 1
  }

  onRefRowGroupOpenedSummary(params) {
    let configRow = this.configSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.configSummaryGridApi.setRowNodeExpanded(configRow, params.expanded);

    let tarRow = this.tarSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.tarSummaryGridApi.setRowNodeExpanded(tarRow, params.expanded);

    if (this.optAVisible) {
      let optA = this.optASummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optASummaryGridApi.setRowNodeExpanded(optA, params.expanded);
    }

    if (this.optBVisible) {
      let optB = this.optBSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optBSummaryGridApi.setRowNodeExpanded(optB, params.expanded);
    }

    if (this.optCVisible) {
      let optC = this.optCSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optCSummaryGridApi.setRowNodeExpanded(optC, params.expanded);
    }
  }

  onGridRefChangedSummary(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedRefSummary(params) {
    if (this.sizeToFitRefreshRefSummary != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onRefCellValueChangedSummary(params) {
    this.hasUnsavedChanges = true;
  }

  loadTarGridStructureSummary() {
    this.tarColumnDefsSummary = [
      {
        field: "rowGroup",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: "",
        width: 180
      },
      {
        headerName: "Value",
        field: "TargetScenario.TargetCost.PerSystem",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.TargetScenario.TargetCost.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.TargetScenario.TargetCost.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.TargetScenario.TargetCost.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.TargetScenario.TargetCost.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.TargetScenario.TargetCost.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.TargetScenario.TargetCost.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.TargetScenario.TargetCost.PerSystem;
            }
            return (params.node.data.TargetScenario.TargetCost.PerSystem);
          }
        }
      },
      {
        headerName: "",
        width: 160,
        field: "tc",
        cellRendererFramework: EditEqCalcComponent,
      },
      {
        headerName: "Value",
        field: "TargetScenario.Forecast.PerSystem",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.TargetScenario.Forecast.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.TargetScenario.Forecast.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.TargetScenario.Forecast.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.TargetScenario.Forecast.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.TargetScenario.Forecast.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.TargetScenario.Forecast.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.TargetScenario.Forecast.PerSystem;
            }
            return (params.node.data.TargetScenario.Forecast.PerSystem);
          }
        }
      },
      {
        field: "fc",
        headerName: "",
        width: 80,
        cellRendererFramework: EditEqCalcComponent,
      },
      {
        headerName: "Value",
        field: "TargetScenario.Forecast.ActualMEKs",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.TargetScenario.Forecast.ActualMEKs);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.TargetScenario.Forecast.ActualMEKs;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.TargetScenario.Forecast.ActualMEKs = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.TargetScenario.Forecast.ActualMEKs;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.TargetScenario.Forecast.ActualMEKs);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.TargetScenario.Forecast.ActualMEKs = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.TargetScenario.Forecast.ActualMEKs;
            }
            return (params.node.data.TargetScenario.Forecast.ActualMEKs);
          }
        }
      },
      {
        headerName: "",
        width: 80
      }
    ];

    this.getRowHeightTarSummary = 25

    this.tarDefaultColDefSummary = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.domLayoutTarSummary = "autoHeight";

    this.tarSummaryFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onTarGridReadySummary(params: any) {
    this.tarSummaryGridApi = params.api;
    this.tarSummaryGridApi.setHeaderHeight(20);
    this.sizeToFitRefreshTarSummary = 1
  }

  onTarRowGroupOpenedSummary(params) {
    let refRow = this.refSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.refSummaryGridApi.setRowNodeExpanded(refRow, params.expanded);

    let configRow = this.configSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.configSummaryGridApi.setRowNodeExpanded(configRow, params.expanded);

    if (this.optAVisible) {
      let optA = this.optASummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optASummaryGridApi.setRowNodeExpanded(optA, params.expanded);
    }

    if (this.optAVisible) {
      let optB = this.optBSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optBSummaryGridApi.setRowNodeExpanded(optB, params.expanded);
    }

    if (this.optAVisible) {
      let optC = this.optCSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optCSummaryGridApi.setRowNodeExpanded(optC, params.expanded);
    }
  }

  onGridTarChangedSummary(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedTarSummary(params) {
    if (this.sizeToFitRefreshTarSummary != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onTarCellValueChangedSummary(params) {
    this.hasUnsavedChanges = true;
  }

  loadOptAGridStructureSummary() {
    this.optAColumnDefsSummary = [
      {
        field: "rowGroup",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: "",
        width: 180
      },
      {
        headerName: "Value",
        field: "OptionA.TargetCost.PerSystem",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionA.TargetCost.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionA.TargetCost.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.OptionA.TargetCost.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionA.TargetCost.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionA.TargetCost.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionA.TargetCost.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionA.TargetCost.PerSystem;
            }
            return (params.node.data.OptionA.TargetCost.PerSystem);
          }
        }
      },
      {
        headerName: "",
        width: 160,
        field: "tcOptA",
        cellRendererFramework: EditEqCalcComponent,
      },
      {
        headerName: "Value",
        field: "OptionA.Forecast.PerSystem",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionA.Forecast.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionA.Forecast.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.OptionA.Forecast.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionA.Forecast.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionA.Forecast.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionA.Forecast.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionA.Forecast.PerSystem;
            }
            return (params.node.data.OptionA.Forecast.PerSystem);
          }
        }
      },
      {
        headerName: "",
        width: 80,
        field: "fcOptA",
        cellRendererFramework: EditEqCalcComponent,
      },
      {
        headerName: "Value",
        field: "OptionA.Forecast.ActualMEKs",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionA.Forecast.ActualMEKs);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionA.Forecast.ActualMEKs;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.OptionA.Forecast.ActualMEKs = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionA.Forecast.ActualMEKs;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionA.Forecast.ActualMEKs);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionA.Forecast.ActualMEKs = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionA.Forecast.ActualMEKs;
            }
            return (params.node.data.OptionA.Forecast.ActualMEKs);
          }
        }
      },
      {
        headerName: "",
        width: 80
      }
    ];

    this.getRowHeightOptASummary = 25

    this.optADefaultColDefSummary = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.domLayoutOptASummary = "autoHeight";

    this.optASummaryFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onOptAGridReadySummary(params: any) {
    this.optASummaryGridApi = params.api;
    this.optASummaryGridApi.setHeaderHeight(20);
    this.sizeToFitRefreshOptASummary = 1
  }

  onOptARowGroupOpenedSummary(params) {
    let refRow = this.refSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.refSummaryGridApi.setRowNodeExpanded(refRow, params.expanded);

    let tarRow = this.tarSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.tarSummaryGridApi.setRowNodeExpanded(tarRow, params.expanded);

    let config = this.configSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.configSummaryGridApi.setRowNodeExpanded(config, params.expanded);

    if (this.optBVisible) {
      let optB = this.optBSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optBSummaryGridApi.setRowNodeExpanded(optB, params.expanded);
    }

    if (this.optCVisible) {
      let optC = this.optCSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optCSummaryGridApi.setRowNodeExpanded(optC, params.expanded);
    }
  }

  onGridOptAChangedSummary(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedOptASummary(params) {
    if (this.sizeToFitRefreshOptASummary != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onOptACellValueChangedSummary(params) {
    this.hasUnsavedChanges = true;
  }

  loadOptBGridStructureSummary() {
    this.optBColumnDefsSummary = [
      {
        field: "rowGroup",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: "",
        width: 180
      },
      {
        headerName: "Value",
        field: "OptionB.TargetCost.PerSystem",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionB.TargetCost.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionB.TargetCost.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.OptionB.TargetCost.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionB.TargetCost.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionB.TargetCost.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionB.TargetCost.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionB.TargetCost.PerSystem;
            }
            return (params.node.data.OptionB.TargetCost.PerSystem);
          }
        }
      },
      {
        headerName: "",
        width: 160,
        field: "tcOptB",
        cellRendererFramework: EditEqCalcComponent,
      },
      {
        headerName: "Value",
        field: "OptionB.Forecast.PerSystem",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionB.Forecast.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionB.Forecast.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.OptionB.Forecast.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionB.Forecast.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionB.Forecast.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionB.Forecast.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionB.Forecast.PerSystem;
            }
            return (params.node.data.OptionB.Forecast.PerSystem);
          }
        }
      },
      {
        headerName: "",
        width: 80,
        field: "fcOptB",
        cellRendererFramework: EditEqCalcComponent,
      },
      {
        headerName: "Value",
        field: "OptionB.Forecast.ActualMEKs",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionB.Forecast.ActualMEKs);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionB.Forecast.ActualMEKs;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.OptionB.Forecast.ActualMEKs = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionB.Forecast.ActualMEKs;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionB.Forecast.ActualMEKs);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionB.Forecast.ActualMEKs = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionB.Forecast.ActualMEKs;
            }
            return (params.node.data.OptionB.Forecast.ActualMEKs);
          }
        }
      },
      {
        headerName: "",
        width: 80
      }
    ];

    this.getRowHeightOptBSummary = 25

    this.optBDefaultColDefSummary = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.domLayoutOptBSummary = "autoHeight";

    this.optBSummaryFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onOptBGridReadySummary(params: any) {
    this.optBSummaryGridApi = params.api;
    this.optBSummaryGridApi.setHeaderHeight(20);
    this.sizeToFitRefreshOptBSummary = 1
  }

  onOptBRowGroupOpenedSummary(params) {
    let refRow = this.refSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.refSummaryGridApi.setRowNodeExpanded(refRow, params.expanded);

    let tarRow = this.tarSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.tarSummaryGridApi.setRowNodeExpanded(tarRow, params.expanded);

    let config = this.configSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.configSummaryGridApi.setRowNodeExpanded(config, params.expanded);

    if (this.optAVisible) {
      let optA = this.optASummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optASummaryGridApi.setRowNodeExpanded(optA, params.expanded);
    }

    if (this.optCVisible) {
      let optC = this.optCSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optCSummaryGridApi.setRowNodeExpanded(optC, params.expanded);
    }
  }

  onGridOptBChangedSummary(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedOptBSummary(params) {
    if (this.sizeToFitRefreshOptBSummary != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onOptBCellValueChangedSummary(params) {
    this.hasUnsavedChanges = true;
  }

  loadOptCGridStructureSummary() {
    this.optCColumnDefsSummary = [
      {
        field: "rowGroup",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: "",
        width: 180
      },
      {
        headerName: "Value",
        field: "OptionC.TargetCost.PerSystem",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionC.TargetCost.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionC.TargetCost.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.OptionC.TargetCost.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionC.TargetCost.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionC.TargetCost.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionC.TargetCost.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionC.TargetCost.PerSystem;
            }
            return (params.node.data.OptionC.TargetCost.PerSystem);
          }
        }
      },
      {
        headerName: "",
        width: 160,
        field: "tcOptC",
        cellRendererFramework: EditEqCalcComponent,
      },
      {
        headerName: "Value",
        field: "OptionC.Forecast.PerSystem",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionC.Forecast.PerSystem);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionC.Forecast.PerSystem;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)

              total = overhead + labor + mekTotal;
              params.node.data.OptionC.Forecast.PerSystem = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionC.Forecast.PerSystem;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionC.Forecast.PerSystem);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionC.Forecast.PerSystem = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionC.Forecast.PerSystem;
            }
            return (params.node.data.OptionC.Forecast.PerSystem);
          }
        }
      },
      {
        headerName: "",
        width: 80,
        field: "fcOptC",
        cellRendererFramework: EditEqCalcComponent,
      },
      {
        headerName: "Value",
        field: "OptionC.Forecast.ActualMEKs",
        width: 80,
        cellClass: (params) => {
          if (!params.node.group && params.data.Name != "Labour")
            return "colorTotal"
        },
        headerTooltip: "Value",
        editable: (params) => {
          if (!params.node.group && params.node.data.Name == "Labour") {
            return true;
          } else {
            return false;
          }
        },
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            //Calculation for Total BK (Bottom-up)
            if (params.node.data.Name == "TotalBK") {
              let total = 0;

              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionC.Forecast.ActualMEKs);
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionC.Forecast.ActualMEKs;
              let overhead = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              total = overhead + labor + mekTotal;
              params.node.data.OptionC.Forecast.ActualMEKs = total;
              return total;
            }
            //Calcution for therein overhead
            if (params.node.data.Name == "Overhead") {
              let value = 0;
              let mekTotal = params.api.getDisplayedRowAtIndex(7).data.OptionC.Forecast.ActualMEKs;
              let labor = Number(params.api.getDisplayedRowAtIndex(6).data.OptionC.Forecast.ActualMEKs);

              value = mekTotal * (Number(this.bkFactorValue ?? 1) - 1) + labor * (Number(this.fkFactorValue ?? 1) - 1)
              params.node.data.OptionC.Forecast.ActualMEKs = value;
              return value;
            }
            //Calculation for therein MEKs total
            if (params.node.data.Name == "MEKTotal") {
              return params.node.data.OptionC.Forecast.ActualMEKs;
            }
            return (params.node.data.OptionC.Forecast.ActualMEKs);
          }
        }
      },
      {
        headerName: "",
        width: 80
      }
    ];

    this.getRowHeightOptCSummary = 25

    this.optCDefaultColDefSummary = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.domLayoutOptCSummary = "autoHeight";

    this.optCSummaryFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onOptCGridReadySummary(params: any) {
    this.optCSummaryGridApi = params.api;
    this.optCSummaryGridApi.setHeaderHeight(20);
    this.sizeToFitRefreshOptCSummary = 1
  }

  onOptCRowGroupOpenedSummary(params) {
    let refRow = this.refSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.refSummaryGridApi.setRowNodeExpanded(refRow, params.expanded);

    let tarRow = this.tarSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.tarSummaryGridApi.setRowNodeExpanded(tarRow, params.expanded);

    let config = this.configSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.configSummaryGridApi.setRowNodeExpanded(config, params.expanded);

    if (this.optBVisible) {
      let optB = this.optBSummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optBSummaryGridApi.setRowNodeExpanded(optB, params.expanded);
    }

    if (this.optAVisible) {
      let optA = this.optASummaryGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optASummaryGridApi.setRowNodeExpanded(optA, params.expanded);
    }
  }

  onGridOptCChangedSummary(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedOptCSummary(params) {
    if (this.sizeToFitRefreshOptCSummary != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onOptCCellValueChangedSummary(params) {
    this.hasUnsavedChanges = true;
  }

  loadConfigurationGridStructure() {
    this.rowClassRules = {
      'subTotal': function (params) {
        if (!params.node.group) {
          if (params.node.data.Component == params.node.data.Material) {
            let count = 0;
            params.api.forEachNode((node) => {
              if (!node.group) {
                if (!node.data.hasOwnProperty("AssemblyRow") && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material)
                  count++;
              }
            })
            if (count != 0) {
              return true;
            }
          }
        } else {
          return true;
        }
      },
      'newAddedMaterial': function (params) {
        let flag = 0, createdOn = new Date();
        if (!params.node.group) {
          params.api.forEachNode(n => {
            if (!n.group && n.data.EQId > 0) flag = 1;
            if (!n.group) {
              if (n.data.CreatedOn != null && new Date(n.data.CreatedOn) <= createdOn) createdOn = n.data.CreatedOn;
            }
          })
          console.log(params.data.Material, createdOn)
          if (flag == 1 && params.data.EQId == 0) {
            return true;
          }
          else if (params.data.CreatedOn != null && params.data.CreatedOn > createdOn) {
            return true;
          }
        }
      },
      'inactiveRow': function (params) {
        if (!params.node.group && params.node.data.IsActive != null && !params.node.data.IsActive) return true;
      },
    }

    this.configColumnDefs = [
      {
        field: "Assembly",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: "VBLO",
        field: "VBLO",
        tooltipField: "VBLO",
        width: 60,
        headerClass: "right-border",
        cellStyle: (params) => (!params.node.group && params.node.data.VBLO == "Please Select") ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" },
        cellClass: "right-border",
        headerTooltip: "VBLO",
        editable: (params) => {
          return (!params.node.group && params.node.data.hasOwnProperty("IsManual"));
        },
        cellEditor: 'agRichSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: this.vbloDropdown
        }
      },
      {
        headerName: "VBLO Description",
        field: "VBLODescription",
        tooltipField: "VBLO Description",
        width: 100,
        headerClass: "right-border",
        cellStyle: (params) => (!params.node.group && params.node.data.VBLODescription == "Add Description") ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" },
        editable: (params) => {
          return (!params.node.group && params.node.data.VBLO == "")
        },
        singleClickEdit: true,
        cellClass: "right-border",
        headerTooltip: "VBLO Description",
      },
      {
        headerName: "Eco-ID",
        field: "EcoId",
        tooltipField: "EcoId",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Eco-ID",
        singleClickEdit: true,
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable;
          }
        },
      },
      {
        headerName: "GMK Group",
        field: "GMKGroup",
        tooltipField: "GMKGroup",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "GMKGroup",
        editable: (params) => {
          return (!params.node.group && params.node.data.hasOwnProperty("IsManual"));
        },
        singleClickEdit: true,
      },
      {
        headerName: "Plant",
        field: "Plant",
        tooltipField: "Plant",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Plant",
        editable: (params) => {
          return (!params.node.group && params.node.data.hasOwnProperty("IsManual"));
        },
        singleClickEdit: true,
      },
      {
        headerName: "Procurement Type",
        field: "ProcurementType",
        tooltipField: "ProcurementType",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Procurement Type",
        editable: (params) => {
          return (!params.node.group && params.node.data.hasOwnProperty("IsManual"));
        },
        singleClickEdit: true,
      },
      {
        headerName: "Material",
        field: "Material",
        tooltipField: "Material",
        width: 70,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Material",
        editable: false,
      },
      {
        headerName: "Material Description",
        field: "MaterialDescription",
        headerClass: "right-border",
        tooltipField: "MaterialDescription",
        width: 150,
        headerTooltip: "Material Description",
        cellStyle: (params) => (!params.node.group && params.node.data.MaterialDescription == "Add Description") ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" },
        editable: (params) => {
          return (!params.node.group && params.node.data.hasOwnProperty("IsManual"))
        },
        singleClickEdit: true,
        cellClass: "right-border"
      },
      {
        headerName: "Component",
        field: "Component",
        tooltipField: "Component",
        width: 70,
        headerTooltip: "Component",
        editable: (params) => {
          return (!params.node.group && params.node.data.hasOwnProperty("IsManual"));
        },
        cellEditor: 'agRichSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: this.componentDropdown
        },
        cellStyle: (params) => (!params.node.group && params.node.data.Component == "Please Select") ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" },
      },
    ];

    this.getRowHeightConfig = 25

    this.configDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      filter: true
    }

    this.domLayoutConfig = "autoHeight";
    this.configFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onFilterChanged(params) {
    let model = params.api.getFilterModel();
    this.refGridApi.setFilterModel(model);
    this.refGridApi.onFilterChanged();

    this.tarGridApi.setFilterModel(model);
    this.tarGridApi.onFilterChanged();

    if (this.optAVisible) {
      this.optAGridApi.setFilterModel(model);
      this.optAGridApi.onFilterChanged();
    }

    if (this.optBVisible) {
      this.optBGridApi.setFilterModel(model);
      this.optBGridApi.onFilterChanged();
    }

    if (this.optCVisible) {
      this.optCGridApi.setFilterModel(model);
      this.optCGridApi.onFilterChanged();
    }
  }

  onConfigGridReady(params: any) {
    this.configGridApi = params.api;
    this.configGridApi.setHeaderHeight(68);
    this.sizeToFitRefreshConfig = 1;
  }

  onConfigRowGroupOpened(params) {
    let refRow = this.refGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.refGridApi.setRowNodeExpanded(refRow, params.expanded);

    let tarRow = this.tarGridApi.getDisplayedRowAtIndex(params.rowIndex);
    this.tarGridApi.setRowNodeExpanded(tarRow, params.expanded);

    if (this.optAVisible) {
      let optARow = this.optAGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optAGridApi.setRowNodeExpanded(optARow, params.expanded);
    }

    if (this.optAVisible) {
      let optBRow = this.optBGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optBGridApi.setRowNodeExpanded(optBRow, params.expanded);
    }

    if (this.optAVisible) {
      let optCRow = this.optCGridApi.getDisplayedRowAtIndex(params.rowIndex);
      this.optCGridApi.setRowNodeExpanded(optCRow, params.expanded);
    }
  }

  onConfigCellValueChanged(params) {
    if (params.column.colId == "VBLO") {
      let data = this.gridData.find(x => x.VBLO == params.value);
      if (data == undefined) {
        params.data.VBLO = "";
        params.data.VBLODescription = "Add Description";
        params.data.VBLOId = 0;
        params.data.KMATVBLOId = 0;
        params.data.KMATId = this.TCEQTargetCosts[1].KMATId;
      } else {
        params.data.VBLOId = data.VBLOId;
        params.data.VBLODescription = data.VBLODescription;
        params.data.KMATVBLOId = data.KMATVBLOId;
        params.data.KMATId = data.KMATId;
      }
    }
    if (params.column.colId == "Component") {
      if (params.value == "Create new Component") params.data.Component = "";
    }

    this.configGridApi.refreshCells();
    this.refGridApi.redrawRows();
  }

  onGridConfigChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedConfig(params) {
    if (this.sizeToFitRefreshConfig != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  loadRefGridStructure() {
    this.refColumnDefs = [
      {
        field: "Assembly",
        rowGroup: true,
        hide: true,
      },
      {
        field: "VBLO",
        hide: true,
      },
      {
        field: "VBLODescription",
        hide: true,
      },
      {
        field: "EcoId",
        hide: true,
      },
      {
        field: "GMKGroup",
        hide: true,
      },
      {
        field: "Plant",
        hide: true,
      },
      {
        field: "ProcurementType",
        hide: true,
      },
      {
        field: "Material",
        hide: true,
      },
      {
        field: "MaterialDescription",
        hide: true,
      },
      {
        field: "Component",
        hide: true,
      },
      {
        headerName: "Actuals at Creation Date",
        headerTooltip: "Actuals at Creation Date",
        headerClass: "right-border left-border",
        children: [
          {
            headerName: "BKs per piece",
            headerClass: "right-border left-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.BKsPerPiece);
                }
              });
              return result;
            },
            cellClass: "left-border right-border colorTotal",
            field: "BKsPerPiece",
            tooltipField: "BKs per piece",
            headerTooltip: "BKs per piece",
            width: 88,
            editable: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("IsManual")) {
                return params.node.data.IsEditable;
              } else {
                return false;
              }
            },
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
          },
          {
            headerName: "MEKs per piece",
            headerClass: "right-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.ReferencceScenario.MEKsPerPiece;
                }
              });
              return result;
            },
            cellClass: "right-border colorTotal",
            field: "ReferencceScenario.MEKsPerPiece",
            tooltipField: "MEKs per piece",
            headerTooltip: "MEKs per piece",
            width: 88,
            editable: false,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.hasOwnProperty("IsManual")) {
                  let bk = params.node.data.BKsPerPiece;
                  let type = params.node.data.ProcurementType;
                  if (type == "E" || type == "" || type == "X") {
                    return params.node.data.ReferencceScenario.MEKsPerPiece = Number(Number(bk) / Number(this.bkFactorValue));
                  } else {
                    return params.node.data.ReferencceScenario.MEKsPerPiece = bk;
                  }
                } else {
                  return (params.node.data.ReferencceScenario.MEKsPerPiece);
                }
              }
            },
          }

        ]
      },
      {
        headerName: "Quantity",
        field: "ReferencceScenario.Quantity",
        tooltipField: "Quantity",
        width: 50,
        headerClass: "right-border",
        cellClass: (params) => {
          if (!params.node.group) {
            return "right-border"
          }
        },
        headerTooltip: "Quantity",
        singleClickEdit: true,
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable;
          }
        },
        cellEditor: 'doublingEditor',
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Weight",
        field: "ReferencceScenario.Weight",
        tooltipField: "Weight",
        width: 50,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Weight",
        singleClickEdit: true,
        cellEditor: 'doublingEditor',
        editable: (params) => {
          if (!params.node.group) {
            if (params.node.data.hasOwnProperty("AssemblyRow") || params.node.data.hasOwnProperty("Pinned")) {
              return false;
            }
            else {
              return params.node.data.IsEditable;
            }
          }
        },
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "MEK per system",
        field: "ReferencceScenario.MEKPerSystem",
        aggFunc: params => {
          var result = 0;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              result += node.data.ReferencceScenario.MEKPerSystem;
            }
          });
          return result;
        },
        tooltipField: "MEK per system",
        width: 88,
        cellClass: "colorTotal right-border",
        headerTooltip: "MEK per system",
        headerClass: "right-border",
        editable: false,
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
        valueGetter: (params) => {
          if (!params.node.group) {
            if (params.node.data.Component == params.node.data.Material) {
              let total = 0;
              let count = 0;
              params.api.forEachNode((node) => {
                if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                  count++;
                  total = total + node.data.ReferencceScenario.MEKPerSystem;
                }
              })
              if (count == 0) {
                total = params.node.data.ReferencceScenario.MEKsPerPiece * params.node.data.ReferencceScenario.Quantity * params.node.data.ReferencceScenario.Weight;
              }
              params.node.data.ReferencceScenario.MEKPerSystem = total;
              return total;
            } else {
              params.node.data.ReferencceScenario.MEKPerSystem = params.node.data.ReferencceScenario.MEKsPerPiece * params.node.data.ReferencceScenario.Quantity * params.node.data.ReferencceScenario.Weight;
              return params.node.data.ReferencceScenario.MEKPerSystem
            }
          }
        },
      },
    ];

    this.getRowHeightRef = 25

    this.refDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.domLayoutRef = "autoHeight";

    this.refFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onRefGridReady(params: any) {
    this.refGridApi = params.api;
    // this.refGridApi.forEachNode(node => {
    //   console.log(node);
    // })
    this.refGridApi.setGroupHeaderHeight(20);
    this.refPinnedTopData = createDataRef(this.TCEQTargetCostSummaries, this.isEditable);
    this.sizeToFitRefreshRef = 1;
    //params.api.setPinnedTopRowData(this.refPinnedTopData);
  }

  onGridRefChanged(params) {
    this.refGridApi = params.api;
    this.refGridApi.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedRef(params) {
    if (this.sizeToFitRefreshRef != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onRefCellValueChanged(params) {
    if (params.column.colId == "VBLO") {
      let data = this.gridData.find(x => x.VBLO == params.value);
      if (data == undefined) {
        params.data.VBLO = "";
        params.data.VBLODescription = "Add Description";
        params.data.VBLOId = 0;
        params.data.KMATVBLOId = 0;
        params.data.KMATId = this.TCEQTargetCosts[1].KMATId;
      } else {
        params.data.VBLOId = data.VBLOId;
        params.data.VBLODescription = data.VBLODescription;
        params.data.KMATVBLOId = data.KMATVBLOId;
        params.data.KMATId = data.KMATId;
      }
    }
    if (params.column.colId == "Component") {
      if (params.value == "Create new Component") params.data.Component = "";
    }

    this.refGridApi.refreshCells();
    this.tarGridApi.redrawRows();
    this.refGridApi.refreshClientSideRowModel('aggregate');

    let total = 0;
    this.refGridApi.forEachNode(node => {
      if (!node.group && node.data.Material == node.data.Component) {
        total += Number(node.data.ReferencceScenario.MEKPerSystem)
      }
    })
    this.gridDataSummary.find(x => x.Name == "MEKTotal").ReferenceScenario.PerSystem = total;
    this.refSummaryGridApi.redrawRows();

    if (this.optAGridApi != undefined) this.optAGridApi.redrawRows();
    if (this.optBGridApi != undefined) this.optBGridApi.redrawRows();
    if (this.optCGridApi != undefined) this.optCGridApi.redrawRows();

    this.hasUnsavedChanges = true;
    if (params.node.data.EQId != 0) {
      let isPushed = false;
      if (this.modifiedGridData.length == 0) {
        this.modifiedGridData.push(params.node.data);
        isPushed = true;
      }
      else {
        this.modifiedGridData.forEach(row => {
          if (params.node.data.EQId == row.EQId) {
            isPushed = true;
          }
        })
      }
      if (!isPushed) {
        this.modifiedGridData.push(params.node.data);
      }
    }
  }

  loadTarGridStructure() {
    this.tarColumnDefs = [
      {
        field: "Assembly",
        rowGroup: true,
        hide: true,
      },
      {
        field: "VBLO",
        hide: true,
      },
      {
        field: "VBLODescription",
        hide: true,
      },
      {
        field: "EcoId",
        hide: true,
      },
      {
        field: "GMKGroup",
        hide: true,
      },
      {
        field: "Plant",
        hide: true,
      },
      {
        field: "ProcurementType",
        hide: true,
      },
      {
        field: "Material",
        hide: true,
      },
      {
        field: "MaterialDescription",
        hide: true,
      },
      {
        field: "Component",
        hide: true,
      },
      {
        headerName: "Quantity",
        field: "TargetScenario.TargetCostMEK.Quantity",
        tooltipField: "Quantity",
        width: 50,
        headerClass: "right-border",
        headerTooltip: "Quantity",
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable
          }
        },
        cellEditor: 'doublingEditor',
        cellClass: (params) => {
          if (!params.node.group) {
            return "right-border"
          }
        },
        singleClickEdit: true,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Weight",
        field: "TargetScenario.TargetCostMEK.Weight",
        tooltipField: "Weight",
        width: 50,
        headerTooltip: "Weight",
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable
          }
        },
        cellEditor: 'doublingEditor',
        singleClickEdit: true,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Target Cost (MEK)",
        headerTooltip: "Target Cost (MEK)",
        headerClass: "right-border left-border",
        children: [
          {
            headerName: "per piece",
            headerClass: "right-border left-border",
            field: "TargetScenario.TargetCostMEK.PerPiece",
            tooltipField: "perpiece",
            headerTooltip: "perpiece",
            cellClass: "right-border left-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.TargetScenario.TargetCostMEK.PerPiece);
                }
              });
              return result;
            },
            width: 80,
            editable: (params) => {
              if (!params.node.group) {
                return params.node.data.IsEditable
              }
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "per system",
            headerClass: "right-border",
            field: "TargetScenario.TargetCostMEK.PerSystem",
            tooltipField: "per system",
            headerTooltip: "per system",
            cellClass: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("Pinned")) {
                if (params.node.data.Index == 0) return "right-border top-border left-border colorTotal"
                if (params.node.data.Index == 1) return "right-border left-border colorTotal"
                if (params.node.data.Index == 2) return "right-border bottom-border left-border colorTotal"
                return "colorTotal"
              } else {
                return "right-border colorTotal"
              }
            },
            width: 80,
            editable: false,
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.TargetScenario.TargetCostMEK.PerSystem;
                }
              });
              return result;
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.Component == params.node.data.Material) {
                  let total = 0;
                  let count = 0;
                  params.api.forEachNode((node) => {
                    if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                      count++;
                      total = total + (Number(node.data.TargetScenario.TargetCostMEK.PerPiece)) * (Number(node.data.TargetScenario.TargetCostMEK.Quantity)) * (Number(node.data.TargetScenario.TargetCostMEK.Weight))
                    }
                  })
                  if (count == 0) {
                    total = params.node.data.TargetScenario.TargetCostMEK.PerPiece * params.node.data.TargetScenario.TargetCostMEK.Quantity * params.node.data.TargetScenario.TargetCostMEK.Weight;
                  }
                  params.node.data.TargetScenario.TargetCostMEK.PerSystem = total;
                  return total;
                } else {
                  params.node.data.TargetScenario.TargetCostMEK.PerSystem = params.node.data.TargetScenario.TargetCostMEK.PerPiece * params.node.data.TargetScenario.TargetCostMEK.Quantity * params.node.data.TargetScenario.TargetCostMEK.Weight;
                  return params.node.data.TargetScenario.TargetCostMEK.PerSystem
                }
              }
            }
          },
          {
            headerName: "Delta TC vs. Reference MEK",
            headerClass: "right-border",
            field: "TargetScenario.TargetCostMEK.DeltaTCVsRefMEK",
            tooltipField: "Delta TC vs. Reference MEK",
            headerTooltip: "Delta TC vs. Reference MEK",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-eq"
              } else {
                return "right-border colorTotal negative-num-color-eq"
              }
            },
            aggFunc: params => {
              var target = 0, ref = 0, result;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  target += node.data.TargetScenario.TargetCostMEK.PerSystem;
                  ref += node.data.ReferencceScenario.MEKPerSystem;
                }
              });
              result = ((target - ref) / ref) * 100;
              return result;
            },
            width: 80,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: (params) => {
              if (!params.node.group && !params.node.data.hasOwnProperty("Pinned")) {
                params.node.data.TargetScenario.TargetCostMEK.DeltaTCVsRefMEK = Number(params.node.data.TargetScenario.TargetCostMEK.PerSystem) - Number(params.node.data.ReferencceScenario.MEKPerSystem)
                params.node.data.TargetScenario.TargetCostMEK.DeltaTCVsRefMEK = (Number(params.node.data.ReferencceScenario.MEKPerSystem) == 0) ? 0 : (params.node.data.TargetScenario.TargetCostMEK.DeltaTCVsRefMEK / Number(params.node.data.ReferencceScenario.MEKPerSystem)) * 100
                return params.node.data.TargetScenario.TargetCostMEK.DeltaTCVsRefMEK;
              }
            }
          }
        ]
      },
      {
        headerName: "Forecast(MEK)",
        headerTooltip: "Forecast(MEK)",
        headerClass: "right-border",
        children: [
          {
            headerName: "per piece",
            headerClass: "right-border",
            field: "TargetScenario.ForecastMEK.PerPiece",
            tooltipField: "perpiece",
            headerTooltip: "perpiece",
            cellClass: "right-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.TargetScenario.ForecastMEK.PerPiece);
                }
              });
              return result;
            },
            width: 80,
            editable: (params) => {
              if (!params.node.group) {
                return params.node.data.IsEditable
              }
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "per system",
            headerClass: "right-border",
            field: "TargetScenario.ForecastMEK.PerSystem",
            tooltipField: "per system",
            headerTooltip: "per system",
            cellClass: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("Pinned")) {
                if (params.node.data.Index == 0) return "right-border top-border left-border colorTotal"
                if (params.node.data.Index == 1) return "right-border left-border colorTotal"
                if (params.node.data.Index == 2) return "right-border bottom-border left-border colorTotal"
                return "colorTotal"
              } else {
                return "right-border colorTotal"
              }
            },
            width: 80,
            editable: false,
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.TargetScenario.ForecastMEK.PerSystem;
                }
              });
              return result;
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.Component == params.node.data.Material) {
                  let total = 0;
                  let count = 0;
                  params.api.forEachNode((node) => {
                    if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                      count++;
                      total = total + (Number(node.data.TargetScenario.ForecastMEK.PerPiece)) * (Number(node.data.TargetScenario.TargetCostMEK.Quantity)) * (Number(node.data.TargetScenario.TargetCostMEK.Weight))
                    }
                  })
                  if (count == 0) {
                    total = params.node.data.TargetScenario.ForecastMEK.PerPiece * params.node.data.TargetScenario.TargetCostMEK.Quantity * params.node.data.TargetScenario.TargetCostMEK.Weight;
                  }
                  params.node.data.TargetScenario.ForecastMEK.PerSystem = total;
                  return total;
                } else {
                  params.node.data.TargetScenario.ForecastMEK.PerSystem = params.node.data.TargetScenario.ForecastMEK.PerPiece * params.node.data.TargetScenario.TargetCostMEK.Quantity * params.node.data.TargetScenario.TargetCostMEK.Weight;
                  return params.node.data.TargetScenario.ForecastMEK.PerSystem
                }
              }
            },
          },
          {
            headerName: "Delta FC vs. Reference MEK",
            headerClass: "right-border",
            field: "TargetScenario.ForecastMEK.DeltaFCVsRefMEK",
            tooltipField: "Delta FC vs. Reference MEK",
            headerTooltip: "Delta FC vs. Reference MEK",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-eq"
              } else {
                return "right-border colorTotal negative-num-color-eq"
              }
            },
            aggFunc: params => {
              var target = 0, ref = 0, result;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  target += node.data.TargetScenario.ForecastMEK.PerSystem;
                  ref += node.data.ReferencceScenario.MEKPerSystem;
                }
              });
              result = ((target - ref) / ref) * 100;
              return result;
            },
            width: 80,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (!params.node.data.hasOwnProperty("Pinned")) {
                  params.node.data.TargetScenario.ForecastMEK.DeltaFCVsRefMEK = Number(params.node.data.TargetScenario.ForecastMEK.PerSystem) - Number(params.node.data.ReferencceScenario.MEKPerSystem)
                  params.node.data.TargetScenario.ForecastMEK.DeltaFCVsRefMEK = (Number(params.node.data.ReferencceScenario.MEKPerSystem) == 0) ? 0 : (params.node.data.TargetScenario.ForecastMEK.DeltaFCVsRefMEK / Number(params.node.data.ReferencceScenario.MEKPerSystem)) * 100
                  return params.node.data.TargetScenario.ForecastMEK.DeltaFCVsRefMEK;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "Actuals (MEK)",
        field: "TargetScenario.ForecastMEK.ActualsMEK",
        tooltipField: "Actuals (MEK)",
        width: 80,
        headerClass: "right-border",
        cellClass: "right-border colorTotal",
        aggFunc: params => {
          var result = 0;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              result += Number(node.data.TargetScenario.ForecastMEK.ActualsMEK);
            }
          });
          return result;
        },
        headerTooltip: "Actuals (MEK)",
        editable: false,
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
      },
      {
        headerName: "Delta TC vs. ACT",
        field: "TargetScenario.ForecastMEK.DeltaTCVsACT",
        tooltipField: "Delta TC vs. ACT",
        width: 80,
        headerClass: "right-border",
        cellClass: (params) => {
          if (params.value > 0) {
            return "right-border colorTotal positive-num-color-eq"
          } else {
            return "right-border colorTotal negative-num-color-eq"
          }
        },
        aggFunc: params => {
          var target = 0, ref = 0, result;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              target += node.data.TargetScenario.ForecastMEK.ActualsMEK;
              ref += node.data.TargetScenario.TargetCostMEK.PerSystem;
            }
          });
          result = ((target - ref) / ref) * 100;
          return result;
        },
        headerTooltip: "Delta TC vs. ACT",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: (params) => {
          if (!params.node.group) {
            if (!params.node.data.hasOwnProperty("Pinned")) {
              params.node.data.TargetScenario.ForecastMEK.DeltaTCVsACT = Number(params.node.data.TargetScenario.ForecastMEK.ActualsMEK) - Number(params.node.data.TargetScenario.TargetCostMEK.PerSystem)
              params.node.data.TargetScenario.ForecastMEK.DeltaTCVsACT = (Number(params.node.data.TargetScenario.TargetCostMEK.PerSystem) == 0) ? 0 : (params.node.data.TargetScenario.ForecastMEK.DeltaTCVsACT / Number(params.node.data.TargetScenario.TargetCostMEK.PerSystem)) * 100
              return params.node.data.TargetScenario.ForecastMEK.DeltaTCVsACT
            }
          }
        }
      }
    ]

    this.getRowHeightTar = 25;

    this.tarDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.domLayoutTar = "autoHeight";

    this.tarFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onTarGridReady(params: any) {
    this.tarGridApi = params.api;
    this.tarGridApi.setGroupHeaderHeight(20);
    this.sizeToFitRefreshTar = 1;

    if (this.gridDataSummary != undefined) {
      this.calculateSummaryMekTotalTarget();
    }

    grids = document.querySelectorAll('.ag-body-viewport'); // Adjust selector based on your AG Grid structure
    // Attach the scroll event listener to all grids
    grids.forEach(grid => {
      grid.addEventListener('scroll', syncVerticalScroll);
    });
  }

  onGridTarChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedTar(params) {
    if (this.sizeToFitRefreshTar != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  calculateSummaryMekTotalTarget() {
    let targetCostTotal = 0;
    let forecastTotal = 0;
    this.tarGridApi.forEachNode(node => {
      if (!node.group && node.data.Material == node.data.Component) {
        targetCostTotal += Number(node.data.TargetScenario.TargetCostMEK.PerSystem)
        forecastTotal += Number(node.data.TargetScenario.ForecastMEK.PerSystem)
      }
    })

    let data = this.gridDataSummary.find(x => x.Name == "MEKTotal");
    data.TargetScenario.TargetCost.PerSystem = targetCostTotal;
    data.TargetScenario.Forecast.PerSystem = forecastTotal;
    this.tarSummaryGridApi.redrawRows();
  }

  calculateSummaryMekTotalOptionA() {
    let targetCostTotal = 0;
    let forecastTotal = 0;
    this.optAGridApi.forEachNode(node => {
      if (!node.group && node.data.Material == node.data.Component) {
        targetCostTotal += Number(node.data.OptionA.TargetCostMEK.PerSystem)
        forecastTotal += Number(node.data.OptionA.ForecastMEK.PerSystem)
      }
    })

    let data = this.gridDataSummary.find(x => x.Name == "MEKTotal");
    data.OptionA.TargetCost.PerSystem = targetCostTotal;
    data.OptionA.Forecast.PerSystem = forecastTotal;
    this.optASummaryGridApi.redrawRows();
  }

  calculateSummaryMekTotalOptionB() {
    let targetCostTotal = 0;
    let forecastTotal = 0;
    this.optBGridApi.forEachNode(node => {
      if (!node.group && node.data.Material == node.data.Component) {
        targetCostTotal += Number(node.data.OptionB.TargetCostMEK.PerSystem)
        forecastTotal += Number(node.data.OptionB.ForecastMEK.PerSystem)
      }
    })

    let data = this.gridDataSummary.find(x => x.Name == "MEKTotal");
    data.OptionB.TargetCost.PerSystem = targetCostTotal;
    data.OptionB.Forecast.PerSystem = forecastTotal;
    this.optBSummaryGridApi.redrawRows();
  }

  calculateSummaryMekTotalOptionC() {
    let targetCostTotal = 0;
    let forecastTotal = 0;
    this.optCGridApi.forEachNode(node => {
      if (!node.group && node.data.Material == node.data.Component) {
        targetCostTotal += Number(node.data.OptionC.TargetCostMEK.PerSystem)
        forecastTotal += Number(node.data.OptionC.ForecastMEK.PerSystem)
      }
    })

    let data = this.gridDataSummary.find(x => x.Name == "MEKTotal");
    data.OptionC.TargetCost.PerSystem = targetCostTotal;
    data.OptionC.Forecast.PerSystem = forecastTotal;
    this.optCSummaryGridApi.redrawRows();
  }

  onTarCellValueChanged(params) {
    this.tarGridApi.redrawRows();
    this.tarGridApi.refreshClientSideRowModel('aggregate');

    this.calculateSummaryMekTotalTarget();

    this.hasUnsavedChanges = true;
    if (params.node.data.EQId != 0) {
      let isPushed = false;
      if (this.modifiedGridData.length == 0) {
        this.modifiedGridData.push(params.node.data);
        isPushed = true;
      }
      else {
        this.modifiedGridData.forEach(row => {
          if (params.node.data.EQId == row.EQId) {
            isPushed = true;
          }
        })
      }
      if (!isPushed) {
        this.modifiedGridData.push(params.node.data);
      }
    }
  }


  loadOptAGridStructure() {
    this.optAColumnDefs = [
      {
        field: "Assembly",
        rowGroup: true,
        hide: true,
      },
      {
        field: "VBLO",
        hide: true,
      },
      {
        field: "VBLODescription",
        hide: true,
      },
      {
        field: "EcoId",
        hide: true,
      },
      {
        field: "GMKGroup",
        hide: true,
      },
      {
        field: "Plant",
        hide: true,
      },
      {
        field: "ProcurementType",
        hide: true,
      },
      {
        field: "Material",
        hide: true,
      },
      {
        field: "MaterialDescription",
        hide: true,
      },
      {
        field: "Component",
        hide: true,
      },
      {
        headerName: "Quantity",
        field: "OptionA.TargetCostMEK.Quantity",
        tooltipField: "Quantity",
        width: 50,
        headerClass: "right-border",
        headerTooltip: "Quantity",
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable
          }
        },
        cellEditor: 'doublingEditor',
        cellClass: (params) => {
          if (!params.node.group) {
            return "right-border"
          }
        },
        singleClickEdit: true,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Weight",
        field: "OptionA.TargetCostMEK.Weight",
        tooltipField: "Weight",
        width: 50,
        headerTooltip: "Weight",
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable
          }
        },
        cellEditor: 'doublingEditor',
        singleClickEdit: true,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Target Cost (MEK)",
        headerTooltip: "Target Cost (MEK)",
        headerClass: "right-border left-border",
        children: [
          {
            headerName: "per piece",
            headerClass: "right-border left-border",
            field: "OptionA.TargetCostMEK.PerPiece",
            tooltipField: "perpiece",
            headerTooltip: "perpiece",
            cellClass: "right-border left-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.OptionA.TargetCostMEK.PerPiece);
                }
              });
              return result;
            },
            width: 80,
            editable: (params) => {
              if (!params.node.group) {
                return params.node.data.IsEditable
              }
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "per system",
            headerClass: "right-border",
            field: "OptionA.TargetCostMEK.PerSystem",
            tooltipField: "per system",
            headerTooltip: "per system",
            cellClass: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("Pinned")) {
                if (params.node.data.Index == 0) return "right-border top-border left-border colorTotal"
                if (params.node.data.Index == 1) return "right-border left-border colorTotal"
                if (params.node.data.Index == 2) return "right-border bottom-border left-border colorTotal"
                return "colorTotal"
              } else {
                return "right-border colorTotal"
              }
            },
            width: 80,
            editable: false,
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.OptionA.TargetCostMEK.PerSystem;
                }
              });
              return result;
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.Component == params.node.data.Material) {
                  let total = 0;
                  let count = 0;
                  params.api.forEachNode((node) => {
                    if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                      count++;
                      total = total + (Number(node.data.OptionA.TargetCostMEK.PerPiece)) * (Number(node.data.OptionA.TargetCostMEK.Quantity)) * (Number(node.data.OptionA.TargetCostMEK.Weight))
                    }
                  })
                  if (count == 0) {
                    total = params.node.data.OptionA.TargetCostMEK.PerPiece * params.node.data.OptionA.TargetCostMEK.Quantity * params.node.data.OptionA.TargetCostMEK.Weight;
                  }
                  params.node.data.OptionA.TargetCostMEK.PerSystem = total;
                  return total;
                } else {
                  params.node.data.OptionA.TargetCostMEK.PerSystem = params.node.data.OptionA.TargetCostMEK.PerPiece * params.node.data.OptionA.TargetCostMEK.Quantity * params.node.data.OptionA.TargetCostMEK.Weight;
                  return params.node.data.OptionA.TargetCostMEK.PerSystem
                }
              }
            }
          },
          {
            headerName: "Delta TC vs. Reference MEK",
            headerClass: "right-border",
            field: "OptionA.TargetCostMEK.DeltaTCVsRefMEK",
            tooltipField: "Delta TC vs. Reference MEK",
            headerTooltip: "Delta TC vs. Reference MEK",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-eq"
              } else {
                return "right-border colorTotal negative-num-color-eq"
              }
            },
            aggFunc: params => {
              var target = 0, ref = 0, result;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  target += node.data.OptionA.TargetCostMEK.PerSystem;
                  ref += node.data.ReferencceScenario.MEKPerSystem;
                }
              });
              result = ((target - ref) / ref) * 100;
              return result;
            },
            width: 80,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: (params) => {
              if (!params.node.group && !params.node.data.hasOwnProperty("Pinned")) {
                params.node.data.OptionA.TargetCostMEK.DeltaTCVsRefMEK = Number(params.node.data.OptionA.TargetCostMEK.PerSystem) - Number(params.node.data.ReferencceScenario.MEKPerSystem)
                params.node.data.OptionA.TargetCostMEK.DeltaTCVsRefMEK = (Number(params.node.data.ReferencceScenario.MEKPerSystem) == 0) ? 0 : (params.node.data.OptionA.TargetCostMEK.DeltaTCVsRefMEK / Number(params.node.data.ReferencceScenario.MEKPerSystem)) * 100

                return params.node.data.OptionA.TargetCostMEK.DeltaTCVsRefMEK;
              }
            }
          }
        ]
      },
      {
        headerName: "Forecast(MEK)",
        headerTooltip: "Forecast(MEK)",
        headerClass: "right-border",
        children: [
          {
            headerName: "per piece",
            headerClass: "right-border",
            field: "OptionA.ForecastMEK.PerPiece",
            tooltipField: "perpiece",
            headerTooltip: "perpiece",
            cellClass: "right-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.OptionA.ForecastMEK.PerPiece);
                }
              });
              return result;
            },
            width: 80,
            editable: (params) => {
              if (!params.node.group) {
                return params.node.data.IsEditable
              }
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "per system",
            headerClass: "right-border",
            field: "OptionA.ForecastMEK.PerSystem",
            tooltipField: "per system",
            headerTooltip: "per system",
            cellClass: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("Pinned")) {
                if (params.node.data.Index == 0) return "right-border top-border left-border colorTotal"
                if (params.node.data.Index == 1) return "right-border left-border colorTotal"
                if (params.node.data.Index == 2) return "right-border bottom-border left-border colorTotal"
                return "colorTotal"
              } else {
                return "right-border colorTotal"
              }
            },
            width: 80,
            editable: false,
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.OptionA.ForecastMEK.PerSystem;
                }
              });
              return result;
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.Component == params.node.data.Material) {
                  let total = 0;
                  let count = 0;
                  params.api.forEachNode((node) => {
                    if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                      count++;
                      total = total + (Number(node.data.OptionA.ForecastMEK.PerPiece)) * (Number(node.data.OptionA.TargetCostMEK.Quantity)) * (Number(node.data.OptionA.TargetCostMEK.Weight))
                    }
                  })
                  if (count == 0) {
                    total = params.node.data.OptionA.ForecastMEK.PerPiece * params.node.data.OptionA.TargetCostMEK.Quantity * params.node.data.OptionA.TargetCostMEK.Weight;
                  }
                  params.node.data.OptionA.ForecastMEK.PerSystem = total;
                  return total;
                } else {
                  params.node.data.OptionA.ForecastMEK.PerSystem = params.node.data.OptionA.ForecastMEK.PerPiece * params.node.data.OptionA.TargetCostMEK.Quantity * params.node.data.OptionA.TargetCostMEK.Weight;
                  return params.node.data.OptionA.ForecastMEK.PerSystem
                }
              }
            },
          },
          {
            headerName: "Delta FC vs. Reference MEK",
            headerClass: "right-border",
            field: "OptionA.ForecastMEK.DeltaFCVsRefMEK",
            tooltipField: "Delta FC vs. Reference MEK",
            headerTooltip: "Delta FC vs. Reference MEK",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-eq"
              } else {
                return "right-border colorTotal negative-num-color-eq"
              }
            },
            aggFunc: params => {
              var target = 0, ref = 0, result;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  target += node.data.OptionA.ForecastMEK.PerSystem;
                  ref += node.data.ReferencceScenario.MEKPerSystem;
                }
              });
              result = ((target - ref) / ref) * 100;
              return result;
            },
            width: 80,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (!params.node.data.hasOwnProperty("Pinned")) {
                  params.node.data.OptionA.ForecastMEK.DeltaFCVsRefMEK = Number(params.node.data.OptionA.ForecastMEK.PerSystem) - Number(params.node.data.ReferencceScenario.MEKPerSystem)
                  params.node.data.OptionA.ForecastMEK.DeltaFCVsRefMEK = (Number(params.node.data.ReferencceScenario.MEKPerSystem) == 0) ? 0 : (params.node.data.OptionA.ForecastMEK.DeltaFCVsRefMEK / Number(params.node.data.ReferencceScenario.MEKPerSystem)) * 100
                  return params.node.data.OptionA.ForecastMEK.DeltaFCVsRefMEK;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "Actuals (MEK)",
        field: "OptionA.ForecastMEK.ActualsMEK",
        tooltipField: "Actuals (MEK)",
        width: 80,
        headerClass: "right-border",
        cellClass: "right-border colorTotal",
        aggFunc: params => {
          var result = 0;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              result += node.data.OptionA.ForecastMEK.ActualsMEK;
            }
          });
          return result;
        },
        headerTooltip: "Actuals (MEK)",
        editable: false,
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
      },
      {
        headerName: "Delta TC vs. ACT",
        field: "OptionA.ForecastMEK.DeltaTCVsACT",
        tooltipField: "Delta TC vs. ACT",
        width: 80,
        headerClass: "right-border",
        cellClass: (params) => {
          if (params.value > 0) {
            return "right-border colorTotal positive-num-color-eq"
          } else {
            return "right-border colorTotal negative-num-color-eq"
          }
        },
        aggFunc: params => {
          var target = 0, ref = 0, result;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              target += node.data.OptionA.ForecastMEK.ActualsMEK;
              ref += node.data.OptionA.TargetCostMEK.PerSystem;
            }
          });
          result = ((target - ref) / ref) * 100;
          return result;
        },
        headerTooltip: "Delta TC vs. ACT",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: (params) => {
          if (!params.node.group) {
            if (!params.node.data.hasOwnProperty("Pinned")) {
              params.node.data.OptionA.ForecastMEK.DeltaTCVsACT = Number(params.node.data.OptionA.ForecastMEK.ActualsMEK) - Number(params.node.data.OptionA.TargetCostMEK.PerSystem)
              params.node.data.OptionA.ForecastMEK.DeltaTCVsACT = (Number(params.node.data.OptionA.TargetCostMEK.PerSystem) == 0) ? 0 : (params.node.data.OptionA.ForecastMEK.DeltaTCVsACT / Number(params.node.data.OptionA.TargetCostMEK.PerSystem)) * 100
              return params.node.data.OptionA.ForecastMEK.DeltaTCVsACT
            }
          }
        }
      }
    ]

    this.getRowHeightoptA = 25;

    this.optADefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.OptADomLayout = "autoHeight";

    this.optAFrameworkComponents = {
      doublingEditor: DoublingEditorComponent
    };
  }

  onOptAGridReady(params: any) {
    this.optAGridApi = params.api;
    this.optAGridApi.setGroupHeaderHeight(20);
    this.sizeToFitRefreshOptA = 1;

    if (this.gridDataSummary != undefined) {
      this.calculateSummaryMekTotalOptionA();
    }

    grids = document.querySelectorAll('.ag-body-viewport'); // Adjust selector based on your AG Grid structure
    // Attach the scroll event listener to all grids
    grids.forEach(grid => {
      grid.addEventListener('scroll', syncVerticalScroll);
    });
  }

  onGridOptAChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChangedOptA(params) {
    if (this.sizeToFitRefreshOptA != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onOptACellValueChangedCS(params) {
    this.optAGridApi.redrawRows();
    this.optAGridApi.refreshClientSideRowModel('aggregate');

    this.calculateSummaryMekTotalOptionA();

    this.hasUnsavedChanges = true;
    if (params.node.data.OptionA.TargetCostMEK.EQId != 0) {
      let isPushed = false;
      if (this.modifiedGridData.length == 0) {
        this.modifiedGridData.push(params.node.data);
        isPushed = true;
      }
      else {
        this.modifiedGridData.forEach(row => {
          if (params.node.data.EQId == row.EQId) {
            isPushed = true;
          }
        })
      }
      if (!isPushed) {
        this.modifiedGridData.push(params.node.data);
      }
    }
  }

  loadOptBGridStructure() {
    this.optBColumnDefs = [
      {
        field: "Assembly",
        rowGroup: true,
        hide: true,
      },
      {
        field: "VBLO",
        hide: true,
      },
      {
        field: "VBLODescription",
        hide: true,
      },
      {
        field: "EcoId",
        hide: true,
      },
      {
        field: "GMKGroup",
        hide: true,
      },
      {
        field: "Plant",
        hide: true,
      },
      {
        field: "ProcurementType",
        hide: true,
      },
      {
        field: "Material",
        hide: true,
      },
      {
        field: "MaterialDescription",
        hide: true,
      },
      {
        field: "Component",
        hide: true,
      },
      {
        headerName: "Quantity",
        field: "OptionB.TargetCostMEK.Quantity",
        tooltipField: "Quantity",
        width: 50,
        headerClass: "right-border",
        headerTooltip: "Quantity",
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable
          }
        },
        cellEditor: 'doublingEditor',
        cellClass: (params) => {
          if (!params.node.group) {
            return "right-border"
          }
        },
        singleClickEdit: true,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Weight",
        field: "OptionB.TargetCostMEK.Weight",
        tooltipField: "Weight",
        width: 50,
        headerTooltip: "Weight",
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable
          }
        },
        cellEditor: 'doublingEditor',
        singleClickEdit: true,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Target Cost (MEK)",
        headerTooltip: "Target Cost (MEK)",
        headerClass: "right-border left-border",
        children: [
          {
            headerName: "per piece",
            headerClass: "right-border left-border",
            field: "OptionB.TargetCostMEK.PerPiece",
            tooltipField: "perpiece",
            headerTooltip: "perpiece",
            cellClass: "right-border left-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.OptionB.TargetCostMEK.PerPiece);
                }
              });
              return result;
            },
            width: 80,
            editable: (params) => {
              if (!params.node.group) {
                return params.node.data.IsEditable
              }
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "per system",
            headerClass: "right-border",
            field: "OptionB.TargetCostMEK.PerSystem",
            tooltipField: "per system",
            headerTooltip: "per system",
            cellClass: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("Pinned")) {
                if (params.node.data.Index == 0) return "right-border top-border left-border colorTotal"
                if (params.node.data.Index == 1) return "right-border left-border colorTotal"
                if (params.node.data.Index == 2) return "right-border bottom-border left-border colorTotal"
                return "colorTotal"
              } else {
                return "right-border colorTotal"
              }
            },
            width: 80,
            editable: false,
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.OptionB.TargetCostMEK.PerSystem;
                }
              });
              return result;
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.Component == params.node.data.Material) {
                  let total = 0;
                  let count = 0;
                  params.api.forEachNode((node) => {
                    if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                      count++;
                      total = total + (Number(node.data.OptionB.TargetCostMEK.PerPiece)) * (Number(node.data.OptionB.TargetCostMEK.Quantity)) * (Number(node.data.OptionB.TargetCostMEK.Weight))
                    }
                  })
                  if (count == 0) {
                    total = params.node.data.OptionB.TargetCostMEK.PerPiece * params.node.data.OptionB.TargetCostMEK.Quantity * params.node.data.OptionB.TargetCostMEK.Weight;
                  }
                  params.node.data.OptionB.TargetCostMEK.PerSystem = total;
                  return total;
                } else {
                  params.node.data.OptionB.TargetCostMEK.PerSystem = params.node.data.OptionB.TargetCostMEK.PerPiece * params.node.data.OptionB.TargetCostMEK.Quantity * params.node.data.OptionB.TargetCostMEK.Weight;
                  return params.node.data.OptionB.TargetCostMEK.PerSystem
                }
              }
            }
          },
          {
            headerName: "Delta TC vs. Reference MEK",
            headerClass: "right-border",
            field: "OptionB.TargetCostMEK.DeltaTCVsRefMEK",
            tooltipField: "Delta TC vs. Reference MEK",
            headerTooltip: "Delta TC vs. Reference MEK",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-eq"
              } else {
                return "right-border colorTotal negative-num-color-eq"
              }
            },
            aggFunc: params => {
              var target = 0, ref = 0, result;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  target += node.data.OptionB.TargetCostMEK.PerSystem;
                  ref += node.data.ReferencceScenario.MEKPerSystem;
                }
              });
              result = ((target - ref) / ref) * 100;
              return result;
            },
            width: 80,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: (params) => {
              if (!params.node.group && !params.node.data.hasOwnProperty("Pinned")) {
                params.node.data.OptionB.TargetCostMEK.DeltaTCVsRefMEK = Number(params.node.data.OptionB.TargetCostMEK.PerSystem) - Number(params.node.data.ReferencceScenario.MEKPerSystem)
                params.node.data.OptionB.TargetCostMEK.DeltaTCVsRefMEK = (Number(params.node.data.ReferencceScenario.MEKPerSystem) == 0) ? 0 : (params.node.data.OptionB.TargetCostMEK.DeltaTCVsRefMEK / Number(params.node.data.ReferencceScenario.MEKPerSystem)) * 100

                return params.node.data.OptionB.TargetCostMEK.DeltaTCVsRefMEK;
              }
            }
          }
        ]
      },
      {
        headerName: "Forecast(MEK)",
        headerTooltip: "Forecast(MEK)",
        headerClass: "right-border",
        children: [
          {
            headerName: "per piece",
            headerClass: "right-border",
            field: "OptionB.ForecastMEK.PerPiece",
            tooltipField: "perpiece",
            headerTooltip: "perpiece",
            cellClass: "right-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.OptionB.ForecastMEK.PerPiece);
                }
              });
              return result;
            },
            width: 80,
            editable: (params) => {
              if (!params.node.group) {
                return params.node.data.IsEditable
              }
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "per system",
            headerClass: "right-border",
            field: "OptionB.ForecastMEK.PerSystem",
            tooltipField: "per system",
            headerTooltip: "per system",
            cellClass: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("Pinned")) {
                if (params.node.data.Index == 0) return "right-border top-border left-border colorTotal"
                if (params.node.data.Index == 1) return "right-border left-border colorTotal"
                if (params.node.data.Index == 2) return "right-border bottom-border left-border colorTotal"
                return "colorTotal"
              } else {
                return "right-border colorTotal"
              }
            },
            width: 80,
            editable: false,
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.OptionB.ForecastMEK.PerSystem;
                }
              });
              return result;
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.Component == params.node.data.Material) {
                  let total = 0;
                  let count = 0;
                  params.api.forEachNode((node) => {
                    if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                      count++;
                      total = total + (Number(node.data.OptionB.ForecastMEK.PerPiece)) * (Number(node.data.OptionB.TargetCostMEK.Quantity)) * (Number(node.data.OptionB.TargetCostMEK.Weight))
                    }
                  })
                  if (count == 0) {
                    total = params.node.data.OptionB.ForecastMEK.PerPiece * params.node.data.OptionB.TargetCostMEK.Quantity * params.node.data.OptionB.TargetCostMEK.Weight;
                  }
                  params.node.data.OptionB.ForecastMEK.PerSystem = total;
                  return total;
                } else {
                  params.node.data.OptionB.ForecastMEK.PerSystem = params.node.data.OptionB.ForecastMEK.PerPiece * params.node.data.OptionB.TargetCostMEK.Quantity * params.node.data.OptionB.TargetCostMEK.Weight;
                  return params.node.data.OptionB.ForecastMEK.PerSystem
                }
              }
            },
          },
          {
            headerName: "Delta FC vs. Reference MEK",
            headerClass: "right-border",
            field: "OptionB.ForecastMEK.DeltaFCVsRefMEK",
            tooltipField: "Delta FC vs. Reference MEK",
            headerTooltip: "Delta FC vs. Reference MEK",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-eq"
              } else {
                return "right-border colorTotal negative-num-color-eq"
              }
            },
            aggFunc: params => {
              var target = 0, ref = 0, result;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  target += node.data.OptionB.ForecastMEK.PerSystem;
                  ref += node.data.ReferencceScenario.MEKPerSystem;
                }
              });
              result = ((target - ref) / ref) * 100;
              return result;
            },
            width: 80,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (!params.node.data.hasOwnProperty("Pinned")) {
                  params.node.data.OptionB.ForecastMEK.DeltaFCVsRefMEK = Number(params.node.data.OptionB.ForecastMEK.PerSystem) - Number(params.node.data.ReferencceScenario.MEKPerSystem)
                  params.node.data.OptionB.ForecastMEK.DeltaFCVsRefMEK = (Number(params.node.data.ReferencceScenario.MEKPerSystem) == 0) ? 0 : (params.node.data.OptionB.ForecastMEK.DeltaFCVsRefMEK / Number(params.node.data.ReferencceScenario.MEKPerSystem)) * 100
                  return params.node.data.OptionB.ForecastMEK.DeltaFCVsRefMEK;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "Actuals (MEK)",
        field: "OptionB.ForecastMEK.ActualsMEK",
        tooltipField: "Actuals (MEK)",
        width: 80,
        headerClass: "right-border",
        cellClass: "right-border colorTotal",
        aggFunc: params => {
          var result = 0;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              result += node.data.OptionB.ForecastMEK.ActualsMEK;
            }
          });
          return result;
        },
        headerTooltip: "Actuals (MEK)",
        editable: false,
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
      },
      {
        headerName: "Delta TC vs. ACT",
        field: "OptionB.ForecastMEK.DeltaTCVsACT",
        tooltipField: "Delta TC vs. ACT",
        width: 80,
        headerClass: "right-border",
        cellClass: (params) => {
          if (params.value > 0) {
            return "right-border colorTotal positive-num-color-eq"
          } else {
            return "right-border colorTotal negative-num-color-eq"
          }
        },
        aggFunc: params => {
          var target = 0, ref = 0, result;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              target += node.data.OptionB.ForecastMEK.ActualsMEK;
              ref += node.data.OptionB.TargetCostMEK.PerSystem;
            }
          });
          result = ((target - ref) / ref) * 100;
          return result;
        },
        headerTooltip: "Delta TC vs. ACT",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: (params) => {
          if (!params.node.group) {
            if (!params.node.data.hasOwnProperty("Pinned")) {
              params.node.data.OptionB.ForecastMEK.DeltaTCVsACT = Number(params.node.data.OptionB.ForecastMEK.ActualsMEK) - Number(params.node.data.OptionB.TargetCostMEK.PerSystem)
              params.node.data.OptionB.ForecastMEK.DeltaTCVsACT = (Number(params.node.data.OptionB.TargetCostMEK.PerSystem) == 0) ? 0 : (params.node.data.OptionB.ForecastMEK.DeltaTCVsACT / Number(params.node.data.OptionB.TargetCostMEK.PerSystem)) * 100
              return params.node.data.OptionB.ForecastMEK.DeltaTCVsACT
            }
          }
        }
      }
    ]

    this.getRowHeightoptB = 25;

    this.optBDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.OptBDomLayout = "autoHeight";

    this.optBFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onOptBGridReady(params: any) {
    this.optBGridApi = params.api;
    this.optBGridApi.setGroupHeaderHeight(20);
    this.sizeToFitRefreshOptB = 1;

    if (this.gridDataSummary != undefined) {
      this.calculateSummaryMekTotalOptionB();
    }

    grids = document.querySelectorAll('.ag-body-viewport'); // Adjust selector based on your AG Grid structure
    // Attach the scroll event listener to all grids
    grids.forEach(grid => {
      grid.addEventListener('scroll', syncVerticalScroll);
    });
  }

  onDisplayedColumnsChangedOptB(params) {
    if (this.sizeToFitRefreshOptB != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onGridOptBChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onOptBCellValueChangedCS(params) {
    this.optBGridApi.redrawRows();
    this.optBGridApi.refreshClientSideRowModel('aggregate');

    this.calculateSummaryMekTotalOptionB();

    this.hasUnsavedChanges = true;
    if (params.node.data.OptionB.TargetCostMEK.EQId != 0) {
      let isPushed = false;
      if (this.modifiedGridData.length == 0) {
        this.modifiedGridData.push(params.node.data);
        isPushed = true;
      }
      else {
        this.modifiedGridData.forEach(row => {
          if (params.node.data.EQId == row.EQId) {
            isPushed = true;
          }
        })
      }
      if (!isPushed) {
        this.modifiedGridData.push(params.node.data);
      }
    }
  }
  loadOptCGridStructure() {
    this.optCColumnDefs = [
      {
        field: "Assembly",
        rowGroup: true,
        hide: true,
      },
      {
        field: "VBLO",
        hide: true,
      },
      {
        field: "VBLODescription",
        hide: true,
      },
      {
        field: "EcoId",
        hide: true,
      },
      {
        field: "GMKGroup",
        hide: true,
      },
      {
        field: "Plant",
        hide: true,
      },
      {
        field: "ProcurementType",
        hide: true,
      },
      {
        field: "Material",
        hide: true,
      },
      {
        field: "MaterialDescription",
        hide: true,
      },
      {
        field: "Component",
        hide: true,
      },
      {
        headerName: "Quantity",
        field: "OptionC.TargetCostMEK.Quantity",
        tooltipField: "Quantity",
        width: 50,
        headerClass: "right-border",
        headerTooltip: "Quantity",
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable
          }
        },
        cellEditor: 'doublingEditor',
        cellClass: (params) => {
          if (!params.node.group) {
            return "right-border"
          }
        },
        singleClickEdit: true,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Weight",
        field: "OptionC.TargetCostMEK.Weight",
        tooltipField: "Weight",
        width: 50,
        headerTooltip: "Weight",
        editable: (params) => {
          if (!params.node.group) {
            return params.node.data.IsEditable
          }
        },
        cellEditor: 'doublingEditor',
        singleClickEdit: true,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Target Cost (MEK)",
        headerTooltip: "Target Cost (MEK)",
        headerClass: "right-border left-border",
        children: [
          {
            headerName: "per piece",
            headerClass: "right-border left-border",
            field: "OptionC.TargetCostMEK.PerPiece",
            tooltipField: "perpiece",
            headerTooltip: "perpiece",
            cellClass: "right-border left-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.OptionC.TargetCostMEK.PerPiece);
                }
              });
              return result;
            },
            width: 80,
            editable: (params) => {
              if (!params.node.group) {
                return params.node.data.IsEditable
              }
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "per system",
            headerClass: "right-border",
            field: "OptionC.TargetCostMEK.PerSystem",
            tooltipField: "per system",
            headerTooltip: "per system",
            cellClass: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("Pinned")) {
                if (params.node.data.Index == 0) return "right-border top-border left-border colorTotal"
                if (params.node.data.Index == 1) return "right-border left-border colorTotal"
                if (params.node.data.Index == 2) return "right-border bottom-border left-border colorTotal"
                return "colorTotal"
              } else {
                return "right-border colorTotal"
              }
            },
            width: 80,
            editable: false,
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.OptionC.TargetCostMEK.PerSystem;
                }
              });
              return result;
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.Component == params.node.data.Material) {
                  let total = 0;
                  let count = 0;
                  params.api.forEachNode((node) => {
                    if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                      count++;
                      total = total + (Number(node.data.OptionC.TargetCostMEK.PerPiece)) * (Number(node.data.OptionC.TargetCostMEK.Quantity)) * (Number(node.data.OptionC.TargetCostMEK.Weight))
                    }
                  })
                  if (count == 0) {
                    total = params.node.data.OptionC.TargetCostMEK.PerPiece * params.node.data.OptionC.TargetCostMEK.Quantity * params.node.data.OptionC.TargetCostMEK.Weight;
                  }
                  params.node.data.OptionC.TargetCostMEK.PerSystem = total;
                  return total;
                } else {
                  params.node.data.OptionC.TargetCostMEK.PerSystem = params.node.data.OptionC.TargetCostMEK.PerPiece * params.node.data.OptionC.TargetCostMEK.Quantity * params.node.data.OptionC.TargetCostMEK.Weight;
                  return params.node.data.OptionC.TargetCostMEK.PerSystem
                }
              }
            }
          },
          {
            headerName: "Delta TC vs. Reference MEK",
            headerClass: "right-border",
            field: "OptionC.TargetCostMEK.DeltaTCVsRefMEK",
            tooltipField: "Delta TC vs. Reference MEK",
            headerTooltip: "Delta TC vs. Reference MEK",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-eq"
              } else {
                return "right-border colorTotal negative-num-color-eq"
              }
            },
            aggFunc: params => {
              var target = 0, ref = 0, result;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  target += node.data.OptionC.TargetCostMEK.PerSystem;
                  ref += node.data.ReferencceScenario.MEKPerSystem;
                }
              });
              result = ((target - ref) / ref) * 100;
              return result;
            },
            width: 80,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer, valueGetter: (params) => {
              if (!params.node.group && !params.node.data.hasOwnProperty("Pinned")) {
                params.node.data.OptionC.TargetCostMEK.DeltaTCVsRefMEK = Number(params.node.data.OptionC.TargetCostMEK.PerSystem) - Number(params.node.data.ReferencceScenario.MEKPerSystem)
                params.node.data.OptionC.TargetCostMEK.DeltaTCVsRefMEK = (Number(params.node.data.ReferencceScenario.MEKPerSystem) == 0) ? 0 : (params.node.data.OptionC.TargetCostMEK.DeltaTCVsRefMEK / Number(params.node.data.ReferencceScenario.MEKPerSystem)) * 100

                return params.node.data.OptionC.TargetCostMEK.DeltaTCVsRefMEK;
              }
            }
          }
        ]
      },
      {
        headerName: "Forecast(MEK)",
        headerTooltip: "Forecast(MEK)",
        headerClass: "right-border",
        children: [
          {
            headerName: "per piece",
            headerClass: "right-border",
            field: "OptionC.ForecastMEK.PerPiece",
            tooltipField: "perpiece",
            headerTooltip: "perpiece",
            cellClass: "right-border",
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += Number(node.data.OptionC.ForecastMEK.PerPiece);
                }
              });
              return result;
            },
            width: 80,
            editable: (params) => {
              if (!params.node.group) {
                return params.node.data.IsEditable
              }
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
          },
          {
            headerName: "per system",
            headerClass: "right-border",
            field: "OptionC.ForecastMEK.PerSystem",
            tooltipField: "per system",
            headerTooltip: "per system",
            cellClass: (params) => {
              if (!params.node.group && params.node.data.hasOwnProperty("Pinned")) {
                if (params.node.data.Index == 0) return "right-border top-border left-border colorTotal"
                if (params.node.data.Index == 1) return "right-border left-border colorTotal"
                if (params.node.data.Index == 2) return "right-border bottom-border left-border colorTotal"
                return "colorTotal"
              } else {
                return "right-border colorTotal"
              }
            },
            width: 80,
            editable: false,
            aggFunc: params => {
              var result = 0;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  result += node.data.OptionC.ForecastMEK.PerSystem;
                }
              });
              return result;
            },
            cellEditor: 'doublingEditor',
            singleClickEdit: true,
            tooltipValueGetter: CurrencyCellRendererReadOnly,
            cellRenderer: CurrencyCellRendererReadOnly,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (params.node.data.Component == params.node.data.Material) {
                  let total = 0;
                  let count = 0;
                  params.api.forEachNode((node) => {
                    if (!node.group && node.data.Component == params.node.data.Component && node.data.Component != node.data.Material) {
                      count++;
                      total = total + (Number(node.data.OptionC.ForecastMEK.PerPiece)) * (Number(node.data.OptionC.TargetCostMEK.Quantity)) * (Number(node.data.OptionC.TargetCostMEK.Weight))
                    }
                  })
                  if (count == 0) {
                    total = params.node.data.OptionC.ForecastMEK.PerPiece * params.node.data.OptionC.TargetCostMEK.Quantity * params.node.data.OptionC.TargetCostMEK.Weight;
                  }
                  params.node.data.OptionC.ForecastMEK.PerSystem = total;
                  return total;
                } else {
                  params.node.data.OptionC.ForecastMEK.PerSystem = params.node.data.OptionC.ForecastMEK.PerPiece * params.node.data.OptionC.TargetCostMEK.Quantity * params.node.data.OptionC.TargetCostMEK.Weight;
                  return params.node.data.OptionC.ForecastMEK.PerSystem
                }
              }
            },
          },
          {
            headerName: "Delta FC vs. Reference MEK",
            headerClass: "right-border",
            field: "OptionC.ForecastMEK.DeltaFCVsRefMEK",
            tooltipField: "Delta FC vs. Reference MEK",
            headerTooltip: "Delta FC vs. Reference MEK",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-eq"
              } else {
                return "right-border colorTotal negative-num-color-eq"
              }
            },
            aggFunc: params => {
              var target = 0, ref = 0, result;
              params.rowNode.allLeafChildren.forEach(node => {
                if (node.data.Component == node.data.Material) {
                  target += node.data.OptionC.ForecastMEK.PerSystem;
                  ref += node.data.ReferencceScenario.MEKPerSystem;
                }
              });
              result = ((target - ref) / ref) * 100;
              return result;
            },
            width: 80,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: (params) => {
              if (!params.node.group) {
                if (!params.node.data.hasOwnProperty("Pinned")) {
                  params.node.data.OptionC.ForecastMEK.DeltaFCVsRefMEK = Number(params.node.data.OptionC.ForecastMEK.PerSystem) - Number(params.node.data.ReferencceScenario.MEKPerSystem)
                  params.node.data.OptionC.ForecastMEK.DeltaFCVsRefMEK = (Number(params.node.data.ReferencceScenario.MEKPerSystem) == 0) ? 0 : (params.node.data.OptionC.ForecastMEK.DeltaFCVsRefMEK / Number(params.node.data.ReferencceScenario.MEKPerSystem)) * 100
                  return params.node.data.OptionC.ForecastMEK.DeltaFCVsRefMEK;
                }
              }
            }
          }
        ]
      },
      {
        headerName: "Actuals (MEK)",
        field: "OptionC.ForecastMEK.ActualsMEK",
        tooltipField: "Actuals (MEK)",
        width: 80,
        headerClass: "right-border",
        cellClass: "right-border colorTotal",
        aggFunc: params => {
          var result = 0;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              result += node.data.OptionC.ForecastMEK.ActualsMEK;
            }
          });
          return result;
        },
        headerTooltip: "Actuals (MEK)",
        editable: false,
        tooltipValueGetter: CurrencyCellRendererReadOnly,
        cellRenderer: CurrencyCellRendererReadOnly,
      },
      {
        headerName: "Delta TC vs. ACT",
        field: "OptionC.ForecastMEK.DeltaTCVsACT",
        tooltipField: "Delta TC vs. ACT",
        width: 80,
        headerClass: "right-border",
        cellClass: (params) => {
          if (params.value > 0) {
            return "right-border colorTotal positive-num-color-eq"
          } else {
            return "right-border colorTotal negative-num-color-eq"
          }
        },
        aggFunc: params => {
          var target = 0, ref = 0, result;
          params.rowNode.allLeafChildren.forEach(node => {
            if (node.data.Component == node.data.Material) {
              target += node.data.OptionC.ForecastMEK.ActualsMEK;
              ref += node.data.OptionC.TargetCostMEK.PerSystem;
            }
          });
          result = ((target - ref) / ref) * 100;
          return result;
        },
        headerTooltip: "Delta TC vs. ACT",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: (params) => {
          if (!params.node.group) {
            if (!params.node.data.hasOwnProperty("Pinned")) {
              params.node.data.OptionC.ForecastMEK.DeltaTCVsACT = Number(params.node.data.OptionC.ForecastMEK.ActualsMEK) - Number(params.node.data.OptionC.TargetCostMEK.PerSystem)
              params.node.data.OptionC.ForecastMEK.DeltaTCVsACT = (Number(params.node.data.OptionC.TargetCostMEK.PerSystem) == 0) ? 0 : (params.node.data.OptionC.ForecastMEK.DeltaTCVsACT / Number(params.node.data.OptionC.TargetCostMEK.PerSystem)) * 100
              return params.node.data.OptionC.ForecastMEK.DeltaTCVsACT
            }
          }
        }
      }
    ]

    this.getRowHeightoptC = 25;

    this.optCDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }

    this.OptCDomLayout = "autoHeight";

    this.optCFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onOptCGridReady(params: any) {
    this.optCGridApi = params.api;
    this.optCGridApi.setGroupHeaderHeight(20);
    this.sizeToFitRefreshOptC = 1;

    if (this.gridDataSummary != undefined) {
      this.calculateSummaryMekTotalOptionC();
    }

    grids = document.querySelectorAll('.ag-body-viewport'); // Adjust selector based on your AG Grid structure
    // Attach the scroll event listener to all grids
    grids.forEach(grid => {
      grid.addEventListener('scroll', syncVerticalScroll);
    });
  }

  onDisplayedColumnsChangedOptC(params) {
    if (this.sizeToFitRefreshOptC != 0) {
      params.api.sizeColumnsToFit()
    }
  }

  onGridOptCChanged(params) {
    params.api.sizeColumnsToFit();

  }

  onOptCCellValueChangedCS(params) {
    this.optCGridApi.redrawRows();
    this.optCGridApi.refreshClientSideRowModel('aggregate');

    this.calculateSummaryMekTotalOptionC();

    this.hasUnsavedChanges = true;
    if (params.node.data.OptionC.TargetCostMEK.EQId != 0) {
      let isPushed = false;
      if (this.modifiedGridData.length == 0) {
        this.modifiedGridData.push(params.node.data);
        isPushed = true;
      }
      else {
        this.modifiedGridData.forEach(row => {
          if (params.node.data.EQId == row.EQId) {
            isPushed = true;
          }
        })
      }
      if (!isPushed) {
        this.modifiedGridData.push(params.node.data);
      }
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onColumnTarResized(params) {
    params.api.resetRowHeights();
  }
  onColumnTarVisible(params) {
    params.api.resetRowHeights();
  }

  onColumnResized(params) {
    params.api.resetRowHeights();
  }


  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(["/tc-manageProject"]);
  }

  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }

  onClickNext() {
    this.menuService.onClickNextAndBack("next");
  }

  submit(event?: any) {
    console.log(event);
    if (!this.hasUnsavedChanges && event == undefined) {
      return true
    }
    let errorMessage = messages.cellEmptyErrorMesssage;
    let error = [];
    let response = null;
    this.submitted = true;

    this.refGridApi.forEachNode(node => {
      if (!node.group && node.data.hasOwnProperty("IsManual")) {
        if (node.data.VBLO == "Please Select" || node.data.VBLODescription == "Add Description" || node.data.MaterialDescription == "Add Description" || node.data.Component == "Please Select") {
          error.push({ [messages.manualMaterial]: serverMessage.warning });
        }
      }
    })

    if (error.length > 0) {
      this.submitted = false;
      this.openAlertDialog(error);
      return false;
    }
    else {
      let sendData = this.sendData();
      response = this.eqtargetcostingService.insertUpdateTargetcostingData(sendData).toPromise().
        then((res) => {
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            if (errorCode == 0) {
              this.submitted = false;
              this.hasUnsavedChanges = false;
              let dataSaveMessage = serverMessage.dataSaveMessage;
              this.toast.notify(dataSaveMessage, "success");
              if (event != undefined && event != "save") {
                this.localStorageService.remove("configuration");
                this.hasUnsavedChanges = false;
                this.router.navigate(['/tc-manageProject'])
              } else if (event == "save") {
                this.loadData();
              }
              return true;
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          }
          else {
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }
        },
          (error) => {
            this.submitted = false;
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            return false;
          })

      return response;
    }
  }

  sendData() {
    let sendObj: Eqcosts;

    let summaries: Eqsummaries[] = this.TCEQTargetCostSummaries;
    let eqData: Eqtargetcosts[];

    eqData = this.gridData.filter(x => (x.ReferencceScenario.EQId == 0 || x.TargetScenario.TargetCostMEK.EQId == 0 || x.OptionA?.TargetCostMEK.EQId == 0 || x.OptionB?.TargetCostMEK.EQId == 0 || x.OptionC?.TargetCostMEK.EQId == 0));

    this.refGridApi.forEachNode(node => {
      if (!node.group && node.data.hasOwnProperty("IsManual")) {
        if (node.data.VBLO != "Please Select" && node.data.VBLODescription != "Add Description" && node.data.MaterialDescription != "Add Description" && node.data.Component != "Please Select") {
          eqData.push(node.data)
        }
      }
    })

    this.modifiedGridData.forEach(x => {
      if (eqData.findIndex(eq => eq.EQId == x.EQId) == -1) {
        x.IsActive = true;
        x.ModifiedBy = this.userInOrg.UserId;
        eqData.push(x);
      }
    });

    eqData.forEach(x => {
      x.EcoId = x.EcoId ?? "";
      x.IsActive = true;
      x.CreatedBy = this.userInOrg.UserId;
      x.ReferencceScenario.ScenarioTitle = this.refTitle;
      x.TargetScenario.ScenarioTitle = this.tarTitle;
      if (x.OptionA != null) x.OptionA.ScenarioTitle = this.optATitle;
      if (x.OptionB != null) x.OptionB.ScenarioTitle = this.optBTitle;
      if (x.OptionC != null) x.OptionC.ScenarioTitle = this.optCTitle;
    })

    summaries.forEach((s, i) => {
      s.IsActive = true;

      if (s.Id == 0) {
        s.CreatedBy = this.userInOrg.UserId;
      } else {
        s.ModifiedBy = this.userInOrg.UserId;
      }

      s.ReferenceScenario.Id = s.ReferenceScenario.Id ?? 0;
      s.ReferenceScenario.PerSystem = s.ReferenceScenario.PerSystem ?? 0;
      s.TargetScenario.TargetCost.PerSystem = s.TargetScenario.TargetCost.PerSystem ?? 0;
      s.TargetScenario.Forecast.PerSystem = s.TargetScenario.Forecast.PerSystem ?? 0;
      s.TargetScenario.Forecast.ActualMEKs = s.TargetScenario.Forecast.ActualMEKs ?? 0;

      if (this.optAVisible) {
        s.OptionA.TargetCost.PerSystem = s.OptionA.TargetCost.PerSystem ?? 0;
        s.OptionA.Forecast.PerSystem = s.OptionA.Forecast.PerSystem ?? 0;
        s.OptionA.Forecast.ActualMEKs = s.OptionA.Forecast.ActualMEKs ?? 0;
      }
      if (this.optBVisible) {
        s.OptionB.TargetCost.PerSystem = s.OptionB.TargetCost.PerSystem ?? 0;
        s.OptionB.Forecast.PerSystem = s.OptionB.Forecast.PerSystem ?? 0;
        s.OptionB.Forecast.ActualMEKs = s.OptionB.Forecast.ActualMEKs ?? 0;
      }
      if (this.optCVisible) {
        s.OptionC.TargetCost.PerSystem = s.OptionC.TargetCost.PerSystem ?? 0;
        s.OptionC.Forecast.PerSystem = s.OptionC.Forecast.PerSystem ?? 0;
        s.OptionC.Forecast.ActualMEKs = s.OptionC.Forecast.ActualMEKs ?? 0;
      }
    });

    sendObj = {
      TCEQTargetCostSummaries: summaries,
      TCEQTargetCosts: eqData
    }

    console.log(sendObj)

    return sendObj
  }

}

// creates row for summaries
function createDataTar(summary: any[], isEditable: boolean) {
  let result = [];

  summary.forEach((d, i) => {
    result.push({
      Index: i,
      TargetScenario: {
        TargetCostMEK: {
          Id: d.TargetScenario?.TargetCost?.Id ?? null,
          PerSystem: d.TargetScenario?.TargetCost?.PerSystem ?? null
        },
        ForecastMEK: {
          Id: d.TargetScenario?.Forecast?.Id ?? null,
          PerSystem: d.TargetScenario?.Forecast?.PerSystem ?? null,
          ActualsMEK: d.TargetScenario?.Forecast?.ActualMEKs ?? null,
        }
      },
      Pinned: true,
      IsEditable: isEditable
    })
  });

  return result;
};


// creates row for summaries
function createDataRef(summary: any[], isEditable: boolean) {
  let result = [];
  summary.forEach((d, i) => {
    result.push({
      VBLO: d.Description,
      Index: i,
      ReferencceScenario: { Id: d.ReferenceScenario.Id, MEKPerSystem: d.ReferenceScenario.PerSystem },
      Pinned: true,
      IsEditable: isEditable
    })
  });
  return result;
};

// creates row for summaries for OptionA
function createDataOptA(summary: any[], isEditable: boolean) {
  let result = [];

  summary.forEach((d, i) => {
    result.push({
      Index: i,
      OptionA: {
        TargetCostMEK: {
          Id: d.OptionA?.TargetCost?.Id ?? null,
          PerSystem: d.OptionA?.TargetCost?.PerSystem ?? null
        },
        ForecastMEK: {
          Id: d.OptionA?.Forecast?.Id ?? null,
          PerSystem: d.OptionA?.Forecast?.PerSystem ?? null,
          ActualsMEK: d.OptionA?.Forecast?.ActualMEKs ?? null,
        }
      },
      Pinned: true,
      IsEditable: isEditable
    })
  });

  return result;
}

// creates row for summaries for OptionB
function createDataOptB(summary: any[], isEditable: boolean) {
  let result = [];

  summary.forEach((d, i) => {
    result.push({
      Index: i,
      OptionB: {
        TargetCostMEK: {
          Id: d.OptionB?.TargetCost?.Id ?? null,
          PerSystem: d.OptionB?.TargetCost?.PerSystem ?? null
        },
        ForecastMEK: {
          Id: d.OptionB?.Forecast?.Id ?? null,
          PerSystem: d.OptionB?.Forecast?.PerSystem ?? null,
          ActualsMEK: d.OptionB?.Forecast?.ActualMEKs ?? null,
        }
      },
      Pinned: true,
      IsEditable: isEditable
    })
  });

  return result;
}

// creates row for summaries for OptionB
function createDataOptC(summary: any[], isEditable: boolean) {
  let result = [];

  summary.forEach((d, i) => {
    result.push({
      Index: i,
      OptionC: {
        TargetCostMEK: {
          Id: d.OptionC?.TargetCost?.Id ?? null,
          PerSystem: d.OptionC?.TargetCost?.PerSystem ?? null
        },
        ForecastMEK: {
          Id: d.OptionC?.Forecast?.Id ?? null,
          PerSystem: d.OptionC?.Forecast?.PerSystem ?? null,
          ActualsMEK: d.OptionC?.Forecast?.ActualMEKs ?? null,
        }
      },
      Pinned: true,
      IsEditable: isEditable
    })
  });

  return result;
}

// Function to synchronize vertical scrolling
function syncVerticalScroll(event) {
  const scrollTop = event.target.scrollTop; // Get the scroll position of the current grid
  grids.forEach(grid => {
    if (grid !== event.target) {
      grid.scrollTop = scrollTop; // Apply the scroll position to other grids
    }
  });
}

let scrollTimeout;
function syncVerticalScrollDebounced(event) {
  const scrollTop = event.target.scrollTop;
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(() => {
    grids.forEach(grid => {
      if (grid !== event.target) {
        grid.scrollTop = scrollTop;
      }
    });
  }, 10); // Adjust delay as needed
}

// used to format the cell in germman currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
}

// used to format the cell in germman currency format;
function CurrencyCellRendererReadOnly(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(0)));
}