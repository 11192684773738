import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MSenarioType } from 'src/app/enum/MSenarioType';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { TcPlcSummaryService } from 'src/app/services/tc-plc-summary.service';

let countryCode;
@Component({
  selector: 'app-tc-summary',
  templateUrl: './tc-summary.component.html',
  styleUrls: ['./tc-summary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TcSummaryComponent implements OnInit, OnDestroy {
  refRowData: any[];
  refColumnDefs: any;
  refdefaultColDef: any;
  refGridApi: any;
  refGridColumnApi: any;
  refRowHeight: any;
  refheaderHeight: any;
  refrowClassRules: any;
  loginUserDetails: any;
  domLayout: any;
  getRowStyle: any;
  defaultColDef: any;
  rowStyle: any;
  projectId: any;
  solutionId: any;
  getResponse: any;
  errorMessage: any;
  headerYears: any;
  refGridStoredData: any;
  showRefPlcAnalysis = false;
  autoGroupRefCostColumnDef: any;
  getDataPath: any;
  aggRowData: any;
  groupDefaultExpanded: any;
  public sizeToFitRefresh: number = 0;
  configurationId: any;
  tgtrowClassRules: { colorTotal: (params: any) => boolean; colorSubTotal: (params: any) => boolean; };
  autoGrouptgtCostColumnDef: any;
  tgtRowHeight: number;
  tgtheaderHeight: number;
  tgtColumnDefs: any;
  tgtGridApi: any;
  tgtGridColumnApi: any;
  showTgtPlcAnalysis: boolean;
  tgtGridStoredData: any[];
  tgtRowData: any[];
  optArowClassRules: { colorTotal: (params: any) => boolean; colorSubTotal: (params: any) => boolean; };
  optAColumnDefs: any;
  optARowHeight: number;
  optAheaderHeight: number;
  autoGroupoptACostColumnDef: {
    headerName: string; field: string; minwidth: number; suppressSizeToFit: boolean; tooltipValueGetter: (params: any) => any; cellClass: (params: any) => "" | "plcGroupPadding1"; cellRendererParams: { suppressCount: boolean; }; // turn off the row count
    valueGetter: (params: any) => any;
  };
  optAGridApi: any;
  optAGridColumnApi: any;
  optAVisible: boolean = true;
  optBVisible: boolean = true;
  optCVisible: boolean = true;
  showoptAPlcAnalysis: boolean;
  optAGridStoredData: any[];
  optARowData: any[];
  optBrowClassRules: { colorTotal: (params: any) => boolean; colorSubTotal: (params: any) => boolean; };
  optBRowHeight: number;
  optBheaderHeight: number;
  autoGroupoptBCostColumnDef: {
    headerName: string; field: string; minwidth: number; suppressSizeToFit: boolean; tooltipValueGetter: (params: any) => any; cellClass: (params: any) => "" | "plcGroupPadding1"; cellRendererParams: { suppressCount: boolean; }; // turn off the row count
    valueGetter: (params: any) => any;
  };
  optBColumnDefs: ({ headerName: string; field: string; rowGroup: boolean; hide: boolean; suppressSizeToFit: boolean; headerTooltip?: undefined; } | { headerName: string; headerTooltip: string; field?: undefined; rowGroup?: undefined; hide?: undefined; suppressSizeToFit?: undefined; })[];
  optBGridApi: any;
  optBGridColumnApi: any;
  showoptBPlcAnalysis: boolean;
  optBGridStoredData: any[];
  optBRowData: any[];
  optCrowClassRules: { colorTotal: (params: any) => boolean; colorSubTotal: (params: any) => boolean; };
  autoGroupoptCCostColumnDef: {
    headerName: string; field: string; minwidth: number; suppressSizeToFit: boolean; tooltipValueGetter: (params: any) => any; cellClass: (params: any) => "" | "plcGroupPadding1"; cellRendererParams: { suppressCount: boolean; }; // turn off the row count
    valueGetter: (params: any) => any;
  };
  optCRowHeight: number;
  optCheaderHeight: number;
  optCColumnDefs: ({ headerName: string; field: string; rowGroup: boolean; hide: boolean; suppressSizeToFit: boolean; headerTooltip?: undefined; } | { headerName: string; headerTooltip: string; field?: undefined; rowGroup?: undefined; hide?: undefined; suppressSizeToFit?: undefined; })[];
  showoptCPlcAnalysis: boolean;
  optCGridStoredData: any[];
  optCGridApi: any;
  optCRowData: any[];
  optCGridColumnApi: any;
  aggRowDataTar: any[];
  aggRowDataRef: any[];
  aggRowDataOptA: any[];
  aggRowDataOptB: any[];
  aggRowDataOptC: any[];
  configSub: any;
  constructor(
    private sharedService: SharedServiceService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    private tcPlcSummaryService: TcPlcSummaryService,
    private menuService: MenuService
  ) {

  }

  ngOnInit(): void {
    this.showRefPlcAnalysis = true;
    this.sharedService.getProjectIdAndTitle();
    this.loadRefernceGrid();
    this.loadReferncedata();
    this.loadTargetGrid();
    this.loadTargetdata();
    this.loadOptionAGrid();
    this.loadoptionAdata();
   this.loadOptionBGrid();
   this.loadOptionBdata();
    this.loadOptionCGrid();
    this.loadOptionCdata();
    //Get Country Code
    countryCode = this.localStorageService.get('countryCode');
   
   

 
  

  }
  loadReferncedata() {
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      //  this.loadRefernceGrid();
      //   this.showGrid = false;
      this.configurationId = d;
      this.errorMessage = [];
      this.refGridStoredData = [];
      let solutionId = MSenarioType.ReferenceScenario;

      //change this Service method
      if(this.configurationId!=0)
{
      this.tcPlcSummaryService.getTCPLCSummaryAnalysis(this.configurationId, solutionId).subscribe(
        (data: any) => {

          let refPLCData = data;

          let successCode = refPLCData.StatusCode;
          if (successCode != 200) {
            this.showRefPlcAnalysis = true;
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
            return false;
          }
          else if (successCode == 200) {

            if (refPLCData.Data[0].HeaderYear != null && refPLCData.Data[0].HeaderYear != "") {

              this.headerYears = refPLCData.Data[0].HeaderYear.split(",")

              this.refGridStoredData = this.bindPLCSummaryAnalysisRefData(refPLCData.Data);

              //   setTimeout(() => {
              this.setRefGridData(this.headerYears);
              //    }, 1000)
            }
            else {
              this.showRefPlcAnalysis = true;
              let errorMessage = serverMessage.serverErrorMessage
              let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
              this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
              return false;
            }
          }
        }, (error) => {
          this.showRefPlcAnalysis = true;
          this.errorMessage.push(error)
          this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
          return false;
        })
      }
    })
  }
  loadTargetdata() {
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      //   this.showGrid = false;
      this.configurationId = d;
      this.errorMessage = [];
      this.tgtGridStoredData = [];
      let solutionId = MSenarioType.TargetScenario;
      //change this Service method
      if(this.configurationId!=0)
        {
      this.tcPlcSummaryService.getTCPLCSummaryAnalysis(this.configurationId, solutionId).subscribe(
        (data: any) => {
          let tarPLCData = data;
          let successCode = tarPLCData.StatusCode;
          if (successCode != 200) {
            this.showTgtPlcAnalysis = true;
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
            return false;
          }
          else if (successCode == 200) {
            if (tarPLCData.Data[0].HeaderYear != null && tarPLCData.Data[0].HeaderYear != "") {

              this.headerYears = tarPLCData.Data[0].HeaderYear.split(",")

              this.tgtGridStoredData = this.bindPLCSummaryAnalysisData(tarPLCData.Data);

              //   setTimeout(() => {
              this.setTgtGridData(this.headerYears);
              //  }, 1000)
            }
            else {
              this.showTgtPlcAnalysis = true;
              let errorMessage = serverMessage.serverErrorMessage;
              let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
              this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
              return false;
            }
          }
        }, (error) => {
          this.showTgtPlcAnalysis = true;
          this.errorMessage.push(error)
          this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
          return false;
        })
      }
    })
    
  }

  loadRefernceGrid() {
    this.refColumnDefs = [
      {
        headerName: '',
        field: 'RowGroup', rowGroup: true, hide: true,
        suppressSizeToFit: true
      },
      { headerName: 'Years11-20', headerTooltip: 'Years11-20' }
    ]


    this.refRowHeight = 30;
    this.refheaderHeight = 40;
    this.domLayout = 'autoHeight';
    this.autoGroupRefCostColumnDef = {
      headerName: '',
      field: 'plctype',
      minwidth: 200, suppressSizeToFit: true,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      cellClass: params => {
        if (!params.node.group) {
          return params.node.data.rownum == 5.00 || params.node.data.rownum == 6.00
            || params.node.data.rownum == 8.00 || params.node.data.rownum == 9.00
            || params.node.data.rownum == 12.00 || params.node.data.rownum == 13.00
            || params.node.data.rownum == 15.00 || params.node.data.rownum == 16.00
            || params.node.data.rownum == 18.00 || params.node.data.rownum == 19.00 ? 'plcGroupPadding1' : '';
        }
      },
      cellRendererParams: {
        suppressCount: true
      },// turn off the row count
      valueGetter: params => {

        if (!params.node.group) {
          if (params.node.data.rownum == 1.90 || params.node.data.rownum == 4.90 || params.node.data.rownum == 2.00 || params.node.data.rownum == 3.00) {

            return params.node.data.ComponentTitle;
          }
          else {
            return params.node.data.plctype;
          }
        }
        else {
          return params.value;
        }
      }

    };
    this.refrowClassRules = {
      'colorTotal': (params: any) => {

        if (!params.node.group) {
          if (params.data.rownum == 0.20 || params.data.rownum == 0.30 || params.data.rownum == 0.40) {
            return true;
          }
        }
      },
      'colorSubTotal': (params: any) => {
        if (!params.node.group) {
          if (params.data.rownum == 0.50 || params.data.rownum == 4.80 || params.data.rownum == 2.00
            || params.data.rownum == 20.00 || params.data.rownum == 21.00 || params.data.rownum == 22.00) {
            return true;
          }
        }
      }
    }

    this.defaultColDef = {
      suppressMovable: true,
      sortable: false,

    };
    this.getDataPath = function (data) {
      return data.RowGroup.split(',');
    };
  }
  loadTargetGrid() {

    this.tgtColumnDefs = [

      {
        headerName: '',
        field: 'RowGroup', rowGroup: true, hide: true,
        suppressSizeToFit: true
      },
      { headerName: 'Years11-20', headerTooltip: 'Years11-20' }
    ]


    this.tgtRowHeight = 30;
    this.tgtheaderHeight = 40;
    this.domLayout = 'autoHeight';
    this.autoGrouptgtCostColumnDef = {
      headerName: '',
      field: 'plctype',
      minwidth: 200, suppressSizeToFit: true,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      cellClass: params => {
        if (!params.node.group) {
          return params.node.data.rownum == 5.00 || params.node.data.rownum == 6.00
            || params.node.data.rownum == 8.00 || params.node.data.rownum == 9.00
            || params.node.data.rownum == 12.00 || params.node.data.rownum == 13.00
            || params.node.data.rownum == 15.00 || params.node.data.rownum == 16.00
            || params.node.data.rownum == 18.00 || params.node.data.rownum == 19.00 ? 'plcGroupPadding1' : '';
        }
      },
      cellRendererParams: {
        suppressCount: true
      },// turn off the row count
      valueGetter: params => {
        if (!params.node.group) {
          if (params.node.data.rownum == 1.90 || params.node.data.rownum == 4.90 || params.node.data.rownum == 2.00 || params.node.data.rownum == 3.00) {
            return params.node.data.ComponentTitle;
          }
          else {
            return params.node.data.plctype;
          }
        }
        else {
          return params.value;
        }
      }
    };

    this.tgtrowClassRules = {
      'colorTotal': (params: any) => {
        if (!params.node.group) {
          if (params.data.rownum == 0.20 || params.data.rownum == 0.30 || params.data.rownum == 0.40) {
            return true;
          }
        }
      },
      'colorSubTotal': (params: any) => {
        if (!params.node.group) {
          if (params.data.rownum == 0.50 || params.data.rownum == 4.80 || params.data.rownum == 2.00
            || params.data.rownum == 20.00 || params.data.rownum == 21.00 || params.data.rownum == 22.00) {
            return true;
          }
        }
      }
    }

    this.defaultColDef = {
      suppressMovable: true,
      sortable: false,
    };

    this.getDataPath = function (data) {
      return data.RowGroup.split(',');
    };
  }
  loadOptionAGrid() {
    console.log("3");
    this.optAColumnDefs = [

      {
        headerName: '',
        field: 'RowGroup', rowGroup: true, hide: true,
        suppressSizeToFit: true
      },
      { headerName: 'Years11-20', headerTooltip: 'Years11-20', }
    ]


    this.optARowHeight = 30;
    this.optAheaderHeight = 40;
    this.domLayout = 'autoHeight';
    this.autoGroupoptACostColumnDef = {
      headerName: '',
      field: 'plctype',
      minwidth: 200, suppressSizeToFit: true,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      cellClass: params => {
        if (!params.node.group) {
          return params.node.data.rownum == 5.00 || params.node.data.rownum == 6.00
            || params.node.data.rownum == 8.00 || params.node.data.rownum == 9.00
            || params.node.data.rownum == 12.00 || params.node.data.rownum == 13.00
            || params.node.data.rownum == 15.00 || params.node.data.rownum == 16.00
            || params.node.data.rownum == 18.00 || params.node.data.rownum == 19.00 ? 'plcGroupPadding1' : '';
        }
      },
      cellRendererParams: {
        suppressCount: true
      },// turn off the row count
      valueGetter: params => {

        if (!params.node.group) {
          if (params.node.data.rownum == 1.90 || params.node.data.rownum == 4.90 || params.node.data.rownum == 2.00 || params.node.data.rownum == 3.00) {

            return params.node.data.ComponentTitle;
          }
          else {
            return params.node.data.plctype;
          }
        }
        else {
          return params.value;
        }
      }

    };
    this.optArowClassRules = {
      'colorTotal': (params: any) => {

        if (!params.node.group) {
          if (params.data.rownum == 0.20 || params.data.rownum == 0.30 || params.data.rownum == 0.40) {
            return true;
          }
        }
      },
      'colorSubTotal': (params: any) => {
        if (!params.node.group) {
          if (params.data.rownum == 0.50 || params.data.rownum == 4.80 || params.data.rownum == 2.00
            || params.data.rownum == 20.00 || params.data.rownum == 21.00 || params.data.rownum == 22.00) {
            return true;
          }
        }
      }
    }

    this.defaultColDef = {
      suppressMovable: true,
      sortable: false,
    };
    this.getDataPath = function (data) {
      return data.RowGroup.split(',');
    };
  }
  loadoptionAdata() {

    this.configSub = this.sharedService.configurationId.subscribe(d => {
      //   this.showGrid = false;
      this.configurationId = d;
      this.errorMessage = [];
      let optionA = 0;
      this.tgtGridStoredData = [];
      let solutionId = MSenarioType.OptionA;
      if(this.configurationId!=0)
        {
      this.tcPlcSummaryService.getTCPLCSummaryAnalysis(this.configurationId, solutionId).subscribe(
        (data: any) => {


          // this.getResponse = data;
          let optAPLCData = data
          let successCode = optAPLCData.StatusCode;
          if (successCode != 200) {
            this.showoptAPlcAnalysis = true;
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
            return false;
          }
          else if (successCode == 200) {
            
            optAPLCData.Data.forEach(row => {
              if ((row.Total != 0  && row.rownum!=0.20) ||
              (row.Total != 0  && row.rownum!=0.30)||
              (row.Total != 0  && row.rownum!=0.30)){
                //   this.showGrid = true;
                optionA = 1;
              }
            });
            if (optionA == 1) {
              console.log("2");
              this.optAVisible = true;
              if (optAPLCData.Data[0].HeaderYear != null && optAPLCData.Data[0].HeaderYear != "") {
                
                this.headerYears = optAPLCData.Data[0].HeaderYear.split(",")

                this.optAGridStoredData = this.bindPLCSummaryAnalysisOptAData(optAPLCData.Data);

                //    setTimeout(() => {
                this.setoptAGridData(this.headerYears);
                //   }, 2000)
              }
              else {
                this.showoptAPlcAnalysis = true;
                let errorMessage = serverMessage.serverErrorMessage
                let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
                this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
                return false;
              }
            }
            else{
              this.optAVisible = false;
            }
          }
        }, (error) => {
          this.showoptAPlcAnalysis = true;
          this.errorMessage.push(error)
          this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
          return false;
        })
      }
    })
  }
  loadOptionBGrid() {

    this.optBColumnDefs = [

      {
        headerName: '',
        field: 'RowGroup', rowGroup: true, hide: true,
        suppressSizeToFit: true
      },
      { headerName: 'Years11-20', headerTooltip: 'Years11-20' }

    ]


    this.optBRowHeight = 30;
    this.optBheaderHeight = 40;
    this.domLayout = 'autoHeight';
    this.autoGroupoptBCostColumnDef = {
      headerName: '',
      field: 'plctype',
      minwidth: 200, suppressSizeToFit: true,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      cellClass: params => {
        if (!params.node.group) {
          return params.node.data.rownum == 5.00 || params.node.data.rownum == 6.00
            || params.node.data.rownum == 8.00 || params.node.data.rownum == 9.00
            || params.node.data.rownum == 12.00 || params.node.data.rownum == 13.00
            || params.node.data.rownum == 15.00 || params.node.data.rownum == 16.00
            || params.node.data.rownum == 18.00 || params.node.data.rownum == 19.00 ? 'plcGroupPadding1' : '';
        }
      },
      cellRendererParams: {
        suppressCount: true
      },// turn off the row count
      valueGetter: params => {

        if (!params.node.group) {
          if (params.node.data.rownum == 1.90 || params.node.data.rownum == 4.90 || params.node.data.rownum == 2.00 || params.node.data.rownum == 3.00) {

            return params.node.data.ComponentTitle;
          }
          else {
            return params.node.data.plctype;
          }
        }
        else {
          return params.value;
        }
      }

    };
    this.optBrowClassRules = {
      'colorTotal': (params: any) => {

        if (!params.node.group) {
          if (params.data.rownum == 0.20 || params.data.rownum == 0.30 || params.data.rownum == 0.40) {
            return true;
          }
        }
      },
      'colorSubTotal': (params: any) => {
        if (!params.node.group) {
          if (params.data.rownum == 0.50 || params.data.rownum == 4.80 || params.data.rownum == 2.00
            || params.data.rownum == 20.00 || params.data.rownum == 21.00 || params.data.rownum == 22.00) {
            return true;
          }
        }
      }
    }

    this.defaultColDef = {
      suppressMovable: true,
      sortable: false,

    };
    this.getDataPath = function (data) {
      return data.RowGroup.split(',');
    };
  }
  loadOptionBdata() {
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      //   this.showGrid = false;
      this.configurationId = d;
      this.errorMessage = [];
      this.tgtGridStoredData = [];
      let solutionId = MSenarioType.OptionB;
      if(this.configurationId!=0)
        {
      this.tcPlcSummaryService.getTCPLCSummaryAnalysis(this.configurationId, solutionId).subscribe(
        (data: any) => {


          let optBPLCData = data;

          let successCode = optBPLCData.StatusCode;
          if (successCode != 200) {
            this.showoptBPlcAnalysis = true;
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
            return false;
          }
          else if (successCode == 200) {
            let optionB = 0;
            optBPLCData.Data.forEach(row => {
              if ((row.Total != 0  && row.rownum!=0.20) ||
              (row.Total != 0  && row.rownum!=0.30)||
              (row.Total != 0  && row.rownum!=0.30)) {
                //   this.showGrid = true;
                optionB = 1;
              }
            });
            if (optionB == 1) {
              this.optBVisible=true;
              if (optBPLCData.Data[0].HeaderYear != null && optBPLCData.Data[0].HeaderYear != "") {

                this.headerYears = optBPLCData.Data[0].HeaderYear.split(",")

                this.optBGridStoredData = this.bindPLCSummaryAnalysisOptBData(optBPLCData.Data);

                this.setoptBGridData(this.headerYears);

              }
              else {
                this.showoptBPlcAnalysis = true;
                let errorMessage = serverMessage.serverErrorMessage
                let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
                this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
                return false;
              }
            }
            else{
              this.optBVisible = false;
            }
          }
        }, (error) => {
          this.showoptBPlcAnalysis = true;
          this.errorMessage.push(error)
          this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
          return false;
        })
      }
    })

  }
  loadOptionCGrid() {


    this.optCColumnDefs = [

      {
        headerName: '',
        field: 'RowGroup', rowGroup: true, hide: true,
        suppressSizeToFit: true
      },
      { headerName: 'Years11-20', headerTooltip: 'Years11-20' }
    ]


    this.optCRowHeight = 30;
    this.optCheaderHeight = 40;
    this.domLayout = 'autoHeight';
    this.autoGroupoptCCostColumnDef = {
      headerName: '',
      field: 'plctype',
      minwidth: 200, suppressSizeToFit: true,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      cellClass: params => {
        if (!params.node.group) {
          return params.node.data.rownum == 5.00 || params.node.data.rownum == 6.00
            || params.node.data.rownum == 8.00 || params.node.data.rownum == 9.00
            || params.node.data.rownum == 12.00 || params.node.data.rownum == 13.00
            || params.node.data.rownum == 15.00 || params.node.data.rownum == 16.00
            || params.node.data.rownum == 18.00 || params.node.data.rownum == 19.00 ? 'plcGroupPadding1' : '';
        }
      },
      cellRendererParams: {
        suppressCount: true
      },// turn off the row count
      valueGetter: params => {

        if (!params.node.group) {
          if (params.node.data.rownum == 1.90 || params.node.data.rownum == 4.90 || params.node.data.rownum == 2.00 || params.node.data.rownum == 3.00) {

            return params.node.data.ComponentTitle;
          }
          else {
            return params.node.data.plctype;
          }
        }
        else {
          return params.value;
        }
      }

    };
    this.optCrowClassRules = {
      'colorTotal': (params: any) => {

        if (!params.node.group) {
          if (params.data.rownum == 0.20 || params.data.rownum == 0.30 || params.data.rownum == 0.40) {
            return true;
          }
        }
      },
      'colorSubTotal': (params: any) => {
        if (!params.node.group) {
          if (params.data.rownum == 0.50 || params.data.rownum == 4.80 || params.data.rownum == 2.00
            || params.data.rownum == 20.00 || params.data.rownum == 21.00 || params.data.rownum == 22.00) {
            return true;
          }
        }
      }
    }

    this.defaultColDef = {
      suppressMovable: true,
      sortable: false,
      // flex: 1,
      //editable: true,
      //singleClickEdit:true
    };
    this.getDataPath = function (data) {
      return data.RowGroup.split(',');
    };
  }
  loadOptionCdata() {
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      //   this.showGrid = false;
      this.configurationId =d;
      this.errorMessage = [];
      this.tgtGridStoredData = [];
      let solutionId = MSenarioType.OptionC;
      if(this.configurationId!=0)
        {
      this.tcPlcSummaryService.getTCPLCSummaryAnalysis(this.configurationId, solutionId).subscribe(
        (data: any) => {


          let optCPLCData = data;

          let successCode = optCPLCData.StatusCode;
          if (successCode != 200) {
            this.showoptCPlcAnalysis = true;
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
            return false;
          }
          else if (successCode == 200) {
            let optionC = 0;
            optCPLCData.Data.forEach(row => {
              if ((row.Total != 0  && row.rownum!=0.20) ||
              (row.Total != 0  && row.rownum!=0.30)||
              (row.Total != 0  && row.rownum!=0.30))  {
                //   this.showGrid = true;
                optionC = 1;
              }
            });
            if(optionC==1)
            {
            if (optCPLCData.Data[0].HeaderYear != null && optCPLCData.Data[0].HeaderYear != "") {

              this.headerYears = optCPLCData.Data[0].HeaderYear.split(",")

              this.optCGridStoredData = this.bindPLCSummaryAnalysisOptCData(optCPLCData.Data);
                this.setoptCGridData(this.headerYears);
         
            }
            else {
              this.showoptCPlcAnalysis = true;
              let errorMessage = serverMessage.serverErrorMessage
              let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
              this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
              return false;
            }
          }
          else{
            this.optCVisible = false;
          }
        }
        }, (error) => {
          this.showoptCPlcAnalysis = true;
          this.errorMessage.push(error)
          this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
          return false;
        })
      }
    })
  }
  bindPLCSummaryAnalysisData(obj) {
    console.log(obj);
    let rowDataTar = [];
    let dataBind = {};
    let plcSummaryAnalysisData = [];
    this.aggRowDataTar = [];
    plcSummaryAnalysisData = obj;
    plcSummaryAnalysisData.forEach(function (value, key) {
      dataBind = {
        rownum: value.rownum,
        ComponentId: value.ComponentId,
        ComponentTitle: value.ComponentTitle,
        plctype: value.plctype,
        Year1: this.getDecimalFixed(value.Year1),
        Year2: this.getDecimalFixed(value.Year2),
        Year3: this.getDecimalFixed(value.Year3),
        Year4: this.getDecimalFixed(value.Year4),
        Year5: this.getDecimalFixed(value.Year5),
        Year6: this.getDecimalFixed(value.Year6),
        Year7: this.getDecimalFixed(value.Year7),
        Year8: this.getDecimalFixed(value.Year8),
        Year9: this.getDecimalFixed(value.Year9),
        Year10: this.getDecimalFixed(value.Year10),
        Year11: this.getDecimalFixed(value.Year11),
        Year12: this.getDecimalFixed(value.Year12),
        Year13: this.getDecimalFixed(value.Year13),
        Year14: this.getDecimalFixed(value.Year14),
        Year15: this.getDecimalFixed(value.Year15),
        Year16: this.getDecimalFixed(value.Year16),
        Year17: this.getDecimalFixed(value.Year17),
        Year18: this.getDecimalFixed(value.Year18),
        Year19: this.getDecimalFixed(value.Year19),
        Year20: this.getDecimalFixed(value.Year20),
        Total: this.getDecimalFixed(value.Total),
        RowGroup: value.RowGroup,
        SubRowGroup: value.SubRowGroup
      }
      if (value.rownum != 1.00 && value.rownum != 0.60 && value.rownum != 2.00 && value.rownum != 3.00 && value.rownum != 4.00 &&
        value.rownum != 4.70 && value.rownum != 11.00) {
        rowDataTar.push(dataBind);
      }
      if (value.rownum == 1.00 || value.rownum == 0.60 || value.rownum == 2.00 || value.rownum == 3.00 || value.rownum == 4.00 ||
        value.rownum == 4.70 || value.rownum == 11.00) {

        this.aggRowDataTar.push(dataBind);
      }
    }.bind(this));

    return rowDataTar;
  }
  bindPLCSummaryAnalysisRefData(obj) {
    console.log(obj);
    let rowDataRef = [];
    let dataBind = {};
    let plcSummaryAnalysisData = [];
    this.aggRowDataRef = [];
    plcSummaryAnalysisData = obj;
    plcSummaryAnalysisData.forEach(function (value, key) {
      dataBind = {
        rownum: value.rownum,
        ComponentId: value.ComponentId,
        ComponentTitle: value.ComponentTitle,
        plctype: value.plctype,
        Year1: this.getDecimalFixed(value.Year1),
        Year2: this.getDecimalFixed(value.Year2),
        Year3: this.getDecimalFixed(value.Year3),
        Year4: this.getDecimalFixed(value.Year4),
        Year5: this.getDecimalFixed(value.Year5),
        Year6: this.getDecimalFixed(value.Year6),
        Year7: this.getDecimalFixed(value.Year7),
        Year8: this.getDecimalFixed(value.Year8),
        Year9: this.getDecimalFixed(value.Year9),
        Year10: this.getDecimalFixed(value.Year10),
        Year11: this.getDecimalFixed(value.Year11),
        Year12: this.getDecimalFixed(value.Year12),
        Year13: this.getDecimalFixed(value.Year13),
        Year14: this.getDecimalFixed(value.Year14),
        Year15: this.getDecimalFixed(value.Year15),
        Year16: this.getDecimalFixed(value.Year16),
        Year17: this.getDecimalFixed(value.Year17),
        Year18: this.getDecimalFixed(value.Year18),
        Year19: this.getDecimalFixed(value.Year19),
        Year20: this.getDecimalFixed(value.Year20),
        Total: this.getDecimalFixed(value.Total),
        RowGroup: value.RowGroup,
        SubRowGroup: value.SubRowGroup
      }
      if (value.rownum != 1.00 && value.rownum != 0.60 && value.rownum != 2.00 && value.rownum != 3.00 && value.rownum != 4.00 &&
        value.rownum != 4.70 && value.rownum != 11.00) {
        rowDataRef.push(dataBind);
      }
      if (value.rownum == 1.00 || value.rownum == 0.60 || value.rownum == 2.00 || value.rownum == 3.00 || value.rownum == 4.00 ||
        value.rownum == 4.70 || value.rownum == 11.00) {

        this.aggRowDataRef.push(dataBind);
      }
    }.bind(this));

    return rowDataRef;
  }
  bindPLCSummaryAnalysisOptAData(obj) {
    console.log(obj);
    let rowDataOptA = [];
    let dataBind = {};
    let plcSummaryAnalysisData = [];
    this.aggRowDataOptA = [];
    plcSummaryAnalysisData = obj;
    plcSummaryAnalysisData.forEach(function (value, key) {
      dataBind = {
        rownum: value.rownum,
        ComponentId: value.ComponentId,
        ComponentTitle: value.ComponentTitle,
        plctype: value.plctype,
        Year1: this.getDecimalFixed(value.Year1),
        Year2: this.getDecimalFixed(value.Year2),
        Year3: this.getDecimalFixed(value.Year3),
        Year4: this.getDecimalFixed(value.Year4),
        Year5: this.getDecimalFixed(value.Year5),
        Year6: this.getDecimalFixed(value.Year6),
        Year7: this.getDecimalFixed(value.Year7),
        Year8: this.getDecimalFixed(value.Year8),
        Year9: this.getDecimalFixed(value.Year9),
        Year10: this.getDecimalFixed(value.Year10),
        Year11: this.getDecimalFixed(value.Year11),
        Year12: this.getDecimalFixed(value.Year12),
        Year13: this.getDecimalFixed(value.Year13),
        Year14: this.getDecimalFixed(value.Year14),
        Year15: this.getDecimalFixed(value.Year15),
        Year16: this.getDecimalFixed(value.Year16),
        Year17: this.getDecimalFixed(value.Year17),
        Year18: this.getDecimalFixed(value.Year18),
        Year19: this.getDecimalFixed(value.Year19),
        Year20: this.getDecimalFixed(value.Year20),
        Total: this.getDecimalFixed(value.Total),
        RowGroup: value.RowGroup,
        SubRowGroup: value.SubRowGroup
      }
      if (value.rownum != 1.00 && value.rownum != 0.60 && value.rownum != 2.00 && value.rownum != 3.00 && value.rownum != 4.00 &&
        value.rownum != 4.70 && value.rownum != 11.00) {
        rowDataOptA.push(dataBind);
      }
      if (value.rownum == 1.00 || value.rownum == 0.60 || value.rownum == 2.00 || value.rownum == 3.00 || value.rownum == 4.00 ||
        value.rownum == 4.70 || value.rownum == 11.00) {

        this.aggRowDataOptA.push(dataBind);
      }
    }.bind(this));

    return rowDataOptA;
  }
  bindPLCSummaryAnalysisOptBData(obj) {
    console.log(obj);
    let rowDataOptB = [];
    let dataBind = {};
    let plcSummaryAnalysisData = [];
    this.aggRowDataOptB = [];
    plcSummaryAnalysisData = obj;
    plcSummaryAnalysisData.forEach(function (value, key) {
      dataBind = {
        rownum: value.rownum,
        ComponentId: value.ComponentId,
        ComponentTitle: value.ComponentTitle,
        plctype: value.plctype,
        Year1: this.getDecimalFixed(value.Year1),
        Year2: this.getDecimalFixed(value.Year2),
        Year3: this.getDecimalFixed(value.Year3),
        Year4: this.getDecimalFixed(value.Year4),
        Year5: this.getDecimalFixed(value.Year5),
        Year6: this.getDecimalFixed(value.Year6),
        Year7: this.getDecimalFixed(value.Year7),
        Year8: this.getDecimalFixed(value.Year8),
        Year9: this.getDecimalFixed(value.Year9),
        Year10: this.getDecimalFixed(value.Year10),
        Year11: this.getDecimalFixed(value.Year11),
        Year12: this.getDecimalFixed(value.Year12),
        Year13: this.getDecimalFixed(value.Year13),
        Year14: this.getDecimalFixed(value.Year14),
        Year15: this.getDecimalFixed(value.Year15),
        Year16: this.getDecimalFixed(value.Year16),
        Year17: this.getDecimalFixed(value.Year17),
        Year18: this.getDecimalFixed(value.Year18),
        Year19: this.getDecimalFixed(value.Year19),
        Year20: this.getDecimalFixed(value.Year20),
        Total: this.getDecimalFixed(value.Total),
        RowGroup: value.RowGroup,
        SubRowGroup: value.SubRowGroup
      }
      if (value.rownum != 1.00 && value.rownum != 0.60 && value.rownum != 2.00 && value.rownum != 3.00 && value.rownum != 4.00 &&
        value.rownum != 4.70 && value.rownum != 11.00) {
        rowDataOptB.push(dataBind);
      }
      if (value.rownum == 1.00 || value.rownum == 0.60 || value.rownum == 2.00 || value.rownum == 3.00 || value.rownum == 4.00 ||
        value.rownum == 4.70 || value.rownum == 11.00) {

        this.aggRowDataOptB.push(dataBind);
      }
    }.bind(this));

    return rowDataOptB;
  }
  bindPLCSummaryAnalysisOptCData(obj) {
    console.log(obj);
    let rowDataOptC = [];
    let dataBind = {};
    let plcSummaryAnalysisData = [];
    this.aggRowDataOptC = [];
    plcSummaryAnalysisData = obj;
    plcSummaryAnalysisData.forEach(function (value, key) {
      dataBind = {
        rownum: value.rownum,
        ComponentId: value.ComponentId,
        ComponentTitle: value.ComponentTitle,
        plctype: value.plctype,
        Year1: this.getDecimalFixed(value.Year1),
        Year2: this.getDecimalFixed(value.Year2),
        Year3: this.getDecimalFixed(value.Year3),
        Year4: this.getDecimalFixed(value.Year4),
        Year5: this.getDecimalFixed(value.Year5),
        Year6: this.getDecimalFixed(value.Year6),
        Year7: this.getDecimalFixed(value.Year7),
        Year8: this.getDecimalFixed(value.Year8),
        Year9: this.getDecimalFixed(value.Year9),
        Year10: this.getDecimalFixed(value.Year10),
        Year11: this.getDecimalFixed(value.Year11),
        Year12: this.getDecimalFixed(value.Year12),
        Year13: this.getDecimalFixed(value.Year13),
        Year14: this.getDecimalFixed(value.Year14),
        Year15: this.getDecimalFixed(value.Year15),
        Year16: this.getDecimalFixed(value.Year16),
        Year17: this.getDecimalFixed(value.Year17),
        Year18: this.getDecimalFixed(value.Year18),
        Year19: this.getDecimalFixed(value.Year19),
        Year20: this.getDecimalFixed(value.Year20),
        Total: this.getDecimalFixed(value.Total),
        RowGroup: value.RowGroup,
        SubRowGroup: value.SubRowGroup
      }
      if (value.rownum != 1.00 && value.rownum != 0.60 && value.rownum != 2.00 && value.rownum != 3.00 && value.rownum != 4.00 &&
        value.rownum != 4.70 && value.rownum != 11.00) {
          rowDataOptC.push(dataBind);
      }
      if (value.rownum == 1.00 || value.rownum == 0.60 || value.rownum == 2.00 || value.rownum == 3.00 || value.rownum == 4.00 ||
        value.rownum == 4.70 || value.rownum == 11.00) {

        this.aggRowDataOptC.push(dataBind);
      }
    }.bind(this));

    return rowDataOptC;
  }
  setRefGridData(obj) {
    let columnDefsGroup = [];
    let totalavilabletrue = 0;
    let columnDefs = this.refGridApi.getColumnDefs();
    obj.forEach((element, index) => {

      columnDefsGroup.push({
        headerName: element, field: 'Year' + (index + 1), editable: false, minWidth: 51,
        tooltipValueGetter: (params) => {

          return params.value;
        },
        allowedAggFuncs: ['sum'],
        valueGetter: params => {
          // put the value in bold
          let currentCol = params.colDef.field;


          if (params.node.group) {
            let val = this.aggRowDataRef.find(function (data) {
              if (data.plctype == params.node.key)
                return true;
            })
            if (val != undefined) {
              return val[currentCol];
            }
          }
          else {
            return params.data[currentCol]
          }
        }

      });
    });
    columnDefsGroup.forEach(function (colDef, index) {
      if (index > 9) {
        colDef.columnGroupShow = 'open';
      }

    });
    columnDefs.forEach(function (colDef, index) {
      if (colDef.headerName == "Years11-20") {
        colDef.children = columnDefsGroup;
      }

    });
    columnDefs.forEach(element => {
      if (element.headerName == "Total") {
        totalavilabletrue = 1;
      }

    });
    this.refGridApi.sizeColumnsToFit();
    if (totalavilabletrue == 0) {
      columnDefs.push({
        headerName: 'Total', field: 'Total', editable: false, minWidth: 51,
        tooltipValueGetter: (params) => {

          return params.value;
        },
        valueGetter: params => {

          if (params.node.group) {


            let val = this.aggRowDataRef.find(function (data) {
              if (data.plctype == params.node.key)
                return true;


            })

            if (val != undefined) {

              return val.Total;
            }

          }
          else {
            return params.data.Total;
          }
        }

      });
    }
    this.refGridApi.setColumnDefs(columnDefs);
    this.refRowData = this.refGridStoredData;


    this.showRefPlcAnalysis = true;
  }
  setTgtGridData(obj) {
    let columnDefsGroup = [];
    let totalavilabletrue = 0;
    let columnDefs = this.tgtGridApi.getColumnDefs();
    obj.forEach((element, index) => {
      columnDefsGroup.push({
        headerName: element, field: 'Year' + (index + 1), editable: false, minWidth: 51,
        tooltipValueGetter: (params) => {
          return params.value;
        },
        allowedAggFuncs: ['sum'],
        //aggFunc: calculateGroupTotal
        valueGetter: params => {
          // put the value in bold
          let currentCol = params.colDef.field;
          if (params.node.group) {
            let val = this.aggRowDataTar.find(function (data) {
              if (data.plctype == params.node.key)
                return true;
            })
            if (val != undefined) {
              return val[currentCol];

            }
          }
          else {
            return params.data[currentCol]
          }
        },

        cellClass: params => {
          if (!params.node.group) {
            if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
              if (params.value != null && params.value != undefined && params.value != "") {
                if (Number(params.value.replace(/,/g, "")) > 0) {
                  return 'positive-num-color'
                }
                return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
              }
            }
          }
        },
      });
    });
    columnDefsGroup.forEach(function (colDef, index) {
      if (index > 9) {
        colDef.columnGroupShow = 'open';
      }
    });
    columnDefs.forEach(function (colDef, index) {
      if (colDef.headerName == "Years11-20") {
        colDef.children = columnDefsGroup;
      }
    });
    columnDefs.forEach(element => {
      if (element.headerName == "Total") {
        totalavilabletrue = 1;
      }

    });
    this.tgtGridApi.sizeColumnsToFit();
    if (totalavilabletrue == 0) {
      columnDefs.push({
        headerName: 'Total', field: 'Total', editable: false, minWidth: 51,
        tooltipValueGetter: (params) => {
          return params.value;
        },
        valueGetter: params => {
          if (params.node.group) {
            let val = this.aggRowDataTar.find(function (data) {
              if (data.plctype == params.node.key)
                return true;
            })
            if (val != undefined) {
              return val.Total;
            }
          }
          else {
            return params.data.Total;
          }
        },
        cellClass: params => {
          if (!params.node.group) {
            if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
              if (params.value != null && params.value != undefined && params.value != "") {
                if (Number(params.value.replace(/,/g, "")) > 0) {
                  return 'positive-num-color'
                }
                return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
              }

            }
          }
        },
      });
    }
    this.tgtGridApi.setColumnDefs(columnDefs);
    this.tgtRowData = this.tgtGridStoredData;
    this.showTgtPlcAnalysis = true;
  }
  setoptAGridData(obj) {
    console.log("4");
    let columnDefs = this.optAGridApi.getColumnDefs();
    let columnDefsGroup = []

    obj.forEach((element, index) => {
      columnDefsGroup.push({
        headerName: element, field: 'Year' + (index + 1), editable: false, minWidth: 51,
        tooltipValueGetter: (params) => {

          return params.value;
        },
        allowedAggFuncs: ['sum'],

        valueGetter: params => {
          // put the value in bold
          let currentCol = params.colDef.field;
          if (params.node.group) {
            let val = this.aggRowDataOptA.find(function (data) {
              if (data.plctype == params.node.key)
                return true;
            })
            if (val != undefined) {
              return val[currentCol];
            }
          }
          else {
            return params.data[currentCol]
          }
        },
        cellClass: params => {

          if (!params.node.group) {
            if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
              if (params.value != null && params.value != undefined && params.value != "") {
                if (Number(params.value.replace(/,/g, "")) > 0) {
                  return 'positive-num-color'
                }
                return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
              }

            }
          }
        },
      });
    });
    columnDefsGroup.forEach(function (colDef, index) {
      if (index > 9) {
        colDef.columnGroupShow = 'open';
      }

    });
    columnDefs.forEach(function (colDef, index) {
      if (colDef.headerName == "Years11-20") {
        colDef.children = columnDefsGroup;
      }

    });
    this.optAGridApi.sizeColumnsToFit();
    columnDefs.push({
      headerName: 'Total', field: 'Total', editable: false, minWidth: 51,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      valueGetter: params => {
        if (params.node.group) {

          let val = this.aggRowDataOptA.find(function (data) {
            if (data.plctype == params.node.key)
              return true;
          })
          if (val != undefined) {
            return val.Total;
          }
        }
        else {
          return params.data.Total;
        }
      },
      cellClass: params => {
        if (!params.node.group) {
          if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
            if (params.value != null && params.value != undefined && params.value != "") {
              if (Number(params.value.replace(/,/g, "")) > 0) {
                return 'positive-num-color'
              }
              return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
            }

          }
        }
      },
    });
    this.optAGridApi.setColumnDefs(columnDefs);
    this.optARowData = this.optAGridStoredData;
    this.showoptAPlcAnalysis = true;
  }
  setoptBGridData(obj) {
    let columnDefsGroup = []
    let columnDefs = this.optBGridApi.getColumnDefs();
    obj.forEach((element, index) => {

      columnDefsGroup.push({
        headerName: element, field: 'Year' + (index + 1), editable: false, minWidth: 51,
        tooltipValueGetter: (params) => {

          return params.value;
        },
        allowedAggFuncs: ['sum'],
        valueGetter: params => {
          // put the value in bold
          let currentCol = params.colDef.field;
          if (params.node.group) {
            let val = this.aggRowDataOptB.find(function (data) {
              if (data.plctype == params.node.key)
                return true;
            })

            if (val != undefined) {
              return val[currentCol];
            }
          }
          else {
            return params.data[currentCol]
          }
        },
        cellClass: params => {
          if (!params.node.group) {
            if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
              if (params.value != null && params.value != undefined && params.value != "") {
                if (Number(params.value.replace(/,/g, "")) > 0) {
                  return 'positive-num-color'
                }
                return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
              }

            }
          }
        },
      });
    });
    columnDefsGroup.forEach(function (colDef, index) {
      if (index > 9) {
        colDef.columnGroupShow = 'open';
      }

    });
    columnDefs.forEach(function (colDef, index) {
      if (colDef.headerName == "Years11-20") {
        colDef.children = columnDefsGroup;
      }

    });
    this.optBGridApi.sizeColumnsToFit();
    columnDefs.push({
      headerName: 'Total', field: 'Total', editable: false, minWidth: 51,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      valueGetter: params => {

        if (params.node.group) {


          let val = this.aggRowDataOptB.find(function (data) {
            if (data.plctype == params.node.key)
              return true;


          })

          if (val != undefined) {

            return val.Total;
          }

        }
        else {
          return params.data.Total;
        }
      },
      cellClass: params => {

        if (!params.node.group) {
          if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
            if (params.value != null && params.value != undefined && params.value != "") {
              if (Number(params.value.replace(/,/g, "")) > 0) {
                return 'positive-num-color'
              }
              return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
            }

          }
        }
      },
    });
    this.optBGridApi.setColumnDefs(columnDefs);
    this.optBRowData = this.optBGridStoredData;
    this.showoptBPlcAnalysis = true;
  }
  onoptCGridReady(params) {

    this.optCGridApi = params.api;
    this.optCGridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onDisplayedColumnsCChanged(event) {

    if (this.sizeToFitRefresh != 0) {
      this.optCGridApi.sizeColumnsToFit()
    }
  }
  onoptCGridSizeChanged(params) {
    this.optCGridApi = params.api;
    this.optCGridApi.sizeColumnsToFit();
  }

  setoptCGridData(obj) {
    let columnDefsGroup = []
    let columnDefs = this.optCGridApi.getColumnDefs();
    obj.forEach((element, index) => {

      columnDefsGroup.push({
        headerName: element, field: 'Year' + (index + 1), editable: false, minWidth: 51,
        tooltipValueGetter: (params) => {

          return params.value;
        },
        allowedAggFuncs: ['sum'],

        valueGetter: params => {
          // put the value in bold
          let currentCol = params.colDef.field;
          if (params.node.group) {
            let val = this.aggRowDataOptC.find(function (data) {
              if (data.plctype == params.node.key)
                return true;
            })
            if (val != undefined) {
              return val[currentCol];
            }
          }
          else {
            return params.data[currentCol]
          }
        },
        cellClass: params => {

          if (!params.node.group) {
            if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
              if (params.value != null && params.value != undefined && params.value != "") {
                if (Number(params.value.replace(/,/g, "")) > 0) {
                  return 'positive-num-color'
                }
                return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
              }

            }
          }
        },
      });
    });
    columnDefsGroup.forEach(function (colDef, index) {
      if (index > 9) {
        colDef.columnGroupShow = 'open';
      }

    });
    columnDefs.forEach(function (colDef, index) {
      if (colDef.headerName == "Years11-20") {
        colDef.children = columnDefsGroup;
      }

    });
    this.optCGridApi.sizeColumnsToFit();
    columnDefs.push({
      headerName: 'Total', field: 'Total', editable: false, minWidth: 51,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      valueGetter: params => {

        if (params.node.group) {
          let val = this.aggRowDataOptC.find(function (data) {
            if (data.plctype == params.node.key)
              return true;
          })
          if (val != undefined) {
            return val.Total;
          }
        }
        else {
          return params.data.Total;
        }
      },
      cellClass: params => {

        if (!params.node.group) {
          if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
            if (params.value != null && params.value != undefined && params.value != "") {
              if (Number(params.value.replace(/,/g, "")) > 0) {
                return 'positive-num-color'
              }
              return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
            }

          }
        }
      },

    });
    this.optCGridApi.setColumnDefs(columnDefs);
    this.optCRowData = this.optCGridStoredData;



    this.showoptCPlcAnalysis = true;
  }

  public openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  getDecimalFixed(obj) {
    return obj != null && obj != "" ? new Intl.NumberFormat(countryCode).format(obj.toFixed()) : "0";
  }

  onrefGridReady(params) {

    this.refGridApi = params.api;
    this.refGridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onDisplayedColumnsRefChanged(event) {

    if (this.sizeToFitRefresh != 0) {
      this.refGridApi.sizeColumnsToFit()
    }
  }

  onrefGridSizeChanged(params) {
    this.refGridApi = params.api;
    this.refGridApi.sizeColumnsToFit();
  }
  ontgtGridReady(params) {
    this.tgtGridApi = params.api;
    this.tgtGridColumnApi = params.columnApi;
    this.sizeToFitRefresh = 1;
  }

  onDisplayedColumnsTarChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.tgtGridApi.sizeColumnsToFit();
    }
  }

  ontgtGridSizeChanged(params) {
    this.tgtGridApi = params.api;
    this.tgtGridApi.sizeColumnsToFit();
  }
  onoptAGridReady(params) {
    console.log("1");
    this.optAGridApi = params.api;
    this.optAGridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }


  onDisplayedColumnsAChanged(event) {

    if (this.sizeToFitRefresh != 0) {
      this.optAGridApi.sizeColumnsToFit()
    }
  }
  onoptAGridSizeChanged(params) {
    this.optAGridApi = params.api;
    this.optAGridApi.sizeColumnsToFit();
  }
  onoptBGridReady(params) {

    this.optBGridApi = params.api;
    this.optBGridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onDisplayedColumnsBChanged(event) {

    if (this.sizeToFitRefresh != 0) {
      this.optBGridApi.sizeColumnsToFit()
    }
  }
  onoptBGridSizeChanged(params) {
    this.optBGridApi = params.api;
    this.optBGridApi.sizeColumnsToFit();
  }
  onRowClicked(event) {
    this.refGridApi.sizeColumnsToFit();
  }

  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }

  onClickNext() {

  }

  ngOnDestroy(): void {
    this.configSub.unsubscribe();
  }
}
