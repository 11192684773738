import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TcPlcSummaryService {
  private urlGetTCPLCSummaryAnalysis: string = environment.baseURL + "api/TCPLCSummary/GetTCPLCSummaryAnalysis";

  constructor(private http: HttpClient) { }

  getTCPLCSummaryAnalysis(configurationId, scenarioType) {
    return this.http.get(this.urlGetTCPLCSummaryAnalysis +'?configurationID=' + configurationId + '&scenarioType='+ scenarioType).pipe(
      retry(1),
      catchError(this.handleError))
  }
  

  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
