import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable,throwError } from 'rxjs';
import { Comments } from  '../models/comment';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TcDirectcostserviceService {

  constructor(private http: HttpClient) { }
  private urlgetDirectcostsData: string = environment.baseURL + "api/TCDirectCost/GetTCDirectCosts";
 
  getDirectcostsData(configurationId): Observable<any> {
    let _configurationId = Number(configurationId);
    return this.http.get<any>(this.urlgetDirectcostsData + '?configurationId=' + _configurationId );
  }
  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
