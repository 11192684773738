import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, Observable, throwError } from 'rxjs';
import { catchError, concatMap, retry, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialMasterService {

  saveProgress: BehaviorSubject<any> = new BehaviorSubject(0);
  progress = this.saveProgress.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  //This is used to get the material master data
  getAllMaterialMasterData(pageNumber: number, pageSize: number, sortColumn: string, sortDirection: string, businessAreaId: number, businessLineId: number): Observable<any> {
    return this.http.get<any>(environment.baseURL + "api/Material/GetMasterMaterialDetails" +
      "?pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&sortColumn=" + sortColumn + "&sortDirection=" + sortDirection +
      "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId).pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  //This is used to insert and Update the material master data
  updateData(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    return this.http.post<any>(environment.baseURL + "api/Material/InsertOrUpdateBulkDataForMaterial", data, httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  updateDataInChunk(data): Observable<any> {
    debugger;
    const chunkSize = 1000;

    let FullData = data.listMasterMaterialData;

    const totalChunks = Math.ceil(FullData.length / chunkSize);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    const requests = [];
    let completedChunks = 0;
    return from(Array.from({ length: totalChunks }, (_, i) => i)).pipe(
      concatMap((i) => {
        let start = i*chunkSize;
        let end = ((i+1)*chunkSize > FullData.length) ? FullData.length : (i+1)*chunkSize;
        const chunk = FullData.slice(start, end);
        data.listMasterMaterialData = chunk;
        data.chunkStartSize = start;
        data.chunkEndSize = end;
        data.TotalRecord = FullData.length;
  
        return this.http.post<any>(
          `${environment.baseURL}api/Material/InsertOrUpdateBulkDataForMaterial`,
          data,
          httpOptions
        ).pipe(
          tap(() => {
            //Update progress bar on successful upload of each chunk
            let progress = Math.round((completedChunks / totalChunks) * 100);
            progress = progress == 100 ? 0 : progress;
            completedChunks++;
            this.saveProgress.next(progress);
          })
        );
      }),
      catchError(this.handleError) // Catch errors
    );





    // for (let i = 0; i < totalChunks; i++) {
    //   const chunk = FullData.slice(i * chunkSize, (i + 1) * chunkSize)
    //   data.listMasterMaterialData = chunk;
      
    //   const request = this.http.post<any>(environment.baseURL + "api/Material/InsertOrUpdateBulkDataForMaterial", data, httpOptions).pipe(
    //     retry(1),
    //     catchError(this.handleError)
    //   );
        
    //   requests.push(request);
    // }

    // return forkJoin(requests);

  };

  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
