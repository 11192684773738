import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HelpService } from 'src/app/services/help.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { CheckboxComponent } from '../../admin/checkbox/checkbox.component';
import { messages } from 'src/app/popUpMessages/messages';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { DeleteiconComponent } from '../../deleteIconNormalGrid/deleteicon.component';
import { ConfigurationKmatComponent } from '../configuration-kmat/configuration-kmat.component';
import { ProjectService } from 'src/app/services/project.service';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { EditDeleteComponent } from 'src/app/grid/button-edit/edit-delete/edit-delete.component';
import { LockIconComponent } from 'src/app/lock-icon/lock-icon.component';
import moment from 'moment';
import { QuicklinksComponent } from '../quicklinks/quicklinks.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationComponent implements OnInit {

  public rowData: any = [];
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowHeight: any;
  public headerHeight: any;
  public alertErrorBox = [];
  public errorMessage = []
  public loggedInUser: any;
  public getResponse: any;
  public frameworkComponents: any;
  public idGenerated: number = 1;
  public hasUnsavedChanges: boolean = false;
  public submitted: boolean = false;
  public isEditable: boolean = false;

  public sizeToFitRefresh: number = 0;
  public showGrid = false;

  public businessAreaId: number;
  public businessLineId: number;
  public userInOrg: any;
  public allUserList: any;
  public modifiedConfigurationRows = []
  projectSubmitterId: any;

  submitResponse: any;
  receivedData: any;
  totalDataCount: any;
  projectId: any;
  moduleId: number = 4;
  objData: any;
  userDetails: [];
  configurationData: any;
  configurationFromLocal: any;
  allConfigurations: any;
  lockVisible: boolean;
  isAdmin: boolean;

  constructor(
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private configurationService: ConfigurationService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private router: Router,
    private menuService: MenuService,
    private userService: UserInfoService,
    private helpService: HelpService,
  ) { }

  ngOnInit() {
    this.loadGrid();
    this.sharedService.getProjectIdAndTitle();

    this.projectId = this.localStorageService.get("projectId");
    this.objData = {
      projectId: this.projectId,
      moduleId: this.moduleId
    }
    if (this.projectId != null) {
      this.projectService.getTCData(this.projectId).subscribe((data: any) => {
        if (data.StatusCode == 200) {
          this.businessAreaId = data.Data.BusinessAreaId;
          this.businessLineId = data.Data.BusinessLineId;
          this.sharedService.loggedInUser.subscribe((user: any) => {
            if (user) {
              this.showGrid = true
              this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
                this.allUserList = d;
                this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
                if (this.userInOrg != undefined) {
                  console.log("user", this.userInOrg, data.Data);
                  this.projectSubmitterId = this.userInOrg.UserId;

                  if (this.userInOrg.UserId == data.Data.FinancialControllerID) {
                    this.lockVisible = true;
                  }

                  if (this.userInOrg.targetRoleId == 1) {
                    this.isAdmin = true;
                  }

                  if (this.userInOrg.TargetRoleId == 1 || this.userInOrg.TargetRoleId == 2) {
                    this.isEditable = true;
                  }
                  else if (this.userInOrg.TargetRoleId == 3) {
                    if (data.Data.SubmitterId == this.userInOrg.UserId || data.Data.FinancialControllerId == this.userInOrg.UserId
                      || data.Data.ProductManagerId == this.userInOrg.UserId || data.Data.ProjectManagerId == this.userInOrg.UserId ||
                      data.Data.ServiceManagerId == this.userInOrg.UserId || data.Data.ProcurementEngineerId == this.userInOrg.UserId) {
                      this.isEditable = true;
                    }
                    else {
                      this.isEditable = false;
                    }
                  }
                }
                this.loadData();
              })
            }
          })
        }
      });
    } else {
      this.businessAreaId = this.businessLineId = 0;
    }
  }

  loadData() {
    this.showGrid = false;

    this.configurationFromLocal = this.localStorageService.get("configuration");
    this.configurationService.getConfigurationData(this.projectId).subscribe(d => {
      this.showGrid = true;
      let errorMsgeFromBack = d.Data.Message;
      if (d.StatusCode == 200) {
        this.configurationData = d.Data;
        console.log(this.configurationData, this.configurationFromLocal);

        this.configurationData.forEach(data => {
          data["IsAdmin"] = this.isAdmin
          data["IsEditable"] = this.isEditable
          data["LockVisible"] = this.lockVisible
        })
        this.rowData = this.configurationData;
        if (this.configurationFromLocal != null) {
          this.hasUnsavedChanges = true;
          if (this.configurationFromLocal.configurationId == 0)
            this.addNewRow();
          else {
            let row = this.configurationData.find(x => x.Id == this.configurationFromLocal.configurationId);
            this.modifiedConfigurationRows.push(row);
          }
        }
      } else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    });
  }

  loadGrid() {
    this.columnDefs = [
      {
        headerName: "ID",
        field: "Id",
        tooltipField: "id",
        headerTooltip: "ID",
        width: 80,
      },
      {
        headerName: "Name",
        field: "Name",
        tooltipField: "Name",
        headerTooltip: "Name",
        cellEditor: 'agTextCellEditor',
        singleClickEdit: true,
        width: 480,
        editable: (params) => {
          return ((this.isAdmin) || (!params.data.IsLock && this.isEditable))
        },
        cellStyle: (params) => (params.data.Name == "Add Name" || params.data.Name == "") ? { borderLeft: "5px solid #a94442" } : { borderLeft: "" },
      },
      {
        headerName: "EQ",
        field: "eq",
        tooltipField: "eq",
        headerTooltip: "EQ",
        width: 80,
        cellRendererFramework: QuicklinksComponent,
      },
      {
        headerName: "CS",
        field: "cs",
        tooltipField: "cs",
        headerTooltip: "CS",
        width: 80,
        cellRendererFramework: QuicklinksComponent,
      },
      {
        headerName: "SCM",
        field: "scm",
        tooltipField: "scm",
        headerTooltip: "SCM",
        width: 80,
        cellRendererFramework: QuicklinksComponent,
      },
      {
        headerName: "LC",
        field: "lc",
        tooltipField: "lc",
        headerTooltip: "LC",
        width: 80,
        cellRendererFramework: QuicklinksComponent,
      },
      {
        headerName: "Inv",
        field: "inv",
        tooltipField: "inv",
        headerTooltip: "Inv",
        width: 80,
        cellRendererFramework: QuicklinksComponent,
      },
      {
        headerName: "TDC",
        field: "tdc",
        tooltipField: "tdc",
        headerTooltip: "TDC",
        width: 80,
        cellRendererFramework: QuicklinksComponent,
      },
      {
        headerName: "PLC",
        field: "plc",
        tooltipField: "plc",
        headerTooltip: "PLC",
        width: 80,
        cellRendererFramework: QuicklinksComponent,
      },
      {
        headerName: "Floor",
        field: "IsFloor",
        tooltipField: "IsFloor",
        headerTooltip: "Floor",
        cellRendererFramework: CheckboxComponent,
        width: 80,
      },
      {
        headerName: "Ceiling",
        field: "IsCeiling",
        tooltipField: "IsCeiling",
        headerTooltip: "Ceiling",
        cellRendererFramework: CheckboxComponent,
        width: 80,
      },
      {
        headerName: "Biplane",
        field: "IsBiplane",
        tooltipField: "IsBiplane",
        headerTooltip: "Biplane",
        cellRendererFramework: CheckboxComponent,
        width: 80,
      },
      {
        headerName: "MP",
        field: "IsMP",
        tooltipField: "IsMP",
        headerTooltip: "MP",
        cellRendererFramework: CheckboxComponent,
        width: 80,
      },
      {
        headerName: "Pheno",
        field: "IsPheno",
        tooltipField: "IsPheno",
        headerTooltip: "Pheno",
        cellRendererFramework: CheckboxComponent,
        width: 80,
      },
      {
        headerName: "FD",
        field: "IsFD",
        tooltipField: "IsFD",
        headerTooltip: "FD",
        cellRendererFramework: CheckboxComponent,
        width: 80,
      },
      {
        headerName: "I.I.",
        field: "IsII",
        tooltipField: "IsII",
        headerTooltip: "I.I.",
        cellRendererFramework: CheckboxComponent,
        width: 80,
      },
      {
        headerName: "Created by",
        field: "CreatedUserName",
        tooltipField: "CreatedBy",
        headerTooltip: "Created by",
        width: 150,
      },
      {
        headerName: "Created on",
        field: "CreatedOn",
        tooltipField: "CreatedOn",
        valueFormatter: (params) => params.value == null ? null : moment(params.value).format('DD/MM/YYYY'),
        headerTooltip: "Created on",
        width: 150,
      },
      {
        headerName: "Modified by",
        field: "ModifiedUserName",
        tooltipField: "ModifiedBy",
        headerTooltip: "Modified by",
        width: 150,
      },
      {
        headerName: "Modified on",
        field: "ModifiedOn",
        tooltipField: "ModifiedOn",
        valueFormatter: (params) => params.value == null ? null : moment(params.value).format('DD/MM/YYYY'),
        headerTooltip: "Modified on",
        width: 150,
      },
      {
        headerName: "Milestone",
        field: "MilestoneName",
        tooltipField: "milestone",
        headerTooltip: "Milestone",
        width: 120,
      },
      {
        filter: false,
        headerName: "Lock",
        field: "IsLock",
        tooltipField: "lock",
        headerTooltip: "Lock",
        cellRendererFramework: LockIconComponent,
        width: 100,
      },
      {
        filter: false,
        //width: 350,
        headerName: 'Action',
        headerTooltip: "Action",
        cellRendererFramework: EditDeleteComponent,
        width: 240,
      },
    ];
    this.showGrid = true;
    this.defaultColDef = {
      filter: true,
      sortable: true,
      singleClickEdit: true,
      editable: false
    };
    this.domLayout = 'autoHeight';
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    if (params.data.Id != 0) {
      let isPushed = false;
      if (this.modifiedConfigurationRows.length == 0) {
        this.modifiedConfigurationRows.push(params.data);
        isPushed = true;
      }
      else {
        this.modifiedConfigurationRows.forEach(row => {
          if (params.data.Id == row.Id) {
            isPushed = true;
          }
        })
      }
      if (!isPushed) {
        this.modifiedConfigurationRows.push(params.data);
      }
    }
  }

  addNewRow() {
    this.hasUnsavedChanges = true;
    this.alertErrorBox = [];
    let errorMessage = messages.cellEmptyErrorMesssage;
    let error = [];

    this.rowData.push({
      Id: 0,
      Name: "Add Name",
      eq: null,
      cs: null,
      scm: null,
      lc: null,
      inv: null,
      tdc: null,
      plc: null,
      IsFloor: false,
      IsCeiling: false,
      IsBiplane: false,
      IsMP: false,
      IsPheno: false,
      IsFD: false,
      IsII: false,
      CreatedUserName: this.userInOrg?.FullName,
      CreatedOn: new Date(),
      ModifiedUserName: null,
      ModifiedOn: null,
      MileStoneName: '',
      IsEditable: true,
      LockVisible: false,
    });
    this.idGenerated += 1;

  }

  addNewConfiguration() {
    this.alertErrorBox = [];
    let errorMessage = messages.cellEmptyErrorMesssage;
    let error = [];
    let lastRow = this.gridApi.getDisplayedRowAtIndex(this.gridApi.getDisplayedRowCount() - 1);

    if (lastRow != undefined) {
      let data = lastRow.data;

      if (data.Name == "" || data.Name == "Add Name") {
        error.push({ Name: errorMessage })
      }
      if (error.length != 0) {
        this.openAlertDialog(error);
        return false;
      }
    }
    let dialogRef = this.dialog.open(ConfigurationKmatComponent, {
      // maxHeight: '60vh',
      // maxWidth: '40vw',
      height: '60%',
      width: '40%',
      panelClass: 'kmat-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != "" && result != undefined) {
        let configuration = {
          configurationId: 0,
          selectedKmat: result.KMATId,
          addedVblo: null,
          removedVblo: null
        };
        this.localStorageService.set("configuration", configuration);
        this.router.navigate(['/tc-configuration-vblo']);
      }
    });
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.localStorageService.remove("configuration");
    this.router.navigate(["/tc-manageProject"]);
  }

  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }

  onClickNext() {
    this.localStorageService.remove("configuration");
    this.menuService.onClickNextAndBack("next");
  }

  submit(event?: any) {
    if (!this.hasUnsavedChanges && event == undefined) {
      return true
    }
    let errorMessage = messages.cellEmptyErrorMesssage;
    let error = [];
    let lastRow = this.gridApi.getDisplayedRowAtIndex(this.gridApi.getDisplayedRowCount() - 1);

    if (lastRow != undefined) {
      let data = lastRow.data;

      if (data.Name == "" || data.Name == "Add Name") {
        error.push({ Name: errorMessage })
      }
    }
    if (error.length != 0) {
      this.openAlertDialog(error);
      return false;
    }
    else {
      let response = null;
      this.showGrid = false;
      this.submitted = true;

      let gridData = this.modifiedConfigurationRows;
      let sendData = this.sendData(gridData);

      response = this.configurationService.saveConfiguration(sendData).toPromise().
        then((res) => {
          this.showGrid = true;
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            if (errorCode == 0) {
              this.localStorageService.set("ConfigId", null);
              this.sharedService.changeConfigId(0);
              this.hasUnsavedChanges = false;
              let dataSaveMessage = serverMessage.dataSaveMessage;
              this.toast.notify(dataSaveMessage, "success");
              if (event != undefined) {
                this.localStorageService.remove("configuration");
                this.hasUnsavedChanges = false;
                this.router.navigate(['/tc-manageProject'])
              }
              return true;
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          }
          else {
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }
        },
          (error) => {
            this.submitted = false;
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            return false;
          })

      return response;
    }
  }

  sendData(gridData) {
    this.gridApi.forEachNode(node => {
      if (node.data.Id == 0 && node.data.Name != "Add Title") {
        this.hasUnsavedChanges = true;
        gridData.push(node.data);
      }
    })

    let lstConfiguration = [];

    for (let i of gridData) {
      lstConfiguration.push({
        Id: i.Id,
        Name: i.Name,
        IsFloor: i.IsFloor,
        IsCeiling: i.IsCeiling,
        IsBiplane: i.IsBiplane,
        IsMP: i.IsMP,
        IsPheno: i.IsPheno,
        IsFD: i.IsFD,
        IsII: i.IsII,
        CreatedBy: this.userInOrg.UserId,
        CreatedOn: new Date(),
        ModifiedBy: null,
        ModifiedOn: null,
        KMATId: 0,
        AddKMATVBLOIDs: [],
        RemoveKMATVBLOIDs: [],
      })
    }

    if (this.configurationFromLocal != null) {
      lstConfiguration.forEach(c => {
        if (c.Id == this.configurationFromLocal.configurationId) {
          c.KMATId = this.configurationFromLocal.selectedKmat;
          c.AddKMATVBLOIDs = this.configurationFromLocal.addedVblo;
          c.RemoveKMATVBLOIDs = this.configurationFromLocal.removedVblo;
        }
      })
    }

    let tcConfigurationData = {
      projectId: this.projectId,
      userId: this.userInOrg.UserId,
      lsttcconfiguration: lstConfiguration
    }

    return tcConfigurationData;
  }
}
