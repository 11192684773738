<app-progress-spinner *ngIf="!showGrid || importing"></app-progress-spinner>

<div class="form-row container">
    <div class="form-group col-md-3">
          <label for="businessArea" class="label">Business Area</label>
          <select [(ngModel)]="selectBusinessArea" (change)="updateBusinessLine()" id="businessArea"
                class="form-control" aria-label="businessArea" [disabled]="this.disableBusinessAreaDropdown == true">
                <option value="">Please select</option>
                <option *ngFor="let ba of roleBusinessArea" [value]="ba.BusinessAreaId">
                      {{ba.BusinessAreaName}}
                </option>
          </select>
    </div>
    <div class="form-group col-md-3">
          <label for="businessLine" class="label">Business Line</label>
          <select [(ngModel)]="selectBusinessLine" (change)="updateMaterialMaster()" id="businessLine" class="form-control"
                aria-label="businessLine" [disabled]="this.disableBusinessLineDropdown == true">
                <option value="">Please select</option>
                <option *ngFor="let bl of roleBusinessLine" [value]="bl.BusinessLineId">
                      {{bl.BusinessLineName}}
                </option>
          </select>
    </div>
    <div class="form-group col-sm-3">
          <button style="float: right; margin-top: 20px; " class="button button--small" (click)=exportGridData()
                *ngIf="!hideExportImport"> Export To Excel</button>
    </div>
    <div class="form-group col-sm-2">
          <input hidden type="file" accept=".xlsx, .xls" id="file" #file (change)="importExcel($event)">
          <button style="float: left; margin-top: 20px; " class="button button--small" (click)="file.click()"
                *ngIf="!hideExportImport">Import Excel</button>
    </div>
</div>
<br>

<div class="container" *ngIf="showGrid">
    <div class="card" style="height: 100%">
        <div class="label-header" style="width: 100%; margin-top: 0px">
            <label class="label-headercolor">Material Master</label>
        </div>

        <div class="card-body" style="padding: 10px">
            <ag-grid-angular
                id="MaterialMasterGrid"
                style="width: 100%; height: 500px"
                class="ag-theme-alpine"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                [pagination]="true"
                (cellValueChanged)="onCellValueChanged($event)"
                (gridSizeChanged)="onGridSizeChanged($event)"
                [enableBrowserTooltips]="true"
                [frameworkComponents]="frameworkComponents"
            >
            </ag-grid-angular>
            <div class="row">
                <div class="offset-4 col-md-8">
                    <div class="buttonStyle">
                        <button class="button button--small" style="margin-right: 5px" type="submit"
                            (click)="onCancel()">
                            Cancel
                        </button>
                        <button class="button button--small" type="submit" (click)="submit('true')">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>        
</div>
