import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs';
import { IdeaformService } from './ideaform.service';
import { LocalStorageService } from './local-storage.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MenuService } from './menu.service';
import { CategorizationService } from './categorization.service';
import { Router } from '@angular/router';
import { serverMessage } from './../popUpMessages/serverMessage';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './../alert-dialog/alert-dialog.component';
import { ProjectService } from './project.service';
import { ConfigurationService } from './configuration.service';
import { Milestone } from '../models/Milestone';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  @Output() fire: EventEmitter<any> = new EventEmitter();
  invokeFirstComponentFunction = new EventEmitter();
  riskAssessment = new EventEmitter();
  directCostReferenceSc = new EventEmitter();
  directCostTargetSc = new EventEmitter();

  ideaformCheckOptions = new EventEmitter();
  ideaformCheckCancel = new EventEmitter();
  catgorizationOnClickOk = new EventEmitter()
  catgorizationOnClickCancel = new EventEmitter()

  plcComplexityRiskComplex = new EventEmitter();
  plcComplexityRiskRisk = new EventEmitter();
  plcDirectCostOptionA = new EventEmitter();
  plcDirectCostOptionB = new EventEmitter();
  plcDirectCostOptionC = new EventEmitter();
  subProcessTotal = new EventEmitter();
  impactOrgDeletedRows = new EventEmitter();
  subprocessDeletedRows = new EventEmitter();
  tcsubprocessDeletedRows = new EventEmitter();
  userManagementDeletedRows = new EventEmitter();
  isPlcRequiredCategorization = new EventEmitter();
  userMangementObj = new EventEmitter();
  productManagementDeletedRows = new EventEmitter();

  //TargetCosting
  projectMMPgDeletedRows = new EventEmitter();

  //Configuration
  configurationDeletedRows = new EventEmitter();

  //EQ
  eqAddedRow = new EventEmitter();

  // impact people picker emit;
  peoplePickerGridApi = new EventEmitter();
  // admin page;

  userManagement = new EventEmitter();
  userReplacement = new EventEmitter();
  help = new EventEmitter();
  masterData = new EventEmitter();
  mdUnits = new EventEmitter();
  productManagement = new EventEmitter();
  actuals = new EventEmitter();
  periodLock = new EventEmitter();
  fxtranslation = new EventEmitter();
  overheadCost = new EventEmitter();

  accessMsgObj = new BehaviorSubject<string>("");

  plcDirectCostOptionBSub: Subscription;
  plcDirectCostOptionCSub: Subscription;
  plcDirectCostOptionASub: Subscription;
  directCostRefSc: Subscription;
  directCostTarSc: Subscription;
  complxAssesment: Subscription;
  riskAssesment: Subscription;
  ideaformCheckOption: Subscription;
  plcCmplxRiskComplxSub: Subscription;
  ideaformCheckOptionCancel: Subscription;
  plcComplexityRiskRiskSub: Subscription;
  ideaformCheckCancelSub: Subscription;
  ideaformCheckOptionsSub: Subscription;

  data: Subject<any> = new Subject();
  idea: any;
  project: any;
  ideaProductivity: any;
  catData: any;
  routeURL: BehaviorSubject<string> = new BehaviorSubject('');
  routeUrl: BehaviorSubject<string> = new BehaviorSubject("")
  loggedInUser: BehaviorSubject<string> = new BehaviorSubject(null);
  ideaCreatorId: BehaviorSubject<string> = new BehaviorSubject("");
  ideaSubmitterId: BehaviorSubject<number> = new BehaviorSubject(0);
  user: any;
  isEditable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loggedInUserGID = new BehaviorSubject("");
  colHeadernamevalue: any;

  projectData: Subject<any> = new Subject();
  configurationId: BehaviorSubject<any> = new BehaviorSubject(0);
  projectSubmitterId: BehaviorSubject<number> = new BehaviorSubject(0);

  public errorMessageApi = [];
  configuation: any;
  configuationData: string[];

  constructor(
    private localStorage: LocalStorageService,
    private ideaservice: IdeaformService,
    private menuSvc: MenuService,
    private catService: CategorizationService,
    private router: Router,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private configurationkservice: ConfigurationService,
  ) {
  }
  getIdeaIdAndtitle() {
    let ideaId = this.localStorage.get('ideaId')
    if (ideaId == null) {
      this.data.next({ ideaId: null, title: null, currency: null, productivity: 0 })
      this.loggedInUser.subscribe((user: any) => {
        if (user) {
          this.ideaSubmitterId.next(user.UserId)
        }
      })
    }
    else {
      this.ideaservice.getData(ideaId).subscribe((res: any) => {
        if (res.StatusCode == 200) {
          this.idea = res;
          // changes comparision is done on submitter Id; Previous value was contactBy;
          let ideaCreatorId = this.idea.Data.SubmitterId;
          let submitterId = 0;
          this.loggedInUser.subscribe((user: any) => {
            if (user) {
              submitterId = user.UserId
              this.ideaSubmitterId.next(submitterId)
            }
          })
          this.ideaCreatorId.next(ideaCreatorId)

          //Set Idea Productivity and Marque Message
          this.ideaservice.getIdeaProductivityData(ideaId).subscribe((res: any) => {
            if (res.StatusCode == 200) {
              this.ideaProductivity = res;
              this.data.next({
                ideaId: ideaId, title: this.idea.Data.Title,
                currency: this.ideaProductivity.Data.CurrencyId,
                productivity: this.ideaProductivity.Data.IdeaProductivity,
                marqueeMessage: this.ideaProductivity.Data.MarqueMessage
              });
            }
          });
        }
      })
    }
    this.routeURL.next(this.router.url)
    if (!['/manageIdea', '/manageData'].includes(this.router.url)) {
      this.menuSvc.getUpdateMenuData(this.router.url)
      this.menuSvc.getHelpIdForMenu(this.router.url);
    }
    this.getPermissionForEdit()
  }

  getProjectIdAndTitle() {
    let projectId = this.localStorage.get('projectId');
    if (projectId == null) {
      //this.projectData.next({ projectId: null, title: null });
      this.projectData.next({ projectId: null, title: null, milestoname: null, configuration: null, stand: null })
      this.loggedInUser.subscribe((user: any) => {
        if (user) {
          this.projectSubmitterId.next(user.UserId);
        }
      })
    } else {
      this.projectService.getTCData(projectId).subscribe((res: any) => {
        if (res.StatusCode == 200) {
          this.project = res.Data;
          if (this.configurationId.getValue() == 0) {
            this.configurationkservice.getConfigurationData(projectId).subscribe((res: any) => {
              if (res.StatusCode == 200) {
                //this.configuationData = res.Data;
                let configuation = [];
                for (let i of res.Data) {
                  if (i.IsLock == false) {
                    configuation.push({ Id: i.Id, Name: i.Name });
                  }
                }

                let configId = this.localStorage.get("ConfigId");
                console.log(configId)
                if (configId == null && configuation.length != 0) configId = configuation[0].Id
                this.projectData.next({ projectId: this.project.ProjectId, title: this.project.ProjectTitle, milestoname: this.project.MilestoneName, configuration: configuation, selectedConfiguration: configId, stand: this.projectData })
              }
            })
          }
        }
      })
    }

    this.routeURL.next(this.router.url)
    if (!['/tc-manageProject', '/manageData'].includes(this.router.url)) {
      this.menuSvc.getUpdateTCMenuData(this.router.url)
      this.menuSvc.getTCHelpIdForMenu(this.router.url);
    }
  }

  changeConfigId(id) {
    this.configurationId.next(id);
  }

  changeProjectData(data) {
    this.projectData.next(data);
  }

  // getConfigurationDeatils(configurationID) {
  //   let projectId = this.localStorage.get('projectId');
  //   if (projectId == null) {
  //     this.projectData.next({ projectId: null, title: null, milestoname: null,configuation: null,stand:null });
  //     this.loggedInUser.subscribe((user: any) => {
  //       if (user) {
  //         this.projectSubmitterId.next(user.UserId);
  //       }
  //     })
  //   } else {

  //         this.configurationkservice.GetConfigurationDetails(configurationID).subscribe((res : any) => {
  //           if(res.StatusCode == 200){
  //            this.projectData = res.Data.StandFlags;

  //            this.projectData.next({projectId: this.project.ProjectId, title: this.project.ProjectTitle,milestoname:this.project.MilestoneName,configuration:this.projectData,stand:this.projectData})
  //           }
  //         })
  //        // this.projectData.next({projectId: this.project.ProjectId, title: this.project.ProjectTitle,milestoname:this.project.MilestoneName})


  //   }


  //   this.routeURL.next(this.router.url)
  //   if (!['/tc-manageProject', '/manageData'].includes(this.router.url)) {
  //     this.menuSvc.getUpdateTCMenuData(this.router.url)
  //     this.menuSvc.getTCHelpIdForMenu(this.router.url);
  //   }
  // }
  change(ideaId) {
    this.fire.emit(ideaId);
  }
  onComplexityAndRiskComplexity(objDel?) {
    this.invokeFirstComponentFunction.emit(objDel);
  }
  onComplexityAndRiskRisk(objDel?) {
    this.riskAssessment.emit(objDel);
  }

  onIdeaformCheckOption() {
    this.ideaformCheckOptions.emit();
  }

  onIdeaFormCheckCancel() {
    this.ideaformCheckCancel.emit();
  }
  onDirectCostReferenceSc(objDel) {
    this.directCostReferenceSc.emit(objDel);
  }
  onDirectCostTargetSc(objDel) {
    this.directCostTargetSc.emit(objDel);
  }
  getEmittedValue() {
    return this.fire;
  }
  onPlcComplexityRiskComplx(objDel) {
    this.plcComplexityRiskComplex.emit(objDel);
  }
  onPlcComplexityRiskRisk(objDel) {
    this.plcComplexityRiskRisk.emit(objDel);
  }
  onPlcDirectCostOptionA(objDel) {
    this.plcDirectCostOptionA.emit(objDel);
  }
  onPlcDirectCostOptionB(objDel) {
    this.plcDirectCostOptionB.emit(objDel);
  }
  onPlcDirectCostOptionC(objDel) {
    this.plcDirectCostOptionC.emit(objDel);
  }
  getImpactOrgDeletedRows(row) {
    this.impactOrgDeletedRows.emit(row);
  }

  getSubprocessDeletedRows(row) {
    this.subprocessDeletedRows.emit(row);
  }
  getTCSubprocessDeletedRows(row) {
    this.tcsubprocessDeletedRows.emit(row);
  }

  getUserManagementDeletedRows(row, colHeaderName) {
    this.colHeadernamevalue = colHeaderName;
    this.userManagementDeletedRows.emit(row);
  }
  getProductManagementDeletedRows(row) {
    this.productManagementDeletedRows.emit(row);
  }
  getIsPlcRequired(ideaId) {
    return this.catService.getCategorizationDataById(parseInt(ideaId))
  }
  getPermissionForEdit() {
    this.loggedInUser.subscribe(user => {
      this.user = user;
      if (this.user != null) {
        if (this.user.RoleId == 1 || this.user.TargetRoleId == 1 || this.user.TargetRoleId == 2 || this.user.RoleId == 2) {
          console.log("1");
          this.isEditable.next(true)
        }
        else if (this.user.RoleId == 3) {
          this.ideaCreatorId.subscribe(id => {
            if (id == this.user.UserId) {
              this.isEditable.next(true)
            }
            else {
              this.isEditable.next(false)
            }
          })
        }
      }

    })
  }
  onSubProcessTotal() {
    this.subProcessTotal.emit();
  }
  getErrorMessage(error) {
    this.errorMessageApi = [];
    this.errorMessageApi.push({ [error.statusText]: error.message });
    let serverErrorHeader = serverMessage.serverErrorHeader;
    let serverErrorMessage = serverMessage.serverErrorMessage;
    let errorM = [{ [serverErrorMessage]: serverErrorHeader }];
    this.dialog.open(AlertDialogComponent, { data: errorM });
  }

  onCostSaving(obj) {
    this.invokeFirstComponentFunction.emit(obj);
  }

  //Remove dataCategorization
  onRemoveCategorizationData() {
    this.localStorage.remove(this.localStorage.get('ideaId') + '_CategorizationData');
    this.localStorage.remove(this.localStorage.get('ideaId') + '_CategorizationData_Remarks');
  }

  // OnChangeInPLC cagegorization
  onChangePlcCategorizationData() {
    this.isPlcRequiredCategorization.emit();
  }

  // used by admin page on tab change;
  saveAdimPage(pageName) {
    this[pageName].emit();
  }

  getMMPDeletedRows(row) {
    this.projectMMPgDeletedRows.emit(row);
  }

  getConfigurationDeletedRows(row) {
    this.configurationDeletedRows.emit(row);
  }

  getEqAddedRow(addObject, addIndex) {
    this.eqAddedRow.emit({ addObject: addObject, addIndex: addIndex });
  }
}
