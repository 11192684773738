import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { SharedServiceService } from '../../services/shared-service.service';
import { messages } from '../../popUpMessages/messages';
import { serverMessage } from '../../popUpMessages/serverMessage';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
@Component({
  selector: 'app-deleteicon',
  templateUrl: './deleteicon.component.html'
  // styleUrls: ['./deleteicon.component.css']
})
export class DeleteiconComponent implements ICellRendererAngularComp {
  public delVisible: any = true;
  public gridApi: any;
  public params: any;
  public complexityAndRisk = false;
  public user: any;
  public showRemoveIcon: boolean = false;
  public userNotDeleted: boolean = true;
  constructor(public dialog: MatDialog, private sharedService: SharedServiceService) { }

  refresh(): boolean {
    return false;
  };
  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;

    if (this.params.data.hasOwnProperty('IsEditable')) {
      if (this.params.data.IsEditable) {
        this.delVisible = true;
        if (params.data.refsc === 'Total' || params.data.targetSc === 'Total' || params.data.targetSc === 'Change to reference'
          || params.data.refereceScenerioTitle === 'Total' || params.data.targetScenerioTitle === 'Change to reference' || params.data.targetScenerioTitle === 'Total'
          || params.data.OptionCScenerioTitle === 'Total' || params.data.OptionCScenerioTitle === 'Change to reference'
          || params.data.OptionBScenerioTitle === 'Total' || params.data.OptionBScenerioTitle === 'Change to reference' ||
          params.data.OptionAScenerioTitle === 'Total' || params.data.OptionAScenerioTitle === 'Change to reference' ||
          params.data.Crtitle == "Total Complexitiy Level" || params.data.Crtitle == "Complexity Surcharge"
          || params.data.Crtitle == "Total Risk Level" || params.data.Crtitle == "Risk Surcharge" || params.data.SubProcessDetailTitle == "Total"
          || params.data.IsMaster || params.data.CrtitlePlc == "Total Complexitiy Level" || params.data.CrtitlePlc == "Complexity Surcharge"
          || params.data.CrtitlePlcRisk == "Total Risk Level" || params.data.CrtitlePlcRisk == "Risk Surcharge"
        ) {
          this.delVisible = false;
        } else {
          this.delVisible = true;
        }
      } else {
        this.delVisible = false;
      }
    }
    else {
      this.sharedService.isEditable.subscribe(res => {
        this.delVisible = res
        if (this.delVisible) {
          if (params.data.refsc === 'Total' || params.data.targetSc === 'Total' || params.data.targetSc === 'Change to reference'
            || params.data.refereceScenerioTitle === 'Total' || params.data.targetScenerioTitle === 'Change to reference' || params.data.targetScenerioTitle === 'Total'
            || params.data.OptionCScenerioTitle === 'Total' || params.data.OptionCScenerioTitle === 'Change to reference'
            || params.data.OptionBScenerioTitle === 'Total' || params.data.OptionBScenerioTitle === 'Change to reference' ||
            params.data.OptionAScenerioTitle === 'Total' || params.data.OptionAScenerioTitle === 'Change to reference' ||
            params.data.Crtitle == "Total Complexitiy Level" || params.data.Crtitle == "Complexity Surcharge"
            || params.data.Crtitle == "Total Risk Level" || params.data.Crtitle == "Risk Surcharge" || params.data.SubProcessDetailTitle == "Total"
            || params.data.IsMaster || params.data.CrtitlePlc == "Total Complexitiy Level" || params.data.CrtitlePlc == "Complexity Surcharge"
            || params.data.CrtitlePlcRisk == "Total Risk Level" || params.data.CrtitlePlcRisk == "Risk Surcharge"
          ) {
            this.delVisible = false;
          }
          // use to display different icon for user soft delete in "user Management" page;
          if (params.data.userSoftDelete) {
            this.showRemoveIcon = true;
          } else {
            this.showRemoveIcon = false;
          }

          // Used by "user removal" page to hide delete icon for deleted user
          if (params.data.userRemovalPage) {
            if (params.data.OnLegalHold == "No" && params.data.FullName == "Deleted User") {
              this.userNotDeleted = false;
            }
            else {
              this.userNotDeleted = true;
            }
          }
        }
      })
    }
  }

  // get called whenever the delete icon is clicked;
  public invokeDeleteMethod(params) {
    let error: any = messages.deleteMessage;
    let userOnLegalHold = false;
    if (params.data.userRemovalPage) {
      error = messages.removeUser;
      if (params.data.OnLegalHold == "Yes") {
        userOnLegalHold = true;
      }
    }
    if (userOnLegalHold) {
      let headerMessage = serverMessage.warning;
      let errorMessage = messages.userOnLegalHold;
      error = [];
      error.push({ [errorMessage]: headerMessage });
      this.openAlertDialog(error);
    }
    else {
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridApi = params.api;
          // here removing the whole row when click on delete button
          if (params.data.GID == undefined) {
            this.gridApi.applyTransaction({ remove: [params.data] });
          }

          if (this.params.data.level) {
            this.sharedService.onComplexityAndRiskComplexity(params.data);
          }
          if (this.params.data.remaining) {
            this.sharedService.onComplexityAndRiskRisk(params.data);
          }
          if (this.params.data.refereceScenerioTitle || this.params.data.refereceScenerioTitle == "") {
            this.sharedService.onDirectCostReferenceSc(params.data);
          }
          if (this.params.data.targetScenerioTitle || this.params.data.targetScenerioTitle == "") {
            this.sharedService.onDirectCostTargetSc(params.data);
          }
          if (this.params.data.levelPlc) {
            this.sharedService.onPlcComplexityRiskComplx(params.data);
          }
          if (this.params.data.remainingPlc) {
            this.sharedService.onPlcComplexityRiskRisk(params.data);
          }
          if (this.params.data.OptionAScenerioTitle || this.params.data.OptionAScenerioTitle == "") {
            this.sharedService.onPlcDirectCostOptionA(params.data);
          }
          if (this.params.data.OptionBScenerioTitle || this.params.data.OptionBScenerioTitle == "") {
            this.sharedService.onPlcDirectCostOptionB(params.data);
          }
          if (this.params.data.OptionCScenerioTitle || this.params.data.OptionCScenerioTitle == "") {
            this.sharedService.onPlcDirectCostOptionC(params.data);
          }
          if (params.data.ImpactedOrgId != null) {
            this.sharedService.getImpactOrgDeletedRows(params.data);
          }
          if (params.data.SubProcessDetailId != null) {
            this.sharedService.getSubprocessDeletedRows(params.data)
          }
          if (params.data.TCSubProcessDetailId != null) {
            this.sharedService.getTCSubprocessDeletedRows(params.data)
          }
          if (params.data.UserId != null) {
            const colHeaderName = this.params.colDef.headerName;
            this.sharedService.getUserManagementDeletedRows(params.data,colHeaderName)
          }
          if (params.data.ProductId != null) {
            this.sharedService.getProductManagementDeletedRows(params.data)
          }
          if(params.data.mmpTitle){
            this.sharedService.getMMPDeletedRows(params.data);
          }
        }
      })
    }
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
}
