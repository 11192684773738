import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TCSCMLCTargetCost } from '../models/tcscmtarget-cost';


@Injectable({
  providedIn: 'root'
})
export class ScmtargetcostService {

  readonly baseUrl = environment.baseURL

  private urlGetSCMTargetCostData = this.baseUrl + "api/TCSCMLCTargetCost/GetTCSCMCosts?configurationId=";
  private urlPostSCMData = this.baseUrl + "api/TCSCMLCTargetCost/SaveTCSCMTargetCosts";
 // private urlPostLCData = this.baseUrl + "api/TCSCMLCTargetCost/SaveTCSCMTargetCosts";
  private urlGetLCTargetCostData = this.baseUrl + "api/TCSCMLCTargetCost/GetTCLocalCosts?configurationId=";
  constructor(private http: HttpClient) { }

  getSCMTargetCostData(configurationId): Observable<any> {
    return this.http.get<any>(this.urlGetSCMTargetCostData + configurationId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  getLCTargetCostData(configurationId): Observable<any> {
    return this.http.get<any>(this.urlGetLCTargetCostData + configurationId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }


  InsertupdateSCMTargetCostData(data): Observable<TCSCMLCTargetCost> {
    return this.http.post<TCSCMLCTargetCost>(this.urlPostSCMData, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  InsertupdateLCTargetCostData(data): Observable<TCSCMLCTargetCost> {
    return this.http.post<TCSCMLCTargetCost>(this.urlPostSCMData, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  // It handle error ,server connection error and throws it to the component where its is recieved in the error of "subscribe" of service call method;
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }

}
