import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { serverMessage } from '../popUpMessages/serverMessage';
import { LocalStorageService } from './local-storage.service';
import { MenuService } from './menu.service';
import { UserInfoService } from './user-info.service';

@Injectable({
  providedIn: 'root'
})
export class CananctivateService implements CanActivate {
  flatdata = []
  menuData: any;
  status: boolean = false;
  userAccount: any;
  constructor(
    private router: Router,
    private menuSvc: MenuService,
    private localStorage: LocalStorageService,
    private msalService: MsalService,
    private userService: UserInfoService,
    public dialog: MatDialog
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.msalService.instance.getAllAccounts().length == 0 && route.routeConfig.path != "welcome") {

      this.router.navigate(["/welcome"])
      return false
    }
    else if (this.msalService.instance.getAllAccounts().length > 0) {

      if (route.routeConfig.path == "manageData" || route.routeConfig.path == "manageIdea" || route.routeConfig.path == "idea"
      || route.routeConfig.path == "impact" || route.routeConfig.path == "categorization" || route.routeConfig.path == "tc-project"
      || route.routeConfig.path == "tc-configuration" || route.routeConfig.path == "tc-manageProject" 
      || route.routeConfig.path == "tc-configuration-vblo"|| route.routeConfig.path == "tc-eq" || route.routeConfig.path == "tc-cs"|| route.routeConfig.path == "tc-scm" || route.routeConfig.path == "tc-localcosts" || route.routeConfig.path == "tc-investmentplan") {

        this.userAccount = this.msalService.instance.getAllAccounts()[0]
        let gid = this.userAccount.idTokenClaims.siemens_gid;

        return this.userService.getUserInfo(gid).toPromise().then((res: any) => {
          if (res.StatusCode != 200) {
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
            return false;
          }
          else if (res.Data && res.StatusCode == 200) {
            //console.log(res);
            //console.log(res.data.RoleId);
            // To allow only admin user to navigate to manage master data page
            if ((res.Data.RoleId == 1 || res.Data.TargetRoleId == 1) && route.routeConfig.path == "manageData") {
              return true;
            }
            else if (route.routeConfig.path == "manageIdea" || route.routeConfig.path == "idea") {
              return true;
            }
            else if (route.routeConfig.path == "impact" || route.routeConfig.path == "categorization") {
              return true;
            }
            else if (route.routeConfig.path == "tc-project") {
              return true;
            }
            else if (route.routeConfig.path == "tc-configuration") {
              return true;
            } 
            else if (route.routeConfig.path == "tc-manageProject") {
              return true;
            }
            else if (route.routeConfig.path == "tc-configuration-vblo") {
              return true;
            } 
            else if (route.routeConfig.path == "tc-eq") {
              return true;
            }
            else if (route.routeConfig.path == "tc-cs") {
              return true;
            } 
            else if (route.routeConfig.path == "tc-scm") {
              return true;
            } 
            else if (route.routeConfig.path == "tc-localcosts") {
              return true;
            } 
            else if(   route.routeConfig.path == "tc-investmentplan")
            {
              return true;
            }
         
            else {
              this.router.navigate(["/page-not-found"])
              return false;
            }
          }
          else {
            this.router.navigate(["/page-not-found"])
            return false;
          }
        },

          (error) => {

            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            return false;
          })

      }
      else if (route.routeConfig.path == "home" || route.routeConfig.path == "welcome") {
        return true
      }
      else {
        let ideaId = this.localStorage.get("ideaId")
        console.log(ideaId);
        return this.menuSvc.getMenuData(ideaId).pipe(map(data => {
          this.flatdata = []
          this.menuData = data.Data;
          this.getFlatData(this.menuData)
          this.status = this.getPageActivateStatus(route.routeConfig.path)
          if (!this.status) {
            this.router.navigate(["/page-not-found"])
            return false
          }
          else {
            return true
          }
        }))
      }

    }
  }
  getFlatData(dataSource: any) {
    dataSource.forEach((node: any) => {
      if (!node.Children) {
        this.flatdata.push(node)
      }
      else if (node.Children) {
        this.flatdata.push(node);
        this.getFlatData(node.Children)
      }

    })
  }
  getPageActivateStatus(route) {
    for (let data of this.flatdata) {
      if (data.IsDisplay && data.Route == route) {
        return true
      }
    }
    return false

  }

  public openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
}
