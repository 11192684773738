import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { LocalStorageService } from '../../../../services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
@Component({
  selector: 'app-gridlink',
  templateUrl: './gridlink.component.html'
  // styleUrls: ['./gridlink.component.css']
})
export class GridlinkComponent implements ICellRendererAngularComp {

  params: any;
  linkVisible: boolean = true;
  value: any;
  constructor(private route: Router, private localStorageService: LocalStorageService, private sharedService: SharedServiceService) { }
  refresh(): boolean {
    return null;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;

    if (this.params.data.SubProcessTitle == "Total process cost") {
      this.linkVisible = false
    }
    if (params.value != undefined && params.value.length > 85) {

      let value = params.value.substr(0, 85) + '...'
      this.value = value
    }
    else {
      this.value = params.value
    }
  }

  onClick() {
    // this will check whether is params is coming from "ManageIdeaPage";
    if (this.params.data.IdeaId) {
      // assigning the Clicked Idea ID for IdeaForm Page;
      this.localStorageService.set('ideaId', this.params.data.IdeaId);

      this.route.navigate(['/idea']);
    } else if (this.params.data.ProjectId) {
      this.localStorageService.set('projectId', this.params.data.ProjectId);
      this.localStorageService.remove('ConfigId');
      this.sharedService.changeConfigId(0);
      this.route.navigate(['/tc-project']);
    }
    else if(this.params.data.ConfigurationId){
    
      this.route.navigate(["tc-investmentplan/investment-subdetails", this.params.data.TCSubProcessID, this.params.data.ScenarioType]);
    }
    else{
        this.route.navigate(["plc-processcost/subdetails", this.params.data.SubProcessID, this.params.data.SolutionId]);
    }
  }
}
