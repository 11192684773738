import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component'
import { GridComponent } from './grid/grid.component'
import { IdeaformComponent } from './pages/ideaform/ideaform.component'
import { CategorizationComponent } from './pages/categorization/categorization.component'
import { ImpactComponent } from './pages/impact/impact.component';
import { DirectcostComponent } from './pages/plc/directcost/directcost.component';
import { CostsavingsComponent } from './pages/non-plc/costsavings/costsavings.component';
import { RefscenarioComponent } from './pages/non-plc/complexity/refscenario/refscenario.component';
import { TargetscenarioComponent } from './pages/non-plc/complexity/targetscenario/targetscenario.component';
import { NonPlcSummaryComponent } from './pages/non-plc/non-plc-summary/non-plc-summary.component';
import { ProcessCostRefScenarioComponent } from './pages/plc/processcost/refscenario/refscenario.component'
import { SubdetailsComponent } from './pages/plc/processcost/subdetails/subdetails.component'
import { OptionAComponent } from './pages/non-plc/costsavings/option-a/option-a.component';
import { OptionBComponent } from './pages/non-plc/costsavings/option-b/option-b.component';
import { OptionCComponent } from './pages/non-plc/costsavings/option-c/option-c.component';

import { ComplexityOptionAComponent } from './pages/non-plc/complexity/complexity-option-a/complexity-option-a.component';
import { ComplexityOptionBComponent } from './pages/non-plc/complexity/complexity-option-b/complexity-option-b.component';
import { ComplexityOptionCComponent } from './pages/non-plc/complexity/complexity-option-c/complexity-option-c.component';
import { CanDeactivateService } from './services/can-deactivate.service';
import { HelpEditComponent } from './pages/admin/help-edit/help-edit.component'

import { DirectcostOptionAComponent } from './pages/plc/directcost/directcost-option-a/directcost-option-a.component';
import { DirectcostOptionBComponent } from './pages/plc/directcost/directcost-option-b/directcost-option-b.component';
import { DirectcostOptionCComponent } from './pages/plc/directcost/directcost-option-c/directcost-option-c.component';

import { PlcRefScenerioComponent } from './pages/plc/complexityRisk/plc-ref-scenerio/plc-ref-scenerio.component';
import { PlcTarScenerioComponent } from './pages/plc/complexityRisk/plc-tar-scenerio/plc-tar-scenerio.component';
import { PlcOptionAComponent } from './pages/plc/complexityRisk/plc-option-a/plc-option-a.component';
import { PlcOptionBComponent } from './pages/plc/complexityRisk/plc-option-b/plc-option-b.component';
import { PlcOptionCComponent } from './pages/plc/complexityRisk/plc-option-c/plc-option-c.component';
import { PlcProcessCostOptionAComponent } from './pages/plc/processcost/plc-process-cost-option-a/plc-process-cost-option-a.component';
import { PlcProcessCostOptionBComponent } from './pages/plc/processcost/plc-process-cost-option-b/plc-process-cost-option-b.component';
import { PlcProcessCostOptionCComponent } from './pages/plc/processcost/plc-process-cost-option-c/plc-process-cost-option-c.component';
import { PlcPartsUsedRefScenarioComponent } from './pages/plc/partsUsed/plc-parts-used-ref-scenario/plc-parts-used-ref-scenario.component';
import { PlcPartsUsedOptionAComponent } from './pages/plc/partsUsed/plc-parts-used-option-a/plc-parts-used-option-a.component';
import { PlcPartsUsedOptionBComponent } from './pages/plc/partsUsed/plc-parts-used-option-b/plc-parts-used-option-b.component';
import { PlcPartsUsedOptionCComponent } from './pages/plc/partsUsed/plc-parts-used-option-c/plc-parts-used-option-c.component';
import { PlcSummaryComponent } from './pages/plc/plc-summary/plc-summary.component';
import { PlcIBaseOptionAComponent } from './pages/plc/Ibase/plc-ibase-option-a/plc-ibase-option-a.component';
import { PlcIBaseOptionBComponent } from './pages/plc/Ibase/plc-ibase-option-b/plc-ibase-option-b.component';
import { PlcIBaseOptionCComponent } from './pages/plc/Ibase/plc-ibase-option-c/plc-ibase-option-c.component';
import { PlcIBaseRefScenarioComponent } from './pages/plc/Ibase/plc-ibase-ref-scenario/plc-ibase-ref-scenario.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { CananctivateService } from './services/cananctivate.service';
import { ManagedataComponent } from './managedata/managedata.component'
import { LoginCheckComponent } from './pages/login-check/login-check.component';
import { PlcIbaseTarScenarioComponent } from './pages/plc/Ibase/plc-ibase-tar-scenario/plc-ibase-tar-scenario.component';
import { PlcSummaryanalysisRefscenarioComponent } from './pages/plc/plc-summary/plc-summaryanalysis-refscenario/plc-summaryanalysis-refscenario.component'
import { PlcSummaryanalysisOptionAComponent } from './pages/plc/plc-summary/plc-summaryanalysis-option-a/plc-summaryanalysis-option-a.component';
import { PlcSummaryanalysisOptionBComponent } from './pages/plc/plc-summary/plc-summaryanalysis-option-b/plc-summaryanalysis-option-b.component';
import { PlcSummaryanalysisOptionCComponent } from './pages/plc/plc-summary/plc-summaryanalysis-option-c/plc-summaryanalysis-option-c.component';

import { AuditLogComponent } from './pages/audit-log/audit-log.component';
import { ProjectformComponent } from './pages/projectform/projectform.component';
import { TcManageProjectComponent } from './pages/tc-manage-project/tc-manage-project.component';
import { ConfigurationComponent } from './pages/configuration/configuration-overview/configuration.component';
import { CandeactivateTcService } from './services/candeactivate-tc.service';
import { ConfigurationVBLOComponent } from './pages/configuration/configuration-vblo/configuration-vblo.component';
import { CommentboxComponent } from './commentbox/commentbox.component';
import { TcCSComponent } from './pages/tc-cs/tc-cs.component';
import { EqTargetcostingComponent } from './pages/eq-tagercosting/eq-targetcosting/eq-targetcosting.component';
import { ScmTargetcostComponent } from './pages/scm-targetcosting/scm-targetcost/scm-targetcost.component';
import { EqTaretcostingCalcPopupComponent } from './pages/eq-tagercosting/eq-taretcosting-calc-popup/eq-taretcosting-calc-popup.component';
import { TcLocalComponent } from './pages/tc-local/tc-local/tc-local.component';
import { InvestmentTargetComponent } from './pages/Investment-Plan/investment-target/investment-target.component';
import { InvestmentRefscenarioComponent } from './pages/Investment-Plan/Investment-refscenario/investment-refscenario/investment-refscenario.component';
import { TcDirectcostsComponent } from './pages/tc-productlifecyclecosts/tc-directcosts/tc-directcosts/tc-directcosts.component';
import { TcSummaryComponent } from './pages/tc-productlifecyclecosts/tc-summary/tc-summary.component';
import { InvestmentSubdetailsComponent } from './pages/Investment-Plan/investment-subdetails/investment-subdetails.component';


const routes: Routes = [{ path: '', redirectTo: 'welcome', pathMatch: 'full' },
{ path: "welcome", component: LoginCheckComponent },
{ path: 'home', component: HomeComponent, canActivate: [CananctivateService] },
{ path: 'manageIdea', component: GridComponent, canActivate: [CananctivateService] },
{ path: 'idea', component: IdeaformComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'categorization', component: CategorizationComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'impact', component: ImpactComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-directcost', component: DirectcostComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'nonplc-complexity/refscenario', component: RefscenarioComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'nonplc-complexity/targetscenario', component: TargetscenarioComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'nonplc-costsavings', component: CostsavingsComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'nonplc-costsavings/optiona', component: OptionAComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'nonplc-costsavings/optionb', component: OptionBComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'nonplc-costsavings/optionc', component: OptionCComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'nonplc-summary', component: NonPlcSummaryComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'plc-processcost/refscenario', component: ProcessCostRefScenarioComponent, canActivate: [CananctivateService] },
{ path: 'plc-processcost/subdetails/:id/:solutionId', component: SubdetailsComponent, canDeactivate: [CanDeactivateService] },
{ path: 'plc-processcost/optiona', component: PlcProcessCostOptionAComponent, canActivate: [CananctivateService] },
{ path: 'plc-processcost/optionb', component: PlcProcessCostOptionBComponent, canActivate: [CananctivateService] },
{ path: 'plc-processcost/optionc', component: PlcProcessCostOptionCComponent, canActivate: [CananctivateService] },

{ path: 'nonplc-complexity/complexityoptiona', component: ComplexityOptionAComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'nonplc-complexity/complexityoptionb', component: ComplexityOptionBComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'nonplc-complexity/complexityoptionc', component: ComplexityOptionCComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'plc-directcost/directcostoptiona', component: DirectcostOptionAComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-directcost/directcostoptionb', component: DirectcostOptionBComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-directcost/directcostoptionc', component: DirectcostOptionCComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'manageData/help', component: HelpEditComponent },


{ path: 'plc-complexityRisk/plcrefscenerio', component: PlcRefScenerioComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-complexityRisk/plctarscenerio', component: PlcTarScenerioComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-complexityRisk/plcoptiona', component: PlcOptionAComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-complexityRisk/plcoptionb', component: PlcOptionBComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-complexityRisk/plcoptionc', component: PlcOptionCComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'plc-partsUsed/refscenario', component: PlcPartsUsedRefScenarioComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-partsUsed/optiona', component: PlcPartsUsedOptionAComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-partsUsed/optionb', component: PlcPartsUsedOptionBComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-partsUsed/optionc', component: PlcPartsUsedOptionCComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'plc-Ibase/refscenario', component: PlcIBaseRefScenarioComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-Ibase/tarscenario', component: PlcIbaseTarScenarioComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-Ibase/optiona', component: PlcIBaseOptionAComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-Ibase/optionb', component: PlcIBaseOptionBComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-Ibase/optionc', component: PlcIBaseOptionCComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'plc-summary', component: PlcSummaryComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },
{ path: 'plc-summaryanalysis/refscenario', component: PlcSummaryanalysisRefscenarioComponent, canActivate: [CananctivateService] },
{ path: 'plc-summaryanalysis/optiona', component: PlcSummaryanalysisOptionAComponent, canActivate: [CananctivateService] },
{ path: 'plc-summaryanalysis/optionb', component: PlcSummaryanalysisOptionBComponent, canActivate: [CananctivateService] },
{ path: 'plc-summaryanalysis/optionc', component: PlcSummaryanalysisOptionCComponent, canActivate: [CananctivateService] },
{ path: 'page-not-found', component: PageNotFoundComponent },
{ path: 'manageData', component: ManagedataComponent, canActivate: [CananctivateService] },

{ path: 'audit-log', component: AuditLogComponent, canActivate: [CananctivateService], canDeactivate: [CanDeactivateService] },

{ path: 'tc-project', component: ProjectformComponent, canActivate: [CananctivateService], canDeactivate: [CandeactivateTcService] },
{ path: 'tc-configuration', component: ConfigurationComponent, canActivate: [CananctivateService], canDeactivate: [CandeactivateTcService] },
{ path: 'tc-configuration-vblo', component: ConfigurationVBLOComponent, canActivate: [CananctivateService], canDeactivate: [CandeactivateTcService] },

{ path: 'tc-manageProject', component: TcManageProjectComponent, canActivate: [CananctivateService] },
{ path: 'commentbox', component: CommentboxComponent },

{ path: 'tc-cs', component: TcCSComponent, canActivate: [CananctivateService], canDeactivate: [CandeactivateTcService] },
{ path: 'tc-eq', component: EqTargetcostingComponent, canActivate: [CananctivateService], canDeactivate: [CandeactivateTcService] },
{ path: 'tc-scm', component: ScmTargetcostComponent, canActivate: [CananctivateService], canDeactivate: [CandeactivateTcService] },
{ path: 'tc-localcosts', component: TcLocalComponent, canActivate: [CananctivateService], canDeactivate: [CandeactivateTcService] },
{ path: 'tc-investmentplan', component: InvestmentRefscenarioComponent, canActivate: [CananctivateService], canDeactivate: [CandeactivateTcService] },
{ path: 'plc-tc-directcost', component: TcDirectcostsComponent },
{ path: 'plc-tc-summary', component: TcSummaryComponent },

{ path: 'tc-eq-popup', component: EqTaretcostingCalcPopupComponent, canActivate: [CananctivateService] },
{ path: 'tc-investmentplan/investment-subdetails/:id/:solutionId', component: InvestmentSubdetailsComponent, canDeactivate: [CanDeactivateService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {

    // Restore the last scroll position
    scrollPositionRestoration: "enabled",
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: "enabled"
  })],

  exports: [RouterModule]

})

export class AppRoutingModule { }
