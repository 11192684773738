import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProjectsearchService } from 'src/app/services/projectsearch.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { GridlinkComponent } from '../plc/processcost/gridlink/gridlink.component';
import moment from 'moment';
import { EditDeleteComponent } from 'src/app/grid/button-edit/edit-delete/edit-delete.component';

let countryCode;

@Component({
  selector: 'app-tc-manage-project',
  templateUrl: './tc-manage-project.component.html',
  styleUrls: ['./tc-manage-project.component.css']
})
export class TcManageProjectComponent implements OnInit {

  public showGrid: boolean = false;
  public gridApi: any;
  public rowData: any ;
  public columnDefs: any;
  public paginationPageSize;
  public rowStyle;
  public defaultProperties: any;
  public gridColumnApi: any;
  public countryCode:any;
  public user: any;
  public dialog:any;

  constructor(
    public router: Router,
    private sharedService: SharedServiceService,
    private projectSearchservice:ProjectsearchService,
    private LocalStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.loadRowData();
    // this.loadGrid();
    // set the ProjectId null;
    this.LocalStorageService.set("projectId", null);
    //Get Country Code
    countryCode = this.LocalStorageService.get('countryCode');
  }

  loadRowData(){
    this.sharedService.loggedInUser.subscribe((user) => {
      if(user != null){
        this.user = user;
        this.projectSearchservice.getAllProjects(this.user.UserId).subscribe((data: any) => {
          let msgFromBack = data.Data.Message;
          if(data.StatusCode == 200){
            this.rowData = data.Data;
            this.loadGrid();
          }
          else{
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + msgFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
          }
        },(error) => {
          //Show errorCode and errorMessage in the UI
          // this.showGrid = false;
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        })
      }
    })  
  }

  loadGrid(){
    this.rowStyle = { fontSize: '0.7rem' };
    this.columnDefs = [
      {headerName: "ID", sortable: true, headerTooltip:"ID", field: 'ProjectId', width: 80, filter: 'agTextColumnFilter', tooltipField: "ProjectId"},
      {headerName:"Project", sortable: true, headerTooltip:"Project", field:'ProjectTitle', width: 400, filter: 'agTextColumnFilter', tooltipField: 'ProjectTitle', cellRendererFramework: GridlinkComponent},
      {headerName: "Submitter", sortable: true, headerTooltip: "Submitter", field:'Submitter', width: 150, resizable: true, filter: true, tooltipField: "Submitter" },
      {headerName:"Creation Date", sortable:true, headerTooltip:"Creation Date", field:'CreatedDate', width: 100, filter: "agDateColumnFilter", filterParams: {
        buttons: ['reset'],
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          let changedValue: any = moment(filterLocalDateAtMidnight).format('MM/DD/YYYY');
          let changedValue1: any = moment(cellValue).format('MM/DD/YYYY');
          changedValue = new Date(changedValue);
          changedValue1 = new Date(changedValue1);
          if (changedValue.getTime() === changedValue1.getTime()) {
            return 0;
          }
          if (changedValue1 < changedValue) {
            return -1;
          }
          if (changedValue1 > changedValue) {
            return 1;
          }
        },
        browserDatePicker: true,
      }, tooltipValueGetter: DateFormat, valueFormatter: DateFormat},
      {headerName: "Product Manager", sortable: true, headerTooltip: "Product Manager", field:'ProductManager', width: 150, resizable: true, filter: true, tooltipField: "Product Manager" },
      {headerName: "Project Manager", sortable: true, headerTooltip: "Project Manager", field:'ProjectManager', width: 150, resizable: true, filter: true, tooltipField: "Project Manager" },
      {headerName: "Financial Controller", sortable: true, headerTooltip: "Financial Controller", field:'FinancialController', width: 150, resizable: true, filter: true, tooltipField: "Financial Controller" },
      {headerName: "Service Manager", sortable: true, headerTooltip: "Service Manager", field:'ServiceManager', width: 150, resizable: true, filter: true, tooltipField: "Service Manager" },
      {headerName: "Procurement Engineer", sortable: true, headerTooltip: "Procurement Engineer", field:'ProcurementEngineer', width: 150, resizable: true, filter: true, tooltipField: "Procurement Engineer" },
      {headerName: "Milestone", sortable: true, headerTooltip: "Milestone", field:'MilestoneName', width: 60, resizable: true, filter: true, tooltipField: "Milestone" },
      {headerName: "Status", sortable: true, headerTooltip: "Status", field:'MeasureStatusTitle', width: 100, resizable: true, filter: true, tooltipField: "Status" },
      {
        editable: false,
        headerName: "Action",
        headerTooltip: "Action",
        cellRendererFramework: EditDeleteComponent,
        colId: "add",
        width: 100
      }
    ]
    this.showGrid = true;
    // this.rowData = [];
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  
  goToHomePage() {
    this.router.navigate(['/home']);
  }

  goToCreateProject() {
    this.router.navigate(['/tc-project'])
  }

  ResetFilter() {
    this.LocalStorageService.set('tcPersistFilter', null);
    const filterModel = this.LocalStorageService.get('tcPersistFilter');
    this.gridApi.setFilterModel(JSON.parse(filterModel));
  }

  onPageSizeChanged() {
    let value = (<HTMLInputElement>document.getElementById('page-size')).value;
    let pageSize = this.gridApi.paginationGetRowCount();
    if (value == "all") {
      this.gridApi.paginationSetPageSize(Number(pageSize));
    }
    else {
      this.gridApi.paginationSetPageSize(Number(value));
    }
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'ProjectId',
          sort: 'desc',
        },
      ],
    });
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params): void {
    const filterModel = this.LocalStorageService.get('tcPersistFilter');
    params.api.setFilterModel(JSON.parse(filterModel));
  }

  onFilterChanged(params) {
    this.onPageSizeChanged();
    this.LocalStorageService.set('persistFilter', JSON.stringify(params.api.getFilterModel()));
  }

}

function roundOfProductivityData(params) {
  return new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
}

// this will format date to German Date Format;
function DateFormat(params) {
  let orignalValue = params.value;
  if (orignalValue != null) {
    let changedValue = moment(orignalValue).format('MM/DD/YYYY');
    return changedValue;
  } else {
    return orignalValue;
  }
}
