import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { ProcessCostService } from 'src/app/services/process-cost.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
//import { MSolutionType } from 'src/app/enum/MSolutionType';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { GridlinkComponent } from 'src/app/pages/plc/processcost/gridlink/gridlink.component';
import { RowNode } from 'ag-grid-community';
import { MSenarioType } from 'src/app/enum/MSenarioType';
//import { GridlinkComponent } from '../gridlink/gridlink.component';
let countryCode;
@Component({
  selector: 'app-investment-refscenario',
  templateUrl: './investment-refscenario.component.html',
  styleUrls: ['./investment-refscenario.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InvestmentRefscenarioComponent implements OnInit, OnDestroy {

  refProcessCostColumnDefs: any;
  rowClassRules: { 'rowpin-background': (params: any) => boolean; };
  //  refProcessCostDefaultColDef: { suppressMovable: boolean; sortable: boolean; };

  public showGrid = false;
  projectId: any;
  refProcessCostRowData: any[];
  refProcessCostGridApi: any;
  refProcessCostgridColumnApi: any;
  hasUnsavedChanges: boolean;
  isEditable: boolean = true;
  public rowHeight: any;
  public headerHeight: any;
  refFrameworkComponents: { DoublingEditor: any; };
  tgtProcessCostGridApi: any;
  tgtProcessCostgridColumnApi: any;
  tgtProcessCostRowData: any[];
  tgtProcessCostColumnDefs: any;
  domLayout: string;
  public getResponse: any;
  refProcessCostDefaultColDef: { suppressMovable: boolean; sortable: boolean; };
  errorMessage: any[];
  dataRec: any;
  configurationId: any;
  opAProcessCostColumnDefs: any;
  opAProcessCostDefaultColDef: { suppressMovable: boolean; sortable: boolean; };
  opAProcessCostRowData: any[];
  opBProcessCostDefaultColDef: { suppressMovable: boolean; sortable: boolean; };
  opBProcessCostRowData: any[];
  opBProcessCostColumnDefs: any;
  opCProcessCostDefaultColDef: { suppressMovable: boolean; sortable: boolean; };
  opCProcessCostColumnDefs: any;
  opCProcessCostRowData: any[];
  optAVisible: boolean = false;
  optBVisible: boolean = false;
  optCVisible: boolean = false;
  opAProcessCostgridColumnApi: any;
  opAProcessCostGridApi: any;
  opBProcessCostGridApi: any;
  opBProcessCostgridColumnApi: any;
  opCProcessCostGridApi: any;
  opCProcessCostgridColumnApi: any;
  configSub: any;
  objData: { projectId: any; moduleId: any; };
  moduleId: Number = 10;
  constructor(
    private http: HttpClient,
    private sharedService: SharedServiceService,
    private localStorageService: LocalStorageService,
    private processCostService: ProcessCostService,
    public dialog: MatDialog,
    private menuService: MenuService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {


    this.sharedService.getProjectIdAndTitle();
    this.projectId = this.localStorageService.get("projectId");
    this.objData = {
      projectId: this.projectId,
      moduleId: this.moduleId
    }
    countryCode = this.localStorageService.get('countryCode');

    this.showGrid = true
    this.loadRefProcessCost();
    this.loadtgtProcessCost();
    this.loadreferenceGrid();
    this.loadAInvestmentPlan();
    this.loadBInvestmentPlan();
    this.loadCInvestmentPlan();
    this.loadtargetGrid();
    this.loadOptionA();
    this.loadOptionB();
    this.loadOptionC();

  }

  addNewRow() {
    if (this.optAVisible == true && this.optBVisible == true) {
      this.optCVisible = true;

    }
    if (this.optAVisible == true) {
      this.optBVisible = true;

    }
    if (this.optBVisible == false) {

      this.optAVisible = true;

    }

  }
  public loadRefProcessCost() {

    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d
      //let projectId = this.projectId;
      this.errorMessage = [];
      let solutionId = MSenarioType.ReferenceScenario;
      console.log(this.configurationId);
      console.log(solutionId);
      this.processCostService.getInvestmentPlanData(this.configurationId, solutionId).subscribe((data) => {
        this.getResponse = data;


        let successCode = this.getResponse.StatusCode;
        if (successCode != 200) {
          let errorMsgeFromBack = this.getResponse.Data.Message;
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
          return false;
        }
        else if (successCode == 200) {
          this.dataRec = this.getResponse.Data;

          this.refProcessCostRowData = this.dataRec;
          this.showGrid = true;
        }
      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
        return false;
      })
    })
  }
  public loadtgtProcessCost() {

    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d
      let solutionId = MSenarioType.TargetScenario;

      this.processCostService.getInvestmentPlanData(this.configurationId, solutionId).subscribe((data) => {
        this.getResponse = data;
        let successCode = this.getResponse.StatusCode;
        if (successCode != 200) {
          let errorMsgeFromBack = this.getResponse.Data.Message;
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
          return false;
        }
        else if (successCode == 200) {
          this.dataRec = this.getResponse.Data;
          this.tgtProcessCostRowData = this.dataRec;
          this.showGrid = true;
        }
      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
        return false;
      })
    })
  }
  loadAInvestmentPlan() {
    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d
      this.errorMessage = [];
      let solutionId = MSenarioType.OptionA;

      this.processCostService.getInvestmentPlanData(this.configurationId, solutionId).subscribe((data) => {
        this.getResponse = data;

        let successCode = this.getResponse.StatusCode;
        if (successCode != 200) {
          let errorMsgeFromBack = this.getResponse.Data.Message;
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
          return false;
        }
        else if (successCode == 200) {
          this.dataRec = this.getResponse.Data;
          this.opAProcessCostRowData = this.dataRec;
          this.opAProcessCostRowData.forEach(row => {
            if (row.OutputFinalTotal != null) {
              this.showGrid = true;
              this.optAVisible = true
            }
          });

        }
      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
        return false;
      })
    })
  }
  loadBInvestmentPlan() {
    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d
      this.errorMessage = [];
      let solutionId = MSenarioType.OptionB;

      this.processCostService.getInvestmentPlanData(this.configurationId, solutionId).subscribe((data) => {
        this.getResponse = data;
        let successCode = this.getResponse.StatusCode;
        if (successCode != 200) {
          let errorMsgeFromBack = this.getResponse.Data.Message;
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
          return false;
        }
        else if (successCode == 200) {
          this.dataRec = this.getResponse.Data;
          this.opBProcessCostRowData = this.dataRec;
          this.opBProcessCostRowData.forEach(row => {
            if (row.OutputFinalTotal != null) {
              this.showGrid = true;
              this.optBVisible = true
            }
          });

        }
      }, (error) => {

        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
        return false;
      })
    })
  }
  loadCInvestmentPlan() {
    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d
      this.errorMessage = [];
      let solutionId = MSenarioType.OptionC;

      this.processCostService.getInvestmentPlanData(this.configurationId, solutionId).subscribe((data) => {
        this.getResponse = data;
        let successCode = this.getResponse.StatusCode;
        if (successCode != 200) {
          let errorMsgeFromBack = this.getResponse.Data.Message;
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
          return false;
        }
        else if (successCode == 200) {
          this.dataRec = this.getResponse.Data;
          this.opCProcessCostRowData = this.dataRec;
          this.opCProcessCostRowData.forEach(row => {
            if (row.OutputFinalTotal != null) {
              this.showGrid = true;
              this.optCVisible = true
            }
          });

        }
      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
        return false;
      })
    })
  }
  loadreferenceGrid() {

    this.showGrid = true;
    this.refProcessCostColumnDefs = [
      {
        headerName: "",
        //  headerClass: "processcost-class",
        children: [
          {
            headerName: "Process Costs", headerTooltip: "Process Costs",
            children: [
              // { headerName:"ID",field: 'id',minWidth: 10 },
              {
                headerName: "Subprocess", headerTooltip: "Subprocess",
                cellStyle: { 'text-align': 'left !important' },
                width: 120,
                suppressSizeToFit: true,
                field: 'SubProcessTitle',
                tooltipField: "SubProcessTitle",
                cellRendererFramework: GridlinkComponent
              }
            ]
          },
        ]
      },
      {
        headerName: "Output", headerTooltip: "Output",
        children: [
          {
            headerName: "One Time", headerTooltip: "One Time",
            headerClass: "border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputOTEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputOTCost',
                tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputOTTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTTotal;
                  }
                }/*valueGetter:oneTimeTotal*/
              },
            ]
          },

          {
            headerName: "Recurring/year", headerTooltip: "Recurring/year",
            headerClass: "ry-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRYEffort', //tooltipValueGetter: CurrencyCellRenderer,cellRenderer: CurrencyCellRenderer, 
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRYCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRYTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYTotal;
                  }
                }/*valueGetter:reccuringPerYrTotal*/
              },
            ]
          },

          {
            headerName: "Recurring over lifetime", headerTooltip: "Recurring over lifetime",
            headerClass: "rl-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRLEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRLCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRLTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLTotal;
                  }
                }
              },
            ]
          },

          {
            headerName: "Total", headerTooltip: "Total",
            headerClass: "border-left",
            children: [
              {
                headerName: "Over Lifetime", headerTooltip: "Over Lifetime", field: 'OutputFinalTotal', tooltipValueGetter: CurrencyCellRenderer,
                cellRenderer: CurrencyCellRenderer,
                cellClass: 'colorTotal numCell-align border-left', headerClass: 'border-left',
                valueGetter: overLifetimeTotal
              },
            ]
          },
        ]
      },

    ];

    this.domLayout = 'autoHeight';
    this.rowClassRules = {
      'rowpin-background': function (params: any) {
        return params.data.SubProcessTitle === "Total process cost"
      },

    };
    this.refProcessCostDefaultColDef = {
      suppressMovable: true,
      sortable: false,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;

    this.refProcessCostRowData = [];
  }

  onRefProcessCostGridReady(params: any) {
    this.refProcessCostGridApi = params.api;
    this.refProcessCostgridColumnApi = params.columnApi;

    this.refProcessCostGridApi.setGroupHeaderHeight(25);
    setTimeout(() => {
      let pinnedBottomData = this.generatePinnedBottomData();
      this.refProcessCostGridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 1000)
  }

  generatePinnedBottomData() {
    // generate a row-data with null values
    let result = { "OutputOTEffort": null, 'OutputOTCost': null, "OutputOTTotal": null, "OutputRYEffort": null, "OutputRYCost": null, "OutputRYTotal": null, "OutputRLEffort": null, "OutputRLCost": null, "OutputRLTotal": null, "OutputFinalTotal": null }

    return this.calculatePinnedBottomData(result);

  }
  calculatePinnedBottomData(target: any) {
    let columnsWithAggregation = ["OutputOTEffort", "OutputOTCost", "OutputOTTotal", "OutputRYEffort", "OutputRYCost", "OutputRYTotal", "OutputRLEffort", "OutputRLCost", "OutputRLTotal", "OutputFinalTotal"]
    columnsWithAggregation.forEach(element => {

      this.refProcessCostGridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element])
          target[element] += Number(rowNode.data[element]);
      });
    })
    target['SubProcessTitle'] = 'Total process cost';
    return target;
  }
  onRefProcessCostGridSizeChanged(params) {
    this.refProcessCostGridApi = params.api;
    this.refProcessCostGridApi.sizeColumnsToFit();
  }
  public openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  loadtargetGrid() {

    this.showGrid = true;
    this.tgtProcessCostColumnDefs = [
      {
        headerName: "",
        //  headerClass: "processcost-class",
        children: [
          {
            headerName: "Process Costs", headerTooltip: "Process Costs",
            children: [
              // { headerName:"ID",field: 'id',minWidth: 10 },
              {
                headerName: "Subprocess", headerTooltip: "Subprocess",
                cellStyle: { 'text-align': 'left !important' },
                width: 120,
                suppressSizeToFit: true,
                field: 'SubProcessTitle',
                tooltipField: "SubProcessTitle",
                cellRendererFramework: GridlinkComponent
              }
            ]
          },
        ]
      },
      {
        headerName: "Output", headerTooltip: "Output",
        children: [
          {
            headerName: "One Time", headerTooltip: "One Time",
            headerClass: "border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputOTEffort', // tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, 
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputOTCost',

                tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputOTTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTTotal;
                  }
                }/*valueGetter:oneTimeTotal*/
              },
            ]
          },

          {
            headerName: "Recurring/year", headerTooltip: "Recurring/year",
            headerClass: "ry-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRYEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRYCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRYTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYTotal;
                  }
                }/*valueGetter:reccuringPerYrTotal*/
              },
            ]
          },

          {
            headerName: "Recurring over lifetime", headerTooltip: "Recurring over lifetime",
            headerClass: "rl-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRLEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRLCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRLTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer,
                cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLTotal;
                  }
                }
              },
            ]
          },

          {
            headerName: "Total", headerTooltip: "Total",
            headerClass: "border-left",
            children: [
              {
                headerName: "Over Lifetime", headerTooltip: "Over Lifetime", field: 'OutputFinalTotal', tooltipValueGetter: CurrencyCellRenderer,
                cellRenderer: CurrencyCellRenderer,
                cellClass: 'colorTotal numCell-align border-left', headerClass: 'border-left',
                valueGetter: overLifetimeTotal
              },
            ]
          },
        ]
      },

    ];

    this.domLayout = 'autoHeight';
    this.rowClassRules = {
      'rowpin-background': function (params: any) {
        return params.data.SubProcessTitle === "Total process cost"
      },

    };
    // this.refProcessCostDefaultColDef={
    //   suppressMovable: true,
    //   sortable: false,
    // }
    this.rowHeight = 25;
    this.headerHeight = 48;

    this.tgtProcessCostRowData = [];
  }


  ontgtProcessCostGridSizeChanged(params) {
    this.tgtProcessCostGridApi = params.api;
    this.tgtProcessCostGridApi.sizeColumnsToFit();
  }

  ontgtProcessCostGridReady(params: any) {
    this.tgtProcessCostGridApi = params.api;
    this.tgtProcessCostgridColumnApi = params.columnApi;

    this.tgtProcessCostGridApi.setGroupHeaderHeight(25);
    setTimeout(() => {
      let pinnedBottomData = this.generatePinnedBottomData();
      this.tgtProcessCostGridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 1000)
  }


  loadOptionA() {
    this.opAProcessCostColumnDefs = [
      {
        headerName: "",
        headerClass: "processcost-class",
        children: [
          {
            headerName: "Process Costs",
            headerTooltip: "Process Costs",
            children: [
              {
                headerName: "Subprocess",
                headerTooltip: "Subprocess",
                cellStyle: { 'text-align': 'left !important' },
                width: 120,
                suppressSizeToFit: true,
                field: 'SubProcessTitle',
                tooltipField: 'SubProcessTitle',
                cellRendererFramework: GridlinkComponent
              }
            ]
          },
        ]
      },
      {
        headerName: "Output",
        headerTooltip: "Output",
        children: [
          {
            headerName: "One Time",
            headerTooltip: "One Time",
            headerClass: "border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputOTEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", field: 'OutputOTCost', headerTooltip: "cost [abs]", tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputOTTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTTotal;
                  }
                }
                /*valueGetter:oneTimeTotal*/
              },
            ]
          },

          {
            headerName: "Recurring/year",
            headerTooltip: "Recurring/year",
            headerClass: "ry-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRYEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRYCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRYTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYTotal;
                  }
                }
                /*valueGetter:reccuringPerYrTotal*/
              },
            ]
          },

          {
            headerName: "Recurring over lifetime",
            headerTooltip: "Recurring over lifetime",
            headerClass: "rl-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRLEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRLCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRLTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLTotal;
                  }
                }
              },
            ]
          },

          {
            headerName: "Total",
            headerTooltip: "Total",
            headerClass: "border-left",
            children: [
              { headerName: "Over Lifetime", headerTooltip: "Over Lifetime", field: 'OutputFinalTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align border-left', headerClass: 'border-left', valueGetter: overLifetimeTotal/*valueGetter:lifetimeTotal*/ },
            ]
          },
        ]
      },
    ];

    this.domLayout = 'autoHeight';
    this.rowClassRules = {
      'rowpin-background': function (params: any) {
        return params.data.SubProcessTitle === "Total process cost"
      },
    };

    this.opAProcessCostDefaultColDef = {
      suppressMovable: true,
      sortable: false,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.opAProcessCostRowData = [];
  }
  onopAProcessCostGridReady(params: any) {
    this.opAProcessCostGridApi = params.api;
    this.opAProcessCostgridColumnApi = params.columnApi;
    this.opAProcessCostGridApi.setGroupHeaderHeight(25);
    setTimeout(() => {
      let pinnedBottomData = this.generateAPinnedBottomData();
      this.opAProcessCostGridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 1000);
  }

  onopAProcessCostGridSizeChanged(params) {
    this.opAProcessCostGridApi = params.api;
    this.opAProcessCostGridApi.sizeColumnsToFit();
  }

  generateAPinnedBottomData() {
    // generate a row-data with null values
    let result = { "OutputOTEffort": null, 'OutputOTCost': null, "OutputOTTotal": null, "OutputRYEffort": null, "OutputRYCost": null, "OutputRYTotal": null, "OutputRLEffort": null, "OutputRLCost": null, "OutputRLTotal": null, "OutputFinalTotal": null }

    return this.calculateAPinnedBottomData(result);
  }

  calculateAPinnedBottomData(target: any) {
    let columnsWithAggregation = ["OutputOTEffort", "OutputOTCost", "OutputOTTotal", "OutputRYEffort", "OutputRYCost", "OutputRYTotal", "OutputRLEffort", "OutputRLCost", "OutputRLTotal", "OutputFinalTotal"]
    columnsWithAggregation.forEach(element => {
      this.opAProcessCostGridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element])
          target[element] += Number(rowNode.data[element]);
      });
    })
    target['SubProcessTitle'] = 'Total process cost';
    return target;
  }
  loadOptionB() {
    this.opBProcessCostColumnDefs = [
      {
        headerName: "",
        headerClass: "processcost-class",

        children: [
          {
            headerName: "Process Costs",
            headerTooltip: "Process Costs",
            children: [
              // { headerName:"ID",field: 'id',minWidth: 10 },
              {
                headerName: "Subprocess",
                headerTooltip: "Subprocess",
                cellStyle: { 'text-align': 'left !important' },
                width: 120,
                suppressSizeToFit: true,
                field: 'SubProcessTitle',
                tooltipField: 'SubProcessTitle',
                cellRendererFramework: GridlinkComponent
              }
            ]
          },
        ]
      },
      {
        headerName: "Output",
        headerTooltip: "Output",
        children: [
          {
            headerName: "One Time",
            headerTooltip: "One Time",
            headerClass: "border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputOTEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputOTCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputOTTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTTotal;
                  }
                }
                /*valueGetter:oneTimeTotal*/
              },
            ]
          },

          {
            headerName: "Recurring/year",
            headerTooltip: "Recurring/year",
            headerClass: "ry-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRYEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRYCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRYTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYTotal;
                  }
                }
                /*valueGetter:reccuringPerYrTotal*/
              },
            ]
          },

          {
            headerName: "Recurring over lifetime",
            headerTooltip: "Recurring over lifetime",
            headerClass: "rl-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRLEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRLCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRLTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLTotal;
                  }
                }
                /*valueGetter:reccuringLifetimeTotal*/
              },
            ]
          },

          {
            headerName: "Total",
            headerTooltip: "Total",
            headerClass: "border-left",
            children: [
              { headerName: "Over Lifetime", headerTooltip: "Over Lifetime", field: 'OutputFinalTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align border-left', headerClass: 'border-left', valueGetter: overLifetimeTotal/*valueGetter:lifetimeTotal*/ },
            ]
          },
        ]
      },

    ];

    this.domLayout = 'autoHeight';
    this.rowClassRules = {
      'rowpin-background': function (params: any) {
        return params.data.SubProcessTitle === "Total process cost"
      },

    };
    this.opBProcessCostDefaultColDef = {
      suppressMovable: true,
      sortable: false,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.opBProcessCostRowData = [];

  }
  onopBProcessCostGridReady(params: any) {
    this.opBProcessCostGridApi = params.api;
    this.opBProcessCostgridColumnApi = params.columnApi;

    this.opBProcessCostGridApi.setGroupHeaderHeight(25);
    setTimeout(() => {
      let pinnedBottomData = this.generateBPinnedBottomData();
      this.opBProcessCostGridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 1000)
  }

  onopBProcessCostGridSizeChanged(params) {
    this.opBProcessCostGridApi = params.api;
    this.opBProcessCostGridApi.sizeColumnsToFit();
  }
  generateBPinnedBottomData() {
    // generate a row-data with null values
    let result = { "OutputOTEffort": null, 'OutputOTCost': null, "OutputOTTotal": null, "OutputRYEffort": null, "OutputRYCost": null, "OutputRYTotal": null, "OutputRLEffort": null, "OutputRLCost": null, "OutputRLTotal": null, "OutputFinalTotal": null }

    return this.calculateBPinnedBottomData(result);

  }
  calculateBPinnedBottomData(target: any) {
    let columnsWithAggregation = ["OutputOTEffort", "OutputOTCost", "OutputOTTotal", "OutputRYEffort", "OutputRYCost", "OutputRYTotal", "OutputRLEffort", "OutputRLCost", "OutputRLTotal", "OutputFinalTotal"]
    columnsWithAggregation.forEach(element => {

      this.opBProcessCostGridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element])
          target[element] += Number(rowNode.data[element]);
      });
    })
    target['SubProcessTitle'] = 'Total process cost';
    return target;
  }
  loadOptionC() {
    this.opCProcessCostColumnDefs = [
      {
        headerName: "",
        headerClass: "processcost-class",
        children: [
          {
            headerName: "Process Costs", headerTooltip: "Process Costs",
            children: [
              // { headerName:"ID",field: 'id',minWidth: 10 },
              {
                headerName: "Subprocess", headerTooltip: "Subprocess",
                cellStyle: { 'text-align': 'left !important' },
                width: 120,
                suppressSizeToFit: true,
                field: 'SubProcessTitle',
                tooltipField: 'SubProcessTitle',
                cellRendererFramework: GridlinkComponent
              }
            ]
          },
        ]
      },
      {
        headerName: "Output", headerTooltip: "Output",
        children: [
          {
            headerName: "One Time", headerTooltip: "One Time",
            headerClass: "border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputOTEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputOTCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputOTTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputOTTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputOTTotal;
                  }
                }/*valueGetter:oneTimeTotal*/
              },
            ]
          },

          {
            headerName: "Recurring/year", headerTooltip: "Recurring/year",
            headerClass: "ry-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRYEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRYCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRYTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRYTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRYTotal;
                  }
                }
                /*valueGetter:reccuringPerYrTotal*/
              },
            ]
          },

          {
            headerName: "Recurring over lifetime", headerTooltip: "Recurring over lifetime",
            headerClass: "rl-header-class border-left",
            children: [
              {
                headerName: "effort [abs]", headerTooltip: "effort [abs]", field: 'OutputRLEffort', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align border-left', headerClass: 'border-left',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLEffort == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLEffort;
                  }
                }
              },
              {
                headerName: "cost [abs]", headerTooltip: "cost [abs]", field: 'OutputRLCost', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLCost == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLCost;
                  }
                }
              },
              {
                headerName: "Total", headerTooltip: "Total", field: 'OutputRLTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align',
                valueGetter: function (params) {
                  if (params.node.data.OutputRLTotal == null) {
                    return 0;
                  } else {
                    return params.node.data.OutputRLTotal;
                  }
                }/*valueGetter:reccuringLifetimeTotal*/
              },
            ]
          },

          {
            headerName: "Total",
            headerTooltip: "Total",
            headerClass: "border-left",
            children: [
              { headerName: "Over Lifetime", headerTooltip: "Over Lifetime", field: 'OutputFinalTotal', tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, cellClass: 'colorTotal numCell-align border-left', headerClass: 'border-left', valueGetter: overLifetimeTotal/*valueGetter:lifetimeTotal*/ },
            ]
          },
        ]
      },

    ];

    this.domLayout = 'autoHeight';
    this.rowClassRules = {
      'rowpin-background': function (params: any) {
        return params.data.SubProcessTitle === "Total process cost"
      },

    };
    this.opCProcessCostDefaultColDef = {
      suppressMovable: true,
      sortable: false,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.opCProcessCostRowData = [];
  }
  onopCProcessCostGridReady(params: any) {
    this.opCProcessCostGridApi = params.api;
    this.opCProcessCostgridColumnApi = params.columnApi;

    this.opCProcessCostGridApi.setGroupHeaderHeight(25);
    setTimeout(() => {
      let pinnedBottomData = this.generateCPinnedBottomData();
      this.opCProcessCostGridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 1000)
  }

  onopCProcessCostGridSizeChanged(params) {
    this.opCProcessCostGridApi = params.api;
    this.opCProcessCostGridApi.sizeColumnsToFit();
  }
  generateCPinnedBottomData() {
    // generate a row-data with null values
    let result = { "OutputOTEffort": null, 'OutputOTCost': null, "OutputOTTotal": null, "OutputRYEffort": null, "OutputRYCost": null, "OutputRYTotal": null, "OutputRLEffort": null, "OutputRLCost": null, "OutputRLTotal": null, "OutputFinalTotal": null }

    return this.calculateCPinnedBottomData(result);

  }
  calculateCPinnedBottomData(target: any) {
    let columnsWithAggregation = ["OutputOTEffort", "OutputOTCost", "OutputOTTotal", "OutputRYEffort", "OutputRYCost", "OutputRYTotal", "OutputRLEffort", "OutputRLCost", "OutputRLTotal", "OutputFinalTotal"]
    columnsWithAggregation.forEach(element => {

      this.opCProcessCostGridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element])
          target[element] += Number(rowNode.data[element]);
      });
    })
    target['SubProcessTitle'] = 'Total process cost';
    return target;
  }
  // get called when user click on cancel and exit;
  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea'])
  }
  // move user to back page;
  onClickBack() {
    this.hasUnsavedChanges = false;
    this.menuService.onClickNextAndBack("back")
  }
  // moves user to the next page;
  onClickNext() {
    this.hasUnsavedChanges = false;
    this.menuService.onClickNextAndBack("next")
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  ngOnDestroy(): void {
    this.configSub.unsubscribe();
  }
}
function overLifetimeTotal(params) {
  let data = params.node.data;
  params.data.OutputFinalTotal = Number(data.OutputOTTotal) + Number(data.OutputRLTotal);
  if (params.data.OutputFinalTotal == null) {
    params.data.OutputFinalTotal = 0;
  }
  return params.data.OutputFinalTotal;

}
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(0)));
};