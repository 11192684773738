import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


import { Observable, throwError } from 'rxjs';

import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Cscost } from '../models/Cscost';

@Injectable({
  providedIn: 'root'
})
export class CstargetcostingService {

  readonly baseUrl = environment.baseURL

  private urlGetCsTargetCostingData = this.baseUrl + "api/TCCS/GetTCCSByConfigurationId?configurationId=";
  private urlPostCSData = this.baseUrl + "api/TCCS/InsertTCCSTargetCost";
  constructor(private http: HttpClient) { }
  
  getCsTargetcostingData(configurationId): Observable<any> {
    return this.http.get<any>(this.urlGetCsTargetCostingData + configurationId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }


  InsertupdateCScost(data): Observable<Cscost> {
    return this.http.post<Cscost>(this.urlPostCSData, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  // It handle error ,server connection error and throws it to the component where its is recieved in the error of "subscribe" of service call method;
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
