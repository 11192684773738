<div class="col-md-12 col-lg-12" style="background-color: #CF4B00; margin-top: 0; height: 35px; margin-bottom: 10px;">
    <label class="label-headercolor label-text" style="padding-bottom: 0 !important;">Create VBLO </label>
</div>
<form [formGroup]="configurationForm" class="container">
    <div class="row" style="margin-top: 10px; margin-bottom: 10px;">
        <div class="col-md-3 col-lg-3">
            <label>VBLO Title<span class="asterisk_input">*</span></label>
        </div>
        <div class="col-md-9 col-lg-9">
            <div class="textfield js-textfield textfield-with-prefix"
                style="margin-bottom: -1.66667rem !important; width: 300%;">
                <input maxlength="255" type="text" aria-label="title" class="form-control textfield__input invalid"
                    formControlName="title" />
            </div>
        </div>
    </div>

    <!-- <div class="row" style="margin-top: 10px; margin-bottom: 10px;">
        <div class="col-md-3 col-lg-3">
            <label>VBLO-Mat.Amount</label>
        </div>
        <div class="col-md-3 col-lg-3">
            <div class="textfield js-textfield textfield-with-prefix"
                style="margin-bottom: -1.66667rem !important; width: 300%;">
                <input maxlength="255" type="text" aria-label="vbloamount" class="form-control textfield__input invalid"
                    formControlName="vbloamount" />
            </div>
        </div>
        <div class="col-md-3 col-lg-3">
            <label>VBLO-Mat.Price</label>
        </div>
        <div class="col-md-3 col-lg-3">
            <div class="textfield js-textfield textfield-with-prefix"
                style="margin-bottom: -1.66667rem !important; width: 300%;">
                <input maxlength="255" type="text" aria-label="vbloprice" class="form-control textfield__input invalid"
                    formControlName="vbloprice" />
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 10px; margin-bottom: 10px;">

        <div class="col-md-3 col-lg-3">
            <label>VBLO-Mat.Date </label>
        </div>
        <div class="col-md-2 col-lg-2" style="margin-top: 4px;">
            <input class="ttmp" type="text" aria-label="Datepicker" [matDatepicker]="picker4"
                formControlName="dateVBLOMat" placeholder=" mm/dd/yyyy" style="width: 120%;" />
        </div>
        <div class="col-md-1 col-lg-1">
            <mat-datepicker-toggle class="ttmp1" matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
        </div>
        <div class="col-md-3 col-lg-3">
            <label>VBLO-Mat.Kreditor </label>
        </div>
        <div class="col-md-3 col-lg-3">
            <div class="textfield js-textfield textfield-with-prefix"
                style="margin-bottom: -1.66667rem !important; width: 300%;">
                <input maxlength="255" type="text" aria-label="vblokreditor"
                    class="form-control textfield__input invalid" formControlName="vblokreditor" />
            </div>
        </div>
    </div> -->
    <div class="row" style="margin-top: 10px; margin-bottom: 10px;">
        <div class="col-md-3 col-lg-3">
            <label>VBLO Type<span class="asterisk_input">*</span></label>
        </div>
        <div class="col-md-3 col-lg-3">
            <div class="textfield js-textfield textfield-with-prefix"
                style="margin-bottom: -1.66667rem !important; width: 300%;">
                <!-- <input maxlength="255" type="text" aria-label="vblotype" class="form-control textfield__input invalid"
                    formControlName="vblotype" /> -->
                <select id="vbloType" class="form-control invalid" aria-label="vbloType" formControlName="vblotype"
                    style="margin-top: 4px; height: 30px !important; font-size: 0.7rem !important; padding: 1px;">
                    <option value="">Please select</option>
                    <option *ngFor="let s of vbloTypeList" [value]="s">
                        {{s}}
                    </option>
                </select>
            </div>
        </div>

        <div class="col-md-3 col-lg-3">
            <label>Assembly<span class="asterisk_input">*</span></label>
        </div>
        <div class="col-md-3 col-lg-3">
            <div class="textfield js-textfield textfield-with-prefix"
                style="margin-bottom: -1.66667rem !important; width: 300%;">
                <!-- <input maxlength="255" type="text" aria-label="vblotype" class="form-control textfield__input invalid"
                    formControlName="assembly" /> -->
                <select id="assembly" class="form-control invalid" aria-label="assembly" formControlName="assembly"
                    style="margin-top: 4px; height: 30px !important; font-size: 0.7rem !important; padding: 1px;">
                    <option value="">Please select</option>
                    <option *ngFor="let s of assemblyList" [value]="s">
                        {{s}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</form>
<!-- <div mat-dialog-content>This dialog showcases the title, close, content and actions elements.</div> -->
<mat-dialog-actions align="end" style="margin-top: 82px;">
    <button class="button button--small" mat-dialog-close>
        Cancel</button>&nbsp;
    <button class="button button--small" cdkFocusInitial (click)="onSubmit($event)">
        Save</button>&nbsp;
</mat-dialog-actions>