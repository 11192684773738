<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>

<div class="container">
    <div class="label-header">
        <label class="label-headercolor">SCM Target Costs </label>
    </div>

    <div style="overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
        <div class="col-md-5 col-lg-5 label-header" style="display: inline-block;">
            <!-- <label class="label-headercolor">Reference Scenario: {{ referenceTitle }} </label> -->
            <label class="label-headercolor">Reference Scenario: <input type="text" [(ngModel)]="refTitle"
                    placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>

        <div class="col-md-7 col-lg-7 label-header" style="margin-left: 15px; display: inline-block;">
            <!-- <label class="label-headercolor">Target Scenario: {{ targetTitle }} </label> -->
            <label class="label-headercolor">Target Scenario: <input type="text" [(ngModel)]="tarTitle"  placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optAVisible" class="col-md-7 col-lg-7 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option A: <input [(ngModel)]="optATitle" type="text"
                    placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optBVisible" class="col-md-7 col-lg-7 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option B: <input [(ngModel)]="optBTitle" type="text"
                    placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div *ngIf="optCVisible" class="col-md-7 col-lg-7 label-header"
            style="margin-left: 15px; display: inline-block;">
            <label class="label-headercolor">Option C: <input [(ngModel)]="optCTitle" type="text"
                    placeholder="Add Title"
                    style="background-color: transparent;border: none;color: white;font-weight: 600;font-size: 1em;">
            </label>
        </div>
        <div class="col-md-1 col-lg-1 label-header" style="margin-left: 15px; display: inline-block;">
            <button [disabled]="!isEditable" [ngClass]="{ 'click-not-allowed': !isEditable }" (click)="addNewRow()"
                title="Add">
                <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add" />
                <label class="label-headercolor">add Option</label>
            </button>
        </div>
        <br>

        <div class="col-md-5 col-lg-5" style="padding-left: 0px;
         padding-right:0px; 
         display: inline-block;">
            <div class="col-md-5 col-lg-5 label-header" style="display: inline-block;width: 45.2%;">
                <label class="label-headercolor">Labour (Add item </label>
                <button [disabled]="!isEditable" [ngClass]="{'click-not-allowed':!isEditable}" (click)="AddNewRowRef()"
                    title="Add">
                    <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add">
                </button><label class="label-headercolor"> )</label>
            </div>

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;;" class="ag-theme-alpine" id="refLabourGrid"
                [rowData]="refGridLabourData" [columnDefs]="refColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="refFrameworkComponents"
                [defaultColDef]="refDefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onRefCellValueChangedLabour($event)"
                (gridReady)="onRefGridReady($event)" (gridSizeChanged)="onGridRefChanged($event)"
                [getRowHeight]="getRefRowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight"
                (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>

        <div class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="tarLabourGrid"
                [rowData]="tarGridLabourData" [columnDefs]="tarColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="tarFrameworkComponents"
                [defaultColDef]="tarDefaultColDef" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onTarLabourCellValueChanged($event)"
                (gridReady)="onTarGridReady($event)" (gridSizeChanged)="onGridTarChanged($event)"
                [getRowHeight]="getTarRowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>

        <div *ngIf="optAVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optALabourGrid"
                [rowData]="optAGridLabourData" [columnDefs]="optAColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="frameworkComponents" [defaultColDef]="optADefaultColDef"
                [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptionALabourCellValueChanged($event)" (gridReady)="onTarAGridReady($event)"
                (gridSizeChanged)="onGridTarAChanged($event)" [getRowHeight]="getOptARowHeight" [rowHeight]="rowHeight"
                [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <div *ngIf="optBVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optBLabourGrid"
                [rowData]="optBGridLabourData" [columnDefs]="optBColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="frameworkComponents" [defaultColDef]="optBDefaultColDef"
                [headerHeight]="headerHeight" [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptionBLabourCellValueChanged($event)" (gridReady)="onTarBGridReady($event)"
                (gridSizeChanged)="onGridTarBChanged($event)" [getRowHeight]="getOptBRowHeight" [rowHeight]="rowHeight"
                [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <div *ngIf="optCVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optCLabourGrid"
                [rowData]="optCGridLabourData" [columnDefs]="optCColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="frameworkComponents" [defaultColDef]="optCDefaultColDef"
                [headerHeight]="headerHeight" [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptionCLabourCellValueChanged($event)" (gridReady)="onTarCGridReady($event)"
                (gridSizeChanged)="onGridTarCChanged($event)" [getRowHeight]="getOptCRowHeight" [rowHeight]="rowHeight"
                [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <br>

        <div class="col-md-5 col-lg-5" style="padding-left: 0px;
        padding-right:0px; 
        display: inline-block;">
            <div class="col-md-5 col-lg-5 label-header" style="display: inline-block;width: 45.2%;">
                <label class="label-headercolor">Overhead (Add item </label>
                <button [disabled]="!isEditable" [ngClass]="{'click-not-allowed':!isEditable}"
                    (click)="AddNewRowOverheadRef()" title="Add">
                    <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add">
                </button><label class="label-headercolor"> )</label>
            </div>

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;;" class="ag-theme-alpine" id="refOverheadGrid"
                [rowData]="refGridOverheadData" [columnDefs]="refColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="refFrameworkComponents"
                [defaultColDef]="refDefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onRefOverheadCellValueChanged($event)"
                (gridReady)="onRefGridReadyoverhead($event)" (gridSizeChanged)="onGridRefChanged1($event)"
                [getRowHeight]="getRefRowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>

        <div class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="tarOverheadGrid"
                [rowData]="tarGridOverheadData" [columnDefs]="tarColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="tarFrameworkComponents"
                [defaultColDef]="tarDefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onTarOverheadCellValueChanged($event)"
                (gridReady)="onTarGridOverheadReady($event)" (gridSizeChanged)="onGridTarChanged1($event)"
                [getRowHeight]="getTarRowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>


        <div *ngIf="optAVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optAOverheadGrid"
                [rowData]="optAGridOverheadData" [columnDefs]="optAColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="frameworkComponents" [defaultColDef]="optADefaultColDef"
                [headerHeight]="headerHeight" [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptionAOverheadCellValueChanged($event)"
                (gridReady)="onTarGridAOverheadReady($event)" (gridSizeChanged)="onGridTarAChanged1($event)"
                [getRowHeight]="getOptARowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <div *ngIf="optBVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optBOverheadGrid"
                [rowData]="optBGridOverheadData" [columnDefs]="optBColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="frameworkComponents" [defaultColDef]="optBDefaultColDef"
                [headerHeight]="headerHeight" [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptionBOverheadCellValueChanged($event)" (gridReady)="onTarBOverheadReady($event)"
                (gridSizeChanged)="onGridTarBChanged1($event)" [getRowHeight]="getOptBRowHeight" [rowHeight]="rowHeight"
                [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <div *ngIf="optCVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optCOverheadGrid"
                [rowData]="optCGridOverheadData" [columnDefs]="optCColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="frameworkComponents" [defaultColDef]="optCDefaultColDef"
                [headerHeight]="headerHeight" [enableBrowserTooltips]="true" [popupParent]="popupParent"
                (cellValueChanged)="onOptionCOverheadCellValueChanged($event)" (gridReady)="onTarCOverheadReady($event)"
                (gridSizeChanged)="onGridTarCChanged1($event)" [getRowHeight]="getOptCRowHeight" [rowHeight]="rowHeight"
                [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <br>

        <div class="col-md-5 col-lg-5" style="padding-left: 0px;
        padding-right:0px; 
        display: inline-block;">
            <div class="col-md-5 col-lg-5 label-header" style="display: inline-block;width: 45.2%;">
                <label class="label-headercolor">Special Direct Costs (Add item </label>
                <button [disabled]="!isEditable" [ngClass]="{'click-not-allowed':!isEditable}"
                    (click)="AddNewRowSpecialRef()" title="Add">
                    <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add">
                </button><label class="label-headercolor"> )</label>
            </div>
            <ag-grid-angular *ngIf="showGrid" style="width: 100%;;" class="ag-theme-alpine" id="refSpecialGrid"
                [rowData]="refGridSpecialDirectCostData" [columnDefs]="refColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="refFrameworkComponents"
                [defaultColDef]="refDefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onRefSpecialCellValueChanged($event)"
                (gridReady)="onRefGridReadySpecial($event)" (gridSizeChanged)="onGridRefChanged2($event)"
                [getRowHeight]="getRefRowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>

        <div class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="tarSpecialGrid"
                [rowData]="tarGridSpecialDirectCostData" [columnDefs]="tarColumnDefs" [rowClassRules]="rowClassRulesRef"
                [domLayout]="domLayout" [frameworkComponents]="tarFrameworkComponents"
                [defaultColDef]="tarDefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onTarSpecialCellValueChanged($event)"
                (gridReady)="onTarGridSpecialReady($event)" (gridSizeChanged)="onGridTarChanged2($event)"
                [getRowHeight]="getTarRowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <div *ngIf="optAVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optASpecialGrid"
                [rowData]="optAGridSpecialDirectCostData" [columnDefs]="optAColumnDefs"
                [rowClassRules]="rowClassRulesRef" [domLayout]="domLayout" [frameworkComponents]="frameworkComponents"
                [defaultColDef]="optADefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onOptionASpecialCellValueChanged($event)"
                (gridReady)="onTarGridASpecialReady($event)" (gridSizeChanged)="onGridTarAChanged2($event)"
                [getRowHeight]="getOptARowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <div *ngIf="optBVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optBSpecialGrid"
                [rowData]="optBGridSpecialDirectCostData" [columnDefs]="optBColumnDefs"
                [rowClassRules]="rowClassRulesRef" [domLayout]="domLayout" [frameworkComponents]="frameworkComponents"
                [defaultColDef]="optBDefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onOptionBSpecialCellValueChanged($event)"
                (gridReady)="onTarBSpecialReady($event)" (gridSizeChanged)="onGridTarBChanged2($event)"
                [getRowHeight]="getOptBRowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <div *ngIf="optCVisible" class="col-md-7 col-lg-7"
            style="margin-left: 15px;padding-left: 0px;padding-right: 0px; display: inline-block;">

            <ag-grid-angular *ngIf="showGrid" style="width: 100%;" class="ag-theme-alpine" id="optCSpecialGrid"
                [rowData]="optCGridSpecialDirectCostData" [columnDefs]="optCColumnDefs"
                [rowClassRules]="rowClassRulesRef" [domLayout]="domLayout" [frameworkComponents]="frameworkComponents"
                [defaultColDef]="optCDefaultColDef" [headerHeight]="headerHeight" [enableBrowserTooltips]="true"
                [popupParent]="popupParent" (cellValueChanged)="onOptionCSpecialCellValueChanged($event)"
                (gridReady)="onTarCSpecialReady($event)" (gridSizeChanged)="onGridTarCChanged2($event)"
                [getRowHeight]="getOptCRowHeight" [rowHeight]="rowHeight" [headerHeight]="headerHeight" (displayedColumnsChanged)="onDisplayedColumnsChanged">
            </ag-grid-angular>
        </div>
        <br>
    </div>

    <div class="row">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle" style="float: right; margin-right: 5px; margin-bottom: 20px">
                <button class="button button--small" (click)="onClickCancel()">
                    Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
                    Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
                    Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted || !isEditable" (click)="submit($event)"
                    type="submit">
                    Save & Exit
                </button>
            </div>
        </div>
    </div>

    <app-commentbox [configData]="objData"></app-commentbox>
</div>