import { Component, Input, OnInit } from '@angular/core';
import { ideaPageConstants } from 'src/app/app-constants/ideaPage-constants';
import { UserReplacementService } from 'src/app/services/user-replacement.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { CommentService } from '../services/comment.service';
import { Comments } from 'src/app/models/comment'
import { BrowserModule, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { serverMessage } from '../popUpMessages/serverMessage';
import { ProjectService } from '../services/project.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedServiceService } from '../services/shared-service.service';
import { UserInfoService } from '../services/user-info.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-commentbox',
  templateUrl: './commentbox.component.html',
  styleUrls: ['./commentbox.component.css']
})
export class CommentboxComponent implements OnInit {

  public allActInActUser: any;
  public responsiblePeoplePickr: FormGroup;
  // submitter people picker form variable;
  public submitterPeoplePickr: FormGroup;
  public submitterSearchData: any = [];
  public oldDataSubmitter: any;
  public submitResponse: any;
  public searchInputSubmitter = new FormControl();
  public submitterId: any;
  selectedValue: string;
  public commentsToSend = new Comments();
  public ideaId: any;
  private previousInput = '';
  public hideoption = true;
  public email: string;
  public commentsData;
  public dataRecieved: any;
  public projectId: any;
  public businessAreaId: number;
  public businessLineId: number;
  public userInOrg: any;
  public allUserList: any;
  public userDetails: any;
  CreatedDate: any;
  selectedCommentsData: any[];
  constructor(
    private userReplacementSer: UserReplacementService,
    private localStorageService: LocalStorageService,
    private _commentservice: CommentService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private sharedService: SharedServiceService,
    private userService: UserInfoService,
  ) { }
  editorStyle = {
    height: '720px',
    width: '150px'
  }
  @Input() configData: { projectId: number, moduleId: number, user: any };

  ngOnInit(): void {
    this.ideaId = this.localStorageService.get('ideaId');
    this.projectDetails();
    this.getComments();
    this.getProjectDetails();


  }

  comments: string[] = [];

  newComment: string = '';

  user;
  public sectionModel;

  projectDetails() {
    this.projectId = this.localStorageService.get("projectId");
    if (this.projectId != null) {
      this.projectService.getTCData(this.projectId).subscribe((data: any) => {
        if (data) {
          if (data.StatusCode == 200) {
            this.businessAreaId = data.Data.BusinessAreaId;
            this.businessLineId = data.Data.BusinessLineId;
          }
        }

        this.sharedService.loggedInUser.subscribe((user: any) => {
          if (user) {
            this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
              this.allUserList = d;
              this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
              this.userDetails = this.userInOrg;
            })
          }
        })
      });

    }

  }

  addComment() {
    let commentsToSend;
    if (this.newComment.trim()) {
      //this.comments.push(this.newComment);
      //this.newComment = '';
      commentsToSend = this.sendData(this.newComment);
    }
    let response = null;
    response = this._commentservice.postData(commentsToSend).toPromise().
      then((res) => {
        this.submitResponse = res;
        let successCode = this.submitResponse.StatusCode;
        let errorCode = this.submitResponse.Data.ErrorCode;
        let errorMsgeFromBack = this.submitResponse.Data.Message;
        if (successCode == 200) {
          if (errorCode == 0) {
            this.newComment = '';
            let dataSaveMessage = serverMessage.dataSaveMessage;
            this.getComments();
            //this.toast.notify(dataSaveMessage, "success");
          }
        }
      });
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  }

  getComments() {
    let projectId = this.configData.projectId;
    let moduleId = this.configData.moduleId;
    
    this._commentservice.getCommentsData(projectId, moduleId).subscribe((commentsdata: any) => {
      let errorMsgeFromBack = commentsdata.Data.Message;
      if (commentsdata.StatusCode == 200) {
        this.commentsData = commentsdata.Data;
        //  this.CreatedDate = formatDate(commentsdata.Data.CreatedOn, 'yyyy-MM-dd', 'en-US');
        let selectedComments = [];
        for (let i of this.commentsData) {
          this.CreatedDate = formatDate(i.CreatedOn, 'yyyy-MM-dd', 'en-US');
          selectedComments.push({ CreatedOn: this.CreatedDate,UserName:i.UserName,CommentText:i.CommentText });
          //this.ImProdIdSelData.push({ id: i.KMATVBLOId, ImpactedProductId: i.VBLOName });
        }
        this.selectedCommentsData = selectedComments;
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    });
  }
  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  onKeyUp(event: KeyboardEvent) {
    const input = (event.target as HTMLInputElement).innerText;
    //const input = this.newComment;
    //const matches = input.match(/@(\w{4})/g);
    const atIndex = input.lastIndexOf('@');
    if (atIndex !== -1) {
      const email = input.slice(atIndex + 1).split(' ')[0];
      if (email.length >= 4) {
        this.fetchUserData(email, true);
      }
    }
    if (input.trim() === '') {
      this.submitterSearchData = [];
    }
  }


  onEmailclick(email) {
    //let matches = this.newComment.match(/@\w+/g);
    const atIndex = this.newComment.lastIndexOf('@');
    const tatestvalue = this.newComment.substring(atIndex + 1);
    this.newComment = this.newComment.replace(tatestvalue, email);

    // for (let i = 0; i < matches.length; i++) {
    //   this.newComment = this.newComment.replace(matches[i], email);
    //   //let test = this.newComment.replace(/@([^@]*)$/, email);
    // }
    //this.searchInputSubmitter.setValue('');
    this.submitterSearchData = [];


  }
  onCancel() {
    this.newComment = '';
  }

  fetchUserData(input, bind) {
    let searchInput: string = "";
    // bind == false; it means function is called from the HTML;
    // bind == true; it means function is called from the Class;
    if (!bind) {
      searchInput = input.target.value.toLowerCase();
    } else {
      searchInput = input.toLowerCase();
    }
    // searchInput.length != 24 for to neglect the length for the space after selecting the email
    if (searchInput.length >= 4 && searchInput.length != 24) {
      if (bind) {
        {
          this.userReplacementSer.getUserList(1, 1).subscribe((data) => {
            this.allActInActUser = data;
            let userDetail = [];
            userDetail = this.searchUsrInUsrlst(this.allActInActUser, searchInput);
            this.fillSearchValue(bind, userDetail);
            //this.hideoption = false;

          })
        }
      }
    }
  }
  // It will run a search operation in the userlist recieved from DB and return the user Information;
  searchUsrInUsrlst(userList, searchValue) {
    let userDetail = []
    userList.filter(data => {
      if (data.Email.toLowerCase().includes(searchValue)) {
        userDetail.push({
          Email: data.Email,
          FirstName: data.FirstName,
          FullName: data.FullName,
          GID: data.GID,
          IsActive: data.IsActive,
          LastName: data.LastName,
          OrgCode: data.OrgCode,
          RoleId: data.RoleId,
          RoleTitle: data.RoleTitle,
          UserDetails: data.UserDetails,
          UserId: data.UserId
        });
      }
    });
    return userDetail;
  }
  getProjectDetails() {
    this.projectService.getTCData(this.configData.projectId).subscribe((data: any) => {
      let errorMsgeFromBack = data.Data.Message;
      if (data.StatusCode == 200) {
        this.dataRecieved = data;
        this.dataRecieved = this.dataRecieved.Data;
      }
    });
  }


  sendData(email: string) {
    let atValue: string = '';
    let match;
    let emailsList: string[] = [];
    const regex = /@(\S+)/g;
    while ((match = regex.exec(email)) != null) {
      emailsList.push(match[1]);
    }
    console.log(emailsList);
    const stringArray = emailsList.join(";");
    this.commentsToSend.ProjectId = this.configData.projectId;;
    this.commentsToSend.ModuleId = this.configData.moduleId;;
    this.commentsToSend.CommentText = this.newComment;
    this.commentsToSend.UserId = this.userInOrg.UserId;
    //this.commentsToSend.UserName = "Mahaboob";
    this.commentsToSend.UserEmail = this.userInOrg.Email;
    this.commentsToSend.TaggedEmail = stringArray;
    this.commentsToSend.refCommentId = null;
    this.commentsToSend.IsActive = true;
    this.commentsToSend.CreatedBy = this.userInOrg.UserId;
    this.commentsToSend.CreatedOn = new Date();
    this.commentsToSend.ModifiedBy = this.userInOrg.UserId;
    this.commentsToSend.ModifiedOn = new Date();

    return this.commentsToSend;
  }
  fillSearchValue(bind, userDetail) {
    this.submitterSearchData = userDetail;

    if (!bind) {
      this.submitterPeoplePickr.get("submitterPeoplePickr").setValue("");
    }
  }
  clearSearch() {
    this.searchInputSubmitter.setValue('');
  }



}


