export class Comments {
    //CommentId: number;
    ProjectId: number;
    ModuleId: number;
    CommentText:string;
    UserId: number;
    //UserName: string;
    UserEmail: string;
    //TaggedEmail: string [];
    TaggedEmail: string;
    refCommentId :string;
    IsActive: boolean;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}