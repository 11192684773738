import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { totalmem } from 'os';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { TcDirectcostserviceService } from 'src/app/services/tc-directcostservice.service';

let countryCode;
@Component({
  selector: 'app-tc-directcosts',
  templateUrl: './tc-directcosts.component.html',
  styleUrls: ['./tc-directcosts.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TcDirectcostsComponent implements OnInit, OnDestroy {
  public showGrid = false;
  public projectId: any;
  public hasUnsavedChanges: boolean = false;
  public submitted: boolean = false;
  refTitle: string = "";
  tarTitle: string = "";
  optATitle: string = "";
  optBTitle: string = "";
  optCTitle: string = "";
  domLayout: string;
  refdomLayout: string;
  OptADomLayout: string;
  OptBDomLayout: string;
  OptCDomLayout: string;
  refPinnedBottomData: any;
  tarPinnedBottomData: any;
  optAPinnedBottomData: any;
  optBPinnedBottomData: any;
  optCPinnedBottomData: any;
  rowClassRulesRef: any;
  rowClassRulesTar: any;
  rowClassRulesOptA: any;
  rowClassRulesOptB: any;
  rowClassRulesOptC: any;
  refTotalDirectCosts: any;
  tarTotalDirectCosts: any;
  optATotalDirectCosts: any;
  optBTotalDirectCosts: any;
  optCTotalDirectCosts: any;
  totalDirectCosts = 0;
  configSub: any;
  headerHeight: number;
  rowHeight: number;

  constructor(
    private tcDirectCostService: TcDirectcostserviceService,
    private localStorageService: LocalStorageService,
    private sharedService: SharedServiceService,
    private menuService: MenuService,
    private router: Router,
    public dialog: MatDialog,
  ) { }
  gridData: any[];
  tarGridData: any[];
  tarGridApi: any;
  tarDefaultColDef: any;
  tarColumnDefs: any;

  refColumnDefs: any;
  refDefaultColDef: any;
  refGridApi: any;
  refGridData: any[];


  optAColumnDefs: any;
  optADefaultColDef: any;
  optAGridData: any[];
  optAGridApi: any;

  optBGridData: any[];
  optBColumnDefs: any;
  optBDefaultColDef: any;
  optBGridApi: any;

  optCGridData: any[];
  optCColumnDefs: any;
  optCDefaultColDef: any;
  optCGridApi: any;

  configurationId: any;
  optAVisible: boolean = false;
  optBVisible: boolean = false;
  optCVisible: boolean = false;
  directCostData: any;
  objData: { projectId: any; moduleId: any; };
  moduleId: number = 12;


  ngOnInit(): void {
    this.projectId = this.localStorageService.get("projectId");
    this.objData = {
      projectId: this.projectId,
      moduleId: this.moduleId
    }
    countryCode = this.localStorageService.get('countryCode');
    this.loadData();
    this.sharedService.getProjectIdAndTitle();
    // this.loadRefGridStructure();
    // this.loadTarGridStructure();
    // this.loadOptAGridStructure();
    // this.loadOptBGridStructure();
    // this.loadOptCGridStructure();
  }
  loadData() {
    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d
      this.optAVisible = false;
      this.optBVisible = false;
      this.optCVisible = false;

      if (this.configurationId != null) {
        this.tcDirectCostService.getDirectcostsData(this.configurationId).subscribe((data: any) => {
          this.showGrid = true;
          if (data) {
            let errorMsgeFromBack = data.Message;
            if (data.StatusCode == 200) {
              this.directCostData = data;
              console.log(this.directCostData);
              this.gridData = this.directCostData.Data.ReferenceScenario;
              this.tarGridData = this.directCostData.Data.TargetScenario;
              this.optAGridData = this.directCostData.Data.OptionA;
              this.optBGridData = this.directCostData.Data.OptionB;
              this.optCGridData = this.directCostData.Data.OptionC;
              // console.log(this.gridData);
              // if (this.gridData.length !=0) {
              //   this.totalDirectCosts = 0;
              //   this.gridData.forEach(x => {
              //     this.totalDirectCosts += x.TotalDirectCosts;
              //   })
              //   //this.gridData["TotalcalDirectCosts"] = this.totalDirectCosts;
              // }
              if (this.optAGridData.length > 0) {
                this.optAVisible = true
              }
              if (this.optBGridData.length > 0) {
                this.optBVisible = true
              }
              if (this.optCGridData.length > 0) {
                this.optCVisible = true
              }
              let  total=0
              this.gridData.forEach((x, i) => {
                total += Number(this.gridData[i].TotalDirectCosts);
               
              })
              console.log(total);
              this.gridData["referenceTotal"] = total;

              this.gridData.forEach((x, i) => {
                x["referenceTotal"] = this.gridData[i].TotalDirectCosts;
              })
              this.tarGridData.forEach((x, i) => {
                x["referenceTotal"] = this.gridData["referenceTotal"];

              })
              this.optAGridData.forEach((x, i) => {
                x["referenceTotal"] = this.gridData["referenceTotal"];//this.gridData[i].referenceTotal;
                
              })
              this.optBGridData.forEach((x, i) => {
                x["referenceTotal"] = this.gridData["referenceTotal"];
              })
              this.optCGridData.forEach((x, i) => {
                x["referenceTotal"] = this.gridData["referenceTotal"];
              })
           
             // this.optAGridData["referenceTotalNew"] =this.gridData;
              // console.log(this.optAGridData);
              // console.log(this.gridData);
              // console.log(this.tarGridData);

              this.loadRefGridStructure();
              this.loadTarGridStructure();
              this.loadOptAGridStructure();
              this.loadOptBGridStructure();
              this.loadOptCGridStructure();

              this.refTitle = this.directCostData.Data.ReferenceScenario[0].ScenarioTitle ?? ""
              this.tarTitle = this.directCostData.Data.TargetScenario[0].ScenarioTitle ?? ""
              this.optATitle = this.directCostData.Data.OptionA?.ScenarioTitle ?? ""
              this.optBTitle = this.directCostData.Data.OptionB?.ScenarioTitle ?? ""
              this.optCTitle = this.directCostData.Data.OptionC?.ScenarioTitle ?? ""


            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
            }
          }
        });
      }
    });
  }
  loadRefGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.Type;
        return data === 'Total Cost';
      }
    }

    this.refColumnDefs = [
      {
        headerName: "Type",
        field: "Type",
        tooltipField: "Type",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Type",
        editable: false
      },
      {
        headerName: "Component",
        field: "Assembly",
        tooltipField: "Assembly",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Component",
        editable: false
      },
      {
        headerName: "Material",
        field: "Material",
        tooltipField: "Material",
        width: 100,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Material",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Labour",
        field: "Labor",
        tooltipField: "Labor",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Labour",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Overhead",
        field: "Overhead",
        tooltipField: "Overhead",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Overhead",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Product Costs",
        field: "DirectProductCosts",
        tooltipField: "DirectProductCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Direct Product Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Service Costs",
        field: "DirectServiceCosts",
        tooltipField: "DirectServiceCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "DirectServiceCosts",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Special Direct Costs",
        field: "SpecialDirectCosts",
        tooltipField: "SpecialDirectCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Special Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Local Costs",
        field: "LocalCosts",
        tooltipField: "LocalCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Local Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Special Costs",
        field: "TotalSpecialDirectCosts",
        tooltipField: "TotalSpecialDirectCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Special Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Direct Costs",
        field: "TotalDirectCosts",
        tooltipField: "TotalDirectCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.Type === 'Total Cost') {
            //return this.totalDirectCosts;
            console.log(params);
            let total = 0;
            if (params.api != null) {
              //conse.log(params.api)
              params.api.forEachNode((node) => {
                console.log(node.data.TotalDirectCosts)
                total += Number(node.data.TotalDirectCosts)
                console.log(total)
              })
              params.data.TotalDirectCosts = total;
              return total;
            }
          } else {
            return (params.data.TotalDirectCosts);
          }
        }
      },
    ];
    this.refDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    //this.gridData = [];
    this.refdomLayout = "autoHeight";
    this.showGrid = true;
  }
  onRefGridReady(params: any) {
    console.log(6);
    this.refGridApi = params.api;
    this.refGridApi.setGroupHeaderHeight(20);
    this.refPinnedBottomData = createDataRef();
    params.api.setPinnedBottomRowData(this.refPinnedBottomData);
  }
  onRefCellValueChanged(params) {

    let tar = this.tarGridData.find(x => x.Type == params.data.Type)
    tar.ReferenceCost = params.data.TotalDirectCosts
    this.tarGridApi.redrawRows();

  }
  // totalRef() {
  //   setTimeout(() => {
  //     let Costs = 0;
  //     //conse.log('HI');
  //     ////conse.log(this.refGridApi.data.TotalDirectCosts);
  //     this.refGridApi.forEachNode(function (node) {
  //       //conse.log('Node data:', node.data);
  //       //conse.log('HI');
  //       Costs = Number(node.data.TotalDirectCosts) + Costs
  //       //conse.log(node.data.TotalDirectCosts);

  //     })
  //     this.refTotalDirectCosts = Costs;
  //     //conse.log(Costs);
  //     return Costs;
  //   }, 1000);

  // }

  onGridRefChanged(params) {
    setTimeout(() => {
      this.refGridApi = params.api;
      this.refGridApi.sizeColumnsToFit();
    }, 1000);

  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedRef(params) {
    params.api.resetRowHeights();
  }

  onColumnVisibleRef(params) {
    params.api.resetRowHeights();
  }
  loadTarGridStructure() {
    this.rowClassRulesTar = {
      'colorTotal': function (params) {
        let data = params.data.Type;
        return data === 'Total Cost' || data === 'Change to Reference';
      }
    }
    this.tarColumnDefs = [
      {
        headerName: "Type",
        field: "Type",
        tooltipField: "Type",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Type",
        editable: false
      },
      {
        headerName: "Component",
        field: "Assembly",
        tooltipField: "Assembly",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Component",
        editable: false
      },
      {
        headerName: "Material",
        field: "Material",
        tooltipField: "Material",
        width: 100,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Material",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Labour",
        field: "Labour",
        tooltipField: "Labour",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Labour",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Overhead",
        field: "Overhead",
        tooltipField: "Overhead",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Overhead",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Product Costs",
        field: "DirectProductCosts",
        tooltipField: "Direct Product Costs",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Direct Product Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Service Costs",
        field: "DirectServiceCosts",
        tooltipField: "DirectServiceCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "DirectServiceCosts",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Special Direct Costs",
        field: "SpecialDirectCost",
        tooltipField: "Special Direct Costs",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Special Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Local Costs",
        field: "LocalCosts",
        tooltipField: "Local Costs",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Local Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Special Costs",
        field: "TotalSpecialCosts",
        tooltipField: "Total Special Costs",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Special Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Direct Costs",
        field: "TotalDirectCosts",
        tooltipField: "Total Direct Costs",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.Type === 'Total Cost') {
            //conse.log('HI');
            let total = 0;
            if (params.api != null) {
              //conse.log(params.api)
              params.api.forEachNode((node) => {
                total += Number(node.data.TotalDirectCosts)
                console.log(total)
              })
              params.data.TotalDirectCosts = total;
              return total;
            }
          }
          else if (params.node.data.Type === 'Change to Reference') {
            let referenceTotal = 0;
            params.api.forEachNode((node) => {
              referenceTotal = Number(node.data.referenceTotal)
              //console.log(referenceTotal)
            })
            let targetTotal = 0;
            params.api.forEachNode((node) => {
              targetTotal += Number(node.data.TotalDirectCosts)
              //conse.log(targetTotal)
            })
            params.node.data.TotalDirectCosts = referenceTotal - targetTotal

            //conse.log(params.node.data.TotalDirectCosts);
            //params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? null : params.node.data.DeltaTCVsRef
            return params.node.data.TotalDirectCosts;
          }
          else {
            return (params.data.TotalDirectCosts);
          }
        }
      },
    ];
    this.tarDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    //this.tarGridData = [];
    this.domLayout = "autoHeight";
    this.showGrid = true;
  }

  onTarGridReady(params: any) {
    this.tarGridApi = params.api;
    this.tarGridApi.setGroupHeaderHeight(20);
    this.tarPinnedBottomData = createDataTar();
    //conse.log(this.tarPinnedBottomData);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);
  }
  totalTar() {
    let Costs = 0
    this.tarGridApi.forEachNode(function (node) {
      //conse.log(node.data);
      Costs = Number(node.data.TotalDirectCosts) + Costs
    })
    //conse.log(Costs);
    return Costs;
  }
  changeRefenceTar() {
    let tarChangeRefrence = 0;
    tarChangeRefrence = this.refTotalDirectCosts - this.tarTotalDirectCosts;
    return tarChangeRefrence
  }


  onTarRefChanged(params) {
    setTimeout(() => {
      this.tarGridApi = params.api;
      this.tarGridApi.sizeColumnsToFit();
    }, 1000);

  }
  onGridTarChanged(params) {
    setTimeout(() => {
      this.tarGridApi = params.api;
      this.tarGridApi.sizeColumnsToFit();
    }, 1000);

  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedTar(params) {
    params.api.resetRowHeights();
  }

  onColumnVisibleTar(params) {
    params.api.resetRowHeights();
  }

  loadOptAGridStructure() {
    this.rowClassRulesOptA = {
      'colorTotal': function (params) {
        let data = params.data.Type;
        return data === 'Total Cost' || data === 'Change to Reference';
      }
    }
    this.optAColumnDefs = [
      {
        headerName: "Type",
        field: "Type",
        tooltipField: "Type",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Type",
        editable: false
      },
      {
        headerName: "Component",
        field: "Assembly",
        tooltipField: "Assembly",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Component",
        editable: false
      },
      {
        headerName: "Material",
        field: "Material",
        tooltipField: "Material",
        width: 100,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Material",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Labour",
        field: "Labor",
        tooltipField: "Labour",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Labour",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Overhead",
        field: "Overhead",
        tooltipField: "Overhead",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Overhead",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Product Costs",
        field: "DirectProductCosts",
        tooltipField: "DirectProductCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Direct Product Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Service Costs",
        field: "DirectServiceCosts",
        tooltipField: "DirectServiceCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "DirectServiceCosts",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Special Direct Costs",
        field: "SpecialDirectCost",
        tooltipField: "Special Direct Costs",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Special Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Local Costs",
        field: "LocalCosts",
        tooltipField: "LocalCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Local Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Special Costs",
        field: "TotalSpecialCosts",
        tooltipField: "TotalSpecialCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Special Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Direct Costs",
        field: "TotalDirectCosts",
        tooltipField: "TotalDirectCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.Type === 'Total Cost') {
            //conse.log('HI');
            let total = 0;
            if (params.api != null) {
              //conse.log(params.api)
              params.api.forEachNode((node) => {
                total += Number(node.data.TotalDirectCosts)
                //conse.log(total)
              })
              params.data.TotalDirectCosts = total;
              return total;
            }
          }
          else if (params.node.data.Type === 'Change to Reference') {
            let referenceTotal = 0;
            console.log('12');
            params.api.forEachNode((node) => {
              referenceTotal = Number(node.data.referenceTotal)
             console.log(referenceTotal)
           console.log('2');
            })
            let optATotal = 0;
            params.api.forEachNode((node) => {
              optATotal += Number(node.data.TotalDirectCosts)
              console.log(optATotal)
            })
            params.node.data.TotalDirectCosts = referenceTotal - optATotal

            //conse.log(params.node.data.TotalDirectCosts);
            //params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? null : params.node.data.DeltaTCVsRef
            return params.node.data.TotalDirectCosts;
          }
          else {
            return (params.data.TotalDirectCosts);
          }
        }
      },
    ];
    this.optADefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.OptADomLayout = "autoHeight";
    this.showGrid = true;
  }

  onOptAGridReady(params: any) {
    console.log('1');
    this.optAGridApi = params.api;
    this.optAGridApi.setGroupHeaderHeight(20);
    this.optAPinnedBottomData = createDataOptA();
    //conse.log(this.optAPinnedBottomData);
    params.api.setPinnedBottomRowData(this.optAPinnedBottomData);
  }
  totalOptA() {
    let costs = 0
    this.optAGridApi.forEachNode(function (node) {
      costs = Number(node.data.TotalDirectCosts) + costs
    })
    this.optATotalDirectCosts = costs;
    return costs;
  }
  changeRefenceOptA() {
    //conse.log(this.optATotalDirectCosts);
    let optAChangeRefrence = 0;
    optAChangeRefrence = this.refTotalDirectCosts - this.optATotalDirectCosts;
    return optAChangeRefrence
  }

  onGridOptACSChanged(params) {
    setTimeout(() => {
      this.optAGridApi = params.api;
      this.optAGridApi.sizeColumnsToFit();
    }, 1000);

  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptA(params) {
    params.api.resetRowHeights();
  }

  onColumnVisibleOptA(params) {
    params.api.resetRowHeights();
  }

  loadOptBGridStructure() {
    this.rowClassRulesOptB = {
      'colorTotal': function (params) {
        let data = params.data.Type;
        return data === 'Total Cost' || data === 'Change to Reference';
      }
    }
    this.optBColumnDefs = [
      {
        headerName: "Type",
        field: "Type",
        tooltipField: "Type",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Type",
        editable: false
      },
      {
        headerName: "Component",
        field: "Assembly",
        tooltipField: "Assembly",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Component",
        editable: false
      },
      {
        headerName: "Material",
        field: "Material",
        tooltipField: "Material",
        width: 100,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Material",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Labour",
        field: "Labor",
        tooltipField: "Labour",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Labour",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Overhead",
        field: "Overhead",
        tooltipField: "Overhead",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Overhead",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Product Costs",
        field: "DirectProductCosts",
        tooltipField: "DirectProductCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Direct Product Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Service Costs",
        field: "DirectServiceCosts",
        tooltipField: "DirectServiceCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "DirectServiceCosts",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Special Direct Costs",
        field: "SpecialDirectCost",
        tooltipField: "Special Direct Costs",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Special Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Local Costs",
        field: "LocalCosts",
        tooltipField: "LocalCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Local Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Special Costs",
        field: "TotalSpecialCosts",
        tooltipField: "TotalSpecialCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Special Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Direct Costs",
        field: "TotalDirectCosts",
        tooltipField: "TotalDirectCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.Type === 'Total Cost') {
            //conse.log('HI');
            let total = 0;
            if (params.api != null) {
              //conse.log(params.api)
              params.api.forEachNode((node) => {
                total += Number(node.data.TotalDirectCosts)
                //conse.log(total)
              })
              params.data.TotalDirectCosts = total;
              return total;
            }
          }
          else if (params.node.data.Type === 'Change to Reference') {
            let referenceTotal = 0;
            params.api.forEachNode((node) => {
              referenceTotal = Number(node.data.referenceTotal)
              //conse.log(referenceTotal)
            })
            let targetTotal = 0;
            params.api.forEachNode((node) => {
              targetTotal += Number(node.data.TotalDirectCosts)
              //conse.log(targetTotal)
            })
            params.node.data.TotalDirectCosts = referenceTotal - targetTotal

            //conse.log(params.node.data.TotalDirectCosts);
            //params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? null : params.node.data.DeltaTCVsRef
            return params.node.data.TotalDirectCosts;
          }
          else {
            return (params.data.TotalDirectCosts);
          }
        }
      },
    ];
    this.optBDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.OptBDomLayout = "autoHeight";
    this.showGrid = true;
  }

  onOptBGridReady(params: any) {
    this.optBGridApi = params.api;
    this.optBGridApi.setGroupHeaderHeight(20);
    this.optBPinnedBottomData = createDataOptB();
    params.api.setPinnedBottomRowData(this.optBPinnedBottomData);
  }
  totalOptB() {
    let costs = 0
    this.optBGridApi.forEachNode(function (node) {
      costs = Number(node.data.TotalDirectCosts) + costs
    })
    this.optBTotalDirectCosts = costs;
    return this.optBTotalDirectCosts;
  }
  changeRefenceOptB() {
    let optBChangeRefrence = 0;
    optBChangeRefrence = this.refTotalDirectCosts - this.optBTotalDirectCosts;
    return optBChangeRefrence
  }
  onGridOptBCSChanged(params) {
    setTimeout(() => {
      this.optBGridApi = params.api;
      this.optBGridApi.sizeColumnsToFit();
    }, 1000);

  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptB(params) {
    params.api.resetRowHeights();
  }

  onColumnVisibleOptB(params) {
    params.api.resetRowHeights();
  }

  loadOptCGridStructure() {
    this.rowClassRulesOptC = {
      'colorTotal': function (params) {
        let data = params.data.Type;
        return data === 'Total Cost' || data === 'Change to Reference';
      }
    }
    this.optCColumnDefs = [
      {
        headerName: "Type",
        field: "Type",
        tooltipField: "Type",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Type",
        editable: false
      },
      {
        headerName: "Component",
        field: "Assembly",
        tooltipField: "Assembly",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Component",
        editable: false
      },
      {
        headerName: "Material",
        field: "Material",
        tooltipField: "Material",
        width: 100,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Material",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Labour",
        field: "Labor",
        tooltipField: "Labour",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Labour",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Overhead",
        field: "Overhead",
        tooltipField: "Overhead",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Overhead",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Product Costs",
        field: "DirectProductCosts",
        tooltipField: "DirectProductCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Direct Product Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Direct Service Costs",
        field: "DirectServiceCosts",
        tooltipField: "DirectServiceCosts",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "DirectServiceCosts",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Special Direct Costs",
        field: "SpecialDirectCost",
        tooltipField: "Special Direct Costs",
        width: 60,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Special Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Local Costs",
        field: "LocalCosts",
        tooltipField: "LocalCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Local Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Special Costs",
        field: "TotalSpecialCosts",
        tooltipField: "TotalSpecialCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Special Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,

      },
      {
        headerName: "Total Direct Costs",
        field: "TotalDirectCosts",
        tooltipField: "TotalDirectCosts",
        width: 150,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Total Direct Costs",
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.Type === 'Total Cost') {
            //conse.log('HI');
            let total = 0;
            if (params.api != null) {
              //conse.log(params.api)
              params.api.forEachNode((node) => {
                total += Number(node.data.TotalDirectCosts)
                //conse.log(total)
              })
              params.data.TotalDirectCosts = total;
              return total;
            }
          }
          else if (params.node.data.Type === 'Change to Reference') {
            let referenceTotal = 0;
            params.api.forEachNode((node) => {
              referenceTotal = Number(node.data.referenceTotal)
              //conse.log(referenceTotal)
            })
            let targetTotal = 0;
            params.api.forEachNode((node) => {
              targetTotal += Number(node.data.TotalDirectCosts)
              //conse.log(targetTotal)
            })
            params.node.data.TotalDirectCosts = referenceTotal - targetTotal

            //conse.log(params.node.data.TotalDirectCosts);
            //params.node.data.DeltaTCVsRef = (params.node.data.DeltaTCVsRef == 0) ? null : params.node.data.DeltaTCVsRef
            return params.node.data.TotalDirectCosts;
          }
          else {
            return (params.data.TotalDirectCosts);
          }
        }
      },
    ];
    this.optCDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.OptCDomLayout = "autoHeight";
    this.showGrid = true;
  }

  onOptCGridReady(params: any) {
    this.optCGridApi = params.api;
    this.optCGridApi.setGroupHeaderHeight(20);
    this.optCPinnedBottomData = createDataOptC();
    params.api.setPinnedBottomRowData(this.optCPinnedBottomData);
  }
  totalOptC() {
    let costs = 0
    this.optCGridApi.forEachNode(function (node) {
      costs = Number(node.data.TotalDirectCosts) + costs
    })
    this.optCTotalDirectCosts = costs;
    return costs;
  }
  changeRefenceOptC() {
    let optCChangeRefrence = 0;
    optCChangeRefrence = this.refTotalDirectCosts - this.optCTotalDirectCosts;
    return optCChangeRefrence
  }

  onGridOptCCSChanged(params) {
    setTimeout(() => {
      this.optCGridApi = params.api;
      this.optCGridApi.sizeColumnsToFit();
    }, 1000);

  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptC(params) {
    params.api.resetRowHeights();
  }

  onColumnVisibleOptC(params) {
    params.api.resetRowHeights();
  }
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(["/tc-manageProject"]);
  }

  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }

  onClickNext() {
    this.menuService.onClickNextAndBack("next");
  }
  submit(event?: any) {
    this.router.navigate(['/tc-manageProject']);
  }
  ngOnDestroy(): void {
    this.configSub.unsubscribe();
  }
}
// creates row for "Total Direc Costs" for reference
function createDataRef() {
  //conse.log(totalCost);
  let result = [];
  result.push({
    Type: "Total Cost",
    TotalDirectCosts: null
  });
  return result;
};
// creates row for "Total Direc Costs" for target
function createDataTar() {
  let result = [];
  result.push({
    Type: "Total Cost",
    TotalDirectCosts: null
  });
  result.push({
    Type: "Change to Reference",
    TotalDirectCosts: null,
  });
  return result;
};
// creates row for "Total Direc Costs" for OptionA
function createDataOptA() {
 //console.log( this.refGridApi.data)
  let result = [];
  result.push({
    Type: "Total Cost",
    TotalDirectCosts: null
  });
  result.push({
    Type: "Change to Reference",
    TotalDirectCosts: null,
  });
  return result;
};
// creates row for "Total Direc Costs" for OptionB
function createDataOptB() {
  let result = [];
  result.push({
    Type: "Total Cost",
    TotalDirectCosts: null
  });
  result.push({
    Type: "Change to Reference",
    TotalDirectCosts: null
  });
  return result;
};
// creates row for "Total Direc Costs" for OptionC
function createDataOptC() {
  let result = [];
  result.push({
    Type: "Total Cost",
    TotalDirectCosts: null
  });
  result.push({
    Type: "Change to Reference",
    TotalDirectCosts: null
  });
  return result;
};
// used to format the cell in germman currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(0)));
}