import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { IdeasearchService } from '../../../services/ideasearch.service';
import { MatDialog } from '@angular/material/dialog'
import { DialogComponent } from '../../../dialog/dialog.component';
import { AlertDialogComponent } from '../../../alert-dialog/alert-dialog.component';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { serverMessage } from '../../../popUpMessages/serverMessage';
import { ToasterService } from 'src/app/services/toaster.service';
import { messages } from 'src/app/popUpMessages/messages';
import { UserInfoService } from 'src/app/services/user-info.service';
import { ProjectsearchService } from 'src/app/services/projectsearch.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-edit-delete',
  templateUrl: './edit-delete.component.html'
  // styleUrls: ['./edit-delete.component.css']
})
export class EditDeleteComponent implements ICellRendererAngularComp {
  public passedData: any;
  public gridApi: any;
  public params: any;
  public objectOfGrid: any;
  public incomingValue: any;
  public statuscode: any;
  public errorMessage = [];
  public user: any;
  public isDelVisible: boolean = false;
  public isCopyVisible: boolean = false;
  public isEditVisible: boolean = false;
  public response: any;
  public copiedData: any;
  public copiedTCData: any;
  public copiedConfigurationData: any;
  projectId: any;
  constructor(private router: Router,
    private toast: ToasterService,
    private gridS: IdeasearchService,
    private dialog: MatDialog,
    private sharedService: SharedServiceService,
    private userService: UserInfoService,
    private projectSearchS: ProjectsearchService,
    private configurationService: ConfigurationService,
    private localStorageService: LocalStorageService) { }


  refresh(): boolean {
    return false;
  };

  // get called on load of the component;
  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    this.sharedService.loggedInUser.subscribe(user => {
      this.user = user;
      if (this.user) {
        if (this.params.data.RoleId == 1 || this.params.data.RoleId == 2) {
          this.isDelVisible = true;
          this.isCopyVisible = true;
        }
        else if (this.params.data.RoleId == 3) {
          let currentUser = this.user.FullName + " [" + this.user.OrgCode + "]";

          if (params.data.Submitter == currentUser || params.data.ProjectManager == currentUser || params.data.ProductManager == currentUser
            || params.data.FinancialController == currentUser || params.data.ServiceManager == currentUser || params.data.ProcurementEngineer == currentUser
          ) {
            this.isDelVisible = true;
            this.isCopyVisible = true;
          }
          else {
            this.isDelVisible = false;
            this.isCopyVisible = false;
          }
        }

        if (this.params.data.hasOwnProperty("Id")) {
          if (this.params.data.Id == 0) {
            this.isEditVisible = false;
            this.isCopyVisible = false;
            this.isDelVisible = true;
          } else {
            if (this.params.data.hasOwnProperty("IsEditable") && this.params.data.hasOwnProperty("IsLock") && this.params.data.hasOwnProperty("IsAdmin")) {
              this.isEditVisible = this.isCopyVisible = this.isDelVisible = (this.params.data.IsAdmin) || (this.params.data.IsEditable && !this.params.data.IsLock);
            }
          }
        }
      }
    })
  }

  // this will move you to the ideaPage for edit;
  redirect(params) {
    this.passedData = params.data.IdeaId;
    this.router.navigate(["/idea", this.passedData]);
  }

  //get called if edit icon is clicked
  editData(params) {
    console.log("edit clicked")
    if (params.data.Id != undefined) {
      let configuration = {
        configurationId: params.data.Id,
        selectedKmat: 0,
        addedVblo: null,
        removedVblo: null
      }
      this.sharedService.changeConfigId(0);
      this.localStorageService.set("ConfigId", params.data.Id);
      this.localStorageService.set("configuration", configuration);
      this.router.navigate(['/tc-configuration-vblo'])
    }
  }

  // get called on delete icon is clicked;
  delData(params) {
    if (params.data.IdeaId != undefined) {
      let error = messages.deleteMessage;
      // opens the dialog box for confirmation with two button Yes and No
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridS.deldata({ 'IdeaId': params.data.IdeaId, 'UserId': params.data.UserId, 'BusinessAreaId': params.data.BusinessAreaId, 'BusinessLineId': params.data.BusinessLineId }).subscribe((data) => {

            this.response = data;
            let successCode = this.response.StatusCode;
            let errorCode = this.response.Data.ErrorCode;
            let errorMsgeFromBack = this.response.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                this.gridApi.applyTransaction({ remove: [params.data] });
                this.toast.notify(serverMessage.ideaSearch.dataDeleted, "success");
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
              }
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          },
            (error) => {
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            })
        }
      })
    }
    else if (params.data.ProjectId != undefined) {
      let error = messages.deleteMessage;
      // opens the dialog box for confirmation with two button Yes and No
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.projectSearchS.deleteProject({ 'ProjectId': params.data.ProjectId, 'UserId': params.data.UserId, 'BusinessAreaId': params.data.BusinessAreaId, 'BusinessLineId': params.data.BusinessLineId }).subscribe((data) => {
            this.response = data;
            let successCode = this.response.StatusCode;
            let errorCode = this.response.Data.ErrorCode;
            let errorMsgeFromBack = this.response.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                this.gridApi.applyTransaction({ remove: [params.data] });
                this.toast.notify(serverMessage.projectSearch.dataDeleted, "success");
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
              }
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          },
            (error) => {
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            }
          )
        }
      })
    }
    //Delete configuration
    else if (params.data.Id != undefined) {
      let error = messages.deleteMessage;
      // opens the dialog box for confirmation with two button Yes and No
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (params.data.Id == 0) {
            this.gridApi.applyTransaction({ remove: [params.data] });
            this.localStorageService.remove("configuration");
          } else {
            this.configurationService.deleteConfiguration({ 'ConfigurationId': params.data.Id, 'UserId': this.user.UserId }).subscribe((data) => {
              this.response = data;
              let successCode = this.response.StatusCode;
              let errorCode = this.response.Data.ErrorCode;
              let errorMsgeFromBack = this.response.Data.Message;
              if (successCode == 200) {
                if (errorCode == 0) {
                  this.gridApi.applyTransaction({ remove: [params.data] });
                  this.toast.notify(serverMessage.projectSearch.dataDeleted, "success");
                  this.sharedService.changeConfigId(0);
                }
                else {
                  //Show errorCode and errorMessage in the UI
                  let errorContainer = [
                    { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                  ]
                  this.openAlertDialog(errorContainer)
                }
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
              }
            },
              (error) => {
                this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
              }
            )
          }
        }
      })
    }
  }

  // get called on copy icon is clicked;
  copyData(params) {
    if (params.data.IdeaId != undefined) {
      let error = messages.copyMessage + params.data.IdeaId + '?';
      // opens the dialog box for confirmation with two button Yes and No
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridS.copydata({ 'IdeaId': params.data.IdeaId, 'UserId': params.data.UserId, 'BusinessAreaId': params.data.BusinessAreaId, 'BusinessLineId': params.data.BusinessLineId }).subscribe((data) => {
            this.response = data;
            let successCode = this.response.StatusCode;
            let errorCode = this.response.Data.ErrorCode;
            let errorMsgeFromBack = this.response.Data.Message;
            let result = [];
            if (successCode == 200) {
              if (errorCode == 0) {
                this.router.navigateByUrl('/manageIdea');
                this.gridS.getIdea(this.user.UserId).subscribe((data: any) => {

                  if (data.StatusCode == 200) {
                    //result = data.Data;
                    //const sorted = result.sort((a, b) => (a.IdeaId > b.IdeaId ? -1 : 1));
                    const sorted = data.Data.toSorted((a, b) => (a.IdeaId > b.IdeaId ? -1 : 1));
                    this.copiedData = sorted;
                    this.gridApi.applyTransaction({
                      add: [{
                        IdeaId: this.copiedData[0].IdeaId,
                        Title: this.copiedData[0].Title,
                        Submitter: this.user.FullName + " [" + this.user.OrgCode + "]",
                        Responsible: this.user.FullName + " [" + this.user.OrgCode + "]",
                        Productivity: params.data.Productivity,
                        MeasureStatusTitle: this.copiedData[0].MeasureStatusTitle,
                        StartDate: params.data.StartDate,
                        BusinessAreaId: params.data.BusinessAreaId,
                        BusinessAreaName: params.data.BusinessAreaName,
                        BusinessLineId: params.data.BusinessLineId,
                        BusinessLineName: params.data.BusinessLineName,
                        RoleId: params.data.RoleId,
                        UserId: params.data.UserId
                      }], addIndex: 0
                    })
                  }
                })
                this.toast.notify(serverMessage.ideaSearch.dataCopied, "success");
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
              }
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          },
            (error) => {
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            })
        }
      })
    }
    else if (params.data.ProjectId != undefined) {
      let error = messages.copyTCMessage + params.data.ProjectId + '?';
      // opens the dialog box for confirmation with two button Yes and No
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.projectSearchS.copyProject({ 'ProjectId': params.data.ProjectId, 'UserId': params.data.UserId, 'BusinessAreaId': params.data.BusinessAreaId, 'BusinessLineId': params.data.BusinessLineId }).subscribe((data) => {
            this.response = data;
            let successCode = this.response.StatusCode;
            let errorCode = this.response.Data.ErrorCode;
            let errorMsgeFromBack = this.response.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                this.router.navigateByUrl('/tc-manageProject');
                this.projectSearchS.getAllProjects(this.user.UserId).subscribe((data: any) => {
                  if (data.StatusCode == 200) {
                    const sorted = data.Data.toSorted((a, b) => (a.ProjectId > b.ProjectId ? -1 : 1));
                    this.copiedTCData = sorted;
                    this.gridApi.applyTransaction({
                      add: [{
                        ProjectId: this.copiedTCData[0].ProjectId,
                        ProjectTitle: this.copiedTCData[0].ProjectTitle,
                        Submitter: this.user.FullName + " [" + this.user.OrgCode + "]",
                        CreatedDate: new Date().toISOString(),
                        ProductManager: this.copiedTCData[0].ProductManager,
                        ProjectManager: this.copiedTCData[0].ProjectManager,
                        FinancialController: this.copiedTCData[0].FinancialController,
                        ServiceManager: this.copiedTCData[0].ServiceManager,
                        ProcurementEngineer: this.copiedTCData[0].ProcurementEngineer,
                        MilestoneName: this.copiedTCData[0].MilestoneName,
                        MeasureStatusTitle: this.copiedTCData[0].MeasureStatusTitle,
                        RoleId: params.data.RoleId,
                        // UserId: params.data.UserId
                      }], addIndex: 0
                    })
                  }
                })
                this.toast.notify(serverMessage.ideaSearch.dataCopied, "success");
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
              }
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          },
            (error) => {
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            })
        }
      })
    }
    else if (params.data.Id != undefined) {
      let error = messages.copyTcConfiguration + params.data.Id + '?';
      // opens the dialog box for confirmation with two button Yes and No
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.configurationService.copyConfiguration({ 'ConfigurationId': params.data.Id, 'UserId': this.user.UserId }).subscribe((data) => {
            this.response = data;
            let successCode = this.response.StatusCode;
            let errorCode = this.response.Data.ErrorCode;
            let errorMsgeFromBack = this.response.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                console.log(data)
                this.sharedService.changeConfigId(0);
        
                this.projectId = this.localStorageService.get("projectId");
                this.configurationService.getConfigurationData(this.projectId).subscribe((data: any) => {
                  if (data.StatusCode == 200) {
                    const sorted = data.Data.toSorted((a, b) => (a.Id > b.Id ? -1 : 1));
                    this.copiedConfigurationData = sorted;
                    this.gridApi.applyTransaction({
                      add: [{
                        Id: this.copiedConfigurationData[0].Id,
                        Name: this.copiedConfigurationData[0].Name,
                        eq: null,
                        cs: null,
                        scm: null,
                        lc: null,
                        inv: null,
                        tdc: null,
                        plc: null,
                        IsFloor: this.copiedConfigurationData[0].IsFloor,
                        IsCeiling: this.copiedConfigurationData[0].IsCeiling,
                        IsBiplane: this.copiedConfigurationData[0].IsBiplane,
                        IsMP: this.copiedConfigurationData[0].IsMP,
                        IsPheno: this.copiedConfigurationData[0].IsPheno,
                        IsFD: this.copiedConfigurationData[0].IsFD,
                        IsII: this.copiedConfigurationData[0].IsII,
                        CreatedUserName: this.copiedConfigurationData[0].CreatedUserName,
                        CreatedOn: this.copiedConfigurationData[0].CreatedOn,
                        ModifiedUserName: null,
                        ModifiedOn: null,
                        MileStoneName: this.copiedConfigurationData[0].MilestoneName,
                        IsEditable: this.isCopyVisible
                      }], addIndex: 0
                    })
                  }
                })
                this.toast.notify(serverMessage.projectSearch.dataCopied, "success");
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
              }
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          },
            (error) => {
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            }
          )
        }
      });
    }
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
}


