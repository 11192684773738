<div class="container">
  <div class="row">
    <div class="col-md-11 col-lg-11">
      <ul class="nav nav-tabs" #myTab>
          <!-- <li class="nav-item">
          <a
          id="helpTab"
            #helpTab
            class="nav-link active"
            (click)="openAdminPage('help')"
            role="tab"
            data-toggle="tab"
            >Help</a
          >
        </li>   -->
        <li class="nav-item">
          <a
          id="userManagementTab"
            #userManagementTab
            class="nav-link active "
            (click)="openAdminPage('userManagement')"
            role="tab"
            data-toggle="tab"
            >User Management</a
          >
        </li>
        <li class="nav-item">
          <a
          id="userReplacementTab"
            #userReplacementTab
            class="nav-link"
            (click)="openAdminPage('userReplacement')"
            role="tab"
            data-toggle="tab"
            >User Removal</a
          >
        </li>
        <!-- <li class="nav-item">
          <a
          id="masterDataTab"
            #masterDataTab
            class="nav-link"
            (click)="openAdminPage('masterData')"
            role="tab"
            data-toggle="tab"
            >Master Data</a
          >
        </li> -->
        <!-- <li class="nav-item">
          <a
          id="productManagementTab"
            #productManagementTab
            class="nav-link"
            (click)="openAdminPage('productManagement')"
            role="button"
            data-toggle="tab"
            >Product Management</a
          >
        </li> -->
        <!-- <li class="nav-item">
          <a
          id="actualsTab"
            #actualsTab
            class="nav-link"
            (click)="openAdminPage('actuals')"
            role="button"
            data-toggle="tab"
            >Actual Units</a
          >
        </li> -->
        <!-- MDUnit is changed to PlanUnit -->
        <!-- <li class="nav-item">
          <a
          id="mdUnitsTab"
            #mdUnitsTab
            class="nav-link"
            (click)="openAdminPage('mdUnits')"
            role="tab"
            data-toggle="tab"
            >Plan Units</a
          >
        </li> -->
        <li class="nav-item">
          <a
          id="periodLockTab"
            #periodLockTab
            class="nav-link"
            (click)="openAdminPage('periodLock')"
            role="button"
            data-toggle="tab"
            >Period Lock</a
          >
        </li>
        
        <li class="nav-item">
          <a
          id="fxtranslationTab"
            #fxtranslationTab
            class="nav-link"
            (click)="openAdminPage('fxtranslation')"
            role="button"
            data-toggle="tab"
            >FX Translation</a
          >
        </li> 
        <!-- <li class="nav-item">
          <a
          id="fxtranslationTab"
            #fxtranslationTab
            class="nav-link"
            (click)="openAdminPage('fxtranslation')"
            role="button"
            data-toggle="tab"
            >Material Master</a
          >
        </li> -->
        <!-- <li class="nav-item">
          <a
          id="fxtranslationTab"
            #fxtranslationTab
            class="nav-link"
            (click)="openAdminPage('fxtranslation')"
            role="button"
            data-toggle="tab"
            >Target Costing:Help</a
          >
        </li> -->
        <li class="nav-item dropdown" *ngIf="isProductivityVisible">
          <a class="nav-link  dropdown-toggle" href="#" role="button"  id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Productivity
          </a>        
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item"
            id="helpTab"
              #helpTab
              (click)="openAdminPage('help')"
              role="tab"
              data-toggle="tab"
              >Help</a
            >            
            <a class="dropdown-item"
            id="masterDataTab"
              #masterDataTab
              class="dropdown-item"
              (click)="openAdminPage('masterData')"
              role="tab"
              data-toggle="tab"
              >Master Data</a
            >
            <a class="dropdown-item"
            id="productManagementTab"
              #productManagementTab
            
              (click)="openAdminPage('productManagement')"
              role="button"
              data-toggle="tab"
              >Product Management</a
            >
            <a
            id="actualsTab"
              #actualsTab
              class="dropdown-item"
              (click)="openAdminPage('actuals')"
              role="button"
              data-toggle="tab"
              >Actual Units</a
            >
            
            <a
            id="mdUnitsTab"
              #mdUnitsTab
              class="dropdown-item"
              (click)="openAdminPage('mdUnits')"
              role="tab"
              data-toggle="tab"
              >Plan Units</a
            >
           
          </ul>
        </li>
     
        <li class="nav-item dropdown" *ngIf="isTargetCostingVisible">
          <a class="nav-link  dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Target Costing
          </a>        
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item"
            id="helptargetcostingTab"
              #helptargetcostingTab
              (click)="openAdminPage('helptargetcosting')"
              role="tab"
              data-toggle="tab"
              >Help Costing</a
            >            
            <a class="dropdown-item"
            id="materialMasterTab"
              #materialMasterTab
              class="dropdown-item"
              (click)="openAdminPage('materialMaster')"
              role="tab"
              data-toggle="tab"
              >Material Master</a
            >
            <a class="dropdown-item"
            id="overheadCostTab"
              #overheadCostTab
              class="dropdown-item"
              (click)="openAdminPage('overheadCost')"
              role="tab"
              data-toggle="tab"
              >Overhead Cost</a
            >
           
          </ul>
        </li>
       
      </ul>
    </div>

    <div class="col-md-1 col-lg-1">
      <button
        (click)="goToHomePage()"
        title="Home"
        style="position: relative; margin-top: 5px; margin-left: 21px"
      >
        <img
          alt="homeIcon"
          src="../assets/icons/home.svg"
          height="50"
          width="50"
        />
        <figcaption style="padding: 0px; text-align: center; font-weight: bold">
          Home
        </figcaption>
      </button>
    </div>
  </div>
</div>
<div class="container" style="margin-top: 10px">
   <!-- <app-help-edit
    *ngIf="help"
    (unSavedChanges)="dataFromChild($event, 'help')"
  ></app-help-edit> -->
  
    <app-user-management
    *ngIf="userManagement"
      (unSavedChanges)="dataFromChild($event, 'userManagement')"
    ></app-user-management>
   
  <div *ngIf="help">
  <app-help-edit
    
    (unSavedChanges)="dataFromChild($event, 'help')"
  ></app-help-edit>
</div>
  <div *ngIf="masterData">
    <app-master-data
      (unSavedChanges)="dataFromChild($event, 'masterData')"
    ></app-master-data>
  </div>
  <div *ngIf="mdUnits">
    <app-md-units
      (unSavedChanges)="dataFromChild($event, 'mdUnits')"
    ></app-md-units>
  </div>
  <div *ngIf="productManagement">
    <app-product-management
      (unSavedChanges)="dataFromChild($event, 'productManagement')"
    ></app-product-management>
  </div>
  <div *ngIf="userReplacement">
    <app-user-replacement
      (unSavedChanges)="dataFromChild($event, 'userReplacement')"
    ></app-user-replacement>
  </div>
  <div *ngIf="actuals">
    <app-actual-unit
      (unSavedChanges)="dataFromChild($event, 'actuals')"
    ></app-actual-unit>
  </div>
  <div *ngIf="periodLock">
    <app-period-lock
      (unSavedChanges)="dataFromChild($event, 'periodLock')"
    ></app-period-lock>
  </div>
  <div *ngIf="fxtranslation">
    <app-fxtranslation
      (unSavedChanges)="dataFromChild($event, 'fxtranslation')"
    ></app-fxtranslation>
  </div>
  <div *ngIf="helptargetcosting">
    <app-help-edit-target-costing
      (unSavedChanges)="dataFromChild($event, 'helptargetcosting')"
    ></app-help-edit-target-costing>
  </div>
  <div *ngIf="materialMaster">
    <app-material-master
      (unSavedChanges)="dataFromChild($event, 'materialMaster')"
    ></app-material-master>
  </div>
  <div *ngIf="overheadCost">
    <app-overhead
      (unSavedChanges)="dataFromChild($event, 'overheadCost')"
    ></app-overhead>
  </div>
</div>
