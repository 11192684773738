import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GridApi, ExcelExportParams, GridOptions } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import * as XLSX from 'xlsx';
import { MasterMaterialData } from 'src/app/models/master-material-data';
import { MaterialMasterService } from 'src/app/services/material-master.service';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { DatePipe } from '@angular/common';
import { GridDatepickerComponent } from '../../grid-datepicker/grid-datepicker.component';
import { MasterMaterialDetail } from 'src/app/models/master-material-detail';

let countryCode;
@Component({
  selector: 'app-material-master',
  templateUrl: './material-master.component.html',
  styleUrls: ['./material-master.component.css']
})
export class MaterialMasterComponent implements OnInit {

  public rowData: any = [];
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi!: GridApi;
  public gridColumnApi: any;
  public domLayout: any;
  public rowHeight: any;
  public headerHeight: any;
  public Editable: boolean = true;
  public modifiedMaterialMasterRows = [];
  public alertErrorBox = [];
  public errorMessage = []
  public roleMasterData = [];
  public loggedInUser: any;
  public getResponse: any;
  public frameworkComponents: any;

  public sizeToFitRefresh: number = 0;
  public showGrid = false;
  public importing = false;

  submitResponse: any;
  importExcelData: any;
  receivedData: any;
  totalDataCount: any;
  hideExportImport: boolean = true;
  isImport: boolean = false;

  s1: any = ["Material Master"];

  // changes;
  public adminSubscription: any;
  @Output() unSavedChanges = new EventEmitter<any>();
  // end;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;

  selectBusinessArea = "";
  selectBusinessLine = "";
  progress: any;

  constructor(
    private router: Router,
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private toast: ToasterService,
    private materialMasterService: MaterialMasterService,
    private datePipe: DatePipe
  ) {
    this.frameworkComponents = { datePicker: GridDatepickerComponent };
  }

  ngOnInit() {
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user;

        //getting business area and line access of user
        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        //removing duplicates in the list
        this.roleBusinessArea = this.roleBusinessArea.filter((v, i, a) => a.findIndex(v2 => (v2.BusinessAreaId === v.BusinessAreaId)) === i);

        //If the user has only one access, auto populate the dropdown
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.disableBusinessLineDropdown = true;
          this.hideExportImport = false;
        }
        this.showGrid = true;
      }
    );

    countryCode = this.localStorageService.get('countryCode');

    this.bindMaterialMasterData();
  }

  updateBusinessLine() {
    this.hideExportImport = true;
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
    this.bindMaterialMasterData();
  }

  updateMaterialMaster() {
    if (this.selectBusinessLine == "") {
      this.hideExportImport = true;
    }
    else {
      this.hideExportImport = false;
    }
    this.bindMaterialMasterData();
  }


  exportGridData() {
    this.showGrid = false;
    let i = 0;
    this.gridApi.exportDataAsExcel({
      columnKeys: ['MaterialAssembly', 'Kmat', 'KmatText','KMATPlant','KMATStatus','Vblo', 'VbloText','VbloPlant','VbloStatus','IsKMATVBLOActive', 'Material', 'MaterialDesc', 'MaterialComponent', 'MaterialComponentDescription'
        ,'IsVBLOMaterialActive', 'MaterialSupplierNo', 'MaterialSupplier', 'MaterialSupplierCountry', 'MaterialProcurementType', 'MaterialPlant','MaterialStatus', 'KMATCurrency', 'MaterialGMKGroup', 'VbloType', 'MaterialWeight'
        , 'MaterialBkValue', 'MaterialQuantity', 'MaterialIdentifier', 'MaterialDate', 'MaterialMr', 'VbloAmount', 'VbloPrice', 'VbloDate', 'VbloKreditor'],
      processHeaderCallback: (params) => {
        const customHeaders: { [key: string]: string } = {
          MaterialAssembly: 'Assembly',
          Kmat: 'KMAT',
          KmatText: 'KMATDescription',
          KMATPlant: 'KMATPlant',
          KMATStatus: 'KMATStatus',
          Vblo: 'VBLO',
          VbloText: 'VBLODescription',
          VbloPlant: 'VbloPlant',
          VbloStatus: 'VbloStatus',
          IsKMATVBLOActive: 'IsVBLOActive',
          Material: 'Material',
          MaterialDesc: 'MaterialDescription',
          MaterialComponent: 'Component',
          MaterialComponentDescription: 'ComponentDescription',
          IsVBLOMaterialActive: 'IsMaterialActive',
          MaterialSupplierNo: 'SupplierNo',
          MaterialSupplier: 'Supplier',
          MaterialSupplierCountry: 'SupplierCountry',
          MaterialProcurementType: 'ProcurementType',
          MaterialPlant: 'MaterialPlant',
          MaterialStatus: 'MaterialStatus',
          KMATCurrency: 'Currency',
          MaterialGMKGroup: 'GMKGroup',
          VbloType: 'VBLOType',
          MaterialWeight: 'Weight',
          MaterialBkValue: 'BKValue',
          MaterialQuantity: 'Quantity',
          MaterialIdentifier: 'Identifier',
          MaterialDate: 'MaterialDate',
          MaterialMr: 'MR',
          VbloAmount: 'VbloAmount',
          VbloPrice: 'VbloPrice',
          VbloDate: 'VbloDate',
          VbloKreditor: 'VbloKreditor'
        };
        
        return customHeaders[params.column.getColId()] || params.column.getColDef().headerName!;
      },
      // protection: true,
      sheetName: "Material Master",
      fileName: "MaterialMaster",
    });
    this.showGrid = true;
    this.toast.notify(serverMessage.excelExportMessage, "success");
  }

  importExcel(event: any) {
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
    if (event.target.files.length > 0) {
      this.importing = true;
      let file = event.target.files[0];
      //Validation for excel type.
      let ext = file.name.split('.').pop();
      if (ext != "xlsx") {
        this.importing = false;
        this.toast.notify(serverMessage.excelnotImportMessage, "error"); this.onCancel();
      }
      else {
        // To check the size of the Excel file
        // if (event.target.files[0].size / 1024 / 1024 > 10) {
        //   this.importing = false;
        //   this.toast.notify(serverMessage.excelImportMessage + ' is More Than 10MB', "error"); this.onCancel();
        // }
        // else {
          let fileReader = new FileReader();
          fileReader.readAsBinaryString(file);
          fileReader.onload = (e) => {
            let workBook = XLSX.read(fileReader.result, { type: 'binary', cellDates: true });
            let workSheet = workBook.SheetNames;

            this.importExcelData = XLSX.utils.sheet_to_json(workBook.Sheets[workSheet[0]], { defval: "" });
           
            //If user uploads different excel file
            if (workBook.SheetNames[0] !== this.s1[0]) { this.importing = false; this.toast.notify(serverMessage.materialMaster.WorksheetError, "error"); this.onCancel(); }
            else {
              //if user uploads blank excel file
              if (this.importExcelData.length == 0) { this.importing = false; this.toast.notify(serverMessage.Blanksheeterror, "error"); this.onCancel(); }

              else {
                // Check if the columns exist
                const hasIsVBLOActive = this.importExcelData.some((row: any) => 'IsVBLOActive' in row);
                const hasIsMaterialActive = this.importExcelData.some((row: any) => 'IsMaterialActive' in row);

                // Filter only if at least one column exists
                if (hasIsVBLOActive && hasIsMaterialActive) {
                  this.importExcelData = this.importExcelData.filter((row: any) =>
                    row.IsVBLOActive === true || row.IsVBLOActive === "true" || row.IsMaterialActive === true || row.IsMaterialActive === "true"
                  );
                }

                let errorLine = 0;
                let errorMessage = "";

                // Validate mandatory fields
                const hasInvalidData = this.importExcelData.some((item, index) => {

                  errorLine = index + 2;

                  if (!item.KMATDescription || !item.KMAT) {
                    errorMessage = serverMessage.materialMaster.kmatError;
                    return true; 
                  }

                  if ((!item.VBLODescription && item.VBLO) || (item.VBLODescription && !item.VBLO)) {
                    errorMessage = serverMessage.materialMaster.vbloError; 
                    return true;
                  }

                  if ((!item.MaterialDescription && item.Material) || (item.MaterialDescription && !item.Material)) {
                    errorMessage = serverMessage.materialMaster.materialError;  
                    return true;
                  }

                  if ((!item.ComponentDescription && item.Component) || (item.ComponentDescription && !item.Component)) {
                    errorMessage = serverMessage.materialMaster.componentError;   
                    return true;
                  }

                  if (!item.VBLODescription && item.MaterialDescription) {
                    errorMessage = serverMessage.materialMaster.materialVbloError;    
                    return true;
                  }
                  return false;
                });

                if (hasInvalidData) {
                  this.importing = false;
                  this.toast.notify(
                    `${errorMessage} at line ${errorLine}. Please correct it.`,
                    "error"
                  );
                  this.onCancel();
                  return;
                }


                // Check for same KMAT with different descriptions
                // const kmatMap = new Map<string, string>();
                // const hasConflict = this.importExcelData.some((item, index) => {
                //   if (kmatMap.has(item.KMAT)) {
                //     if (kmatMap.get(item.KMAT) !== item.KMATDescription) {
                //       errorLine = index + 1; // Excel rows are 1-indexed
                //       return true;
                //     }
                //   } else {
                //     kmatMap.set(item.KMAT, item.KMATDescription);
                //   }
                //   return false;
                // });

                // if (hasConflict) {
                //   this.importing = false;
                //   this.toast.notify(
                //     `Conflict found at line ${errorLine}: KMAT has inconsistent descriptions.`,
                //     "error"
                //   );
                //   this.onCancel();
                //   return;
                // }

        

                // Check for consistent Description,Plant, Status, and Currency for each KMAT
                const kmatConsistencyMap = new Map<string, { Description: string; KMATPlant: string; KMATStatus: string; Currency: string }>();
                const hasInconsistentData = this.importExcelData.some((item, index) => {
                  errorLine = index + 2; // Excel rows are 1-indexed

                  if (kmatConsistencyMap.has(item.KMAT)) {
                    const storedCombination = kmatConsistencyMap.get(item.KMAT);

                    // Check if the current combination matches the stored one
                    if (
                      storedCombination?.Description !== item.KMATDescription ||
                      storedCombination?.KMATPlant !== item.KMATPlant ||
                      storedCombination?.KMATStatus !== item.KMATStatus ||
                      storedCombination?.Currency !== item.Currency
                    ) {
                      this.toast.notify(
                        `Inconsistent Description, KMATPlant, KMATStatus, or Currency for KMAT "${item.KMAT}" at line ${errorLine}. Please ensure they are consistent for all rows.`,
                        "error"
                      );
                      return true;
                    }
                  } else {
                    // Store the combination for the current KMAT
                    kmatConsistencyMap.set(item.KMAT, {
                      Description: item.KMATDescription,
                      KMATPlant: item.KMATPlant,
                      KMATStatus: item.KMATStatus,
                      Currency: item.Currency,
                    });
                  }

                  return false;
                });

                if (hasInconsistentData) {
                  this.importing = false; 
                  this.onCancel();
                  return;
                }

                // Check for consistent VBLODesc, Price, and AmountBkValue for each VBLO
                const vbloConsistencyMap = new Map<string, { VBLODesc: string; VBLOType: string; VbloPlant: number;VbloStatus:string; Amount: number; Price: number; Date: string; Kreditor: string; }>();
                const hasInconsistentVBLO = this.importExcelData.some((item, index) => {
                  errorLine = index + 2; // Excel rows are 1-indexed

                  if (vbloConsistencyMap.has(item.VBLO)) {
                    const storedCombination = vbloConsistencyMap.get(item.VBLO);

                    // Check if the current combination matches the stored one
                    if (
                      storedCombination?.VBLODesc !== item.VBLODescription ||
                      storedCombination?.VBLOType !== item.VBLOType ||
                      storedCombination?.VbloPlant !== item.VbloPlant ||
                      storedCombination?.VbloStatus !== item.VbloStatus ||
                      storedCombination?.Amount !== item.VbloAmount ||
                      storedCombination?.Price !== item.Price ||
                      storedCombination?.Date !== item.VbloDate ||
                      storedCombination?.Kreditor !== item.VbloKreditor
                    ) {
                      this.toast.notify(
                        `Inconsistent VBLOType, VBLODesc, VbloPlant, VbloStatus, VbloAmount, Price, VbloDate or VbloKreditor for VBLO "${item.VBLO}" at line ${errorLine}. Please ensure they are consistent for all rows.`,
                        "error"
                      );
                      return true;
                    }
                  } else {
                    // Store the combination for the current VBLO
                    vbloConsistencyMap.set(item.VBLO, {
                      VBLODesc: item.VBLODescription,
                      VBLOType: item.VBLOType,
                      VbloPlant: item.VbloPlant,
                      VbloStatus: item.VbloStatus,
                      Amount: item.VbloAmount,
                      Price: item.Price,
                      Date: item.VbloDate,
                      Kreditor: item.VbloKreditor
                    });
                  }

                  return false;
                });


                if (hasInconsistentVBLO) {
                  this.importing = false; 
                  this.onCancel();
                  return;
                }

               
                // Check for consistent Material fields
                const materialConsistencyMap = new Map<string, {
                  MaterialDescription: string; SupplierNo: string; Supplier: string; SupplierCountry: string;
                  Component: string; ComponentDescription: string; ProcurementType: string; MaterialPlant: string; MaterialStatus:string;
                   BKValue: string;  MaterialDate: string;
                  MR: string; GMKGroup: string; Assembly: string;
                }>();
                const hasInconsistentMaterial = this.importExcelData.some((item, index) => {
                  errorLine = index + 2;

                  if (materialConsistencyMap.has(item.Material +'-'+ item.Component)) {
                    const storedCombination = materialConsistencyMap.get(item.Material +'-'+ item.Component);

                    // Check if the current combination matches the stored one
                    if (
                      storedCombination?.MaterialDescription !== item.MaterialDescription ||
                      storedCombination?.SupplierNo !== item.SupplierNo ||
                      storedCombination?.Supplier !== item.Supplier ||
                      storedCombination?.SupplierCountry !== item.SupplierCountry ||
                      storedCombination?.Component !== item.Component ||
                      storedCombination?.ComponentDescription !== item.ComponentDescription ||
                      storedCombination?.ProcurementType !== item.ProcurementType ||
                      storedCombination?.MaterialPlant !== item.MaterialPlant ||
                      storedCombination?.BKValue !== item.BKValue ||
                      storedCombination?.MaterialStatus !== item.MaterialStatus ||
                      storedCombination?.MaterialDate !== item.MaterialDate ||
                      storedCombination?.MR !== item.MR ||
                      storedCombination?.GMKGroup !== item.GMKGroup ||
                      storedCombination?.Assembly !== item.Assembly
                    ) {
                      this.toast.notify(
                        `Inconsistent MaterialDescription, SupplierNo, Supplier, SupplierCountry, Component,ComponentDescription,ProcurementType
                           MaterialPlant, MaterialStatus, BKValue,MR,GMKGroup or Assembly for Component "${item.Component}" at line ${errorLine}. Please ensure they are consistent for all rows.`,
                        "error"
                      );
                      return true;
                    }
                  } else {
                    // Store the combination for the current VBLO
                    materialConsistencyMap.set(item.Material +'-'+ item.Component, {
                      MaterialDescription: item.MaterialDescription,
                      SupplierNo: item.SupplierNo,
                      Supplier: item.Supplier,
                      SupplierCountry: item.SupplierCountry,
                      Component: item.Component,
                      ComponentDescription: item.ComponentDescription,
                      ProcurementType: item.ProcurementType,
                      MaterialPlant: item.MaterialPlant,
                      BKValue: item.BKValue,
                      MaterialStatus: item.MaterialStatus,
                      MaterialDate: item.MaterialDate,
                      MR: item.MR,
                      GMKGroup: item.GMKGroup,
                      Assembly: item.Assembly
                    });
                  }

                  return false;
                });


                if (hasInconsistentMaterial) {
                  this.importing = false; 
                  this.onCancel();
                  return;
                }

                if (!hasInvalidData && !hasInconsistentMaterial && !hasInconsistentVBLO && !hasInconsistentData) {
                  this.importing = false;
                  this.excelDatatoSend(this.importExcelData);
                  this.isImport = true;
                  //this.Editable = false;
                  this.toast.notify(serverMessage.excelImportMessage, "success");
                }

              }
            }

          }
       // }
      }
    }
  }

  excelDatatoSend(importedData: any[]) {
    const masterMaterialData = importedData.map(data => {
      let materialDate = new Date(data.MaterialDate);
      if (!isNaN(materialDate.getTime())) {
        materialDate.setMinutes(-materialDate.getTimezoneOffset() + 60);
      }

      let vbloDate = new Date(data.VbloDate)
      if (!isNaN(vbloDate.getTime())) {
        vbloDate.setMinutes(-vbloDate.getTimezoneOffset() + 60);
      }

      return {
        MaterialAssembly: data.Assembly,
        Kmat: data.KMAT,
        KmatText: data.KMATDescription,
        KMATPlant:data.KMATPlant,
        KMATStatus:data.KMATStatus,
        Vblo: data.VBLO,
        VbloText: data.VBLODescription,
        VbloPlant: data.VbloPlant,
        VbloStatus: data.VbloStatus,
        KMATCurrency: data.Currency,
        MaterialProcurementType: data.ProcurementType,
        MaterialSupplierNo: data.SupplierNo,
        MaterialSupplier: data.Supplier,
        MaterialSupplierCountry: data.SupplierCountry,
        MaterialComponent: data.Component,
        MaterialComponentDescription: data.ComponentDescription,
        MaterialDesc: data.MaterialDescription,
        Material: data.Material,
        MaterialPlant: data.MaterialPlant,
        MaterialStatus: data.MaterialStatus,
        VbloType: data.VBLOType,
        MaterialWeight: data.Weight,
        MaterialBkValue: data.BKValue,
        MaterialQuantity: data.Quantity,
        MaterialDate: isNaN(materialDate.getTime()) ? '' : materialDate,
        MaterialMr: data.MR,
        MaterialGMKGroup: data.GMKGroup,
        VbloAmount: data.VbloAmount,
        VbloPrice: data.VbloPrice,
        VbloDate: isNaN(vbloDate.getTime()) ? '' : vbloDate,
        VbloKreditor: data.VbloKreditor,
        MaterialIdentifier: data.Identifier
      }
    });
    this.modifiedMaterialMasterRows = masterMaterialData;

    this.Editable = false;
    this.loadMaterialMasterGrid();
  }

  bindMaterialMasterData() {
    this.showGrid = false;
    this.materialMasterService.getAllMaterialMasterData(-1, -1, "", "ASC", Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe(data => {
      this.getResponse = data;
      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.receivedData = data.Data.Item1;
        this.totalDataCount = data.Data.Item2;
        if (this.receivedData == "") {
          this.rowData = [];
          this.showGrid = true;
          return;
        }
        this.rowData = this.receivedData;
        this.loadMaterialMasterGrid();
      }
    });
  }

  loadMaterialMasterGrid() {
    this.columnDefs = [
      {
        headerName: "Assembly",
        field: "MaterialAssembly",
        tooltipField: "MaterialAssembly",
        headerTooltip: "Assembly",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "KMAT",
        field: "Kmat",
        tooltipField: "Kmat",
        headerTooltip: "KMAT",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "KMAT Text",
        field: "KmatText",
        tooltipField: "KmatText",
        headerTooltip: "KMAT Tex",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "KMATPlant",
        field: "KMATPlant",
        tooltipField: "KMATPlant",
        headerTooltip: "KMATPlant",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "KMATStatus",
        field: "KMATStatus",
        tooltipField: "KMATStatus",
        headerTooltip: "KMATStatus",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "KMAT Created UserName",
        field: "KMATCreatedBy",
        tooltipField: "KMATCreatedBy",
        headerTooltip: "KMATCreatedBy",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "KMAT Modified UserName",
        field: "KMATModifiedBy",
        tooltipField: "KMATModifiedBy",
        headerTooltip: "KMATModifiedBy",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "VBLO",
        field: "Vblo",
        tooltipField: "Vblo",
        headerTooltip: "VBLO",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "VBLO Text",
        field: "VbloText",
        tooltipField: "VbloText",
        headerTooltip: "VBLO Text",
        editable: params => {
          if (params.data.IsVBLOManual === false) {
            return false;
          }
          return this.Editable;
        },

        cellStyle: params => {
          if (params.data.IsVBLOManual === false) {
            return { backgroundColor: '#e2e5e8' };
          }
          return {};
        }
      },
      {
        headerName: "VbloPlant",
        field: "VbloPlant",
        tooltipField: "VbloPlant",
        headerTooltip: "VbloPlant",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "VbloStatus",
        field: "VbloStatus",
        tooltipField: "VbloStatus",
        headerTooltip: "VbloStatus",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "VBLO Created UserName",
        field: "VBLOCreatedBy",
        tooltipField: "VBLOCreatedBy",
        headerTooltip: "VBLOCreatedBy",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "VBLO Modified UserName",
        field: "VBLOModifiedBy",
        tooltipField: "VBLOModifiedBy",
        headerTooltip: "VBLOModifiedBy",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "Is VBLO Active",
        field: "IsKMATVBLOActive",
        tooltipField: "IsVBLOActive",
        headerTooltip: "IsVBLOActive",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "ECO ID",
        field: "MaterialECOId",
        tooltipField: "MaterialECOId",
        headerTooltip: "ECO ID",
        editable: params => {
          if (params.data.IsMaterialManual === false) {
            return false;
          }
          return this.Editable;
        },
        cellStyle: params => {
          if (params.data.IsMaterialManual === false) {
            return { backgroundColor: '#e2e5e8' };
          }
          return {};
        },
      },
      {
        headerName: "Material",
        field: "Material",
        tooltipField: "Material",
        headerTooltip: "Material",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "Material Description",
        field: "MaterialDesc",
        tooltipField: "MaterialDesc",
        headerTooltip: "Material Description",
        editable: params => {
          if (params.data.IsMaterialManual === false) {
            return false;
          }
          return this.Editable;
        },
        cellStyle: params => {
          if (params.data.IsMaterialManual === false) {
            return { backgroundColor: '#e2e5e8' };
          }
          return {};
        },
      },
      {
        headerName: "Material Created UserName",
        field: "MaterialCreatedBy",
        tooltipField: "MaterialCreatedBy",
        headerTooltip: "MaterialCreatedBy",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "Material Modified UserName",
        field: "MaterialModifiedBy",
        tooltipField: "MaterialModifiedBy",
        headerTooltip: "MaterialModifiedBy",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      {
        headerName: "Component",
        field: "MaterialComponent",
        tooltipField: "MaterialComponent",
        headerTooltip: "Material Component",
        editable: params => {
          if (params.data.IsMaterialManual === false) {
            return false;
          }
          return this.Editable;
        },
        cellStyle: params => {
          if (params.data.IsMaterialManual === false) {
            return { backgroundColor: '#e2e5e8' };
          }
          return {};
        },
      },
      {
        headerName: "Component Description",
        field: "MaterialComponentDescription",
        tooltipField: "MaterialComponentDescription",
        headerTooltip: "Material Component Description",
        editable: params => {
          if (params.data.IsMaterialManual === false) {
            return false;
          }
          return this.Editable;
        },
        cellStyle: params => {
          if (params.data.IsMaterialManual === false) {
            return { backgroundColor: '#e2e5e8' };
          }
          return {};
        },
      },
      {
        headerName: "Is Material Active",
        field: "IsVBLOMaterialActive",
        tooltipField: "IsMaterialActive",
        headerTooltip: "IsMaterialActive",
        editable: false,
        cellStyle: params => {
          return { backgroundColor: '#e2e5e8' };
        },
      },
      
      {
        headerName: "Supplier No",
        field: "MaterialSupplierNo",
        tooltipField: "MaterialSupplierNo",
        headerTooltip: "Material SupplierNo",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "Supplier",
        field: "MaterialSupplier",
        tooltipField: "MaterialSupplier",
        headerTooltip: "Supplier",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "Supplier Country",
        field: "MaterialSupplierCountry",
        tooltipField: "MaterialSupplierCountry",
        headerTooltip: "Material Supplier Country",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "Procurement Type",
        field: "MaterialProcurementType",
        tooltipField: "MaterialProcurementType",
        headerTooltip: "Procurement Type",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "MaterialPlant",
        field: "MaterialPlant",
        tooltipField: "MaterialPlant",
        headerTooltip: "MaterialPlant",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "MaterialStatus",
        field: "MaterialStatus",
        tooltipField: "MaterialStatus",
        headerTooltip: "MaterialStatus",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "Currency",
        field: "KMATCurrency",
        tooltipField: "Currency",
        headerTooltip: "Currency",
        editable: (params) => {
          return this.Editable;
        },
      },
     
      {
        headerName: "Material GMKGroup",
        field: "MaterialGMKGroup",
        tooltipField: "MaterialGMKGroup",
        headerTooltip: "Material GMKGroup",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "VBLO Type",
        field: "VbloType",
        tooltipField: "VbloType",
        headerTooltip: "VBLO Type",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "Weight",
        field: "MaterialWeight",
        tooltipField: "MaterialWeight",
        headerTooltip: "Weight",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "BK-Value",
        field: "MaterialBkValue",
        tooltipField: "MaterialBkValue",
        headerTooltip: "BK-Value",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "Quantity",
        field: "MaterialQuantity",
        tooltipField: "Material Quantity",
        headerTooltip: "Material Quantity",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "Identifier",
        field: "MaterialIdentifier",
        tooltipField: "MaterialIdentifier",
        headerTooltip: "Identifier",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "Date",
        field: "MaterialDate",
        tooltipField: "MaterialDate",
        headerTooltip: "Date",
        valueFormatter: (params) => this.datePipe.transform(params.value, 'dd/MM/yyyy'),
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "MR",
        field: "MaterialMr",
        tooltipField: "MaterialMr",
        headerTooltip: "MR",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "VBLO-Mat.Amount",
        field: "VbloAmount",
        tooltipField: "VbloAmount",
        headerTooltip: "VBLO-Mat.Amount",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "VBLO-Mat.Price",
        field: "VbloPrice",
        tooltipField: "VbloPrice",
        headerTooltip: "VBLO-Mat.Price",
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "VBLO-Mat.Date",
        field: "VbloDate",
        tooltipField: "VbloDate",
        headerTooltip: "VBLO-Mat.Date",
        cellClass: "dateColumn",
        valueFormatter: (params) => this.datePipe.transform(params.value, 'dd/MM/yyyy'),
        editable: (params) => {
          return this.Editable;
        },
      },
      {
        headerName: "VBLO-Mat.Kreditor",
        field: "VbloKreditor",
        tooltipField: "VbloKreditor",
        headerTooltip: "VBLO-Mat.Kreditor",
        editable: (params) => {
          return this.Editable;
        },
      }
    ];
    this.defaultColDef = {
      filter: true,
      sortable: true,
      editable: true,
      singleClickEdit: true
    };
    this.domLayout = 'autoHeight';
    this.showGrid = true;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    //this.gridApi.sizeColumnsToFit();
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      //this.gridApi.sizeColumnsToFit();
    }
  }

  onCellValueChanged(params) {
    debugger;
    this.isImport = false;
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }

    this.unSavedChanges.emit(valToEmit);
    let rowPushed = false;

    this.hideExportImport = true;
    if (this.modifiedMaterialMasterRows.length == 0) {
       this.modifiedMaterialMasterRows.push(params.data);
       rowPushed = true;
      //this.modifiedMaterialMasterRows = this.rowData;
    }
    else {
      let index = this.modifiedMaterialMasterRows.findIndex(row => row["MaterialComponent"] == params.data["MaterialComponent"] && row["Material"] == params.data["Material"] && row["Kmat"] == params.data["Kmat"] && row["Vblo"] == params.data["Vblo"]);
      
      if (index != -1) {
        this.modifiedMaterialMasterRows[index] = params.data;
      }
      else
      {
        this.modifiedMaterialMasterRows.push(params.data);
      }
      // this.modifiedMaterialMasterRows.forEach(
      //   (row, index) => {
      //     if (row["MaterialId"] == params.data["MaterialId"] && row["KmatId"] == params.data["KmatId"] && row["Vblo"] == params.data["Vblo"]) {
      //       this.modifiedMaterialMasterRows[index] = params.data;
      //       rowPushed = true;
      //     }
      //   }
      // )
    }
    // if (!rowPushed) {
    //   this.modifiedMaterialMasterRows.push(params.data);
    // }
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  onCancel() {
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }

    this.isImport = false;
    this.modifiedMaterialMasterRows = [];
    this.hideExportImport = false;
    this.unSavedChanges.emit(valToEmit);
    (<HTMLInputElement>document.getElementById('file')).value = "";
    this.bindMaterialMasterData();
    this.Editable = true;
  }

  submit(event?: any) {
    let response = null;
    //console.log(this.modifiedMaterialMasterRows.length); 
    let postData = this.dataToSend();
    if (this.modifiedMaterialMasterRows.length != 0) {
      this.modifiedMaterialMasterRows = [];
      this.showGrid = false;
      response = this.materialMasterService.updateDataInChunk(postData).toPromise()
        .then(res => {
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              let valToEmit = {
                unsavedChanges: false,
                saveButtonClick: event == undefined ? false : true
              }
              this.unSavedChanges.emit(valToEmit);
              // end;
              this.toast.notify(serverMessage.dataSaveMessage, "success");
              this.modifiedMaterialMasterRows = [];
              (<HTMLInputElement>document.getElementById('file')).value = "";
              this.hideExportImport = false;
              this.bindMaterialMasterData();
              this.Editable = true;
              this.isImport = false;
              return true;
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI              
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              this.showGrid = true;
              return false;

            }
          }
          //Going in else means that success code is not. ie. Http Status from server is not ok.
          else {
            //Show errorCode and errorMessage in the UI           
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer);
            this.showGrid = true;
            return false;
          }
        }, (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          this.showGrid = true;
          return false;
        })
    } else {
      // changes;
      let valToEmit = {
        unsavedChanges: false,
        saveButtonClick: event == undefined ? false : true
      }
      this.unSavedChanges.emit(valToEmit);
      // end;
      return true;
    }
    return response;
  }

  dataToSend() {
    const materialMasterData: MasterMaterialDetail = {
      isImport: this.isImport,
      userId: this.loggedInUser.UserId,
      listMasterMaterialData: this.modifiedMaterialMasterRows,
      businessAreaId: Number(this.selectBusinessArea),
      businessLineId: Number(this.selectBusinessLine),
    }

    return materialMasterData;
  }

}
