import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MaterialMasterService } from '../services/material-master.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class ProgressSpinnerComponent implements OnInit {
  
  progress: Number = 0;

  @Input() show: boolean = false;

  constructor(
    private masterService: MaterialMasterService
  ) {
  }

  ngOnInit(): void {
    this.masterService.progress.subscribe(p => this.progress = p);
  }

}
