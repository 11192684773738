<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>
<div *ngIf="showGrid" class="container">
    <div class="label-header">
        <label class="label-headercolor">Reference Scenario </label>
    </div>
    <div class="col-md-12 col-lg-12" style="padding-left: 0px;padding-right: 0px;">
  <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="ProcessCost" [rowData]="refProcessCostRowData"
        [columnDefs]="refProcessCostColumnDefs" [defaultColDef]="refProcessCostDefaultColDef"
        [rowClassRules]="rowClassRules" [domLayout]="domLayout" [frameworkComponents]="refFrameworkComponents" [rowHeight]="rowHeight" [headerHeight]="headerHeight"
        (gridReady)="onRefProcessCostGridReady($event)" (gridSizeChanged)="onRefProcessCostGridSizeChanged($event)">
    </ag-grid-angular>  
   </div>

   <div class="label-header">
    <label class="label-headercolor">Target Scenario </label>
</div>
   <div class="col-md-12 col-lg-12"
   style="padding-left: 0px;padding-right: 0px; ">
<ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="ProcessCost" [rowData]="tgtProcessCostRowData"
[columnDefs]="tgtProcessCostColumnDefs" [defaultColDef]="defaultColDef"
[rowClassRules]="rowClassRules" [domLayout]="domLayout" [rowHeight]="rowHeight" [headerHeight]="headerHeight"
(gridReady)="ontgtProcessCostGridReady($event)" (gridSizeChanged)="ontgtProcessCostGridSizeChanged($event)">
</ag-grid-angular>
</div>
<div  *ngIf="optAVisible">
<div class="label-header">
    <label class="label-headercolor">Option A </label>
</div>
<ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="ProcessCost" [rowData]="opAProcessCostRowData"
    [columnDefs]="opAProcessCostColumnDefs" [defaultColDef]="opAProcessCostDefaultColDef"
    [rowClassRules]="rowClassRules" [domLayout]="domLayout" [rowHeight]="rowHeight" [headerHeight]="headerHeight"
    (gridReady)="onopAProcessCostGridReady($event)" (gridSizeChanged)="onopAProcessCostGridSizeChanged($event)">
</ag-grid-angular>
</div>
<div  *ngIf="optBVisible">
<div class="label-header">
    <label class="label-headercolor">Option B </label>
</div>
<ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="ProcessCost" [rowData]="opBProcessCostRowData"
    [columnDefs]="opBProcessCostColumnDefs" [defaultColDef]="opBProcessCostDefaultColDef"
    [rowClassRules]="rowClassRules" [domLayout]="domLayout" [rowHeight]="rowHeight" [headerHeight]="headerHeight"
    (gridReady)="onopBProcessCostGridReady($event)" (gridSizeChanged)="onopBProcessCostGridSizeChanged($event)">
</ag-grid-angular>
</div>
<div  *ngIf="optCVisible">

<div class="label-header">
    <label class="label-headercolor">Option C </label>
</div>
<ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="ProcessCost" [rowData]="opCProcessCostRowData"
    [columnDefs]="opCProcessCostColumnDefs" [defaultColDef]="opCProcessCostDefaultColDef"
    [rowClassRules]="rowClassRules" [domLayout]="domLayout" [rowHeight]="rowHeight" [headerHeight]="headerHeight"
    (gridReady)="onopCProcessCostGridReady($event)" (gridSizeChanged)="onopCProcessCostGridSizeChanged($event)">
</ag-grid-angular>
</div>

<div class="col-md-1 col-lg-1 label-header" style="display: inline-block;">
    <button [disabled]="!isEditable" [ngClass]="{ 'click-not-allowed': !isEditable }" (click)="addNewRow()"
        title="Add">
        <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add" />
        <label class="label-headercolor">add Option</label>
    </button>
</div>
   
    <div class="row" style="margin-top: 20px">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle">
                <button class="button button--small" (click)="onClickCancel()">Cancel & Exit</button>&nbsp;
                <button class="button button--small" (click)="onClickBack()" type="submit">Back</button>&nbsp;
                <button class="button button--small" (click)="onClickNext()" type="submit">Next</button>&nbsp;
            </div> 
        </div>
    </div>

    <app-commentbox [configData]="objData"></app-commentbox>
</div>