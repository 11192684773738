<form [formGroup]="helpForm" (ngSubmit)="onSubmit($event)">
    <div class="container">
      <div class="card" style="height: 100%; width: 100%">
        <div class="card-header label">Help Target Master Page</div>
        <div class="card-body" style="padding: 10px">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="parentPage" class="label">Parent Pages</label>
              <select
                id="parentPage"
                class="form-control"
                formControlName="parentId"
                aria-label="parentPage"
                (change)="parentPageChange($event.target.value)"
              >
                <option value="">Please select</option>
                <option *ngFor="let pType of parentPages" [value]="pType.TCHelpId">
                  {{ pType.PageName }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="childPage" class="label">Child Pages</label>
              <select
                id="childPage"
                class="form-control"
                formControlName="childId"
                aria-label="childPage"
                (change)="childPageChange($event.target.value)"
              >
                <option value="">Please select</option>
                <option *ngFor="let pType of childPages" [value]="pType.TCHelpId">
                  {{ pType.PageName }}
                </option>
              </select>
            </div>
          </div>
  
          <div class="row pt-2">
            <div class="col-md-12">
              <div class="formGroup">
                <quill-editor
                (keyup)="changeInTextArea($event)"
                 
                  [styles]="editorStyle"
                  formControlName="editor"
                  placeholder="Enter Text"
                  [(ngModel)]="val"
                  id="editor"
                  
                >
                </quill-editor>
              </div>
              <div></div>
              <div class="row" style="margin-top: 10px">
                <div class="offset-6 col-md-6">
                  <div class="buttonStyle">
                    <button
                      class="button button--small"
                      style="margin-right: 5px; margin-top: 5px;"
                      type="submit"
                      (click)="onCancel()"
                    >
                      Cancel
                    </button>
                    <button
                      class="button button--small"
                      style="margin-top: 5px; float: right"
                      type="submit"
                      (click)="sendData('true')"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  