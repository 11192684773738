import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { EqTaretcostingCalcPopupComponent } from '../eq-taretcosting-calc-popup/eq-taretcosting-calc-popup.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-edit-eq-calc',
  templateUrl: './edit-eq-calc.component.html',
  styleUrls: ['./edit-eq-calc.component.css']
})
export class EditEqCalcComponent implements ICellRendererAngularComp {
  public gridApi: any;
  public params: any;
  isEditVisible: any;
  countryCode;
  totalBK: any;

  constructor(
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
  ) { }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  // get called on load of the component;
  agInit(params: ICellRendererParams) {
    this.params = params;
    this.gridApi = params.api;
    this.countryCode = this.localStorageService.get('countryCode');

    if (!this.params.node.group && this.params.data.Name == "Margin") {
      this.isEditVisible = this.params.data.IsEditable;
    } else {
      this.isEditVisible = false;
    }
  }

  editData(params: any) {
    if (!this.params.node.group && this.params.column.colId == "tc") {
      this.totalBK = this.gridApi.getDisplayedRowAtIndex(4).data.TargetScenario.TargetCost.PerSystem
    } 
    if (!this.params.node.group && this.params.column.colId == "fc") {
      this.totalBK = this.gridApi.getDisplayedRowAtIndex(4).data.TargetScenario.Forecast.PerSystem;
    }

    if (!this.params.node.group && this.params.column.colId == "tcOptA") {
      this.totalBK = this.gridApi.getDisplayedRowAtIndex(4).data.OptionA.TargetCost.PerSystem
    } 
    if (!this.params.node.group && this.params.column.colId == "fcOptA") {
      this.totalBK = this.gridApi.getDisplayedRowAtIndex(4).data.OptionA.Forecast.PerSystem;
    }

    if (!this.params.node.group && this.params.column.colId == "tcOptB") {
      this.totalBK = this.gridApi.getDisplayedRowAtIndex(4).data.OptionB.TargetCost.PerSystem
    } 
    if (!this.params.node.group && this.params.column.colId == "fcOptB") {
      this.totalBK = this.gridApi.getDisplayedRowAtIndex(4).data.OptionB.Forecast.PerSystem;
    }

    if (!this.params.node.group && this.params.column.colId == "tcOptC") {
      this.totalBK = this.gridApi.getDisplayedRowAtIndex(4).data.OptionC.TargetCost.PerSystem
    } 
    if (!this.params.node.group && this.params.column.colId == "fcOptC") {
      this.totalBK = this.gridApi.getDisplayedRowAtIndex(4).data.OptionC.Forecast.PerSystem;
    }

    let dialogRef = this.dialog.open(EqTaretcostingCalcPopupComponent, {
      height: '42%',
      width: '40%',
      panelClass: 'kmat-dialog-container',
      data: this.totalBK
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (!this.params.node.group && this.params.column.colId == "tc") {
        this.gridApi.getDisplayedRowAtIndex(1).data.TargetScenario.TargetCost.PerSystem = result.CRP;
        this.gridApi.getDisplayedRowAtIndex(2).data.TargetScenario.TargetCost.PerSystem = result.Margin;
        this.gridApi.getDisplayedRowAtIndex(3).data.TargetScenario.TargetCost.PerSystem = result.BK;
      } 
      if (!this.params.node.group && this.params.column.colId == "fc") {
        this.gridApi.getDisplayedRowAtIndex(1).data.TargetScenario.Forecast.PerSystem = result.CRP;
        this.gridApi.getDisplayedRowAtIndex(2).data.TargetScenario.Forecast.PerSystem = result.Margin;
        this.gridApi.getDisplayedRowAtIndex(3).data.TargetScenario.Forecast.PerSystem = result.BK;
      }

      if (!this.params.node.group && this.params.column.colId == "tcOptA") {
        this.gridApi.getDisplayedRowAtIndex(1).data.OptionA.TargetCost.PerSystem = result.CRP;
        this.gridApi.getDisplayedRowAtIndex(2).data.OptionA.TargetCost.PerSystem = result.Margin;
        this.gridApi.getDisplayedRowAtIndex(3).data.OptionA.TargetCost.PerSystem = result.BK;
      } 
      if (!this.params.node.group && this.params.column.colId == "fcOptA") {
        this.gridApi.getDisplayedRowAtIndex(1).data.OptionA.Forecast.PerSystem = result.CRP;
        this.gridApi.getDisplayedRowAtIndex(2).data.OptionA.Forecast.PerSystem = result.Margin;
        this.gridApi.getDisplayedRowAtIndex(3).data.OptionA.Forecast.PerSystem = result.BK;
      }

      if (!this.params.node.group && this.params.column.colId == "tcOptB") {
        this.gridApi.getDisplayedRowAtIndex(1).data.OptionB.TargetCost.PerSystem = result.CRP;
        this.gridApi.getDisplayedRowAtIndex(2).data.OptionB.TargetCost.PerSystem = result.Margin;
        this.gridApi.getDisplayedRowAtIndex(3).data.OptionB.TargetCost.PerSystem = result.BK;
      } 
      if (!this.params.node.group && this.params.column.colId == "fcOptB") {
        this.gridApi.getDisplayedRowAtIndex(1).data.OptionB.Forecast.PerSystem = result.CRP;
        this.gridApi.getDisplayedRowAtIndex(2).data.OptionB.Forecast.PerSystem = result.Margin;
        this.gridApi.getDisplayedRowAtIndex(3).data.OptionB.Forecast.PerSystem = result.BK;
      }

      if (!this.params.node.group && this.params.column.colId == "tcOptC") {
        this.gridApi.getDisplayedRowAtIndex(1).data.OptionC.TargetCost.PerSystem = result.CRP;
        this.gridApi.getDisplayedRowAtIndex(2).data.OptionC.TargetCost.PerSystem = result.Margin;
        this.gridApi.getDisplayedRowAtIndex(3).data.OptionC.TargetCost.PerSystem = result.BK;
      } 
      if (!this.params.node.group && this.params.column.colId == "fcOptC") {
        this.gridApi.getDisplayedRowAtIndex(1).data.OptionC.Forecast.PerSystem = result.CRP;
        this.gridApi.getDisplayedRowAtIndex(2).data.OptionC.Forecast.PerSystem = result.Margin;
        this.gridApi.getDisplayedRowAtIndex(3).data.OptionC.Forecast.PerSystem = result.BK;
      }
      this.gridApi.refreshCells();
    });

  }

}
