export class TCSCMLCTargetCost {

    SCMId: number;
    ConfigurationId: number;
    Component: string;
    SubComponent: string;
    ReferenceScenario: SCMLCRefScenario;
    TargetScenario: SCMLCScenario;
    OptionA: SCMLCScenario;
    OptionB: SCMLCScenario;
    OptionC: SCMLCScenario;
    IsActive: boolean;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}

export class SCMLCCost {
    Absolute: number;
    PercentOfMEK: number;
    Total: number;
}

export class SCMLCRefScenario extends SCMLCCost {
    SCMId: number;
    ScenarioTitle: string;
    Quantity: number;
    Weight: number;
}

export class SCMLCScenario {
    ScenarioTitle: string;
    TargetCost: SCMLCTargetCost;
    Forecast: SCMLCForecast;
    Actual: SCMLCActual;
}

export class SCMLCTargetCost extends SCMLCCost {
    SCMId: number;
    Quantity: number;
    Weight: number;
    DeltaTCVsRef: number;
}

export class SCMLCForecast extends SCMLCCost {
    SCMId: number;
    DeltaFCVsRef: number;
}

export class SCMLCActual extends SCMLCCost {
    SCMId: number;
    DeltaTCVsACT: number;
}


