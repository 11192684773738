import { ViewChild, Component, ViewEncapsulation, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharedServiceService } from '../app/services/shared-service.service'
import { Subject } from 'rxjs';
import { LocalStorageService } from '../app/services/local-storage.service';
import { CategorizationService } from "../app/services/categorization.service";
import { ConfigurationService } from './services/configuration.service';

//Defined Global variables
let countryCode;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AppComponent implements OnInit {
  public Identifier: any;
  public CurrencyFromDB: any;
  public currency: any;
  public Productivity: any;
  public dataRecived: any;
  public ProductivityFCEUR: any;
  public productivity: any;
  public ProjectId: any;
  public ProjectTitle: any;

  @ViewChild('sidenav') sidenav: any;
  @ViewChild('sidehelp') sidehelp: any;

  title = 'eco';
  navopened: boolean = true;
  helpopened: boolean = false;
  sideHelpOpen: boolean = false;
  loggedIn = false;
  isIframe = false;
  sidenavopened: boolean = true;
  fixed: any = 7;
  username: string = ""
  userInfo: any;
  ideaIdAndTitle: Subject<any> = new Subject<any>();
  idea: any;
  userAccount: any;
  maxValue: any = 50;
  product: any;
  marqueeMessage: string = "";
  currencyValueFromCategorization = '';
  MilestoneName: any;
  ConFiguration: any = [];

  configurationID: any;
  Stand: any;
  projectData: any;

  constructor(
    public router: Router,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedServiceService,
    private LocalStorageService: LocalStorageService,
    private catService: CategorizationService,
    private localStorageService: LocalStorageService,
    private configurationkservice: ConfigurationService
  ) { }

  ngOnInit(): void {
    window.onunload = () => {
      // Clear the local storage of categorization on close of page tab or on refresh;    
      this.sharedService.onRemoveCategorizationData();

    }
    //Get Country Code
    countryCode = this.LocalStorageService.get('countryCode');
    this.catService.currentMsg.subscribe(x => this.currency = x);
    setTimeout(() => {
      this.sharedService.data.subscribe((data) => {
        console.log(data);
        this.dataRecived = data;
        this.Identifier = this.dataRecived.ideaId;
        this.product = this.dataRecived.title;
        this.CurrencyFromDB = this.dataRecived.currency;
        this.currency = this.CurrencyFromDB;
        this.productivity = this.dataRecived.productivity;
        countryCode = this.LocalStorageService.get('countryCode');
        this.ProductivityFCEUR = new Intl.NumberFormat(countryCode).format(Number(parseFloat(this.productivity).toFixed(2)));
        this.marqueeMessage = this.dataRecived.marqueeMessage;
        if (!this.marqueeMessage) {
          this.marqueeMessage = '';
        }

        if (this.product) {
          if (this.dataRecived.title.length <= this.maxValue) {
            this.Productivity = this.dataRecived.title;
          }
          else {
            this.Productivity = this.dataRecived.title.substr(0, this.maxValue) + '...';
          }
        }
        else {
          this.Productivity = null;
        }

      });
      
      this.sharedService.projectData.subscribe((data) => {
        this.dataRecived = data;
        this.ProjectId = data.projectId;
        this.ProjectTitle = data.title;
        this.MilestoneName = data.milestoname;
        this.ConFiguration = data.configuration;
        this.configurationID = data.selectedConfiguration;

        if (data.configuration != null && data.configuration?.length != 0) {
          //this.configurationID = this.ConFiguration[0].Id;
          this.sharedService.changeConfigId(this.configurationID);
          this.Stand = data.stand;
          //this.localStorageService.set("ConfigId", this.configurationID);

          this.configurationkservice.GetConfigurationDetails(this.configurationID).subscribe((res: any) => {
            if (res.StatusCode == 200) {
              this.projectData = res.Data.StandFlags;

              this.Stand = res.Data.StandFlags;
            }
          })
          this.configurationkservice.GetLinkKMATDetails(this.configurationID).subscribe((res: any) => {
            if (res.StatusCode == 200) {

              console.log(res.Data, this.configurationID)
              if (res.Data != null)
                //  this.projectData = res.Data.Currency;

                this.currency = res.Data.Currency;

              else
                this.currency = null;
            }

          })
        }
        if (this.ProjectId) {
          if (this.dataRecived.title.length <= this.maxValue) {
            this.ProjectTitle = this.dataRecived.title;
          }
          else {
            this.ProjectTitle = this.dataRecived.title.substr(0, this.maxValue) + '...';
          }
        }
        else {
          this.ProjectTitle = null;
        }
      })
    }, 50)
  
  }
  changeVal() {
    return this.product
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getRoute() {
    if (this.router.url == "/home") {
      return "mainside-container";
    }
    else {
      return "main-container";
    }
  }

  toggleSideNav() {
    this.sidenav.toggle();
    this.sidenavopened = !this.sidenavopened
  }

  toggleHelpNav() {
    this.sidehelp.toggle();
    this.sideHelpOpen = !this.sideHelpOpen;
    if (this.fixed == 238) {
      this.fixed = 7;
    }
    else if (this.fixed == 7) {
      this.fixed = 238;
    }
  }

  ConfigurationChanged() {
    this.configurationkservice.GetConfigurationDetails(this.configurationID).subscribe((res: any) => {
      if (res.StatusCode == 200) {
        this.projectData = res.Data.StandFlags;

        this.Stand = res.Data.StandFlags;
      }
    })
    this.configurationkservice.GetLinkKMATDetails(this.configurationID).subscribe((res: any) => {
      if (res.StatusCode == 200) {

        console.log(res.Data, this.configurationID)
        if (res.Data != null)
          //  this.projectData = res.Data.Currency;

          this.currency = res.Data.Currency;

        else
          this.currency = null;
      }

    })
    this.localStorageService.set("ConfigId", this.configurationID);
    this.sharedService.changeConfigId(this.configurationID);
  }
}
