import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from './local-storage.service';
import { SharedServiceService } from './shared-service.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Dirty } from '../interface/dirty-interface';
import { Observable, of } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CandeactivateTcService {

  constructor(
    private dialog: MatDialog, 
    private localStorage: LocalStorageService, 
    private sharedService: SharedServiceService, 
    private router: Router
  ) { }

  canDeactivate(component: Dirty, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) 
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      // when user navigate to create project page
      if (this.localStorage.get("projectId") == null) {
        if (nextState.url == "/home" && component.canDeactivate()) {
          let dialogRef = this.dialog.open(DialogComponent, { data: { message: "You have unsaved changes.Do you want to save it?", okBtn: "Yes", cancelBtn: "No" } })
  
          return dialogRef.afterClosed().pipe(map(result => {
            if (result) {
              return component.submit()
            }
            else {
              return of(true);
            }
  
          }))
        }
        else if (nextState.url == "/home" && !component.canDeactivate()) {// To allow the user to navigate when he attmpts to navigate to home while creating idea
          return true;
        }
        else if (nextState.url == '/tc-manageProject' && !component.canDeactivate()) {// To allow user to navigate directly to manage project page when the page dont have unsaved changes
          return true;
        }
        else if (nextState.url == '/tc-configuration' && !component.canDeactivate()) {// To allow user to navigate directly to configuration page when the page dont have unsaved changes
          return true;
        }
        else if (nextState.url == '/tc-eq' && !component.canDeactivate()) {// To allow user to navigate directly to configuration page when the page dont have unsaved changes
          return true;
        }
        else if (nextState.url == '/tc-manageProject' && component.canDeactivate()) {// To allow the user to navigate to grid after successful data save
          return this.getDeactivateStatus(component);
        }
        else {
          return this.getDeactivateStatus(component);
        }
      } 
      else if (component.canDeactivate()) { 
        if (currentState.url == "/tc-configuration-vblo") {
          return true;
        }
        return this.getDeactivateStatus(component);
      } 
      else {
        if (currentState.url == "/tc-configuration-vblo") {
          this.localStorage.remove("configuration")
        }
        return true;
      }
  }

  getDeactivateStatus(component: any) {
    return component.submit();
  }

}
