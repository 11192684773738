<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>
<div class="container">
    <div class="row">
        <div class="col-md-8 col-lg-8">
            <header>
                <p style="font-size: 18px; font-weight: 550; margin-left: 64%">
                    <b>Manage Projects</b>
                </p>
            </header>
        </div>
        <div class="col-md-4c col-lg-4" style="text-align: right; margin-top: 5px">
            <button (click)="goToHomePage()" title="Home" aria-label="Home">
                <img
                  alt="homeIcon"
                  src="../assets/icons/home.svg"
                  height="50"
                  width="50"
                />
                <figcaption style="padding: 0px; text-align: center; font-weight: bold">
                  Home
                </figcaption>
              </button>
              <button
                (click)="goToCreateProject()"
                title="Create Project"
                aria-label="Create Idea"
                style="margin-right: 27px"
              >
                <img
                  alt="IdeaIcon"
                  src="../assets/icons/idea.svg"
                  height="50"
                  width="50"
                />
                <figcaption style="padding: 0px; text-align: center; font-weight: bold">
                  New
                </figcaption>
              </button>
        </div>
    </div>
</div>

<div class="example-header" style="margin-left: 50px">
    <label for="page-size"
      >Page Size:
      <select (change)="onPageSizeChanged()" id="page-size" aria-label="Pages">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200" selected="">200</option>
        <option value="all">All</option>
      </select>
    </label>
    <div class="d-inline-block" style="margin-left: 1%; font-size: 1px;">
      <label  (click)="ResetFilter()"><u> Reset Filter </u></label>
    </div>
  </div>

  <div
  style="
    justify-content: center;
    display: flex;
    margin-left: 50px;
    margin-right: 50px;
  "
>
  <ag-grid-angular
    *ngIf="showGrid"
    style="width: 100%; height: 560px"
    class="ag-theme-alpine grid"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultProperties"
    [pagination]="true"
    [animateRows]="true"
    (gridSizeChanged)="onGridSizeChanged($event)"
    [paginationPageSize]="paginationPageSize"
    (gridReady)="onGridReady($event)"
    [rowStyle]="rowStyle"
    (firstDataRendered)="onFirstDataRendered($event)"
    (filterChanged)="onFilterChanged($event)"
  >
  </ag-grid-angular>
</div>