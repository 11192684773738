<app-progress-spinner *ngIf="!showRefPlcAnalysis"></app-progress-spinner>
<div class="container">
     <div class="label-header">
        <label class="label-headercolor">Reference Scenario </label>
    </div>

    <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-alpine" [headerHeight]="refheaderHeight"
        [suppressRowTransform]="true" [getRowStyle]="getRowStyle" [rowClassRules]="refrowClassRules"
        [rowHeight]="refRowHeight" [rowStyle]="rowStyle" [rowData]="refRowData" [columnDefs]="refColumnDefs"
        [defaultColDef]="defaultColDef" [autoGroupColumnDef]="autoGroupRefCostColumnDef"
        [groupDefaultExpanded]="groupDefaultExpanded" [suppressAggFuncInHeader]="true" [enableBrowserTooltips]="true"
        [domLayout]="domLayout" (gridReady)="onrefGridReady($event)" (gridSizeChanged)="onrefGridSizeChanged($event)"
        (rowClicked)="onRowClicked($event)" (displayedColumnsChanged)="onDisplayedColumnsRefChanged($event)">
    </ag-grid-angular>

    <div class="label-header">
        <label class="label-headercolor">Target Scenario </label>
    </div>
    <ag-grid-angular style="width: 100%; height: 100%;"
    class="ag-theme-alpine"
    [headerHeight]="tgtheaderHeight"
    [suppressRowTransform]="true"
    [getRowStyle]="getRowStyle"
    [rowClassRules]="tgtrowClassRules"
    [rowHeight]="tgtRowHeight"
    [rowStyle]="rowStyle"
    [rowData]="tgtRowData"
    [columnDefs]="tgtColumnDefs"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGrouptgtCostColumnDef"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [suppressAggFuncInHeader]="true"
    [enableBrowserTooltips]="true"
    [domLayout]="domLayout"
    (gridReady)="ontgtGridReady($event)"
    (gridSizeChanged)="ontgtGridSizeChanged($event)"

   
>
</ag-grid-angular> 
<div  *ngIf="optAVisible">
<div class="label-header">
    <label class="label-headercolor">Option A </label>
</div>

  <ag-grid-angular style="width: 100%; height: 100%;"
  class="ag-theme-alpine"
  [headerHeight]="optAheaderHeight"
  [suppressRowTransform]="true"
  [getRowStyle]="getRowStyle"
  [rowClassRules]="optArowClassRules"
  [rowHeight]="optARowHeight"
  [rowStyle]="rowStyle"
  [rowData]="optARowData"
  [columnDefs]="optAColumnDefs"
  [defaultColDef]="defaultColDef"
  [autoGroupColumnDef]="autoGroupoptACostColumnDef"
  [groupDefaultExpanded]="groupDefaultExpanded"
  [suppressAggFuncInHeader]="true"
  [enableBrowserTooltips]="true"
  [domLayout]="domLayout"
  (gridReady)="onoptAGridReady($event)"
  (gridSizeChanged)="onoptAGridSizeChanged($event)"

  
>
</ag-grid-angular>
</div>
<div  *ngIf="optBVisible">
<div class="label-header">
    <label class="label-headercolor">Option B </label>
</div>

  <ag-grid-angular style="width: 100%; height: 100%;"
  class="ag-theme-alpine"
  [headerHeight]="optBheaderHeight"
  [suppressRowTransform]="true"
  [getRowStyle]="getRowStyle"
  [rowClassRules]="optBrowClassRules"
  [rowHeight]="optBRowHeight"
  [rowStyle]="rowStyle"
  [rowData]="optBRowData"
  [columnDefs]="optBColumnDefs"
  [defaultColDef]="defaultColDef"
  [autoGroupColumnDef]="autoGroupoptBCostColumnDef"
  [groupDefaultExpanded]="groupDefaultExpanded"
  [suppressAggFuncInHeader]="true"
  [enableBrowserTooltips]="true"
  [domLayout]="domLayout"
  (gridReady)="onoptBGridReady($event)"
  (gridSizeChanged)="onoptBGridSizeChanged($event)"

  (displayedColumnsChanged)="onDisplayedColumnsBChanged($event)"
>

</ag-grid-angular>
</div>
<div  *ngIf="optCVisible">
<div class="label-header">
    <label class="label-headercolor">Option C </label>
</div>

  <ag-grid-angular style="width: 100%; height: 100%;"
  class="ag-theme-alpine"
  [headerHeight]="optCheaderHeight"
  [suppressRowTransform]="true"
  [getRowStyle]="getRowStyle"
  [rowClassRules]="optCrowClassRules"
  [rowHeight]="optCRowHeight"
  [rowStyle]="rowStyle"
  [rowData]="optCRowData"
  [columnDefs]="optCColumnDefs"
  [defaultColDef]="defaultColDef"
  [autoGroupColumnDef]="autoGroupoptCCostColumnDef"
  [groupDefaultExpanded]="groupDefaultExpanded"
  [suppressAggFuncInHeader]="true"
  [enableBrowserTooltips]="true"
  [domLayout]="domLayout"
  (gridReady)="onoptCGridReady($event)"
  (gridSizeChanged)="onoptCGridSizeChanged($event)"

  (displayedColumnsChanged)="onDisplayedColumnsCChanged($event)"
>

</ag-grid-angular>
</div>
    <div class="row">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle">
                <button class="button button--small" (click)="onClickBack()">Back</button>&nbsp;
                <!-- <button class="button button--small" (click)="onClickNext()">Next</button>&nbsp; -->

            </div>
        </div>
    </div>
</div>