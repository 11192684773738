import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { KmatDto } from 'src/app/models/KmatDto';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-configuration-kmat',
  templateUrl: './configuration-kmat.component.html',
  styleUrls: ['./configuration-kmat.component.css']
})
export class ConfigurationKmatComponent implements OnInit {

  public showGrid: boolean = false;
  public rowData: any = [];
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowHeight: any;
  public headerHeight: any;
  public sizeToFitRefresh: number = 0;
  public allKmatData: KmatDto[];
  public selectedRowData: any;
  projectId: any;

  constructor(
    public dialogRef: MatDialogRef<ConfigurationKmatComponent>,
    private router: Router,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private configurationService: ConfigurationService
  ) { }

  ngOnInit(): void {
    this.projectId = this.localStorageService.get("projectId");
    if (this.projectId != null) {
      this.configurationService.getKmatData(this.projectId).subscribe(d => {
        let errorMsgeFromBack = d.Data.Message;
        if (d.StatusCode == 200) {
          this.allKmatData = d.Data;
          this.rowData = this.allKmatData;
        } else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      },
        (error) => {
          //Show errorCode and errorMessage in the UI
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        });
    }
    this.loadGrid();
  }

  loadGrid() {
    this.showGrid = true;
    this.columnDefs = [
      {
        field: "selected",
        checkboxSelection: false,
        cellRenderer: function cellTitle(params) {
          let cellValue = `<div class="ngSelectionCell"><input name="selected" type="radio" style="accent-color: red; height: 2em; width: 18px;"></div>`;
          return cellValue;
        },
      },
      {
        headerName: "KMAT",
        field: "KMAT",
        headerTooltip: "KMAT",
        editable: false,
      },
      {
        headerName: "KMAT Text",
        field: "KMATName",
        headerTooltip: "KMAT Text",
        editable: false,
      },
      {
        headerName: "Plant",
        field: "Plant",
        headerTooltip: "Plant",
        editable: false,
      },
      {
        headerName: "SAP Status",
        field: "Status",
        headerTooltip: "SAP Status",
        editable: false,
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  filterKMAT(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  onCellClicked(event) {
    if (event.column.getColId() == "selected")
      this.selectedRowData = event.node.data;
  }

  onSubmit() {
    let selectedRow = this.selectedRowData;

    if (selectedRow == undefined || selectedRow == null)
      this.openAlertDialog([{ [messages.kmatData]: serverMessage.warning }]);
    else {
      this.dialogRef.close(selectedRow);
    }
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
}
