import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DoublingEditorComponent } from '../non-plc/costsavings/doubling-editor.component';
import { CstargetcostingService } from 'src/app/services/cstargetcosting-service.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { ProjectService } from 'src/app/services/project.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { Cscost } from 'src/app/models/Cscost';

let countryCode;

@Component({
  selector: 'app-tc-cs',
  templateUrl: './tc-cs.component.html',
  styleUrls: ['./tc-cs.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TcCSComponent implements OnInit, OnDestroy {

  showGrid: boolean = false;
  refTitle: string = "";
  tarTitle: string = "";
  optATitle: string = "";
  optBTitle: string = "";
  optCTitle: string = "";
  isEditable: boolean = true;
  public submitted: boolean = false;
  //grid Structure
  refGridApi: any;
  refColumnDefs: any;
  refDefaultColDef: any;
  refGridData: any;
  refPinnedBottomData: any;
  public refFrameworkComponents;
  rowClassRulesRef: any;
  rowClassRulesTar: any;
  rowClassRulesOptA: any;
  rowClassRulesOptB: any;
  rowClassRulesOptC: any;


  tarGridApi: any;
  tarPinnedBottomData: any;
  tarColumnDefs: any;
  tarDefaultColDef: any;
  tarGridData: any;
  public tarFrameworkComponents;
  public tarDomLayout: any;
  public domLayout: any;
  public modifiedRefCSRows = [];
  public modifiedTarCSRows = [];
  public modifiedOptACSRows = [];
  public modifiedOptBCSRows = [];
  public modifiedOptCCSRows = [];
  optAVisible: boolean = false;
  optBVisible: boolean = false;
  optCVisible: boolean = false;
  csMasterData: any;
  projectId: any;
  hasUnsavedChanges: boolean;
  public businessAreaId: number;
  public businessLineId: number;
  public userInOrg: any;
  public allUserList: any;

  optAGridApi: any;
  optAPinnedBottomData: any;
  optAColumnDefs: any;
  optAGridData: any[];
  optADefaultColDef: any;
  optACCRTargetCost: any;
  optACCRActuals: any;
  optACCRForecast: any;
  public optAFrameworkComponents;

  optBGridApi: any;
  optBPinnedBottomData: any;
  optBGridData: any[];
  optBDefaultColDef: any;
  optBColumnDefs: any;
  optBCCRTargetCost: any;
  optBCCRActuals: any;
  optBCCRForecast: any;
  public optBFrameworkComponents;

  optCGridApi: any;
  optCPinnedBottomData: any;
  optCDefaultColDef: any;
  optCGridData: any[];
  optCColumnDefs: any;
  optCCCRTargetCost: any;
  optCCCRActuals: any;
  optCCCRForecast: any;
  public optCFrameworkComponents;

  projectSubmitterId: any;
  submitResponse: any;
  objData: { projectId: any; moduleId: any; };
  moduleId: number = 7;
  refCCRCost: any;
  tarCCRTargetCost: any;
  tarCCRActuals: any;
  tarCCRForecast: any;
  configurationId: any;
  refCCRId: any;
  tarCCRTargetCostId: any;
  tarCCRActualsId: any;
  tarCCRForecastId: any;
  optACCRTargetCostId: any;
  optACCRActualsId: any;
  optACCRForecastId: any;
  optBCCRTargetCostId: any;
  optBCCRActualsId: any;
  optBCCRForecastId: any;
  optCCCRTargetCostId: any;
  optCCCRActualsId: any;
  optCCCRForecastId: any;
  rowHeight: number;
  headerHeight: number;
  configSub: any;

  constructor(
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private router: Router,
    private menuService: MenuService,
    private CstargetcostingService: CstargetcostingService,
    private userService: UserInfoService,
  ) { }

  ngOnInit(): void {
    this.sharedService.getProjectIdAndTitle();

    this.projectId = this.localStorageService.get("projectId");
    this.objData = {
      projectId: this.projectId,
      moduleId: this.moduleId
    }

    countryCode = this.localStorageService.get('countryCode');

    if (this.projectId != null) {
      this.projectService.getTCData(this.projectId).subscribe((data: any) => {
        if (data.StatusCode == 200) {
          this.businessAreaId = data.Data.BusinessAreaId;
          this.businessLineId = data.Data.BusinessLineId;
          this.sharedService.loggedInUser.subscribe((user: any) => {
            if (user) {
              this.showGrid = true
              this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
                this.allUserList = d;
                this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
                if (this.userInOrg != undefined) {
                  console.log("user", this.userInOrg, data.Data);
                  this.projectSubmitterId = this.userInOrg.UserId;

                  if (this.userInOrg.TargetRoleId == 1 || this.userInOrg.TargetRoleId == 2) {
                    this.isEditable = true;
                  }
                  else if (this.userInOrg.TargetRoleId == 3) {
                    if (data.Data.SubmitterId == this.userInOrg.UserId || data.Data.FinancialControllerId == this.userInOrg.UserId
                      || data.Data.ProductManagerId == this.userInOrg.UserId || data.Data.ProjectManagerId == this.userInOrg.UserId ||
                      data.Data.ServiceManagerId == this.userInOrg.UserId || data.Data.ProcurementEngineerId == this.userInOrg.UserId) {
                      this.isEditable = true;
                    }
                    else {
                      this.isEditable = false;
                    }
                  }
                }
                this.loadData();
                // this.loadRefGridStructure();
                // this.loadTarGridStructure();
                // this.loadOptAGridStructure();
                // this.loadOptBGridStructure();
                // this.loadOptCGridStructure();
              })
            }
          })
        }
      });
    } else {
      this.businessAreaId = this.businessLineId = 0;
    }

  }


  addNewRow() {
    if (this.optAVisible == true && this.optBVisible == true) {
      this.optCVisible = true;
    }
    if (this.optAVisible == true) {
      this.optBVisible = true;
    }
    if (this.optBVisible == false) {

      this.optAVisible = true;
    }

  }

  loadData() {
    this.showGrid = false;
    this.configSub = this.sharedService.configurationId.subscribe(d => {
      this.showGrid = false;
      this.configurationId = d
      this.optAVisible = false;
      this.optBVisible = false;
      this.optCVisible = false;
      this.CstargetcostingService.getCsTargetcostingData(this.configurationId).subscribe(d => {
        this.showGrid = true;
        let errorMsgeFromBack = d.Data.Message;
        if (d.StatusCode == 200) {
          this.csMasterData = d.Data;

          if (this.csMasterData[0].OptionA) this.optAVisible = true
          if (this.csMasterData[0].OptionB) this.optBVisible = true
          if (this.csMasterData[0].OptionC) this.optCVisible = true

          this.refTitle = this.csMasterData[0].ReferenceScenario.ScenarioTitle ?? ""
          this.tarTitle = this.csMasterData[0].TargetScenario.ScenarioTitle ?? ""
          this.optATitle = this.csMasterData[0].OptionA?.ScenarioTitle ?? ""
          this.optBTitle = this.csMasterData[0].OptionB?.ScenarioTitle ?? ""
          this.optCTitle = this.csMasterData[0].OptionC?.ScenarioTitle ?? ""

          this.refGridData = [];
          this.tarGridData = [];
          this.optAGridData = [];
          this.optBGridData = [];
          this.optCGridData = [];
          for (let i of this.csMasterData) {
            i["IsEditable"] = this.isEditable;
            if (i.Service == "CCR") {
              this.refCCRCost = i.ReferenceScenario.Cost;
              this.refCCRId = i.ReferenceScenario.CSId;

              this.tarCCRTargetCost = i.TargetScenario.TargetCost?.Cost ?? null;
              this.tarCCRActuals = i.TargetScenario.Actuals?.Cost ?? null;
              this.tarCCRForecast = i.TargetScenario.ForeCast?.Cost ?? null;

              this.optACCRTargetCost = i.OptionA?.TargetCost?.Cost ?? null;
              this.optACCRActuals = i.OptionA?.Actuals?.Cost ?? null;
              this.optACCRForecast = i.OptionA?.ForeCast?.Cost ?? null;

              this.optBCCRTargetCost = i.OptionB?.TargetCost?.Cost ?? null;
              this.optBCCRActuals = i.OptionB?.Actuals?.Cost ?? null;
              this.optBCCRForecast = i.OptionB?.ForeCast?.Cost ?? null;

              this.optCCCRTargetCost = i.OptionC?.TargetCost?.Cost ?? null;
              this.optCCCRActuals = i.OptionC?.Actuals?.Cost ?? null;
              this.optCCCRForecast = i.OptionC?.ForeCast?.Cost ?? null;

              this.tarCCRTargetCostId = i.TargetScenario.TargetCost?.CSId ?? null;
              this.tarCCRActualsId = i.TargetScenario.Actuals?.CSId ?? null;
              this.tarCCRForecastId = i.TargetScenario.ForeCast?.CSId ?? null;

              this.optACCRTargetCostId = i.OptionA?.TargetCost?.CSId ?? null;
              this.optACCRActualsId = i.OptionA?.Actuals?.CSId ?? null;
              this.optACCRForecastId = i.OptionA?.ForeCast?.CSId ?? null;

              this.optBCCRTargetCostId = i.OptionB?.TargetCost?.CSId ?? null;
              this.optBCCRActualsId = i.OptionB?.Actuals?.CSId ?? null;
              this.optBCCRForecastId = i.OptionB?.ForeCast?.CSId ?? null;

              this.optCCCRTargetCostId = i.OptionC?.TargetCost?.CSId ?? null;
              this.optCCCRActualsId = i.OptionC?.Actuals?.CSId ?? null;
              this.optCCCRForecastId = i.OptionC?.ForeCast?.CSId ?? null;
            }
            if (i.Service != "Total Service costs" && i.Service != "CCR") {
              this.refGridData.push({
                CSId: i.CSId,
                ConfigurationId: i.ConfigurationId,
                Service: i.Service,
                Costs: i.ReferenceScenario.Cost,
                IsActive: i.IsActive,
                CreatedBy: i.CreatedBy,
                CreatedOn: i.CreatedOn,
                ModifiedBy: i.ModifiedBy,
                ModifiedOn: i.ModifiedOn,
                IsEditable: this.isEditable,
              })

              this.tarGridData.push({
                CSIdTargetCost: i.TargetScenario.TargetCost?.CSId ?? 0,
                CSIdForecast: i.TargetScenario.ForeCast?.CSId ?? 0,
                CSIdActuals: i.TargetScenario.Actuals?.CSId ?? 0,
                ConfigurationId: i.ConfigurationId,
                Service: i.Service,
                ReferenceCost: i.ReferenceScenario.Cost,
                TargetCosts: i.TargetScenario.TargetCost?.Cost ?? null,
                Forecast: i.TargetScenario.ForeCast?.Cost ?? null,
                Actuals: i.TargetScenario.Actuals?.Cost ?? null,
                DeltaTCVsRef: i.TargetScenario.TargetCost?.DeltaTCvsRefrence ?? null,
                DeltaFCVsRef: i.TargetScenario.ForeCast?.DeltaFCvsRefrence ?? null,
                DeltaACTVsRef: i.TargetScenario.Actuals?.DeltaTCvsAct ?? null,
                IsActive: i.IsActive,
                CreatedBy: i.CreatedBy,
                CreatedOn: i.CreatedOn,
                ModifiedBy: i.ModifiedBy,
                ModifiedOn: i.ModifiedOn,
                IsEditable: this.isEditable,
              })

              this.optAGridData.push({
                CSIdTargetCost: i.OptionA?.TargetCost?.CSId ?? 0,
                CSIdForecast: i.OptionA?.ForeCast?.CSId ?? 0,
                CSIdActuals: i.OptionA?.Actuals?.CSId ?? 0,
                ConfigurationId: i.ConfigurationId,
                Service: i.Service,
                ReferenceCost: i.ReferenceScenario.Cost,
                TargetCosts: i.OptionA?.TargetCost?.Cost ?? null,
                Forecast: i.OptionA?.ForeCast?.Cost ?? null,
                Actuals: i.OptionA?.Actuals?.Cost ?? null,
                DeltaTCVsRef: i.OptionA?.TargetCost?.DeltaTCvsRefrence ?? null,
                DeltaFCVsRef: i.OptionA?.ForeCast?.DeltaFCvsRefrence ?? null,
                DeltaACTVsRef: i.OptionA?.Actuals?.DeltaTCvsAct ?? null,
                IsActive: i.IsActive,
                CreatedBy: i.CreatedBy,
                CreatedOn: i.CreatedOn,
                ModifiedBy: i.ModifiedBy,
                ModifiedOn: i.ModifiedOn,
                IsEditable: this.isEditable,
              })

              this.optBGridData.push({
                CSIdTargetCost: i.OptionB?.TargetCost?.CSId ?? 0,
                CSIdForecast: i.OptionB?.ForeCast?.CSId ?? 0,
                CSIdActuals: i.OptionB?.Actuals?.CSId ?? 0,
                ConfigurationId: i.ConfigurationId,
                Service: i.Service,
                ReferenceCost: i.ReferenceScenario.Cost,
                TargetCosts: i.OptionB?.TargetCost?.Cost ?? null,
                Forecast: i.OptionB?.ForeCast?.Cost ?? null,
                Actuals: i.OptionB?.Actuals?.Cost ?? null,
                DeltaTCVsRef: i.OptionB?.TargetCost?.DeltaTCvsRefrence ?? null,
                DeltaFCVsRef: i.OptionB?.ForeCast?.DeltaFCvsRefrence ?? null,
                DeltaACTVsRef: i.OptionB?.Actuals?.DeltaTCvsAct ?? null,
                IsActive: i.IsActive,
                CreatedBy: i.CreatedBy,
                CreatedOn: i.CreatedOn,
                ModifiedBy: i.ModifiedBy,
                ModifiedOn: i.ModifiedOn,
                IsEditable: this.isEditable,
              })

              this.optCGridData.push({
                CSIdTargetCost: i.OptionC?.TargetCost?.CSId ?? 0,
                CSIdForecast: i.OptionC?.ForeCast?.CSId ?? 0,
                CSIdActuals: i.OptionC?.Actuals?.CSId ?? 0,
                ConfigurationId: i.ConfigurationId,
                Service: i.Service,
                ReferenceCost: i.ReferenceScenario.Cost,
                TargetCosts: i.OptionC?.TargetCost?.Cost ?? null,
                Forecast: i.OptionC?.ForeCast?.Cost ?? null,
                Actuals: i.OptionC?.Actuals?.Cost ?? null,
                DeltaTCVsRef: i.OptionC?.TargetCost?.DeltaTCvsRefrence ?? null,
                DeltaFCVsRef: i.OptionC?.ForeCast?.DeltaFCvsRefrence ?? null,
                DeltaACTVsRef: i.OptionC?.Actuals?.DeltaTCvsAct ?? null,
                IsActive: i.IsActive,
                CreatedBy: i.CreatedBy,
                CreatedOn: i.CreatedOn,
                ModifiedBy: i.ModifiedBy,
                ModifiedOn: i.ModifiedOn,
                IsEditable: this.isEditable,
              })
            }
          }

          this.loadRefGridStructure();
          this.loadTarGridStructure();
          this.loadOptAGridStructure();
          this.loadOptBGridStructure();
          this.loadOptCGridStructure();
        } else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      });
    })
  }

  loadRefGridStructure() {
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.Service;
        return data === 'Total Service Costs' || data === 'CCR';
      }
    }

    this.refColumnDefs = [
      {
        headerName: "Service",
        field: "Service",
        tooltipField: "service",
        width: 250,
        headerClass: "right-border",
        cellClass: "right-border",
        headerTooltip: "Service",
        editable: false
      },
      {
        headerName: "Costs",
        field: "Costs",
        tooltipField: "Costs",
        width: 130,
        headerTooltip: "Costs",
        tooltipValueGetter: CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.Service === 'Total Service Costs') {
            let total = 0;

            if (params.api != null) {
              params.api.forEachNode((node) => {

                total += Number(node.data.Costs)
              })
              params.data.Costs = total;
              return total;
            }
          } else {
            return (params.data.Costs);
          }
        },
        cellEditor: 'doublingEditor',
        editable: (params) => {
          if (params.node.data.Service === 'Total Service Costs') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        },
        cellRenderer: CurrencyCellRenderer,
        singleClickEdit: true,
      }
    ];

    this.refDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.domLayout = 'autoHeight';
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.refFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onRefGridReady(params: any) {
    this.refGridApi = params.api;
    this.refGridApi.setHeaderHeight(77.667);
    this.refPinnedBottomData = createDataRef(this.totalRef(), this.isEditable, this.refCCRCost, this.refCCRId);
    params.api.setPinnedBottomRowData(this.refPinnedBottomData);
  }

  onGridRefCSChanged(params) {
    this.refGridApi = params.api;
    this.refGridApi.sizeColumnsToFit();
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedRef(params) {
    params.api.resetRowHeights();
  }

  onColumnVisibleRef(params) {
    params.api.resetRowHeights();
  }

  onRefCellValueChangedCS(params) {

    if (params.data.Service != "CCR") {
      let tar = this.tarGridData.find(x => x.Service == params.data.Service)
      tar.ReferenceCost = params.data.Costs
      this.tarGridApi.redrawRows();

      let optA = this.optAGridData.find(x => x.Service == params.data.Service)
      optA.ReferenceCost = params.data.Costs
      if (this.optAGridApi != undefined) this.optAGridApi.redrawRows();

      let optB = this.optBGridData.find(x => x.Service == params.data.Service)
      optB.ReferenceCost = params.data.Costs
      if (this.optBGridApi != undefined) this.optBGridApi.redrawRows();

      let optC = this.optCGridData.find(x => x.Service == params.data.Service)
      optC.ReferenceCost = params.data.Costs
      if (this.optCGridApi != undefined) this.optCGridApi.redrawRows();
    }

    this.hasUnsavedChanges = true;
    //   if (params.data.configurationId      != 0) {
    let isPushed = false;
    if (this.modifiedRefCSRows.length == 0) {
      this.modifiedRefCSRows.push(params.data);
      isPushed = true;
    }
    else {
      this.modifiedRefCSRows.forEach((row, index) => {
        if (params.data.ConfigurationId
          == row.ConfigurationId && params.data.CSId == row.CSId && params.data.Service == row.Service
        ) {
          // row = params.data;
          // this.modifiedCSRows[index] = params.data;
          isPushed = true;
        }
      })
    }
    if (!isPushed) {
      this.modifiedRefCSRows.push(params.data);
    }
    // }
    console.log(this.modifiedRefCSRows);
  }

  // calculate total for reference grid for bottom "total row"
  totalRef() {
    let costs = 0
    this.refGridApi.forEachNode(function (node) {
      costs = Number(node.data.Costs) + costs
    })
    return costs;
  }

  loadTarGridStructure() {
    this.rowClassRulesTar = {
      'colorTotal': function (params) {
        let data = params.data.Service;
        return data === 'Total Service Costs' || data === 'CCR';
      }
    }

    this.tarColumnDefs = [
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "right-border",
        children: [
          {
            headerName: "Target Costs",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetCosts",
            tooltipField: "TargetCosts",
            headerTooltip: "Target Costs",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.TargetCosts)
                  })
                  params.data.TargetCosts = total;
                  return total;
                }
              } else {
                return (params.data.TargetCosts);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. Reference",
            headerClass: "right-border",
            cellClass: (params) => {
              if (params.value > 0) {
                return "right-border colorTotal positive-num-color-cs"
              } else {
                return "right-border colorTotal negative-num-color-cs"
              }
            },
            field: "DeltaTCVsRef",
            tooltipField: "DeltaTCVsRef",
            headerTooltip: "Delta TC vs. Reference",
            width: 100,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {

                if (params.node.data.TargetCosts == null || Number(params.node.data.TargetCosts) == 0) {
                  return null
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetCosts) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef)/Number(params.node.data.ReferenceCost))*100
                }

                return params.node.data.DeltaTCVsRef;
              } else {
                return null
              }
            }
          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "right-border",
        children: [
          {
            headerName: "Forecast",
            field: "Forecast",
            tooltipField: "Forecast",
            headerTooltip: "Forecast",
            headerClass: "right-border",
            cellClass: "right-border",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.Forecast)
                  })
                  params.data.Forecast = total;
                  return total;
                }
              } else {
                return (params.data.Forecast);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta FC vs. Reference",
            field: "DeltaFCVsRef",
            headerClass: "right-border",
            cellClass: (params) => {
              if (params.value > 0)
                return "right-border colorTotal positive-num-color-cs"
              else
                return "right-border colorTotal negative-num-color-cs"
            },
            tooltipField: "DeltaFCVsRef",
            headerTooltip: "Delta FC vs. Reference",
            width: 100,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {
                if (params.node.data.Forecast == null || Number(params.node.data.Forecast) == 0) {
                  return null
                } else {
                  params.node.data.DeltaFCVsRef = Number(params.node.data.Forecast) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaFCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaFCVsRef)/Number(params.node.data.ReferenceCost))*100
                }
                return params.node.data.DeltaFCVsRef;
              } else {
                return null
              }
            }
          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        children: [
          {
            headerName: "Actuals",
            field: "Actuals",
            tooltipField: "Actuals",
            headerClass: "right-border",
            cellClass: "right-border",
            headerTooltip: "Actuals",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.Actuals)
                  })
                  params.data.Actuals = total;
                  return total;
                }
              } else {
                return (params.data.Actuals);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta ACT vs. Reference",
            field: "DeltaACTVsRef",
            tooltipField: "DeltaACTVsRef",
            headerTooltip: "Delta ACT vs. Reference",
            width: 100,
            editable: false,
            cellClass: (params) => {
              if (params.value > 0)
                return "colorTotal positive-num-color-cs"
              else
                return "colorTotal negative-num-color-cs"
            },

            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {
                if (params.node.data.Actuals == null || Number(params.node.data.Actuals) == 0) {
                  return null
                } else {
                  params.node.data.DeltaACTVsRef = Number(params.node.data.Actuals) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaACTVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaACTVsRef)/Number(params.node.data.ReferenceCost))*100
                }
                return params.node.data.DeltaACTVsRef;
              } else {
                return null
              }
            }
          }
        ]
      }
    ];

    this.tarDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.tarFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onTarGridReady(params: any) {
    this.tarGridApi = params.api;
    this.tarGridApi.setGroupHeaderHeight(30);

    this.tarPinnedBottomData = createDataTar(this.totalTar(), this.isEditable, this.tarCCRTargetCost, this.tarCCRForecast, this.tarCCRActuals, this.tarCCRTargetCostId, this.tarCCRForecastId, this.tarCCRActualsId);
    params.api.setPinnedBottomRowData(this.tarPinnedBottomData);
  }

  onGridTarCSChanged(params) {
    params.api.sizeColumnsToFit();
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedTar(params) {
    params.api.resetRowHeights();
  }
  onColumnVisibleTar(params) {
    params.api.resetRowHeights();
  }

  onTarCellValueChangedCS(params) {
    this.tarGridApi.redrawRows();
    this.hasUnsavedChanges = true;
    //   if (params.data.configurationId      != 0) {
    let isPushed = false;
    if (this.modifiedTarCSRows.length == 0) {
      this.modifiedTarCSRows.push(params.data);
      isPushed = true;
    }
    else {
      this.modifiedTarCSRows.forEach((row, index) => {
        if (params.data.ConfigurationId
          == row.ConfigurationId && params.data.Service == row.Service
        ) {
          // row = params.data;
          // this.modifiedCSRows[index] = params.data;
          isPushed = true;
        }
      })
    }
    if (!isPushed) {
      this.modifiedTarCSRows.push(params.data);
    }
    // }
    console.log(this.modifiedTarCSRows);
  }

  // calculate total for target grid for bottom "total row"
  totalTar() {
    let targetCost = 0;
    let actuals = 0;
    let forecast = 0;
    this.tarGridApi.forEachNode(function (node) {
      targetCost = Number(node.data.TargetCosts) + targetCost
      actuals = Number(node.data.Actuals) + actuals
      forecast = Number(node.data.Forecast) + forecast
    })
    return [targetCost, actuals, forecast];
  }

  loadOptAGridStructure() {
    this.rowClassRulesOptA = {
      'colorTotal': function (params) {
        let data = params.data.Service;
        return data === 'Total Service Costs' || data === 'CCR';
      }
    }

    this.optAColumnDefs = [
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "right-border",
        children: [
          {
            headerName: "Target Costs",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetCosts",
            tooltipField: "TargetCosts",
            headerTooltip: "Target Costs",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.TargetCosts)
                  })
                  params.data.TargetCosts = total;
                  return total;
                }
              } else {
                return (params.data.TargetCosts);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. Reference",
            headerClass: "right-border",
            cellClass: (params) => {
              if (params.value > 0)
                return "right-border colorTotal positive-num-color-cs"
              else
                return "right-border colorTotal negative-num-color-cs"
            },
            field: "DeltaTCVsRef",
            tooltipField: "DeltaTCVsRef",
            headerTooltip: "Delta TC vs. Reference",
            width: 100,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {

                if (params.node.data.TargetCosts == null || Number(params.node.data.TargetCosts) == 0) {
                  return null
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetCosts) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef)/Number(params.node.data.ReferenceCost))*100
                }

                return params.node.data.DeltaTCVsRef;
              } else {
                return null
              }
            }
          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "right-border",
        children: [
          {
            headerName: "Forecast",
            field: "Forecast",
            tooltipField: "Forecast",
            headerTooltip: "Forecast",
            headerClass: "right-border",
            cellClass: "right-border",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.Forecast)
                  })
                  params.data.Forecast = total;
                  return total;
                }
              } else {
                return (params.data.Forecast);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta FC vs. Reference",
            field: "DeltaFCVsRef",
            headerClass: "right-border",
            cellClass: (params) => {
              if (params.value > 0)
                return "right-border colorTotal positive-num-color-cs"
              else
                return "right-border colorTotal negative-num-color-cs"
            },
            tooltipField: "DeltaFCVsRef",
            headerTooltip: "Delta FC vs. Reference",
            width: 100,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {
                if (params.node.data.Forecast == null || Number(params.node.data.Forecast) == 0) {
                  return null
                } else {
                  params.node.data.DeltaFCVsRef = Number(params.node.data.Forecast) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaFCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaFCVsRef)/Number(params.node.data.ReferenceCost))*100
                }
                return params.node.data.DeltaFCVsRef;
              } else {
                return null
              }
            }
          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        children: [
          {
            headerName: "Actuals",
            field: "Actuals",
            tooltipField: "Actuals",
            headerClass: "right-border",
            cellClass: "right-border",
            headerTooltip: "Actuals",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.Actuals)
                  })
                  params.data.Actuals = total;
                  return total;
                }
              } else {
                return (params.data.Actuals);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta ACT vs. Reference",
            field: "DeltaACTVsRef",
            tooltipField: "DeltaACTVsRef",
            headerTooltip: "Delta ACT vs. Reference",
            width: 100,
            editable: false,
            cellClass: (params) => {
              if (params.value > 0)
                return "colorTotal positive-num-color-cs"
              else
                return "colorTotal negative-num-color-cs"
            },
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {
                if (params.node.data.Actuals == null || Number(params.node.data.Actuals) == 0) {
                  return null
                } else {
                  params.node.data.DeltaACTVsRef = Number(params.node.data.Actuals) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaACTVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaACTVsRef)/Number(params.node.data.ReferenceCost))*100
                }
                return params.node.data.DeltaACTVsRef;
              } else {
                return null
              }
            }
          }
        ]
      }
    ];

    this.optADefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.optAFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onOptAGridReady(params: any) {
    this.optAGridApi = params.api;
    this.optAGridApi.setGroupHeaderHeight(30);

    this.optAPinnedBottomData = createDataOptA(this.totalOptA(), this.isEditable, this.optACCRTargetCost, this.optACCRForecast, this.optACCRActuals, this.optACCRTargetCostId, this.optACCRForecastId, this.optACCRActualsId);
    params.api.setPinnedBottomRowData(this.optAPinnedBottomData);
  }

  onGridOptACSChanged(params) {
    params.api.sizeColumnsToFit();
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptA(params) {
    params.api.resetRowHeights();
  }
  onColumnVisibleOptA(params) {
    params.api.resetRowHeights();
  }

  onOptACellValueChangedCS(params) {
    this.optAGridApi.redrawRows();
    this.hasUnsavedChanges = true;
    let isPushed = false;
    if (this.modifiedOptACSRows.length == 0) {
      this.modifiedOptACSRows.push(params.data);
      isPushed = true;
    }
    else {
      this.modifiedOptACSRows.forEach((row, index) => {
        if (params.data.ConfigurationId
          == row.ConfigurationId && params.data.CSId == row.CSId && params.data.Service == row.Service
        ) {
          isPushed = true;
        }
      })
    }
    if (!isPushed) {
      this.modifiedOptACSRows.push(params.data);
    }
  }

  // calculate total for option A grid for bottom "total row"
  totalOptA() {
    let targetCost = 0;
    let actuals = 0;
    let forecast = 0;
    this.optAGridApi.forEachNode(function (node) {
      targetCost = Number(node.data.TargetCosts) + targetCost
      actuals = Number(node.data.Actuals) + actuals
      forecast = Number(node.data.Forecast) + forecast
    })
    return [targetCost, actuals, forecast];
  }


  loadOptBGridStructure() {
    this.rowClassRulesOptB = {
      'colorTotal': function (params) {
        let data = params.data.Service;
        return data === 'Total Service Costs' || data === 'CCR';
      }
    }

    this.optBColumnDefs = [
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "right-border",
        children: [
          {
            headerName: "Target Costs",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetCosts",
            tooltipField: "TargetCosts",
            headerTooltip: "Target Costs",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.TargetCosts)
                  })
                  params.data.TargetCosts = total;
                  return total;
                }
              } else {
                return (params.data.TargetCosts);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. Reference",
            headerClass: "right-border",
            cellClass: (params) => {
              if (params.value > 0)
                return "right-border colorTotal positive-num-color-cs"
              else
                return "right-border colorTotal negative-num-color-cs"
            },
            field: "DeltaTCVsRef",
            tooltipField: "DeltaTCVsRef",
            headerTooltip: "Delta TC vs. Reference",
            width: 100,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {

                if (params.node.data.TargetCosts == null || Number(params.node.data.TargetCosts) == 0) {
                  return null
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetCosts) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef)/Number(params.node.data.ReferenceCost))*100
                }

                return params.node.data.DeltaTCVsRef;
              } else {
                return null
              }
            }
          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "right-border",
        children: [
          {
            headerName: "Forecast",
            field: "Forecast",
            tooltipField: "Forecast",
            headerTooltip: "Forecast",
            headerClass: "right-border",
            cellClass: "right-border",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.Forecast)
                  })
                  params.data.Forecast = total;
                  return total;
                }
              } else {
                return (params.data.Forecast);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta FC vs. Reference",
            field: "DeltaFCVsRef",
            headerClass: "right-border",
            cellClass: (params) => {
              if (params.value > 0)
                return "right-border colorTotal positive-num-color-cs"
              else
                return "right-border colorTotal negative-num-color-cs"
            },
            tooltipField: "DeltaFCVsRef",
            headerTooltip: "Delta FC vs. Reference",
            width: 100,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {
                if (params.node.data.Forecast == null || Number(params.node.data.Forecast) == 0) {
                  return null
                } else {
                  params.node.data.DeltaFCVsRef = Number(params.node.data.Forecast) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaFCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaFCVsRef)/Number(params.node.data.ReferenceCost))*100
                }
                return params.node.data.DeltaFCVsRef;
              } else {
                return null
              }
            }
          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        children: [
          {
            headerName: "Actuals",
            field: "Actuals",
            tooltipField: "Actuals",
            headerClass: "right-border",
            cellClass: "right-border",
            headerTooltip: "Actuals",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.Actuals)
                  })
                  params.data.Actuals = total;
                  return total;
                }
              } else {
                return (params.data.Actuals);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta ACT vs. Reference",
            field: "DeltaACTVsRef",
            tooltipField: "DeltaACTVsRef",
            headerTooltip: "Delta ACT vs. Reference",
            width: 100,
            editable: false,
            cellClass: (params) => {
              if (params.value > 0)
                return "colorTotal positive-num-color-cs"
              else
                return "colorTotal negative-num-color-cs"
            },
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {
                if (params.node.data.Actuals == null || Number(params.node.data.Actuals) == 0) {
                  return null
                } else {
                  params.node.data.DeltaACTVsRef = Number(params.node.data.Actuals) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaACTVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaACTVsRef)/Number(params.node.data.ReferenceCost))*100
                }
                return params.node.data.DeltaACTVsRef;
              } else {
                return null
              }
            }
          }
        ]
      }
    ];

    this.optBDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.optBFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onOptBGridReady(params: any) {
    this.optBGridApi = params.api;
    this.optBGridApi.setGroupHeaderHeight(30);

    this.optBPinnedBottomData = createDataOptB(this.totalOptB(), this.isEditable, this.optBCCRTargetCost, this.optBCCRForecast, this.optBCCRActuals, this.optBCCRTargetCostId, this.optBCCRForecastId, this.optBCCRActualsId);
    params.api.setPinnedBottomRowData(this.optBPinnedBottomData);
  }

  onGridOptBCSChanged(params) {
    params.api.sizeColumnsToFit();
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptB(params) {
    params.api.resetRowHeights();
  }
  onColumnVisibleOptB(params) {
    params.api.resetRowHeights();
  }

  onOptBCellValueChangedCS(params) {
    this.optBGridApi.redrawRows();
    this.hasUnsavedChanges = true;
    let isPushed = false;
    if (this.modifiedOptBCSRows.length == 0) {
      this.modifiedOptBCSRows.push(params.data);
      isPushed = true;
    }
    else {
      this.modifiedOptBCSRows.forEach((row, index) => {
        if (params.data.ConfigurationId
          == row.ConfigurationId && params.data.CSId == row.CSId && params.data.Service == row.Service
        ) {
          isPushed = true;
        }
      })
    }
    if (!isPushed) {
      this.modifiedOptBCSRows.push(params.data);
    }
  }

  // calculate total for option B grid for bottom "total row"
  totalOptB() {
    let targetCost = 0;
    let actuals = 0;
    let forecast = 0;
    this.optBGridApi.forEachNode(function (node) {
      targetCost = Number(node.data.TargetCosts) + targetCost
      actuals = Number(node.data.Actuals) + actuals
      forecast = Number(node.data.Forecast) + forecast
    })
    return [targetCost, actuals, forecast];
  }

  loadOptCGridStructure() {
    this.rowClassRulesOptC = {
      'colorTotal': function (params) {
        let data = params.data.Service;
        return data === 'Total Service Costs' || data === 'CCR';
      }
    }

    this.optCColumnDefs = [
      {
        headerName: "Target Costs",
        headerTooltip: "Target Costs",
        headerClass: "right-border",
        children: [
          {
            headerName: "Target Costs",
            headerClass: "right-border",
            cellClass: "right-border",
            field: "TargetCosts",
            tooltipField: "TargetCosts",
            headerTooltip: "Target Costs",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.TargetCosts)
                  })
                  params.data.TargetCosts = total;
                  return total;
                }
              } else {
                return (params.data.TargetCosts);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta TC vs. Reference",
            headerClass: "right-border",
            cellClass: (params) => {
              if (params.value > 0)
                return "right-border colorTotal positive-num-color-cs"
              else
                return "right-border colorTotal negative-num-color-cs"
            },
            field: "DeltaTCVsRef",
            tooltipField: "DeltaTCVsRef",
            headerTooltip: "Delta TC vs. Reference",
            width: 100,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {

                if (params.node.data.TargetCosts == null || Number(params.node.data.TargetCosts) == 0) {
                  return null
                } else {
                  params.node.data.DeltaTCVsRef = Number(params.node.data.TargetCosts) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaTCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaTCVsRef)/Number(params.node.data.ReferenceCost))*100
                }

                return params.node.data.DeltaTCVsRef;
              } else {
                return null
              }
            }
          }
        ]
      },
      {
        headerName: "Forecast",
        headerTooltip: "Forecast",
        headerClass: "right-border",
        children: [
          {
            headerName: "Forecast",
            field: "Forecast",
            tooltipField: "Forecast",
            headerTooltip: "Forecast",
            headerClass: "right-border",
            cellClass: "right-border",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.Forecast)
                  })
                  params.data.Forecast = total;
                  return total;
                }
              } else {
                return (params.data.Forecast);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta FC vs. Reference",
            field: "DeltaFCVsRef",
            headerClass: "right-border",
            cellClass: (params) => {
              if (params.value > 0)
                return "right-border colorTotal positive-num-color-cs"
              else
                return "right-border colorTotal negative-num-color-cs"
            },
            tooltipField: "DeltaFCVsRef",
            headerTooltip: "Delta FC vs. Reference",
            width: 100,
            editable: false,
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {
                if (params.node.data.Forecast == null || Number(params.node.data.Forecast) == 0) {
                  return null
                } else {
                  params.node.data.DeltaFCVsRef = Number(params.node.data.Forecast) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaFCVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaFCVsRef)/Number(params.node.data.ReferenceCost))*100
                }
                return params.node.data.DeltaFCVsRef;
              } else {
                return null
              }
            }
          }
        ]
      },
      {
        headerName: "Actuals",
        headerTooltip: "Actuals",
        children: [
          {
            headerName: "Actuals",
            field: "Actuals",
            tooltipField: "Actuals",
            headerClass: "right-border",
            cellClass: "right-border",
            headerTooltip: "Actuals",
            width: 100,
            tooltipValueGetter: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service === 'Total Service Costs') {
                let total = 0;

                if (params.api != null) {
                  params.api.forEachNode((node) => {
                    total += Number(node.data.Actuals)
                  })
                  params.data.Actuals = total;
                  return total;
                }
              } else {
                return (params.data.Actuals);
              }
            },
            cellEditor: 'doublingEditor',
            editable: (params) => {
              if (params.node.data.Service === 'Total Service Costs') {
                return false;
              }
              else {
                return params.data.IsEditable;
              }
            },
            cellRenderer: CurrencyCellRenderer,
            singleClickEdit: true,
          },
          {
            headerName: "Delta ACT vs. Reference",
            field: "DeltaACTVsRef",
            tooltipField: "DeltaACTVsRef",
            headerTooltip: "Delta ACT vs. Reference",
            width: 100,
            editable: false,
            cellClass: (params) => {
              if (params.value > 0)
                return "colorTotal positive-num-color-cs"
              else
                return "colorTotal negative-num-color-cs"
            },
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer,
            valueGetter: function (params) {
              if (params.node.data.Service != 'Total Service Costs' && params.node.data.Service != 'CCR') {
                if (params.node.data.Actuals == null || Number(params.node.data.Actuals) == 0) {
                  return null
                } else {
                  params.node.data.DeltaACTVsRef = Number(params.node.data.Actuals) - Number(params.node.data.ReferenceCost)
                  params.node.data.DeltaACTVsRef = (Number(params.node.data.ReferenceCost) == 0) ? 0 : (Number(params.node.data.DeltaACTVsRef)/Number(params.node.data.ReferenceCost))*100
                }
                return params.node.data.DeltaACTVsRef;
              } else {
                return null
              }
            }
          }
        ]
      }
    ];

    this.optCDefaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
    }
    this.rowHeight = 25;
    this.headerHeight = 48;
    this.optCFrameworkComponents = {
      doublingEditor: DoublingEditorComponent,
    };
  }

  onOptCGridReady(params: any) {
    this.optCGridApi = params.api;
    this.optCGridApi.setGroupHeaderHeight(30);

    this.optCPinnedBottomData = createDataOptC(this.totalOptC(), this.isEditable, this.optCCCRTargetCost, this.optCCCRForecast, this.optCCCRActuals, this.optCCCRTargetCostId, this.optCCCRForecastId, this.optCCCRActualsId);
    params.api.setPinnedBottomRowData(this.optCPinnedBottomData);
  }

  onGridOptCCSChanged(params) {
    params.api.sizeColumnsToFit();
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResizedOptC(params) {
    params.api.resetRowHeights();
  }
  onColumnVisibleOptC(params) {
    params.api.resetRowHeights();
  }

  onOptCCellValueChangedCS(params) {
    this.optCGridApi.redrawRows();
    this.hasUnsavedChanges = true;
    let isPushed = false;
    if (this.modifiedOptCCSRows.length == 0) {
      this.modifiedOptCCSRows.push(params.data);
      isPushed = true;
    }
    else {
      this.modifiedOptCCSRows.forEach((row, index) => {
        if (params.data.ConfigurationId
          == row.ConfigurationId && params.data.CSId == row.CSId && params.data.Service == row.Service
        ) {
          isPushed = true;
        }
      })
    }
    if (!isPushed) {
      this.modifiedOptCCSRows.push(params.data);
    }
  }

  // calculate total for option A grid for bottom "total row"
  totalOptC() {
    let targetCost = 0;
    let actuals = 0;
    let forecast = 0;
    this.optCGridApi.forEachNode(function (node) {
      targetCost = Number(node.data.TargetCosts) + targetCost
      actuals = Number(node.data.Actuals) + actuals
      forecast = Number(node.data.Forecast) + forecast
    })
    return [targetCost, actuals, forecast];
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(["/tc-manageProject"]);
  }

  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }

  onClickNext() {
    this.menuService.onClickNextAndBack("next");
  }

  submit(event?: any) {
    if (!this.hasUnsavedChanges && event == undefined) {
      return true
    }
    let response = null;
    this.submitted = true;

    let sendData = this.sendData();
    response = this.CstargetcostingService.InsertupdateCScost(sendData).toPromise().
      then((res) => {
        this.submitResponse = res;
        let successCode = this.submitResponse.StatusCode;
        let errorCode = this.submitResponse.Data.ErrorCode;
        let errorMsgeFromBack = this.submitResponse.Data.Message;
        if (successCode == 200) {
          if (errorCode == 0) {
            this.hasUnsavedChanges = false;
            let dataSaveMessage = serverMessage.dataSaveMessage;
            this.toast.notify(dataSaveMessage, "success");
            if (event != undefined) {
              //  this.localStorageService.remove("configuration");
              this.hasUnsavedChanges = false;
              this.router.navigate(['/tc-manageProject'])
            }
            return true;
          }
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }
        }
        else {
          let errorContainer = [
            { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
          this.submitted = false;
          return false;
        }
      },
        (error) => {
          this.submitted = false;
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          return false;
        })

    return response;

  }
  sendData() {
    let refObj: Cscost[] = this.csMasterData;

    refObj.forEach(cs => {
      cs.IsActive = true;
      cs.ReferenceScenario.ScenarioTitle = this.refTitle;
      cs.TargetScenario.ScenarioTitle = this.tarTitle;
      if (cs.OptionA != null) cs.OptionA.ScenarioTitle = this.optATitle;
      if (cs.OptionB != null) cs.OptionB.ScenarioTitle = this.optBTitle;
      if (cs.OptionC != null) cs.OptionC.ScenarioTitle = this.optCTitle;

      if (cs.CSId == 0) {
        cs.CreatedBy = this.userInOrg.UserId;
        cs.CreatedOn = new Date();
      } else {
        cs.ModifiedBy = this.userInOrg.UserId;
        cs.ModifiedOn = new Date();
      }

      let referenceRow = this.modifiedRefCSRows.find(x => x.Service == cs.Service);
      if (referenceRow) {
        cs.ReferenceScenario.CSId = referenceRow.CSId;
        cs.ReferenceScenario.Cost = Number(referenceRow.Costs);
      }

      let targetRow = this.modifiedTarCSRows.find(x => x.Service == cs.Service);
      if (targetRow) {
        cs.TargetScenario.TargetCost = {
          CSId: targetRow.CSIdTargetCost,
          Cost: Number(targetRow.TargetCosts),
          DeltaTCvsRefrence: Number(targetRow.DeltaTCVsRef)
        }

        cs.TargetScenario.ForeCast = {
          CSId: targetRow.CSIdForecast,
          Cost: Number(targetRow.Forecast),
          DeltaFCvsReference: Number(targetRow.DeltaFCVsRef)
        }

        cs.TargetScenario.Actuals = {
          CSId: targetRow.CSIdActuals,
          Cost: Number(targetRow.Actuals),
          DeltaTCvsAct: Number(targetRow.DeltaACTVsRef)
        }
      } else {
        if (cs.TargetScenario.TargetCost == null) {
          cs.TargetScenario.TargetCost = {
            CSId: 0,
            Cost: 0,
            DeltaTCvsRefrence: 0
          }
        }
        if (cs.TargetScenario.ForeCast == null) {
          cs.TargetScenario.ForeCast = {
            CSId: 0,
            Cost: 0,
            DeltaFCvsReference: 0,
          }
        }
        if (cs.TargetScenario.Actuals == null) {
          cs.TargetScenario.Actuals = {
            CSId: 0,
            Cost: 0,
            DeltaTCvsAct: 0,
          }
        }
      }

      if (this.optAVisible) {
        let optARow = this.modifiedOptACSRows.find(x => x.Service == cs.Service);
        if (optARow) {
          let TargetCost = {
            CSId: optARow.CSIdTargetCost,
            Cost: Number(optARow.TargetCosts),
            DeltaTCvsRefrence: Number(optARow.DeltaTCVsRef)
          }

          let ForeCast = {
            CSId: optARow.CSIdForecast,
            Cost: Number(optARow.Forecast),
            DeltaFCvsReference: Number(optARow.DeltaFCVsRef)
          }

          let Actuals = {
            CSId: optARow.CSIdActuals,
            Cost: Number(optARow.Actuals),
            DeltaTCvsAct: Number(optARow.DeltaACTVsRef)
          }
          cs.OptionA = { TargetCost: TargetCost, ForeCast: ForeCast, Actuals: Actuals, ScenarioTitle: this.optATitle };
        } else {
          if (cs.OptionA == null) {
            let TargetCost = {
              CSId: 0,
              Cost: 0,
              DeltaTCvsRefrence: 0
            }
            let ForeCast = {
              CSId: 0,
              Cost: 0,
              DeltaFCvsReference: 0,
            }
            let Actuals = {
              CSId: 0,
              Cost: 0,
              DeltaTCvsAct: 0,
            }
            cs.OptionA = { TargetCost: TargetCost, ForeCast: ForeCast, Actuals: Actuals, ScenarioTitle: this.optATitle };
          }
        }
      }

      if (this.optBVisible) {
        let optBRow = this.modifiedOptBCSRows.find(x => x.Service == cs.Service);
        if (optBRow) {
          let TargetCost = {
            CSId: optBRow.CSIdTargetCost,
            Cost: Number(optBRow.TargetCosts),
            DeltaTCvsRefrence: Number(optBRow.DeltaTCVsRef)
          }

          let ForeCast = {
            CSId: optBRow.CSIdForecast,
            Cost: Number(optBRow.Forecast),
            DeltaFCvsReference: Number(optBRow.DeltaFCVsRef)
          }

          let Actuals = {
            CSId: optBRow.CSIdActuals,
            Cost: Number(optBRow.Actuals),
            DeltaTCvsAct: Number(optBRow.DeltaACTVsRef)
          }
          cs.OptionB = { TargetCost: TargetCost, ForeCast: ForeCast, Actuals: Actuals, ScenarioTitle: this.optBTitle };
        } else {
          if (cs.OptionB == null) {
            let TargetCost = {
              CSId: 0,
              Cost: 0,
              DeltaTCvsRefrence: 0
            }
            let ForeCast = {
              CSId: 0,
              Cost: 0,
              DeltaFCvsReference: 0,
            }
            let Actuals = {
              CSId: 0,
              Cost: 0,
              DeltaTCvsAct: 0,
            }
            cs.OptionB = { TargetCost: TargetCost, ForeCast: ForeCast, Actuals: Actuals, ScenarioTitle: this.optBTitle };
          }
        }
      }

      if (this.optCVisible) {
        let optCRow = this.modifiedOptCCSRows.find(x => x.Service == cs.Service);
        if (optCRow) {
          let TargetCost = {
            CSId: optCRow.CSIdTargetCost,
            Cost: Number(optCRow.TargetCosts),
            DeltaTCvsRefrence: Number(optCRow.DeltaTCVsRef)
          }

          let ForeCast = {
            CSId: optCRow.CSIdForecast,
            Cost: Number(optCRow.Forecast),
            DeltaFCvsReference: Number(optCRow.DeltaFCVsRef)
          }

          let Actuals = {
            CSId: optCRow.CSIdActuals,
            Cost: Number(optCRow.Actuals),
            DeltaTCvsAct: Number(optCRow.DeltaACTVsRef)
          }
          cs.OptionC = { TargetCost: TargetCost, ForeCast: ForeCast, Actuals: Actuals, ScenarioTitle: this.optCTitle };
        } else {
          if (cs.OptionC == null) {
            let TargetCost = {
              CSId: 0,
              Cost: 0,
              DeltaTCvsRefrence: 0
            }
            let ForeCast = {
              CSId: 0,
              Cost: 0,
              DeltaFCvsReference: 0,
            }
            let Actuals = {
              CSId: 0,
              Cost: 0,
              DeltaTCvsAct: 0,
            }
            cs.OptionC = { TargetCost: TargetCost, ForeCast: ForeCast, Actuals: Actuals, ScenarioTitle: this.optCTitle };
          }
        }
      }

      if (cs.Service == "Total Service costs") {
        let totalRefRow = this.refGridApi.getPinnedBottomRow(0).data;
        cs.ReferenceScenario.Cost = totalRefRow.Costs;

        let totalTarRow = this.tarGridApi.getPinnedBottomRow(0).data;
        cs.TargetScenario.TargetCost.Cost = totalTarRow.TargetCosts;
        cs.TargetScenario.ForeCast.Cost = totalTarRow.Forecast;
        cs.TargetScenario.Actuals.Cost = totalTarRow.Actuals;

        if (this.optAVisible) {
          let totalOptARow = this.optAGridApi.getPinnedBottomRow(0).data;
          cs.OptionA.TargetCost.Cost = totalOptARow.TargetCosts;
          cs.OptionA.ForeCast.Cost = totalOptARow.Forecast;
          cs.OptionA.Actuals.Cost = totalOptARow.Actuals;
        }
        if (this.optBVisible) {
          let totalOptBRow = this.optBGridApi.getPinnedBottomRow(0).data;
          cs.OptionB.TargetCost.Cost = totalOptBRow.TargetCosts;
          cs.OptionB.ForeCast.Cost = totalOptBRow.Forecast;
          cs.OptionB.Actuals.Cost = totalOptBRow.Actuals;
        }
        if (this.optCVisible) {
          let totalOptCRow = this.optCGridApi.getPinnedBottomRow(0).data;
          cs.OptionC.TargetCost.Cost = totalOptCRow.TargetCosts;
          cs.OptionC.ForeCast.Cost = totalOptCRow.Forecast;
          cs.OptionC.Actuals.Cost = totalOptCRow.Actuals;
        }
      }
    })

    console.log(refObj)

    return refObj;
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  ngOnDestroy(): void {
    this.configSub.unsubscribe();
  }
}

// creates row for "Total Service Costs" and "CCR"
function createDataRef(totalCost, isEditable, refCCRCost, refCCRId) {
  let result = [];
  result.push({
    Service: "Total Service Costs",
    Costs: totalCost
  });
  result.push({
    CSId: refCCRId,
    Service: "CCR",
    Costs: refCCRCost,
    IsEditable: isEditable
  });
  return result;
};

// creates row for "Total Service Costs" and "CCR"
function createDataTar(total, isEditable, tarCCRTargetCost, tarCCRForecast, tarCCRActuals, tarCCRTargetCostId, tarCCRForecastId, tarCCRActualsId) {
  let result = [];
  result.push({
    Service: "Total Service Costs",
    TargetCosts: total[0],
    Actuals: total[1],
    Forecast: total[2],
  });
  result.push({
    CSIdTargetCost: tarCCRTargetCostId,
    CSIdActuals: tarCCRActualsId,
    CSIdForecast: tarCCRForecastId,
    Service: "CCR",
    TargetCosts: tarCCRTargetCost,
    Actuals: tarCCRActuals,
    Forecast: tarCCRForecast,
    IsEditable: isEditable
  });
  return result;
};

// creates row for "Total Service Costs" and "CCR"
function createDataOptA(total, isEditable, optACCRTargetCost, optACCRForecast, optACCRActuals, optACCRTargetCostId, optACCRForecastId, optACCRActualsId) {
  let result = [];
  result.push({
    Service: "Total Service Costs",
    TargetCosts: total[0],
    Actuals: total[1],
    Forecast: total[2],
  });
  result.push({
    CSIdTargetCost: optACCRTargetCostId,
    CSIdActuals: optACCRActualsId,
    CSIdForecast: optACCRForecastId,
    Service: "CCR",
    TargetCosts: optACCRTargetCost,
    Actuals: optACCRActuals,
    Forecast: optACCRForecast,
    IsEditable: isEditable
  });
  return result;
};

// creates row for "Total Service Costs" and "CCR"
function createDataOptB(total, isEditable, optBCCRTargetCost, optBCCRForecast, optBCCRActuals, optBCCRTargetCostId, optBCCRForecastId, optBCCRActualsId) {
  let result = [];
  result.push({
    Service: "Total Service Costs",
    TargetCosts: total[0],
    Actuals: total[1],
    Forecast: total[2],
  });
  result.push({
    CSIdTargetCost: optBCCRTargetCostId,
    CSIdActuals: optBCCRActualsId,
    CSIdForecast: optBCCRForecastId,
    Service: "CCR",
    TargetCosts: optBCCRTargetCost,
    Actuals: optBCCRActuals,
    Forecast: optBCCRForecast,
    IsEditable: isEditable
  });
  return result;
};

// creates row for "Total Service Costs" and "CCR"
function createDataOptC(total, isEditable, optCCCRTargetCost, optCCCRForecast, optCCCRActuals, optCCCRTargetCostId, optCCCRForecastId, optCCCRActualsId) {
  let result = [];
  result.push({
    Service: "Total Service Costs",
    TargetCosts: total[0],
    Actuals: total[1],
    Forecast: total[2],
  });
  result.push({
    CSIdTargetCost: optCCCRTargetCostId,
    CSIdActuals: optCCCRActualsId,
    CSIdForecast: optCCCRForecastId,
    Service: "CCR",
    TargetCosts: optCCCRTargetCost,
    Actuals: optCCCRActuals,
    Forecast: optCCCRForecast,
    IsEditable: isEditable
  });
  return result;
};

// used to format the cell in germman currency format;
function CurrencyCellRenderer(params: any) {
  if (params.data.Service == "Total Service Costs")
    return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(0)));
  else
    return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
}