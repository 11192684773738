import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.css']
})
export class AddMaterialComponent implements ICellRendererAngularComp {
  public gridApi: any;
  public params: any;
  isAddVisible: any;

  constructor(
    private sharedService: SharedServiceService
  ) { }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  // get called on load of the component;
  agInit(params: ICellRendererParams) {
    this.params = params;
    this.gridApi = params.api;
  }

  addData(params: any) {
    let addObject = {
      IsManual: true,
      IsEditable: true,
      VBLO: "Please Select",
      VBLOId: 0,
      VBLODescription: "",
      EcoId: "",
      GMKGroup: this.gridApi.getDisplayedRowAtIndex(1).data.GMKGroup,
      Plant: this.gridApi.getDisplayedRowAtIndex(1).data.Plant,
      ProcurementType: "",
      Assembly: params.node.key,
      Component: "Please Select",
      BKsPerPiece: 0,
      Material: 0,
      MaterialDescription: "Add Description",
      MaterialNo: 0,
      EQId: 0,
      KMATId: 0,
      KMATVBLOId: 0,
      ConfigurationId: this.gridApi.getDisplayedRowAtIndex(1).data.ConfigurationId,
      ReferencceScenario: {
        ScenarioTitle: "",
        EQId: 0,
        MEKsPerPiece: 0,
        Quantity: 0,
        Weight: 0,
        MEKPerSystem: 0,
      },
      TargetScenario: {
        ScenarioTitle: "",
        TargetCostMEK: {
          EQId: 0,
          PerPiece: 0,
          Quantity: 0,
          Weight: 0,
          PerSystem: 0,
          DeltaTCVsRefMEK: 0,
        },
        ForecastMEK: {
          EQId: 0,
          PerPiece: 0,
          PerSystem: 0,
          DeltaFCVsRefMEK: 0,
          ActualsMEK: 0,
          DeltaTCVsACT: 0,
        }
      }
    };

    this.sharedService.getEqAddedRow(addObject, 0);

    this.gridApi.applyTransaction({
      add: [addObject], addIndex: 0
    })
  }

}