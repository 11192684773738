import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectSearch } from '../models/ProjectSearch';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectsearchService {

  private urlTCGetProject: string = environment.baseURL + "api/TCProject/GetProjectForSearch";
  private urlTCDeleteProject: string = environment.baseURL + "api/TCProject/DeleteProject";
  private urlTCCopyProject: string = environment.baseURL + "api/TCProject/CopyProject";
  
  constructor(
    public http:HttpClient
  ) { }

  getAllProjects(userId):Observable<ProjectSearch>{
    return this.http.get<ProjectSearch>(this.urlTCGetProject + "?userId=" + userId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  deleteProject(obj){
    let projectId = obj.ProjectId;
    let userId = obj.UserId;
    let businessAreaId = obj.BusinessAreaId;
    let businessLineId = obj.BusinessLineId;

    return this.http.post(this.urlTCDeleteProject + '?projectId=' + projectId + '&userId=' + userId + '&businessAreaId=' + businessAreaId + '&businessLineId=' + businessLineId, "").pipe(
      retry(1),
      catchError(this.handleError));
  }

  copyProject(obj){
    let projectId = obj.ProjectId;
    let userId = obj.UserId;
    let businessAreaId = obj.BusinessAreaId;
    let businessLineId = obj.BusinessLineId;

    return this.http.post(this.urlTCCopyProject + "?projectId=" + projectId + "&userId=" + userId + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId, ""). pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
   
    return throwError(error || "Server Error");
  }
}
