<div class="col-md-12 col-lg-12" style="background-color: #CF4B00; margin-top: 0; height: 35px; margin-bottom: 10px;">
    <label class="label-headercolor label-text" style="padding-bottom: 0 !important;">Select a KMAT</label>
</div>

<div class="container" style="height: 70%; overflow: auto;">
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="js-textfield textfield-with-prefix" style="background-color: #ededed; margin-bottom: 5px; height: 2.2rem; border-bottom: 1px solid #666666">
                <input id="inputBox" placeholder="Search for KMAT" aria-label="Search" class="form-control textfield__input borderStyle" style="padding: 0 10px !important; font-size: 0.9rem;"
                    (input) = "filterKMAT($event.target.value)" />
            </div>
        </div>
    </div>
    <ag-grid-angular id="kmatGrid" style="width: 100%; height: 85%" class="ag-theme-alpine"
        [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
        (gridSizeChanged)="onGridSizeChanged($event)" (cellClicked)="onCellClicked($event)"
        [enableBrowserTooltips]="true" (displayedColumnsChanged)="onDisplayedColumnsChanged">
    </ag-grid-angular>

</div>

<mat-dialog-actions align="end">
    <button class="button button--small" mat-dialog-close>
        Cancel</button>&nbsp;
    <button class="button button--small" cdkFocusInitial (click)="onSubmit()">
        Select</button>&nbsp;
</mat-dialog-actions>                                          