import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { OverheadService } from 'src/app/services/overhead.service';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { merge } from 'rxjs';
import * as XLSX from 'xlsx';

let countryCode;
@Component({
  selector: 'app-overhead',
  templateUrl: './overhead.component.html',
  styleUrls: ['./overhead.component.css']
})
export class OverheadComponent implements OnInit {

  public showGrid: boolean = true;
  public rowData: any = [];
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;

  public alertErrorBox = [];
  public modifiedOverheadCostrows = [];
  public modifiedExcelOverheadCostRows = [];
  public errorMessage = []
  public roleMasterData = [];
  public loggedInUser: any;
  public getResponse: any;
  public frameworkComponents: any;
  public sizeToFitRefresh: number = 0;
  public paginationPageSize:any ;

  submitResponse: any;
  refObject: any;
  receivedData: any;
  recievedOverheadCostData: any;
  headerYears: any = [];
  startYear: any = 2024;
  hideExportImport: boolean = true;
  FilterData: any;
  importExcelData: any;
  FinalDatatoSend: any = [];
  Flag: boolean = false;
  s1: any = ["OverheadCost"];
  yearArr: any = ["2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040"];
  public importing = false;
  // changes;
  public adminSubscription: any;
  public unsvdAdminChanges: boolean = false;
  @Output() unSavedChanges = new EventEmitter<any>();

  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;
  public Editable: boolean = true;

  selectBusinessArea = "";
  selectBusinessLine = "";


  constructor(
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toast: ToasterService,
    private overheadService: OverheadService
  ) {
    this.frameworkComponents = { doublingEditor: DoublingEditorComponent }
  }

  ngOnInit(): void {
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user;

        //getting business area and line access of user
        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        //removing duplicates in the list
        this.roleBusinessArea = this.roleBusinessArea.filter((v, i, a) => a.findIndex(v2 => (v2.BusinessAreaId === v.BusinessAreaId)) === i);

        //If the user has only one access, auto populate the dropdown
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.disableBusinessLineDropdown = true;
          this.hideExportImport = false;
        }
        this.showGrid = true;
      }
    );

    this.adminSubscription = this.sharedService.overheadCost.subscribe(() => {
      this.submit();
    })


    countryCode = this.localStorageService.get('countryCode');
    this.bindOverheadCostData();
  }

  updateBusinessLine() {
    this.hideExportImport = true;
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
    this.bindOverheadCostData();
  }

  updateOverheadCost() {
    if (this.selectBusinessLine == "") {
      this.hideExportImport = true;
    }
    else {
      this.hideExportImport = false;
    }
    this.bindOverheadCostData();
  }


  exportGridData() {
    this.showGrid = false;
    this.gridApi.exportDataAsExcel({
      // columnKeys: ['ProductTitle'],
      processCellCallback(params) {
        return params.value ?? 0;
      },
      allColumns: true,
      protection: true,
      sheetName: "OverheadCost",
      fileName: "OverheadCost"
    });
    this.showGrid = true;
    this.toast.notify(serverMessage.excelExportMessage, "success");
  }


  importExcel(event: any) {
    console.log("imported");
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
    if (event.target.files.length > 0) {
      this.importing = true;
      let file = event.target.files[0];
      //Validation for Excel Types
      let ext = file.name.split('.').pop();
      if (ext != "xlsx") {
        this.importing = false;
        this.toast.notify(serverMessage.excelnotImportMessage, "error"); this.onCancel();
      }
      else {
        // To check the size of the Excel file
        if (event.target.files[0].size / 1024 / 1024 > 10) {
          this.importing = false;
          this.toast.notify(serverMessage.excelImportMessage + ' is More Than 10MB', "error"); this.onCancel();
        }
        else {
          let fileReader = new FileReader();
          fileReader.readAsBinaryString(file);
          fileReader.onload = (e) => {
            let workBook = XLSX.read(fileReader.result, { type: 'binary', cellDates: true });
            let workSheet = workBook.SheetNames;

            this.importExcelData = XLSX.utils.sheet_to_json(workBook.Sheets[workSheet[0]], { defval: "" });
            //If user uploads different excel file
            if (workBook.SheetNames[0] !== this.s1[0]) { this.importing = false; this.toast.notify(serverMessage.overheadCost.WorksheetError, "error"); this.onCancel(); }
            else {
              //if user uploads blank excel file
              if (this.importExcelData.length == 0) { this.importing = false; this.toast.notify(serverMessage.Blanksheeterror, "error"); this.onCancel(); }
              else {
                for (let i = 0; i < this.importExcelData.length; i++) {
                  let overheadCostType = this.importExcelData[0]["Overhead Cost Type"];
                  let GMKGroup = this.importExcelData[i]["GMK-Gruppie"];

                  if (overheadCostType == "" || GMKGroup == "") {
                    this.importing = false;
                    this.toast.notify(serverMessage.materialMaster.kmatError, "error"); this.onCancel();
                    break;
                  }
                }
                this.importing = false;
                this.excelDatatoSend(this.importExcelData);
                this.hideExportImport = true;
                //this.Editable = false;
                this.toast.notify(serverMessage.excelImportMessage, "success");
              }
            }
          }
        }
      }
    }
  }

  excelDatatoSend(receivedExcelData) {
    console.log(receivedExcelData);
    // const overheadCostData = this.formatData(receivedExcelData);
    this.modifiedExcelOverheadCostRows = this.formatData(receivedExcelData);
    console.log(this.modifiedExcelOverheadCostRows);
    this.Editable = false;
    this.loadOverheadCostData();
  }

  bindOverheadCostData() {
    this.headerYears = [];
    this.overheadService.getAllOverheadCostData(Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe((data) => {
      this.getResponse = data;
      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        console.log(data.Data);
        this.receivedData = data.Data;
        if (this.receivedData != "") {
          this.recievedOverheadCostData = this.receivedData;
          console.log(this.recievedOverheadCostData);
        }
        else {
          //Static HeaderYears
          for (let val = 0; val < 10; val++) {
            this.headerYears.push(this.startYear + val);
          }
          this.rowData = [];
          this.showGrid = true;
          return;
        }

        let yearList = Array.from(new Set(this.receivedData.map(item => item.Year))).sort();
        let minYear = yearList[0];
        let maxYear = yearList[yearList.length - 1];
        console.log(yearList, minYear, maxYear);
        let overheadCostRow = [];
        const mergedMap = new Map();
        this.recievedOverheadCostData.forEach(data => {
          const key = `${data.GMKGroup}-${data.OverheadCostType}`;
          if (mergedMap.has(key)) {
            const existingRecord = mergedMap.get(key);
            existingRecord[data.Year] = data.FactorValue;
          }
          else {
            let rowNode = {};
            rowNode["GMKGroup"] = data.GMKGroup;
            rowNode["OverheadCostType"] = data.OverheadCostType;
            for (let i = Number(minYear); i <= Number(maxYear); i++) {
              if (!this.headerYears.includes(i)) {
                this.headerYears.push(i);
              }
              rowNode[i] = null;
            }
            rowNode[data.Year] = data.FactorValue;
            mergedMap.set(key, rowNode);
          }
        })
        overheadCostRow = Array.from(mergedMap.values());
        console.log(overheadCostRow);
        this.rowData = overheadCostRow;
        this.loadOverheadCostData();
      }

    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      return false;
    });
  }



  loadOverheadCostData() {
    // let yearColumnsGroup = [];
    this.columnDefs = [
      {
        headerName: "Overhead Cost Type",
        field: "OverheadCostType",
        tooltipField: "OverheadCostType",
        headerTooltip: "Overhead Cost Type",
        editable: false,
      },
      {
        headerName: "GMK-Gruppe",
        field: "GMKGroup",
        tooltipField: "GMKGroup",
        headerTooltip: "GMK-Gruppe",
        editable: false
      },
      // { headerName: 'Years11-20', headerTooltip: 'Years11-20' , hide: true}
    ]

    //Static HeaderYears

    // for (let val = 0; val < 10; val++) {
    //   this.headerYears.push(this.startYear + val);
    // }
    console.log(this.headerYears);
    this.headerYears.forEach((year, index) => {
      this.columnDefs.push({
        headerName: year,
        field: String(year),
        // tooltipField: year,
        headerTooltip: year,
        tooltipValueGetter: CurrencyCellRenderer,
        editable: true,
        singleClickEdit: true,
        cellRenderer: CurrencyCellRenderer,
        // cellEditor: "doublingEditor",
        minWidth: 50
      })
    });

    this.defaultColDef = {
      editable: true,
      filter: true,
      sortable: true,
      suppressMovable: true,
      singleClickEdit: true,
    };
    this.domLayout = 'autoHeight';
    this.showGrid = true;
    // this.rowData = [];
    this.paginationPageSize = 50;
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  onClickCancel() {
    // changes;
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }

    this.unSavedChanges.emit(valToEmit);
    // end;
  }

  submit(event?: any) {
    let response = null;
    let postData: any;
    if (this.modifiedOverheadCostrows.length != 0 || this.modifiedExcelOverheadCostRows.length != 0) {
      if (this.modifiedExcelOverheadCostRows.length != 0) {
        postData = {
          tCOverheadCosts: this.modifiedExcelOverheadCostRows,
          UserId: this.loggedInUser.UserId,
          BusinessAreaId: Number(this.selectBusinessArea),
          BusinessLineId: Number(this.selectBusinessLine)
        };
      }
      else {
        postData = this.datTosSend();
      }
      this.showGrid = false;
      console.log(postData);
      response = this.overheadService.postData(postData).toPromise().then(res => {
        this.submitResponse = res;
        let successCode = this.submitResponse.StatusCode;
        let errorCode = this.submitResponse.Data.ErrorCode;
        let errorMsgeFromBack = this.submitResponse.Data.Message;
        if (successCode == 200) {
          if (errorCode == 0) {
            let valToEmit = {
              unsavedChanges: false,
              saveButtonClick: event == undefined ? false : true
            }
            this.unSavedChanges.emit(valToEmit);
            // end;
            this.toast.notify(serverMessage.dataSaveMessage, "success");
            this.modifiedOverheadCostrows = [];
            (<HTMLInputElement>document.getElementById('file')).value = "";
            this.hideExportImport = false;
            this.bindOverheadCostData();
            this.Editable = true;
            this.hideExportImport = false;
            return true;
          }
          else {
            //Show errorCode and errorMessage in the UI              
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer);
            this.showGrid = true;
            return false;

          }
        }
        //Going in else means that success code is not. ie. Http Status from server is not ok.
        else {
          //Show errorCode and errorMessage in the UI           
          let errorContainer = [
            { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
          this.showGrid = true;
          return false;
        }

      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        this.showGrid = true;
        return false;
      }
      )
    }
    else {
      // changes;
      let valToEmit = {
        unsavedChanges: false,
        saveButtonClick: event == undefined ? false : true
      }
      this.unSavedChanges.emit(valToEmit);
      // end;
      return true;
    }
    return response;
  }

  datTosSend() {
    let sendOverheadCostData = [];
    this.modifiedOverheadCostrows.forEach(row => {
      let years = Object.keys(row).filter(key => !isNaN(Number(key)) && row[key] !== null);
      years.forEach(year => {
        let overheadCostrow: any = {};
        let costIdrow = this.receivedData.find(x => x.OverheadCostType == row.OverheadCostType && x.GMKGroup == row.GMKGroup && x.Year == Number(year));
        overheadCostrow["OverheadCostId"] = costIdrow == undefined ? 0 : costIdrow.OverheadCostId;
        overheadCostrow["GMKGroup"] = row.GMKGroup;
        overheadCostrow["OverheadCostType"] = row.OverheadCostType;
        overheadCostrow["Year"] = year;
        overheadCostrow["FactorValue"] = Number(row[year]);
        if (overheadCostrow.OverheadCostId == 0 || (overheadCostrow.OverheadCostId != 0 && Number(costIdrow.FactorValue) != Number(overheadCostrow.FactorValue))) {
          sendOverheadCostData.push(overheadCostrow);
        }
      })
    })
    // sendOverheadCostData = this.formatData(this.modifiedOverheadCostrows);
    this.refObject = {
      tCOverheadCosts: sendOverheadCostData,
      UserId: this.loggedInUser.UserId,
      BusinessAreaId: Number(this.selectBusinessArea),
      BusinessLineId: Number(this.selectBusinessLine)
    }
    return this.refObject;
  }

  formatData(data) {
    let returnDTOData = [];
    data.forEach(row => {
      let years = Object.keys(row).filter(key => !isNaN(Number(key)) && row[key] !== null).sort();
      years.forEach(year => {
        let overheadCostrow = {};
        let costIdrow = this.receivedData.find(x => x.OverheadCostType == row["Overhead Cost Type"] && x.GMKGroup == row["GMK-Gruppe"] && x.Year == Number(year));
        overheadCostrow["OverheadCostId"] = costIdrow == undefined ? 0 : costIdrow.OverheadCostId;
        overheadCostrow["GMKGroup"] = row["GMK-Gruppe"];
        overheadCostrow["OverheadCostType"] = row["Overhead Cost Type"];
        overheadCostrow["Year"] = year;
        overheadCostrow["FactorValue"] = Number(row[year]);
        returnDTOData.push(overheadCostrow);
      })
    })
    return returnDTOData;
  }

  onCancel() {
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }
    this.unSavedChanges.emit(valToEmit);
    this.hideExportImport = false;
    this.modifiedOverheadCostrows = [];
    (<HTMLInputElement>document.getElementById('file')).value = "";
    this.bindOverheadCostData();
    this.Editable = true;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onCellValueChanged(params) {
    // changes;
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false

    }
    this.unSavedChanges.emit(valToEmit);
    // end;
    this.hideExportImport = true;
    let rowPushed = false;
    if (this.modifiedOverheadCostrows.length == 0) {
      this.modifiedOverheadCostrows.push(params.data);
      rowPushed = true;
    }
    else {
      this.modifiedOverheadCostrows.forEach(row => {
        if (row["OverheadCostType"] == params.data["OverheadCostType"] && row["GMKGroup"] == params.data["GMKGroup"]) {
          rowPushed = true;
        }
      })
    }
    if (!rowPushed) {
      this.modifiedOverheadCostrows.push(params.data);
    }
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe()
    }
  }

}

//use to format the cell according to german currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode, {
    minimumFractionDigits: 3,  // Minimum of 3 decimal places
    maximumFractionDigits: 3,  // Maximum of 4 decimal places
  }).format(params.value);
}
