import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { Observable, throwError } from 'rxjs';

import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EnumMasterType } from '../enum/MasterType';
import { KMATHierarchyDto } from '../models/KMATHierarchyDto';
import { KmatDto } from '../models/KmatDto';
import { TCConfigurationDataRequest } from '../models/tc-configuration';
import { CreateManualVBLODTO } from '../models/CreateManualVBLODTO';
import { TCConfigurationDetailDTO } from '../models/TCConfigurationDetailDTO';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  // contains base Url
  readonly baseUrl = environment.baseURL

  private urlGetVBLOData = this.baseUrl + "api/TCConfiguration/GetKMATHierarchyWithVBLOs?projectId=";
  private urlGetKMATData = this.baseUrl + "api/TCConfiguration/GetAllKMAT?projectId=";
  private urlGetConfigurationData = this.baseUrl + "api/TCConfiguration/GetAllConfigurationForProject?projectId=";
  private urlPostConfigurationData = this.baseUrl + "api/TCConfiguration/InsertUpdateTCConfiguration";
  private urlDeleteConfiguration = this.baseUrl + "api/TCConfiguration/DeleteConfigurationById";
  private urlCopyConfiguration = this.baseUrl + "api/TCConfiguration/CopyConfigurationById"
  private urlLockUnlockConfiguration = this.baseUrl + "api/TCConfiguration/LockUnLockConfigurationById"
  private urlMvbloData = this.baseUrl + "api/TCConfiguration/CreateManualVBLO";
  private urlGetConfiguratiodeatils = this.baseUrl + "api/TCConfiguration/GetConfigurationDetails?configurationId=";
  private urlGetLinkKMATDetails=this.baseUrl + "api/TCConfiguration/GetLinkKMATDetails?configurationId=";
  private urlGetVBLOTypeAndAssemblyList = this.baseUrl + "api/TCConfiguration/GetVBLOTypeAndAssembly";
  constructor(private http: HttpClient) {
  }

  getConfigurationData(projectId): Observable<any> {
    return this.http.get<any>(this.urlGetConfigurationData + projectId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  GetConfigurationDetails(configurationId): Observable<any> {
    return this.http.get<any>(this.urlGetConfiguratiodeatils + configurationId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }


  getVBLOData(projectId, configurationId, kmatId): Observable<KMATHierarchyDto> {
    return this.http.get<KMATHierarchyDto>(this.urlGetVBLOData + projectId + "&configurationId=" + configurationId + '&kmatId=' + kmatId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  };

  getKmatData(projectId): Observable<any> {
    return this.http.get(this.urlGetKMATData + projectId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  saveConfiguration(tcConfigurationData: TCConfigurationDataRequest): Observable<any> {
    return this.http.post(this.urlPostConfigurationData, tcConfigurationData).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  deleteConfiguration(obj): Observable<any> {
    let configurationId = obj.ConfigurationId;
    let userId = obj.UserId;
    return this.http.delete(this.urlDeleteConfiguration + "?configurationId=" + configurationId + "&userId=" + userId).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  copyConfiguration(obj): Observable<any> {
    let configurationId = obj.ConfigurationId;
    let userId = obj.UserId;
    return this.http.post(this.urlCopyConfiguration + "?configurationId=" + configurationId + "&userId=" + userId, "").pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  lockUnlockConfiguration(obj): Observable<any> {
    let configurationId = obj.ConfigurationId;
    let userId = obj.UserId;
    return this.http.post(this.urlLockUnlockConfiguration + "?configurationId=" + configurationId + "&userId=" + userId, "").pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
 
  postMVBLOData(data): Observable<CreateManualVBLODTO> {
    return this.http.post<CreateManualVBLODTO>(this.urlMvbloData, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  GetLinkKMATDetails(configurationId): Observable<any> {
    return this.http.get(this.urlGetLinkKMATDetails + configurationId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  GetVBLOTypeAndAssemblyList():Observable<any>{
    return this.http.get(this.urlGetVBLOTypeAndAssemblyList).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // It handle error ,server connection error and throws it to the component where its is recieved in the error of "subscribe" of service call method;
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
